import React, { useState } from 'react';
import { Button, Modal, Card } from 'react-bootstrap';
import UploadForm from '../Forms/UploadForm';

const UploadModal = (props = {}) => {
  const [modalState, setModalState] = useState(false);
  const openModal = () => (setModalState(true));
  const closeModal = () => (setModalState(false));

  return (
    <div {...props} >
      <Button className="green-button" onClick={openModal}>+ New Upload Request</Button>
      <Modal show={modalState} onHide={closeModal} animation={false} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Upload Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UploadForm closeModal={closeModal} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export { UploadModal };
export default UploadModal;