import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useUserData } from '../contexts/AuthContext';

// Styles 
import { Row, Col, Alert, Button } from 'react-bootstrap';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Swal from 'sweetalert2';
import 'react-tabs/style/react-tabs.css';
import { StyledButton } from "../styled/Misc";

// Components 
import RequestsTabs from '../components/RequestsTabs';
import { LawFirmForm } from "../components/Forms/LawFirmForm";
import DeliverySettingsForm from '../components/Forms/DeliverySettingsForm';
import LawFirmBSOAccounts from "../components/Tables/LawFirmBSOAccounts";
import LawFirmUsers from "../components/Tables/LawFirmUsers";
import Debug from "../components/Debug";
import { admin } from '../utils/EREDocs';
import { humanize } from "../utils/";
let seconds_between_server_checks = Number(process.env.REACT_APP_SECONDS_BETWEEN_SERVER_CHECKS || '60');

const FirmManagementDetails = () => {
  const navigate = useNavigate();
  const { userData } = useUserData();
  let { org_id } = useParams();
  let { passport: {
    user: {
      group_id = 6,
      organization: {
        org_id: default_org_id,
        prefix,
      } = {},
    } = {} } = {}, isAuthenticated = false } = userData || {};
  (typeof default_org_id !== "number") && (default_org_id = Number(default_org_id));
  (typeof org_id !== "number") && (org_id = Number(org_id));
  (!org_id && default_org_id) && (org_id = default_org_id);
  (org_id !== default_org_id) && (prefix = null);
  let superAdminAccess = (isAuthenticated && Number(group_id) === 1);
  let showPortalRebootButton = (superAdminAccess && Number(org_id) === Number(process.env.REACT_APP_PORTAL_SERVER_ORG_ID || '3')); // Use the org_id that is used by the portal server 
  const [portalServerStatus, setPortalServerStatus] = useState({
    alias: process.env.REACT_APP_PORTAL_SERVER_ALIAS || "PORTAL_SERVER",
    status: "unknown",
    last_checked: Date.now(),
  });

  const [loadingPage, setLoadingPage] = useState(false);
  const [showPageAlert, setShowPageAlert] = useState(false);
  const [pageAlert, setPageAlert] = useState({ message: "", alert_type: "info" });
  const [tabIndex, setTabIndex] = useState(0);
  const [firmSecretsLabel, setFirmSecretsLabel] = useState("Firm Secrets");
  const [firmSecrets, setFirmSecrets] = useState({});

  const readFirmSecrets = async (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    let alertObject = { ...pageAlert };
    let newState = { ...firmSecrets };
    let params = { org_id };
    let response;

    try {
      if (loadingPage) {
        throw new Error("Please wait for the form to finish loading.");
      }
      if (!params?.org_id) {
        throw new Error("Missing org_id.");
      }
      setLoadingPage(true);
      response = await admin.secrets(params);
      alertObject.message = "Read Secrets Successfully!";
    } catch (ex) {
      console.log(ex);
      response = ex?.response || {};
      response.status = 500;
      response.statusText = `${ex.message}`;
      if (!response?.data) {
        response.data = { err: 401, message: `${ex.message}`, error: ex.error };
      }
    } finally {
      setLoadingPage(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    let { error, message = '', secrets, SecretId } = data;
    let { SecretString = '{}' } = secrets || {};
    alertObject.alert_type = status !== 200 ? "danger" : "success";
    if (status !== 200) {
      alertObject.message = `[${status}] ${statusText} `;
      newState = {}; // Reset the state
    }
    alertObject.message += message ? `${message}` : "";
    alertObject.message += SecretId ? ` SecretId: ${SecretId} ` : "";
    alertObject.message += error ? ` ${error} ` : "";

    if (SecretId) {
      setFirmSecretsLabel(`Firm SecretId ${SecretId}`);
    }

    // Extract the secrets from the response
    try {
      if (!SecretString || typeof SecretString !== 'string') {
        throw new Error("Invalid secret string");
      }
      newState = JSON.parse(SecretString);
    } catch (ex) {
      newState = {}; // Reset the state
    }
    setFirmSecrets((prev) => ({ ...prev, ...newState }));

    // Show Alert success or danger
    setShowPageAlert(!!alertObject?.message);
    if (alertObject?.message) {
      alertObject.message = alertObject.message.trim();
      setPageAlert(prevState => ({ ...prevState, ...alertObject }));
    }
    return;
  };

  const getServerStatus = async ({ checkLiveStatus = false } = {}) => {
    let seconds_from_last_checked = Math.round((Date.now() - portalServerStatus.last_checked) / 1000);

    if (checkLiveStatus) {
      if (seconds_from_last_checked < seconds_between_server_checks && !['running', 'stopped', 'blacklisted'].includes(portalServerStatus?.status)) {
        await Swal.fire({
          icon: 'info',
          title: "Too Soon!",
          text: `Please wait ${seconds_between_server_checks - seconds_from_last_checked} seconds before checking again.`,
          showCancelButton: true,
        });
        return;
      }

      let rebootSteps = ['running', 'stopping', 'stopped', 'pending', 'running'];
      let nextStep = (portalServerStatus?.status === 'blacklisted') ? 'stopping' : rebootSteps[rebootSteps.indexOf(portalServerStatus?.status) + 1] || 'running';
      let title = `Are you sure you want to set ${humanize((portalServerStatus.alias || '').toUpperCase().replace('_SERVER', ''))} from ${portalServerStatus?.status} to ${nextStep}?`;
      let confirmButtonText = `Yes, set  ${portalServerStatus.alias || 'server'} to ${nextStep}!`;
      let confirmed = await Swal.fire({
        title,
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText,
      }).then((result) => (result.isConfirmed));
      if (!confirmed) {
        return;
      }
    }

    let alertObj = { ...pageAlert };
    let serverStatus = "unknown";
    setLoadingPage(true);
    let { status, statusText, data } = await admin.getServerStatus({ server_alias: portalServerStatus.alias, dryRun: !Boolean(checkLiveStatus) }).catch((error) => {
      console.log("Error on admin.getServerStatus", error);
    }) || {};
    setLoadingPage(false);

    if (status > 399) {
      console.log("Error on admin.getServerStatus", { status, statusText, data });
      alertObj.alert_type = "danger";
      alertObj.message = `Error on admin.getServerStatus ${statusText}`;
    } else {
      alertObj.alert_type = "info";
      alertObj.message = "";
    }

    let { instance_state = [], status_details, status: _status } = data?.results || data || {};
    if (instance_state.length > 0) {
      serverStatus = instance_state[0]?.state || "unknown";
    }
    setPortalServerStatus(prevState => ({ ...prevState, status: serverStatus, ...(checkLiveStatus && { last_checked: Date.now() }) }));

    if (checkLiveStatus) {
      alertObj.message = status_details || _status || statusText || "";
    }
    if (!!alertObj.message) {
      setShowPageAlert(!!alertObj.message);
      setPageAlert(prevState => ({ ...prevState, ...alertObj }));
      await Swal.fire({
        icon: alertObj.alert_type === 'danger' ? 'error' : 'success',
        title: "Done!",
        text: alertObj.message,
        showConfirmButton: true,
        background: false,
        backdrop: false
      });
    }
  };

  const handleServerReboot = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    let seconds_from_last_checked = Math.round((Date.now() - portalServerStatus.last_checked) / 1000);
    if (seconds_from_last_checked < seconds_between_server_checks && !['running', 'stopped', 'blacklisted'].includes(portalServerStatus?.status)) {
      await Swal.fire({
        icon: 'info',
        title: "Too Soon!",
        text: `Please wait ${seconds_between_server_checks - seconds_from_last_checked} seconds before checking again.`,
        showCancelButton: true,
      });
      return;
    }

    let action = ['running', 'stopped', 'blacklisted'].includes(portalServerStatus.status || 'unknown') ? 'toggle_reboot' : 'check_status';
    if (action === "check_status") {
      return getServerStatus({ checkLiveStatus: true });
    }

    let rebootSteps = ['running', 'stopping', 'stopped', 'pending', 'running'];
    let nextStep = (portalServerStatus?.status === 'blacklisted') ? 'stopping' : rebootSteps[rebootSteps.indexOf(portalServerStatus?.status) + 1] || 'running';
    let title = `Are you sure you want to set ${humanize((portalServerStatus.alias || '').toUpperCase().replace('_SERVER', ''))} from ${portalServerStatus?.status} to ${nextStep}?`;
    let confirmButtonText = `Yes, set  ${portalServerStatus.alias || 'server'} to ${nextStep}!`;
    let confirmed = await Swal.fire({
      title,
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText,
    }).then((result) => (result.isConfirmed));
    if (!confirmed) {
      return;
    }

    let alertObj = { ...pageAlert };
    let serverStatus = "unknown";
    setLoadingPage(true);
    let { status, statusText, data } = await admin.toggleReboot({ server_alias: portalServerStatus.alias }).catch((error) => {
      console.log("Error on admin.handleServerReboot", error);
    }) || {};
    setLoadingPage(false);

    if (status > 399) {
      console.log("Error on admin.handleServerReboot", { status, statusText, data });
      alertObj.alert_type = "danger";
      alertObj.message = `Error on admin.getServerStatus ${statusText}`;
    } else {
      alertObj.alert_type = "info";
    }

    let { instance_state = [], status_details, status: _status } = data?.results || data || {};
    alertObj.message = status_details || _status || statusText || "";
    if (instance_state.length > 0) {
      serverStatus = instance_state[0]?.state || "unknown";
    }
    setPortalServerStatus(prevState => ({ ...prevState, status: serverStatus, last_checked: Date.now() }));
    if (!!alertObj.message) {
      setShowPageAlert(!!alertObj.message);
      setPageAlert(prevState => ({ ...prevState, ...alertObj }));
      await Swal.fire({
        icon: alertObj.alert_type === 'danger' ? 'error' : 'success',
        title: "Done!",
        text: alertObj.message,
        showConfirmButton: true,
        background: false,
        backdrop: false
      });
    }
  };

  const initFn = async () => {
    if (showPortalRebootButton) {
      await getServerStatus();
    }
  };

  useEffect(() => {
    let mounted = true;
    (mounted) && initFn();
    return () => mounted = false
  }, []);

  useEffect(() => {
    let mounted = true;
    const init = async () => {
      setLoadingPage(false);
      if (!org_id || org_id === "" || org_id === 0) {
        navigate(`/firm-management`);
      }
    }
    (mounted) && init();
    return () => mounted = false
  }, [org_id]);

  return (
    <div className="page-container">
      {showPortalRebootButton ? <div className="d-flex justify-content-between">
        <h4 className="h-inline">Law Firm Details: (Id:{org_id})</h4>
        <Button
          type="submit"
          size="md"
          variant={'running' === portalServerStatus.status ? "success"
            : (['stopped', 'terminated', 'blacklisted'].includes(portalServerStatus.status) ? "danger"
              : ['pending', 'unknown'].includes(portalServerStatus.status) ? "info" : "warning")}
          onClick={handleServerReboot}
          {...(loadingPage) && { disabled: true }}
        >{`${humanize((portalServerStatus.alias || '').toUpperCase().replace('_SERVER', ''))} is ${portalServerStatus.status} | `}
          <strong>{['running', 'blacklisted'].includes(portalServerStatus.status) ? 'Stop' : 'stopped' === portalServerStatus.status ? 'Start' : 'Check Status'}</strong>
        </Button>
      </div> : <h4>Law Firm Details: (Id:{org_id})</h4>}
      <hr />
      <Alert
        dismissible
        onClose={() => setShowPageAlert(false)}
        show={showPageAlert}
        variant={pageAlert.alert_type}
        className="p-2 mb-1"
      >{pageAlert.message}</Alert>

      <Row>
        <Col xs={5}>
          <LawFirmForm org_id={org_id} />
        </Col>
        <Col>
          <Tabs key="tabs" selectedIndex={tabIndex} onSelect={tabIndex => setTabIndex(tabIndex)} >
            <TabList>
              <Tab key="tab-1" >
                <h5>BSO Accounts</h5>
              </Tab>
              <Tab key="tab-2" >
                <h5>Users</h5>
              </Tab>
              <Tab key="tab-3" >
                <h5>Requests</h5>
              </Tab>
              <Tab key="tab-4" >
                <h5>Settings</h5>
              </Tab>
            </TabList>

            <TabPanel key="tab-1-info" >
              <Row>
                <Col>
                  <LawFirmBSOAccounts org_id={org_id} />
                </Col>
              </Row>
            </TabPanel>

            <TabPanel key="tab-2-info" >
              <Row>
                <Col>
                  <LawFirmUsers org_id={org_id} />
                </Col>
              </Row>
            </TabPanel>

            <TabPanel key="tab-3-info" >
              <RequestsTabs org_id={org_id} />
            </TabPanel>

            <TabPanel key="tab-4-info" >
              <DeliverySettingsForm org_id={org_id} />
            </TabPanel>
          </Tabs>
        </Col>
      </Row>
      <Row>
        <Debug debugLabel={firmSecretsLabel} debugData={firmSecrets} className="mt-3 col-12 firm-secrets">
          <div className="ps-0 mb-3">
            <StyledButton
              className={`${loadingPage ? "light-green-button" : "green-button"}`}
              onClick={readFirmSecrets}
            >{loadingPage ? "Reading Secret..." : "Read Firm Secrets"}</StyledButton>
          </div>
        </Debug>
      </Row>
    </div>
  );
}

export default FirmManagementDetails;