import React, { useState, useEffect } from 'react';
import { useUserData } from "../../contexts/AuthContext";
import { Button, Modal, Table, Alert } from 'react-bootstrap';
import { StyledBSOStatus } from "../../styled/Misc";

import { BSOTFAForm, BSOLoginForm } from '../Forms/';
import { bso } from "../../utils/EREDocs"; // tryAutoLogin, toggleAccount

const MINUTE_MS = 60 * 1000; // 1minute in milliseconds
const BSO_COOKIES_EXPIRATION_MS = MINUTE_MS * Number(process.env.REACT_APP_BSO_COOKIES_EXPIRATION_MINUTES || '105') // In Minutes (default 105minutes = 1hr 45minutes)

const BSOAccountsList = ({ switchSlide = () => { } }) => {
  const { userData, setUserData, handleBSOLogout, clearStrikesClicked, alertMessage, setAlertMessage } = useUserData();
  let { bsoLoggedIn = false, bso_username, auth_id, firm_id, attorney = '', attorney_email, default_user_id, enabled, locked, last_login_attempted, text_number, strikeCount, MAX_STRIKES_ALLOWED,
    server_autologin = false, client_autologin = false,
    passport: { user: { auth_accounts = [], selected_auth = {} } = {} } = {}
  } = userData || {};

  if (Object.keys(selected_auth).length === 0) {
    selected_auth = { bsoLoggedIn, bso_username, auth_id, firm_id, attorney, attorney_email, default_user_id, enabled, locked, last_login_attempted, text_number, strikeCount, max_strikes: MAX_STRIKES_ALLOWED };
  }

  const strikeMessage = "Strikes are the total number of times the BSO rejected your request. \n\nThis is often the case when the claimant ssn is not found, or no longer accessible at the BSO for your BSO session login. \n\nIn order to help prevent locking out your BSO account, strikes are implemented to prevent too many failed requests in a 24 hour period. When your strike count exceeds the acceptable total, requests will be prevented/cancelled until 24 hours or until strikes are cleared.";
  const [showAlert, setShowAlert] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);

  // ===============[ Select BSO Account ]==================================
  const selectBSOAccount = async (event, { bso_username, auth_id } = {}) => {
    if (!event?.target) {
      return;
    }
    event.preventDefault();

    let resp = { data: null };
    try {
      setLoadingForm(true);
      let params = { bso_login: bso_username, auth_id };
      resp = await bso.toggleAccount(params); // Should return the same data ase API.getUserSession() if successful 
    } catch (error) {
      const data = (error.response && error.response.data) ? error.response.data : { err: 401, details: `${error.message}`, error: error.error };
      let error_message = `${data?.err ? data.err + " " : ""}`; // 401 
      error_message += `${data?.status ? data.status + " " : ""}`; // ERROR 
      if (data?.error) {
        error_message += `${data.error} `; // ScrapeError Message + BSOAutoLogin Error 
      } else if (data?.message) {
        error_message += `${data.message} `;
      } else {
        error_message += `${data?.details ? data.details : ""}`;
      }
      console.log(data ? data : error);
      console.log(error_message);
      setShowAlert(true && error_message);
      setAlertMessage(prevState => ({ ...prevState, message: error_message, alert_type: "danger" }));
      return;
    } finally {
      setLoadingForm(false);
    }

    setUserData(prevState => ({
      ...prevState,
      ...resp?.data,
      awaitingOTP: false,
    }));
  };

  // ===============[ handleBSOAutoLogin ]==================================
  const handleBSOAutoLogin = async (event, { bso_username, auth_id } = {}) => {
    if (!event?.target || !server_autologin) {
      return;
    }
    event.preventDefault();
    let current_user_session = {
      data: null
    };
    try {
      setLoadingForm(true);
      let params = { bso_login: bso_username, auth_id };
      current_user_session = await bso.tryAutoLogin(params); // Should return the same data ase API.getUserSession() if successful 
    } catch (error) {
      const data = (error.response && error.response.data) ? error.response.data : { err: 401, details: `${error.message}`, error: error.error };
      let error_message = `${data?.err ? data.err + " " : ""}`; // 401 
      error_message += `${data?.status ? data.status + " " : ""}`; // ERROR 
      if (data?.error) {
        error_message += `${data.error} `; // ScrapeError Message + BSOAutoLogin Error 
      } else if (data?.message) {
        error_message += `${data.message} `;
      } else {
        error_message += `${data?.details ? data.details : ""}`;
      }
      console.log(data ? data : error);
      setShowAlert(true);
      setAlertMessage(prevState => ({ ...prevState, message: error_message, alert_type: "danger" }));
    } finally {
      setLoadingForm(false);
    }

    setUserData(prevState => ({
      ...prevState,
      ...current_user_session?.data,
      awaitingOTP: false,
    }));
  }

  // =========================[ useEffect ]=========================
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setLoadingForm(false);
    }
    return () => mounted = false
  }, []);

  let ignoreMessages = ["Already Logged In"];
  return (<div>
    <Alert
      dismissible
      onClose={() => setShowAlert(false)}
      show={showAlert}
      variant={!ignoreMessages.includes(alertMessage.message) ? alertMessage.alert_type : "info"}
    >{!ignoreMessages.includes(alertMessage.message) ? alertMessage.message : "The BSO Account that is selected is what will be used for your BSO requests."}</Alert>
    <Table striped bordered>
      <thead>
        <tr>
          <th>Attorney</th>
          <th>BSO Status</th>
          <th>Strikes</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {auth_accounts.map(({ bsoLoggedIn = false, bso_username, auth_id, attorney, max_strikes = MAX_STRIKES_ALLOWED, strikeCount = 0, selected = false } = {}) => (
          <tr key={`bsoAcct-${auth_id}`}>
            <td>{attorney}</td>
            <td className={bsoLoggedIn ? "text-success" : "text-danger"}>{bsoLoggedIn ? "Connected" : "Not Connected"}</td>
            <td>
              {`${strikeCount} of ${max_strikes}`}
              {strikeCount > 0 && <Button className="remove-button ms-2 btn-sm" onClick={clearStrikesClicked} title={strikeMessage} {...(loadingForm) && { disabled: true }}>
                {loadingForm ? "Loading..." : "Clear Strikes"}
              </Button>}
            </td>
            <td>
              {!bsoLoggedIn && server_autologin && (<Button className="green-button me-2 btn-sm" onClick={(event) => handleBSOAutoLogin(event, { bso_username, auth_id })} title={`bso_username = ${bso_username} auth_id = ${auth_id}`} {...(loadingForm) && { disabled: true }} >
                {loadingForm ? "Loading..." : "Auto Login"}
              </Button>)}
              {bsoLoggedIn ? (
                <Button className="remove-button btn-sm" onClick={(event) => handleBSOLogout(event, { bso_username, auth_id })} title={`bso_username = ${bso_username} auth_id = ${auth_id}`} {...(loadingForm) && { disabled: true }}>
                  {loadingForm ? "Loading..." : "Log Out"}</Button>
              ) : (
                <Button className="green-button btn-sm"
                  onClick={async (event) => {
                    if (selected_auth.auth_id !== auth_id) {
                      await selectBSOAccount(event, { bso_username, auth_id });
                    }
                    return switchSlide();
                  }}
                  {...(loadingForm) && { disabled: true }}>
                  {loadingForm ? "Loading..." : "Login"}
                </Button>
              )}
            </td>
            <td>
              {selected_auth.auth_id === auth_id ? (
                <span className="bold-text text-success" title={`bso_username = ${bso_username} auth_id = ${auth_id}`}>Selected</span>
              ) : (
                <Button className="primary-button btn-sm" onClick={(event) => selectBSOAccount(event, { bso_username, auth_id })} title={`bso_username = ${bso_username} auth_id = ${auth_id}`} {...(loadingForm) && { disabled: true }}>
                  {loadingForm ? "Loading..." : "Select"}
                </Button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </div>);
}

const BSOAccountModal = (props) => {
  const { userData, sessionIntervalId } = useUserData();
  let { attorney = '', bsoLoggedIn = false, server_autologin = false, client_autologin = false } = userData || {};
  const expiresInMinutes = ((userData?.AuthT ? (userData.AuthT + BSO_COOKIES_EXPIRATION_MS) - Date.now() : 0) / MINUTE_MS).toFixed(2);

  // Modal State
  const [modalState, setModalState] = useState(false);

  // Slide State
  const [slide, setSlide] = useState(0);  // 0 = BSO Accounts Table, 1 = BSO Login Form, 2 = BSO TFA Form

  // Open Modal
  const openModal = (s = 0) => {
    setSlide(s);
    setModalState(true);
  };

  // Close Modal
  const closeModal = (s = 0) => {
    setSlide(s);
    setModalState(false);
  };

  const RenderSwitch = ({ s = 0 } = {}) => {
    switch (s) {
      case 1:
        return <BSOLoginForm switchSlide={(to = 2) => setSlide(to)} />;
      case 2:
        return <BSOTFAForm switchSlide={(to = 0) => setSlide(to)} />;
      default:
        return <BSOAccountsList switchSlide={(to = 1) => setSlide(to)} />;
    }
  };

  return (
    <div {...props} >
      <StyledBSOStatus
        className="primary-button"
        onClick={() => openModal()}
        data-intervalid={sessionIntervalId}
        title={`Expires in ${expiresInMinutes} minutes`}
      >{attorney ? `(${attorney}) ` : ""}BSO Status: <span className={bsoLoggedIn ? "text-success" : "text-danger"}>{bsoLoggedIn ? "Connected" : "Not Connected"}</span>
      </StyledBSOStatus>
      <Modal show={modalState} onHide={closeModal} animation={false} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {slide === 0 ? "BSO Account List" : "BSO Login"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RenderSwitch s={slide} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export { BSOAccountsList, BSOAccountModal };
export default BSOAccountModal;