import React, { useState } from 'react';
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useUserData } from "../contexts/AuthContext";
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import SubHeader from './SubHeader';

// Globals 
const OVERRIDE_LOGIN = process.env.REACT_APP_OVERRIDE_LOGIN === "true";
const HeaderMenu = [
  { page_title: "Claimants", page_link: ["/claimants", "/claimant-details/:claimant_id"], disabled: false, adminOnly: false, ereAccess: true },
  { page_title: "Download Requests", page_link: ["/download-requests", "/claimant-logs", "/download-request-details/:request_id", "/default-download-request", "/custom-download-request"], disabled: false, adminOnly: false, ereAccess: true },
  { page_title: "Upload Requests", page_link: "/upload-requests", disabled: false, adminOnly: false, ereAccess: true },
];
const HeaderSubMenu = {
  "Claimants": [
    { page_title: "Claimants", page_link: "/claimants", disabled: false, ereAccess: true },
  ],
  "Claimant Details": [
    { page_title: "Claimants", page_link: "/claimants", disabled: false, ereAccess: true },
    { page_title: "Claimant Details", page_link: "/claimant-details/:claimant_id", disabled: false, ereAccess: true },
  ],
  "Download Requests": [
    { page_title: "Request Logs", page_link: "/download-requests", disabled: false, ereAccess: true },
    { page_title: "Claimant Logs", page_link: "/claimant-logs", disabled: false, ereAccess: true },
    { component_config: { type: 'DownloadRequestModal' }, disabled: false, ereAccess: true },
  ],
  "Request Details": [
    { page_title: "Request Logs", page_link: "/download-requests", disabled: false, ereAccess: true },
    { page_title: "Claimant Logs", page_link: "/claimant-logs", disabled: false, ereAccess: true },
    { page_title: "Request Details", page_link: "/download-request-details/:request_id", disabled: false, ereAccess: true },
    { component_config: { type: 'DownloadRequestModal' }, disabled: false, ereAccess: true },
  ],
  "Default Download Request": [
    { page_title: "Request Logs", page_link: "/download-requests", disabled: false, ereAccess: true },
    { page_title: "Claimant Logs", page_link: "/claimant-logs", disabled: false, ereAccess: true },
    { page_title: "Default Download Request", page_link: "/default-download-request", disabled: false, ereAccess: true },
    { component_config: { type: 'DownloadRequestModal' }, disabled: false, ereAccess: true },
  ],
  "Custom Download Request": [
    { page_title: "Request Logs", page_link: "/download-requests", disabled: false, ereAccess: true },
    { page_title: "Claimant Logs", page_link: "/claimant-logs", disabled: false, ereAccess: true },
    { page_title: "Custom Download Request", page_link: "/custom-download-request", disabled: false, ereAccess: true },
    { component_config: { type: 'DownloadRequestModal' }, disabled: false, ereAccess: true },
  ],
  "Upload Requests": [
    { page_title: "Upload Requests", page_link: "/upload-requests", disabled: false, ereAccess: true },
    { component_config: { type: 'UploadModal' }, disabled: false, ereAccess: true },
  ],
  "Firm Management": [
    { page_title: "Firm Management", page_link: "/firm-management", disabled: false, adminOnly: true, ereAccess: false },
    { page_title: "Downloads", page_link: "/download-management", disabled: false, adminOnly: true, ereAccess: false },
    { page_title: "Uploads", page_link: "/upload-management", disabled: false, adminOnly: true, ereAccess: false },
    { page_title: "Download Queue", page_link: "/download-queue", disabled: false, adminOnly: true, ereAccess: false },
  ],
  "Firm Details": [
    { page_title: "Firm Management", page_link: "/firm-management", disabled: false, adminOnly: true, ereAccess: false },
    { page_title: "Firm Details", page_link: "/firm-management-details/:org_id", disabled: false, adminOnly: true, ereAccess: false },
  ],
  "BSO Account Management": [
    { page_title: "Firm Management", page_link: "/firm-management", disabled: false, adminOnly: true, ereAccess: false },
    { page_title: "Firm Details", page_link: "/firm-management-details/:org_id", disabled: false, adminOnly: true, ereAccess: false },
    { page_title: "BSO Account Management", page_link: "/bso-account-management-details/:auth_id", disabled: false, adminOnly: true, ereAccess: false },
  ],
  "User Details": [
    { page_title: "Firm Management", page_link: "/firm-management", disabled: false, adminOnly: true, ereAccess: false },
    { page_title: "Firm Details", page_link: "/firm-management-details/:org_id", disabled: false, adminOnly: true, ereAccess: false },
    { page_title: "User Details", page_link: "/user/:user_id", disabled: false, adminOnly: true, ereAccess: false },
  ],
  "Support": [
    { page_title: "Support", page_link: "/support", disabled: false, adminOnly: false, ereAccess: false },
  ],
  "Account": [
    { page_title: "Account", page_link: "/account", disabled: false, adminOnly: false, ereAccess: false },
  ],
};

const MainHeader = ({ toggleTheme, theme } = {}) => {
  const navigate = useNavigate();
  let { pathname = "/login" } = useLocation();
  const { userData, handleLogout } = useUserData();
  let { passport: {
    user: {
      first_name = '', last_name = '', group_name = '', group_id = 6,
      ere_access: default_user_ere_access,
    } = {}
  } = {}, email = '', isAuthenticated = false } = userData || {};
  let { passport: { user: { user_id } = {} } = {} } = userData || {};
  (default_user_ere_access && typeof default_user_ere_access === 'string') && (default_user_ere_access = default_user_ere_access === 'true');
  (group_id && typeof group_id === 'string') && (group_id = Number(group_id));
  let hasEREAccess = !!default_user_ere_access;
  let AdminAccess = (isAuthenticated && [1, 2].includes(group_id)) || OVERRIDE_LOGIN;
  const ShowHeader = (isAuthenticated && pathname !== "/login") || OVERRIDE_LOGIN;
  let page = HeaderMenu.find(({ page_link = "#", page_title = '', ereAccess = false, adminOnly = false, groupOnly = false, disabled = false } = {}) => {
    return (page_title && !disabled &&
      ((ereAccess && hasEREAccess) || (adminOnly && AdminAccess)) &&
      (!groupOnly || groupOnly === group_id || OVERRIDE_LOGIN)) &&
      (Array.isArray(page_link) ? page_link.some((pg) => pathname === pg || pg.includes(":") && pathname.includes(pg.substring(0, pg.indexOf(':')))) :
        pathname === page_link)
  })?.page_title;
  if (!page) {
    page = Object.entries(HeaderSubMenu).reduce((acc, [menu, subMenu]) => {
      let found = subMenu.find(({ page_link = "#", page_title = '', ereAccess = false, adminOnly = false, groupOnly = false, disabled = false } = {}) => {
        return (page_title && !disabled && ((ereAccess && hasEREAccess) || (adminOnly && AdminAccess) || (!ereAccess && !adminOnly)) && (!groupOnly || groupOnly === group_id || OVERRIDE_LOGIN)) && pathname === page_link;
      });
      if (found && !acc) {
        acc = menu;
      }
      return acc;
    }, '');
  }

  let user_info = false;
  if (isAuthenticated) {
    if (first_name || last_name) {
      user_info = `${first_name}${last_name ? " " + last_name : ""}${group_name ? " (" + group_name + ")" : ""}`;
    } else if (email) {
      user_info = `${email}`;
    }
  }

  const [showDropDown, setShowDropDown] = useState(false);
  const showDropdown = (event) => {
    if (!event) return;
    setShowDropDown(true);
  }

  const hideDropdown = (event) => {
    if (!event) return;
    let { id } = event?.target || {};
    if (id === "collapsible-nav-dropdown") return; // This allows us to browse the items bellow the id without hiding the dropdown
    setShowDropDown(false);
  }

  const navToHome = () => {
    navigate("/");
  }

  return (
    ShowHeader ? (<header id="main-header" >
      <Navbar collapseOnSelect expand="lg" variant="light" className="header-main-nav">
        <Navbar.Brand onClick={navToHome}><span>ERE<span className="blue-text">DOCS</span></span></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {HeaderMenu.filter(({ ereAccess = false, adminOnly = false, groupOnly = false, disabled = false, page_title = '' }) => {
              return page_title && !disabled && ((ereAccess && hasEREAccess) || (adminOnly && AdminAccess) || (!ereAccess && !adminOnly)) && (!groupOnly || groupOnly === group_id || OVERRIDE_LOGIN);
            }).map(({ page_title, page_link }, index) => {
              (Array.isArray(page_link)) && (page_link = page_link[0]);
              return (<span key={`header-${page_title}-${index}`} className="header-nav-link">
                <Link to={page_link} className={`nav-link ${(pathname === page_link || page === page_title) ? "active" : ""}`}>{page_title}</Link>
              </span>)
            })}
          </Nav>
          <Nav>
            <NavDropdown title={user_info} id="collapsible-nav-dropdown" show={showDropDown} onMouseEnter={showDropdown} onMouseLeave={hideDropdown} >
              <Link to={`/user/${user_id}`} className="dropdown-item">My Profile</Link>
              {AdminAccess && (<Link to="/firm-management" className="dropdown-item">Firm Management</Link>)}
              <Link to="/support" className="dropdown-item">Support</Link>
              <NavDropdown.Divider />
              <Link to="#" className="dropdown-item" onClick={handleLogout} >Logout</Link>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <SubHeader HeaderMenu={HeaderMenu} HeaderSubMenu={HeaderSubMenu} pathname={pathname} hasEREAccess={hasEREAccess} AdminAccess={AdminAccess} group_id={group_id} OVERRIDE_LOGIN={OVERRIDE_LOGIN} />
    </header >) : null
  );
}

export default MainHeader;