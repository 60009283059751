import React, { useState, useEffect } from 'react';
import { Navigate } from "react-router-dom";
import { useUserData } from "../contexts/AuthContext";
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';
import ForgotPasswordModal from '../components/Modals/ForgotPasswordModal';
import { validParams } from "../utils/"

const Login = (props) => {
  const { userData, handleLogin, alertMessage } = useUserData();
  const [showAlert, setShowAlert] = useState(false);
  const [pageAlert, setPageAlert] = useState({
    message: "",
    alert_type: "info",
  });
  const [loggingIn, setLoggingIn] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formState, setFormState] = useState({
    email: '',
    password: ''
  });

  const handleFormChange = (event) => {
    if (!event?.target) {
      return;
    }
    const { name, value } = event.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
    formValidation({ [name]: value });
  };

  const formValidation = (currentInputField = {}) => {
    let alertObject = { message: "", alert_type: "info" };

    // Validate Form on change 
    let requiredFields = ['email', 'password']; // backend version is org_id, group_id, email, password but a default random password will be used when not provided 
    let testParams = Object.entries({ ...formState, ...currentInputField }).filter(([k, v]) => v).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
    let valid = validParams(testParams, requiredFields, 'required');
    !valid && (alertObject.message = "Please fill out all required fields. Missing " + requiredFields.filter((f) => !testParams[f]).join(', '));

    // Test email - Note, always checking this email after required fields ensures the [valid email address] message will be the first form validation message, 
    // until the email is correct, then required fields, then the password validation message.
    const email_regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,40})+$/;
    let testEmail = currentInputField?.email || formState.email;
    valid = email_regex.test(testEmail);
    !valid && (alertObject.message = "Please enter a valid email address.");

    // Test password
    if (valid) {
      let testPassword = currentInputField?.password || formState.password;
      valid = !!testPassword;
      !valid && (alertObject.message = "Please enter your password.");
    }

    setValidated(valid);
    setShowAlert(!valid);
    valid && (alertObject.message = "");
    if (alertObject.message) {
      alertObject.message = alertObject.message.trim();
      setPageAlert(prevState => ({ ...prevState, ...alertObject }));
    }
  };

  const handleLoginAttempt = async (event) => {
    if (!event) {
      return;
    }
    event.preventDefault();
    setLoggingIn(true);
    try {
      if (!validated) {
        throw new Error("Your form input is not valid. Please check your input and try again.");
      }
      await handleLogin(formState);
    } catch (err) {
      console.log(err);
    } finally {
      setLoggingIn(false);
    }
  };

  useEffect(() => {
    setShowAlert(!!alertMessage.message);
    setPageAlert(prevState => ({ ...prevState, ...alertMessage }));
  }, [alertMessage.message]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      (loggingIn) && setLoggingIn(false);

    }
    return () => mounted = false;
  }, [userData.isAuthenticated]);

  if (userData.isAuthenticated) {
    let group_id = userData?.passport?.user?.group_id || 6;
    let default_user_ere_access = userData?.passport?.user?.ere_access || false;
    (default_user_ere_access && typeof default_user_ere_access === 'string') && (default_user_ere_access = default_user_ere_access === 'true');
    let hasEREAccess = !!default_user_ere_access;
    let AdminAccess = [1, 2].includes(Number(group_id));
    let goTo = (!hasEREAccess && AdminAccess) ? "/firm-management" : userData.referrer || "/download-requests";
    return (<Navigate to={goTo} />);
  }

  return (
    <Row className="display-flex h-100" {...props}>
      <Col className="h-100 mx-auto p-0 d-flex flex-column justify-content-center login-section p-4">
        <Row className="">
          <Col>
            <h5 className="bold-text site-logo">ERE<span className="blue-text">DOCS</span></h5>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="">
            <h4 className="text-center mb-4">Portal Login</h4>

            <Form className="login-form mb-5" onSubmit={handleLoginAttempt}>
              <Form.Group className="mb-3" controlId="login-email">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  size="sm"
                  placeholder="Enter email"
                  name="email"
                  value={formState.email}
                  onChange={handleFormChange}
                  {...(loggingIn) && { disabled: true }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="login-password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  size="sm"
                  placeholder="Enter Password"
                  name="password"
                  value={formState.password}
                  onChange={handleFormChange}
                  {...(loggingIn) && { disabled: true }}
                />
              </Form.Group>

              <Alert dismissible onClose={() => setShowAlert(false)} show={showAlert} variant={pageAlert.alert_type} >{pageAlert.message}</Alert>

              <div className="center-text">
                <Button type="submit" className="primary-button shadow-sm mb-4" {...(loggingIn || !validated) && { disabled: true }}>{loggingIn ? "Signing in..." : "Sign In"}</Button>
                <ForgotPasswordModal />
                <span className="bold-text d-block">Don't have an account?</span>
                <a href="mailto:support@eredocs.com?subject=Contact Us - Register for a new account.">Contact Us</a> to get registered.
              </div>
            </Form>
          </Col>
        </Row>

      </Col>
      <Col md={8} className="login-page-right-content d-none d-md-block d-l-block d-xl-block"></Col>
    </Row>
  );
};

export default Login;