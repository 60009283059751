import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUserData } from '../../contexts/AuthContext';
import { Table, Alert, Row, Col } from 'react-bootstrap';
import { StyledSpan } from "../../styled/Span";
import AddBSOAccountModal from '../Modals/AddBSOAccountModal';
import Loader from "../Loader";
import { firm_management } from "../../utils/EREDocs"
import { humanize, dehumanize, massageData } from '../../utils/';
const defaultQueryParams = { limit: 25, showDebug: false };
let IntervalId = null;

const LawFirmBSOAccounts = ({ org_id } = {}) => {
  const navigate = useNavigate();
  const { userData } = useUserData();
  let { passport: {
    user: { org_id: default_org_id, auth_id: default_user_auth_id,
      selected_auth: { auth_id: default_auth_id } = {}
    } = {}
  } = {} } = userData || {};
  (!org_id && default_org_id) && (org_id = default_org_id);
  (!default_auth_id && default_user_auth_id) && (default_auth_id = default_user_auth_id);
  (typeof default_auth_id !== 'number') && (default_auth_id = Number(default_auth_id));
  (typeof org_id !== 'number') && (org_id = Number(org_id));

  let refresh_interval_seconds = 0;
  let skipFields = [];
  let dateFields = [];
  let renamedFields = {
    auth_id: "ID",
    attorney: "Attorney Name",
  };
  let tableFields = ['ID', 'Attorney Name', 'email', 'enabled'];

  const bsoAccountsRef = useRef();
  const [invokeUpdate, setInvokeUpdate] = useState(false);
  const [loadingBSOAccounts, setLoadingBSOAccounts] = useState(false);
  const [Pages, setPages] = useState([1]);
  const [activePage, setActivePage] = useState(1);
  const [tableHeader, setTableHeader] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [showTableAlert, setShowTableAlert] = useState(false);
  const [tableAlert, setTableAlert] = useState({ message: "", alert_type: "info" });

  // Extracts row items and save to rowObject and selectedItem
  const handleTableRowClick = (event) => {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation(); // Prevent other events from firing on click
    let clickedElement = event?.target || {};
    let { parentElement, dataset, tagName, type, innerText } = clickedElement;
    let action = false;

    // Go up one level in the DOM but preserve innerText
    if (tagName !== "TD" && parentElement?.tagName === "TD") {
      clickedElement = parentElement;
      parentElement = clickedElement.parentElement;
      dataset = { ...dataset, ...clickedElement?.dataset };
      tagName = clickedElement?.tagName;
      if (!innerText && clickedElement?.innerText) {
        innerText = clickedElement.innerText;
      }
    }

    let rowObject = {
      innerText,
      tagName,
      ...(type && { type }),
      ...dataset,
      ...parentElement?.dataset,
    };

    // Extract row items and save to rowObject and selectedItem
    let selectedItem = {};
    if (tagName === "TD") {
      let tdArray = Array.from(parentElement.querySelectorAll("td")).map(({ innerText }) => innerText).filter((i) => i) || [];
      let thArray = Array.from(parentElement.closest("table").querySelectorAll("thead tr th")).map(({ innerText }) => dehumanize(innerText)).filter((i) => i) || [];

      if ('col' in rowObject) { // Extract selectedItem
        let col = Number(rowObject.col);
        if (col && innerText === tdArray[col]) {
          selectedItem[thArray[col]] = tdArray[col];
        }
      }

      // Match thArray length with tdArray length
      if (tdArray.length > thArray.length) {
        // slice extra elements to match thArray length
        tdArray = tdArray.slice(0, thArray.length);
      } else if (tdArray.length < thArray.length) {
        // Fill in the blanks with null values to match thArray length
        let startIndex = tdArray.length;
        tdArray.length = thArray.length;
        tdArray.fill(null, startIndex);
      }

      if (tdArray.length === thArray.length) {
        for (let i = 0; i < thArray.length; i++) {
          rowObject[thArray[i]] = tdArray[i];
        }
      }
    }

    // Extract selectedItem if failed to do so above
    if ('field' in rowObject) {
      let field = dehumanize(rowObject.field); // We must dehumanize this field since that's what happened to all of values in thArray (see above)
      if (!selectedItem[field] && rowObject[field] === innerText) {
        selectedItem[field] = rowObject[field];
      }
    }

    // Object.keys(rowObject).length > 0 && console.log("handleTableRowClick", rowObject);
    // Object.keys(selectedItem).length > 0 && console.log("selectedItem", selectedItem);
    let { auth_id, id } = rowObject || {};
    (!auth_id && id) && (auth_id = id);
    (auth_id) && (action = "navigate");
    if (action) {
      if (["navigate"].includes(action)) {
        return navigate(`/bso-account-management-details/${auth_id}`);
      } else {
        console.log(`Unknown action: ${action}`);
      }
    }
    return;
  };

  const getTableData = async ({
    startedAt = Date.now(), headerFields = tableFields, renamed_fields = renamedFields, date_fields = dateFields, functionFields = skipFields,
    refresh_in_seconds = refresh_interval_seconds,
  } = {}) => {
    let now = Date.now();
    let duration_seconds = Math.round((now - startedAt) / 1000);
    let ran_count = !refresh_in_seconds ? 1 : Math.round(duration_seconds / refresh_in_seconds) || 1; // Avoid dividing by zero
    let first_run = now === startedAt || ran_count === 1;

    // Detect if user navigated away from this page 
    let offsetWidth = bsoAccountsRef?.current?.offsetWidth || 0;
    let offsetHeight = bsoAccountsRef?.current?.offsetHeight || 0;
    let navigated_away = !bsoAccountsRef?.current || !(offsetWidth > 0 && offsetHeight > 0);

    if (!first_run && (navigated_away || !isAuthenticated)) {
      console.log("Clearing Interval because user navigated away.", { IntervalId, first_run, navigated_away, isAuthenticated, offsetWidth, offsetHeight });
      clearInterval(IntervalId);
      return;
    }

    let alertObj = { ...tableAlert };
    let queryParams = {
      ...defaultQueryParams,
      ...(org_id && { org_id }),
      page: activePage
    };
    let response;

    try {
      setLoadingBSOAccounts(true);
      if (!queryParams?.org_id) {
        throw new Error("No org_id found in queryParams");
      }
      response = await firm_management.getLawFirmBSOAccounts(queryParams);
      alertObj.message = "";
    } catch (error) {
      console.log(error);
      response = error?.response || {};
      response.status = 500;
      response.statusText = `${error.message}`;
      if (!response?.data) {
        response.data = { err: 401, error: error.error, details: `${error.message}` };
      }
    } finally {
      setLoadingBSOAccounts(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    let { err, error, details, message, rows = [], count = 0, pageCount = 1, page, pages = [] } = data || {};

    if (status === 200) {
      let dataHeader = [...headerFields, ...functionFields];
      let dataRows = (!Array.isArray(rows) || rows.length === 0) ? [] : massageData(rows, dataHeader, renamed_fields, date_fields);
      if (rows.length === 0 || count === 0) {
        if (activePage > 1 && activePage === Pages[Pages.length - 1]) {
          setActivePage(Pages[Pages.length - 2]); // Make sure we don't get stuck loading nothing
        } else {
          alertObj.alert_type = "info";
          alertObj.message = "";
        }
      }

      pages = pages.reduce((acc, item, index) => {
        if (index === 0 && item.number !== 1) {
          acc.push(1); // Always include the first page 
        }
        acc.push(item.number);
        if (index === (pages.length - 1) && !acc.includes(pageCount)) {
          acc.push(pageCount); // Always include the last page
        }
        return acc;
      }, []);

      setTableHeader(dataHeader);
      setTableRows(dataRows);
      setPages(pages);
    } else {
      alertObj.alert_type = "danger";
      alertObj.message = `[${status ?? err}] ${statusText} `;    // API Error Code 
      alertObj.message += data?.status ? `${data.status} ` : ""; // API Status 
      alertObj.message += error ? `${error} ` : "";              // API Error 
      alertObj.message += (typeof response?.data === 'string') ? response.data : message ?? (details ? `${details} ` : "") + `Failed to read data.`;
      alertObj.message = alertObj.message.trim();
    }

    setShowTableAlert(!!alertObj.message);
    if (alertObj.message) {
      setTableAlert(prevState => ({ ...prevState, ...alertObj }));
    }
    if (first_run && refresh_in_seconds) {
      if (IntervalId !== null) {
        console.log("Clearing Interval because new interval is being started.", { IntervalId });
        clearInterval(IntervalId);
      }
      let interval_ms = refresh_in_seconds * 1000;
      IntervalId = setInterval(getTableData.bind(null, { startedAt, headerFields, renamed_fields, date_fields, functionFields, refresh_in_seconds }), interval_ms); // Next render every refresh_in_seconds seconds
      console.log("Set Interval!", { IntervalId, activePage, loadingLogs, startedAt });
    }

    // console.log("getTableData", { first_run, IntervalId, ran_count, duration_seconds, now, startedAt, navigated_away, isAuthenticated, offsetWidth, offsetHeight, status, statusText });
    return data;
  }; // END getTableData

  useEffect(() => {
    let mounted = true;
    const init = async () => {
      await getTableData();
    }
    if (mounted) init();
    return () => mounted = false;
  }, [activePage, org_id, invokeUpdate]);

  return (
    <div className="page-container" ref={bsoAccountsRef}>
      <Row>
        <Col>
          <h4 className="h-inline">BSO Accounts</h4>
        </Col>
        <Col>
          <AddBSOAccountModal className="d-flex justify-content-end" org_id={org_id} auth_id={0} setInvokeParentUpdate={setInvokeUpdate} />
        </Col>
      </Row>
      <Alert
        dismissible
        onClose={() => setShowTableAlert(false)}
        show={showTableAlert}
        variant={tableAlert.alert_type}
      >{tableAlert.message}</Alert>

      {loadingBSOAccounts ? (<Loader className="text-info" style={null} />) : (<Table hover>
        <thead>
          <tr>{tableHeader.filter((k) => !skipFields.includes(k)).map((header, index) => (
            <th key={`${index}-${header}`}>{(header.includes("_") || header.charAt(header.length - 1) === header.charAt(header.length - 1).toLowerCase()) ? (humanize(header)) : header}</th>))}
          </tr>
        </thead>
        <tbody>{Array.isArray(tableRows) && tableRows.length ? tableRows.map((row = {}, index) => {
          return (<tr
            key={`${index}-${row.ID}`}
            data-row={index}
            data-auth_id={row?.ID}
            onClick={handleTableRowClick}
          >{Object.entries(row).filter(([k, v]) => !skipFields.includes(k)).map(([key, value], idx) => (
            <td key={`${index}-${key}`} data-col={idx} data-field={key}>
              {(dateFields.includes(key)) ? value :
                (key === "enabled" ? (<StyledSpan data-value={value ? "enabled" : "inactive"}>{humanize(value)}</StyledSpan>) :
                  key === "email" ? `${value}`.toLowerCase() : humanize(value))}
            </td>
          ))}
          </tr>)
        }) : (<tr><td colSpan={tableHeader.filter((k) => !skipFields.includes(k)).length}><strong>No BSO Accounts found.</strong></td></tr>)}
        </tbody>
        {Pages.length > 1 && (<tfoot>
          <tr>
            <td className="text-right" colSpan={tableHeader.filter((k) => !skipFields.includes(k)).length + 1} >
              <nav>
                <ul className="pagination justify-content-end" data-active-page={activePage} >
                  {Pages && Pages.length > 0 && Pages.map((pageNumber, index) => (
                    <li key={`page-${pageNumber}-${index}`} className={`page-item ${pageNumber === activePage ? "disabled" : ""}`} >
                      <Link
                        to={`#page-${pageNumber}`}
                        className="page-link"
                        onClick={(e) => {
                          e.preventDefault();
                          setActivePage(pageNumber);
                        }}
                        {...(pageNumber === activePage) && { tabIndex: -1 }}
                      >{pageNumber}</Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </td>
          </tr>
        </tfoot>)}
      </Table>)}
    </div>
  );
}

export default LawFirmBSOAccounts;