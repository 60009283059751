import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { useUserData } from "../../contexts/AuthContext";
import { Table, Button, Row, Col, Form, Dropdown } from 'react-bootstrap';
import { StyledFormControl, StyledGreenCheckbox } from "../../styled/Forms";
import { StyledSpan } from "../../styled/Span";
import { StyledListNoBorder } from '../../styled/List';
import Loader from "../Loader";
import moment from "moment-timezone";
import { uploader } from "../../utils/EREDocs";
import { humanize, dehumanize, massageData, renameKeys, isObject, formatDate, splitArrayChunks, humanFileSize, handleTableRowClick, validParams } from "../../utils/";
import uploaderManagementStatus from '../../fake-data/uploaderManagementStatus.json';
const SECONDS_REFRESH_DETAILS_LOGS = Number(process.env.REACT_APP_SECONDS_REFRESH_LOGS || '5'); // Refresh logs every 5 seconds. Zero means no refresh.
const defaultQueryParams = { limit: 25, showDebug: false };
let IntervalId = null;

const ClaimantUploadRequests = ({
  auth_id, org_id, claimant_id, showFilter = true, showActions = false, limit = defaultQueryParams.limit, showDebug = defaultQueryParams.showDebug, showClaimantLinks = true, restricted = true, refresh_interval_seconds = SECONDS_REFRESH_DETAILS_LOGS,
  tableFields = [], renamedFields = {}, dateFields = [], formatStr = "MM/D/YYYY hh:mm:ss A", skipFields = [], special_fields = [], special_field_columns = [],
  showParentAlert = () => { }, setParentAlert = () => { }, parentInit = async () => { }, parentRef = null, ...props
} = {}) => {
  const { userData } = useUserData();
  let { isAuthenticated = false, passport: {
    user: { user_id, auth_id: user_auth_id, org_id: user_org_id, group_id = 6,
      selected_auth: { auth_id: default_auth_id } = {}
    } = {}
  } = {} } = userData || {};

  let superAdminAccess = (isAuthenticated && Number(group_id) === 1);
  let adminAccess = (isAuthenticated && Number(group_id) === 2);
  if (!restricted && !superAdminAccess && !adminAccess) {
    restricted = true;
  }

  // Set as numbers 
  (typeof default_auth_id !== 'number') && (default_auth_id = Number(default_auth_id));
  (typeof user_auth_id !== 'number') && (user_auth_id = Number(user_auth_id));
  (typeof user_org_id !== 'number') && (user_org_id = Number(user_org_id));

  // use defaults from session 
  (!auth_id && default_auth_id) && (auth_id = default_auth_id);
  (!auth_id && user_auth_id) && (auth_id = user_auth_id);
  (!org_id && user_org_id) && (org_id = user_org_id);

  // set auth_id and org_id as numbers 
  (auth_id && typeof auth_id !== 'number') && (auth_id = Number(auth_id));
  (org_id && typeof org_id !== 'number') && (org_id = Number(org_id));

  const defaultFormInputs = ['document_date'];
  const extraFormInputs = ['treatment_source', 'from_date', 'to_date'];
  const useExtraFormInputs = ["0001", "3157"]; // Will use extraFormInputs fields 

  if (!Array.isArray(skipFields) || skipFields.length === 0) {
    skipFields = ['claimant_id', 'document_type_id', 'total_file_size', 'status_details', 'firm', 'site_code', 'rqid', 'rf', 'dr', 'qid', 'doc_count', 'brq_ran_count', 'brq_priority', 'matter_id', 'can_process_requests', 'last_captured', ...defaultFormInputs, ...extraFormInputs]; // These fields are used by functions and should not be displayed
  }
  if (claimant_id && typeof claimant_id !== 'number') {
    claimant_id = Number(claimant_id);
  }
  // If no claimant_id passed in force filter to be true
  if (!claimant_id && !showFilter) {
    showFilter = true;
  }
  if (claimant_id && showClaimantLinks) {
    showClaimantLinks = false; // Don't show links if claimant_id is passed in
  } else if (!claimant_id && !showClaimantLinks) {
    showClaimantLinks = true;
  }

  // Set default fields if not passed in
  if (!isObject(renamedFields)) {
    renamedFields = {};
  }
  if (!Array.isArray(dateFields)) {
    dateFields = [];
  }
  if (!Array.isArray(tableFields) || tableFields.length === 0) {
    if (claimant_id) {
      tableFields = ['attorney', 'id', 'status', 'brq_id', 'brq_status', 'tracking_number', 'last_status_check', 'next_run', 'Requested At', 'Delivered At', 'Duration', 'upload_method', 'document_type', 'status_of_case', 'requested_by']
    } else {
      tableFields = ['attorney', 'id', 'status', 'brq_id', 'brq_status', 'tracking_number', 'last_status_check', 'next_run', 'Requested At', 'Delivered At', 'Duration', 'upload_method', 'claimant', 'document_type', 'status_of_case', 'requested_by']
    }
    renamedFields = {
      upload_request_id: 'id',
      bso_request_queue_id: 'brq_id',
      ...(!claimant_id && { claimant_name: 'claimant' }),
      submitted_timestamp: 'Requested At',
      successful_sftp_delivery: 'Delivered At',
    };
    ['last_status_check', 'next_run', 'Requested At', 'Delivered At'].forEach(field => {
      if (!dateFields.includes(field)) {
        dateFields.push(field);
      }
    });
  }

  const statusKeyMap = uploaderManagementStatus;
  const tableRef = useRef();
  const [invokeUpdate, setInvokeUpdate] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);

  const [dataCount, setDataCount] = useState(0);
  const [Pages, setPages] = useState([1]);
  const [activePage, setActivePage] = useState(1);
  const [tableHeader, setTableHeader] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [filterFormState, setFilterFormState] = useState({
    show_all: false,
    id: '',
    tracking_number: '',
    ...(!claimant_id && { claimant_id: '', claimant: '' }),
    ...(!restricted && {
      auth_id: '',
      attorney: ''
    }),
    document_type: '',
    file_name: '',
    status: '',
    brq_status: '',
    requested_at: '',
    end_date: '',
  });

  const [isValid, setIsValid] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [actionBitMask, setActionBitMask] = useState(15); // 0 - show no buttons
  const [dropdownBitMask, setDropdownBitMask] = useState(15); // 15 - show all buttons

  const handleInputChange = (event) => {
    if (!event?.target) return;
    event.stopPropagation(); // Prevent other events from firing on click

    let { name, value, type, checked = false } = event.target;
    if (type === 'checkbox') {
      value = checked;
    } else {
      event.preventDefault();
    }
    setFilterFormState((prevState) => ({
      ...prevState,
      [name]: value,
      ...(name === "show_all" && value ? {
        id: '',
        tracking_number: '',
        ...(!claimant_id && { claimant_id: '', claimant: '' }),
        ...(!restricted && {
          auth_id: '',
          attorney: ''
        }),
        document_type: '',
        file_name: '',
        status: '',
        brq_status: '',
        requested_at: '',
        end_date: '',
      } : { show_all: false }),
    }));

    if (name === 'show_all') {
      if (value && IntervalId !== null) {
        console.log("Clearing Interval because show all was checked.", { IntervalId });
        clearInterval(IntervalId);
      }
      onSubmit();
    }
    return;
  };

  const onSubmit = async (event) => {
    if (event) event.preventDefault() && event.stopPropagation();
    setActionBitMask(0);
    setDropdownBitMask(15);
    return setInvokeUpdate(prev => !prev);
  }

  const onReset = (event) => {
    if (event) event.preventDefault() && event.stopPropagation();
    setFilterFormState({
      show_all: false,
      id: '',
      tracking_number: '',
      ...(!claimant_id && { claimant_id: '', claimant: '' }),
      ...(!restricted && {
        auth_id: '',
        attorney: ''
      }),
      document_type: '',
      file_name: '',
      status: '',
      brq_status: '',
      requested_at: '',
      end_date: '',
    });
    setActionBitMask(0);
    setDropdownBitMask(15);
    return setInvokeUpdate(prev => !prev);
  };

  const handleBulkAction = async (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    let { target = {} } = event || {};
    let { innerText } = target || {};

    let action = "";
    if (`${innerText}`.toLowerCase().includes("mark")) {
      // Mark Confirmed or Fail
      action = "mark";
      if (`${innerText}`.toLowerCase().includes("confirmed")) {
        action += "_confirmed";
      } else if (`${innerText}`.toLowerCase().includes("failed")) {
        action += "_failed";
      }
    } else if (`${innerText}`.toLowerCase().includes("check")) {
      action = "check_status";
    } else if (`${innerText}`.toLowerCase().includes("deliver")) {
      action = "deliver_receipt";
    }

    // console.log("handleBulkAction", { innerText, action }, selectedRows);

    // Flatten selectedRows and create params 
    let neededParams = ['bso_request_queue_id', 'upload_request_id', 'tracking_number', 'qid', 'auth_id'];
    let params = selectedRows.reduce((acc, row) => {
      Object.entries(row).forEach(([key, value]) => {
        if (neededParams.includes(key)) {
          acc[key] = key in acc ? `${acc[key]}, ${value}` : value;
        }
      });
      return acc;
    }, { action });

    let alertObj = {
      message: "Processing...",
      type: "info",
    }
    let response;
    try {
      if (!isValid) {
        throw new Error(`Invalid Selection! ${tableAlert.message}`);
      }

      setLoadingTable(true);
      response = await uploader.uploaderBulkAction(params);
      alertObj.message = ""; // Reset alert when successful 
      setActionBitMask(0);
      setDropdownBitMask(15);
      setInvokeUpdate(prev => !prev);
    } catch (error) {
      console.log("Error on uploader.uploaderBulkAction with params", params);
      console.log(error);
      response = error?.response || {};
      response.status = 500;
      response.statusText = `${error.message}`;
      if (!response?.data) {
        response.data = { err: 500, error: error.error, details: `${error.message}` };
      }
    } finally {
      setLoadingTable(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    // console.log("handleBulkAction", { status, statusText, data });


    if (status !== 200) {
      alertObj.alert_type = "danger";
      alertObj.message = `${statusText}`;
    }

    showParentAlert(!!alertObj.message);
    if (!!alertObj.message) {
      alertObj.message = alertObj.message.trim();
      setParentAlert(prevState => ({ ...prevState, ...alertObj }));
    }

    return;
  };

  const handleSingleAction = async (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    let { target = {} } = event || {};
    let { name, value, checked, disabled = false, type, id, dataset, tagName, innerText, href, parentElement, className, title } = target;
    if (type !== 'checkbox') {
      event.preventDefault(); // Allows checkboxes to work as normal
    }

    let action = "";
    if (`${innerText}`.toLowerCase().includes("mark")) {
      // Mark Confirmed or Fail
      action = "mark";
      if (`${innerText}`.toLowerCase().includes("confirmed")) {
        action += "_confirmed";
      } else if (`${innerText}`.toLowerCase().includes("failed")) {
        action += "_failed";
      }
    } else if (`${innerText}`.toLowerCase().includes("check")) {
      action = "check_status";
    } else if (`${innerText}`.toLowerCase().includes("deliver")) {
      action = "deliver_receipt";
    }
    // console.log("handleSingleAction", { action, name, value, checked, disabled, type, id, dataset, tagName, innerText, href, parentElement, className, title });

    // Go up one level in the DOM but preserve innerText
    if (!['TD'].includes(tagName)) {
      if (parentElement?.tagName === "TD") {
        target = parentElement;
      } else {
        target = parentElement?.closest("td");
      }
      if (target) {
        parentElement = target?.parentElement;
        dataset = { ...dataset, ...target?.dataset };
        tagName = target?.tagName;
        if (!innerText && target?.innerText) {
          innerText = target?.innerText;
        }
      }
    }

    let rowObject = {
      innerText,
      tagName,
      ...(type && { type }),
      ...dataset,
      ...parentElement?.dataset,
    };

    // Extract row items and save to rowObject
    if (tagName === "TD") {
      // Select Resource 
      let requestTableElement = parentElement.closest("table") || document.querySelector("table");
      let thArray = Array.from(requestTableElement.querySelectorAll("thead tr th")).map(({ innerText }) => dehumanize(innerText)).slice(2); // remove 2 columns at beginning
      let tdArray = Array.from(parentElement.querySelectorAll("td")).map(({ innerText }) => innerText).slice(2); // remove 2 columns at beginning

      // Match thArray length with tdArray length
      if (tdArray.length > thArray.length) {
        // slice extra elements to match thArray length
        tdArray = tdArray.slice(0, thArray.length);
      } else if (tdArray.length < thArray.length) {
        // Fill in the blanks with null values to match thArray length
        let startIndex = tdArray.length;
        tdArray.length = thArray.length;
        tdArray.fill(null, startIndex);
      }

      if (tdArray.length === thArray.length) {
        for (let i = 0; i < thArray.length; i++) {
          rowObject[thArray[i]] = tdArray[i];
        }
      }
    }

    rowObject.action = action;
    // console.log(`action: ${action}`); 
    // Object.keys(rowObject).length > 0 && console.log("handleSingleAction rowObject", rowObject);

    let originalFieldNames = Object.entries(renamedFields).reduce((acc, [key, value]) => ({ ...acc, [dehumanize(value)]: key }), {});
    let neededParams = ['bso_request_queue_id', 'upload_request_id', 'tracking_number', 'qid', 'auth_id'];
    let params = Object.entries(rowObject).reduce((acc, [key, value]) => {
      if (key in originalFieldNames) {
        key = originalFieldNames[key];
      }
      if (neededParams.includes(key)) {
        acc[key] = key in acc ? `${acc[key]}, ${value}` : value;
      }
      return acc;
    }, { action });


    let alertObj = {
      message: "Processing...",
      type: "info",
    }
    let response;

    try {
      if (!isValid) {
        throw new Error(`Invalid Selection! ${tableAlert.message}`);
      }

      setLoadingTable(true);
      response = await uploader.uploaderBulkAction(params);
      alertObj.message = ""; // Reset alert when successful 
      setActionBitMask(0);
      setDropdownBitMask(15);
      setInvokeUpdate(prev => !prev);
    } catch (error) {
      console.log("Error on uploader.uploaderBulkAction with params", params);
      console.log(error);
      response = error?.response || {};
      response.status = 500;
      response.statusText = `${error.message}`;
      if (!response?.data) {
        response.data = { err: 500, error: error.error, details: `${error.message}` };
      }
    } finally {
      setLoadingTable(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    // console.log("handleSingleAction", { status, statusText, data });

    if (status !== 200) {
      alertObj.alert_type = "danger";
      alertObj.message = `${statusText}`;
    }

    showParentAlert(!!alertObj.message);
    if (!!alertObj.message) {
      alertObj.message = alertObj.message.trim();
      setParentAlert(prevState => ({ ...prevState, ...alertObj }));
    }
    return;
  };

  const rowClick = (rowObject = {}, selectedItem = {}) => {
    if (!showActions) {
      return;
    }
    // Object.keys(rowObject).length > 0 && console.log("rowClick", rowObject);
    // Object.keys(selectedItem).length > 0 && console.log("selectedItem", selectedItem);

    let error_count = 0;
    let alertOptions = {
      message: '',
      alert_type: "info"
    };
    let { type, name, tagName, col, row, checked } = rowObject || {};

    let action = false;
    if (type === "checkbox") {
      let nameArr = name.split("_");
      if (['select', 'all'].every((n) => nameArr.includes(n))) {
        action = name; // select_all_rows
      } else {
        action = "toggle_selected";
      }
    } else if (tagName === "TD") {
      action = "toggle_selected";
    }
    rowObject.action = action;
    // console.log(`action: ${action}`);

    let TableElement = tableRef?.current || document.querySelector("table");
    if (Number(col) === 1 && TableElement) {
      TableElement.querySelectorAll("tbody tr td div.dropdown").forEach(div => div.classList.remove("show"));
      TableElement.querySelectorAll("tbody tr td div.dropdown div.dropdown-menu").forEach(div => div.classList.remove("show"));
    }

    if (action) {
      if (['select', 'all'].every((n) => action.split("_").includes(n))) { // select_all_rows
        let { err_count = 0, err_message = '' } = updateSelectedRows(rowObject, !!checked);
        error_count += err_count;
        alertOptions.message += err_message;
        if (TableElement) {
          TableElement.querySelectorAll("tbody tr td input[type='checkbox']").forEach((el) => {
            el.checked = !!checked
          });
        }
      } else if (action === "toggle_selected") {
        // Toggle Selected 
        let updateResourceChecked = !!checked;
        row = Number(row);

        if (!isNaN(row) && TableElement) {
          if (type !== "checkbox") {
            TableElement.querySelectorAll("tbody tr td input[type='checkbox']").forEach((el, index) => {
              if (index === row) {
                el.checked = updateResourceChecked = !el.checked; // Toggle Checked State
              }
            });
          }

          // Check if all is selected and update "select all" checkbox 
          if (TableElement.querySelector(`thead tr th input[name='select_all_rows']`)) {
            let selectAllPreviousState = !!TableElement.querySelector(`thead tr th input[name='select_all_rows']`)?.checked;
            let selectAllNewState = Array.from(TableElement.querySelectorAll("tbody tr td input[type='checkbox']")).length === Array.from(TableElement.querySelectorAll("tbody tr td input[type='checkbox']"))
              .map((el) => el?.checked).filter(Boolean).length;

            if (selectAllPreviousState !== selectAllNewState) {
              TableElement.querySelector(`thead tr th input[name='select_all_rows']`).checked = updateResourceChecked = selectAllNewState;
            }
          }
        }

        let { err_count = 0, err_message = '' } = updateSelectedRows(rowObject, updateResourceChecked);
        error_count += err_count;
        alertOptions.message += err_message;
      } else {
        console.log("Unknown Action");
        error_count++;
        alertOptions.message = `Unknown Action ${action}`;
        alertOptions.alert_type = "info";
      }
    }
    else if (error_count === 0) {
      // Check if all is selected and update "select all" checkbox 
      if (TableElement.querySelector(`thead tr th input[name='select_all_rows']`)) {
        let selectAllPreviousState = !!TableElement.querySelector(`thead tr th input[name='select_all_rows']`)?.checked;
        let selectAllNewState = Array.from(TableElement.querySelectorAll("tbody tr td input[type='checkbox']")).length === Array.from(TableElement.querySelectorAll("tbody tr td input[type='checkbox']"))
          .map((el) => el?.checked).filter(Boolean).length;

        if (selectAllPreviousState !== selectAllNewState) {
          TableElement.querySelector(`thead tr th input[name='select_all_rows']`).checked = updateResourceChecked = selectAllNewState;
        }
      }
    }

    let valid = error_count === 0;
    setIsValid(valid);
    showParentAlert(!valid);
    if (!valid) {
      alertOptions.message = `${error_count} error${error_count > 1 ? 's' : ''}: ${alertOptions?.message}`;
      setParentAlert(prevState => ({ ...prevState, ...alertOptions }));
    }
    return;
  };

  const getTableData = async ({ startedAt = Date.now(), headerFields = tableFields, renamed_fields = renamedFields,
    date_fields = dateFields, format_str = formatStr, functionFields = skipFields, specialFields = special_fields, specialFieldColumns = special_field_columns, refresh_in_seconds = refresh_interval_seconds
  } = {}) => {

    if (filterFormState.show_all) {
      refresh_in_seconds = 0; // Disable interval when showing all records
    }

    let now = Date.now();
    let duration_seconds = Math.round((now - startedAt) / 1000);
    let brq_ran_count = !refresh_in_seconds ? 1 : Math.round(duration_seconds / refresh_in_seconds) || 1; // Avoid dividing by zero
    let first_run = now === startedAt || brq_ran_count === 1;

    // Detect if user navigated away from this page 
    let offsetWidth = parentRef?.current?.offsetWidth || 0;
    let offsetHeight = parentRef?.current?.offsetHeight || 0;
    let navigated_away = !parentRef?.current || !(offsetWidth > 0 && offsetHeight > 0);

    if (!first_run && (navigated_away || !isAuthenticated)) {
      console.log("Clearing Interval because user navigated away.", { IntervalId, first_run, navigated_away, isAuthenticated, offsetWidth, offsetHeight });
      clearInterval(IntervalId);
      return;
    }

    let alertObj = { message: "", alert_type: "info" };
    let originalFieldNames = Object.entries(renamedFields).reduce((acc, [key, value]) => ({ ...acc, [value]: key }), { file_name: "filename" });
    let filterValues = Object.entries(filterFormState).filter(([id, value]) => value); // Filter out empty values
    let filteredData = (filterValues.length === 0) ? {} : Object.entries(filterFormState).reduce((acc, [id, value]) => ({ ...acc, ...(value && { [id]: value }) }), {});
    filteredData = Object.keys(filteredData).length > 0 ? renameKeys(originalFieldNames, filteredData) : {};
    let params = {
      ...(restricted ? {
        ...(user_id && { user_id }),
        ...(auth_id && { auth_id }),
        ...(org_id && { org_id }),
      } : {
        restricted // set restricted to false otherwise it will be true by default 
      }),
      ...(claimant_id && { claimant_id }),
      ...filteredData,
      showDebug,
      limit,
      page: activePage,
    };
    let response;
    try {
      if (loadingTable) {
        throw new Error("Please wait for the table to finish loading.");
      }
      let anyFields = ['auth_id', 'org_id', 'user_id'];
      let testParams = Object.entries(params).filter(([k, v]) => v).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
      if (restricted && !validParams(testParams, anyFields, 'any')) {
        let error_message = "getTableData params is missing any of the following fields " + anyFields.filter((f) => !testParams[f]).join(', ');
        throw new Error(error_message);
      }

      setLoadingTable(true);
      if (claimant_id) {
        response = await uploader.getClaimantUploadRequests(params);
      } else {
        response = await uploader.getUploadRequests(params);
      }
      alertObj.message = "";
    } catch (error) {
      console.log(error);
      response = error?.response || {};
      response.status = 500;
      response.statusText = `${error.message}`;
      if (!response?.data) {
        response.data = { err: 401, error: error.error, details: `${error.message}` };
      }
    } finally {
      setLoadingTable(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    let { err, error, details, message, rows = [], count = 0, pageCount = 1, page, pages = [] } = data || {};

    if (status === 200) {
      let dataHeader = [...headerFields, ...functionFields];
      let dataRows = (!Array.isArray(rows) || rows.length === 0) ? [] : massageData(rows, dataHeader, renamed_fields, date_fields, specialFields, specialFieldColumns, format_str);
      if (rows.length === 0 || count === 0) {
        if (activePage > 1 && activePage === Pages[Pages.length - 1]) {
          setActivePage(Pages[Pages.length - 2]); // Make sure we don't get stuck loading nothing
        } else {
          alertObj.alert_type = "info";
          alertObj.message = "";
        }
      }

      pages = pages.reduce((acc, item, index) => {
        if (index === 0 && item.number !== 1) {
          acc.push(1); // Always include the first page 
        }
        acc.push(item.number);
        if (index === (pages.length - 1) && !acc.includes(pageCount)) {
          acc.push(pageCount); // Always include the last page
        }
        return acc;
      }, []);

      setTableHeader(dataHeader);
      setTableRows(dataRows);
      setPages(pages);
      setDataCount(count);
    } else {
      alertObj.alert_type = "danger";
      alertObj.message = `[${status ?? err}] ${statusText} `;    // API Error Code 
      alertObj.message += data?.status ? `${data.status} ` : ""; // API Status 
      alertObj.message += error ? `${error} ` : "";              // API Error 
      alertObj.message += (typeof response?.data === 'string') ? response.data : message ?? (details ? `${details} ` : "") + `Failed to read data.`;
      alertObj.message = alertObj.message.trim();
    }

    showParentAlert(!!alertObj.message);
    if (alertObj.message) {
      setParentAlert(prevState => ({ ...prevState, ...alertObj }));
    }
    if (first_run && refresh_in_seconds) {
      if (IntervalId !== null) {
        console.log("Clearing Interval because new interval is being started.", { IntervalId });
        clearInterval(IntervalId);
      }
      let interval_ms = refresh_in_seconds * 1000;
      IntervalId = setInterval(getTableData.bind(null, { startedAt, headerFields, renamed_fields, date_fields, functionFields, refresh_in_seconds }), interval_ms); // Next render every refresh_in_seconds seconds
      console.log("Set Interval!", { IntervalId, activePage, loadingTable, startedAt });
    }

    // console.log("getTableData", { first_run, IntervalId, brq_ran_count, duration_seconds, now, startedAt, navigated_away, isAuthenticated, offsetWidth, offsetHeight, status, statusText });
    return data;
  }; // END getTableData


  // ===============[ updateSelectedRows ]==================================
  const updateSelectedRows = (params = {}, checked = true) => {
    let { action, col, row, id } = params;
    // console.log(`updateSelectedRows [${checked}]`, params);

    if (checked && IntervalId !== null) {
      console.log("Clearing Interval because row was selected.", { IntervalId });
      clearInterval(IntervalId);
    }

    if (filterFormState.show_all) {
      setFilterFormState((prevState) => ({ ...prevState, show_all: false }));
    }

    let err_count = 0;
    let err_message = '';
    let actionAccumulator = 0;
    const FIFTEEN_MIN_MS = 15 * 60 * 1000;

    const actionValidation = ({ status = null, delivered_at = null, qid = null, brq_ran_count = 0 } = {}) => {
      let bitMaskValue = 2; // At least allow [Check Status] no matter what 
      if (["SENT", "CONFIRMED", "FAILED", "FAILED_CONFIRMED"].includes(status)) {
        bitMaskValue = bitMaskValue | 1; // Show button [Deliver Receipt JSON]
      }

      if (["PROCESSING", "SENT", "QUEUED"].includes(status)) {
        bitMaskValue = bitMaskValue | 2; // Show button [Check Status]
      }

      if (delivered_at) {
        bitMaskValue = bitMaskValue | 8; // Show button [Mark as Confirmed]
      } else if (status) {
        // Fail or Confirmed
        if (["FAILED"].includes(status) || brq_ran_count > 3) {
          bitMaskValue = bitMaskValue | 4; // Show button [Mark as Fail]
        } else if (["SENT", "CONFIRMED"].includes(status)) {
          bitMaskValue = bitMaskValue | 8; // Show button [Mark as Confirmed]
        }
      }

      return bitMaskValue;
    };

    // Search for the target resource in the requestObject all_resources and update resources
    setSelectedRows((prevState) => {
      let newState = [...prevState];
      let allRows = tableRows || [];
      let originalFieldNames = Object.entries(renamedFields).reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {});

      if (action === "select_all_rows") {
        if (checked) {
          newState = allRows.map((r) => {
            // Use original field names found in our database 
            originalFieldNames = { ...Object.entries(r).reduce((acc, [key, value]) => ({ ...acc, [key]: dehumanize(key) }), {}), ...originalFieldNames };
            let _row = renameKeys(originalFieldNames, r);
            let { submitted_timestamp = formatDate(), upload_request_id, delivered_at = null, status = null, qid = null, brq_ran_count = 0 } = _row || {};
            if (typeof brq_ran_count !== "number") {
              brq_ran_count = Number(brq_ran_count);
            }

            // Overall Validation
            if (!submitted_timestamp || moment(Date.now() - FIFTEEN_MIN_MS).isAfter(new Date(submitted_timestamp)) === false) { // Make sure row is older than 15 minutes 
              err_count++;
              err_message += `upload_request_id: ${upload_request_id} Requested At ${submitted_timestamp} which is not older than 15 minutes`;
            }

            // Action Validation
            actionAccumulator = actionAccumulator | actionValidation({ delivered_at, status, qid, brq_ran_count });
            return _row;
          });
        } else {
          newState = [];
        }
      } else {
        // Handle Single selection
        if (checked) {
          allRows.forEach((r = {}) => {
            if (r[renamedFields['upload_request_id']] === Number(id)) {
              originalFieldNames = { ...Object.entries(r).reduce((acc, [key, value]) => ({ ...acc, [key]: dehumanize(key) }), {}), ...originalFieldNames };
              let _row = renameKeys(originalFieldNames, r);
              let { submitted_timestamp = formatDate(), upload_request_id, delivered_at = null, status = null, qid = null, brq_ran_count = 0 } = _row || {};
              if (typeof brq_ran_count !== "number") {
                brq_ran_count = Number(brq_ran_count);
              }

              // Only add if not already in the array
              if (!newState.find(({ upload_request_id } = {}) => upload_request_id === Number(id))) {
                // Overall Validation
                if (!submitted_timestamp || moment(Date.now() - FIFTEEN_MIN_MS).isAfter(new Date(submitted_timestamp)) === false) { // Make sure row is older than 15 minutes 
                  err_count++;
                  err_message += `upload_request_id: ${upload_request_id} Requested At ${submitted_timestamp} which is not older than 15 minutes`;
                }

                // Action Validation Button 
                actionAccumulator = actionAccumulator | actionValidation({ delivered_at, status, qid, brq_ran_count });
                newState.push(_row);
              }
            }
          });
        } else {
          newState = newState.filter(({ upload_request_id } = {}) => upload_request_id !== Number(id));
        }
      }

      if (newState.length === 0) {
        setActionBitMask(0);
        setDropdownBitMask(15);
        setInvokeUpdate(prev => !prev);
      } else {
        setActionBitMask(prevState => prevState | actionAccumulator);

        if (Number(col) === 1) {
          setDropdownBitMask(actionAccumulator);
        }
      }

      return newState;
    }); // End setSelectedResources

    return { err_count, err_message };
  } // End updateSelectedRows

  useEffect(() => {
    let mounted = true;
    const init = async () => {
      if (typeof parentInit === 'function') {
        await parentInit();
      }
    }
    if (mounted) init();
    return () => mounted = false;
  }, []);

  useEffect(() => {
    let mounted = true;
    const init = async () => {
      setSelectedRows([]);
      await getTableData();

    }
    if (mounted) init();
    return () => mounted = false;
  }, [invokeUpdate, auth_id, claimant_id, activePage]);

  return (
    <div {...props}>
      {!showFilter && (<h4>Upload Requests</h4>)}

      {(selectedRows.length > 1) ? (<div className="bulk-actions mb-3">
        {((actionBitMask & 8) === 8) && (<Button
          className="me-3"
          size="md"
          variant="primary"
          onClick={handleBulkAction}
          disabled={loadingTable}
        >{isValid ? "✔" : "✘"} Mark {selectedRows.length === tableRows.length ? "All" : selectedRows.length} as Confirmed</Button>)}
        {((actionBitMask & 4) === 4) && (<Button
          className="me-3"
          size="md"
          variant="primary"
          onClick={handleBulkAction}
          disabled={loadingTable}
        >{isValid ? "✔" : "✘"} Mark {selectedRows.length === tableRows.length ? "All" : selectedRows.length} as Failed</Button>)}
        {((actionBitMask & 2) === 2) && (<Button
          className="me-3"
          size="md"
          variant="primary"
          onClick={handleBulkAction}
          disabled={loadingTable}
        >{isValid ? "✔" : "✘"} Check {selectedRows.length === tableRows.length ? "All" : selectedRows.length} Status</Button>)}
        {((actionBitMask & 1) === 1) && (<Button
          className="me-3"
          size="md"
          variant="primary"
          onClick={handleBulkAction}
          disabled={loadingTable}
        >{isValid ? "✔" : "✘"} Deliver {selectedRows.length === tableRows.length ? "All" : selectedRows.length} Receipt JSON</Button>)}
      </div>)
        : showFilter && (<Form noValidate onSubmit={onSubmit} onReset={onReset} autoComplete="off" className="mb-3">
          {splitArrayChunks(Object.entries(filterFormState).filter(([k, v]) => !['show_all', ...(restricted ? ['auth_id', 'attorney'] : [])].includes(k)), 12)
            .map((chunk, idx, arr) => (<Row key={`chunk-${idx}`}>
              <Col xs={12} md={12} lg={12} className="mb-3 d-flex justify-content-start ps-0">
                {chunk.map(([key, value], index) => (<Form.Group key={`${idx}-${index}-${key}`} controlId={key} className="mb-3 me-3">
                  <Form.Label>{humanize(key)}</Form.Label>
                  {['show_all', 'status'].some((f) => `${key}`.toLowerCase().includes(f)) ? (<Form.Select size="sm" name={key} value={value} onChange={handleInputChange} >
                    <option value="">--</option>
                    {key in statusKeyMap && Array.isArray(statusKeyMap[key]) && statusKeyMap[key].length > 0 && statusKeyMap[key].map(({ status, label, css_class = "text-info" } = {}, idx) => (
                      <option
                        key={`${status}-${idx}`}
                        value={status}
                        {...(css_class && { className: css_class })}
                      >
                        {label ?? humanize(status)}
                      </option>
                    ))}
                  </Form.Select>) : (<StyledFormControl
                    key={`${key}-${index}`}
                    name={key}
                    value={value}
                    size="sm"
                    type={['requested_at', 'delivered_at', 'end_date'].includes(key) ? "date" : "text"}
                    data-index={index}
                    className="w-auto"
                    onChange={handleInputChange}
                  />)}
                </Form.Group>))}
              </Col>
            </Row>))}

          <Row>
            <Col className="ps-2">
              <StyledListNoBorder>
                <StyledGreenCheckbox className="list-group-item">
                  <Form.Control
                    name="show_all"
                    type="checkbox"
                    title="Show All"
                    className="me-2"
                    checked={filterFormState.show_all}
                    onChange={handleInputChange}
                  />
                  <span className="ms-2">Show All</span>
                </StyledGreenCheckbox>
              </StyledListNoBorder>
            </Col>
            <Col xs="2" sm="2" md="2" lg="2" className="d-flex align-items-end justify-content-end">
              <Button className="btn btn-primary me-3" type="submit" name="search" {...(loadingTable) && { disabled: true }}>Search</Button>
              <Button className="btn btn-secondary" type="reset" name="clear" {...(loadingTable) && { disabled: true }}>Clear</Button>
            </Col>
          </Row>
        </Form>)}

      {loadingTable ? (<Loader className="text-info" style={null} />) : (<Table hover ref={tableRef}>
        <thead>
          <tr>
            {showActions && Array.isArray(tableRows) && tableRows.length > 0 && (<th key="0-select_all">
              <StyledGreenCheckbox>
                <Form.Control
                  key="select_all_rows"
                  name="select_all_rows"
                  className="me-2"
                  type="checkbox"
                  title="Select All Rows"
                  defaultChecked={false}
                  onChange={(e) => (handleTableRowClick.call(null, e, rowClick))}
                />
              </StyledGreenCheckbox>
            </th>)}
            {showActions && (<th {...((tableRows || []).length === 0) && { colSpan: "2" }}>Count {dataCount}</th>)}
            {tableHeader.filter((k) => !skipFields.includes(k)).map((header, index) => (
              <th key={`${index}-${header}`}>{(header.includes("_") || header.charAt(header.length - 1) === header.charAt(header.length - 1).toLowerCase()) ? (humanize(header)) : header}</th>))}
          </tr>
        </thead>
        <tbody>{Array.isArray(tableRows) && tableRows.length ? tableRows.map((row = {}, tr_index) => {
          let titleData = "";
          if (row?.auth_id) {
            titleData += `auth_id=${row?.auth_id}\n`;
          }
          if (row?.status_details) {
            titleData += `status_details=${row?.status_details}\n`;
          }
          if (useExtraFormInputs.includes(row?.document_type_id)) {
            (!titleData.endsWith(" ") && !titleData.endsWith("\n")) && (titleData += " ");
            titleData += `treatment_source=${row?.treatment_source} from_date=${row?.from_date} to_date=${row?.to_date}`;
          } else {
            (!titleData.endsWith(" ") && !titleData.endsWith("\n")) && (titleData += " ");
            titleData += `document_date=${row?.document_date}`;
          }
          if (row?.notes) {
            (!titleData.endsWith(" ") && !titleData.endsWith("\n")) && (titleData += " ");
            titleData += `notes=${row?.notes} `;
          }
          if (row?.total_file_size) {
            (!titleData.endsWith(" ") && !titleData.endsWith("\n")) && (titleData += " ");
            titleData += `total_file_size=${humanFileSize(row?.total_file_size)}`;
          }
          if (row?.qid) {
            (!titleData.endsWith(" ") && !titleData.endsWith("\n")) && (titleData += " ");
            titleData += `qid=${row.qid}`;
          }
          if (row?.rqid) {
            (!titleData.endsWith(" ") && !titleData.endsWith("\n")) && (titleData += "\n");
            titleData += `rqid=${row.rqid}`;
          }
          if (row?.site_code) {
            (!titleData.endsWith(" ") && !titleData.endsWith("\n")) && (titleData += " ");
            titleData += `site_code=${row.site_code}`;
          }
          if (row?.rf) {
            (!titleData.endsWith(" ") && !titleData.endsWith("\n")) && (titleData += " ");
            titleData += `rf=${row.rf}`;
          }
          if (row?.dr) {
            (!titleData.endsWith(" ") && !titleData.endsWith("\n")) && (titleData += " ");
            titleData += `dr=${row.dr}`;
          }
          if (row?.doc_count) {
            (!titleData.endsWith(" ") && !titleData.endsWith("\n")) && (titleData += "\n");
            titleData += `doc_count=${row.doc_count}`;
          }
          if (row?.brq_priority) {
            (!titleData.endsWith(" ") && !titleData.endsWith("\n")) && (titleData += " ");
            titleData += `brq_priority=${row.brq_priority}`;
          }
          if (row?.brq_ran_count) {
            (!titleData.endsWith(" ") && !titleData.endsWith("\n")) && (titleData += " ");
            titleData += `brq_ran_count=${row.brq_ran_count}`;
          }

          const FIFTEEN_MIN_MS = 15 * 60 * 1000;
          let className = "Requested At" in row && row["Requested At"] && moment(Date.now() - FIFTEEN_MIN_MS).isAfter(new Date(row["Requested At"])) ? (row?.can_process_requests ? "" : "table-secondary") : "light-blue";

          return (<tr
            key={`${tr_index}-${row.key}`}
            data-row={tr_index}
            data-upload_request_id={row?.ID}
            data-claimant_id={row?.claimant_id}
            data-auth_id={row?.auth_id}
            data-total_file_size={row?.total_file_size}
            data-brq_ran_count={row?.brq_ran_count}
            data-treatment_source={row?.treatment_source}
            data-from_date={row?.from_date}
            data-to_date={row?.to_date}
            data-document_date={row?.document_date}
            data-document_type_id={row?.document_type_id}
            data-status_details={row?.status_details}
            data-qid={row?.qid}
            data-rqid={row?.rqid}
            data-site_code={row?.site_code}
            data-rf={row?.rf}
            data-dr={row?.dr}
            {...(className) && { className }}
            {...(titleData) && { title: titleData }}
            onClick={(e) => (handleTableRowClick.call(null, e, rowClick))}
          >
            {showActions && <td key="0-checkbox" width={45} data-col={0}>
              <StyledGreenCheckbox>
                <Form.Control
                  className="me-2"
                  type="checkbox"
                  name={`row_${tr_index}`}
                  title={`Select ${tr_index}`}
                  defaultChecked={false}
                />
              </StyledGreenCheckbox>
            </td>}
            {showActions && <td data-col={1}>
              <Dropdown onClick={(e) => (handleTableRowClick.call(null, e, rowClick))} >
                <Dropdown.Toggle className="simple-menu-button" variant="primary" id="dropdown-basic">&#x22EE;</Dropdown.Toggle>
                <Dropdown.Menu>
                  {((dropdownBitMask & 8) === 8) && (<Dropdown.Item onClick={handleSingleAction}>{isValid ? "✔" : "✘"} Mark as Confirmed</Dropdown.Item>)}
                  {((dropdownBitMask & 4) === 4) && (<Dropdown.Item onClick={handleSingleAction}>{isValid ? "✔" : "✘"} Mark as Failed</Dropdown.Item>)}
                  {((dropdownBitMask & 2) === 2) && (<Dropdown.Item onClick={handleSingleAction}>{isValid ? "✔" : "✘"} Check Status</Dropdown.Item>)}
                  {((dropdownBitMask & 1) === 1) && (<Dropdown.Item onClick={handleSingleAction}>{isValid ? "✔" : "✘"} Deliver Receipt JSON</Dropdown.Item>)}
                </Dropdown.Menu>
              </Dropdown>
            </td>}

            {Object.entries(row).filter(([k, v]) => !skipFields.includes(k)).map(([key, value], td_index) => {
              let title = "";
              if (key === "attorney") {
                title = `${row?.firm} auth_id=${row?.auth_id}`;
              }

              let innerText = "";
              if (['tracking_number', ...dateFields].includes(key)) {
                innerText = dateFields.includes(key) ? (value || "").replace(moment(Date.now()).format("MM/D/YYYY "), "") : value;
              } else if (['status', 'status_of_case', 'brq_status'].includes(key)) {
                innerText = <StyledSpan>{humanize(value)}</StyledSpan>;
              } else if (key === "email" && value) {
                innerText = `${value}`.toLowerCase();
              } else if (key === "filename" && value) {
                innerText = value.split(/[\\\/]/).pop();
              } else if (key === "document_type" && value) {
                innerText = value.split(";").join(" - ");
              } else if (key === "claimant" && showClaimantLinks) {
                let titleString = `claimant_id=${row.claimant_id} matter_id=${row.matter_id}`;
                if (row?.last_captured) {
                  titleString += ` last_captured=${formatDate(row.last_captured)}`;
                }
                innerText = <Link to={`/claimant-details/${row.claimant_id}`} title={titleString} onClick={(e) => e.stopPropagation()}>{humanize(value)}</Link>
              } else if (key === "attorney" && row?.auth_id) {
                innerText = <Link to={`/bso-account-management-details/${row?.auth_id}`} onClick={(e) => e.stopPropagation()}>{humanize(value)}</Link>
              } else if (key === "destination" && value) {
                innerText = value.split(",").join(" ");
              } else if (key === "upload_method" && value) {
                let labelMap = {
                  ef: "Electronic Folder",
                  indr: "Individual Response"
                }
                innerText = humanize(labelMap[value] || value);
              } else {
                innerText = humanize(value);
              }
              return (<td key={`${tr_index}-${key}`} data-col={td_index + (showActions ? 2 : 0)} data-field={key} {...(title && { title })}>{innerText}</td>);
            })}
          </tr>)
        }) : (<tr><td colSpan={tableHeader.filter((k) => !skipFields.includes(k)).length + 2}><strong>No Upload Requests found.</strong></td></tr>)}
        </tbody>
        {Pages.length > 1 && (<tfoot>
          <tr>
            <td className="text-right" colSpan={tableHeader.filter((k) => !skipFields.includes(k)).length + 2} >
              <nav>
                <ul className="pagination justify-content-end" data-active-page={activePage} >
                  {Pages && Pages.length > 0 && Pages.map((pageNumber, index) => (
                    <li key={`page-${pageNumber}-${index}`} className={`page-item ${pageNumber === activePage ? "disabled" : ""}`} >
                      <Link
                        to={`#page-${pageNumber}`}
                        className="page-link"
                        onClick={(e) => {
                          e.preventDefault();
                          setActivePage(pageNumber);
                        }}
                        {...(pageNumber === activePage) && { tabIndex: -1 }}
                      >{pageNumber}</Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </td>
          </tr>
        </tfoot>)}
      </Table>)
      }
    </div>
  );
}

export default ClaimantUploadRequests;