import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom"
import { useUserData } from "../contexts/AuthContext";
import { Form, Col, Row, Button, Table, Alert, Dropdown } from 'react-bootstrap';
import moment from "moment-timezone";
import { StyledListNoBorder } from '../styled/List';
import { StyledSpan } from '../styled/Span';
import { StyledGreenCheckbox, StyledFormControl } from '../styled/Forms';
import Loader from "../components/Loader";
import { request_queue } from "../utils/EREDocs";
import { massageData, humanize, dehumanize, renameKeys, formatDate, splitArrayChunksAlt } from "../utils";
import downloaderManagementStatus from '../fake-data/downloaderManagementStatus.json';
const defaultQueryParams = { limit: 50, navSize: 5, showLog: false, returnPagination: true };
let IntervalId = null;

const DownloadQueue = ({ showClaimantLinks = true } = {}) => {
  const { userData } = useUserData();
  let { passport: { user: { org_id, user_id, email: user_email, group_id = 6 } = {} } = {}, isAuthenticated = false } = userData || {};
  (typeof org_id !== 'number') && (org_id = Number(org_id));
  // let superAdminAccess = (isAuthenticated && Number(group_id) === 1);
  let refresh_interval_seconds = Number(process.env.REACT_APP_SECONDS_REFRESH_AUDIT_LOGS || '30');
  let skipFields = ['message', 'firm_id', 'firm', 'prefix', 'attorney_id', 'claimant_id', 'matter_id', 'exhibited_count', 'unexhibited_count', 'media_count', 'request_id', 'delivery_status_details', 'duration', 'document_title', 'can_process_requests', 'last_captured'];
  let dateFields = ['bso_requested_at', 'bso_requested_at', 'downloaded_at', 'sftp_delivered_at', 'received_at'];
  let renamedFields = {};
  let tableFields = [
    'queue_id',
    'social_id',
    'attorney',
    'last_four_ssn',
    'claimant',
    'queue_status',
    'social_status',
    'document_status',
    'document_type',
    'downloaded_status',
    'delivery_status',
    'received_at',
    'bso_requested_at',
    'downloaded_at',
    'sftp_delivered_at',
    'total_time_alive',
  ];

  const statusKeyMap = downloaderManagementStatus;
  const downloadQueueRef = useRef();
  const [invokeUpdate, setInvokeUpdate] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);

  const [dataCount, setDataCount] = useState(0);
  const [Pages, setPages] = useState([1]);
  const [activePage, setActivePage] = useState(1);
  const [tableHeader, setTableHeader] = useState([]);
  const [tableRows, setTableRows] = useState([]);

  const [showTableAlert, setShowTableAlert] = useState(false);
  const [tableAlert, setTableAlert] = useState({ message: "", alert_type: "info" });

  const [filterFormState, setFilterFormState] = useState({
    show_all: false,
    last_four_ssn: '',
    claimant: '',
    claimant_id: '',
    queue_id: '',
    social_id: '',
    firm_id: '',
    attorney_id: '',
    matter_id: '',
    queue_status: 'PENDING',
    received_at: '',
    can_process_requests: true,
  });

  const [isValid, setIsValid] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleFormChange = (event) => {
    if (!event?.target) return;
    let { name, value, type, checked = false } = event.target;
    if (type === 'checkbox') {
      value = checked;
    }

    setFilterFormState((prevState) => ({
      ...prevState,
      ...(name === 'show_all' ? {
        [name]: value,
        ...(value && {
          queue_status: '',
        })
      } : {
        [name]: value,
        show_all: false
      })
    }));

    if (name === 'show_all') {
      if (value && IntervalId !== null) {
        console.log("Clearing Interval because show all was checked.", { IntervalId });
        clearInterval(IntervalId);
      }
      searchRequests();
    }
  };

  const searchRequests = async (event) => {
    if (event) event.preventDefault() && event.stopPropagation();
    return setInvokeUpdate(prev => !prev);
  };

  const clearFilters = () => {
    setFilterFormState({
      show_all: false,
      last_four_ssn: '',
      claimant: '',
      claimant_id: '',
      queue_id: '',
      social_id: '',
      firm_id: '',
      attorney_id: '',
      matter_id: '',
      queue_status: 'PENDING',
      received_at: '',
      can_process_requests: true,
    });
    return setInvokeUpdate(prev => !prev);
  };

  const handleSingleAction = async (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    let { target = {} } = event || {};
    let { name, value, checked, disabled = false, type, id, dataset, tagName, innerText, href, parentElement, className, title } = target;
    if (type !== 'checkbox') {
      event.preventDefault(); // Allows checkboxes to work as normal
    }

    let action = "";
    if (`${innerText}`.toLowerCase().includes("restart")) {
      action = "restart";
    } else if (`${innerText}`.toLowerCase().includes("cancel")) {
      action = "cancel";
    }
    // console.log("handleSingleAction", { action, name, value, checked, disabled, type, id, dataset, tagName, innerText, href, parentElement, className, title });

    // Go up one level in the DOM but preserve innerText
    if (!['TD'].includes(tagName)) {
      if (parentElement?.tagName === "TD") {
        target = parentElement;
      } else {
        target = parentElement?.closest("td");
      }
      if (target) {
        parentElement = target?.parentElement;
        dataset = { ...dataset, ...target?.dataset };
        tagName = target?.tagName;
        if (!innerText && target?.innerText) {
          innerText = target?.innerText;
        }
      }
    }

    let rowObject = {
      innerText,
      tagName,
      ...(type && { type }),
      ...dataset,
      ...parentElement?.dataset,
    };

    // Extract row items and save to rowObject
    if (tagName === "TD") {
      // Select Resource 
      let requestTableElement = parentElement.closest("table") || document.querySelector("table");
      let thArray = Array.from(requestTableElement.querySelectorAll("thead tr th")).map(({ innerText }) => dehumanize(innerText)).slice(2); // remove 2 columns at beginning
      let tdArray = Array.from(parentElement.querySelectorAll("td")).map(({ innerText }) => innerText).slice(2); // remove 2 columns at beginning

      // Match thArray length with tdArray length
      if (tdArray.length > thArray.length) {
        // slice extra elements to match thArray length
        tdArray = tdArray.slice(0, thArray.length);
      } else if (tdArray.length < thArray.length) {
        // Fill in the blanks with null values to match thArray length
        let startIndex = tdArray.length;
        tdArray.length = thArray.length;
        tdArray.fill(null, startIndex);
      }

      if (tdArray.length === thArray.length) {
        for (let i = 0; i < thArray.length; i++) {
          rowObject[thArray[i]] = tdArray[i];
        }
      }
    }

    rowObject.action = action;
    // console.log(`action: ${action}`); 
    // Object.keys(rowObject).length > 0 && console.log("handleSingleAction rowObject", rowObject);

    let originalFieldNames = Object.entries(renamedFields).reduce((acc, [key, value]) => ({ ...acc, [dehumanize(value)]: key }), {});
    const aliasParams = {
      queue_id: 'request_queue_id',
      attorney_id: 'auth_id',
    };
    let neededParams = ['queue_id', 'attorney_id'];
    let params = Object.entries(rowObject).reduce((acc, [key, value]) => {
      if (key in originalFieldNames) {
        key = originalFieldNames[key];
      }
      if (neededParams.includes(key)) {
        key = aliasParams[key] || key;
        acc[key] = key in acc ? `${acc[key]}, ${value}` : value;
      }
      return acc;
    }, { action });

    let alertObj = {
      message: "Processing...",
      alert_type: "info",
    }
    let response;

    try {
      if (!isValid) {
        throw new Error(`Invalid Selection! ${tableAlert.message}`);
      }

      setLoadingTable(true);
      response = await request_queue.downloadQueueBulkAction(params);
      alertObj.message = ""; // Reset alert when successful 
      setInvokeUpdate(prev => !prev);
    } catch (error) {
      console.log("Error on request_queue.downloadQueueBulkAction with params", params);
      console.log(error);
      response = error?.response || {};
      response.status = 500;
      response.statusText = `${error.message}`;
      if (!response?.data) {
        response.data = { err: 500, error: error.error, details: `${error.message}` };
      }
    } finally {
      setLoadingTable(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    let { message = '', success = false } = data || {};
    (!message) && (message = `[${status}] ${statusText}`);
    // console.log("handleSingleAction Results", { status, statusText, data });

    alertObj.message = message;
    if (status !== 200 || !success) {
      alertObj.alert_type = "danger";
    }

    showParentAlert(!!alertObj.message);
    if (!!alertObj.message) {
      alertObj.message = alertObj.message.trim();
      setParentAlert(prevState => ({ ...prevState, ...alertObj }));
    }
    return;
  };

  const handleBulkAction = async (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    let { target: { innerText = '' } = {} } = event || {};

    let action = "";
    if (`${innerText}`.toLowerCase().includes("restart")) {
      action = "restart";
    } else if (`${innerText}`.toLowerCase().includes("cancel")) {
      action = "cancel";
    }

    // console.log("handleBulkAction", { innerText, action }, selectedRows);

    const aliasParams = {
      queue_id: 'request_queue_id',
      attorney_id: 'auth_id',
    };
    let neededParams = ['queue_id', 'attorney_id'];
    let params = selectedRows.reduce((acc, row) => {
      Object.entries(row).forEach(([key, value]) => {
        if (neededParams.includes(key)) {
          key = aliasParams[key] || key;
          acc[key] = key in acc ? `${acc[key]}, ${value}` : value;
        }
      });
      return acc;
    }, { action });

    let alertObj = {
      message: "Processing...",
      alert_type: "info",
    };
    let response;

    try {
      if (!isValid) {
        throw new Error(`Invalid Selection! ${tableAlert.message}`);
      }

      setLoadingTable(true);
      response = await request_queue.downloadQueueBulkAction(params);
      alertObj.message = ""; // Reset alert when successful 
      setInvokeUpdate(prev => !prev);
    } catch (error) {
      console.log("Error on request_queue.downloadQueueBulkAction with params", params);
      console.log(error);
      response = error?.response || {};
      response.status = 500;
      response.statusText = `${error.message}`;
      if (!response?.data) {
        response.data = { err: 500, error: error.error, details: `${error.message}` };
      }
    } finally {
      setLoadingTable(false);
    }

    let { status = 500, statusText = '', data = {} } = response || {};
    let { message = '', success = false } = data || {};
    (!message) && (message = `[${status}] ${statusText}`);
    // console.log("downloadQueueBulkAction Results", { status, statusText, data });

    alertObj.message = message;
    if (status !== 200 || !success) {
      alertObj.alert_type = "danger";
    }

    setShowTableAlert(!!alertObj.message);
    if (!!alertObj.message) {
      alertObj.message = alertObj.message.trim();
      setTableAlert(prevState => ({ ...prevState, ...alertObj }));
    }
    return;
  }

  // Extracts row items 
  const handleTableRowClick = async (event) => {
    if (!event?.target) return;
    event.stopPropagation(); // Prevent other events from firing on click

    let error_count = 0;
    let alertOptions = {
      message: '',
      alert_type: "info"
    };

    let { target = {} } = event || {};
    let { name, value, checked, disabled = false, type, id, dataset, tagName, innerText, href, parentElement, className, title } = target;
    if (type !== 'checkbox') {
      event.preventDefault(); // Allows checkboxes to work as normal
    }

    // Go up one level in the DOM but preserve innerText
    if (!['TD'].includes(tagName)) {
      if (parentElement?.tagName === "TD") {
        target = parentElement;
      } else {
        target = parentElement?.closest("td");
      }
      if (target) {
        parentElement = target?.parentElement;
        dataset = { ...dataset, ...target?.dataset };
        tagName = target?.tagName;
        if (!innerText && target?.innerText) {
          innerText = target?.innerText;
        }
      }
    }

    // Set action if needed 
    let action = false;
    if (type === "checkbox") {
      let nameArr = name.split("_");
      if (['select', 'all'].every((n) => nameArr.includes(n))) {
        action = name; // select_all_rows
      } else {
        action = "toggle_selected";
      }
    } else if (tagName === "TD") {
      action = "toggle_selected";
    }

    let rowObject = {
      innerText,
      tagName,
      ...(type && { type }),
      ...dataset,
      ...parentElement?.dataset,
    };

    // Extract row items and save to rowObject and selectedItem
    let selectedItem = {};
    if (tagName === "TD") {
      // Select Resource 
      let requestTableElement = parentElement.closest("table") || document.querySelector("table");
      let thArray = Array.from(requestTableElement.querySelectorAll("thead tr th")).map(({ innerText }) => dehumanize(innerText)).slice(2); // remove 2 columns at beginning
      let tdArray = Array.from(parentElement.querySelectorAll("td")).map(({ innerText }) => innerText).slice(2); // remove 2 columns at beginning

      if ('col' in rowObject) { // Extract selectedItem
        let col = Number(rowObject.col);
        if (col && innerText === tdArray[col]) {
          selectedItem[thArray[col]] = tdArray[col];
        }
      }

      // Match thArray length with tdArray length
      if (tdArray.length > thArray.length) {
        // slice extra elements to match thArray length
        tdArray = tdArray.slice(0, thArray.length);
      } else if (tdArray.length < thArray.length) {
        // Fill in the blanks with null values to match thArray length
        let startIndex = tdArray.length;
        tdArray.length = thArray.length;
        tdArray.fill(null, startIndex);
      }

      if (tdArray.length === thArray.length) {
        for (let i = 0; i < thArray.length; i++) {
          rowObject[thArray[i]] = tdArray[i];
        }
      }
    }

    rowObject.action = action;
    // console.log(`action: ${action}`);
    // Object.keys(rowObject).length > 0 && console.log("handleTableRowClick rowObject", rowObject);
    // Object.keys(selectedItem).length > 0 && console.log("selectedItem", selectedItem);

    let { row, col } = rowObject;
    let requestTableElement = parentElement.closest("table") || document.querySelector("table");
    if (Number(col) === 1 && requestTableElement) {
      requestTableElement.querySelectorAll("tbody tr td div.dropdown").forEach(div => div.classList.remove("show"));
      requestTableElement.querySelectorAll("tbody tr td div.dropdown div.dropdown-menu").forEach(div => div.classList.remove("show"));
    }

    if (action) {
      if (['select', 'all'].every((n) => action.split("_").includes(n))) { // select_all_rows
        let { err_count = 0, err_message = '' } = updateSelectedRows(rowObject, !!checked);
        error_count += err_count;
        alertOptions.message += err_message;
        if (requestTableElement) {
          requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']").forEach((el) => {
            el.checked = !!checked
          });
        }
      } else if (action === "toggle_selected") {
        // Toggle Selected 
        let updateResourceChecked = !!checked;
        row = Number(row);

        if (!isNaN(row) && requestTableElement) {
          if (type !== "checkbox") {
            requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']").forEach((el, index) => {
              if (index === row) {
                el.checked = updateResourceChecked = !el.checked; // Toggle Checked State
              }
            });
          }

          // Check if all is selected and update "select all" checkbox 
          if (requestTableElement.querySelector(`thead tr th input[name='select_all_rows']`)) {
            let selectAllPreviousState = !!requestTableElement.querySelector(`thead tr th input[name='select_all_rows']`)?.checked;
            let selectAllNewState = Array.from(requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']")).length === Array.from(requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']"))
              .map((el) => el?.checked).filter(Boolean).length;

            if (selectAllPreviousState !== selectAllNewState) {
              requestTableElement.querySelector(`thead tr th input[name='select_all_rows']`).checked = updateResourceChecked = selectAllNewState;
            }
          }
        }

        let { err_count = 0, err_message = '' } = updateSelectedRows(rowObject, updateResourceChecked);
        error_count += err_count;
        alertOptions.message += err_message;
      } else {
        console.log("Unknown Action");
        error_count++;
        alertOptions.message = `Unknown Action ${action}`;
        alertOptions.alert_type = "info";
      }
    }
    else if (error_count === 0) {
      // Check if all is selected and update "select all" checkbox 
      if (requestTableElement.querySelector(`thead tr th input[name='select_all_rows']`)) {
        let selectAllPreviousState = !!requestTableElement.querySelector(`thead tr th input[name='select_all_rows']`)?.checked;
        let selectAllNewState = Array.from(requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']")).length === Array.from(requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']"))
          .map((el) => el?.checked).filter(Boolean).length;

        if (selectAllPreviousState !== selectAllNewState) {
          requestTableElement.querySelector(`thead tr th input[name='select_all_rows']`).checked = updateResourceChecked = selectAllNewState;
        }
      }
    }

    let valid = error_count === 0;
    setIsValid(valid);
    setShowTableAlert(!valid);
    if (!valid) {
      alertOptions.message = `${error_count} error${error_count > 1 ? 's' : ''}: ${alertOptions?.message}`;
      setTableAlert(prevState => ({ ...prevState, ...alertOptions }));
    }
    return;
  }; // END handleTableRowClick

  const getTableData = async ({
    startedAt = Date.now(), headerFields = tableFields, renamed_fields = renamedFields, date_fields = dateFields, functionFields = skipFields,
    refresh_in_seconds = refresh_interval_seconds,
  } = {}) => {
    if (filterFormState.show_all) {
      refresh_in_seconds = 0; // Disable interval when showing all records
    }

    let now = Date.now();
    let duration_seconds = Math.round((now - startedAt) / 1000);
    let ran_count = !refresh_in_seconds ? 1 : Math.round(duration_seconds / refresh_in_seconds) || 1; // Avoid dividing by zero
    let first_run = now === startedAt || ran_count === 1;

    // Detect if user navigated away from this page 
    let offsetWidth = downloadQueueRef?.current?.offsetWidth || 0;
    let offsetHeight = downloadQueueRef?.current?.offsetHeight || 0;
    let navigated_away = !downloadQueueRef?.current || !(offsetWidth > 0 && offsetHeight > 0);

    if (!first_run && (navigated_away || !isAuthenticated)) {
      console.log("Clearing Interval because user navigated away.", { IntervalId, first_run, navigated_away, isAuthenticated, offsetWidth, offsetHeight });
      clearInterval(IntervalId);
      return;
    }

    const aliasParams = {
      queue_id: 'request_queue_id',
      social_id: 'request_social_id',
      attorney_id: 'auth_id',
      matter_id: 'external_claimant_id',
      queue_status: 'status',
    };
    let alertObj = { ...tableAlert };
    let filterValues = Object.entries(filterFormState).filter(([id, value]) => value); // Filter out falsy values
    let filteredData = (filterValues.length === 0) ? {} : Object.entries(filterFormState).reduce((acc, [id, value]) => ({ ...acc, ...(value && renameKeys(aliasParams, { [id]: value })) }), {});
    let queryParams = {
      ...defaultQueryParams,
      ...filteredData,
      page: activePage
    };

    let response;
    try {
      setLoadingTable(true);
      response = await request_queue.getDownloadQueue(queryParams);
      alertObj.message = ""; // Reset alert when successful 
    } catch (error) {
      console.log(error);
      response = error?.response || {};
      response.status = 500;
      response.statusText = `${error.message}`;
      if (!response?.data) {
        response.data = { err: 401, error: error.error, details: `${error.message}` };
      }
    } finally {
      setLoadingTable(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    let { err, error, details, message, rows = [], count = 0, pageCount = 1, page, pages = [] } = data || {};

    if (status === 200) {
      let dataHeader = [...new Set([...headerFields, ...functionFields])];
      let dataRows = (!Array.isArray(rows) || rows.length === 0) ? [] : massageData(rows, dataHeader, renamed_fields, date_fields, [], [], "MM/D/YYYY hh:mm:ss A");
      if (rows.length === 0 || count === 0) {
        if (activePage > 1 && activePage === Pages[Pages.length - 1]) {
          setActivePage(Pages[Pages.length - 2]); // Make sure we don't get stuck loading nothing
        } else {
          alertObj.alert_type = "info";
          alertObj.message = "No downloads found.";
        }
      }

      pages = pages.reduce((acc, pageNumber, index) => {
        if (index === 0 && pageNumber !== 1) {
          acc.push(1); // Always include the first page 
        }
        acc.push(pageNumber);
        if (index === (pages.length - 1) && !acc.includes(pageCount)) {
          acc.push(pageCount); // Always include the last page
        }
        return acc;
      }, []);

      setTableHeader(dataHeader);
      setTableRows(dataRows);
      setPages(pages);
      setDataCount(count);
    } else {
      alertObj.alert_type = "danger";

      if (typeof response?.data === 'string') {
        alertObj.message = response.data;
        alertObj.message += ` (${statusText}) `;
      } else {
        alertObj.message = `[${status ?? err}] ${statusText} `;    // API Error Code 
        alertObj.message += data?.status ? `${data.status} ` : ""; // API Status 
        alertObj.message += message ?? (details ? `${details} ` : "") + `Failed to read data.`;
      }
      alertObj.message += error ? `${error} ` : "";                // API Error 
    }

    setShowTableAlert(!!alertObj.message);
    if (!!alertObj.message) {
      alertObj.message = alertObj.message.trim();
      setTableAlert(prevState => ({ ...prevState, ...alertObj }));
    }
    if (first_run && refresh_in_seconds) {
      if (IntervalId !== null) {
        console.log("Clearing Interval because new interval is being started.", { IntervalId });
        clearInterval(IntervalId);
      }
      let interval_ms = refresh_in_seconds * 1000;
      IntervalId = setInterval(getTableData.bind(null, { startedAt, headerFields, renamed_fields, date_fields, functionFields, refresh_in_seconds }), interval_ms); // Next render every refresh_in_seconds seconds
      console.log("Set Interval!", { IntervalId, activePage, loadingTable, startedAt });
    }

    // console.log("getTableData", { first_run, IntervalId, ran_count, duration_seconds, now, startedAt, navigated_away, isAuthenticated, offsetWidth, offsetHeight, status, statusText });
    return data;
  }; // END getTableData

  // ===============[ updateSelectedRows ]==================================
  const updateSelectedRows = (params = {}, checked = true) => {
    let { action, col, row, queue_id: qid, claimant_id, last_four_ssn } = params;
    // console.log(`updateSelectedRows [${checked}] ${action}`, params);

    (col && typeof col === 'string') && (col = Number(col));
    (row && typeof row === 'string') && (row = Number(row));
    (qid && typeof qid === 'string') && (qid = Number(qid));
    (claimant_id && typeof claimant_id === 'string') && (claimant_id = Number(claimant_id));
    (last_four_ssn && typeof last_four_ssn === 'string') && (last_four_ssn = Number(last_four_ssn));

    if (checked && IntervalId !== null) {
      console.log("Clearing Interval because row was selected.", { IntervalId });
      clearInterval(IntervalId);
    }

    if (filterFormState.show_all) {
      setFilterFormState((prevState) => ({ ...prevState, show_all: false }));
    }
    let err_count = 0;
    let err_message = '';
    const FIFTEEN_MIN_MS = 15 * 60 * 1000;

    setSelectedRows((prevState) => {
      let newState = [...prevState];
      let allRows = tableRows || [];
      let originalFieldNames = Object.entries(renamedFields).reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {});

      if (action === "select_all_rows") {
        if (checked) {
          newState = allRows.map((r) => {
            // Use original field names found in our database 
            originalFieldNames = { ...Object.entries(r).reduce((acc, [key, value]) => ({ ...acc, [key]: dehumanize(key) }), {}), ...originalFieldNames };
            let _row = renameKeys(originalFieldNames, r);
            let { received_at = formatDate(), queue_id, queue_status = null, exhibited_count = null, unexhibited_count = null, media_count = null } = _row || {};
            (queue_id && typeof queue_id === 'string') && (_row.queue_id = queue_id = Number(queue_id));

            // Overall Validation
            if (!received_at || moment(Date.now() - FIFTEEN_MIN_MS).isAfter(new Date(received_at)) === false) { // Make sure row is older than 15 minutes 
              err_count++;
              err_message += `queue_id: ${queue_id} Received At ${received_at} which is not older than 15 minutes`;
            }

            return _row;
          });
        } else {
          newState = [];
        }
      } else {
        // Handle Single selection
        if (checked) {
          allRows.forEach((r = {}) => {
            if (r?.queue_id === qid) {
              originalFieldNames = { ...Object.entries(r).reduce((acc, [key, value]) => ({ ...acc, [key]: dehumanize(key) }), {}), ...originalFieldNames };
              let _row = renameKeys(originalFieldNames, r);
              let { received_at = formatDate(), queue_id, queue_status = null, exhibited_count = null, unexhibited_count = null, media_count = null } = _row || {};
              (queue_id && typeof queue_id === 'string') && (_row.queue_id = queue_id = Number(queue_id));

              // Only add if not already in the array
              if (!newState.find((s = {}) => s.queue_id === qid)) {
                // Overall Validation
                if (!received_at || moment(Date.now() - FIFTEEN_MIN_MS).isAfter(new Date(received_at)) === false) { // Make sure row is older than 15 minutes 
                  err_count++;
                  err_message += `queue_id: ${queue_id} Received At ${received_at}  which is not older than 15 minutes`;
                }

                newState.push(_row);
              }
            }
          });
        } else {
          newState = newState.filter((s = {}) => s.queue_id !== qid);
        }
      }
      if (newState.length === 0) {
        setInvokeUpdate(prev => !prev);
      }

      return newState;
    }); // End setSelectedResources

    return { err_count, err_message };
  } // End updateSelectedRows

  useEffect(() => {
    let mounted = true;
    const init = async () => {
      setSelectedRows([]);
      await getTableData();
    }
    if (mounted) init();
    return () => mounted = false;
  }, [invokeUpdate, activePage, org_id]);

  return (
    <div className="page-container" ref={downloadQueueRef}>
      <h4>Download Queue</h4>
      <hr />
      <Alert
        dismissible
        onClose={() => setShowTableAlert(false)}
        show={showTableAlert}
        variant={tableAlert.alert_type}
      >{tableAlert.message}</Alert>

      {(selectedRows.length > 0) ? (<div className="bulk-actions mb-3">
        <Button className="me-3" size="md" variant="primary" onClick={handleBulkAction} name="restart" {...(loadingTable) && { disabled: true }} >
          {isValid ? "✔" : "✘"} Restart {selectedRows.length === tableRows.length ? "All" : (selectedRows.length === 1 ? "QID: " + selectedRows[0].queue_id : selectedRows.length)}
        </Button>
        <Button className="me-3" size="md" variant="primary" onClick={handleBulkAction} name="cancel" {...(loadingTable) && { disabled: true }} >
          {isValid ? "✔" : "✘"} Cancel {selectedRows.length === tableRows.length ? "All" : (selectedRows.length === 1 ? "QID: " + selectedRows[0].queue_id : selectedRows.length)}
        </Button>
      </div>) : (<div className="filter-section">
        <Form onSubmit={searchRequests} onReset={clearFilters} autoComplete="off" className="mb-3">
          {splitArrayChunksAlt(Object.entries(filterFormState).filter(([k, v]) => !['show_all'].includes(k)), 12, 14)
            .map((chunk, idx, arr) => (<Row key={`chunk-${idx}`}>
              <Col xs={12} md={12} lg={12} className="mb-3 d-flex justify-content-start ps-0">
                {chunk.map(([key, value], index) => (<Form.Group controlId={key} className="me-2 mt-2" key={`${idx}-${index}-${key}`}>
                  {key !== "can_process_requests" && (<Form.Label>{humanize(key)}</Form.Label>)}
                  {['job_type', 'zip_type'].includes(key) || `${key}`.toLowerCase().includes("status") ? (<Form.Select size="sm" name={key} value={value} onChange={handleFormChange} >
                    <option value="">--</option>
                    {key in statusKeyMap && Array.isArray(statusKeyMap[key]) && statusKeyMap[key].length > 0 && statusKeyMap[key].map(({ status, label, css_class = "text-primary" } = {}, idx) => (
                      <option
                        key={`${status}-${idx}`}
                        value={status}
                        {...(css_class && { className: css_class })}
                      >
                        {label ?? humanize(status)}
                      </option>
                    ))}
                  </Form.Select>) : key === "can_process_requests" ? (<StyledListNoBorder key={`${key}-${index}`}><StyledGreenCheckbox className="list-group-item">
                    <Form.Control
                      name={key}
                      type="checkbox"
                      className="me-2"
                      checked={value}
                      onChange={handleFormChange}
                    />
                    <span className="ms-2">{humanize(key)}</span>
                  </StyledGreenCheckbox></StyledListNoBorder>) : (<StyledFormControl
                    key={`${key}-${index}`}
                    name={key}
                    value={value}
                    size="sm"
                    type={dateFields.includes(key) ? "date" : "text"}
                    data-index={index}
                    className="w-auto"
                    onChange={handleFormChange}
                  />)}
                </Form.Group>))}
              </Col>
            </Row>))}

          <Row className="pt-3">
            <Col className="ps-2">
              <StyledListNoBorder>
                <StyledGreenCheckbox className="list-group-item">
                  <Form.Control
                    name="show_all"
                    type="checkbox"
                    title="Show All"
                    className="me-2"
                    checked={filterFormState.show_all}
                    onChange={handleFormChange}
                  />
                  <span className="ms-2">Show All</span>
                </StyledGreenCheckbox>
              </StyledListNoBorder>
            </Col>
            <Col className="d-flex justify-content-end">
              <Button className="primary-button" type="submit" name="apply" {...(loadingTable) && { disabled: true }} >Apply Filters</Button>
              <Button className="remove-button ms-3" type="reset" name="clear" {...(loadingTable) && { disabled: true }} >Clear Filters</Button>
            </Col>
          </Row>
        </Form>
      </div>)}

      {loadingTable ? (<Loader className="text-info" style={null} />) : (<Table hover>
        <thead>
          <tr>
            {Array.isArray(tableRows) && tableRows.length > 0 && (<th key="0-select_all">
              <StyledGreenCheckbox>
                <Form.Control
                  key="select_all_rows"
                  name="select_all_rows"
                  className="me-2"
                  type="checkbox"
                  title="Select All Rows"
                  defaultChecked={false}
                  onChange={handleTableRowClick}
                />
              </StyledGreenCheckbox>
            </th>)}
            <th {...((tableRows || []).length === 0) && { colSpan: "2" }}>Count {dataCount}</th>
            {tableHeader.filter((k) => !skipFields.includes(k)).map((header, index, arr) => (
              <th key={`${index}-${header}`}>{(header.includes("_") || header.charAt(header.length - 1) === header.charAt(header.length - 1).toLowerCase()) ? (humanize(header)) : header}</th>))
            }
          </tr>
        </thead>
        <tbody>{Array.isArray(tableRows) && tableRows.length > 0 ? tableRows.map((row = {}, tr_index) => {
          let title = skipFields.reduce((a, k) => (k in row && row[k] ? `${a} ${k === 'message' ? "" : k + ": "}${row[k]}, ` : a), "").trim().slice(0, -1);
          const FIFTEEN_MIN_MS = 15 * 60 * 1000;
          let className = "received_at" in row && row["received_at"] && moment(Date.now() - FIFTEEN_MIN_MS).isAfter(new Date(row["received_at"])) ? (row?.can_process_requests ? "" : "table-secondary") : "light-blue";

          return (<tr
            key={`${tr_index}`}
            data-row={tr_index}
            data-claimant_id={row.claimant_id}
            data-matter_id={row.matter_id}
            data-request_queue_id={row.request_queue_id}
            data-request_id={row.request_id}
            data-request_social_id={row.request_social_id}
            data-qid={row.qid}
            data-auth_id={row.auth_id}
            onClick={handleTableRowClick}
            {...(className) && { className }}
            {...(title) && { title }}
          >
            <td key="0-checkbox" width={45} data-col={0}>
              <StyledGreenCheckbox>
                <Form.Control
                  className="me-2"
                  type="checkbox"
                  name={`row_${tr_index}`}
                  title={`Select ${tr_index}`}
                  defaultChecked={false}
                />
              </StyledGreenCheckbox>
            </td>
            <td data-col={1}>
              <Dropdown onClick={handleTableRowClick} >
                <Dropdown.Toggle className="simple-menu-button" variant="primary" id="dropdown-basic">&#x22EE;</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleSingleAction}>{isValid ? "✔" : "✘"} Restart</Dropdown.Item>
                  <Dropdown.Item onClick={handleSingleAction}>{isValid ? "✔" : "✘"} Cancel</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
            {Object.entries(row).filter(([k, v]) => !skipFields.includes(k)).map(([key, value], td_index) => (<td
              key={`${td_index + 2}-${key}`}
              data-col={`${td_index + 2}`}
              {...(key === "Attorney" && row?.firm) && { title: row.firm }}
            >
              {showClaimantLinks && key === "claimant"
                ? <Link to={`/claimant-details/${row.claimant_id}`} title={`claimant_id=${row.claimant_id} matter_id=${row.matter_id} ssn=${row?.ssn?.slice(-4)} last_captured=${row?.last_captured ? formatDate(row.last_captured) : "null"}`} onClick={(e) => e.stopPropagation()}>{humanize(value)}</Link>
                : dateFields.includes(key)
                  ? (value || "").replace(moment(Date.now()).format("MM/D/YYYY "), "")
                  : (["job type", "status"].some((f) => key.toLowerCase().includes(f))
                    ? (<StyledSpan data-value={value}>{humanize(value)}</StyledSpan>)
                    : key === "attorney" && (row?.auth_id || row?.attorney_id)
                      ? <Link to={`/bso-account-management-details/${row?.auth_id || row?.attorney_id}`} title={`auth_id=${row?.auth_id || row?.attorney_id}`} onClick={(e) => e.stopPropagation()}>{humanize(value)}</Link>
                      : humanize(value, key === "prefix"))
              }
            </td>))}
          </tr>)
        }) : (<tr><td colSpan={tableHeader.filter((k) => !skipFields.includes(k)).length + 2}><strong>No downloads found.</strong></td></tr>)}
        </tbody>
        {Pages.length > 1 && (<tfoot>
          <tr>
            <td className="text-right" colSpan={tableHeader.filter((k) => !skipFields.includes(k)).length + 2} >
              <nav>
                <ul className="pagination justify-content-end" data-active-page={activePage} >
                  {Pages && Pages.length > 0 && Pages.map((pageNumber, index) => (
                    <li key={`page-${pageNumber}-${index}`} className={`page-item ${pageNumber === activePage ? "disabled" : ""}`} >
                      <Link
                        to={`#page-${pageNumber}`}
                        className="page-link"
                        onClick={(e) => {
                          e.preventDefault();
                          setActivePage(pageNumber);
                        }}
                        {...(pageNumber === activePage) && { tabIndex: -1 }}
                      >{pageNumber}</Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </td>
          </tr>
        </tfoot>)}
      </Table>)}
    </div>
  );
}

export default DownloadQueue;