import styled from "styled-components";
import { Table } from 'react-bootstrap';

export const StyledRequestTable = styled(Table)`
  & > thead > tr > th:last-child,
  & > tbody > tr > td:last-child {
    text-align: right;
  }
`;

export const StyledCustomDownloadTable = styled(Table)`
  & > thead > tr {
    background-color: var(--light-grey);
  }
`;
