import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useUserData } from "../contexts/AuthContext";

// Bootstrap Components
import { Alert, Row, Col, ListGroup, Button } from 'react-bootstrap';
import { StyledListNoBorder } from "../styled/List"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import 'react-tabs/style/react-tabs.css';

// Components 
import ClaimantDownloadRequests from '../components/Tables/ClaimantDownloadRequests';
import ClaimantUploadRequests from '../components/Tables/ClaimantUploadRequests';
import ClaimantResources from '../components/Tables/ClaimantResources';
import ClaimantEFolder from '../components/ClaimantEFolder';
import Loader from "../components/Loader";

// EREDocs Functions 
import { downloader } from '../utils/EREDocs';
import { isObject, humanize, splitArrayChunks, massageData } from '../utils/'

// Globals 
const OVERRIDE_LOGIN = process.env.REACT_APP_OVERRIDE_LOGIN === "true";
const defaultQueryParams = { showDebug: false, decryptSSN: false, scrapeClaimant: false, deliverClaimantResults: false, includeResources: true };

const ClaimantDetails = (props = {}) => {
  let { claimant_id } = useParams();
  const { userData } = useUserData();
  let { passport: {
    user: {
      group_id = 6, auth_id, user_id,
      organization: { org_id, prefix } = {},
      selected_auth: { auth_id: default_auth_id } = {}
    } = {}
  } = {}, isAuthenticated = false, bsoLoggedIn = false } = userData || {};

  (typeof default_auth_id !== 'number') && (default_auth_id = Number(default_auth_id));
  (typeof auth_id !== 'number') && (auth_id = Number(auth_id));
  (!auth_id && default_auth_id) && (auth_id = default_auth_id);
  let superAdminAccess = (isAuthenticated && Number(group_id) === 1) || OVERRIDE_LOGIN;
  let adminAccess = (isAuthenticated && Number(group_id) === 2) || OVERRIDE_LOGIN;
  if (!claimant_id && props?.claimant_id) {
    claimant_id = props.claimant_id; // Override if passed in props 
  }
  if (claimant_id && typeof claimant_id !== 'number') {
    claimant_id = Number(claimant_id);
  }

  const [tabIndex, setTabIndex] = useState(0);
  const [invokeUpdate, setInvokeUpdate] = useState(false);
  const [loadingLogs, setLoadingLogs] = useState(false);

  const [claimantDetails, setClaimantDetails] = useState([]);
  const [claimantResources, setClaimantResources] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [pageAlert, setPageAlert] = useState({ message: "", alert_type: "info" });
  const claimantDetailsRef = useRef();

  const showDownloadLinks = !adminAccess; // We don't want firm admins to see download links
  const skipItem = ['claimant_name', 'SSN', 'prefix', 'org_id'];
  const getClaimantData = async ({ functionFields = skipItem, scrapeClaimant = false } = {}) => {
    let queryParams = { ...defaultQueryParams, decryptSSN: superAdminAccess, scrapeClaimant };
    let alert_message = '';
    let alert_type = "info";
    let response;

    try {
      setLoadingLogs(true);
      response = await downloader.getClaimant(claimant_id, queryParams);
    } catch (error) {
      console.log(error);
      response = error?.response || {};
      if (!response?.data) {
        response.data = { err: 401, error: error.error, details: `${error.message}` };
      }
    } finally {
      setLoadingLogs(false);
    }

    let { status, statusText = '', data = {} } = response;
    let { err, error, details, message, resources = {}, claimant = {}, ...remainingData } = data || {};
    claimant = { ...claimant, ...remainingData };

    let detailsObj = {};
    if (status !== 200) {
      detailsObj = data;
      alert_type = "danger";
      alert_message = `[${status ?? err}] ${statusText} `;    // API Error Code 
      alert_message += data?.status ? `${data.status} ` : ""; // API Status 
      alert_message += error ? `${error} ` : "";              // API Error 
      alert_message += (typeof response?.data === 'string') ? response.data : message ?? (details ? `${details} ` : "") + `Failed to read data for claimant details.`;
      alert_message = alert_message.trim();
    } else {
      let date_fields = ['last_captured', 'status_date', 'hearing_schedule_date', 'scheduler_requested_date'];
      let details_fields = ['Claimant ID', 'last_four_social', 'matter_id', 'firm_id', 'firm_name', 'org_id', 'prefix', 'attorney_id', 'level',
        'application', 'claim_type', 'last_insured', 'last_changed', 'alleged_onset',
        'unexhibited_count', 'exhibited_count', 'media_count', 'last_captured', 'status_of_case',
        'status_date', 'hearing_schedule_date', 'scheduler_requested_date', 'Hearing Status Report ID', 'electronic_folder', ...functionFields];
      let renamed_fields = {
        decryptedSSN: 'SSN',
        claimant_id: 'Claimant ID',
        hearing_status_report_id: 'Hearing Status Report ID',
        auth_id: 'attorney_id',
        organization: 'firm_name'
      };
      detailsObj = (!isObject(claimant) ? [{}] : massageData(claimant, details_fields, renamed_fields, date_fields))[0];
      setClaimantDetails(detailsObj);

      // Update Claimant Resources in socialObj
      let all_resources = {};
      if (isObject(resources) && Object.keys(resources).length > 0) {
        let mediaFields = ['id', 'link', 'section', 'description', 'judge', 'hearing', 'receipt_date'];
        let resourcesFields = [...mediaFields, 'exhibit', 'source', 'date_from', 'date_to', 'decision_date', 'document_date', 'received', 'marked', 'pages'];
        let { case_docs = [], exhibits = [], media = [] } = resources;
        if (case_docs.length > 0) {
          all_resources.case_docs = (case_docs || []).reduce((acc, { title = '', data = [] } = {}) => {
            if (title && data.length > 0) {
              acc.push({ title, data: massageData(data, resourcesFields) });
            }
            return acc;
          }, []);
        }
        if (exhibits.length > 0) {
          all_resources.exhibits = (exhibits || []).reduce((acc, { title = '', data = [] } = {}) => {
            if (title && data.length > 0) {
              acc.push({ title, data: massageData(data, resourcesFields) });
            }
            return acc;
          }, []);
        }
        if (media.length > 0) {
          all_resources.media = (media || []).reduce((acc, { title = '', data = [] } = {}) => {
            if (title && data.length > 0) {
              acc.push({ title, data: massageData(data, mediaFields) });
            }
            return acc;
          }, []);
        }
      } // END Update Claimant Resources in socialObj
      setClaimantResources(all_resources);
    }

    if (alert_message) {
      console.log(alert_message);
      setShowAlert(true);
      setPageAlert(prevState => ({ ...prevState, message: alert_message, alert_type }));
    }

    return detailsObj;
  };

  // ===============[ useEffect ]==================================
  useEffect(() => {
    const init = async () => {
      setShowAlert(false);
      await getClaimantData();
    }
    let mounted = true;
    if (mounted) {
      init(); // Initialize Request Data for Table
    }
    return () => { // Returned function will be called on component unmount 
      mounted = false;
    }
  }, [invokeUpdate]);

  return (
    <div className="page-container" ref={claimantDetailsRef} >
      <div className="d-flex justify-content-between">
        <h4>{claimantDetails?.claimant_name || "Claimant Details"}</h4>
        <Button
          type="submit"
          size="md"
          variant="danger"
          onClick={(e) => {
            if (!e?.target) return;
            e.stopPropagation();
            e.preventDefault();
            return getClaimantData({ scrapeClaimant: true }); // return setInvokeUpdate(prev => !prev);
          }}
          {...(loadingLogs) && { disabled: true }}
        >Refresh Claimant Info</Button>
      </div>
      <hr />
      <Alert
        dismissible
        onClose={() => setShowAlert(false)}
        show={showAlert}
        variant={pageAlert.alert_type}
      >{pageAlert.message}</Alert>

      <Row id="claimant-details-summary" className="mb-4">
        <Col>
          <Row>
            {loadingLogs ? (<Loader className="text-info" style={null} />) : isObject(claimantDetails) && Object.keys(claimantDetails).length > 0 && splitArrayChunks(Object.entries(claimantDetails), 5).map((chunk, idx) => (<Col key={`chunk-${idx}`}>
              <StyledListNoBorder>{chunk.filter(([k, v]) => !skipItem.includes(k)).map(([key, value], index) => (
                <ListGroup.Item key={`${index}-${key}`}>
                  <strong
                    {...(superAdminAccess && key === "last_four_social") && { title: claimantDetails?.SSN }}
                  >{key.charAt(key.length - 1) === key.charAt(key.length - 1).toLowerCase() ? humanize(key) : key}: </strong>{typeof value !== 'string' ? humanize(value) : value}
                </ListGroup.Item>
              ))}</StyledListNoBorder>
            </Col>))}
          </Row>
        </Col>
      </Row>

      <Tabs key="tabs" className="mt-3" selectedIndex={tabIndex} onSelect={(tabIndex) => setTabIndex(tabIndex)} >
        <TabList>
          <Tab key="tab-1" >
            <h5 {...(tabIndex === 0 && { className: "text-primary bold-text" })}>Resources</h5>
          </Tab>
          <Tab key="tab-2" >
            <h5 {...(tabIndex === 1 && { className: "text-primary bold-text" })}>Download Requests</h5>
          </Tab>
          <Tab key="tab-3" >
            <h5 {...(tabIndex === 2 && { className: "text-primary bold-text" })}>Upload Requests</h5>
          </Tab>
          <Tab key="tab-4" >
            <h5 {...(tabIndex === 3 && { className: "text-primary bold-text" })}>eFolder</h5>
          </Tab>
        </TabList>

        <TabPanel key="tab-1-claimant-resources">
          <ClaimantResources
            className="mt-3"
            claimant_id={claimant_id || claimantDetails['Claimant ID']}
            all_resource={claimantResources}
            claimant={claimantDetails}
            superAdminAccess={superAdminAccess}
            adminAccess={adminAccess}
            user_id={user_id}
            bso_auth_id={default_auth_id}
            bsoLoggedIn={bsoLoggedIn}
          />
        </TabPanel>

        <TabPanel key="tab-2-claimant-download-requests">
          <ClaimantDownloadRequests
            className="mt-4"
            org_id={org_id}
            claimant_id={claimant_id}
            showFilter={false}
            showDebug={false}
            showDownloadLinks={showDownloadLinks}
            showClaimantLinks={false}
            showParentAlert={setShowAlert}
            setParentAlert={setPageAlert}
            parentRef={claimantDetailsRef}
            refresh_interval_seconds={0}
            skipFields={[
              'document_percentage', 'docs_processed', 'docs_to_process', 'docs_requested', 'exhibited_status', 'unexhibited_status', 'custom_status',
              'media_percentage', 'media_processed', 'media_to_process', 'media_requested',
              'claimant_id', 'matter_id', 'file_downloads', 'download_status_details'
            ]}
            tableFields={['request_id', 'ID', 'QID', 'Status', 'Documents', 'Media']}
            renamedFields={{
              request_social_id: "ID",
              social_status: "Status",
              social_status_details: "download_status_details",
              document_status: "Documents",
              media_status: "Media",
            }}
          />
        </TabPanel>

        <TabPanel key="tab-3-claimant-upload-requests">
          <ClaimantUploadRequests
            className="mt-4"
            auth_id={auth_id}
            org_id={org_id}
            claimant_id={claimant_id}
            showFilter={false}
            showDebug={false}
            showClaimantLinks={false}
            showParentAlert={setShowAlert}
            setParentAlert={setPageAlert}
            parentRef={claimantDetailsRef}
            refresh_interval_seconds={0}
            skipFields={[
              'document_date', // defaultFormInputs
              'treatment_source', 'from_date', 'to_date', // extraFormInputs
              'claimant_id', 'auth_id', 'document_type_id', 'total_file_size', 'status_details', 'last_captured'
            ]}
            tableFields={['ID', 'Requested', 'last_status_check', 'status', 'requested_by', 'attorney', ...(claimant_id ? [] : ['claimant']), 'document_type', 'tracking_number', 'rqid', 'site_code', 'destination', 'filename', 'status_of_case']}
            renamedFields={{
              upload_request_id: 'ID',
              ...(!claimant_id && { claimant_name: 'claimant' }),
              submitted_timestamp: 'Requested',
            }}
            dateFields={['Requested', 'last_status_check']}
            formatStr={"MM/D/YYYY hh:mm:ss A"}
          />
        </TabPanel>

        <TabPanel key="key-4-claimant-efolder">
          <ClaimantEFolder
            fromS3={false}
            hideFullPath={true}
            showDownloadButton={true}
            claimant_id={claimant_id || claimantDetails['Claimant ID']}
            user_id={user_id}
            org_id={org_id}
            auth_id={default_auth_id}
            rootPath={`${org_id}-${prefix}/claimants/${claimant_id || claimantDetails['Claimant ID']}`}
            showParentAlert={setShowAlert}
            setParentAlert={setPageAlert}
          />
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default ClaimantDetails;