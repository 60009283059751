import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useUserData } from '../../contexts/AuthContext';

// Styles 
import { Form, Button, Alert, Row, Col, ListGroup } from 'react-bootstrap';
import { StyledListNoBorder } from '../../styled/List';

// EREDocs Functions 
import { firm_management } from "../../utils/EREDocs";
import { validParams, sleep } from "../../utils/";

const ChangePasswordForm = ({ user_id, reset_password_token, closeModal } = {}) => {
  const navigate = useNavigate();
  const { userData } = useUserData();
  let { passport: { user: { user_id: default_user_id } = {} } = {} } = userData || {};

  // Clean up variables if needed 
  (!user_id && default_user_id) && (user_id = default_user_id);
  typeof user_id !== 'number' && (user_id = parseInt(user_id));

  const [passwordErrors, setPasswordErrors] = useState({ special: true, upper: true, number: true, eight: true, match: true });
  const [loadingForm, setLoadingForm] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [modalAlert, setModalAlert] = useState({
    message: "Validation Error, your password must meet the complexity requirements listed.",
    alert_type: "info",
  });
  const [formState, setFormState] = useState({
    newPassword: "",
    retypePassword: "",
    user_id,
  });

  const cancelChangePassword = () => (typeof closeModal === 'function' ? closeModal() : navigate("/"));
  const testPasswordCriteria = (password, retypePassword) => {
    let special = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    let number = /[0-9]/;
    let uppercase = /[A-Z]/;
    let length = /.{8,}/;
    let e = { ...passwordErrors };

    // Reset passwordErrors values to false 
    Object.entries(e).forEach(([key]) => {
      e[key] = false;
    });

    e.match = !password || (password !== retypePassword);
    e.upper = !uppercase.test(password);
    e.eight = !length.test(password);
    e.special = !special.test(password);
    e.number = !number.test(password);

    setPasswordErrors(e);
    let hasErrors = Object.values(e).some(i => i);
    return (hasErrors === false); // Return true if no errors
  }

  // ===============[ handleChange ]==================================
  const handleInputChange = (event) => {
    if (!event?.target?.name) {
      return;
    }
    let alertObject = {
      message: "",
      alert_type: "info",
    }
    let { name, value, type, checked = false } = event.target;
    if (type === 'checkbox') {
      value = checked;
    }
    setFormState((prevState) => ({ ...prevState, [name]: value }));

    // Validate Form on change
    let requiredFields = ['newPassword', 'retypePassword'];
    let testParams = Object.entries({ ...formState, [name]: value }).filter(([k, v]) => v).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
    let valid = validParams(testParams, requiredFields, 'required');
    if (!valid) {
      alertObject.message = "Please fill out all required fields. Missing " + requiredFields.filter((f) => !testParams[f]).join(', ') + ". ";
    }

    if (['newPassword', 'retypePassword'].includes(name)) {
      let pw = (name === 'newPassword') ? value : formState.newPassword;
      let pw_confirm = (name === 'retypePassword') ? value : formState.retypePassword;
      valid = testPasswordCriteria(pw, pw_confirm);
      if (!valid) {
        alertObject.message += "Validation Error, your password must meet the complexity requirements listed.";
      }
    }

    setValidated(valid);
    setShowAlert(!valid);
    if (alertObject.message) {
      alertObject.message = alertObject.message.trim();
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }
  };

  // ===============[ changePassword ]==================================
  const changePassword = async (event) => {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation();

    let alertObject = {
      message: "",
      alert_type: "info",
    };

    let response;
    try {
      if (!validated) {
        throw new Error("Your form input is not valid. Please check your input and try again.");
      }

      let { newPassword, retypePassword, ...resetPwParams } = formState;
      if (newPassword !== retypePassword) {
        console.log("This shouldn't have been able to get here, form validation for password match should have prevented this.");
      }
      resetPwParams.password = retypePassword; // NOTE: newPassword === retypePassword
      if (reset_password_token) {
        resetPwParams.reset_password_token = reset_password_token;
        resetPwParams.user_id = null; // if using a magic link, don't pass user_id // IMPORTANT, only use the magic link token if that's how they got here.
      }

      // Filter out empty values 
      setLoadingForm(true);
      resetPwParams = Object.entries(resetPwParams).reduce((acc, [k, v]) => ({ ...acc, ...(v && { [k]: v }) }), {});
      response = await firm_management.resetPassword(resetPwParams).then((resp) => {
        setLoadingForm(false);
        setFormState({ ...formState, newPassword: "", retypePassword: "" });
        return resp;
      });
      alertObject.message = "Saved Successfully! ";
    } catch (ex) {
      setLoadingForm(false);
      console.log(ex);
      response = ex?.response || {};
      response.status = 500;
      response.statusText = `${ex.message}`;
      if (!response?.data) {
        response.data = { err: 401, message: `${ex.message}`, error: ex.error, details: "There was an error requesting your password change. Please contact support." };
      }
    }

    let { status = 500, statusText = '', data = {} } = response;
    let { ok = false, message = '', details = '', error } = data;

    alertObject.alert_type = status !== 200 ? "danger" : "success";
    alertObject.message += message;
    if (status !== 200 || !ok) {
      if (message.includes("The password reset link is expired")) {
        details = " Please request another password update link.";
        alertObject.message = "The password reset link is expired.";
        alertObject.alert_type = "danger";
        statusText = "The password reset link is expired.";
        status = 401;
      } else if (message.includes("No user found with that password reset token")) {
        details = " Please request another password update link.";
        alertObject.message = "Error processing your change passsword request.";
        alertObject.alert_type = "danger";
        statusText = "Error processing your change passsword request.";
        status = 401;
      }
      alertObject.message = `[${status}] ${statusText} ${alertObject.message ?? "Invalid submission."}`;
      alertObject.message += details ? `${details} ` : "";
      alertObject.message += error ? `${error} ` : ""; // ScrapeError Message + Controller Error 
      alertObject.message += (typeof response?.data === 'string') ? response.data : "";
    }

    // Show Alert success or danger
    if (alertObject?.message) {
      alertObject.message = alertObject.message.trim();
      setShowAlert(true);
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }

    (status === 200 && typeof closeModal === 'function') && await sleep(1).then(() => {
      closeModal();
    });
    return;
  }

  return (<div>
    <Alert
      dismissible
      onClose={() => setShowAlert(false)}
      show={showAlert}
      variant={modalAlert.alert_type}
    >{modalAlert.message}</Alert>

    <Form noValidate validated={validated} onSubmit={changePassword} autoComplete="off" >
      <p>Enter a new password below that follows the criteria listed.</p>
      <Row>
        <Col>
          <Form.Label className="mt-3">Password</Form.Label>
          <Form.Control
            type="password"
            placeholder=""
            name="newPassword"
            onChange={handleInputChange}
            value={formState.newPassword}
            autoComplete="off"
            required
          />
          <Form.Label className="mt-3">Retype Password</Form.Label>
          <Form.Control
            type="password"
            placeholder=""
            name="retypePassword"
            onChange={handleInputChange}
            value={formState.retypePassword}
            autoComplete="off"
            required
          />
        </Col>
        <Col>
          <strong>Your password must:</strong>
          <StyledListNoBorder>
            <ListGroup.Item key={`special-${passwordErrors.special}`} className={passwordErrors.special ? 'text-danger' : 'text-success'}>{(passwordErrors.special ? "✘" : "✔")} Contains at least 1 special character.</ListGroup.Item>
            <ListGroup.Item key={`upper-${passwordErrors.upper}`} className={passwordErrors.upper ? 'text-danger' : 'text-success'}>{(passwordErrors.upper ? "✘" : "✔")} Contains at least 1 upper-case character.</ListGroup.Item>
            <ListGroup.Item key={`number-${passwordErrors.number}`} className={passwordErrors.number ? 'text-danger' : 'text-success'}>{(passwordErrors.number ? "✘" : "✔")} Contains at least 1 number.</ListGroup.Item>
            <ListGroup.Item key={`eight-${passwordErrors.eight}`} className={passwordErrors.eight ? 'text-danger' : 'text-success'}>{(passwordErrors.eight ? "✘" : "✔")} Contains at least 8 characters.</ListGroup.Item>
            <ListGroup.Item key={`match-${passwordErrors.match}`} className={passwordErrors.match ? 'text-danger' : 'text-success'}>{(passwordErrors.match ? "✘" : "✔")} Passwords match.</ListGroup.Item>
          </StyledListNoBorder>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            className="primary-button me-2"
            type="submit"
            variant="success"
            {...(loadingForm || !validated) && { disabled: true }}
          >{loadingForm ? "Loading..." : "Save Changes"}</Button>
          <Button
            className="simple-button"
            variant="light"
            onClick={cancelChangePassword}
            {...(loadingForm) && { disabled: true }}
          >{loadingForm ? "Loading..." : "Cancel"}</Button>
        </Col>
      </Row>
    </Form>
  </div>);
}

export { ChangePasswordForm };
export default ChangePasswordForm;