import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import { Form, Alert, Button, Modal, Col } from 'react-bootstrap';
import { StyledGreenCheckbox } from '../../styled/Forms';
import { StyledListNoBorder } from '../../styled/List';
import { StyledCustomDownloadTable } from '../../styled/Table';
import Loader from "../Loader";
import { humanize, dehumanize, renameKeys } from "../../utils/";
import { downloader } from "../../utils/EREDocs";
const defaultDownloadParams = { createPDF: true, dryRun: false, convertMethod: 'pdftron' };

const ClaimantResources = ({ claimant_id, all_resource, claimant, superAdminAccess = false, adminAccess = false, user_id, bso_auth_id, bsoLoggedIn = false, ...props } = {}) => {
  (claimant.attorney_id && !claimant.auth_id) && (claimant.auth_id = claimant.attorney_id);
  let { case_docs = [], exhibits = [], media = [] } = all_resource || {}; // All Resources 
  const case_docs_count = Array.isArray(case_docs) ? case_docs.reduce((acc, { data = [] } = {}) => acc + data.length, 0) : 0;
  const exhibited_count = Array.isArray(exhibits) ? exhibits.reduce((acc, { data = [] } = {}) => acc + data.length, 0) : 0;
  const media_count = Array.isArray(media) ? media.reduce((acc, { data = [] } = {}) => acc + data.length, 0) : 0;

  const [caseDocsModalState, setCaseDocsModalState] = useState(false);
  const [exhibitsModalState, setExhibitsModalState] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [selectedResources, setSelectedResources] = useState(null); // case_docs: [ { title, data: [] } ], exhibits: [ { title, data: [] } ], media: [ { title, data: [] } ]
  const [tabIndex, setTabIndex] = useState(0);
  const [loadingTable, setLoadingTable] = useState(false);
  const [showTableAlert, setShowTableAlert] = useState(false);
  const [tableAlert, setTableAlert] = useState({ message: "", alert_type: "info" });
  const [downloadParams, setDownloadParams] = useState([
    {
      name: "createPDF",
      label: "Create PDF",
      checked: defaultDownloadParams.createPDF,
      disabled: false,
      adminOnly: false,
      group_id: 1, // Groups are used to group download params so that only one can be selected at a time
    },
    {
      name: "zipDownloaded",
      label: "Zip Downloaded Files",
      checked: false,
      disabled: false,
      adminOnly: false,
      group_id: 1,
    },
    {
      name: "dryRun",
      label: "Dry Run",
      checked: defaultDownloadParams.dryRun,
      disabled: false,
      adminOnly: true,
      group_id: 2,
    },
    {
      name: "PDF_OCR_PROCESSING",
      label: "OCR Processing",
      checked: false,
      disabled: false,
      adminOnly: false,
      group_id: 3,
      parent_group_id: 1,
    },
    {
      name: "PDF_INCLUDE_BACKTOTOP_LINK",
      label: "Include Backtotop Link",
      checked: true,
      disabled: false,
      adminOnly: false,
      group_id: 4,
      parent_group_id: 1,
    },
    {
      name: "PDF_INCLUDE_TITLES",
      label: "Include Titles",
      checked: true,
      disabled: false,
      adminOnly: false,
      group_id: 5,
      parent_group_id: 1,
    },
    {
      name: "PDF_INCLUDE_TOC",
      label: "Include Toc",
      checked: true,
      disabled: false,
      adminOnly: false,
      group_id: 6,
      parent_group_id: 1,
    },
    {
      name: "convertMethod",
      label: "Tiff2PDF Convert Method",
      value: defaultDownloadParams.convertMethod,
      options: ['pdftron', 'nconvert', 'tiff2pdf', 'imagemagick'],
      disabled: false,
      adminOnly: true,
      group_id: 7,
      parent_group_id: 1, // Parent groups are only relevant if the parent_group_id is enabled (true/false)
    },
    {
      name: "title",
      label: "Title",
      value: "", // Default title will be used if not provided
      disabled: false,
      adminOnly: false,
      group_id: 8,
    },
    {
      name: "PDF_SETTINGS_AUTHOR",
      label: "PDF Author",
      value: "EREDocs",
      disabled: false,
      adminOnly: false,
      group_id: 9,
      parent_group_id: 1,
    },
    {
      name: "PDF_SETTINGS_CREATOR",
      label: "PDF Creator",
      value: "EREDocs",
      disabled: false,
      adminOnly: false,
      group_id: 10,
      parent_group_id: 1,
    },
    {
      name: "PDF_SETTINGS_PRODUCER",
      label: "PDF Producer",
      value: "EREDocs",
      disabled: false,
      adminOnly: false,
      group_id: 11,
      parent_group_id: 1,
    },
    {
      name: "request_id",
      label: "Request ID",
      value: "",
      disabled: false,
      adminOnly: false,
      group_id: 12,
    },
    {
      name: "request_social_id",
      label: "Request Social ID",
      value: "",
      disabled: false,
      adminOnly: false,
      group_id: 13,
    },
  ]);

  const handleResourceDownload = async (event, { docs = [], resource_id, tab } = {}) => {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation();
    (!Array.isArray(docs)) && (docs = docs ? [docs] : []);
    if (resource_id && !docs.includes(resource_id)) {
      docs.push(resource_id);
    }
    if (!Array.isArray(docs) || !claimant_id) return;

    // format params 
    let { attorney_id, auth_id, matter_id, firm_id, org_id, prefix, SSN: ssn } = claimant || {};
    (attorney_id) && (auth_id = attorney_id); // attorney_id may be used for auth_id

    if (!user_id && claimant?.user_id) {
      user_id = claimant.user_id;
    }
    let pdf_settings = downloadParams.reduce((acc, { name, value, checked } = {}) => ({
      ...acc,
      ...(`${name}`.toUpperCase().includes('PDF_') && { [`${name}`.toLowerCase().replace("pdf_", '')]: checked ?? value })
    }), {});

    let params = downloadParams.reduce((acc, { name, value, checked } = {}) => ({
      ...acc,
      ...((`${name}`.toLowerCase().replace("pdf_", '') in pdf_settings === false) && { [name]: value !== undefined ? value : (checked || false) })
    }), { ...defaultDownloadParams, ...(claimant && { claimant }), ...(tab && { tab }), docs, pdf_settings, auth_id, matter_id, firm_id, org_id, prefix, user_id, ssn });

    let alertObj = { ...tableAlert };
    let resp;
    try {
      setLoadingTable(true);
      if (tab === "exhibits") {
        setExhibitsModalState(false);
      } else {
        setCaseDocsModalState(false);
      }
      if (!bsoLoggedIn || Number(auth_id) !== Number(bso_auth_id)) {
        let error_message = "";
        if (bsoLoggedIn && Number(auth_id) !== Number(bso_auth_id)) {
          error_message = `Detected a mismatch between the BSO account related to this claimant and the BSO account you are logged into (${auth_id} !== ${bso_auth_id}). `;
        }
        error_message += "Please Log into the BSO account related to this claimant.";
        throw new Error(error_message);
      }
      if (!isValid) {
        console.log("Params not valid", params);
        throw new Error("Params not valid");
      }

      resp = await downloader.downloadClaimantResources(claimant_id, params);
    } catch (error) {
      console.log("Error downloading resources", error);
      resp = error?.response || { status: 500, statusText: error?.message || error || "Unknown Error" };
      resp.data = {
        ok: false,
        statusCode: resp.status,
        statusMessage: resp.statusText
      }
    } finally {
      setLoadingTable(false);
    }

    let { status, statusText, data = {} } = resp || {};
    let { ok = false, statusCode = 500, statusMessage, downloadDocs = [] } = data || {};
    alertObj.alert_type = (!ok || status !== 200) ? "danger" : "success";
    alertObj.message = `[${status || statusCode}] - `;
    alertObj.message += statusMessage || statusText || "Unknown Error";

    setShowTableAlert(!!alertObj.message);
    if (!!alertObj.message) {
      setTableAlert(prevState => ({ ...prevState, ...alertObj }));
    }

    return;
  }; // END handleResourceDownload

  const handleTableRowClick = async (event) => {
    if (!event?.target) return;
    event.stopPropagation(); // Prevent other events from firing on click

    let error_count = 0;
    let alertOptions = {
      message: '',
      alert_type: "info"
    };

    let { target = {}, which, keyCode, key } = event || {};
    let { name, value, checked, disabled = false, type, id, dataset, tagName, innerText, href, parentElement, className, title } = target;
    let action = false;

    if (type !== 'checkbox') {
      event.preventDefault(); // Allows checkboxes to work as normal
    }

    // Set action if needed 
    if (type === "checkbox") {
      let nameArr = name.split("_");
      if (['select', 'all'].every((n) => nameArr.includes(n))) {
        action = name; // select_all_case_docs_<section_index>, select_all_exhibits_0, select_all_media_1
      } else if (downloadParams.map(({ name } = {}) => name).includes(name)) {
        action = "update_download_params";
      } else {
        action = "toggle_selected";
      }
    } else if (['INPUT', 'SELECT'].includes(tagName) && downloadParams.map(({ name } = {}) => name).includes(name)) {
      action = "update_download_params";
    } else if (tagName === 'BUTTON' && innerText) {
      action = `${innerText}`.toLowerCase().includes("download") ? "download" : `${innerText}`.replace("✘", "").replace("✔", "").toLowerCase().trim();
    } else if (tagName === "TD") {
      action = "toggle_selected";
    }
    // console.log("handleTableRowClick target", { ...dataset, name, value, checked, type, tagName, className, innerText, which, keyCode, key, href, title, action });

    // Go up one level in the DOM but preserve innerText
    if (!['TD', 'BUTTON'].includes(tagName)) {
      if (parentElement?.tagName === "TD") {
        target = parentElement;
      } else {
        target = parentElement?.closest("td");
      }
      if (target) {
        parentElement = target?.parentElement;
        dataset = { ...dataset, ...target?.dataset };
        tagName = target?.tagName;
        if (!innerText && target?.innerText) {
          innerText = target?.innerText;
        }
      }
    }

    let rowObject = {
      innerText,
      tagName,
      ...(type && { type }),
      ...dataset,
      ...parentElement?.dataset,
    };

    // Extract row items and save to rowObject and selectedItem
    let selectedItem = {};
    if (tagName === "TD") {
      // Select Resource 
      let requestTableElement = parentElement.closest("table") || document.querySelector("table");
      let tdArray = Array.from(parentElement.querySelectorAll("td")).map(({ innerText }) => innerText).filter((i) => i) || [];
      let thArray = Array.from(requestTableElement.querySelectorAll("thead tr th")).map(({ innerText }) => dehumanize(innerText)).filter((i) => i) || [];

      if ('col' in rowObject) { // Extract selectedItem
        let col = Number(rowObject.col);
        if (col && innerText === tdArray[col]) {
          selectedItem[thArray[col]] = tdArray[col];
        }
      }

      // Match thArray length with tdArray length
      if (tdArray.length > thArray.length) {
        // slice extra elements to match thArray length
        tdArray = tdArray.slice(0, thArray.length);
      } else if (tdArray.length < thArray.length) {
        // Fill in the blanks with null values to match thArray length
        let startIndex = tdArray.length;
        tdArray.length = thArray.length;
        tdArray.fill(null, startIndex);
      }

      if (tdArray.length === thArray.length) {
        for (let i = 0; i < thArray.length; i++) {
          rowObject[thArray[i]] = tdArray[i];
        }
      }
    }

    let { row, select_all_name, id: resource_id, link, tab, section } = rowObject;

    // Object.keys(rowObject).length > 0 && console.log("handleTableRowClick rowObject", rowObject);
    // Object.keys(selectedItem).length > 0 && console.log("selectedItem", selectedItem);
    // console.log(`action: ${action}`);

    if (action) {
      if (action === "download") {
        let docs = selectedResources && selectedResources[tab] ? selectedResources[tab].reduce((acc, { data = [] } = {}) => ([...acc, ...data.map(({ id } = {}) => id)]), []) : [];
        return handleResourceDownload(event, { docs, resource_id, tab });
      } else if (['select', 'all'].every((n) => action.split("_").includes(n))) { // select_all_case_docs_<section_letter>, select_all_exhibits_A, select_all_media_M
        // Select All  
        updateResources({ select_all_name: select_all_name || action }, !!checked);
        let requestTableElement = parentElement.closest("table") || document.querySelector("table");
        if (requestTableElement) {
          requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']").forEach((el) => {
            el.checked = !!checked
          });
        }
      } else if (action === "toggle_selected") {
        // Toggle Selected 
        let updateResourceParams = { id: resource_id, tab, section };
        let updateResourceChecked = !!checked;
        let requestTableElement = parentElement.closest("table") || document.querySelector("table");
        row = Number(row);
        if (isNaN(row) && requestTableElement) {
          row = Array.from(requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']"))
            .reduce((acc, { dataset }, index) => {
              if (dataset?.id === resource_id || dataset?.select_all_name === select_all_name || dataset?.link === link) {
                acc = index
              }
              return acc;
            }, null);
        }

        if (!isNaN(row) && requestTableElement) {
          if (type !== "checkbox") {
            requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']").forEach((el, index) => {
              if (index === row) {
                el.checked = updateResourceChecked = !el.checked; // Toggle Checked State
              }
            });
          }

          // Click the "Select All" checkbox if all SSNs are selected
          if (requestTableElement.querySelector(`thead tr th input[name='${select_all_name}']`)) {
            let selectAllPreviousState = !!requestTableElement.querySelector(`thead tr th input[name='${select_all_name}']`)?.checked;
            let selectAllNewState = Array.from(requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']")).length === Array.from(requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']"))
              .map((el) => el?.checked).filter(Boolean).length;

            if (selectAllPreviousState !== selectAllNewState) {
              updateResourceParams.select_all_name = select_all_name || action;
              requestTableElement.querySelector(`thead tr th input[name='${select_all_name}']`).checked = updateResourceChecked = selectAllNewState;
            }
          }
        }

        updateResources(updateResourceParams, updateResourceChecked);
      } else if (action === "update_download_params") {
        setDownloadParams((prevState) => {
          let newState = [...prevState];
          newState.forEach((item, index, arr) => {
            if (item.name === name) {
              if (type === 'checkbox') {
                item.checked = checked;
                // See if we need to toggle the opposing setting 
                let toggleChecked = arr.filter(({ group_id, name, checked: toggle_checked }) => (toggle_checked !== undefined && group_id === item.group_id && name !== item.name));
                let toggleDisabled = arr.filter(({ parent_group_id }) => (parent_group_id === item.group_id));
                if (toggleChecked.length > 0) {
                  toggleChecked.forEach((toggleItem) => {
                    toggleItem.checked = !checked;
                  });
                }
                if (toggleDisabled.length > 0) {
                  let isDisabled = item.name === "createPDF" ? !checked : checked; // Match the createPDF setting 
                  toggleDisabled.forEach((toggleItem) => {
                    toggleItem.disabled = isDisabled;
                  });
                }
              } else {
                item.value = value;
              }
            }
          });

          return newState;
        });
      } else {
        console.log("Unknown Action");
        error_count++;
        alertOptions.message = `Unknown Action ${action}`;
        alertOptions.alert_type = "info";
      }
    }
    else if (error_count === 0 && select_all_name) {
      // Click the "Select All" checkbox if all resources are selected
      let requestTableElement = parentElement.closest("table") || document.querySelector("table");
      if (requestTableElement && requestTableElement.querySelector(`thead tr th input[name='${select_all_name}']`)) {
        let selectAllPreviousState = !!requestTableElement.querySelector(`thead tr th input[name='${select_all_name}']`)?.checked;
        let selectAllNewState = Array.from(requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']")).length === Array.from(requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']"))
          .map((el) => el?.checked).filter(Boolean).length;

        if (selectAllPreviousState !== selectAllNewState) {
          requestTableElement.querySelector(`thead tr th input[name='${select_all_name}']`).checked = selectAllNewState;
          updateResources({ select_all_name: select_all_name || action }, selectAllNewState);
        }
      }
    }

    // Make sure bsoLogged in and it's the correct bso_auth_id relating to the claimant 
    (claimant.attorney_id && !claimant.auth_id) && (claimant.auth_id = claimant.attorney_id); // attorney_id may be used for auth_id
    if (!bsoLoggedIn || Number(claimant?.auth_id) !== Number(bso_auth_id)) {
      error_count++;
      if (bsoLoggedIn && Number(claimant?.auth_id) !== Number(bso_auth_id)) {
        alertOptions.message += `Detected a mismatch between the BSO account related to this claimant and the BSO account you are logged into (${claimant?.auth_id} !== ${bso_auth_id}). `;
      }
      alertOptions.message += "Please Log into the BSO account related to this claimant.";
      alertOptions.alert_type = "warning";
    }

    setShowTableAlert(error_count > 0);
    if (error_count > 0) {
      alertOptions.message = `${error_count} error${error_count > 1 ? 's' : ''}: ${alertOptions?.message}`;
      setTableAlert(prevState => ({ ...prevState, ...alertOptions }));
    }
    return;
  }; // END handleTableRowClick

  // ===============[ updateResources ]==================================
  const updateResources = ({ tab, section, id, select_all_name } = {}, checked = true) => {
    if (select_all_name) { // select_all_case_docs_A
      section = select_all_name.split("_").pop(); // A, B, D, E, F
      tab = select_all_name.split("_").filter((x) => !['select', 'all', section].includes(x)).join("_"); // case_docs, exhibits, media
    }
    if (!(id || select_all_name) || !section || !tab) {
      console.log(`Unable to ${checked ? "add" : "remove"} resource. Missing required parameters. tab: ${tab}, section: ${section}, id: ${id}`);
      return;
    }
    if (!['case_docs', 'exhibits', 'media'].includes(tab)) {
      console.log(`Unable to ${checked ? "add" : "remove"} resource. Invalid tab: ${tab}`);
      return;
    }
    const SECTION_NAMES = {
      'A': 'A. Payment Documents/Decisions',
      'B': 'B. Jurisdictional Documents/Notices',
      'D': 'D. Non-Disability Development',
      'E': 'E. Disability Related Development',
      'F': 'F. Medical Records',
      'M': 'Media Files'
    };
    if (!Object.keys(SECTION_NAMES).includes(section)) {
      console.log(`Unable to ${checked ? "add" : "remove"} resource. Invalid section: ${section}`);
      return;
    }
    let targetTitle = SECTION_NAMES[section];
    let keyMap = { // { oldKey: newKey }
      pg: 'pages',
      "#": 'exhibit',
    };

    // Search for the target resource in the requestObject all_resources and update resources
    return setSelectedResources((prevState) => {
      let newState = { ...prevState };
      if (!all_resource) {
        all_resource = {
          [tab]: [{ title: targetTitle, data: [] }]
        };
      } else if (!all_resource[tab]) {
        all_resource[tab] = [{ title: targetTitle, data: [] }];
      }
      all_resource[tab].forEach(({ title, data: raw_data = [] }) => {
        let data = [...raw_data].reduce((acc, item) => {
          acc.push(renameKeys(keyMap, item));
          return acc;
        }, []);

        if (title === targetTitle) {
          // Handle bulk selection
          if (!id && select_all_name) {
            if (checked) { // Bulk Add Resource when checked == true
              if (!newState) {
                newState = {
                  [tab]: [{
                    title,
                    data: [...data],
                  }]
                };
              } else if (!newState[tab]) {
                newState[tab] = [{
                  title,
                  data: [...data],
                }];
              } else if (!newState[tab].find(({ title } = {}) => title === targetTitle)) {
                newState[tab].push({
                  title,
                  data: [...data],
                });
              } else {
                newState[tab].forEach((tabObj = {}) => {
                  if (tabObj.title === targetTitle) {
                    tabObj.data = [...data];
                  }
                });
              }
            } else { // Bulk Remove Resource when checked == false
              if (newState && newState[tab]) {
                if (newState[tab].find(({ title } = {}) => title === targetTitle)) {
                  newState[tab].forEach((tabObj = {}, tab_index) => {
                    if (tabObj.title === targetTitle) {
                      newState[tab].splice(tab_index, 1);
                      if (!newState[tab].length) {
                        delete newState[tab]; // Clean up empty tabs
                      }
                    }
                  });
                }
              }
            }
          } else {
            // Handle Single selection
            data.forEach((doc) => {
              if (doc.id === id) {
                if (checked) { // Add Resource when checked == true
                  if (!newState) {
                    newState = {
                      [tab]: [{
                        title,
                        data: [{ ...doc }]
                      }]
                    };
                  } else if (!newState[tab]) {
                    newState[tab] = [{
                      title,
                      data: [{ ...doc }]
                    }];
                  } else if (!newState[tab].find(({ title } = {}) => title === targetTitle)) {
                    newState[tab].push({
                      title,
                      data: [{ ...doc }]
                    });
                  } else {
                    newState[tab].forEach((tabObj = {}) => {
                      let { title, data: resourceData } = tabObj || {};
                      if (title === targetTitle) {
                        if (!resourceData.find((doc = {}) => doc.id === id)) {
                          tabObj.data.push({ ...doc });
                        } else {
                          console.log(`Unable to add resource. Resource already exists. tab: ${tab} title: ${targetTitle}, id: ${id}`);
                        }
                      }
                    });
                  }
                } else { // Remove Resource when checked == false
                  if (newState && newState[tab]) {
                    if (newState[tab].find(({ title } = {}) => title === targetTitle)) {
                      newState[tab].forEach((tabObj = {}) => {
                        let { title, data: resourceData } = tabObj || {};
                        if (title === targetTitle) {
                          resourceData.forEach((doc, resource_index) => {
                            if (doc.id === id) {
                              tabObj.data.splice(resource_index, 1);
                            }
                          });
                        }
                      });
                    }
                  }

                  // Clean up empty tabs
                  if (newState && newState[tab]) {
                    if (newState[tab].find(({ title } = {}) => title === targetTitle)) {
                      newState[tab].forEach((tabObj = {}, tab_index) => {
                        let { title, data: resourceData } = tabObj || {};
                        if (title === targetTitle) {
                          if (!resourceData.length) {
                            newState[tab].splice(tab_index, 1);
                          }
                          if (!newState[tab].length) {
                            delete newState[tab];
                          }
                        }
                      });
                    }
                  }
                } // End !checked 
              } // End if (doc.id === id)
            }); // End data.forEach(doc) from all_resources
          } // End Handle Single selection
        } // End if (title === targetTitle)
      }); // End newState[tab].forEach(({ title, data = [] })

      // Check if request object is ready to be submitted
      let valid = (case_docs_count + exhibited_count + media_count) > 0;
      if (newState && Object.keys(newState).length > 0) {
        let { case_docs: selected_case_docs = [], exhibits: selected_exhibits = [], media: selected_media = [] } = newState || {};
        const selected_case_docs_count = Array.isArray(selected_case_docs) ? selected_case_docs.reduce((acc, { data = [] } = {}) => acc + data.length, 0) : 0;
        const selected_exhibited_count = Array.isArray(selected_exhibits) ? exhibits.reduce((acc, { data = [] } = {}) => acc + data.length, 0) : 0;
        const selected_media_count = Array.isArray(selected_media) ? selected_media.reduce((acc, { data = [] } = {}) => acc + data.length, 0) : 0;
        valid = (selected_case_docs_count + selected_exhibited_count + selected_media_count) > 0;
        if (!valid) {
          valid = (case_docs_count + exhibited_count + media_count) > 0;
        }
      }

      setIsValid(valid);
      setShowTableAlert(!valid);
      if (!valid) {
        setTableAlert(prevState => ({ ...prevState, alert_type: "warning", message: "Please select at least one resource to add." }));
      }
      return newState;
    }); // End setSelectedResources
  } // End updateResources

  return (<div {...props}>
    <Alert
      dismissible
      onClose={() => setShowTableAlert(false)}
      show={showTableAlert}
      variant={tableAlert.alert_type}
    >{tableAlert.message}</Alert>

    <Tabs key="tabs" selectedIndex={tabIndex} onSelect={(tabIndex) => setTabIndex(tabIndex)}  >
      <TabList>
        <Tab key="tab-1" >
          <h5 {...(tabIndex === 0 && { className: "text-primary bold-text" })}>Case Documents {`(${case_docs_count})`}</h5>
        </Tab>
        <Tab key="tab-2" >
          <h5 {...(tabIndex === 1 && { className: "text-primary bold-text" })}>Exhibit List {`(${exhibited_count})`}</h5>
        </Tab>
        <Tab key="tab-3" >
          <h5 {...(tabIndex === 2 && { className: "text-primary bold-text" })}>Multimedia Files {`(${media_count})`}</h5>
        </Tab>
      </TabList>

      <TabPanel key="tab-1-case_docs">
        {Array.isArray(case_docs) && case_docs.length > 0 && case_docs.map(({ title, data = [] } = {}, section_index) => {
          let section_letter = title.split(".")[0];

          // These fields will be skipped if there is no value 
          let possiblySkipFieldsCounter = {
            source: 0,
            date_from: 0,
            date_to: 0,
            decision_date: 0,
            document_date: 0,
            received: 0,
            marked: 0
          };
          let mediaFields = ["judge", "hearing", "receipt_date"];
          let skipFields = ["id", "link", "section", ...mediaFields];
          let tableFields = ["#", "description", ...(Object.keys(possiblySkipFieldsCounter)), "pg", ...skipFields];
          let renamedFields = {
            pages: "pg",
            exhibit: "#",
          };
          let tableRows = data.reduce((acc, row = {}) => {
            let newRow = tableFields.reduce((a, field) => {
              if (field in row) {
                a[field] = row[field];
              } else if (Object.values(renamedFields).includes(field)) {
                let renamedIndex = Object.values(renamedFields).indexOf(field);
                let oldField = Object.keys(renamedFields)[renamedIndex];
                if (row[oldField]) {
                  row[field] = row[oldField]; // rename the field
                  delete row[oldField]; // delete the old field
                  a[field] = row[field];
                }
              }

              if (a[field] && field in possiblySkipFieldsCounter) {
                possiblySkipFieldsCounter[field]++;
              }

              if (field === "section" && row[field] && section_letter !== row[field]) {
                section_letter = row[field];
              }

              return a;
            }, {});

            if (Object.keys(newRow).length) {
              acc.push(newRow);
            }
            return acc;
          }, []);

          // Update skipFields
          Object.entries(possiblySkipFieldsCounter).forEach(([field, count]) => {
            if (count === 0) {
              skipFields.push(field);
            }
          });

          return (<div key={`case_docs-${title}-${section_index}`} {...(section_index > 0 && { className: "mt-4" })}>
            {section_index === 0 && (<div>
              <Button
                className="mb-3"
                size="sm"
                variant="primary"
                onClick={() => (setCaseDocsModalState(true))}
                disabled={loadingTable}
              >{isValid ? "✔" : "✘"} Download {[0, case_docs_count].includes(selectedResources?.case_docs?.reduce((acc, { data = [] } = {}) => acc + (data || []).length, 0) || 0)
                ? "All"
                : selectedResources?.case_docs?.reduce((acc, { data = [] } = {}) => acc + (data || []).length, 0) || "All"} Case Documents</Button>
              <Modal
                show={caseDocsModalState}
                onHide={() => setCaseDocsModalState(false)}
                size="lg"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Download {[0, case_docs_count].includes(selectedResources?.case_docs?.reduce((acc, { data = [] } = {}) => acc + (data || []).length, 0) || 0)
                    ? "All"
                    : selectedResources?.case_docs?.reduce((acc, { data = [] } = {}) => acc + (data || []).length, 0) || "All"} Case Documents</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form noValidate className="row">
                    <p>Select the download options you would like to apply to your download request.</p>

                    {(Array.isArray(downloadParams) && downloadParams.length > 0) && (<Form.Group className="row" controlId="downloadParamsCheckbox" >
                      <Col>
                        <StyledListNoBorder >{downloadParams.filter((item) => item.checked !== undefined).map(({ name, label, checked = false, value, disabled = false, title, adminOnly }, index) => (
                          <StyledGreenCheckbox key={`checkbox-${index}`} className="list-group-item">
                            <Form.Control key={`${name}-${index}`} className="me-2" type="checkbox" name={name} checked={checked} data-index={index} onChange={handleTableRowClick} {...(disabled || adminOnly && !(adminAccess || superAdminAccess)) && { disabled: true }} />
                            <span {...(title) && { title }} {...(disabled || adminOnly && !(adminAccess || superAdminAccess)) ? { className: "text-muted ms-2" } : { className: "ms-2" }} >{label}</span>
                          </StyledGreenCheckbox>))}</StyledListNoBorder>
                      </Col>
                      <Col>
                        <StyledListNoBorder className="d-flex align-items-end">{downloadParams.filter((item) => item.value !== undefined)
                          .map(({ name, label, checked = false, value, options, disabled = false, title, adminOnly }, index) => (<div key={`checkbox-${index}`} className="list-group-item d-flex">
                            <Form.Label className="me-2 mt-2" {...(title) && { title }} {...(disabled || adminOnly && !(adminAccess || superAdminAccess)) && { className: "text-muted" }}>{label}</Form.Label>
                            {(Array.isArray(options))
                              ? <Form.Select key={`${name}-${index}`} className="flex-grow-1 w-auto" name={name} value={value} data-index={index} onChange={handleTableRowClick} {...(disabled || adminOnly && !(adminAccess || superAdminAccess)) && { disabled: true }} >
                                {options.map((opt, idx) => (<option key={`${idx}-${opt}`} value={opt}>{opt}</option>))}
                              </Form.Select>
                              : <Form.Control key={`${name}-${index}`} className="w-auto" type="text" name={name} value={value} data-index={index} onChange={handleTableRowClick} {...(disabled || adminOnly && !(adminAccess || superAdminAccess)) && { disabled: true }} />}
                          </div>))}</StyledListNoBorder>
                      </Col>
                    </Form.Group>)}
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => (setCaseDocsModalState(false))}>Close</Button>
                  <Button variant="primary" onClick={handleTableRowClick} data-tab="case_docs" id="download_case_docs">{isValid ? "✔" : "✘"} Download</Button>
                </Modal.Footer>
              </Modal>
            </div>)}
            <h6>{title}</h6>
            {loadingTable ? (<Loader className="text-info" style={null} />) : (<StyledCustomDownloadTable id={`table_case_docs_${section_letter}`} hover>
              <thead>
                <tr>
                  <th key="0-select_all">
                    <StyledGreenCheckbox>
                      <Form.Control
                        key={`select_all_case_docs_${section_letter}`}
                        name={`select_all_case_docs_${section_letter}`}
                        className="me-2"
                        type="checkbox"
                        title={`Select All ${title}`}
                        defaultChecked={false}
                        onChange={handleTableRowClick}
                      />
                    </StyledGreenCheckbox>
                  </th>
                  {tableFields.filter((k) => !skipFields.includes(k)).map((header, th_index) =>
                  (<th key={`${th_index + 1}-${header}`} >
                    {(header.includes("_") || header.charAt(header.length - 1) === header.charAt(header.length - 1).toLowerCase()) ? (humanize(header)) : header}
                  </th>))}
                </tr>
              </thead>
              <tbody>{Array.isArray(tableRows) && tableRows.length > 0 && tableRows.map((row = {}, tr_index) =>
              (<tr
                key={`case_docs-${title}-${section_index}-${tr_index}`}
                data-row={tr_index}
                data-link={row?.link}
                data-id={row?.id}
                data-section={row?.section}
                data-tab="case_docs"
                data-select_all_name={`select_all_case_docs_${section_letter}`}
                onClick={handleTableRowClick}
              >
                <td key="0-checkbox" width={45} data-col={0}>
                  <StyledGreenCheckbox>
                    <Form.Control
                      className="me-2"
                      type="checkbox"
                      name={`row_${tr_index}`}
                      title={`Select ${tr_index}`}
                      defaultChecked={false}
                    />
                  </StyledGreenCheckbox>
                </td>
                {tableFields.filter((k) => !skipFields.includes(k)).map((field, td_index) => (<td key={`${td_index + 1}-${field}`} data-col={`${td_index + 1}`}>{row[field] || ""}</td>))}
              </tr>))}
              </tbody>
            </StyledCustomDownloadTable>)}
          </div>)
        })}
      </TabPanel>

      <TabPanel key="tab-2-exhibits">
        {Array.isArray(exhibits) && exhibits.length > 0 && exhibits.map(({ title, data = [] } = {}, section_index) => {
          let section_letter = title.split(".")[0];

          // These fields will be skipped if there is no value 
          let possiblySkipFieldsCounter = {
            source: 0,
            date_from: 0,
            date_to: 0,
            decision_date: 0,
            document_date: 0,
            received: 0,
            marked: 0
          };
          let mediaFields = ["judge", "hearing", "receipt_date"];
          let skipFields = ["id", "link", "section", ...mediaFields];
          let tableFields = ["#", "description", ...(Object.keys(possiblySkipFieldsCounter)), "pg", ...skipFields];
          let renamedFields = {
            pages: "pg",
            exhibit: "#",
          };
          let tableRows = data.reduce((acc, row = {}) => {
            let newRow = tableFields.reduce((a, field) => {
              if (field in row) {
                a[field] = row[field];
              } else if (Object.values(renamedFields).includes(field)) {
                let renamedIndex = Object.values(renamedFields).indexOf(field);
                let oldField = Object.keys(renamedFields)[renamedIndex];
                if (row[oldField]) {
                  row[field] = row[oldField]; // rename the field
                  delete row[oldField]; // delete the old field
                  a[field] = row[field];
                }
              }

              if (a[field] && field in possiblySkipFieldsCounter) {
                possiblySkipFieldsCounter[field]++;
              }

              if (field === "section" && row[field] && section_letter !== row[field]) {
                section_letter = row[field];
              }

              return a;
            }, {});

            if (Object.keys(newRow).length) {
              acc.push(newRow);
            }
            return acc;
          }, []);

          // Update skipFields
          Object.entries(possiblySkipFieldsCounter).forEach(([field, count]) => {
            if (count === 0) {
              skipFields.push(field);
            }
          });

          return (<div key={`exhibits-${title}-${section_index}`} {...(section_index > 0 && { className: "mt-4" })}>
            {section_index === 0 && (<div>
              <Button
                className="mb-3"
                size="sm"
                variant="primary"
                onClick={() => (setExhibitsModalState(true))}
                disabled={loadingTable}
              >{isValid ? "✔" : "✘"} Download {[0, exhibited_count].includes(selectedResources?.exhibits?.reduce((acc, { data = [] } = {}) => acc + (data || []).length, 0) || 0)
                ? "All"
                : selectedResources?.exhibits?.reduce((acc, { data = [] } = {}) => acc + (data || []).length, 0) || "All"} Exhibits</Button>
              <Modal
                show={exhibitsModalState}
                onHide={() => setExhibitsModalState(false)}
                size="lg"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Download {[0, exhibited_count].includes(selectedResources?.exhibits?.reduce((acc, { data = [] } = {}) => acc + (data || []).length, 0) || 0)
                    ? "All"
                    : selectedResources?.exhibits?.reduce((acc, { data = [] } = {}) => acc + (data || []).length, 0) || "All"} Exhibits</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form noValidate className="row">
                    <p>Select the download options you would like to apply to your download request.</p>

                    {(Array.isArray(downloadParams) && downloadParams.length > 0) && (<Form.Group className="row" controlId="downloadParamsCheckbox" >
                      <Col>
                        <StyledListNoBorder >{downloadParams.filter((item) => item.checked !== undefined).map(({ name, label, checked = false, value, disabled = false, title }, index) => (
                          <StyledGreenCheckbox key={`checkbox-${index}`} className="list-group-item">
                            <Form.Control key={`${name}-${index}`} className="me-2" type="checkbox" name={name} checked={checked} data-index={index} onChange={handleTableRowClick} {...(disabled) && { disabled }} />
                            <span {...(title) && { title }} {...(disabled) ? { className: "text-muted ms-2" } : { className: "ms-2" }} >{label}</span>
                          </StyledGreenCheckbox>))}</StyledListNoBorder>
                      </Col>
                      <Col>
                        <StyledListNoBorder className="d-flex align-items-end">{downloadParams.filter((item) => item.value !== undefined)
                          .map(({ name, label, checked = false, value, options, disabled = false, title }, index) => (<div key={`checkbox-${index}`} className="list-group-item d-flex">
                            <Form.Label className="me-2 mt-2" {...(title) && { title }} {...(disabled) && { className: "text-muted" }}>{label}</Form.Label>
                            {(Array.isArray(options))
                              ? <Form.Select key={`${name}-${index}`} className="flex-grow-1 w-auto" name={name} value={value} data-index={index} onChange={handleTableRowClick} {...(disabled) && { disabled }} >
                                {options.map((opt, idx) => (<option key={`${idx}-${opt}`} value={opt}>{opt}</option>))}
                              </Form.Select>
                              : <Form.Control key={`${name}-${index}`} className="w-auto" type="text" name={name} value={value} data-index={index} onChange={handleTableRowClick} {...(disabled) && { disabled }} />}
                          </div>))}</StyledListNoBorder>
                      </Col>
                    </Form.Group>)}
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => (setExhibitsModalState(false))}>Close</Button>
                  <Button variant="primary" onClick={handleTableRowClick} data-tab="exhibits" id="download_exhibits">{isValid ? "✔" : "✘"} Download</Button>
                </Modal.Footer>
              </Modal>
            </div>)}
            <h6>{title}</h6>
            {loadingTable ? (<Loader className="text-info" style={null} />) : (<StyledCustomDownloadTable id={`table_exhibits_${section_letter}`} hover>
              <thead>
                <tr>
                  <th key="0-select_all">
                    <StyledGreenCheckbox>
                      <Form.Control
                        key={`select_all_exhibits_${section_letter}`}
                        name={`select_all_exhibits_${section_letter}`}
                        className="me-2"
                        type="checkbox"
                        title={`Select All ${title}`}
                        defaultChecked={false}
                        onChange={handleTableRowClick}
                      />
                    </StyledGreenCheckbox>
                  </th>
                  {tableFields.filter((k) => !skipFields.includes(k)).map((header, th_index) =>
                  (<th key={`${th_index + 1}-${header}`}>
                    {(header.includes("_") || header.charAt(header.length - 1) === header.charAt(header.length - 1).toLowerCase()) ? (humanize(header)) : header}
                  </th>))}
                </tr>
              </thead>
              <tbody>{Array.isArray(tableRows) && tableRows.length > 0 && tableRows.map((row = {}, tr_index) =>
              (<tr
                key={`exhibits-${title}-${section_index}-${tr_index}`}
                data-row={tr_index}
                data-link={row?.link}
                data-id={row?.id}
                data-section={row?.section}
                data-tab="exhibits"
                data-select_all_name={`select_all_exhibits_${section_letter}`}
                onClick={handleTableRowClick}
              >
                <td key="0-checkbox" width={45} data-col={0}>
                  <StyledGreenCheckbox>
                    <Form.Control
                      className="me-2"
                      type="checkbox"
                      name={`row_${tr_index}`}
                      title={`Select ${tr_index}`}
                      defaultChecked={false}
                    />
                  </StyledGreenCheckbox>
                </td>
                {tableFields.filter((k) => !skipFields.includes(k)).map((field, td_index) => (<td key={`${td_index + 1}-${field}`} data-col={`${td_index + 1}`}>{row[field] || ""}</td>))}
              </tr>))}
              </tbody>
            </StyledCustomDownloadTable>)}
          </div>)
        })}
      </TabPanel>

      <TabPanel key="tab-3-media">
        {Array.isArray(media) && media.length > 0 && media.map(({ title, data = [] } = {}, section_index) => {
          let section_letter = "M";

          // These fields will be skipped if there is no value 
          let possiblySkipFieldsCounter = {
            source: 0,
            date_from: 0,
            date_to: 0,
            decision_date: 0,
            document_date: 0,
            received: 0,
            marked: 0
          };
          let mediaFields = ["judge", "hearing", "receipt_date"];
          let skipFields = ["id", "link", "section"];
          let tableFields = ["#", "description", ...mediaFields, ...(Object.keys(possiblySkipFieldsCounter)), "pg", ...skipFields];
          let renamedFields = {
            pages: "pg",
            exhibit: "#",
          };
          let tableRows = data.reduce((acc, row = {}) => {
            let newRow = tableFields.reduce((a, field) => {
              if (field in row) {
                a[field] = row[field];
              } else if (Object.values(renamedFields).includes(field)) {
                let renamedIndex = Object.values(renamedFields).indexOf(field);
                let oldField = Object.keys(renamedFields)[renamedIndex];
                if (row[oldField]) {
                  row[field] = row[oldField]; // rename the field
                  delete row[oldField]; // delete the old field
                  a[field] = row[field];
                }
              }

              if (a[field] && field in possiblySkipFieldsCounter) {
                possiblySkipFieldsCounter[field]++;
              }

              return a;
            }, {});

            if (Object.keys(newRow).length) {
              acc.push(newRow);
            }
            return acc;
          }, []);

          // Update skipFields
          Object.entries(possiblySkipFieldsCounter).forEach(([field, count]) => {
            if (count === 0) {
              skipFields.push(field);
            }
          });

          return (<div key={`media-${title}-${section_index}`} {...(section_index > 0 && { className: "mt-4" })}>
            <h6>{title}</h6>
            <StyledCustomDownloadTable id={`table_media_${section_letter}`} hover>
              <thead>
                <tr>
                  <th key="0-select_all">
                    <StyledGreenCheckbox>
                      <Form.Control
                        key={`select_all_media_${section_letter}`}
                        name={`select_all_media_${section_letter}`}
                        className="me-2"
                        type="checkbox"
                        title={`Select All ${title}`}
                        defaultChecked={false}
                        onChange={handleTableRowClick}
                      />
                    </StyledGreenCheckbox>
                  </th>
                  {tableFields.filter((k) => !skipFields.includes(k)).map((header, th_index) =>
                  (<th key={`${th_index + 1}-${header}`}>
                    {(header.includes("_") || header.charAt(header.length - 1) === header.charAt(header.length - 1).toLowerCase()) ? (humanize(header)) : header}
                  </th>))}
                </tr>
              </thead>
              <tbody>{Array.isArray(tableRows) && tableRows.length > 0 && tableRows.map((row = {}, tr_index) =>
              (<tr
                key={`media-${title}-${section_index}-${tr_index}`}
                data-row={tr_index}
                data-link={row?.link}
                data-id={row?.id}
                data-section={row?.section}
                data-tab="media"
                data-select_all_name={`select_all_media_${section_letter}`}
                onClick={handleTableRowClick}
              >
                <td key="0-checkbox" width={45} data-col={0}>
                  <StyledGreenCheckbox>
                    <Form.Control
                      className="me-2"
                      type="checkbox"
                      name={`row_${tr_index}`} title={`Select ${tr_index}`}
                      defaultChecked={false}
                    />
                  </StyledGreenCheckbox>
                </td>
                {tableFields.filter((k) => !skipFields.includes(k)).map((field, td_index) => (<td key={`${td_index + 1}-${field}`} data-col={`${td_index + 1}`}>{row[field] || ""}</td>))}
              </tr>))}
              </tbody>
            </StyledCustomDownloadTable>
          </div>)
        })}
      </TabPanel>
    </Tabs>
  </div>);
}

export default ClaimantResources;