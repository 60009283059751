import React, { useState, useEffect } from 'react';
import { useUserData } from '../../contexts/AuthContext';
import { Form, Button, Alert, Col } from 'react-bootstrap';
import { StyledGreenCheckbox, StyledFormInput } from "../../styled/Forms";
import { humanize, validParams } from "../../utils/";
import { bsoaccount_management } from "../../utils/manage";

const SFTPSetupForm = ({ org_id, closeModal = () => { }, setParentState = () => { }, sftp_state } = {}) => {
  const { userData, setUserData } = useUserData();
  let { SFTP = false, passport: { user: { org_id: default_org_id, user_settings: { SFTP: default_sftp = false } = {} } = {} } = {} } = userData || {};
  (!org_id && default_org_id) && (org_id = default_org_id);
  (typeof org_id !== "number") && (org_id = Number(org_id));
  (!SFTP && default_sftp) && (SFTP = default_sftp);
  (typeof sftp_state === 'boolean') && (SFTP = default_sftp); // override state from parent prop
  (typeof SFTP !== "boolean") && (SFTP = SFTP === "true");
  let labelPlaceholder = {
    SFTP_PUBLIC_KEY_CONTENTS: "ssh-rsa",
    SFTP_PRIVATE_KEY_CONTENTS: "-----BEGIN OPENSSH PRIVATE KEY-----\n-----END OPENSSH PRIVATE KEY-----",
  };

  const [loadingForm, setLoadingForm] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [modalAlert, setModalAlert] = useState({ message: "", alert_type: "info" });
  const [editableForm, setEditableForm] = useState(!org_id);
  const [previousFormValues, setPreviousFormValues] = useState({});
  const [formState, setFormState] = useState({
    SFTP,
    SFTP_HOST: "",
    SFTP_USERNAME: "",
    SFTP_PASSWORD: "",
    SFTP_ROOT_PATH: "./",
    SFTP_PUBLIC_KEY_CONTENTS: "",
    SFTP_PRIVATE_KEY_CONTENTS: "",
  });

  const handleInputChange = (event) => {
    if (!event?.target) return;
    event.stopPropagation();

    let { name, value, type, checked = false } = event.target;
    if (type === 'checkbox') {
      value = checked;
    } else if (type === 'radio') {
      (typeof value !== 'boolean') && (value = value === 'true');
    } else {
      event.preventDefault();
    }

    setFormState((prevState) => ({ ...prevState, [name]: value }));

    // Validate Form on change
    let alertObject = { ...modalAlert };
    let requiredFields = ['SFTP', 'SFTP_HOST', 'SFTP_USERNAME', 'SFTP_PASSWORD', 'SFTP_ROOT_PATH', 'SFTP_PUBLIC_KEY_CONTENTS', 'SFTP_PRIVATE_KEY_CONTENT'];
    let testParams = Object.entries({ ...formState, [name]: value }).filter(([k, v]) => type === 'checkbox' || v).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
    let valid = validParams(testParams, requiredFields, 'any');
    setValidated(valid);
    setShowAlert(!valid);
    if (!valid) {
      alertObject.message = "Please fill out all required fields. Missing " + requiredFields.filter((f) => !testParams[f]).join(', ');
    }
    if (alertObject.message) {
      alertObject.message = alertObject.message.trim();
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }
    return;
  };

  const loadSftpSetup = async (orgID = org_id) => {
    let sftpSettings = { ...formState, SFTP };
    if (orgID) {
      let alertObject = { ...modalAlert, message: "" };
      let params = { org_id: orgID };
      sftpSettings = await bsoaccount_management.getSecretsForSFTP(params)
        .then(({ status = 500, statusText = "Internal Server Error", data: { data: secret = {}, ok = false, statusCode = 500, message = '', error = '' } = {} }) => {
          if (status !== 200) {
            console.log(`${status} ${statusText}`);
          }
          if (statusCode !== 200 || !ok) {
            console.log(`${statusCode} ${message ?? error}`);
            alertObject.message += `${message ?? error}`;
          }
          return secret;
        }).catch((err) => {
          let { status, statusText = '', data = {} } = err?.response || {};
          let { error, message = '' } = data || {};
          console.log(`${status} ${statusText} ${message}`);
          alertObject.message += `${message}`;
          return { ...formState, SFTP };
        });

      setShowAlert(!!alertObject.message);
      if (!!alertObject.message) {
        alertObject.alert_type = "info";
        alertObject.message = alertObject.message.replace("Error:", "").trim();
        setModalAlert(prevState => ({ ...prevState, ...alertObject }));
      }
    }
    setFormState((prev) => ({ ...prev, ...sftpSettings }));
    setPreviousFormValues(sftpSettings);
    if (orgID === default_org_id) {
      setUserData((prev) => ({
        ...prev, ...{ SFTP: sftpSettings.SFTP },
        passport: {
          ...prev.passport,
          user: {
            ...prev.passport.user, ...{ SFTP: sftpSettings.SFTP },
            user_settings: { ...prev.passport.user.user_settings, ...{ SFTP: sftpSettings.SFTP } }
          }
        }
      }));
    }
  };

  const onSubmit = async (event) => {
    if (!event) return;
    event.stopPropagation(); // Prevent other events from firing on click
    event.preventDefault();
    let alertObject = { ...modalAlert };
    let payload = { org_id, ...formState };
    let response;

    try {
      if (!validated) {
        throw new Error("Your form input is not valid. Please check your input and try again.");
      }

      setLoadingForm(true);
      response = await bsoaccount_management.updateSecretsForSFTP(payload);
      alertObject.message = "Saved Successfully";
    } catch (ex) {
      console.log(ex);
      response = ex?.response || {};
      response.status = 500;
      response.statusText = `${ex.message}`;
      if (!response?.data) {
        response.data = { err: 401, details: `${ex.message}`, error: ex.error };
      }
    } finally {
      setLoadingForm(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    let { statusCode = 500, err, error, details, message = '', data: newState = {}, } = data || {};
    alertObject.alert_type = status !== 200 ? "danger" : "success";
    if (status !== 200 || statusCode !== 200) {
      alertObject.message = `[${status ?? err}] ${statusText} ${alertObject.message}`;
      alertObject.message += data?.status ? `${data.status} ` : ""; // ERROR 
      alertObject.message += error ? `${error} ` : ""; // ScrapeError Message + Controller Error 
      alertObject.message += (typeof response?.data === 'string') ? response.data : message ?? (details ? `${details} ` : "") + "Invalid submission.";
    }

    if (Object.keys(newState).length > 0) {
      setFormState(prevState => ({ ...prevState, ...newState }));
    }

    // Update previous with new values
    setEditableForm(false);
    setPreviousFormValues(newState);
    if (org_id === default_org_id) {
      setUserData((prev) => ({
        ...prev, ...{ SFTP: newState.SFTP },
        passport: {
          ...prev.passport,
          user: {
            ...prev.passport.user, ...{ SFTP: newState.SFTP },
            user_settings: { ...prev.passport.user.user_settings, ...{ SFTP: newState.SFTP } }
          }
        }
      }));
    }

    // Show Alert success or danger
    setShowAlert(!!alertObject?.message);
    if (!!alertObject?.message) {
      alertObject.message = alertObject.message.trim();
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }
    return;
  };

  const editInfo = async () => {
    setShowAlert(false);
    setEditableForm(true);
  };

  const cancelModal = (event) => {
    if (!event?.target) return;
    event.stopPropagation(); // Prevent other events from firing on click
    event.preventDefault();
    (closeModal && typeof closeModal === 'function') && closeModal();
  };

  const onReset = (event) => {
    if (!event?.target) return;
    event.stopPropagation(); // Prevent other events from firing on click
    event.preventDefault();
    setEditableForm(false);
    setFormState(previousFormValues);
  };

  useEffect(() => {
    let mounted = true;
    const init = async () => {
      setLoadingForm(true);
      await loadSftpSetup(org_id);
      setLoadingForm(false);
    }
    (mounted) && init();
    return () => mounted = false
  }, [org_id]);

  useEffect(() => {
    let mounted = true;
    const init = () => {
      if (typeof setParentState === 'function') {
        setParentState((prev) => ({ ...prev, ...{ SFTP: formState.SFTP } }));
      }
    }
    (mounted) && init();
    return () => mounted = false
  }, [formState.SFTP]);

  return (
    <div>
      <p>Please provide the SFTP account information below. These will get saved in a secure location that only the application will have access to. We suggest creating an account with limited write permissions to a particular directory.</p>
      <Alert
        dismissible
        onClose={() => setShowAlert(false)}
        show={showAlert}
        variant={modalAlert.alert_type}
      >{modalAlert.message}</Alert>

      <Form noValidate autoComplete="off" onSubmit={onSubmit} onReset={onReset} className="row">
        {Object.entries(formState).filter(([k, v]) => ['SFTP'].includes(k)).map(([key, value], index) => (
          <Form.Group key={`0-${index}-${key}`} controlId={key} className="col-12">
            <StyledGreenCheckbox>
              <Form.Control
                className={previousFormValues[key] !== value && 'changed-field'}
                disabled={!editableForm}
                type="checkbox"
                name={key}
                onChange={handleInputChange}
                checked={value === true || value === "true" ? true : false}
              />
              <span className="ms-2">{key}</span>
            </StyledGreenCheckbox>
          </Form.Group>
        ))}

        {Object.entries(formState).filter(([k, v]) => !['SFTP', 'SFTP_PRIVATE_KEY_CONTENTS', 'SFTP_PUBLIC_KEY_CONTENTS'].includes(k)).map(([key, value], index) => (
          <Form.Group key={`${index}-${key}`} controlId={key} className="col-12">
            <Form.Label className="mt-2 me-2">{humanize(key)}</Form.Label>
            <StyledFormInput
              className={previousFormValues[key] !== value && 'changed-field'}
              disabled={!editableForm}
              type="text"
              placeholder=""
              name={key}
              onChange={handleInputChange}
              autoComplete="off"
              value={value}
            />
          </Form.Group>
        ))}

        {Object.entries(formState).filter(([k, v]) => ['SFTP_PRIVATE_KEY_CONTENTS', 'SFTP_PUBLIC_KEY_CONTENTS'].includes(k)).map(([key, value], index) => (
          <Form.Group key={`3-${index}-${key}`} controlId={key} className="col-12">
            <Form.Label className="mt-3 me-2">{humanize(key)}</Form.Label>
            <Form.Control
              className={previousFormValues[key] !== value && 'changed-field'}
              disabled={!editableForm}
              as="textarea"
              rows={6}
              name={key}
              placeholder={labelPlaceholder[key] ?? `${humanize(key)}`}
              onChange={handleInputChange}
              value={value}
              autoComplete="off"
            />
          </Form.Group>
        ))}

        {editableForm ? (<Col className="col-12 mt-3 d-flex justify-content-end">
          {JSON.stringify(previousFormValues) !== JSON.stringify(formState) && (<Button
            className="btn btn-primary me-3"
            type="submit"
            disabled={loadingForm || !validated}
          >
            Save Changes
          </Button>)}
          <Button
            className="btn btn-secondary"
            type="reset"
            disabled={loadingForm}
          >
            Cancel Edit
          </Button>
        </Col>) : (<Col className="col-12 mt-3 d-flex justify-content-end">
          <Button
            className="btn btn-primary me-3"
            type="button"
            onClick={editInfo}
            disabled={loadingForm}
          >
            Edit
          </Button>
          <Button
            className="btn btn-secondary"
            type="button"
            onClick={cancelModal}
            disabled={loadingForm}
          >
            Close
          </Button>
        </Col>)}
      </Form>
    </div>
  );
}

export { SFTPSetupForm };
export default SFTPSetupForm;