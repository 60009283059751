import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Styled Components
import { ListGroup } from 'react-bootstrap';
import { StyledProgressBar } from "../styled/ProgressBar";
import { StyledListNoPadding } from "../styled/List";

// EREDocs Functions
import { downloader } from "../utils/EREDocs";
import { formatDate, statusFieldsClass, humanize, getUrlPath } from "../utils"

/**
 * CustomProgressBar
 * Renders one of the following: Show Links, Show Status or Show Progress Bar.
 */
const CustomProgressBar = ({
  percentProgress = 0, field = "Documents",
  status = "PENDING", download_status = "PENDING", download_status_details = "",
  exhibited_status = "UNKNOWN", unexhibited_status = "UNKNOWN", custom_status = "UNKNOWN",
  docs_requested = false, media_requested = false, file_downloads = [], showDownloadLinks = true,
} = {}) => {
  const [loadingFile, setLoadingFile] = useState(false);

  percentProgress = parseInt(percentProgress);
  if (field === "Media" && !media_requested || field === "Documents" && !docs_requested) {
    download_status = "NOT_REQUESTED";
  }
  if (download_status_details === null || download_status_details === undefined) {
    download_status_details = "";
  }

  const pdf_status = { exhibited_status, unexhibited_status, custom_status };
  if (field === "Documents" && docs_requested) {
    const ignoredStatus = ["PENDING", "UNKNOWN", "SUCCESS"];
    let pdf_status_text = Object.entries(pdf_status).filter(([k, v]) => !ignoredStatus.includes(v)).map(([key, value]) => `${humanize(value)} ${humanize(key.split("_")[0])}`).join(", ");
    if (pdf_status_text.length > 0) {
      if (download_status_details) {
        download_status_details += " ";
      }
      download_status_details += pdf_status_text;
    }
  }

  const handleDownloadLink = async (event) => {
    if (!event?.target?.href || loadingFile) return;
    event.preventDefault();
    event.stopPropagation(); // stops the handleSocialRowClick from firing on the parent tr element (which is what we want)

    let { href } = event.target || {};
    href = `/${getUrlPath(href)}`;
    if (!href || href === "#" || href === "/eredocs") return;

    try {
      setLoadingFile(true);
      await downloader.getFile(href, ({ status, statusText, data }) => {
        setLoadingFile(false);
        let message = `${status} ${statusText}`;
        (typeof data === 'string') && (message += ` - ${data}`);
        if (![200, 201].includes(status)) {
          message = `Error ${message}`;
        }
        console.log(message);
      });
    } catch (error) {
      let { status, statusText, data } = error?.response || { 'status': 500, 'statusText': "Unknown server error.", data: { err: 500, 'details': error?.message ? error.message : error } };
      let error_message = `Error ${status} - `;
      if (typeof data === 'string') {
        error_message += (statusText === data) ? statusText : data;
      } else if (typeof data === 'object') {
        error_message += data?.message ? `${data?.details ? data.details + ". " : ""}${data.message}` : (data?.details ? data.details : "Unknown server error.");
      }
      console.log(error?.message ? error.message : (error?.stack ? error.stack : error));
      console.log(error_message);
    }

    return;
  };

  // ===============[ useEffect ]==================================
  useEffect(() => {
    let mounted = true;
    const init = async () => {
      setLoadingFile(false);
    }
    (mounted) && init();
    return () => mounted = false;
  }, []);

  // Show Links or Show Status
  if (Math.floor(percentProgress) <= 0 || Math.floor(percentProgress) >= 100) {
    const showLinks = Boolean(Array.isArray(file_downloads) && file_downloads.length > 0 && (field === "Documents" && docs_requested || field === "Media" && media_requested));

    // Show Links
    if (showLinks && percentProgress >= 100) {
      return (<StyledListNoPadding>
        {file_downloads.filter(({ file_type_name = "Exibited" } = {}) => (field === "Media" ? file_type_name === field : file_type_name !== "Media"))
          .map(({ download_link, filename, file_type_name, downloaded_count, last_downloaded, expiresAt, file_css_class = "", file_status, file_label, status_code = 1, ...DLObj }, index) => {
            let linkHref = download_link ? `/eredocs/downloader/file/${download_link.split(/[\\\/]/).slice(-3).join('/')}` : '#';
            let linkStyle = file_css_class;
            let linkText = (field !== "Media") ? file_type_name : (filename ? filename.split(/[\\\/]/).slice(-1).join('/') : (download_link ? download_link.split(/[\\\/]/).slice(-1).join('/') : ""));
            const linkTitle = downloaded_count ? `${linkText} downloaded at ${formatDate(last_downloaded, "MM/DD/YY hh:mm:ss A")}` : `${linkText} ${file_label}${download_status_details ? " (" + download_status_details + ")" : ""}`;

            // Add to LinkText based on DLObj.status.status_code and downloaded_count
            const expiresIn = (new Date(expiresAt || Date.now()) - Date.now());
            if (expiresIn < 0 || file_status === "EXPIRED") {
              linkText += ` expired${expiresAt && " at " + formatDate(expiresAt, "MM/DD/YY hh:mm:ss A")}`;
              linkHref = "#";
              linkStyle = "text-muted";

            } else if (status_code !== 1) {
              linkText += ` - ${file_label}${download_status_details ? " (" + download_status_details + ")" : ""}`;
            } else if (downloaded_count) {
              linkText += ` - Downloaded ${downloaded_count} time${downloaded_count > 1 ? 's' : ''}`;
            }

            return (
              <ListGroup.Item key={`${field}-links-${index}`} >
                {showDownloadLinks && status_code === 1 && expiresIn >= 0 ? (
                  <Link
                    key={`${field}-links-${linkHref}`}
                    to={linkHref}
                    title={linkTitle}
                    onClick={handleDownloadLink}
                  >{loadingFile ? `downloading ${linkText}` : linkText}</Link>
                ) : (
                  <span
                    key={linkTitle}
                    title={linkTitle}
                    className={linkStyle}
                  >{linkText}</span>
                )}
              </ListGroup.Item>
            );
          })}
      </StyledListNoPadding>)

    } else if (download_status) { // Show Download Status
      return (<span className={statusFieldsClass(/\b(unavailable)\b/gi.test(download_status) ? status : download_status)}>
        {(/\b(unavailable)\b/gi.test(download_status) ? humanize(status) : ((download_status_details) ? `${humanize(download_status)} - ${download_status_details}` : humanize(download_status)))}
      </span>)
    } else if (status) { // Show Overall Status
      return (<span className={statusFieldsClass(status)}>{humanize(status)}</span>)
    }

    return null
  }

  // Show Progress Bar
  return (<StyledProgressBar
    now={percentProgress}
    title={(/\b(unavailable)\b/gi.test(download_status) ? humanize(status) : (
      (download_status_details) ? `${humanize(download_status)} - ${download_status_details}` : humanize(download_status)
    ))}
  />);
};

export { CustomProgressBar };
export default CustomProgressBar;
