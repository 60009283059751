import React, { useState, useEffect } from 'react';
import { useUserData } from '../../contexts/AuthContext';
import { Form, Button, Alert, Col } from 'react-bootstrap';
import { StyledInputGroup } from "../../styled/Forms"
import { validParams, humanize, formatPhoneNumber } from "../../utils";
import { bsoaccount_management } from "../../utils/manage";

const UpdateGmailCredentialsForm = ({ auth_id, closeModal = () => { }, setInvokeParentUpdate = () => { } } = {}) => {
  const { userData } = useUserData();
  let { email: default_email, auth_id: default_auth_id,
    passport: {
      user: { email = '',
        selected_auth: {
          auth_id: selected_auth_id,
          attorney_email: selected_google_email,
          text_number: selected_google_voice_number,
        } = {},
      } = {}
    } = {}
  } = userData || {};

  (!email && default_email) && (email = default_email);
  (!auth_id && default_auth_id) && (auth_id = default_auth_id);
  (auth_id && typeof auth_id !== 'number') && (auth_id = Number(auth_id));
  (selected_auth_id && typeof selected_auth_id !== 'number') && (selected_auth_id = Number(selected_auth_id));
  let labelFields = {
    googleVoiceNumber: "google_voice_number",
    gmailEmail: "gmail_email",
    gmailPassword: "gmail_password",
  };

  const [loadingForm, setLoadingForm] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [modalAlert, setModalAlert] = useState({ message: "", alert_type: "info" });
  const [formState, setFormState] = useState({
    googleVoiceNumber: "",
    gmailEmail: "",
    gmailPassword: ""
  });

  const formValidationEmail = (email) => {
    let valid = false;
    const email_regex_ver1 = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,40})+$/;
    const email_regex_ver2 = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email_regex_ver1.test(email) && email_regex_ver2.test(email)) {
      valid = true;
    }
    return valid;
  };

  const handleInputChange = (event) => {
    if (!event?.target) return;
    event.stopPropagation();
    let { name, value, type, checked = false } = event.target;
    (type !== 'checkbox') && event.preventDefault();

    value = (type === 'checkbox') ? checked :
      (name === 'gmailEmail' && value) ? `${value}`.toLowerCase() :
        (name === "googleVoiceNumber" && value) ? formatPhoneNumber(value) : value;
    setFormState((prevState) => ({ ...prevState, [name]: value }));

    // Validate Form on change
    let alertObject = { ...modalAlert };
    let requiredFields = ['googleVoiceNumber', 'gmailEmail', 'gmailPassword'];
    let testParams = Object.entries({ ...formState, [name]: value }).filter(([k, v]) => type === 'checkbox' || v).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
    let valid = validParams(testParams, requiredFields, 'required');

    if (valid) {
      valid = formValidationEmail(testParams.gmailEmail);
      if (valid) {
        valid = /^\d+$/.test(testParams.googleVoiceNumber.replace(/\D+/g, ''));
        if (valid) {
          valid = testParams.googleVoiceNumber.replace(/\D+/g, '').length >= 10;
          if (!valid) {
            alertObject.message = "Please enter a phone number with at least 10 digits.";
          }
        } else {
          alertObject.message = "Please enter a valid phone number with numeric characters only.";
        }
      } else {
        alertObject.message = "Please enter a valid email address";
      }
    } else {
      alertObject.message = "Please fill out all required fields. Missing " + requiredFields.filter((f) => !testParams[f]).map((k) => humanize(labelFields[k] ?? k)).join(', ');
    }

    setValidated(valid);
    setShowAlert(!valid);
    if (alertObject.message) {
      alertObject.message = alertObject.message.trim();
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }
    return;
  };

  // onReset 
  const cancelUpdateGmailCredentials = (event) => {
    if (event) {
      event.stopPropagation(); // Prevent other events from firing on click
      event.preventDefault();
    }
    setFormState((prev) => ({
      ...prev,
      googleVoiceNumber: selected_auth_id === auth_id ? selected_google_voice_number : "",
      gmailEmail: selected_auth_id === auth_id ? selected_google_email : "",
      gmailPassword: ""
    }));
    if (closeModal && typeof closeModal === 'function') {
      closeModal();
    }
  }

  // onSubmit 
  const updateGmailCredentials = async (event) => {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation();
    let alertObject = { ...modalAlert };
    let payload = { auth_id, ...formState };
    let response;

    try {
      if (!validated) {
        throw new Error("Your form input is not valid. Please check your input and try again.");
      }

      setLoadingForm(true);
      response = await bsoaccount_management.updateSecrets(payload);
      alertObject.message = "Saved Successfully";
    } catch (ex) {
      console.log(ex);
      response = ex?.response || {};
      response.status = 500;
      response.statusText = `${ex.message}`;
      if (!response?.data) {
        response.data = { ok: false, error: ex.error, statusMessage: `${ex.message}`, };
      }
    } finally {
      setLoadingForm(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    let { ok = false, statusCode = 500, statusMessage = "Internal Server Error", error, ARN, Name: SecretId, VersionId, data: SecretObject = null } = data || {};

    alertObject.alert_type = (status !== 200 || !ok) ? "danger" : "success";
    if (status !== 200 || !ok) {
      alertObject.message = `[${status}] ${statusText}`;
    } else {
      let newState = (selected_auth_id === auth_id) ? {
        googleVoiceNumber: selected_google_voice_number,
        gmailEmail: selected_google_email,
        gmailPassword: ""
      } : {
        googleVoiceNumber: "",
        gmailEmail: "",
        gmailPassword: ""
      };
      setFormState((prev) => ({ ...prev, ...newState }));
    }
    alertObject.message += statusMessage ? `${statusMessage} ` : "";
    alertObject.message += SecretId ? ` SecretId: ${SecretId} ` : "";
    alertObject.message += VersionId ? ` VersionId: ${VersionId} ` : "";
    alertObject.message += error ? `${error} ` : "";

    // Show Alert success or danger
    if (alertObject?.message) {
      alertObject.message = alertObject.message.trim();
      setShowAlert(true);
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }

    if (typeof setInvokeParentUpdate === 'function') {
      setInvokeParentUpdate((prevState) => !prevState);
    }
    return;
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setLoadingForm(false);
      setValidated(false);
      setShowAlert(true);
      setModalAlert((prevState) => ({ ...prevState, message: "Please ensure you enter all of the required information.", alert_type: "info" }));
      let newState = (selected_auth_id === auth_id) ? {
        googleVoiceNumber: selected_google_voice_number,
        gmailEmail: selected_google_email,
        gmailPassword: ""
      } : {
        googleVoiceNumber: "",
        gmailEmail: "",
        gmailPassword: ""
      };
      setFormState((prev) => ({ ...prev, ...newState }));
    }
    return () => mounted = false
  }, []);

  return (
    <div>
      <p>For security purposes, GMAIL credentials are only passed through this form to avoid exposure to unauthorized entities. Credentials are stored in a secure vault that only the application has access to.</p>
      <Alert
        dismissible
        onClose={() => setShowAlert(false)}
        show={showAlert}
        variant={modalAlert.alert_type}
      >{modalAlert.message}</Alert>

      <Form validated={validated} onSubmit={updateGmailCredentials} onReset={cancelUpdateGmailCredentials} autoComplete={"off"} >
        <StyledInputGroup className="row">
          {Object.entries(formState).map(([key, value], index) => (
            <Col key={`${index}-${key}`} xs={12} md={4} lg={4}>
              <Form.Label className="mt-3 me-2">{humanize(labelFields[key] ?? key)}</Form.Label>
              <Form.Control
                disabled={loadingForm}
                type="text"
                placeholder=""
                name={key}
                value={value}
                onChange={handleInputChange}
                autoComplete="off"
                required
              />
            </Col>
          ))}
        </StyledInputGroup>

        <Col className="col-12 mt-3 d-flex justify-content-end">
          <Button className="btn btn-primary me-3" type="submit" disabled={loadingForm || !validated}>{loadingForm ? "Loading..." : "Save Changes"}</Button>
          <Button className="btn btn-secondary" type="reset" disabled={loadingForm}>{loadingForm ? "Loading..." : "Cancel"}</Button>
        </Col>
      </Form>
    </div>
  );
}

export { UpdateGmailCredentialsForm };
export default UpdateGmailCredentialsForm;