import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useUserData } from '../../contexts/AuthContext';
import { ChangePasswordForm } from '../Forms';

const ChangePasswordModal = ({ user_id, ...props } = {}) => {
  const { userData } = useUserData();
  let { passport: { user: { user_id: default_user_id } = {} } = {} } = userData || {};

  // Clean up variables if needed 
  (!user_id && default_user_id) && (user_id = default_user_id);
  typeof user_id !== 'number' && (user_id = parseInt(user_id));

  const [modalState, setModalState] = useState(false);
  const openModal = () => (setModalState(true));
  const closeModal = () => (setModalState(false));

  return (<span {...props} >
    <Button onClick={() => openModal()}>Change Password</Button>
    <Modal show={modalState} onHide={closeModal} animation={false} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Change Password
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ChangePasswordForm user_id={user_id} closeModal={closeModal} />
      </Modal.Body>
    </Modal>
  </span>);
}

export { ChangePasswordModal };
export default ChangePasswordModal;