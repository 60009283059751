import styled from "styled-components";
import { Accordion } from 'react-bootstrap';

export const StyledAccordionDownloader = styled(Accordion)`
  & .accordion-button {
    font-weight: bold; 
    color: var(--white); 
    background-color: var(--primary-color) !important; 
    border: none !important;
  }

  & .accordion-button::after {
    width: ${({ 'data-background-size': backgroundSize = '1.25rem' }) => backgroundSize}; 
    height: ${({ 'data-background-size': backgroundSize = '1.25rem' }) => backgroundSize}; 
    background-size: ${({ 'data-background-size': backgroundSize = '1.25rem' }) => backgroundSize}; 
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23${({ 'data-fill': fill = 'ffffff' }) => fill}'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }

  & .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23${({ 'data-active-fill': activeFill = '2f9b2f' }) => activeFill}'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }

  & .accordion-body {
    background-color: var(--white) !important;
    border: 1px solid var(--light-grey) !important;
  }
`;

