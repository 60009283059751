import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useUserData } from "../contexts/AuthContext";
import { Alert, Table, ListGroup, Row, Col, Button } from 'react-bootstrap'; // Bootstrap Components
import { StyledListNoBorder } from "../styled/List"
import CustomProgressBar from "../components/CustomProgressBar";
import Loader from "../components/Loader";

// EREDocs Functions 
import { downloader } from "../utils/EREDocs";
import { humanize, dehumanize, massageData, isObject, splitArrayChunks, statusFieldsClass, getUrlPath } from "../utils/";
import downloadRequestDetails from '../fake-data/downloadRequestDetails.json' // When loadFakeData = true

// Globals
const disableDownloadRowButton = true; // true will hide download button on each row 
const OVERRIDE_LOGIN = process.env.REACT_APP_OVERRIDE_LOGIN === "true";
const loadFakeData = process.env.REACT_APP_LOAD_FAKE_DATA === "true";
const SECONDS_REFRESH_DETAILS_LOGS = Number(process.env.REACT_APP_SECONDS_REFRESH_DETAILS_LOGS || '5'); // Refresh logs every 5 seconds. Zero means no refresh.
const IN_PROGRESS_STATUS = ['CREATED', 'PENDING', 'PROCESSING', 'INITIATING', 'INITIATED']; // Also referred to as SOCIAL_PENDING_STATUS
const SOCIAL_SUCCESS_STATUS = ['CANCELLED', 'READY', 'SUCCESS'];
// const SOCIAL_FAILED_STATUS = ['FAIL', 'FAILED', 'READY_WITH_ERRORS', 'BLOCKED', 'DELETED', 'NOT_IN_BSO'];
// const SOCIAL_FINISH_STATUS = [...SOCIAL_SUCCESS_STATUS, 'BLOCKED', 'DELETED', 'NOT_IN_BSO'];
const defaultQueryParams = { limit: 25, showDebug: false };
let IntervalId = null;

/**
 * @param {*} props 
 * @returns 
 */
const DownloadRequestDetails = ({ request_id: default_request_id, ...props } = {}) => {
  let { request_id } = useParams();
  if (!request_id && default_request_id) {
    request_id = default_request_id; // Override request_id with passed in request_id from props
  }
  const navigate = useNavigate();
  const { userData } = useUserData();
  let { isAuthenticated = false, passport: { user: { group_id = 6 } = {} } = {}, } = userData || {};

  const [invokeUpdate, setInvokeUpdate] = useState(false);
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);

  const [requestDetails, setRequestDetails] = useState([]);
  const [appliedPDFSettings, setAppliedPDFSettings] = useState({});
  const [socialHeader, setSocialHeader] = useState([]);
  const [socialRows, setSocialRows] = useState([]);

  const [Pages, setPages] = useState([1]);
  const [activePage, setActivePage] = useState(1);
  const [showAlert, setShowAlert] = useState(false);
  const [pageAlert, setPageAlert] = useState({ message: "", alert_type: "info" });
  const socialLogsRef = useRef();

  let adminAccess = (isAuthenticated && Number(group_id) === 2) || OVERRIDE_LOGIN;
  const showDownloadLinks = !adminAccess; // We don't want firm admins to see download links
  const showCancelAllButton = (Array.isArray(socialRows) && socialRows || []).every((row = {}) => ((IN_PROGRESS_STATUS.includes(row?.social_status))));
  const showDownloadAllButton = !showCancelAllButton
    && (Array.isArray(socialRows) && socialRows || []).every((row = {}) => (
      SOCIAL_SUCCESS_STATUS.includes(row?.social_status)
      && (Array.isArray(row?.file_downloads) && row?.file_downloads || []).every(({ request_zip = false, expiresAt = Date.now(), status_code = 3 } = {}) => (
        Boolean(request_zip) && (new Date(expiresAt || Date.now()) - Date.now()) >= 0 && status_code !== 3
      ))
    ));

  /**
   * handleClick 
   * When action is download we'll prepare the href for downloading the file otherwise just navigate to the href 
   * @param {*} event 
   * @param {Object} params - {
   *  action - 'download' | 'navigate' | 'cancel' | 'cancel-all', 
   *  href - url string to download file or navigate to,
   * }
   * @returns 
   */
  const handleClick = async (event, { href, action = "navigate", request_id, request_social_id } = {}) => {
    if (!event || loadingFile) return;
    event.preventDefault();
    event.stopPropagation();

    if (['cancel', 'cancel-all'].includes(action)) {
      if (!request_id && (event?.target?.dataset?.request_id)) {
        request_id = event?.target?.dataset?.request_id;
      }
      if (!request_social_id && (event?.target?.dataset?.request_social_id)) {
        request_social_id = event?.target?.dataset?.request_social_id;
      }
      if (window.confirm(`Are you sure you want to cancel ${(action === "cancel-all") ? "request_id: " + request_id : "request_social_id: " + request_social_id}?`)) {
        let response;
        try {
          if (action === "cancel-all") {
            if (!request_id) {
              throw new Error("request_id is required for cancel-all");
            }
            response = await downloader.cancelRequest(request_id);
          } else {
            if (!request_social_id) {
              throw new Error("request_social_id is required for cancel");
            }
            response = await downloader.cancelSocialRequest(request_social_id);
          }
        } catch (error) {
          console.log(error);
          response = error?.response || {};
          if (!response?.data) {
            response.data = { err: 401, error: error.error, details: `${error.message}` };
          }
        }

        let { status, statusText = '', data = {} } = response;
        let { err, error, details, message, success = false } = data || {};
        if (status !== 200 || !success) {
          let alert_type = "danger";
          let alert_message = `[${status ?? err}] ${statusText} `; // API Error Code 
          alert_message += data?.status ? `${data.status} ` : "";  // API Status 
          alert_message += error ? `${error} ` : "";               // API Error 
          alert_message += (typeof response?.data === 'string') ? response.data : message ?? (details ? `${details} ` : "") + `Failed to read data for request details.`;
          alert_message = alert_message.trim();
          setShowAlert(true);
          setPageAlert(prevState => ({ ...prevState, message: alert_message, alert_type }));
        }
      }
      return setInvokeUpdate(prev => !prev); // Exit if action is cancel or cancel-all
    }

    if (!href && (event?.target?.href || event?.target?.dataset?.href)) {
      href = event?.target?.href || event?.target?.dataset?.href;
    }
    if (!href || href === "#" || href === "/eredocs") return;

    if (action === "navigate") {
      navigate(href); // Will not work with <Link> component or <a> tag. Just use Link and a tag naturally instead of navigate.
      return; // Exit if action is navigate
    }

    // Prepare href for downloading a file 
    href = `/${getUrlPath(href)}`;
    if (href === "#" || href === "/eredocs" || !showDownloadLinks) return;

    try {
      setLoadingFile(true);
      await downloader.getFile(href, ({ status, statusText, data }) => {
        setLoadingFile(false);
        let message = `${status} ${statusText}`;
        (typeof data === 'string') && (message += ` - ${data}`);
        if (![200, 201].includes(status)) {
          message = `Error ${message}`;
        } else {
          setInvokeUpdate(prev => !prev); // Exit if action is download
        }
        console.log(message);
      });
    } catch (error) {
      let { status, statusText, data } = error?.response || { 'status': 500, 'statusText': "Unknown server error.", data: { err: 500, 'details': error?.message ? error.message : error } };
      let error_message = `Error ${status} - `;
      if (typeof data === 'string') {
        error_message += (statusText === data) ? statusText : data;
      } else if (typeof data === 'object') {
        error_message += data?.message ? `${data?.details ? data.details + ". " : ""}${data.message}` : (data?.details ? data.details : "Unknown server error.");
      }
      console.log(error?.message ? error.message : (error?.stack ? error.stack : error));
      console.log(error_message);
    }
    return
  };

  /**
   * handleSocialRowClick
   * Extracts row items and save to rowObject and selectedItem
   * @param {*} event 
   * @returns 
   */
  const handleSocialRowClick = (event) => {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation(); // Prevent other events from firing on click

    let clickedElement = event?.target || {};
    let { parentElement, dataset, tagName, type, innerText, href, id } = clickedElement;
    let action = (href || ["BUTTON", "SPAN"].includes(tagName)) ? (
      `${innerText}`.toLowerCase().includes("download") ? "download" : (
        `${innerText}`.toLowerCase().includes("cancel") ? "cancel" : "navigate"
      )
    ) : false;

    // Go up one level in the DOM but preserve innerText
    if (tagName !== "TD" && parentElement?.tagName === "TD") {
      clickedElement = parentElement;
      parentElement = clickedElement.parentElement;
      dataset = { ...dataset, ...clickedElement?.dataset };
      tagName = clickedElement?.tagName;
      if (!innerText && clickedElement?.innerText) {
        innerText = clickedElement.innerText;
      }
    }

    let rowObject = {
      innerText,
      tagName,
      ...(type && { type }),
      ...dataset,
      ...parentElement?.dataset,
    };

    // Extract row items and save to rowObject and selectedItem
    let selectedItem = {};
    if (tagName === "TD") {
      let tdArray = Array.from(parentElement.querySelectorAll("td")).map(({ innerText }) => innerText).filter((i) => i) || [];
      let thArray = Array.from(parentElement.closest("table").querySelectorAll("thead tr th")).map(({ innerText }) => dehumanize(innerText)).filter((i) => i) || [];

      if ('col' in rowObject) { // Extract selectedItem
        let col = Number(rowObject.col);
        if (col && innerText === tdArray[col]) {
          selectedItem[thArray[col]] = tdArray[col];
        }
      }

      // Match thArray length with tdArray length
      if (tdArray.length > thArray.length) {
        // slice extra elements to match thArray length
        tdArray = tdArray.slice(0, thArray.length);
      } else if (tdArray.length < thArray.length) {
        // Fill in the blanks with null values to match thArray length
        let startIndex = tdArray.length;
        tdArray.length = thArray.length;
        tdArray.fill(null, startIndex);
      }

      if (tdArray.length === thArray.length) {
        for (let i = 0; i < thArray.length; i++) {
          rowObject[thArray[i]] = tdArray[i];
        }
      }
    }

    // Extract selectedItem if failed to do so above
    if ('field' in rowObject) {
      let field = dehumanize(rowObject.field); // We must dehumanize this field since that's what happened to all of values in thArray (see above)
      if (!selectedItem[field] && rowObject[field] === innerText) {
        selectedItem[field] = rowObject[field];
      }
    }
    // Object.keys(rowObject).length > 0 && console.log("handleSocialRowClick", rowObject);
    // Object.keys(selectedItem).length > 0 && console.log("selectedItem", selectedItem);

    if (action) {
      let { request_id, request_social_id } = rowObject;
      if (!request_id) {
        console.log("Missing request_id in rowObject:", rowObject);
        return;
      }
      if (["download", "navigate"].includes(action)) {
        if (!href) {
          let fileName = (request_social_id) ? `request_social_id_${request_social_id}.zip` : `request_id_${request_id}.zip`;
          href = `eredocs/downloader/file/${request_id}/0/${fileName}`;
        }
      } else if (action === "cancel") {
        if (id === `cancel-all-btn-${request_id}` || !request_social_id) {
          action = "cancel-all";
        }
      }
      return handleClick(event, { href, action, request_id, request_social_id });
    }
    return;
  };

  /**
   * getSocialData
   * startedAt - timestamp of when the request was started
   * functionFields - These fields are used in the functions and not displayed in the table 
   * 
   * @param {Object} params 
   * @returns 
   */
  const docProgressFields = ['document_percentage', 'docs_processed', 'docs_to_process', 'docs_requested', 'exhibited_status', 'unexhibited_status', 'custom_status'];
  const mediaProgressFields = ['media_percentage', 'media_processed', 'media_to_process', 'media_requested'];
  const skipFields = ['request_id', 'claimant_id', 'matter_id', 'file_downloads', 'download_status_details', ...docProgressFields, ...mediaProgressFields]; // These fields are used by functions and should not be displayed
  const getSocialData = async ({ startedAt = Date.now(), functionFields = skipFields } = {}) => {
    let now = Date.now();
    let duration_seconds = Math.round((now - startedAt) / 1000);
    let ran_count = !SECONDS_REFRESH_DETAILS_LOGS ? 1 : Math.round(duration_seconds / SECONDS_REFRESH_DETAILS_LOGS) || 1; // Avoid dividing by zero
    let first_run = now === startedAt || ran_count === 1;

    // Detect if user navigated away from this page 
    let offsetWidth = socialLogsRef?.current?.offsetWidth || 0;
    let offsetHeight = socialLogsRef?.current?.offsetHeight || 0;
    let navigated_away = !socialLogsRef?.current || !(offsetWidth > 0 && offsetHeight > 0);

    if (!first_run && (navigated_away || !isAuthenticated)) {
      console.log("Clearing Interval for details because user navigated away.", { IntervalId, first_run, navigated_away, isAuthenticated, offsetWidth, offsetHeight });
      clearInterval(IntervalId);
      return;
    }

    let patchData = { ...defaultQueryParams, page: activePage };
    let alert_message = '';
    let alert_type = "info";
    let response;

    try {
      if (loadFakeData) {
        response = {
          status: 200,
          statusText: 'OK',
          data: downloadRequestDetails
        };
      } else {
        response = await downloader.getSocialDetails(request_id, patchData);
      }
    } catch (error) {
      console.log(error);
      response = error?.response || {};
      if (!response?.data) {
        response.data = { err: 401, error: error.error, details: `${error.message}` };
      }
    }

    let { status, statusText = '', data = {} } = response;
    let { err, error, details, message, rows = [], count = 0, pageCount = 1, pages = [] } = data || {};

    if (status === 200) {
      // Get data_details
      let details_fields = ['request_id', 'requested_by', 'request_status', 'socials_processed', 'socials_to_process', 'Requested', 'Batch ID', 'request_type'];
      let renamed_fields = {
        request_queue_batch: "Batch ID",
        requested_timestamp: "Requested",
      };
      const date_fields = ['requested_timestamp', 'Requested', 'createdAt', 'updatedAt'];
      const special_fields = ['socials_processed'];
      const special_field_columns = {
        'socials_processed': ['socials_processed', 'of', 'socials_to_process']
      };

      let data_details = !isObject(rows[0]) ? [] : massageData(rows[0], details_fields, renamed_fields, date_fields, special_fields, special_field_columns);

      // Get dataHeader
      let dataHeader = ['ID', 'QID', 'claimant_name', 'SSN', 'social_status', 'Documents', 'Media', ...functionFields];
      renamed_fields = {
        request_social_id: "ID",
        social_status_details: "download_status_details",
        document_status: "Documents",
        media_status: "Media",
      };

      // Get dataRows
      let dataRows = (!Array.isArray(rows) || rows.length === 0) ? [] : massageData(rows, dataHeader, renamed_fields);

      // Get pdf_settings
      let { pdf_settings = {} } = rows[0] || {};
      if (typeof pdf_settings === 'string') {
        try {
          pdf_settings = JSON.parse(pdf_settings);
        } catch (e) {
          pdf_settings = {};
        }
      } else if (!isObject(pdf_settings)) {
        pdf_settings = {};
      }

      if (rows.length === 0 || count === 0) {
        if (activePage > 1 && activePage === Pages[Pages.length - 1]) {
          setActivePage(Pages[Pages.length - 2]); // Make sure we don't get stuck loading nothing
        } else {
          alert_type = "info";
          alert_message = "No logs found";
        }
      }

      pages = pages.reduce((acc, item, index) => {
        if (index === 0 && item.number !== 1) {
          acc.push(1); // Always include the first page 
        }

        acc.push(item.number);

        if (index === (pages.length - 1) && !acc.includes(pageCount)) {
          acc.push(pageCount); // Always include the last page
        }
        return acc;
      }, []);

      setRequestDetails(data_details);
      setAppliedPDFSettings(pdf_settings);
      setSocialHeader(dataHeader);
      setSocialRows(dataRows);
      setPages(pages);
    } else {
      alert_type = "danger";
      alert_message = `[${status ?? err}] ${statusText} `;    // API Error Code 
      alert_message += data?.status ? `${data.status} ` : ""; // API Status 
      alert_message += error ? `${error} ` : "";              // API Error 
      alert_message += (typeof response?.data === 'string') ? response.data : message ?? (details ? `${details} ` : "") + `Failed to read data for request details.`;
      alert_message = alert_message.trim();
    }

    if (alert_message) {
      setShowAlert(true);
      setPageAlert(prevState => ({ ...prevState, message: alert_message, alert_type }));
    }

    if (first_run && SECONDS_REFRESH_DETAILS_LOGS) {
      if (IntervalId !== null) {
        console.log("Clearing Interval for details because new interval is being started.", { IntervalId });
        clearInterval(IntervalId);
      }
      let interval_ms = SECONDS_REFRESH_DETAILS_LOGS * 1000;
      IntervalId = setInterval(getSocialData.bind(null, { startedAt, functionFields }), interval_ms); // Next render every SECONDS_REFRESH_DETAILS_LOGS seconds
      console.log("Set Interval for details!", { IntervalId, activePage, loadingLogs, startedAt });
    }

    // console.log("getSocialData", { first_run, IntervalId, ran_count, duration_seconds, now, startedAt, navigated_away, isAuthenticated, offsetWidth, offsetHeight, status, statusText });
    return data;
  };

  // ===============[ useEffect ]==================================
  useEffect(() => {
    const init = async () => {
      setShowAlert(false);
      setLoadingLogs(true);
      await getSocialData().then((sData) => {
        setLoadingLogs(false);
        setLoadingFile(false);
      });
    }

    let mounted = true;
    if (mounted) {
      if (IntervalId !== null) {
        console.log("Clearing Interval for details because changed detected", { IntervalId });
        clearInterval(IntervalId);
      }
      init(); // Initialize Request Data for Table
    }

    // Returned function will be called on component unmount 
    return () => {
      if (IntervalId !== null) {
        console.log("Clearing Interval for details because this component unmounted.", { IntervalId });
        clearInterval(IntervalId);
      }
      mounted = false;
    }
  }, [invokeUpdate, activePage]);

  return (
    <div className="page-container" ref={socialLogsRef} {...props}>
      <h4>Download Request Details</h4>
      <hr />
      <Alert
        dismissible
        onClose={() => setShowAlert(false)}
        show={showAlert}
        variant={pageAlert.alert_type}
      >{pageAlert.message}</Alert>

      <Row id="request-detail-summary" className="mb-4">
        <Col>
          <StyledListNoBorder>
            {requestDetails.map((dataObj, index) => (Object.entries(dataObj).map(([key, value]) => (<ListGroup.Item key={`${index}-${key}`}>
              <span className="bold-text">{`${key === "request_status" ? "Status" : humanize(key)}: `}</span>
              <span {...("request_status" === key && { className: statusFieldsClass(value) })}>{"request_status" === key ? humanize(value) : value}</span>
            </ListGroup.Item>))))}
          </StyledListNoBorder>
        </Col>
        <Col>
          <Row>
            <span className="bold-text">Applied PDF Settings</span>
            {isObject(appliedPDFSettings) && Object.keys(appliedPDFSettings).length > 0 && splitArrayChunks(Object.entries(appliedPDFSettings), 7).map((chunk, idx) => (<Col key={`chunk-${idx}`}>
              <StyledListNoBorder key={`chunk-ul-${idx}`}>{chunk.map(([key, value], index) => (
                <ListGroup.Item key={`${index}-${key}`}>{humanize(key)}: {typeof value !== 'string' ? humanize(value) : value}</ListGroup.Item>
              ))}</StyledListNoBorder>
            </Col>))}
          </Row>
        </Col>
      </Row>

      <h5>Socials included in the request</h5>

      {loadingLogs ? (<Loader className="text-info" style={null} />) : (<Table hover>
        <thead>
          <tr>{socialHeader.filter((k) => !skipFields.includes(k)).map((header, index, arr) => (<th
            key={`${index}-${header}`}
            {...(index === arr.length - 1 && !(showCancelAllButton || showDownloadAllButton)) && { colSpan: 2 }}
          >{header.includes("_") ? (header === "social_status" ? "Status" : humanize(header)) : header}
          </th>))}{showDownloadLinks && (showCancelAllButton || showDownloadAllButton) && (<th>
            <Button
              data-status={requestDetails[0]?.request_status}
              data-request_id={requestDetails[0]?.request_id}
              id={`${showCancelAllButton ? "cancel" : "download"}-all-btn-${requestDetails[0]?.request_id}`}
              type={showCancelAllButton ? "reset" : "submit"}
              size="sm"
              variant="danger"
              onClick={handleSocialRowClick}
            >{showCancelAllButton ? "Cancel Remaining" : (loadingFile ? "Downloading Zip File" : "Download All")}</Button>
          </th>)}</tr>
        </thead>
        <tbody>{Array.isArray(socialRows) && socialRows.length ? socialRows.map((row = {}, index) => {
          const showCancelButton = IN_PROGRESS_STATUS.includes(row?.social_status);
          const showDownloadButton = !showCancelButton && !disableDownloadRowButton
            && SOCIAL_SUCCESS_STATUS.includes(row?.social_status)
            && (row?.file_downloads || []).every(({ request_zip = false, expiresAt = Date.now(), status_code = 3 } = {}) => (
              Boolean(request_zip) && (new Date(expiresAt || Date.now()) - Date.now()) >= 0 && status_code !== 3
            ));

          return (<tr
            onClick={handleSocialRowClick}
            key={`${index}-${row.ID}`}
            data-row={index}
            data-request_id={row?.request_id}
            data-request_social_id={row.ID}
            data-claimant_id={row.claimant_id}
            data-matter_id={row.matter_id}
            data-status={row?.social_status}
            data-exhibited_status={row.exhibited_status}
            data-unexhibited_status={row.unexhibited_status}
            data-custom_status={row.custom_status}
          >{Object.entries(row).filter(([k, v]) => !skipFields.includes(k)).map(([key, value], idx) => {
            let progressBarColumns = ["Documents", "Media"];
            let percentageFields = {
              Documents: "document_percentage",
              Media: "media_percentage",
            };

            return (<td key={`${index}-${key}`} data-col={idx} data-field={key} {...(["social_status", ...progressBarColumns].includes(key) && { className: statusFieldsClass(value) })}>
              {progressBarColumns.includes(key) ? (<CustomProgressBar
                showDownloadLinks={showDownloadLinks}
                percentProgress={row[percentageFields[key]] || 0}
                field={key}
                download_status={value} // document_status or media_status
                download_status_details={row.download_status_details}
                status={row?.social_status} // Overall status of the social
                exhibited_status={row.exhibited_status}
                unexhibited_status={row.unexhibited_status}
                custom_status={row.custom_status}
                docs_requested={row.docs_requested}
                media_requested={row.media_requested}
                file_downloads={row?.file_downloads}
              />) : (key === "claimant_name" ? (
                <Link to={`/claimant-details/${row.claimant_id}`} title={`claimant_id=${row.claimant_id} matter_id=${row.matter_id}`} onClick={(e) => e.stopPropagation()}>{humanize(value)}</Link>
              ) : humanize(value))}
            </td>);
          })}<td>{(showCancelButton || showDownloadButton) && (<Button
            data-request_id={row?.request_id}
            data-request_social_id={row.ID}
            data-status={row?.social_status}
            id={`${showCancelButton ? "cancel" : "download"}-request_social_id-${row.ID}`}
            type={showCancelButton ? "reset" : "submit"}
            size="sm"
            variant="danger"
          >{showCancelButton ? "Cancel" : "Download"}</Button>)}</td></tr>)
        }) : (<tr><td colSpan={socialHeader.filter((k) => !skipFields.includes(k)).length}>No socials included in the request.</td></tr>)}
        </tbody>
        {Pages.length > 1 && (<tfoot>
          <tr>
            <td className="text-right" colSpan={socialHeader.filter((k) => !skipFields.includes(k)).length + 1} >
              <nav>
                <ul className="pagination justify-content-end" data-active-page={activePage} >
                  {Pages && Pages.length > 0 && Pages.map((pageNumber, index) => (
                    <li key={`page-${pageNumber}-${index}`} className={`page-item ${pageNumber === activePage ? "disabled" : ""}`} >
                      <Link
                        to={`#page-${pageNumber}`}
                        className="page-link"
                        onClick={(e) => {
                          e.preventDefault();
                          setActivePage(pageNumber);
                        }}
                        {...(pageNumber === activePage) && { tabIndex: -1 }}
                      >{pageNumber}</Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </td>
          </tr>
        </tfoot>)}
      </Table>)}
    </div>
  );
}

export default DownloadRequestDetails;
