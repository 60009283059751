import React, { useContext, useState, useEffect } from "react";
import swal from "sweetalert";
import EREDocs from "../utils/EREDocs";
import API from "../utils/API";
import { getParams, formatDate, AlertMessage } from "../utils";

const AuthContext = React.createContext(-1);
const useUserData = () => useContext(AuthContext);
const MINUTE_MS = 60 * 1000; // 1minute in milliseconds
const OVERRIDE_LOGIN = process.env.REACT_APP_OVERRIDE_LOGIN && process.env.REACT_APP_OVERRIDE_LOGIN === "true";
const CHECK_BSO_COOKIES_INTERVAL_MS = 1000 * Number(process.env.REACT_APP_CHECK_BSO_COOKIES_INTERVAL_SECONDS || 15 * 60); // In seconds (default 15minutes)
const BSO_COOKIES_EXPIRATION_MS = MINUTE_MS * Number(process.env.REACT_APP_BSO_COOKIES_EXPIRATION_MINUTES || '105') // In Minutes (default 105minutes = 1hr 45minutes)

const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [sessionIntervalId, setSessionInterval] = useState(null); // Interval for checkUserSession()
  const [startedIntervalAt, setStartedIntervalAt] = useState(Date.now()); // Used to re-create a counter to count how many times we checkUserSession()
  const [userData, setUserData] = useState({
    status: "READY",
    USE_BOTTOM_QUEUE_OPTION: false,
    token: "",
    email: "",
    request_id: null,       // Do we have an active request_id processing? If true than lock out the downloader until it's finished
    isAuthenticated: false, // Are we logged into our portal?
    bsoLoggedIn: false,     // Do we have an active session on BSO site that is completely logged in? IFF req.session.bso.cookies exists than /user/session will set this TRUE
    AuthT: null,            // cookie creation timestamp that comes directly from the cookies object. 
    awaitingOTP: false,     // Are we waiting for a one time passcode? False when we are past the one time passcode 
    twofactor_iat: null,    // timestamp of when the two factor code should have been sent to the gmail 
    text_number: null,      // phone number that should have been sent the text for 2fa
    server_autologin: false, // await EREDocs.bso.tryAutoLogin() will only run if this is TRUE 
    client_autologin: false, // OTP on BSOLogin page will only run if this is TRUE 
    ere_ssn_error_notification: 1,
    strikeCount: 0,
    referrer: "/download-requests", // The referrer of the user
  });

  const [alertMessage, setAlertMessage] = useState({
    message: "",
    alert_type: "info",
    append_after_element: "main-section"
  });

  // =========================[ handleLogin ]==============================
  const handleLogin = async ({ email, password }) => {
    let loginData = { email, password };
    setLoading(true);

    let response = await API.login(loginData);
    let { status, responseText, data } = response;

    let message_type = "danger";
    let alert_message = data.hasOwnProperty("message") ? data.message : ((responseText !== undefined && responseText.hasOwnProperty("message")) ? `${responseText.message}` : "");

    if (status === 200 && data.ok && data.logged_in) {
      let current_user_session = OVERRIDE_LOGIN ? { data: { logged_in: false } } : await API.getUserSession();
      let { passport: { user: { user_id, auth_id, ere_access: default_user_ere_access = false, group_id = 6 } = {} } = {} } = current_user_session?.data || {};
      (default_user_ere_access && typeof default_user_ere_access === 'string') && (default_user_ere_access = default_user_ere_access === 'true');
      (group_id && typeof group_id === 'string') && (group_id = Number(group_id));
      let hasEREAccess = !!default_user_ere_access;
      let isAuthenticated = !!current_user_session?.data?.logged_in
      let AdminAccess = (isAuthenticated && [1, 2].includes(group_id)) || OVERRIDE_LOGIN;

      setUserData(prevState => ({
        ...prevState,
        ...current_user_session.data,
        awaitingOTP: false,
        isAuthenticated,
        referrer: !hasEREAccess && AdminAccess ? "/firm-management" : "/download-requests",
      }));

      if (status === 200) {
        message_type = "success";
      }

    } else {
      setUserData(prevState => ({
        ...prevState,
        email: email,
        token: null,
        isAuthenticated: false
      }));

      alert_message = `Error: ${alert_message}`;

      if (status !== 200) {
        message_type = "danger";
      }
    }

    setAlertMessage(prevState => ({
      ...prevState,
      message: alert_message,
      alert_type: message_type,
    }));

    setLoading(false);
    return;
  }

  // =========================[ handleLogout ]=========================================
  const handleLogout = (event) => {
    setLoading(true);

    event.preventDefault();
    API.logout().then((response) => {
      let { status, data } = response;
      if (status === 200 && data.ok) {
        setUserData({
          token: "",
          email: "",
          isAuthenticated: false
        });
      }
    }).catch(error => {
      setAlertMessage(prevState => ({
        ...prevState,
        message: "Logout error: " + error,
        alert_type: "danger",
      }));
    });

    setLoading(false);
    return;
  };

  // =========================[ handleBSOLogout ]=========================================
  const handleBSOLogout = async (event, { bso_username, auth_id } = {}) => {
    if (event) {
      event.preventDefault();
    }
    setLoading(true);

    let popUpMessage = "Are you sure you want to log out of the BSO?";
    let tmp = '';
    if (bso_username) {
      tmp += `bso_username: ${bso_username} `;
    }
    if (auth_id) {
      tmp += `auth_id: ${auth_id}`;
    }
    if (tmp) {
      popUpMessage += ` (${tmp.trim()})`;
    }
    if (!window.confirm(popUpMessage)) {
      return;
    }

    try {
      // keepSessionAlive is not a full BSO logout because we do need the bso session to stay active. We are just tricking the front end to get new cookies. 
      let params = {
        keepSessionAlive: true,
        ...(bso_username && { bso_username }),
        ...(auth_id && { auth_id }),
      };
      let { status, data } = await EREDocs.bso.logout(params);
      if (status === 200 && data.ok) {
        let { passport: { user: { auth_accounts = [], selected_auth = {} } = {} } = {} } = userData || {};
        if (selected_auth.auth_id === auth_id) {
          selected_auth = auth_accounts.find((account) => {
            if (account.auth_id === auth_id) {
              account.Cookies = [];
              account.cookie_id = null;
              account.AuthT = null;
              account.bsoLoggedIn = false;
              return true;
            }
          }) || { Cookies: [], cookie_id: null, AuthT: null, bsoLoggedIn: false };
        } else {
          auth_accounts.forEach((account) => {
            if (account.auth_id === auth_id) {
              account.Cookies = [];
              account.cookie_id = null;
              account.AuthT = null;
              account.bsoLoggedIn = false;
            }
          });
        }

        let updatedUserData = {
          passport: {
            user: {
              ...userData.passport.user,
              selected_auth,
              auth_accounts
            },
          }
        };
        if (userData?.auth_id === auth_id) {
          updatedUserData = { ...updatedUserData, bsoLoggedIn: false, AuthT: null, awaitingOTP: false, twofactor_iat: null, text_number: null };
        }

        setUserData(prevState => ({ ...prevState, ...updatedUserData }));
        setAlertMessage(prevState => ({ ...prevState, message: "Successfully logged out of the BSO", alert_type: "success" }));
      }
    } catch (error) {
      let error_message = "Logout error: ";
      error_message += error?.message ? error.message : (error?.stack ? error.stack : error);
      console.log(error_message);

      setAlertMessage(prevState => ({
        ...prevState,
        message: error_message,
        alert_type: "danger",
      }));
    }

    setLoading(false);
    return;
  }

  // =========================[ deleteStrikes ]=========================================
  const deleteStrikes = async (auth_id) => {
    if (!auth_id) {
      auth_id = userData?.passport?.user?.auth_id;
    }
    if (!auth_id) {
      return;
    }
    setLoading(true);

    let paramsToQueryString = { auth_id };
    await EREDocs.bso.deleteStrikes(getParams(paramsToQueryString));
    let strikeCount = 0;
    setUserData(prevState => ({ ...prevState, strikeCount }));
    setLoading(false);
    return strikeCount;
  }

  // =========================[ clearStrikesClicked ]=========================================
  const clearStrikesClicked = async (event, auth_id = null) => {
    (event) && (event.preventDefault());
    setLoading(true);
    await swal({
      title: "BSO Account Strikes",
      text: "If your BSO account was locked, you need to call the SSA BSO to have your account unlocked. Call 1-800-772-6270 Monday through Friday, 7:00 a.m. to 7:00 p.m. Eastern Time to speak with Employer Customer Service personnel to unlock your account.\n\nAfter you reinstate your BSO account by calling the BSO, you should clear your strikes, then you can submit more requests.\n\nClick OK if you called the BSO to unlock your account.  By clicking OK, you will remove your current strikes, or click Cancel to not clear strikes.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (clearStrikes) => {
        if (clearStrikes) {
          swal("Your strikes will be cleared, and future requests will be attempted.  Please ensure that if your BSO account was locked, you contact BSO to have your BSO account unlocked.  Call 1-800-772-6270 Monday through Friday, 7:00 a.m. to 7:00 p.m. Eastern Time to speak with Employer Customer Service personnel to unlock your account.");
          await deleteStrikes(auth_id);
        } else {
          swal("Your strikes will not be cleared.");
        }
      });
    setLoading(false);
  }

  // =========================[ checkUserSession ]=========================
  const checkUserSession = async () => {
    setLoading(true);

    let counter = Math.round((Date.now() - startedIntervalAt) / CHECK_BSO_COOKIES_INTERVAL_MS);
    let isAuthenticated = !!userData.isAuthenticated;
    let AutoLoginEnabled = !!userData.server_autologin;
    let expiresAt = userData.AuthT ? Number(userData.AuthT) + BSO_COOKIES_EXPIRATION_MS : Date.now();
    let expiresInSeconds = ((expiresAt - Date.now()) / 1000).toFixed(0);

    let expiresInMinutes = (expiresInSeconds / 60).toFixed(2);
    let connectedTimeMinutes = ((Date.now() - (userData.AuthT || Date.now())) / MINUTE_MS).toFixed(2);

    expiresAt = formatDate(expiresAt);
    // console.log(`=====================[ checkUserSession - count: ${counter} ]======================== `);
    if (expiresInSeconds <= 0) {
      // console.log("Session has expired!");
    }

    let current_user_session = {
      data: null
    };

    try {
      if (userData.bsoLoggedIn && (counter > 0 || expiresInSeconds <= 0) && AutoLoginEnabled && !OVERRIDE_LOGIN) {
        // Keep Cookies Alive 
        current_user_session = await EREDocs.bso.tryAutoLogin(); // Should return the same data ase API.getUserSession() if successful 
      } else {
        current_user_session = OVERRIDE_LOGIN ? { data: { logged_in: false } } : await API.getUserSession();
      }

      isAuthenticated = !!current_user_session?.data?.logged_in;
    } catch (error) {
      if (error?.message) {
        console.log(error.message);
      } else {
        console.log(error, error?.stack);
      }
      isAuthenticated = false;
    }

    let { passport: { user: { user_id, auth_id } = {} } = {}, server_autologin = false, AuthT, bsoLoggedIn = false } = current_user_session?.data || {};
    AutoLoginEnabled = !!server_autologin;
    expiresAt = AuthT ? Number(AuthT) + BSO_COOKIES_EXPIRATION_MS : Date.now();
    expiresInSeconds = ((expiresAt - Date.now()) / 1000).toFixed(0);

    if (expiresInSeconds <= 0 && bsoLoggedIn && !isAuthenticated && !AutoLoginEnabled) {
      current_user_session.data.bsoLoggedIn = false;
      current_user_session.data.AuthT = null;
    }

    // console.log({ AutoLoginEnabled, expiresInSeconds, expiresInMinutes, connectedTimeMinutes, expiresAt, strikeCount });
    setUserData(prevState => ({
      ...prevState,
      ...current_user_session?.data,
      awaitingOTP: false,
      isAuthenticated,
    }));

    if (!isAuthenticated) {
      console.log("User is not authenticated, redirecting to login page");
    }

    if (sessionIntervalId && (!isAuthenticated || !current_user_session?.data?.bsoLoggedIn || expiresInSeconds <= 0)) {
      // Stop Session Interval
      console.log("---------------- [Stop] Session Interval ---------------- ");
      console.log(`Clearing the interval ${sessionIntervalId}`);
      clearInterval(sessionIntervalId);
      setSessionInterval(null);
    }

    if (loading) {
      setLoading(false);
    }
    return isAuthenticated;
  }

  // =========================[ useEffect ]=========================
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (userData.status !== "READY") {
        // This gives us a way from the back end to know if cookies are locked. 
        // If the status is 'DOWNLOADING_FILES' or 'INITIATING' the user will have to log back in anyways
        setLoading(false);
        return;
      }

      setStartedIntervalAt(Date.now());
      if (checkUserSession() && userData.bsoLoggedIn) {
        if (sessionIntervalId) {
          console.log("---------------- [Stop] Session Interval ---------------- ");
          console.log(`Clearing the interval ${sessionIntervalId}`);
          clearInterval(sessionIntervalId);
          setSessionInterval(null);
        }

        console.log("---------------- [Start] Session Interval ---------------- ");
        const _sessionIntervalId = setInterval(checkUserSession, CHECK_BSO_COOKIES_INTERVAL_MS);
        setSessionInterval(_sessionIntervalId);
        console.log({ _sessionIntervalId, 'check_bso_cookies_interval_minutes': CHECK_BSO_COOKIES_INTERVAL_MS / MINUTE_MS });
      }

      setLoading(false);
    }
    return () => {
      mounted = false;
    };
  }, [userData.isAuthenticated, userData.bsoLoggedIn]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      // (!!alertMessage.message) && console.log(alertMessage.message);
      AlertMessage(alertMessage.message, alertMessage.alert_type, alertMessage.append_after_element);
      if (loading && alertMessage.alert_type === "danger") {
        setLoading(false);
      }
    }
    return () => {
      mounted = false;
    };
  }, [alertMessage]);

  return (
    <AuthContext.Provider
      value={{
        userData,
        setUserData,
        handleLogin,
        handleLogout,
        handleBSOLogout,
        loading,
        setLoading,
        alertMessage,
        setAlertMessage,
        sessionIntervalId,
        setSessionInterval,
        startedIntervalAt,
        setStartedIntervalAt,
        checkUserSession,
        deleteStrikes,
        clearStrikesClicked,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, useUserData };
