import React, { useState, useEffect } from 'react';
import { useUserData } from '../../contexts/AuthContext';
import { Form, Button, Alert } from 'react-bootstrap'; // Bootstrap Components

// EREDocs Functions
import { bso } from "../../utils/EREDocs"; // twoFactor

/**
 * BSOTFAForm
 * @param {*} param0 
 * @returns 
 */
const BSOTFAForm = ({ switchSlide = () => { } }) => {
  const { userData, setUserData, setStartedIntervalAt } = useUserData();
  let { text_number = '', twofactor_iat = Date.now() } = userData || {};

  const [loadingForm, setLoadingForm] = useState(false);
  const [formState, setFormState] = useState({
    otp: '',
    bsoSessionId: '',
  });
  const [validated, setValidated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [modalAlert, setModalAlert] = useState({
    message: `The one time pass code should have been sent to the phone number: ${text_number}.`,
    alert_type: "info",
  });

  /**
   * handleInputChange
   * @param {*} event 
   * @returns 
   */
  const handleInputChange = (event) => {
    if (!event?.target?.name) {
      return;
    }
    let { name, value } = event.target;
    setFormState({ ...formState, [name]: value });

    // Validate Form on Change
    let valid = false;
    let alert_message = "";
    let alert_type = "info";
    const otp_regex = /^.{10,}$/gm; // Must Contain 10 Characters
    valid = otp_regex.test(value);
    if (!valid) {
      alert_message = "The one time pass code should have been sent to the phone number: " + text_number + " and must contain 10 characters.";
      alert_type = "info";
    }
    setValidated(valid);
    setShowAlert(!valid);
    if (alert_message) {
      setModalAlert(prevState => ({ ...prevState, message: alert_message.trim(), alert_type }));
    }
  };

  /**
   * submitTFACode
   * 
   * @param {*} event 
   * @returns 
   */
  const submitTFACode = async (event) => {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation();

    let alert_message = "";
    let response;
    try {
      setLoadingForm(true);
      if (!validated) {
        throw new Error(`The one time pass code should have been sent to the phone number: ${text_number}.`);
      }

      // Submit TFA Code
      response = await bso.twoFactor(formState);
    } catch (error) {
      console.log(error);
      response = error.response || {};
      if (!response?.data) {
        response.data = { err: 401, details: `${error.message}`, error: error.error };
      }
    } finally {
      setLoadingForm(false);
    }

    let { status, statusText = '', data = {} } = response;
    let { AuthT, err, error, details, message } = data;
    if (status !== 200) {
      console.log(data ? data : error);
      alert_message = `[${status ?? err}] ${statusText} `;
      alert_message += data?.status ? `${data.status} ` : ""; // ERROR 
      alert_message += error ? `${error} ` : ""; // ScrapeError Message + BSOAutoLogin Error 
      alert_message += (typeof response?.data === 'string') ? response.data : message ?? (details ? `${details} ` : "") + `Invalid 2fa code ${formState.otp}. Please double check the code that was sent to phone number: ${text_number}.`;

      if (details === 'LOGIN') {
        setUserData(prevState => ({ ...prevState, bsoLoggedIn: false, awaitingOTP: false, AuthT: null, twofactor_iat: null }));
        setFormState({ otp: '' });
        switchSlide(1); // move back to the LOGIN slide 
        return;
      }
    }

    if (alert_message) {
      setShowAlert(true);
      setModalAlert(prevState => ({ ...prevState, message: alert_message.trim(), alert_type: "danger" }));
      return;
    }

    twofactor_iat = Date.now();
    setStartedIntervalAt(twofactor_iat);
    setUserData(prevState => ({ ...prevState, bsoLoggedIn: true, awaitingOTP: false, AuthT, twofactor_iat }));
    setFormState({ otp: '' });
    switchSlide();
    return;
  };

  // Cancel TFA
  const cancelTFA = (event) => {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation();
    setLoadingForm(false);
    setUserData(prevState => ({ ...prevState, bsoLoggedIn: false, awaitingOTP: false, AuthT: null, twofactor_iat: null }));
    setFormState({ otp: '' });
    switchSlide(1); // move back to the LOGIN slide 
    return;
  };

  // =========================[ useEffect ]=========================
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setLoadingForm(false);
    }
    return () => mounted = false
  }, []);

  return (
    <div>
      <Alert
        dismissible
        onClose={() => setShowAlert(false)}
        show={showAlert}
        variant={modalAlert.alert_type}
      >{modalAlert.message}</Alert>
      <Form noValidate validated={validated} onSubmit={submitTFACode}>
        <Form.Group className="mb-3" controlId="tfa-passcode">
          <Form.Label>One-time passcode:</Form.Label>
          <Form.Control
            type="text"
            size="sm"
            name="otp"
            placeholder="Enter passcode"
            onChange={handleInputChange}
            value={formState.otp}
            autoComplete="off"
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="bso-session-id">
          <Form.Label>BSO Session ID:</Form.Label>
          <Form.Control
            type="text"
            size="sm"
            name="bsoSessionId"
            placeholder="Enter BSO Session ID"
            onChange={handleInputChange}
            value={formState.bsoSessionId}
            autoComplete="off"
          />
        </Form.Group>
        <Button className="primary-button" variant="success" type="submit" disabled={!validated} {...(loadingForm) && { disabled: true }}>{loadingForm ? "Loading..." : "Submit"}</Button>
        <Button className="simple-button" variant="light" onClick={cancelTFA} {...(loadingForm) && { disabled: true }}>{loadingForm ? "Loading..." : "Cancel"}</Button>
      </Form>
    </div>
  );
}

export { BSOTFAForm };
export default BSOTFAForm;