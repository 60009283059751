import axios from "axios";
import { getParams } from "../utils";
import downloadQueueDummyData from '../fake-data/downloadQueue.json';

const AXIOS_TIMEOUT_MS = 1000 * Number(process.env.REACT_APP_AXIOS_TIMEOUT_SECONDS || 300); // In Milliseconds (default 5minutes)
let axiosOptions = { timeout: AXIOS_TIMEOUT_MS }; // (in ms) timeout of zero means there is no timeout limit 

const admin = {
  getGmailToken: ({ auth_id, user_id, ...queryParams } = {}) => axios.get(`/admin/refreshauthaccount/${getParams({ auth_id, user_id, ...queryParams })}`, axiosOptions),
  setGmailToken: ({ auth_id, code, ...postData } = {}) => axios.post(`/admin/refreshauthaccount/${getParams({ auth_id })}`, { code, ...postData }, axiosOptions),
  organizations: ({ org_id = 0, ...params } = {}) => axios.get(`/admin/organizations/${org_id}/${getParams(params)}`, axiosOptions),
  getOrgAndChildrenRecords: (queryParams = {}) => axios.get(`/admin/getorgandchildrenorganizations/${getParams(queryParams)}`, axiosOptions),
  saveLawFirm: ({ org_id = 0, ...postData } = {}) => axios.post(`/admin/organizations/${org_id}`, postData, axiosOptions),
  updateLawFirmUser: (postData = {}) => axios.put(`/user/update`, postData, axiosOptions),
  saveLawFirmUser: ({ user_id = 0, ...postData } = {}) => axios.post(`/admin/users/${user_id}`, postData, axiosOptions),
  saveBSOAccount: ({ auth_id = 0, ...postData } = {}) => axios.post(`/admin/authaccounts/${auth_id}`, postData, axiosOptions),
  disableAuthAccount: ({ auth_id = 0 } = {}) => axios.delete(`/admin/authaccounts/${auth_id}`, axiosOptions),
  users: ({ user_id = 0, ...params } = {}) => axios.get(`/admin/users/${user_id}/${getParams(params)}`, axiosOptions),
  authAccounts: ({ auth_id = 0, ...params } = {}) => axios.get(`/admin/authaccounts/${auth_id}/${getParams(params)}`, axiosOptions),
  groups: ({ group_id = 0, ...params } = {}) => axios.get(`/admin/groups/${group_id}/${getParams(params)}`, axiosOptions),
  secrets: ({ org_id = 0, prefix = 0, ...params } = {}) => axios.get(`/admin/secrets/${org_id}/${prefix}/${getParams(params)}`, axiosOptions),
  getSettings: (queryParams = {}) => axios.get(`/user/settings${getParams(queryParams)}`, axiosOptions),
  saveUserSettings: ({ user_id = 0, ...postData } = {}) => axios.post(`/user/settings${getParams({ user_id })}`, postData, axiosOptions),
  saveAuthSettings: ({ auth_id = 0, ...postData } = {}) => axios.post(`/user/authsettings${getParams({ auth_id })}`, postData, axiosOptions),
  getAuthSetting: ({ auth_id = 0, key = 0 } = {}) => axios.get(`/user/authsettings/${key}/${getParams({ auth_id })}`, axiosOptions),
  getOrgSetting: ({ org_id = 0, key = 0 } = {}) => axios.get(`/user/orgsettings/${key}/${getParams({ org_id })}`, axiosOptions),
  saveOrgSettings: ({ org_id = 0, ...postData } = {}) => axios.post(`/user/orgsettings${getParams({ org_id })}`, postData, axiosOptions),
  forgotPassword: (postData = {}) => axios.post("/user/forgot-password", postData, axiosOptions),
  toggleReboot: (postData = {}) => axios.post("/admin/reboot", postData, axiosOptions),
  getServerStatus: (postData = {}) => axios.post("/admin/server-status", postData, axiosOptions),
};

const firm_management = {
  resetPassword: (postData) => axios.post("/firm-management/users/reset-password", postData, axiosOptions),
  getLawFirmBSOAccounts: ({ org_id = 0, ...params } = {}) => axios.get(`/firm-management/bsoaccounts/${org_id}${getParams(params)}`, axiosOptions),
  getLawFirmUsers: ({ org_id = 0, ...params } = {}) => axios.get(`/firm-management/users/${org_id}${getParams(params)}`, axiosOptions),
};

const bso = {
  login: (loginData) => axios.post("/eredocs/bso/login/", loginData, axiosOptions),
  logout: ({ keepSessionAlive = true, ...remainingData } = {}) => axios.post("/eredocs/bso/logout/", { keepSessionAlive, ...remainingData }, axiosOptions),
  fullLogout: () => axios.delete("/eredocs/bso/logout/"),
  twoFactor: (data) => axios.post("/eredocs/bso/two-factor/", data, axiosOptions),
  getAgreementText: async () => {
    let response;
    try {
      response = await axios.get("/eredocs/bso/getAgreementText", axiosOptions)
    } catch (error) { // Try to get the agreement text from the public folder if the API is down
      response = await axios.get("agreementText.json");
    }
    return response;
  },
  checkCookies: (queryParams) => axios.get(`/eredocs/bso/checkCookies${getParams(queryParams)}`, axiosOptions),
  tryAutoLogin: (bsoData = {}) => axios.patch("/eredocs/bso/tryAutoLogin", bsoData, axiosOptions),
  toggleAccount: (AccountParams = {}) => axios.patch("/eredocs/bso/toggle", AccountParams, axiosOptions),
  pickupFiles: (queryParams = {}) => axios.get(`/eredocs/bso/pickupFiles${getParams(queryParams)}`, axiosOptions),
  filteredPickupFiles: (filteredData) => axios.post("/eredocs/bso/pickupFiles", filteredData),
  findASR: ({ filteredData, ...queryParams } = {}) => axios.post(`/eredocs/bso/appeals-status-report${getParams(queryParams)}`, filteredData, axiosOptions),
  findHSR: ({ filteredData, ...queryParams } = {}) => axios.post(`/eredocs/bso/hearing-status-report${getParams(queryParams)}`, filteredData, axiosOptions),

  getBsoRequestQueueRecords: (queryParams) => axios.get(`/eredocs/bso/bso-request-queue${getParams(queryParams)}`, axiosOptions),
  deleteBsoRequestQueueRecord: (queryParams) => axios.delete(`/eredocs/bso/bso-request-queue${getParams(queryParams)}`, axiosOptions),
  createBsoRequestQueueRecord: (postData) => axios.post(`/eredocs/bso/bso-request-queue`, postData, axiosOptions),
  upsertBsoRequestQueueRecord: (queryParams, putData) => axios.put(`/eredocs/bso/bso-request-queue${getParams(queryParams)}`, putData, axiosOptions),

  countStrikes: (query) => axios.get(`/api/countstrikes${query}`, axiosOptions),
  getStrikes: (query) => axios.get(`/api/getstrikes${query}`, axiosOptions),
  deleteStrikes: (query) => axios.post(`/api/deletestrikes${query}`, axiosOptions),
};

const downloader = {
  processRequest: (requestData) => axios.post("/eredocs/downloader/process-request/", requestData, axiosOptions),
  getFile: (hrefLinkData, cb = () => { }) => axios.get(hrefLinkData, { ...axiosOptions, responseType: 'blob' }).then(async (response) => {
    let { status, statusText, headers = {}, data } = response || {};
    let type = headers['content-type'] || 'application/pdf'; // Extract type from header 

    if (status === 200) {
      const blob = new Blob([data], { type });

      // Extract filename from header
      let disposition = headers['content-disposition']; // "attachment;filename=John_Howard_Morgan_0900_Custom_2133.pdf" 
      const regex = /filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/g;
      let m;
      let filename = '';
      if ((m = regex.exec(disposition)) !== null) {
        m.forEach((match, groupIndex) => {
          if (groupIndex === 1) {
            filename = match;
          }
        });
      }

      // Download the file 
      const link = document.createElement('a');
      link.download = filename;
      link.href = URL.createObjectURL(blob);
      link.addEventListener('click', (e) => {
        setTimeout(() => URL.revokeObjectURL(link.href), 30 * 1000);
      });
      link.click();
      cb({ status, statusText, data: `Successfully Downloaded File` });
    } else {
      if (type.includes('text/html')) {
        data = await data.text();
      }
      cb({ status, statusText, data });
    }
  }),

  getZipFile: (hrefLinkData, cb = () => { }) => axios.get(hrefLinkData, { ...axiosOptions, responseType: 'blob' }).then(async (response) => {
    let { status, statusText, headers = {}, data } = response || {};
    let type = headers['content-type'] || 'application/zip'; // Extract type from header 
    if (status === 200) {
      const blob = new Blob([data], { type });

      // Extract filename from header
      let disposition = headers['content-disposition']; // "attachment;filename=Mcfarland_6519_DOCS_15286734.zip"
      const regex = /filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/g;
      let m;
      let filename = '';
      if ((m = regex.exec(disposition)) !== null) {
        m.forEach((match, groupIndex) => {
          if (groupIndex === 1) {
            filename = match;
          }
        });
      }

      // Download the file 
      const link = document.createElement('a');
      link.download = filename;
      link.href = URL.createObjectURL(blob);
      link.addEventListener('click', (e) => {
        setTimeout(() => URL.revokeObjectURL(link.href), 30 * 1000);
      });
      link.click();
      cb({ status, statusText, data: `Successfully Downloaded File` });
    } else {
      if (type.includes('text/html')) {
        data = await data.text();
      }
      cb({ status, statusText, data });
    }
  }),

  // Cancel ReqId or SocialId
  cancelRequest: (request_id) => axios.delete("/eredocs/downloader/request/" + request_id),
  cancelSocialRequest: (request_social_id) => axios.delete("/eredocs/downloader/social/" + request_social_id),

  // ere_download_request
  allUserDownloadRequests: (user_id, queryParams) => axios.get("/eredocs/downloader/ere_download_request/user/" + user_id + queryParams, axiosOptions),
  allOrgDownloadRequests: (org_id, queryParams) => axios.get("/eredocs/downloader/ere_download_request/org/" + org_id + queryParams, axiosOptions),
  filteredOrgDownloadRequests: (org_id, postData) => axios.post("/eredocs/downloader/ere_download_request/org/" + org_id, postData, axiosOptions),
  createDownloadRequest: (requestData) => axios.post("/eredocs/downloader/ere_download_request/", requestData, axiosOptions),
  getDownloadRequest: (request_id) => axios.get("/eredocs/downloader/ere_download_request/" + request_id, axiosOptions),
  updateDownloadRequest: (request_id) => axios.put("/eredocs/downloader/ere_download_request/" + request_id, axiosOptions),
  deleteDownloadRequest: (request_id) => axios.delete("/eredocs/downloader/ere_download_request/" + request_id),

  // ere_download_request_socials
  allUserDownloadRequestSocials: (request_id, queryParams) => axios.get("/eredocs/downloader/ere_download_request_socials/request/" + request_id + queryParams, axiosOptions),
  getSocialDetails: (request_id = 0, patchData) => axios.patch("/eredocs/downloader/ere_download_request_socials/request/" + request_id, patchData, axiosOptions),
  createDownloadRequestSocial: (socialData) => axios.post("/eredocs/downloader/ere_download_request_socials/", socialData, axiosOptions),
  getDownloadRequestSocial: (request_social_id) => axios.get("/eredocs/downloader/ere_download_request_socials/" + request_social_id, axiosOptions),
  updateDownloadRequestSocial: (request_social_id) => axios.put("/eredocs/downloader/ere_download_request_socials/" + request_social_id, axiosOptions),
  deleteDownloadRequestSocial: (request_social_id) => axios.delete("/eredocs/downloader/ere_download_request_socials/" + request_social_id),

  // General Status Codes
  getStatus: (queryParams = "") => axios.get("/status/" + queryParams, axiosOptions),

  // Claimants 
  readClaimantInfo: ({ ssn, ...params }) => axios.post(`/eredocs/downloader/read-claimant-info${getParams(params)}`, { ssn }, axiosOptions),
  getClaimants: (queryParams) => axios.get("/eredocs/downloader/claimant/search" + getParams(queryParams), axiosOptions),
  getClaimant: (claimant_id = 0, queryParams) => axios.get("/eredocs/downloader/claimant/" + claimant_id + getParams(queryParams), axiosOptions), // NOTE: will also readClaimantInfo but will use BRQ if fails (or can't) read live from bso 
  findClaimants: (postData = {}) => axios.post("/eredocs/downloader/claimant/find", postData, axiosOptions),

  // Bottom Queue Download Tool 
  downloadClaimantResources: (claimant_id = 0, postData = {}) => axios.post("/eredocs/downloader/claimant/" + claimant_id, postData, { ...axiosOptions, responseType: 'blob' }).then(async (response) => {
    let { status, statusText, headers = {}, data } = response || {};
    let type = headers['content-type'] || 'application/json'; // Extract type from header 

    if (["text/html", "text/plain", "application/json"].some((t) => (type.includes(t)))) {
      let tmpData = await data.text();
      if (type.includes('application/json')) {
        data = JSON.parse(tmpData);
      } else {
        data = {
          ok: status === 200,
          statusCode: status,
          statusMessage: tmpData,
        }
      }
    } else {
      const blob = new Blob([data], { type });

      // Extract filename from header
      let disposition = headers['content-disposition']; // "attachment;filename=Mcfarland_6519_DOCS_15286734.zip"
      const regex = /filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/g;
      let m;
      let filename = '';
      if ((m = regex.exec(disposition)) !== null) {
        m.forEach((match, groupIndex) => {
          if (groupIndex === 1) {
            filename = match;
          }
        });
      }

      // Download the file 
      const link = document.createElement('a');
      link.download = filename;
      link.href = URL.createObjectURL(blob);
      link.addEventListener('click', (e) => {
        setTimeout(() => URL.revokeObjectURL(link.href), 30 * 1000);
      });
      link.click();

      data = {
        ok: status === 200,
        statusCode: status,
        statusMessage: `Successfully Downloaded File ${filename}`
      };
    }

    return { status, statusText, data };
  }),

  getClaimantFolderTree: ({ claimant_id, ...queryParams } = {}) => axios.get("/eredocs/downloader/claimant/folder/" + claimant_id + getParams(queryParams), axiosOptions),
  downloadClaimantFile: (claimant_id = 0, postData = {}) => axios.post("/eredocs/downloader/claimant/folder/" + claimant_id, postData, { ...axiosOptions, responseType: 'blob' }).then(async (response) => {
    let { status, statusText, headers = {}, data } = response || {};
    let type = headers['content-type'] || 'application/json'; // Extract type from header 

    if (["text/html", "text/plain", "application/json"].some((t) => (type.includes(t)))) {
      let tmpData = await data.text();
      if (type.includes('application/json')) {
        data = JSON.parse(tmpData);
      } else {
        data = {
          ok: status === 200,
          statusCode: status,
          statusMessage: tmpData,
        }
      }
    } else {
      const blob = new Blob([data], { type });

      // Extract filename from header
      let disposition = headers['content-disposition']; // "attachment;filename=Mcfarland_6519_DOCS_15286734.zip"
      const regex = /filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/g;
      let m;
      let filename = '';
      if ((m = regex.exec(disposition)) !== null) {
        m.forEach((match, groupIndex) => {
          if (groupIndex === 1) {
            filename = match;
          }
        });
      }

      // Download the file 
      const link = document.createElement('a');
      link.download = filename;
      link.href = URL.createObjectURL(blob);
      link.addEventListener('click', (e) => {
        setTimeout(() => URL.revokeObjectURL(link.href), 30 * 1000);
      });
      link.click();

      data = {
        ok: status === 200,
        statusCode: status,
        statusMessage: `Successfully Downloaded File ${filename}`
      };
    }

    return { status, statusText, data };
  }),
};

const uploader = {
  getUploadRequestForm: (queryParams) => axios.get(`/eredocs/uploader${getParams(queryParams)}`, axiosOptions),
  getSingleUploadRequest: ({ upload_request_id = 0, ...queryParams } = {}) => axios.get(`/eredocs/uploader/${upload_request_id}/${getParams(queryParams)}`, axiosOptions),
  getUploadRequests: (queryParams) => axios.get(`/eredocs/uploader/requests${getParams(queryParams)}`, axiosOptions),
  getClaimantUploadRequests: ({ claimant_id = 0, ...queryParams } = {}) => axios.get(`/eredocs/uploader/requests/${claimant_id}/${getParams(queryParams)}`, axiosOptions),
  createUploadRequest: (formData) => axios.post("/eredocs/uploader", formData, { ...axiosOptions, headers: { 'Content-Type': 'multipart/form-data' } }),
  uploaderBulkAction: (postParams) => axios.post("/eredocs/uploader/bulk", postParams, axiosOptions),
};

const request_queue = {
  getRequestQueue: (request_queue_id = 0, queryParams) => axios.get(`/request_queue/${request_queue_id}/${getParams(queryParams)}`, axiosOptions),
  downloadBulkAction: (postParams) => axios.post("/request_queue/bulk", postParams, axiosOptions),
  getDownloadQueue: (postParams) => axios.post("/request_queue/status", postParams, axiosOptions),
  downloadQueueBulkAction: (postParams) => axios.post("/request_queue/status/bulk", postParams, axiosOptions),
};

export {
  admin,
  bso,
  downloader,
  uploader,
  firm_management,
  request_queue,
}
export default {
  admin,
  bso,
  downloader,
  uploader,
  firm_management,
  request_queue,
};
