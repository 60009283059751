import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import { useUserData } from "./contexts/AuthContext";
import useTheme from "./hooks/UseTheme";

// Styles 
import 'bootstrap/dist/css/bootstrap.min.css';
import { GlobalStyle } from "./styled/Global";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "./styled/Themes";

// Pages
import Login from './pages/Login';
import ChangePassword from './pages/ChangePassword';
import Support from './pages/Support';
import DownloadRequestLogs from './pages/DownloadRequestLogs';
import FirmManagement from './pages/FirmManagement';
import DownloadManagement from './pages/DownloadManagement';
import DownloadQueue from './pages/DownloadQueue';
import UploadManagement from './pages/UploadManagement';
import FirmManagementDetails from './pages/FirmManagementDetails';
import BSOAccountManagementDetails from './pages/BSOAccountManagementDetails';
import ClaimantLogs from './pages/ClaimantLogs';
import DownloadRequestDetails from './pages/DownloadRequestDetails';
import UploadRequestLogs from './pages/UploadRequestLogs';
import Claimants from './pages/Claimants';
import ClaimantDetails from './pages/ClaimantDetails';
import UserDetails from './pages/UserDetails';
import UserAccount from './pages/UserAccount';
import DefaultDownloadRequest from './pages/DefaultDownloadRequest';
import CustomDownloadRequest from './pages/CustomDownloadRequest';

// Components
import MainHeader from './components/MainHeader'
import Help from '././pages/Help';
import PrivacyPolicy from '././pages/PrivacyPolicy';
import Footer from './components/Footer';

const OVERRIDE_LOGIN = process.env.REACT_APP_OVERRIDE_LOGIN === "true";

function App() {
  const [theme, toggleTheme] = useTheme();
  const currentTheme = theme === "light" ? lightTheme : darkTheme;
  const { userData } = useUserData();
  const { isAuthenticated = false } = userData || {};
  return (
    <div className="App">
      <Router>
        <ThemeProvider theme={currentTheme}>
          <GlobalStyle />
          <MainHeader toggleTheme={toggleTheme} theme={theme} />
          {OVERRIDE_LOGIN ? (
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/help" element={<Help />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/reset-password/:reset_password_token" element={<ChangePassword />} />
              <Route path="/support" element={<Support />} />
              <Route path="/download-requests" element={<DownloadRequestLogs />} />
              <Route path="/download-request-details/:request_id" element={<DownloadRequestDetails />} />
              <Route path="/claimant-logs" element={<ClaimantLogs />} />
              <Route path="/upload-requests" element={<UploadRequestLogs />} />
              <Route path="/claimants" element={<Claimants />} />
              <Route path="/claimant-details/:claimant_id" element={<ClaimantDetails />} />
              <Route path="/account" element={<UserAccount />} />
              <Route path="/user/:user_id" element={<UserDetails />} />
              <Route path="/custom-download-request" element={<CustomDownloadRequest />} />
              <Route path="/default-download-request" element={<DefaultDownloadRequest />} />
              <Route path="/firm-management" element={<FirmManagement />} />
              <Route path="/firm-management-details/:org_id" element={<FirmManagementDetails />} />
              <Route path="/download-management" element={<DownloadManagement />} />
              <Route path="/upload-management" element={<UploadManagement />} />
              <Route path="/download-queue" element={<DownloadQueue />} />
              <Route path="/bso-account-management-details/:auth_id" element={<BSOAccountManagementDetails />} />
              <Route path="*" element={<Login />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/help" element={<Help />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/reset-password/:reset_password_token" element={<ChangePassword />} />
              <Route path='/support' element={<PrivateRoute />}>
                <Route index element={<Support />} />
              </Route>
              <Route path='/download-requests' element={<PrivateRoute />}>
                <Route index element={<DownloadRequestLogs />} />
              </Route>
              <Route path='/download-request-details' element={<PrivateRoute />}>
                <Route path=":request_id" element={<DownloadRequestDetails />} />
              </Route>
              <Route path='/claimant-logs' element={<PrivateRoute />}>
                <Route index element={<ClaimantLogs />} />
              </Route>
              <Route path='/upload-requests' element={<PrivateRoute />}>
                <Route index element={<UploadRequestLogs />} />
              </Route>
              <Route path='/claimants' element={<PrivateRoute />}>
                <Route index element={<Claimants />} />
              </Route>
              <Route path='/claimant-details' element={<PrivateRoute />}>
                <Route path=":claimant_id" element={<ClaimantDetails />} />
              </Route>
              <Route path='/account' element={<PrivateRoute />}>
                <Route index element={<UserAccount />} />
              </Route>
              <Route path='/user' element={<PrivateRoute />}>
                <Route path=":user_id" element={<UserDetails />} />
              </Route>
              <Route path='/custom-download-request' element={<PrivateRoute />}>
                <Route index element={<CustomDownloadRequest />} />
              </Route>
              <Route path='/default-download-request' element={<PrivateRoute />}>
                <Route index element={<DefaultDownloadRequest />} />
              </Route>
              <Route path='/firm-management' element={<PrivateAdminRoute />}>
                <Route index element={<FirmManagement />} />
              </Route>
              <Route path='/firm-management-details' element={<PrivateAdminRoute />}>
                <Route path=":org_id" element={<FirmManagementDetails />} />
              </Route>
              <Route path='/download-management' element={<PrivateAdminRoute />}>
                <Route index element={<DownloadManagement />} />
              </Route>
              <Route path='/upload-management' element={<PrivateAdminRoute />}>
                <Route index element={<UploadManagement />} />
              </Route>
              <Route path='/download-queue' element={<PrivateAdminRoute />}>
                <Route index element={<DownloadQueue />} />
              </Route>
              <Route path='/bso-account-management-details/' element={<PrivateAdminRoute />}>
                <Route path=":auth_id" element={<BSOAccountManagementDetails />} />
              </Route>
              <Route path="*" element={<Login />} />
            </Routes>
          )}
          {(isAuthenticated) === true && <Footer />}
        </ThemeProvider>
      </Router>
    </div>
  );
}

function PrivateRoute() {
  const { userData, setUserData } = useUserData();
  const { isAuthenticated = false, referrer } = userData || {};
  const { pathname } = useLocation() || {}; // Current url the user tried to access 

  useEffect(() => {
    let mounted = true;
    if (mounted && pathname && pathname !== referrer) {
      setUserData(prevState => ({ ...prevState, referrer: pathname }));
    }
    return () => {
      mounted = false;
    };
  }, [pathname]);

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
}

function PrivateAdminRoute() {
  const { userData, setUserData } = useUserData();
  let { passport: { user: { group_id = 6 } = {} } = {}, isAuthenticated = false, referrer } = userData || {};
  const AdminGroups = [1, 2];
  const AdminAccess = (isAuthenticated && AdminGroups.includes(Number(group_id))) || OVERRIDE_LOGIN;
  const { pathname } = useLocation() || {}; // Current url the user tried to access 

  useEffect(() => {
    let mounted = true;
    // NOTE: AdminAccess=false on refresh so we don't want to include AdminAccess in the condition
    // Looking further you'll see AdminAccess=false bounces to /login page to find out 
    // that AdminAccess=true and then comes back because of the new referrer that was just set in setUserData below 
    if (mounted && pathname && pathname !== referrer) {
      setUserData(prevState => ({ ...prevState, referrer: pathname }));
    }
    return () => {
      mounted = false;
    };
  }, [pathname]);

  return AdminAccess ? <Outlet /> : <Navigate to="/login" />;
}

export default App;
