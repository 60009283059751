import styled from "styled-components";

const getClassByValue = (value = "") => {
  if (!value) return "";
  value = `${value}`.toLowerCase().trim();

  let matchFields = ['ocr', 'generatepdf', 'delivery', 'started', 'pending', 'requested', 'pause', 'completed', 'fail', 'schedule', 'transferred',
    'docket', 'assembly', 'development', 'temporary', 'closed', 'review', 'queued', 'audit', 'testing', 'assigned', 'error', 'ready', 'confirmed', 'preparation', 'cancel'];
  if (matchFields.some((v) => value.includes(v))) {
    value = matchFields.find((v) => value.includes(v));
  }

  switch (value) {
    case "good":
    case "true":
    case "enabled":
    case "active":
    case "assigned":
    case "confirmed":
    case "sent":
    case "success":
    case "pass":
    case "ready":
    case "delivery":
    case "completed":
    case "schedule":
    case "contacted":
      return "text-success";
    case "bad":
    case "false":
    case "disabled":
    case "fail":
    case "not_in_bso":
      return "text-danger";
    case "okay":
    case "?":
    case "preparation":
    case "downloading":
    case "initiating":
    case "processocr":
    case "ocr":
    case "pdf":
    case "generatepdf":
    case "generating":
    case "error":
    case "started":
    case "review":
    case "testing":
    case "processing":
      return "text-warning"
    case "inactive":
    case "closed":
    case "unavailable":
    case "pause":
    case "cancel":
    case "unknown":
      return "text-muted";
    case "downloaded":
    case "initiated":
      return "text-primary";
    case "new case":
    case "case workup":
    case "pending":
    case "requested":
    case "transferred":
    case "docket":
    case "assembly":
    case "development":
    case "temporary":
    case "queued":
      return "text-info";
    default:
      return "";
  }
}

export const StyledSpan = styled.span.attrs(props => {
  return {
    className: getClassByValue(props['data-value'] || props['children'] || "")
  }
}
)``;
