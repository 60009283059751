import React, { useState, useEffect } from 'react';
import DateTimePicker from 'react-datetime-picker';
import { Modal, Button, Row, Col } from 'react-bootstrap';

const EditBrqNextRunModal = ({ setParentState = () => { }, record = {}, parentState = false, reportChange, ...props } = {}) => {
  const [value, onChange] = useState(new Date());
  const closeModal = () => {
    setParentState(false);
  };

  const handleSaveChanges = () => {
    if (reportChange && typeof reportChange === 'function') {
      reportChange(value, record);
    }
    setParentState(false);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      onChange(new Date());
    }
    return () => {
      mounted = false;
    };
  }, [record]);

  return (
    <div {...props}>
      <Modal
        show={parentState}
        onHide={closeModal}
        animation={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <p>Request Queue Daily Schedule</p>
            <p>Note: These HSR/ASR Schedules will execute at the time/date provided.  After they run, they are continually rescheduled to re-run 24 hours later.</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className={"text-center"}>
              Scheduled to download HSR/ASR after: <DateTimePicker onChange={onChange} value={value} />
            </Col>
          </Row>
          <Button className={"ml-3"} onClick={handleSaveChanges}>Save Changes</Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export { EditBrqNextRunModal };
export default EditBrqNextRunModal;