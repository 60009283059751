import React, { useState } from 'react';
import { useUserData } from "../../contexts/AuthContext";
import { Modal, Button } from 'react-bootstrap';
import { SFTPSetupForm } from '../Forms';

const SFTPSetupModal = ({ org_id, setParentState = () => { }, sftp_state, ...props } = {}) => {
  const { userData } = useUserData();
  let { passport: { user: { org_id: default_org_id } = {} } = {} } = userData || {};
  (!org_id && default_org_id) && (org_id = default_org_id);
  (typeof org_id !== "number") && (org_id = Number(org_id));
  const [modalState, setModalState] = useState(false);
  const openModal = () => (setModalState(true));
  const closeModal = () => (setModalState(false));

  return (
    <div {...props} >
      <Button onClick={openModal}>Setup SFTP</Button>
      <Modal show={modalState} onHide={closeModal} animation={false} size="lg" centered >
        <Modal.Header closeButton>
          <Modal.Title>Setup SFTP Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SFTPSetupForm org_id={org_id} closeModal={closeModal} setParentState={setParentState} sftp_state={sftp_state} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export { SFTPSetupModal };
export default SFTPSetupModal;