import React, { useState, useEffect } from 'react';
import { useUserData } from '../../contexts/AuthContext';
import { Form, Button, Alert } from 'react-bootstrap'; // Bootstrap Components
import { StyledAgreementText } from '../../styled/Misc'; // Styled Components

// EREDocs Functions 
import { bso } from "../../utils/EREDocs"; // getAgreementText, login, tryAutoLogin

/**
 * NOTE: client_autologin was used to automate 2FA from gmail it's no longer used
 * Instead we use server_autologin which does both password and 2FA automation through gmail, imap, or db. 
 * @param {*} param0 
 * @returns 
 */
const BSOLoginForm = ({ switchSlide = () => { } }) => {
  const { userData, setUserData } = useUserData();
  let { passport: { user: { email = '' } = {} } = {}, bso_username = '', server_autologin = false, client_autologin = false } = userData || {};
  if (!email && userData?.email) {
    email = userData.email;
  }

  const [loadingForm, setLoadingForm] = useState(false);
  const [formState, setFormState] = useState({
    bso_username,
    bso_password: '',
    acceptTerms: false,
  });
  const [validated, setValidated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [modalAlert, setModalAlert] = useState({
    message: "Please fill out the form below to sign into the BSO.",
    alert_type: "info",
  });
  const [agreementText, setAgreementText] = useState("");

  /**
   * handleInputChange
   * @param {*} event 
   */
  const handleInputChange = (event) => {
    if (!event?.target?.name) {
      return;
    }
    let { name, value, type, checked = false } = event.target;
    if (type === 'checkbox') {
      value = checked;
    }
    setFormState((prevState) => ({ ...prevState, [name]: value }));

    let valid = true;

    let alert_message = "";
    let alert_type = "info";

    const username_regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/gm; // Must Contain 8 Characters consisting of letters and numbers
    const password_regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/gm; // Must Contain 8 Characters consisting of letters and numbers

    let buname = "" + formState.bso_username;
    if (name === 'bso_username') { buname = value; }

    let bpassword = "" + formState.bso_password;
    if (name === 'bso_password') { bpassword = value; }

    let acceptTerms = formState.acceptTerms;
    if (name === 'acceptTerms') { acceptTerms = value; }

    // Validate Form on change
    let validUsername = username_regex.test(buname);
    let validPassword = password_regex.test(bpassword);
    let validTermsCheck = acceptTerms;

    valid = validUsername && validPassword && validTermsCheck;

    alert_message = "";

    if (!validUsername) {
      alert_message = "Please enter a valid BSO Username.";
    } else if (!validPassword) {
      alert_message = "BSO Password must contain 8 characters consisting of letters and numbers.";
    } else if (!validTermsCheck) {
      alert_message = "Please accept the terms and conditions.";
    }

    setValidated(valid);
    setShowAlert(!valid);
    if (alert_message) {
      setModalAlert(prevState => ({ ...prevState, message: alert_message.trim(), alert_type }));
    }
  };

  // ===============[ getAgreementText ]=================================
  const getAgreementText = async () => {
    let resp;
    try {
      resp = await bso.getAgreementText();
    } catch (error) {
      resp = error?.response || {};
    }

    let { status, statusText = '', details, data } = resp;
    if (status !== 200) {
      let error_message = `[${status ?? data?.err}] ${statusText} `;
      error_message += (typeof data === 'string') ? data : data.message ?? (details ? `${details} ` : "") + "We were not able to load agreement text!";
      setShowAlert(true);
      setModalAlert(prevState => ({ ...prevState, message: error_message, alert_type: "danger" }));
      return;
    }

    setAgreementText(data?.agreement);
  }

  // ===============[ Submit BSO Login ]==================================
  const submitBSOLogin = async (event) => {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation();

    let alert_message = "";
    let response;
    try {
      setLoadingForm(true);
      if (!validated) {
        throw new Error("Please fill out the form below to sign into the BSO.");
      }

      await bso.login({ username: formState.bso_username, password: formState.bso_password });
      setUserData(prevState => ({ ...prevState, bsoLoggedIn: true, AuthT: null, awaitingOTP: false }));
    } catch (ex) {
      console.log(ex);
      response = ex?.response || {};
      if (!response?.data) {
        response.data = { err: 401, details: `${ex.message}`, error: ex.error };
      }
    } finally {
      setLoadingForm(false);
    }

    let { status, statusText = '', data = {} } = response;
    let { err, error, details, message, twofactor_iat = Date.now(), phoneNumber = null } = data;
    if (status !== 200) {
      alert_message = `[${status ?? err}] ${statusText} `;
      alert_message += data?.status ? `${data.status} ` : ""; // ERROR 
      alert_message += error ? `${error} ` : ""; // ScrapeError Message + BSOAutoLogin Error 
      alert_message += (typeof response?.data === 'string') ? response.data : message ?? (details ? `${details} ` : "") + "Invalid username or password.";

      if (details === '2FA') {
        setUserData(prevState => ({ ...prevState, bsoLoggedIn: false, AuthT: null, awaitingOTP: true, twofactor_iat, text_number: phoneNumber }));
        console.log("=================[ 2FA ]=================");
        console.log(alert_message);
        alert_message = ""; // Clearing the alert_message because this is good! The user has been logged in and we are awaiting OTP
      }
    }

    if (alert_message) {
      setShowAlert(true);
      setModalAlert(prevState => ({ ...prevState, message: alert_message.trim(), alert_type: "danger" }));
      return;
    }

    setFormState({
      bso_password: "",
      acceptTerms: false,
    });
    switchSlide(); // Moves to TFA slide
    return;
  };

  // ===============[ handleBSOAutoLogin ]==================================
  const handleBSOAutoLogin = async (event) => {
    if (!event?.target || !server_autologin) {
      return;
    }

    let current_user_session = {
      data: null
    };
    try {
      setLoadingForm(true);
      let params = { bso_login: formState.bso_username };
      current_user_session = await bso.tryAutoLogin(params); // Should return the same data ase API.getUserSession() if successful 
    } catch (error) {
      const data = (error.response && error.response.data) ? error.response.data : { err: 401, details: `${error.message}`, error: error.error };
      let error_message = `${data?.err ? data.err + " " : ""}`; // 401 
      error_message += `${data?.status ? data.status + " " : ""}`; // ERROR 
      if (data?.error) {
        error_message += `${data.error} `; // ScrapeError Message + BSOAutoLogin Error 
      } else if (data?.message) {
        error_message += `${data.message} `;
      } else {
        error_message += `${data?.details ? data.details : ""}`;
      }
      console.log(data ? data : error);
      console.log(error_message);
      setShowAlert(true);
      setModalAlert(prevState => ({ ...prevState, message: error_message, alert_type: "danger" }));
      return;
    } finally {
      setLoadingForm(false);
    }

    setUserData(prevState => ({
      ...prevState,
      ...current_user_session?.data,
      awaitingOTP: false,
    }));

    switchSlide(0); // Moves to Account List slide
  }

  // ===============[ Cancel Login ]==================================
  const cancelLogin = (event) => {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation();
    setLoadingForm(false);
    setFormState({
      bso_password: "",
      acceptTerms: false,
    });
    switchSlide(0); // Moves to Account List slide
  };

  // ===============[ useEffect ]==================================
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setLoadingForm(false);
      getAgreementText();
    }
    return () => mounted = false
  }, []);

  return (
    <div>
      <Alert
        dismissible
        onClose={() => setShowAlert(false)}
        show={showAlert}
        variant={modalAlert.alert_type}
      >{modalAlert.message}</Alert>
      <Form noValidate validated={validated} onSubmit={submitBSOLogin} >
        <Form.Group>
          <Form.Label >BSO Username:</Form.Label>
          <Form.Control
            type="text"
            name="bso_username"
            placeholder=""
            onChange={handleInputChange}
            value={formState.bso_username}
            autoComplete="off"
            required
          />
          <Form.Label className="mt-3">BSO Password:</Form.Label>
          <Form.Control
            type="password"
            placeholder=""
            name="bso_password"
            onChange={handleInputChange}
            value={formState.bso_password}
            autoComplete="off"
            required
          />
          <Form.Label className="mt-3">User terms and conditions:</Form.Label>
          <StyledAgreementText
            className="usercert"
            id="usercert_id"
            dangerouslySetInnerHTML={{
              __html: agreementText
            }}
          />
          <Form.Check
            type="checkbox"
            label="I have read & agree to these terms and conditions"
            className="form-check mb-3"
            id="acceptTerms"
            name="acceptTerms"
            defaultChecked={formState.acceptTerms}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        {server_autologin && (<span><Button className="green-button me-2" onClick={handleBSOAutoLogin} {...(loadingForm) && { disabled: true }}>
          {loadingForm ? "Loading..." : `Auto Login${formState.bso_username ? " (" + formState.bso_username + ")" : ""}`}
        </Button><span className="me-2"> - OR - </span></span>)}
        <Button
          className="primary-button me-2"
          disabled={!validated}
          type="submit"
          variant="success"
          {...(loadingForm) && { disabled: true }}
        >{loadingForm ? "Loading..." : "Login"}</Button>
        <Button className="simple-button" variant="light" onClick={cancelLogin} {...(loadingForm) && { disabled: true }}>{loadingForm ? "Loading..." : "Cancel"}</Button>
      </Form>
    </div>
  );
}

export { BSOLoginForm };
export default BSOLoginForm;