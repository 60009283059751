import React, { useState, useEffect } from 'react';
import { useUserData } from '../../contexts/AuthContext';
import { Form, Button, Alert, Col, InputGroup } from 'react-bootstrap';
import { StyledGreenCheckbox } from "../../styled/Forms";
import { admin } from "../../utils/EREDocs";
import { validParams } from "../../utils/";

const BSOAccountSettingsForm = ({ auth_id, closeModal } = {}) => {
  const { userData, setUserData } = useUserData();
  let { passport: { user: { user_settings = {}, selected_auth: { auth_id: default_auth_id } = {} } = {} } = {} } = userData || {};
  (!auth_id && default_auth_id) && (auth_id = default_auth_id);
  (typeof auth_id === 'string') && (auth_id = Number(auth_id));

  const [loadingForm, setLoadingForm] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [modalAlert, setModalAlert] = useState({ message: "", alert_type: "info" });
  const [editableForm, setEditableForm] = useState(false);
  const [previousFormValues, setPreviousFormValues] = useState({});
  const [formState, setFormState] = useState(user_settings);

  const handleInputChange = (event) => {
    if (!event?.target) return;
    event.stopPropagation(); // Prevent other events from firing on click

    let { name, value, type, checked = false } = event.target;
    if (type === 'checkbox') {
      value = checked;
    } else {
      event.preventDefault();
    }
    setFormState((prevState) => ({ ...prevState, [name]: value }));

    // Validate Form on change 
    let alertObject = { ...modalAlert };
    let requiredFields = ['EMAIL_BSO_LOGIN_FAIL', 'EMAIL_BSO_LOGIN_FAIL_ALWAYS', 'EMAIL_AUTH_NOTIFICATION_LIST', 'MAX_STRIKES_ALLOWED', 'TFA_METHOD', 'PAUSE_BRQ_PICKUP'];
    let testParams = Object.entries({ ...formState, [name]: value }).filter(([k, v]) => type === 'checkbox' || v).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
    let valid = validParams(testParams, requiredFields, 'any');
    setValidated(valid);
    setShowAlert(!valid);
    if (!valid) {
      alertObject.message = "Please fill out all required fields. Missing " + requiredFields.filter((f) => !testParams[f]).join(', ');
    }
    if (alertObject.message) {
      alertObject.message = alertObject.message.trim();
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }
    return;
  };

  const editInfo = async () => {
    setShowAlert(false);
    setEditableForm(true);
  };

  const onReset = (event) => {
    if (!event?.target) return;
    event.stopPropagation(); // Prevent other events from firing on click
    event.preventDefault();
    setEditableForm(false);
    setFormState(previousFormValues);
    (closeModal && typeof closeModal === 'function') && closeModal();
  };

  const onSubmit = async (event) => {
    if (!event?.target) return;
    event.stopPropagation(); // Prevent other events from firing on click
    event.preventDefault();

    let alertObject = { ...modalAlert };
    let BooleanFields = ['EMAIL_BSO_LOGIN_FAIL', 'EMAIL_BSO_LOGIN_FAIL_ALWAYS', 'PAUSE_BRQ_PICKUP'];
    let requiredFields = [...BooleanFields, 'EMAIL_AUTH_NOTIFICATION_LIST', 'MAX_STRIKES_ALLOWED', 'TFA_METHOD'];
    let settings = Object.entries(formState).filter(([k, v]) => requiredFields.includes(k)).map(([key, value]) => ({
      key,
      ...(BooleanFields.includes(key) && typeof value !== 'boolean' ?
        { value: value === "true" } : { value })
    }));
    let saveParams = {
      auth_id,
      settings,
    };

    let response;
    try {
      if (!validated) {
        throw new Error("Your form input is not valid. Please check your input and try again.");
      }
      setLoadingForm(true);
      response = await admin.saveAuthSettings(saveParams);
      alertObject.message = "Saved Successfully";
    } catch (ex) {
      console.log(ex);
      response = ex?.response || {};
      response.status = 500;
      response.statusText = `${ex.message}`;
      if (!response?.data) {
        response.data = { err: 401, details: `${ex.message}`, error: ex.error };
      }
    } finally {
      setLoadingForm(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    let { err, error, details, message = '', createdSettings = [] } = data;
    alertObject.alert_type = status !== 200 ? "danger" : "success";
    if (status !== 200) {
      alertObject.message = `[${status ?? err}] ${statusText} ${alertObject.message}`;
      alertObject.message += data?.status ? `${data.status} ` : ""; // ERROR 
      alertObject.message += error ? `${error} ` : ""; // ScrapeError Message + Controller Error 
      alertObject.message += (typeof response?.data === 'string') ? response.data : message ?? (details ? `${details} ` : "") + "Invalid submission.";
    }

    let newState = { ...formState };
    if (Array.isArray(createdSettings) && createdSettings.length > 0) {
      newState = {
        ...newState, ...createdSettings.reduce((acc, { key, value }) => ({
          ...acc,
          [key]: (BooleanFields.includes(key) && typeof value !== 'boolean') ? value === true : value
        }), {})
      };
      setFormState(prevState => ({ ...prevState, ...newState }));
    }

    setEditableForm(false);
    setPreviousFormValues(newState);
    if (auth_id === default_auth_id) {
      setUserData((prev) => ({
        ...prev, ...newState,
        passport: {
          ...prev.passport,
          user: {
            ...prev.passport.user, ...newState,
            user_settings: { ...prev.passport.user.user_settings, ...newState }
          }
        }
      }));
    }

    // Show Alert success or danger
    setShowAlert(!!alertObject?.message);
    if (!!alertObject?.message) {
      alertObject.message = alertObject.message.trim();
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }
    return;
  };

  useEffect(() => {
    let mounted = true;
    const init = async () => {
      let accountSettings = { ...formState };
      if (auth_id !== default_auth_id) {
        setLoadingForm(true);
        accountSettings = await admin.getSettings({ auth_id }).then(({ status = 500, statusText = "Internal Server Error", data = {} }) => {
          (status !== 200) && console.log(`${status} ${statusText}`);
          return data;
        });
        setLoadingForm(false);
        setFormState(accountSettings);
      } else {
        setUserData((prev) => ({
          ...prev, ...accountSettings,
          passport: {
            ...prev.passport,
            user: {
              ...prev.passport.user, ...accountSettings,
              user_settings: { ...prev.passport.user.user_settings, ...accountSettings }
            }
          }
        }));
      }
      setPreviousFormValues(accountSettings);
    }
    (mounted) && init();
    return () => mounted = false
  }, [auth_id]);

  return (
    <div>
      <Alert
        dismissible
        onClose={() => setShowAlert(false)}
        show={showAlert}
        variant={modalAlert.alert_type}
      >{modalAlert.message}</Alert>

      <Form noValidate onSubmit={onSubmit} onReset={onReset} autoComplete="off" className="row">
        <Form.Group controlId="BSOAccountSettingsPauseBrq" className="col-12">
          <StyledGreenCheckbox>
            <Form.Control
              className={previousFormValues.PAUSE_BRQ_PICKUP !== formState.PAUSE_BRQ_PICKUP && 'changed-field'}
              disabled={!editableForm}
              type="checkbox"
              name="PAUSE_BRQ_PICKUP"
              onChange={handleInputChange}
              checked={formState.PAUSE_BRQ_PICKUP === true || formState.PAUSE_BRQ_PICKUP === "true" ? true : false}
            />
            <span className="ms-2">Pause this BSO Account - Don't process BSO Request Queue Items.</span>
          </StyledGreenCheckbox>
        </Form.Group>
        <hr />
        <Form.Group controlId="BSOAccountSettingsEmailOnFail" className="col-12">
          <StyledGreenCheckbox>
            <Form.Control
              className={previousFormValues.EMAIL_BSO_LOGIN_FAIL !== formState.EMAIL_BSO_LOGIN_FAIL && 'changed-field'}
              disabled={!editableForm}
              type="checkbox"
              name="EMAIL_BSO_LOGIN_FAIL"
              onChange={handleInputChange}
              checked={formState.EMAIL_BSO_LOGIN_FAIL === true || formState.EMAIL_BSO_LOGIN_FAIL === "true" ? true : false}
            />
            <span className="ms-2">Send Email the first time a login fails.</span>
          </StyledGreenCheckbox>
        </Form.Group>
        <Form.Group controlId="BSOAccountSettingsEmailOnFailAlways" className="col-12">
          <StyledGreenCheckbox>
            <Form.Control
              className={previousFormValues.EMAIL_BSO_LOGIN_FAIL_ALWAYS !== formState.EMAIL_BSO_LOGIN_FAIL_ALWAYS && 'changed-field'}
              disabled={!editableForm}
              type="checkbox"
              name="EMAIL_BSO_LOGIN_FAIL_ALWAYS"
              onChange={handleInputChange}
              checked={formState.EMAIL_BSO_LOGIN_FAIL_ALWAYS === true || formState.EMAIL_BSO_LOGIN_FAIL_ALWAYS === "true" ? true : false}
            />
            <span className="ms-2">Send Email every time after the first failure, if a login fails.</span>
          </StyledGreenCheckbox>
        </Form.Group>
        <Form.Group controlId="BSOAccountSettingsEmailNotificationList" className="col-12">
          <Form.Label className="mt-3">Email notification list</Form.Label>
          <Form.Control
            className={previousFormValues.EMAIL_AUTH_NOTIFICATION_LIST !== formState.EMAIL_AUTH_NOTIFICATION_LIST && 'changed-field'}
            disabled={!editableForm}
            type="text"
            placeholder=""
            name="EMAIL_AUTH_NOTIFICATION_LIST"
            onChange={handleInputChange}
            value={formState.EMAIL_AUTH_NOTIFICATION_LIST}
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group controlId="BSOAccountSettingsMaxStrikesAllowed" className="col-12">
          <InputGroup className="mt-2">
            <Form.Label className="mt-3 me-2">Max strikes allowed</Form.Label>
            <Form.Control
              className={previousFormValues.MAX_STRIKES_ALLOWED !== formState.MAX_STRIKES_ALLOWED && 'changed-field'}
              disabled={!editableForm}
              type="number"
              min={1}
              max={99}
              placeholder=""
              name="MAX_STRIKES_ALLOWED"
              onChange={handleInputChange}
              value={formState['MAX_STRIKES_ALLOWED']}
              autoComplete="off"
              required
            />
            <Form.Label className="ms-4 me-2 mt-3">TFA Method</Form.Label>
            <Form.Select
              required
              className={previousFormValues.TFA_METHOD !== formState.TFA_METHOD && 'changed-field'}
              disabled={!editableForm}
              onChange={handleInputChange}
              aria-label="TFA Method"
              name="TFA_METHOD"
              value={formState.TFA_METHOD}>
              <option value="">Select TFA Method</option>
              <option value="gmail">Gmail</option>
              <option value="imap">IMAP</option>
              <option value="db">DB</option>
            </Form.Select>
          </InputGroup>
        </Form.Group>

        {editableForm ? (<Col className="col-12 mt-3 d-flex justify-content-end">
          {JSON.stringify(previousFormValues) !== JSON.stringify(formState) && (<Button
            className="btn btn-primary me-3"
            type="submit"
            disabled={loadingForm || !validated}
          >
            Save Changes
          </Button>)}
          <Button
            className="btn btn-secondary"
            type="reset"
            disabled={loadingForm}
          >
            Cancel Edit
          </Button>
        </Col>) : (<Col className="col-12 mt-3 d-flex justify-content-end">
          <Button
            className="btn btn-primary me-3"
            type="button"
            onClick={editInfo}
            disabled={loadingForm}
          >
            Edit
          </Button>
        </Col>)}
      </Form>
    </div>
  );
}

export { BSOAccountSettingsForm };
export default BSOAccountSettingsForm;