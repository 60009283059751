import React, { useState, useEffect } from 'react';
import { useUserData } from '../../contexts/AuthContext';
import { Form, Alert, Row, Col } from 'react-bootstrap';
import { humanize, validParams, splitArrayChunks } from '../../utils';
import { admin } from "../../utils/EREDocs";

const EREAccessSettingsForm = ({ user_id } = {}) => {
  const { userData, setUserData } = useUserData();
  let { server_autologin: default_server_autologin, client_autologin: default_client_autologin, isAuthenticated = false, passport: {
    user: { group_id = 6, user_id: default_user_id, ere_access: default_user_ere_access, ere_automate_tempcode: default_user_ere_automate_tempcode, server_autologin: default_user_server_autologin, client_autologin: default_user_client_autologin,
    } = {} } = {} } = userData || {};
  (default_user_id && typeof default_user_id === 'string') && (default_user_id = Number(default_user_id));
  (user_id && typeof user_id === 'string') && (user_id = Number(user_id));
  (!user_id && default_user_id) && (user_id = default_user_id);
  (default_user_ere_access && typeof default_user_ere_access === 'string') && (default_user_ere_access = default_user_ere_access === 'true');
  (default_user_ere_automate_tempcode && typeof default_user_ere_automate_tempcode === 'string') && (default_user_ere_automate_tempcode = default_user_ere_automate_tempcode === 'true');
  let superAdminAccess = (isAuthenticated && Number(group_id) === 1);
  let accessFieldSettings = ['ere_access', 'ere_automate_tempcode', 'server_autologin', 'client_autologin'];
  let labelRemap = {
    ere_access: 'allow_ere_access',
    ere_automate_tempcode: 'allow_ere_automate_tfa',
    server_autologin: 'allow_server_auto_login',
    client_autologin: 'allow_client_auto_login',
  };

  const [loadingForm, setLoadingForm] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [modalAlert, setModalAlert] = useState({ message: "", alert_type: "info" });
  const [formState, setFormState] = useState({
    ere_access: false,
    ere_automate_tempcode: false,
    server_autologin: false,
    client_autologin: false,
  });

  const handleInputChange = async (event) => {
    if (!event?.target) return;
    event.stopPropagation(); // Prevent other events from firing on click

    let { name, value, type, checked = false } = event.target;
    if (type === 'checkbox') {
      value = checked;
    } else if (type === 'radio') {
      (typeof value !== 'boolean') && (value = value === 'true');
    } else {
      event.preventDefault();
    }

    // Update the setting in form state 
    setFormState((prevState) => ({ ...prevState, [name]: value }));

    // Validate Form on change 
    let alertObject = { ...modalAlert };
    let requiredFields = [...accessFieldSettings];
    let testParams = Object.entries({ ...formState, [name]: value }).filter(([k, v]) => type === 'checkbox' || v).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
    let valid = validParams(testParams, requiredFields, 'any');
    setShowAlert(!valid);
    if (!valid) {
      alertObject.alert_type = "info";
      alertObject.message = "I hope you know what you're doing. Missing " + requiredFields.filter((f) => !testParams[f]).join(', ');
      alertObject.message = alertObject.message.trim();
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }

    // Update the settings in the database
    if (accessFieldSettings.includes(name)) {
      saveUserAccess(name, value);
    }
    return;
  };

  const saveUserAccess = async (key, value) => {
    (typeof value !== 'boolean') && (value = value === "true");
    let alertObject = { ...modalAlert };
    let condition = { user_id };
    let values = { ...condition, [key]: value };
    let payload = { values, condition, returning: true };
    let response;
    try {
      setLoadingForm(true);
      response = await admin.updateLawFirmUser(payload);
      alertObject.message = "Saved Successfully";
    } catch (ex) {
      console.log(ex);
      response = ex?.response || {};
      response.status = 500;
      response.statusText = `${ex.message}`;
      if (!response?.data) {
        response.data = { err: 401, details: `${ex.message}`, error: ex.error };
      }
    } finally {
      setLoadingForm(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    let { err, error, details, message = '', statusCode = 500, statusMessage = "Internal Server Error", data: updatedResults = {}, rows = [], rowsChanged = 0 } = data || {};
    alertObject.alert_type = status !== 200 ? "danger" : "success";
    if (status !== 200 || statusCode !== 200) {
      alertObject.message = `[${status ?? err}] ${statusText ?? statusMessage} ${alertObject.message}`;
      alertObject.message += data?.status ? `${data.status} ` : ""; // ERROR 
      alertObject.message += error ? `${error} ` : ""; // ScrapeError Message + Controller Error 
      alertObject.message += (typeof response?.data === 'string') ? response.data : message ?? (details ? `${details} ` : "") + "Invalid submission.";
    }

    let {
      ere_access: updated_ere_access = false,
      ere_automate_tempcode: updated_ere_automate_tempcode = false,
      server_autologin: updated_server_autologin = false,
      client_autologin: updated_client_autologin = false,
    } = updatedResults || {};
    let updatedAccessSettings = {
      ere_access: (typeof updated_ere_access === 'boolean') ? updated_ere_access : updated_ere_access === 'true',
      ere_automate_tempcode: (typeof updated_ere_automate_tempcode === 'boolean') ? updated_ere_automate_tempcode : updated_ere_automate_tempcode === 'true',
      server_autologin: (typeof updated_server_autologin === 'boolean') ? updated_server_autologin : updated_server_autologin === 'true',
      client_autologin: (typeof updated_client_autologin === 'boolean') ? updated_client_autologin : updated_client_autologin === 'true',
    }

    if (Object.values(updatedAccessSettings).filter(Boolean).length > 0) {
      setFormState(prevState => ({ ...prevState, ...updatedAccessSettings }));
    }

    if (user_id === default_user_id) {
      let topLevelSetting = {
        server_autologin: updatedAccessSettings.server_autologin,
        client_autologin: updatedAccessSettings.client_autologin,
      };
      setUserData((prev) => ({
        ...prev, ...topLevelSetting,
        passport: {
          ...prev.passport,
          user: {
            ...prev.passport.user, ...updatedAccessSettings
          }
        }
      }));
    }

    // Show Alert success or danger
    setShowAlert(!!alertObject?.message);
    if (!!alertObject?.message) {
      alertObject.message = alertObject.message.trim();
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }
    return;
  }

  const getUserAccessSettings = async (userID = user_id) => {
    let defaultAccessSettings = (user_id === default_user_id) ? {
      ere_access: !!default_user_ere_access,
      ere_automate_tempcode: !!default_user_ere_automate_tempcode,
      server_autologin: default_server_autologin ?? default_user_server_autologin ?? false,
      client_autologin: default_client_autologin ?? default_user_client_autologin ?? false,
    } : { ...formState };
    let accessSettings = { ...defaultAccessSettings };
    let accessSettingsTrueCount = Object.values(accessSettings).filter(Boolean).length;
    if (accessSettingsTrueCount === 0 || userID !== default_user_id) { // At least 1 of the access settings should be true or we'll check getSettings
      let params = { ...(userID) && { user_id: userID } };
      accessSettings = await admin.users(params).then(({ status = 500, statusText = "Internal Server Error", data: { data: loadedUsers = [] } = {} } = {}) => {
        (status !== 200) && console.log(`${status} ${statusText}`);
        if (Array.isArray(loadedUsers) && loadedUsers.length === 1) {
          return loadedUsers[0];
        }
        console.log("Using default access settings because response is not what we expected", loadedUsers);
        return defaultAccessSettings;
      });
    }

    setFormState(prevState => ({ ...prevState, ...accessSettings }));
    if (userID === default_user_id) {
      let topLevelSetting = {
        server_autologin: accessSettings.server_autologin,
        client_autologin: accessSettings.client_autologin,
      };
      setUserData((prev) => ({
        ...prev, ...topLevelSetting,
        passport: {
          ...prev.passport,
          user: {
            ...prev.passport.user, ...accessSettings
          }
        }
      }));
    }
    return;
  }

  useEffect(() => {
    let mounted = true;
    const init = async () => {
      setLoadingForm(true);
      await getUserAccessSettings(user_id);
      setLoadingForm(false);
    }
    (mounted) && init();
    return () => mounted = false
  }, [user_id]);

  return (
    <div>
      <h4>ERE Access Settings</h4>
      <hr />
      <Alert
        dismissible
        onClose={() => setShowAlert(false)}
        show={showAlert}
        variant={modalAlert.alert_type}
        className="p-2 mb-1"
      >{modalAlert.message}</Alert>

      <Form noValidate autoComplete="off" className="row">
        {Object.keys(formState).length > 0 && splitArrayChunks(Object.entries(formState).filter(([k, v]) => accessFieldSettings.includes(k)), 2).map((chunk, idx) => (<Row key={`chunk-${idx}`}>
          {chunk.map(([key, value], index) => (<Col key={`${index}-${key}`}>
            <Form.Group className="mb-3">
              <Form.Label as={`${labelRemap[key] ?? key}`} column>
                <strong>{humanize(labelRemap[key] ?? key)}</strong>
              </Form.Label>
              <Col>
                <Form.Check
                  inline
                  type="radio"
                  label="Enabled"
                  name={key}
                  disabled={(key === "ere_access" && user_id === default_user_id) ? !superAdminAccess : loadingForm}
                  onChange={handleInputChange}
                  value={true}
                  checked={typeof value === "boolean" ? value : value === "true"}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Disabled"
                  name={key}
                  disabled={(key === "ere_access" && user_id === default_user_id) ? !superAdminAccess : loadingForm}
                  onChange={handleInputChange}
                  value={false}
                  checked={typeof value === "boolean" ? !value : value === "false"}
                />
              </Col>
            </Form.Group>
          </Col>))}
        </Row>))}
      </Form>
    </div>
  );
}

export { EREAccessSettingsForm };
export default EREAccessSettingsForm;