import React, { useState, useEffect } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import { bsoaccount_management } from '../../utils/manage';
import { isObject } from '../../utils/';

const TestSFTPDeliveryModal = ({ org_id, ...props } = {}) => {
  const [loadingTest, setLoadingTest] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [modalAlert, setModalAlert] = useState({ message: "", alert_type: "info" });
  const [modalState, setModalState] = useState(false);

  const openModal = () => (setModalState(true));
  const closeModal = () => (setModalState(false));
  const testSFTPDelivery = async (event) => {
    if (event) event.preventDefault() && event.stopPropagation();
    let alertObject = { ...modalAlert };

    let response;
    try {
      setLoadingTest(true);
      let confirm = prompt("Are you sure? This may only test well on live server.", "yes");
      if (confirm !== "yes") {
        throw new Error("Test SFTP delivery cancelled.");
      }
      response = await bsoaccount_management.testSecretsForSFTP({ org_id });
      alertObject.message = "Successfully tested SFTP delivery.";
    } catch (ex) {
      console.log(ex);
      response = ex?.response || {};
      response.status = 500;
      response.statusText = `${ex.message}`;
      if (!response?.data) {
        response.data = { statusCode: 401, statusMessage: `${ex.message}`, error: ex.error, data: null };
      }
    } finally {
      setLoadingTest(false);
      let { status = 500, statusText = '', data = {} } = response || {};
      let { statusCode = 500, statusMessage = '', error, data: testFiles } = data || {};

      alertObject.alert_type = status === 200 ? "success" : "danger";
      if (status !== 200 || error) {
        console.log(error, statusMessage);
        alertObject.message = `[${status ?? statusCode}] ${statusText} `;
        if (statusMessage) {
          alertObject.message += isObject(statusMessage) ? Object.entries(statusMessage).map(([key, value]) => `${key} = ${value}`).join(', ') : statusMessage;
        }
        if (error && !isObject(error)) {
          alertObject.message += `${error}`;
        }
      }
      if (testFiles) {
        alertObject.message += ` Test Files: ${testFiles}`;
      }
    }

    // Show Alert success or danger
    if (alertObject?.message) {
      alertObject.message = alertObject.message.trim();
      setShowAlert(true);
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }
    return;
  }

  useEffect(() => {
    let mounted = true;
    if (mounted) setLoadingTest(false);
    return () => mounted = false;
  }, []);

  return (
    <div {...props} >
      <Button onClick={openModal}>Test SFTP Delivery</Button>
      <Modal
        show={modalState}
        onHide={closeModal}
        animation={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Test SFTP Delivery
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert
            dismissible
            onClose={() => setShowAlert(false)}
            show={showAlert}
            variant={modalAlert.alert_type}
            className="p-2 mb-1"
          >{modalAlert.message}</Alert>
          <Button
            onClick={testSFTPDelivery}
            className="primary-button me-2"
            type="submit"
            variant="success"
            {...(loadingTest) && { disabled: true }}
          >{loadingTest ? "Testing..." : "Test SFTP Delivery"}</Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export { TestSFTPDeliveryModal };
export default TestSFTPDeliveryModal;