import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import { ForgotPasswordForm } from '../Forms';

const ForgotPasswordModal = ({ title, ...props } = {}) => {
  const [modalState, setModalState] = useState(false);
  const openModal = (event) => {
    event.preventDefault();
    setModalState(true)
  };
  const closeModal = () => (setModalState(false));

  return (
    <div {...props}>
      <Link to="#forgot-password" onClick={openModal}>{(title ? title : "Forgot Your Password?")}</Link>
      <Modal
        show={modalState}
        onHide={closeModal}
        animation={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {(props?.title ? props.title : "Forgot Your Password?")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ForgotPasswordForm closeModal={closeModal} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export { ForgotPasswordModal };
export default ForgotPasswordModal;