import React from 'react';

// Bootstrap Components
import { Row, Col } from 'react-bootstrap';

// Components 
import SupportModal from '../components/Modals/SupportModal';
import PublicHeader from '../components/PublicHeader';

const Help = () => {
  return (
    <div className="page-container">
      <PublicHeader />
      <h4>Help</h4>
      <hr />
      <Row>
        <Col>
          <SupportModal title="Contact Us" />
        </Col>
      </Row>
    </div>
  );
}

export default Help;