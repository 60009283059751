import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { LawFirmForm } from '../Forms';

const AddLawFirmModal = ({ org_id, setInvokeParentUpdate = () => { }, ...props } = {}) => {
  const [modalState, setModalState] = useState(false);
  const openModal = () => (setModalState(true));
  const closeModal = () => {
    setModalState(false);
    if (typeof setInvokeParentUpdate === 'function') {
      setInvokeParentUpdate((prevState) => !prevState);
    }
  };

  return (
    <div {...props} >
      <Button onClick={openModal}>+ Add Law Firm</Button>
      <Modal
        show={modalState}
        onHide={closeModal}
        animation={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title title={`(org_id: ${org_id})`}>Add Law Firm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LawFirmForm org_id={0} closeModal={closeModal} setInvokeParentUpdate={setInvokeParentUpdate} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export { AddLawFirmModal };
export default AddLawFirmModal;