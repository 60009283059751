import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useUserData } from "../contexts/AuthContext";
import { Row, Col, ListGroup, Alert, Button } from 'react-bootstrap';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import { StyledHorizontalList } from "../styled/List";
import { StyledButton } from "../styled/Misc";
import BSOCredentialSettings from '../components/Settings/BSOCredentialSettings';
import { BSOAccountForm, BSOAccountSettingsForm } from '../components/Forms';
import BSOAccountStrikeLogs from '../components/Tables/BSOAccountStrikeLogs';
import PickupFiles from '../components/Tables/PickupFiles';
import HearingsStatusReport from '../components/Tables/HearingsStatusReport';
import AppealsStatusReport from '../components/Tables/AppealsStatusReport';
import BSORequestQueueSettings from '../components/Settings/BSORequestQueueSettings';
import Loader from "../components/Loader";
import Debug from "../components/Debug";
import { admin } from '../utils/EREDocs';
import { isObject } from '../utils/';

const BSOAccountManagementDetails = () => {
  let { auth_id } = useParams();
  const { userData } = useUserData();

  // Pull auth_id from userData if not provided 
  let { passport: {
    user: { auth_id: default_user_auth_id,
      selected_auth: { auth_id: default_auth_id } = {},
      organization: {
        org_id,
        prefix,
      } = {},
    } = {}
  } = {} } = userData || {};
  (typeof org_id !== "number") && (org_id = Number(org_id));
  (!auth_id && (default_auth_id || default_user_auth_id)) && (auth_id = default_auth_id ? default_auth_id : default_user_auth_id);
  (auth_id && typeof auth_id !== 'number') && (auth_id = Number(auth_id));

  const [loadingPage, setLoadingPage] = useState(false);
  const [invokeUpdate, setInvokeUpdate] = useState(false);
  const [showPageAlert, setShowPageAlert] = useState(false);
  const [pageAlert, setPageAlert] = useState({ message: "", alert_type: "info" });
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedStatusReport, setSelectedStatusReport] = useState(0);
  const [bsoUser, setBsoUser] = useState('');
  const [defaultAuthUser, setDefaultAuthUser] = useState(0);
  const [authOrgId, setAuthOrgId] = useState(0);
  const [firmSecretsLabel, setFirmSecretsLabel] = useState("Firm Secrets");
  const [firmSecrets, setFirmSecrets] = useState({});

  const readFirmSecrets = async (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    let alertObject = { ...pageAlert };
    let newState = { ...firmSecrets };
    let params = { auth_id, enabled: false };
    let response;
    try {
      if (loadingPage) {
        throw new Error("Please wait for the form to finish loading.");
      }
      if (!params?.auth_id) {
        throw new Error("Missing auth_id.");
      }
      setLoadingPage(true);
      response = await admin.secrets(params);
      alertObject.message = "Read Secrets Successfully!";
    } catch (ex) {
      console.log(ex);
      response = ex?.response || {};
      response.status = 500;
      response.statusText = `${ex.message}`;
      if (!response?.data) {
        response.data = { err: 401, message: `${ex.message}`, error: ex.error };
      }
    } finally {
      setLoadingPage(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    let { error, message = '', secrets, SecretId } = data;
    let { SecretString = '{}' } = secrets || {};
    alertObject.alert_type = status !== 200 ? "danger" : "success";
    if (status !== 200) {
      alertObject.message = `[${status}] ${statusText} `;
      newState = {}; // Reset the state
    }
    alertObject.message += message ? `${message}` : "";
    alertObject.message += SecretId ? ` SecretId: ${SecretId} ` : "";
    alertObject.message += error ? ` ${error} ` : "";

    if (SecretId) {
      setFirmSecretsLabel(`Firm SecretId ${SecretId}`);
    }

    // Extract the secrets from the response
    try {
      if (!SecretString || typeof SecretString !== 'string') {
        throw new Error("Invalid secret string");
      }
      newState = JSON.parse(SecretString);
    } catch (ex) {
      newState = {}; // Reset the state
    }
    setFirmSecrets((prev) => ({ ...prev, ...newState }));

    // Show Alert success or danger
    setShowPageAlert(!!alertObject?.message);
    if (alertObject?.message) {
      alertObject.message = alertObject.message.trim();
      setPageAlert(prevState => ({ ...prevState, ...alertObject }));
    }
    return;
  };

  // Set the auth_account enabled=false and remove AWSsecrets 
  const deleteBSOAccount = async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    let alertObject = { ...pageAlert };
    let response;
    try {
      if (loadingPage) {
        throw new Error("Please wait for the form to finish loading.");
      }
      if (!auth_id) {
        throw new Error("Can't delete BSO account need auth_id.");
      }
      setLoadingPage(true);
      response = await admin.disableAuthAccount({ auth_id }); // Disables auth_account and removes AWSsecrets object for bso account
      alertObject.message = "";
    } catch (ex) {
      console.log(ex);
      response = ex?.response || {};
      response.status = 500;
      response.statusText = `${ex.message}`;
      if (!response?.data) {
        response.data = { error: (ex.message) ? `${ex.message}` : ex.error || ex };
      }
    } finally {
      setLoadingPage(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    let { status: dataStatus, message: dataMessage, error: dataError, info = {} } = data;

    // Extract the secrets from the response
    let { error: infoError, data: infoData = {}, statusMessage: infoMessage } = info || {};
    let { results = {} } = infoData || {};
    let { SearchBy = {}, SecretId: _SecretId = '', SecretObject = {}, SecretProperty = '', secret_response = {} } = results || {};
    let { Name: SecretId, VersionId } = secret_response || {};

    // Show Alert success or danger
    alertObject.alert_type = status !== 200 ? "danger" : "success";
    if (status !== 200) {
      alertObject.message = `[${status}] ${statusText}`;
      if (typeof response?.data === 'string') {
        alertObject.message += ` ${response.data}`;
      } else {
        (dataStatus) && (alertObject.message += ` ${dataStatus}`);
        (dataMessage) && (alertObject.message += ` ${dataMessage}`);
        (dataError) && (alertObject.message += ` ${dataError}`);
      }
    }
    (infoMessage) && (alertObject.message += ` ${infoMessage} `);
    (infoError) && (alertObject.message += ` ${infoError} `);
    (SecretProperty) && (alertObject.message += ` Deleted ${SecretProperty} `);
    (isObject(SearchBy)) && (alertObject.message += " found by " + Object.entries(SearchBy).map(([key, value]) => `${key}: ${value}`).join(', '));
    (SecretId || _SecretId) && (alertObject.message += ` SecretId: ${SecretId || _SecretId} `);
    (VersionId) && (alertObject.message += ` VersionId: ${VersionId} `);

    // Update setFirmSecrets with new state of SecretObject
    if (SecretId && isObject(SecretObject) && Object.keys(SecretObject).length > 0) {
      setFirmSecretsLabel(`Firm SecretId ${SecretId}`);
      setFirmSecrets((prev) => ({ ...prev, ...SecretObject }));
    }

    // Show Alert success or danger
    setShowPageAlert(!!alertObject?.message);
    if (alertObject?.message) {
      alertObject.message = alertObject.message.trim();
      setPageAlert(prevState => ({ ...prevState, ...alertObject }));
    }

    setInvokeUpdate((prevState) => !prevState);
    return;
  };

  const hearingsReportSelected = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedStatusReport(0);
  };
  const appealsReportSelected = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedStatusReport(1);
  };
  const StatusReport = (props) => {
    switch (selectedStatusReport) {
      case 0:
        return (<HearingsStatusReport {...props} />);
      case 1:
        return (<AppealsStatusReport {...props} />);
      default:
        return null;
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setLoadingPage(!(bsoUser || defaultAuthUser));
    }
    return () => mounted = false;
  }, [bsoUser, defaultAuthUser]);

  return (<div className="page-container">
    <Row>
      <Col className="px-0 d-flex justify-content-between">
        <h4>BSO Account Management</h4>
        <Button className="remove-button" name="delete_bso_account" onClick={deleteBSOAccount} >Delete BSO Acct.</Button>
      </Col>
    </Row>
    <hr />
    <Alert
      dismissible
      onClose={() => setShowPageAlert(false)}
      show={showPageAlert}
      variant={pageAlert.alert_type}
      className="p-2 mb-1"
    >{pageAlert.message}</Alert>

    <Row>
      <Col xs={5} className="px-0">
        <Row><BSOAccountForm className="px-0 col-12" auth_id={auth_id} hideBSOFields={true} setBsoUser={setBsoUser} setDefaultAuthUser={setDefaultAuthUser} setAuthOrgId={setAuthOrgId} invokedByParent={invokeUpdate} setInvokeParentUpdate={setInvokeUpdate} /></Row>
        <Row><BSOCredentialSettings className="px-0 col-12" auth_id={auth_id} prefix={prefix} bsoUser={bsoUser} defaultAuthUser={defaultAuthUser} invokedByParent={invokeUpdate} /></Row>
      </Col>

      <Col>
        <Tabs key="tabs" selectedIndex={tabIndex} onSelect={tabIndex => setTabIndex(tabIndex)} >
          <TabList>
            <Tab key="tab-1" ><h5>Settings</h5></Tab>
            <Tab key="tab-2" ><h5>BSO Strike Logs</h5></Tab>
            <Tab key="tab-3" ><h5>Get Status Reports</h5></Tab>
            <Tab key="tab-4"><h5>Pick Up Files</h5></Tab>
          </TabList>

          <TabPanel key="tab-1-info">
            <Row>
              <Col>
                <h6>BSO Account Settings</h6>
                <BSOAccountSettingsForm auth_id={auth_id} />
              </Col>
            </Row>
            <Row>
              <Col>
                <h6>BSO Account HSR/ASR Daily Download Schedules</h6>
                <BSORequestQueueSettings className="px-0" auth_id={auth_id} requiredSearchParams={{ type: ['DOWNLOAD_HSR_ASR', 'DOWNLOAD_IRSR'], auth_id }} renamedColumns={{ 'bso_request_queue_id': 'ID', 'auth_id': 'Attorney ID' }} displayedColumns={['bso_request_queue_id', 'type', 'status', 'next_run', 'ran_count']} />
              </Col>
            </Row>
          </TabPanel>
          <TabPanel key="tab-2-info"><BSOAccountStrikeLogs auth_id={auth_id} /></TabPanel>
          <TabPanel key="tab-3-info">
            <StyledHorizontalList horizontal>
              <ListGroup.Item>
                <Link to="#hsr" onClick={hearingsReportSelected} {...(selectedStatusReport === 0 && { className: "active" })}>Hearing Office Status Report</Link>
              </ListGroup.Item>
              <ListGroup.Item>
                <Link to="#asr" onClick={appealsReportSelected} {...(selectedStatusReport === 1 && { className: "active" })}>Appeals Council Status Report</Link>
              </ListGroup.Item>
            </StyledHorizontalList>
            {loadingPage ? (<Loader className="text-info" style={null} />) : (<StatusReport auth_id={auth_id} />)}
          </TabPanel>
          <TabPanel key="tab-4-info">
            <PickupFiles auth_id={auth_id} org_id={authOrgId} />
          </TabPanel>
        </Tabs>
      </Col>
    </Row>
    <Row>
      <Debug debugLabel={firmSecretsLabel} debugData={firmSecrets} className="mt-3 col-12 firm-secrets">
        <div className="ps-0 mb-3">
          <StyledButton
            className={`${loadingPage ? "light-green-button" : "green-button"}`}
            onClick={readFirmSecrets}
          >{loadingPage ? "Reading Secret..." : "Read Firm Secrets"}</StyledButton>
        </div>
      </Debug>
    </Row>
  </div>);
}

export default BSOAccountManagementDetails;