import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Link } from "react-router-dom";

// NOTE - At least two routes are accessible without a login token, they display a password reset form, help page, and privacy policy.
// Those pages use this public header to help brand those pages.
// See the following links for an example: http://localhost:3002/help and http://localhost:3002/reset-password/e7a89d49-17b5-3f5a-a713-b7e9ca4a0dac

const PublicHeader = () => {
  return (
    <Link to="/login">
      <Navbar collapseOnSelect expand="lg" variant="light" className="header-main-nav">
        <Navbar.Brand><span>ERE<span className="blue-text">DOCS</span></span></Navbar.Brand>
      </Navbar>
    </Link>
  );
}

export default PublicHeader;