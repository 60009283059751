import React, { useState, useEffect } from 'react';
import { useUserData } from '../../contexts/AuthContext';
import { Form, Button, Alert, Row, Col } from 'react-bootstrap';
import ChangePasswordModal from '../Modals/ChangePasswordModal';
import { admin } from "../../utils/EREDocs";
import { formatPhoneNumber, validParams } from "../../utils/";
const defaultQueryParams = { showDebug: false };

const OVERRIDE_LOGIN = process.env.REACT_APP_OVERRIDE_LOGIN === "true";
const LawFirmUserForm = ({ user_id, org_id, closeModal = () => { }, setInvokeParentUpdate = () => { }, setSelectedOrgId = () => { } } = {}) => {
  const { userData } = useUserData();
  let { isAuthenticated = false, passport: {
    user: { user_id: session_user_id, org_id: default_firm_id, group_id: default_role = 6, first_name: default_first_name, last_name: default_last_name, email: default_email, status: default_status,
      phone_number: default_phone_number,
      organization: { organization: defaultFirm, prefix: defaultFirmPrefix, org_parents: firmParents, external_id: default_external_id } = {},
      selected_auth: { bso_username: default_bso_username, auth_id: default_auth_id, firm_id: default_external_firm_id,
        attorney: default_attorney, attorney_email: default_attorney_email, default_user_id, enabled: default_enabled,
        locked: default_locked, last_login_attempted: default_last_login_attempted, text_number: default_text_number
      } = {}
    } = {}
  } = {} } = userData || {};
  (user_id && typeof user_id !== 'number') && (user_id = Number(user_id));
  (org_id && typeof org_id !== 'number') && (org_id = Number(org_id));
  (default_role && typeof default_role !== 'number') && (default_role = Number(default_role));
  (default_firm_id && typeof default_firm_id !== 'number') && (default_firm_id = Number(default_firm_id));
  (session_user_id && typeof session_user_id !== 'number') && (session_user_id = Number(session_user_id));
  (org_id) && (default_firm_id = org_id);
  let superAdminAccess = (isAuthenticated && default_role === 1) || OVERRIDE_LOGIN;
  let adminAccess = (isAuthenticated && default_role === 2) || OVERRIDE_LOGIN;

  const [loadingForm, setLoadingForm] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [modalAlert, setModalAlert] = useState({ message: "", alert_type: "info" });

  const [editableForm, setEditableForm] = useState(!user_id);
  const [previousFormValues, setPreviousFormValues] = useState({});

  const [availableRoles, setAvailableRoles] = useState([{ group_id: default_role }]);
  const [lawFirms, setLawFirms] = useState([{
    org_id: default_firm_id, organization: defaultFirm, prefix: defaultFirmPrefix, org_parents: firmParents,
    external_id: default_external_id
  }]);

  const [formState, setFormState] = useState({
    lawFirm: '',
    role: 6,
    status: '',
    firstName: '',
    lastName: '',
    email: '',
    phone_number: '',
  });

  const handleInputChange = (event) => {
    if (!event?.target?.name) {
      return;
    }
    let alertObject = { message: "", alert_type: "info" };
    let phoneNumberFields = ['phone_number'];
    let { name, value, type, checked = false } = event.target;

    let newState = {};
    if (type === 'checkbox') {
      value = checked;
    } else if (phoneNumberFields.includes(name)) {
      value = formatPhoneNumber(value);
    }
    newState[name] = value;
    setFormState((prevState) => ({ ...prevState, ...newState }));

    // Validate Form on change 
    let requiredFields = ['lawFirm', 'role', 'email']; // backend version is org_id, group_id, email, password but a default random password will be used when not provided 
    let testParams = Object.entries({ ...formState, [name]: value }).filter(([k, v]) => v).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
    let valid = validParams(testParams, requiredFields, 'required');
    if (valid) {
      const email_regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,40})+$/;
      valid = email_regex.test(testParams.email);
      !valid && (alertObject.message = "Please enter a valid email address.");
    } else {
      alertObject.message = "Please fill out all required fields. Missing " + requiredFields.filter((f) => !testParams[f]).join(', ');
    }

    setValidated(valid);
    setShowAlert(!valid);
    if (!!alertObject.message) {
      alertObject.message = alertObject.message.trim();
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }
  };

  const loadLawFirmUser = async () => {
    let loadedValues = (user_id === session_user_id) ? {
      lawFirm: default_firm_id,
      role: default_role,
      status: default_status,
      firstName: default_first_name,
      lastName: default_last_name,
      email: default_email,
      phone_number: default_phone_number,
    } : {
      lawFirm: default_firm_id,
      role: 6,
      status: 'inactive',
      firstName: '',
      lastName: '',
      email: '',
      phone_number: '',
    };

    if (user_id) {
      let { data: { data: loadedUsers = [] } = {} } = await admin.users({ ...defaultQueryParams, user_id, group_id: default_role });
      if (Array.isArray(loadedUsers) && loadedUsers.length === 1) {
        let { org_id: lawFirm = default_firm_id, group_id: role = default_role,
          status = "inactive", first_name: firstName = '', last_name: lastName = '', email,
          phone_number = default_phone_number, group = {}, organization = {}
        } = loadedUsers[0] || {};

        loadedValues = {
          lawFirm,
          role,
          status,
          firstName,
          lastName,
          email,
          phone_number,
        };

        if (Object.keys(group).length > 0) {
          setAvailableRoles([group]);
        }
        if (Object.keys(organization).length > 0) {
          setLawFirms([organization]);
        }
      }
    }

    // Format phone numbers
    let phoneNumberFields = ['phone_number'];
    loadedValues = Object.entries(loadedValues).reduce((acc, [key, value]) => ({
      ...acc, ...{
        [key]: phoneNumberFields.includes(key) ? formatPhoneNumber(value) : value
      }
    }), {});

    // Save loadedValues
    setFormState(loadedValues);
    setPreviousFormValues(loadedValues);

    if (loadedValues.lawFirm && typeof setSelectedOrgId === 'function') {
      setSelectedOrgId(loadedValues.lawFirm);
    }

    return loadedValues;
  } // End of loadLawFirmUser

  const editInfo = () => (setEditableForm(true));
  const cancelupdateLawFirmUser = () => {
    setEditableForm(false);
    setFormState(previousFormValues);
    (closeModal && typeof closeModal === 'function') && closeModal();
  }

  // ===============[ updateLawFirmUser ]==============================
  const updateLawFirmUser = async (event) => {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation();

    let alertObject = {
      message: "",
      alert_type: "info",
    };

    let response;
    try {
      if (!validated) {
        throw new Error("Your form input is not valid. Please check your input and try again.");
      }

      let saveParams = {
        ...(user_id && { user_id }), // New user will be created when user_id is not provided
        ...formState,
      };

      setLoadingForm(true);
      saveParams = Object.entries(saveParams).reduce((acc, [k, v]) => ({ ...acc, ...(v && { [k]: v }) }), {}); // Filter out empty values  
      response = await admin.saveLawFirmUser(saveParams).then((resp) => {
        setLoadingForm(false);
        if (resp.data?.params?.user_id) {
          resp.data.params.user_id = Number(resp.data.params.user_id);
        }
        return resp;
      });

      alertObject.message = "Saved Successfully";
    } catch (ex) {
      setLoadingForm(false);
      console.log(ex);
      response = ex?.response || {};
      response.status = 500;
      response.statusText = `${ex.message}`;
      if (!response?.data) {
        response.data = { err: 401, details: `${ex.message}`, error: ex.error };
      }
    }

    let { status = 500, statusText = '', data = {} } = response;
    let { err, error, details, message = '', params = {} } = data;
    if (params?.user_id) {
      user_id = params.user_id;
    }

    alertObject.alert_type = status !== 200 ? "danger" : "success";
    if (status !== 200) {
      alertObject.message = `[${status ?? err}] ${statusText} ${alertObject.message}`;
      alertObject.message += data?.status ? `${data.status} ` : ""; // ERROR 
      alertObject.message += error ? `${error} ` : ""; // ScrapeError Message + Controller Error 
      alertObject.message += (typeof response?.data === 'string') ? response.data : message ?? (details ? `${details} ` : "") + "Invalid submission.";
    }

    // Update previous with new values
    setEditableForm(false);
    setPreviousFormValues(formState);

    // Show Alert success or danger
    if (alertObject?.message) {
      alertObject.message = alertObject.message.trim();
      setShowAlert(true);
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }

    if (typeof setInvokeParentUpdate === 'function') {
      setInvokeParentUpdate((prevState) => !prevState);
    }
    return;
  };

  // ===============[ useEffect ]==================================
  useEffect(() => {
    let mounted = true;
    const init = async () => {
      let alertObj = { message: "", alert_type: "info" };
      setLoadingForm(true);

      if (superAdminAccess || adminAccess) {
        // Load organizations for dropdown
        await admin.organizations().then(({ status = 500, statusText = "Internal Server Error", data: { data: loadedLawFirms = [] } = {} } = {}) => {
          (status !== 200) && console.log(`${status} ${statusText}`);
          if (Array.isArray(loadedLawFirms) && loadedLawFirms.length > 0) {
            setLawFirms(loadedLawFirms);
          }
        }).catch((ex) => {
          console.log(ex, ex?.message, ex?.stack);
          let { status = 500, statusText = "Internal Server Error", data = {} } = ex?.response || {};
          alertObj.alert_type = "danger";
          alertObj.message += "Get Organizations Error: ";
          if (typeof data === 'string') {
            alertObj.message += data;
          } else {
            let { error, details, message } = data || {};
            (message) && (alertObj.message += `${message} `);
            (details) && (alertObj.message += `${details} `);
            (error) && (alertObj.message += `${error} `);
          }
          alertObj.message += `[${status}] ${statusText} ${ex?.message}. `;
        });

        // Load groups for dropdown
        await admin.groups().then(({ status = 500, statusText = "Internal Server Error", data: { data: loadedRoles = [] } = {} } = {}) => {
          (status !== 200) && console.log(`${status} ${statusText}`);
          if (Array.isArray(loadedRoles) && loadedRoles.length > 0) {
            loadedRoles = loadedRoles.filter(({ group_id = 6 }) => Number(group_id) >= default_role);
            setAvailableRoles(loadedRoles);
          }
        }).catch((ex) => {
          console.log(ex, ex?.message, ex?.stack);
          let { status = 500, statusText = "Internal Server Error", data = {} } = ex?.response || {};
          alertObj.alert_type = "danger";
          alertObj.message += "Get groups Error: ";

          if (typeof data === 'string') {
            alertObj.message += data;
          } else {
            let { error, details, message } = data || {};
            (message) && (alertObj.message += `${message} `);
            (details) && (alertObj.message += `${details} `);
            (error) && (alertObj.message += `${error} `);
          }
          alertObj.message += `[${status}] ${statusText} ${ex?.message}. `;
        });
      }

      setShowAlert(!!alertObj.message);
      if (!!alertObj.message) {
        alertObj.message = alertObj.message.trim();
        setModalAlert(prevState => ({ ...prevState, ...alertObj }));
      } else {
        await loadLawFirmUser();
      }

      setLoadingForm(false);
    };
    (mounted) && init();
    return () => mounted = false
  }, [user_id]);

  return (<div>
    <Alert
      dismissible
      onClose={() => setShowAlert(false)}
      show={showAlert}
      variant={modalAlert.alert_type}
    >{modalAlert.message}</Alert>

    <Form noValidate autoComplete="off">
      <p>To add a new firm user, please complete the form below and ensure that all required fields are populated.</p>
      <Row>
        <Col>
          <Form.Label >Law Firm</Form.Label>
          <Form.Select
            name="lawFirm"
            className={previousFormValues.lawFirm !== formState.lawFirm && 'changed-field'}
            disabled={!!formState.lawFirm}
            value={formState.lawFirm}
            onChange={handleInputChange}
            aria-label="Law Firm">
            <option value="">Select Law Firm</option>
            {lawFirms.length > 0 && lawFirms.map(({ org_id, prefix, organization = '' } = {}) => (
              <option key={`lawFirms-${org_id}`} value={org_id}>
                {`${organization} (${org_id}-${prefix})`}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label className="mt-3">First Name</Form.Label>
          <Form.Control
            className={previousFormValues.firstName !== formState.firstName && 'changed-field'}
            disabled={!editableForm}
            type="text"
            placeholder=""
            name="firstName"
            onChange={handleInputChange}
            value={formState.firstName}
            autoComplete="off"
            required
          />
        </Col>
        <Col>
          <Form.Label className="mt-3">Last Name</Form.Label>
          <Form.Control
            className={previousFormValues.lastName !== formState.lastName && 'changed-field'}
            disabled={!editableForm}
            type="text"
            placeholder=""
            name="lastName"
            onChange={handleInputChange}
            value={formState.lastName}
            autoComplete="off"
            required
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label >Status</Form.Label>
          <Form.Select
            className={previousFormValues.status !== formState.status && 'changed-field'}
            disabled={user_id === session_user_id || !editableForm}
            aria-label="Status"
            name="status"
            value={formState.status}
            onChange={handleInputChange}>
            <option value="">Select Status</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </Form.Select>
        </Col>
        <Col>
          <Form.Label >Role</Form.Label>
          <Form.Select
            name="role"
            className={previousFormValues.status !== formState.status && 'changed-field'}
            disabled={user_id === session_user_id || !editableForm}
            onChange={handleInputChange}
            value={formState.role}
            aria-label="Role">
            <option value="">Select Role</option>
            {availableRoles.length > 0 && availableRoles.filter(({ group_id = 6 }) => (!superAdminAccess && user_id !== session_user_id) ? Number(group_id) > default_role : true).map(({ group_id, group_name } = {}) => (
              <option key={`roles-${group_id}`} value={group_id} >
                {group_name && group_id ? `${group_name} (${group_id})` : group_id}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label className="mt-3">Email</Form.Label>
          <Form.Control
            className={previousFormValues.email !== formState.email && 'changed-field'}
            disabled={!editableForm}
            type="text"
            placeholder=""
            name="email"
            onChange={handleInputChange}
            value={formState.email}
            autoComplete="off"
            required
          />
        </Col>
        <Col>
          <Form.Label className="mt-3">Phone Number</Form.Label>
          <Form.Control
            className={previousFormValues.phone_number !== formState.phone_number && 'changed-field'}
            disabled={!editableForm}
            type="text"
            placeholder=""
            name="phone_number"
            onChange={handleInputChange}
            value={formState.phone_number}
            autoComplete="off"
            required
          />
        </Col>
      </Row>
    </Form>

    <Row>
      {editableForm ? (<Col className="mt-3 d-flex justify-content-end">
        {JSON.stringify(previousFormValues) !== JSON.stringify(formState) && (<Button className="btn btn-primary me-3" onClick={updateLawFirmUser} {...(loadingForm || !validated) && { disabled: true }}>Save Changes</Button>)}
        <Button className="btn btn-secondary" onClick={cancelupdateLawFirmUser} {...(loadingForm) && { disabled: true }}>Cancel Edit</Button>
      </Col>) : (<Col className="mt-3 d-flex justify-content-end"><Button className="btn btn-primary me-3" onClick={editInfo}  {...(loadingForm) && { disabled: true }} >Edit</Button><ChangePasswordModal user_id={user_id} /></Col>)}
    </Row>
  </div>);
}

export { LawFirmUserForm };
export default LawFirmUserForm;