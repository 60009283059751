import React, { useState, useEffect } from 'react';
import { useUserData } from '../../contexts/AuthContext';
import { Form, Button, Alert, Col } from 'react-bootstrap';
import { StyledGreenCheckbox, StyledFormInput } from "../../styled/Forms";
import { formatUserSettings, validParams, humanize } from "../../utils/";
import { admin } from "../../utils/EREDocs"

const DefaultPDFSettingsForm = ({ user_id, closeModal } = {}) => {
  const { userData, setUserData } = useUserData();
  let { passport: { user: { user_id: default_user_id, user_settings = {} } = {} } = {} } = userData || {};
  (typeof default_user_id !== 'number') && (default_user_id = Number(default_user_id));
  (!user_id && default_user_id) && (user_id = default_user_id);
  (typeof user_id !== 'number') && (user_id = Number(user_id));
  let BooleanSettings = ["PDF_INCLUDE_TOC", "PDF_INCLUDE_TITLES", "PDF_INCLUDE_BACKTOTOP_LINK", "PDF_OCR_PROCESSING", "PDF_COMBINE_PDF"];
  let pdfFieldSettings = [...BooleanSettings, "PDF_SETTINGS_AUTHOR", "PDF_SETTINGS_CREATOR", "PDF_SETTINGS_PRODUCER"];

  const [loadingForm, setLoadingForm] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [modalAlert, setModalAlert] = useState({ message: "", alert_type: "info" });
  const [editableForm, setEditableForm] = useState(!user_id);
  const [previousFormValues, setPreviousFormValues] = useState({});
  const [formState, setFormState] = useState((user_id === default_user_id && Object.keys(user_settings).length > 0) ? user_settings : {
    PDF_INCLUDE_TOC: true,
    PDF_INCLUDE_TITLES: true,
    PDF_INCLUDE_BACKTOTOP_LINK: true,
    PDF_OCR_PROCESSING: true,
    PDF_COMBINE_PDF: true,
    PDF_SETTINGS_AUTHOR: "",
    PDF_SETTINGS_CREATOR: "",
    PDF_SETTINGS_PRODUCER: ""
  });

  const handleInputChange = (event) => {
    if (!event?.target) return;
    event.stopPropagation(); // Prevent other events from firing on click

    let { name, value, type, checked = false } = event.target;
    if (type === 'checkbox') {
      value = checked;
    } else {
      event.preventDefault();
    }
    setFormState((prevState) => ({ ...prevState, [name]: value }));

    // Validate Form on change 
    let alertObject = { ...modalAlert };
    let requiredFields = [...pdfFieldSettings];
    let testParams = Object.entries({ ...formState, [name]: value }).filter(([k, v]) => type === 'checkbox' || v).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
    let valid = validParams(testParams, requiredFields, 'any');
    setValidated(valid);
    setShowAlert(!valid);
    if (!valid) {
      alertObject.alert_type = "info";
      alertObject.message = "Please fill out all required fields. Missing " + requiredFields.filter((f) => !testParams[f]).join(', ');
    }
    if (alertObject.message) {
      alertObject.message = alertObject.message.trim();
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }
    return;
  };

  const getPdfSettings = async (userID = user_id) => {
    let userSettings = { ...formState };
    let inList = [...pdfFieldSettings];
    let pdfSettings = Object.fromEntries(Object.entries(formatUserSettings(userSettings)).filter(([k, v]) => inList.includes(k)));
    let pdfSettingsTrueCount = Object.values(pdfSettings).filter(Boolean).length;
    if (pdfSettingsTrueCount === 0 || userID !== default_user_id) { // At least 1 of the pdf settings should be true or we'll check getSettings
      let params = { ...(userID) && { user_id: userID } };
      pdfSettings = await admin.getSettings(params).then(({ status = 500, statusText = "Internal Server Error", data = {} }) => {
        (status !== 200) && console.log(`${status} ${statusText}`);
        return data;
      });
    }

    setFormState((prevState) => ({ ...prevState, ...pdfSettings }));
    setPreviousFormValues(pdfSettings);
    if (userID === default_user_id) {
      setUserData((prev) => ({
        ...prev, ...pdfSettings,
        passport: {
          ...prev.passport,
          user: {
            ...prev.passport.user,
            user_settings: { ...prev.passport.user.user_settings, ...pdfSettings }
          }
        }
      }));
    }
    return;
  };

  const onSubmit = async (event) => {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation();

    let alertObject = { ...modalAlert };
    let requiredFields = [...pdfFieldSettings];
    let settings = Object.entries(formState).filter(([k, v]) => requiredFields.includes(k)).map(([key, value]) => ({
      key,
      ...(BooleanSettings.includes(key) && typeof value !== 'boolean' ?
        { value: value === "true" } : { value })
    }));
    let saveParams = {
      user_id,
      settings,
    };

    let response;
    try {
      if (!validated) {
        throw new Error("Your form input is not valid. Please check your input and try again.");
      }

      setLoadingForm(true);
      response = await admin.saveUserSettings(saveParams);
      alertObject.message = "Saved Successfully";
    } catch (ex) {
      console.log(ex);
      response = ex?.response || {};
      response.status = 500;
      response.statusText = `${ex.message}`;
      if (!response?.data) {
        response.data = { err: 401, details: `${ex.message}`, error: ex.error };
      }
    } finally {
      setLoadingForm(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    let { err, error, details, message = '', createdSettings = [] } = data;
    alertObject.alert_type = status !== 200 ? "danger" : "success";
    if (status !== 200) {
      alertObject.message = `[${status ?? err}] ${statusText} ${alertObject.message}`;
      alertObject.message += data?.status ? `${data.status} ` : ""; // ERROR 
      alertObject.message += error ? `${error} ` : ""; // ScrapeError Message + Controller Error 
      alertObject.message += (typeof response?.data === 'string') ? response.data : message ?? (details ? `${details} ` : "") + "Invalid submission.";
    }

    let newState = { ...formState };
    if (Array.isArray(createdSettings) && createdSettings.length > 0) {
      newState = {
        ...newState, ...createdSettings.reduce((acc, { key, value }) => ({
          ...acc,
          [key]: (BooleanSettings.includes(key) && typeof value !== 'boolean') ? value === true : value
        }), {})
      };
      setFormState(prevState => ({ ...prevState, ...newState }));
    }

    // Update previous with new values
    setEditableForm(false);
    setPreviousFormValues(newState);
    if (user_id === default_user_id) {
      setUserData((prev) => ({
        ...prev, ...newState,
        passport: {
          ...prev.passport,
          user: {
            ...prev.passport.user,
            user_settings: { ...prev.passport.user.user_settings, ...newState }
          }
        }
      }));
    }

    // Show Alert success or danger
    setShowAlert(!!alertObject?.message);
    if (!!alertObject?.message) {
      alertObject.message = alertObject.message.trim();
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }
    return;
  };

  const editInfo = () => {
    setShowAlert(false);
    setEditableForm(true);
  }
  const onReset = (event) => {
    if (!event?.target) return;
    event.stopPropagation(); // Prevent other events from firing on click
    event.preventDefault();
    setEditableForm(false);
    setFormState(previousFormValues);
    (closeModal && typeof closeModal === 'function') && closeModal();
  };

  useEffect(() => {
    let mounted = true;
    const init = async () => {
      setLoadingForm(true);
      await getPdfSettings(user_id);
      setLoadingForm(false);
    }
    (mounted) && init();
    return () => mounted = false;
  }, [user_id]);

  return (
    <div>
      <h4>Default PDF Settings</h4>
      <hr />
      <Alert
        dismissible
        onClose={() => setShowAlert(false)}
        show={showAlert}
        variant={modalAlert.alert_type}
      >{modalAlert.message}</Alert>

      <Form noValidate autoComplete="off" onSubmit={onSubmit} onReset={onReset} className="row">
        <Col>
          {Object.entries(formState).filter(([k, v]) => pdfFieldSettings.includes(k) && BooleanSettings.includes(k)).map(([Key, Value], index) => (
            <Form.Group key={`${index}-${Key}`} controlId={Key}>
              <StyledGreenCheckbox>
                <Form.Control
                  className={previousFormValues[Key] !== Value && 'changed-field'}
                  disabled={!editableForm}
                  type="checkbox"
                  name={Key}
                  onChange={handleInputChange}
                  checked={Value === true || Value === "true" ? true : false}
                />
                <span className="ms-2">{humanize(Key)}</span>
              </StyledGreenCheckbox>
            </Form.Group>
          ))}
        </Col>

        <Col>
          {Object.entries(formState).filter(([k, v]) => pdfFieldSettings.includes(k) && !BooleanSettings.includes(k)).map(([Key, Value], index) => (
            <Form.Group key={`${index}-${Key}`} controlId={Key}>
              <Form.Label className="mt-3 me-2">{humanize(Key)}</Form.Label>
              <StyledFormInput
                className={previousFormValues[Key] !== Value && 'changed-field'}
                disabled={!editableForm}
                type="text"
                name={Key}
                maxLength="40"
                onChange={handleInputChange}
                value={Value}
              />
            </Form.Group>
          ))}
        </Col>

        {editableForm ? (<Col className="col-12 mt-3 d-flex justify-content-end">
          {JSON.stringify(previousFormValues) !== JSON.stringify(formState) && (<Button
            className="btn btn-primary me-3"
            type="submit"
            disabled={loadingForm || !validated}
          >
            Save Changes
          </Button>)}
          <Button
            className="btn btn-secondary"
            type="reset"
            disabled={loadingForm}
          >
            Cancel Edit
          </Button>
        </Col>) : (<Col className="col-12 mt-3 d-flex justify-content-end">
          <Button
            className="btn btn-primary me-3"
            type="button"
            onClick={editInfo}
            disabled={loadingForm}
          >
            Edit
          </Button>
        </Col>)}
      </Form>
    </div>
  );
}

export { DefaultPDFSettingsForm };
export default DefaultPDFSettingsForm;