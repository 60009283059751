import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { StyledButton } from "../../styled/Misc";
import SupportForm from '../Forms/SupportForm';

const SupportModal = ({ title = "?", ...props } = {}) => {
  const [modalState, setModalState] = useState(false);
  const openModal = () => (setModalState(true));
  const closeModal = () => (setModalState(false));

  return (
    <div {...props} >
      <StyledButton
        className="primary-button"
        onClick={() => openModal()}
        title={title}
      >
        <span>{title}</span>
      </StyledButton>
      <Modal
        show={modalState}
        onHide={closeModal}
        animation={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Support
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SupportForm />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export { SupportModal };
export default SupportModal;