import React, { useState, useEffect } from 'react';
import swal from "sweetalert";
import { Table, Row, Col, Alert, Button, Dropdown } from 'react-bootstrap';
import { StyledDropDown } from "../../styled/Dropdown";
import { StyledSpan } from "../../styled/Span";
import Loader from "../Loader";
import EditBrqNextRunModal from '../Modals/EditBrqNextRunModal';
import { bso } from "../../utils/EREDocs";
import { humanize, formatDate } from "../../utils/index";
const STATUS_REPORT_KEYMAP = {
  "DOWNLOAD_HSR_ASR": "Hearing Status Report (HSR) and Appeals Status Report (ASR)",
  "DOWNLOAD_IRSR": "Initial Request Status Report (IRSR)",
};

const BSORequestQueueSettings = ({ auth_id, defaultSearchParams = {}, requiredSearchParams = {}, displayedColumns = [], renamedColumns = {}, ...props } = {}) => {
  const [loadingForm, setLoadingForm] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [modalAlert, setModalAlert] = useState({ message: "", alert_type: "warning" });
  const [record, setRecord] = useState({});
  const [parentState, setParentState] = useState(false);
  const [searchParams, setSearchParams] = useState({ ...defaultSearchParams, ...requiredSearchParams });
  const [lastQuery, setLastQuery] = useState({ ...searchParams });
  const [rows, setRows] = useState([]);
  const [isEditing, setIsEditing] = useState(true);

  let HeaderColumns = [];
  displayedColumns.forEach(column => {
    let columnName = renamedColumns[column] || column;
    HeaderColumns.push(columnName);
  });

  const searchFn = async (params = {}) => {
    setLoadingForm(true);
    let alertObject = { alert_type: "info", message: "Executed Search" };
    let response;
    try {
      response = await bso.getBsoRequestQueueRecords(params);
      alertObject.message = response?.statusMessage || "Success";
      setLastQuery(params);
    } catch (ex) {
      response = { statusCode: 500, statusMessage: "Internal Server Error", error: ex?.message ? ex.message : ex };
      alertObject.message = response?.statusMessage || ex?.message ? ex.message : "Error";
    } finally {
      setLoadingForm(false);
    }
    let { statusCode = 500, statusMessage = '', data = {} } = response;
    let { count, rows } = data?.data || data || {};
    setRows(rows);
    if (statusCode === 200) {
      alertObject.alert_type = "success";
      alertObject.message = statusMessage;
    } else {
      alertObject.alert_type = "danger";
      alertObject.message = statusMessage;
    }

    // Show Alert success or danger
    if (alertObject?.message) {
      alertObject.message = alertObject.message.trim();
      setShowAlert(true);
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }
    return;

  };

  // const newSearch = async (_params) => {
  //   await searchFn(searchParams);
  // }

  // const refreshLastQuery = async () => {
  //   await searchFn(lastQuery);
  // }

  // const onClickSearch = async (event) => {
  //   if (event) {
  //     event.preventDefault();
  //   }
  //   let newSearchParams = { user_id };
  //   setSearchParams({ ...newSearchParams, ...requiredSearchParams });
  //   newSearch(newSearchParams);
  // }

  // const onClickClearSearch = async (event) => {
  //   if (event) {
  //     event.preventDefault();
  //   }
  //   await searchFn({ ...defaultSearchParams, ...requiredSearchParams });
  // }

  /**
  * editEntry - Edit additional details of the BRQ Item
  * @param {*} event 
  * @param {*} bso_request_queue_id 
  * @param {*} values 
  */
  const editEntry = async (event, row) => {
    if (event) {
      event.preventDefault();
    }
    setRecord(row);
    setLoadingForm(true);
    setParentState(true);
    setLoadingForm(false);
    await searchFn(lastQuery);
  }

  const reportChange = async (value, row) => {
    if (value && row && row?.bso_request_queue_id) {
      let condition = { bso_request_queue_id: row.bso_request_queue_id };
      let values = { next_run: value };
      let response
      try {
        response = await bso.upsertBsoRequestQueueRecord(condition, { condition, values });
      } catch (ex) {
        console.log("Error updating record.", ex);
      }
      await searchFn(lastQuery);
    }
  }; // END reportChange

  /**
   * updateEntry - Set the entry to PAUSED or REQUESTED
   * @param {*} event 
   * @param {*} bso_request_queue_id 
   * @param {*} values 
   */
  const updateEntry = async (event, bso_request_queue_id, values) => {
    if (event) {
      event.preventDefault();
    }
    setLoadingForm(true);
    let response;
    try {
      let condition = { bso_request_queue_id };
      response = await bso.upsertBsoRequestQueueRecord(condition, { condition, values });
    } catch (ex) {
      console.log("Error updating entry", ex);
    } finally {
      setLoadingForm(false);
    }
    await searchFn(lastQuery);
  }; // END updateEntry

  /**
   * deleteEntry - Delete BSO Request Queue Record using bso_request_queue_id passed in row
   * @param {*} event 
   * @param {*} bso_request_queue_id 
   * @returns 
   */
  const deleteEntry = async (event, bso_request_queue_id) => {
    if (event) {
      event.preventDefault();
    }
    let choiceToProceed = await swal({
      title: "BSO Request Queue Record",
      text: "Are you sure you want to delete this entry ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(ok => {
      if (ok) {
        return true;
      } else {
        return false;
      }
    });

    if (!choiceToProceed) {
      swal("The record will not be deleted.");
      return;
    }

    try {
      setLoadingForm(true);
      await bso.deleteBsoRequestQueueRecord({ bso_request_queue_id })
    } catch (ex) {
      console.log("Error deleting entry", ex);
      swal("The record could not be deleted. " + ex?.message || ex);
    } finally {
      setLoadingForm(false);
    }
    await searchFn(lastQuery);
  }; // END deleteEntry

  const onClickAddBrqEntry = async (event) => {
    if (event) {
      event.preventDefault();
    }

    let { target = {} } = event || {};
    let { innerText } = target || {};
    let reportType = 'DOWNLOAD_HSR_ASR';
    if (`${innerText}`.includes("IRSR")) {
      reportType = 'DOWNLOAD_IRSR';
    }
    if (!Object.keys(STATUS_REPORT_KEYMAP).includes(reportType)) { // Check if reportType is valid ['DOWNLOAD_HSR_ASR', 'DOWNLOAD_IRSR']
      reportType = 'DOWNLOAD_HSR_ASR';
    }

    let values = {
      auth_id,
      type: reportType,
      action: reportType,
      status: "PAUSED",
      next_run: Date.now()
    }

    try {
      setLoadingForm(true);
      let response = await bso.createBsoRequestQueueRecord({ values });
    } catch (ex) {
      console.log("Error updating entry", ex);
    } finally {
      setLoadingForm(false);
    }
    await searchFn(lastQuery);
  }; // END onClickAddBrqEntry

  useEffect(() => {
    let mounted = true;
    if (mounted && auth_id) {
      searchFn(searchParams);
    }
    return () => mounted = false;
  }, [auth_id]);

  return (
    <div {...props}>
      <Alert
        dismissible
        onClose={() => setShowAlert(false)}
        show={showAlert}
        variant={modalAlert.alert_type}
      >{modalAlert.message}</Alert>

      <Row>{loadingForm ? "loading..." : (<Col>
        {loadingForm ? (<Loader className="text-info" style={null} />) : (<Table hover>
          <thead>
            <tr>
              {HeaderColumns.map((key) => (
                <td key={`Header-${key}`}>
                  {humanize(key)}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.isArray(rows) && rows.length ? rows.map((row = {}, index) => (
              <tr key={`BRQ-TABLE-${index}-${row?.bso_request_queue_id || Math.random()}`} >
                {Object.entries(row).filter(([k, v]) => displayedColumns.includes(k)).map(([key, value], idx) => (
                  <td key={`${index}-${key}`} data-col={idx} data-field={key}>
                    {key === "next_run" ? formatDate(value, "MMMM D, YYYY hh:mm:ss A z Z") :
                      key === "type" && STATUS_REPORT_KEYMAP[value] ? STATUS_REPORT_KEYMAP[value] :
                        key === "status" ? <StyledSpan data-value={value}>{humanize(value)}</StyledSpan> : value}
                  </td>
                ))}
                <td>
                  <StyledDropDown className="dropstart" align="start" drop="start">
                    <Dropdown.Toggle></Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={(e) => { updateEntry(e, row?.bso_request_queue_id || null, { status: 'REQUESTED' }) }}>Set Requested</Dropdown.Item>
                      <Dropdown.Item onClick={(e) => { updateEntry(e, row?.bso_request_queue_id || null, { status: 'PAUSED' }) }}>Set Paused</Dropdown.Item>
                      <Dropdown.Item onClick={(e) => { editEntry(e, row) }}>Set Next Schedule</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item onClick={(e) => { deleteEntry(e, row?.bso_request_queue_id || null) }}><span className="text-danger">DELETE</span></Dropdown.Item>
                    </Dropdown.Menu>
                  </StyledDropDown>
                </td>
              </tr>)) : (
              <tr>
                <td colSpan={displayedColumns.length}>
                  <strong>No records found.</strong>
                </td>
              </tr>
            )}
          </tbody>
        </Table>)}
        <Button onClick={onClickAddBrqEntry}>+ Add HSR/ASR Download Schedule</Button>
        <Button onClick={onClickAddBrqEntry} className="ms-3">+ Add IRSR Download Schedule</Button>
      </Col>)}</Row>
      <EditBrqNextRunModal reportChange={reportChange} record={record} parentState={parentState} setParentState={setParentState} />
    </div>
  );
};

export default BSORequestQueueSettings;