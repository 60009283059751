import React from 'react';
import { Row, Col } from 'react-bootstrap';

const SupportForm = () => {
  return (<div className="container">
    <Row>
      <Col>
        <h1>Support</h1>
      </Col>
    </Row>
    <hr />
    <Row className={"pb-3"}>
      <Col>
        <span><strong>Email us at <a href={"mailto:support@eredocs.com?subject=Contact Us For Support."}>support@eredocs.com</a></strong></span>
      </Col>
    </Row>
    <Row>
      <Col>
        <p>Office Hours:</p>
      </Col>
    </Row>
    <Row>
      <Col>
        <ul>
          <li>Sunday: Closed</li>
          <li>Monday: 7:00 AM - 6:00 PM MST</li>
          <li>Tuesday: 7:00 AM - 6:00 PM MST</li>
          <li>Wednesday: 7:00 AM - 6:00 PM MST</li>
          <li>Thursday: 7:00 AM - 6:00 PM MST</li>
          <li>Friday: 7:00 AM - 6:00 PM MST</li>
          <li>Saturday: Closed</li>
        </ul>
      </Col>
    </Row>
  </div>);
}

export { SupportForm };
export default SupportForm;