import React, { useState, useRef } from 'react';
import { Alert, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useUserData } from "../contexts/AuthContext";
import { admin } from "../utils/EREDocs";
import { humanize } from "../utils/";
import ClaimantUploadRequests from "../components/Tables/ClaimantUploadRequests";
let refresh_interval_seconds = Number(process.env.REACT_APP_SECONDS_REFRESH_AUDIT_LOGS || '30');
let seconds_between_server_checks = Number(process.env.REACT_APP_SECONDS_BETWEEN_SERVER_CHECKS || '60');

const UploadManagement = (props = {}) => {
  const { userData } = useUserData();
  let { passport: { user: { group_id = 6 } = {} } = {}, isAuthenticated = false } = userData || {};
  let superAdminAccess = (isAuthenticated && Number(group_id) === 1);
  const uploadManagementRef = useRef();
  const [showAlert, setShowAlert] = useState(false);
  const [formAlert, setFormAlert] = useState({ message: "", alert_type: "info" });
  const [loadingState, setLoadingState] = useState(false);
  const [uploaderServerStatus, setUploaderServerStatus] = useState({
    alias: process.env.REACT_APP_UPLOADER_SERVER_ALIAS || "UPLOADER_SERVER",
    status: "unknown",
    last_checked: Date.now(),
  });

  const getServerStatus = async ({ checkLiveStatus = false } = {}) => {
    let seconds_from_last_checked = Math.round((Date.now() - uploaderServerStatus.last_checked) / 1000);

    if (checkLiveStatus) {
      if (seconds_from_last_checked < seconds_between_server_checks && !['running', 'stopped', 'blacklisted'].includes(uploaderServerStatus?.status)) {
        await Swal.fire({
          icon: 'info',
          title: "Too Soon!",
          text: `Please wait ${seconds_between_server_checks - seconds_from_last_checked} seconds before checking again.`,
          showCancelButton: true,
        });
        return;
      }

      let rebootSteps = ['running', 'stopping', 'stopped', 'pending', 'running'];
      let nextStep = (uploaderServerStatus?.status === 'blacklisted') ? 'stopping' : rebootSteps[rebootSteps.indexOf(uploaderServerStatus?.status) + 1] || 'running';
      let title = `Are you sure you want to set ${humanize((uploaderServerStatus.alias || '').toUpperCase().replace('_SERVER', ''))} from ${uploaderServerStatus?.status} to ${nextStep}?`;
      let confirmButtonText = `Yes, set  ${uploaderServerStatus.alias || 'server'} to ${nextStep}!`;
      let confirmed = await Swal.fire({
        title,
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText,
      }).then((result) => (result.isConfirmed));
      if (!confirmed) {
        return;
      }
    }

    let alertObj = { ...formAlert };
    let serverStatus = "unknown";
    setLoadingState(true);
    let { status, statusText, data } = await admin.getServerStatus({ server_alias: uploaderServerStatus.alias, dryRun: !Boolean(checkLiveStatus) }).catch((error) => {
      console.log("Error on admin.getServerStatus", error);
    }) || {};
    setLoadingState(false);

    if (status > 399) {
      console.log("Error on admin.getServerStatus", { status, statusText, data });
      alertObj.alert_type = "danger";
      alertObj.message = `Error on admin.getServerStatus ${statusText}`;
    } else {
      alertObj.alert_type = "info";
      alertObj.message = "";
    }

    let { instance_state = [], status_details, status: _status } = data?.results || data || {};
    if (instance_state.length > 0) {
      serverStatus = instance_state[0]?.state || "unknown";
    }
    setUploaderServerStatus(prevState => ({ ...prevState, status: serverStatus, ...(checkLiveStatus && { last_checked: Date.now() }) }));

    if (checkLiveStatus) {
      alertObj.message = status_details || _status || statusText || "";
    }
    if (!!alertObj.message) {
      setShowAlert(!!alertObj.message);
      setFormAlert(prevState => ({ ...prevState, ...alertObj }));
      await Swal.fire({
        icon: alertObj.alert_type === 'danger' ? 'error' : 'success',
        title: "Done!",
        text: alertObj.message,
        showConfirmButton: true,
        background: false,
        backdrop: false
      });
    }
  };

  const handleServerReboot = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    let seconds_from_last_checked = Math.round((Date.now() - uploaderServerStatus.last_checked) / 1000);
    if (seconds_from_last_checked < seconds_between_server_checks && !['running', 'stopped', 'blacklisted'].includes(uploaderServerStatus?.status)) {
      await Swal.fire({
        icon: 'info',
        title: "Too Soon!",
        text: `Please wait ${seconds_between_server_checks - seconds_from_last_checked} seconds before checking again.`,
        showCancelButton: true,
      });
      return;
    }

    let action = ['running', 'stopped', 'blacklisted'].includes(uploaderServerStatus.status || 'unknown') ? 'toggle_reboot' : 'check_status';
    if (action === "check_status") {
      return getServerStatus({ checkLiveStatus: true });
    }

    let rebootSteps = ['running', 'stopping', 'stopped', 'pending', 'running'];
    let nextStep = (uploaderServerStatus?.status === 'blacklisted') ? 'stopping' : rebootSteps[rebootSteps.indexOf(uploaderServerStatus?.status) + 1] || 'running';
    let title = `Are you sure you want to set ${humanize((uploaderServerStatus.alias || '').toUpperCase().replace('_SERVER', ''))} from ${uploaderServerStatus?.status} to ${nextStep}?`;
    let confirmButtonText = `Yes, set  ${uploaderServerStatus.alias || 'server'} to ${nextStep}!`;
    let confirmed = await Swal.fire({
      title,
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText,
    }).then((result) => (result.isConfirmed));
    if (!confirmed) {
      return;
    }

    let alertObj = { ...formAlert };
    let serverStatus = "unknown";
    setLoadingState(true);
    let { status, statusText, data } = await admin.toggleReboot({ server_alias: uploaderServerStatus.alias }).catch((error) => {
      console.log("Error on admin.handleServerReboot", error);
    }) || {};
    setLoadingState(false);

    if (status > 399) {
      console.log("Error on admin.handleServerReboot", { status, statusText, data });
      alertObj.alert_type = "danger";
      alertObj.message = `Error on admin.getServerStatus ${statusText}`;
    } else {
      alertObj.alert_type = "info";
    }

    let { instance_state = [], status_details, status: _status } = data?.results || data || {};
    alertObj.message = status_details || _status || statusText || "";
    if (instance_state.length > 0) {
      serverStatus = instance_state[0]?.state || "unknown";
    }
    setUploaderServerStatus(prevState => ({ ...prevState, status: serverStatus, last_checked: Date.now() }));
    if (!!alertObj.message) {
      setShowAlert(!!alertObj.message);
      setFormAlert(prevState => ({ ...prevState, ...alertObj }));
      await Swal.fire({
        icon: alertObj.alert_type === 'danger' ? 'error' : 'success',
        title: "Done!",
        text: alertObj.message,
        showConfirmButton: true,
        background: false,
        backdrop: false
      });
    }
  };

  const initFn = async () => {
    if (superAdminAccess) {
      await getServerStatus();
    }
  };

  return (
    <div className="page-container" ref={uploadManagementRef} {...props}>
      {superAdminAccess ? <div className="d-flex justify-content-between">
        <h4 className="h-inline">Upload Management</h4>
        <Button
          type="submit"
          size="md"
          variant={'running' === uploaderServerStatus.status ? "success"
            : (['stopped', 'terminated', 'blacklisted'].includes(uploaderServerStatus.status) ? "danger"
              : ['pending', 'unknown'].includes(uploaderServerStatus.status) ? "info" : "warning")}
          onClick={handleServerReboot}
          {...(loadingState) && { disabled: true }}
        >{`${humanize((uploaderServerStatus.alias || '').toUpperCase().replace('_SERVER', ''))} is ${uploaderServerStatus.status} | `}
          <strong>{['running', 'blacklisted'].includes(uploaderServerStatus.status) ? 'Stop' : 'stopped' === uploaderServerStatus.status ? 'Start' : 'Check Status'}</strong>
        </Button>
      </div> : <h4>Upload Management</h4>}
      <hr />
      <Alert
        dismissible
        onClose={() => setShowAlert(false)}
        show={showAlert}
        variant={formAlert.alert_type}
      >{formAlert.message}</Alert>

      <ClaimantUploadRequests
        restricted={false}
        limit={50}
        showActions={true}
        showFilter={true}
        showDebug={false}
        showClaimantLinks={true}
        showParentAlert={setShowAlert}
        setParentAlert={setFormAlert}
        parentInit={initFn}
        parentRef={uploadManagementRef}
        refresh_interval_seconds={refresh_interval_seconds}
        skipFields={[
          'document_date', // defaultFormInputs
          'treatment_source', 'from_date', 'to_date', // extraFormInputs
          'claimant_id', 'auth_id', 'document_type_id', 'total_file_size', 'status_details', 'firm', 'site_code', 'rqid', 'rf', 'dr', 'qid', 'doc_count', 'brq_ran_count', 'brq_priority', 'matter_id', 'can_process_requests', 'last_captured'
        ]}
        tableFields={['attorney', 'id', 'status', 'brq_id', 'brq_status', 'tracking_number', 'last_status_check', 'next_run', 'Requested At', 'Delivered At', 'Duration', 'upload_method', 'claimant', 'document_type', 'status_of_case', 'requested_by']}
        renamedFields={{
          upload_request_id: 'id',
          bso_request_queue_id: 'brq_id',
          claimant_name: 'claimant',
          submitted_timestamp: 'Requested At',
          successful_sftp_delivery: 'Delivered At',
        }}
        dateFields={['last_status_check', 'next_run', 'Requested At', 'Delivered At']}
        formatStr={"MM/D/YYYY hh:mm:ss A"}
      />
    </div>
  );
}

export default UploadManagement;