import styled from "styled-components";
import { Form, InputGroup } from 'react-bootstrap';

export const StyledInputGroup = styled(InputGroup)`
  & input.form-control, 
  & select.form-control {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: solid 1px #ccc;
    border-radius: 0px !important;
    background-color: transparent !important;
    z-index: 1;
  }
`

export const StyledFormControl = styled(Form.Control)`
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: solid 1px #ccc;
  border-radius: 0px !important;
  background-color: transparent !important;
  z-index: 1;
`

export const StyledFormInput = styled.input`
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: solid 1px #ccc;
  border-radius: 0px;
  background-color: transparent !important;
  z-index: 1; 

  &:disabled,
  &[readonly] {
    background-color: #e9ecef !important; 
    opacity: 1;
  }

  &:active,
  &:focus {
    border-bottom: solid 2px var(--accent-color) !important;
  }
`;

export const StyledFormSelect = styled.select`
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: solid 1px #ccc;
  border-radius: 0px;

  &:active,
  &:focus {
    border-bottom: solid 2px var(--accent-color) !important;
  }
`;

export const StyledFormLabel = styled.label`
  position: absolute;
  top: 0;
  padding: 3px 0 0 13px;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
  opacity: 0.5;
  z-index: 0; 

  ${StyledFormInput}:focus ~ & {
    font-size: 90%;
    padding-left: 3px;
    transform: translate3d(0, -100%, 0);
    opacity: 1;
    color: var(--into-text);
  }
  
  ${StyledFormInput}:valid + & {
    font-size: 90%;
    padding-left: 3px;
    transform: translate3d(0, -100%, 0);
    opacity: 1;
    color: var(--into-text);
  }

  ${StyledFormSelect}:focus ~ & {
    font-size: 90%;
    padding-left: 3px;
    transform: translate3d(0, -100%, 0);
    opacity: 1;
    color: var(--into-text);
  }
  
  ${StyledFormSelect}:valid + & {
    font-size: 90%;
    padding-left: 3px;
    transform: translate3d(0, -100%, 0);
    opacity: 1;
    color: var(--into-text);
  }
`;

export const StyledFormInputGroup = styled.div`
  padding: 0; 

  & svg.svg-inline--fa {
    position: absolute;
    right: 8px;
    top: 6px;
    opacity: 0.5;
  }
`;

export const StyledFormGroup = styled.div` 
  margin: 0 auto !important; 
`;

export const StyledFormRow = styled.div`
  margin: 1.5rem 0 0; 
`;

export const StyledForm = styled.form`
  & ${StyledFormGroup}:last-of-type {
    padding-bottom: 0px; 
  }
`;

export const StyledGreenCheckbox = styled.label` 
  display: inline-flex;
  cursor: pointer;
  position: relative;

  & > .form-control:disabled,
  & > .form-control[readonly] {
    background-color: #e9ecef; 
    opacity: 1;
  }

  & > input[type="checkbox"] {
    padding: 0; 
    height: 20px;
    width: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #c2c2c2 !important;
    border-radius: 5px;
    outline: none;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    background-color: #fff;
    cursor: pointer;
    box-shadow: 0 4px 6px -1px rgba(0,0,0,.1), 0 2px 4px -1px rgba(0,0,0,.06);
  }

  & > input:checked::after {
    content: '\\2713';
    display: block;
    position: absolute;
    text-align: center;
    color: #41B883;
    font-weight: 900; 
    font-size: 20px;
    left: 0.2rem;
    top: -0.3rem;
  }

  & > input:active {
    border: 2px solid #34495E;
  }
}
`;
