import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
:root {
  --primary-color: ${({ theme: { primaryColor = "#144291" } = {} } = {}) => primaryColor}; // dark blue
  --primary-color-darker:${({ theme: { primaryColorDarker = "#0a2d69" } = {} } = {}) => primaryColorDarker}; // darker blue
  --secondary-color: ${({ theme: { secondaryColor = "#144291" } = {} } = {}) => secondaryColor}; // dark blue
  --blue: ${({ theme: { blue = "#144291" } = {} } = {}) => blue};
  --red: ${({ theme: { red = "#E61B1B" } = {} } = {}) => red};
  --dark-red:${({ theme: { darkRed = "#ac1010" } = {} } = {}) => darkRed};
  --green:${({ theme: { green = "#2F9B2F" } = {} } = {}) => green};
  --light-green:${({ theme: { lightGreen = "#5BAF55" } = {} } = {}) => lightGreen};
  --dark-green: ${({ theme: { darkGreen = "#1a6a1a" } = {} } = {}) => darkGreen};
  --primary-text-color: ${({ theme: { primaryTextColor = "#2b2b2b" } = {} } = {}) => primaryTextColor};  // black
  --link-color: ${({ theme: { linkColor = "#144291" } = {} } = {}) => linkColor}; // dark blue
  --sub-link-color: ${({ theme: { subLinkColor = "#2F9B2F" } = {} } = {}) => subLinkColor}; // green
  --light-grey: ${({ theme: { lightGrey = "#ECECEC" } = {} } = {}) => lightGrey};
  --dark-grey: ${({ theme: { darkGrey = "#d1d1d1" } = {} } = {}) => darkGrey};
  --grey: ${({ theme: { grey = "#CDCDCD" } = {} } = {}) => grey};
  --gray-line: ${({ theme: { grayLine = "#c7c7c7" } = {} } = {}) => grayLine};
  --white: ${({ theme: { white = "#ffffff" } = {} } = {}) => white};
  --muted-text: ${({ theme: { mutedText = "#b7b7b7" } = {} } = {}) => mutedText};
  --light-blue: ${({ theme: { lightBlue = "#0dcaf069" } = {} } = {}) => lightBlue};
}

* {
  font-size: 12px;
}

tbody tr {
  cursor: pointer !important;
}

.changed-field {
  background-color: #fffdf2 !important;
  border-color: #f2dab3 !important;
}

.App {
  text-align: left;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--white);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--white);
}

.App-link {
  color: var(--link-color);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  height: 100vh;
}

#root {
  height: 100vh;
  color: var(--default-text-color);
  background-color: var(--white);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--white);
}

a {
  text-decoration: none !important;
  /* color: var(--link-color) !important; */
}

button {
  border: none !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.page-container {
  padding: 25px 25px;
  margin: 0 auto;
  max-width: 2000px;
}

.row {
  margin: 0px !important;
}

.blue-text {
  color: var(--blue);
}

.bold-text {
  font-weight: bold !important;
}

.center-text {
  text-align: center !important;
}

.site-logo {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.navbar-brand {
  cursor: pointer;
  font-weight: bold; 
  margin-right: 3rem !important;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--primary-text-color); 
}

.navbar-light .navbar-nav .nav-link.active, 
.navbar-light .navbar-nav .show>.nav-link {
  color: var(--link-color); 
  font-weight: bold;
}

.navbar-dark .navbar-nav .nav-link {
  color: var(--white); 
}

.navbar-dark .navbar-nav .nav-link.active, 
.navbar-dark .navbar-nav .show>.nav-link {
  color: var(--sub-link-color); 
  font-weight: bold;
}

.header-main-nav {
  margin-left: 30px;
  margin-right: 30px;
  background-color: var(--white) !important;
}

.header-main-nav .bg-light {
  background-color: var(--white) !important;
}

.header-main-nav .nav-link {
  margin-right: 2rem;
}

.card-header, 
.sub-header-nav {
  background-color: var(--primary-color) !important;
  color: var(--white) !important;
}

.sub-header-nav a {
  color: var(--white) !important;
}

.sub-header-nav .nav-link {
  margin-right: 2rem;
}

.sub-header {
  padding-left: 30px;
  padding-right: 30px;
  background-color: var(--primary-color);
}

.login-form {
  max-width: 350px;
  margin: 0 auto;
}

.login-page-right-content {
  background-color: var(--primary-color);
}

.btn-primary, 
.primary-button {
  background-color: var(--primary-color) !important;
}

.btn-primary:hover, 
.primary-button:hover {
  background-color: var(--primary-color-darker) !important;
}

.btn-secondary, 
.secondary-button {
  color: var(--white);
  border-color: var(--primary-color);
}

.btn-danger {
  background-color: var(--red) !important;
}

.btn-danger:hover {
  background-color: var(--dark-red) !important;
}

.btn-danger, 
.remove-button {
  color: var(--red) !important;
  background-color: var(--white) !important;
  border: solid 1px var(--red) !important;
}

.btn-danger:hover, 
.remove-button:hover {
  color: var(--white) !important;
  background-color: var(--dark-red) !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-primary-bold {
  font-weight: bold !important;
  color: var(--primary-color) !important;
}

.text-success {
  color: var(--green) !important;
}

.text-danger {
  color: var(--red) !important;
}

.text-muted {
  color: var(--muted-text) !important;
}

.green-button {
  background-color: var(--green) !important;
}

.green-button:hover {
  background-color: var(--dark-green) !important;
}

.light-green-button {
  background-color: var(--light-green) !important;
}

.light-green-button:hover {
  background-color: var(--green) !important;
}

.simple-button {
  background-color: var(--white) !important;
  color: var(--primary-text-color) !important;
}

.simple-button:hover {
  background-color: var(--dark-grey) !important;
}

.filter-section {
  padding: 0px;
}

.card-hover:hover {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// line 
.step-progress._2Jtxm::before { 
  background: linear-gradient(to left, #e7eaf3 50%, var(--light-green) 50%);
  background-size: 200% 100%;
  background-position: right bottom;
}

.step-progress._2Jtxm._2ZUAI::before, 
.step-progress._2Jtxm._35Ago::before, 
.step-progress._2Jtxm._1CcaK::before {
  background-position: left bottom; 
}

// Bubble 
.step-progress._2Jtxm span._2kL0S, 
.step-progress._2Jtxm span._2JvrO {
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--grey);
  background-color: var(--light-grey);
}

.step-progress._2Jtxm._35Ago span._2kL0S {
  color: var(--white);
  background-color: var(--primary-color) !important;
}

.step-progress._2Jtxm._2ZUAI span._2JvrO {
  color: var(--white);
  background-color: var(--light-green) !important;
}

.step-progress._2Jtxm._2ZUAI span._2JvrO:hover {
  color: var(--white);
  background-color: var(--green) !important;
}

.step-progress._2Jtxm._1CcaK span._2JvrO {
  color: var(--white);
  background-color: var(--dark-red) !important;
}

// label 
.step-progress._2Jtxm div._1hzhf {
  color: var(--grey);
}

.step-progress._2Jtxm._35Ago div._1hzhf {
  color: var(--primary-color) !important;
  font-weight: bold !important;
}

.step-progress._2Jtxm._2ZUAI div._1hzhf {
  color: var(--green) !important;
  font-weight: bold !important;
}

.step-progress._2Jtxm._1CcaK div._1hzhf {
  color: var(--red) !important;
  font-weight: bold !important;
}

.input-group input,
.input-group select {
  border-radius: 5px !important;
}

.simple-menu-button,
.simple-menu-button:hover {
  background: none !important;
  border: none !important;
  color: black !important;
}
.simple-menu-button:after {
  display: none; 
}

.light-blue {
  background-color: var(--light-blue);
}

`;
