import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { StyledButton } from "../../styled/Misc";
import UpdateBSOCredentialsForm from '../Forms/UpdateBSOCredentialsForm';

const UpdateBSOCredentialsModal = ({ auth_id, bsoUser, setInvokeParentUpdate = () => { }, ...props } = {}) => {
  const [modalState, setModalState] = useState(false);
  const openModal = () => (setModalState(true));
  const closeModal = () => (setModalState(false));

  return (
    <div {...props} >
      <StyledButton className="primary-button bold-text" onClick={openModal} title={`auth_id=${auth_id}`} >Set BSO Credentials</StyledButton>
      <Modal show={modalState} onHide={closeModal} animation={false} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Update BSO Credentials</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdateBSOCredentialsForm auth_id={auth_id} bsoUser={bsoUser} closeModal={closeModal} setInvokeParentUpdate={setInvokeParentUpdate} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export { UpdateBSOCredentialsModal };
export default UpdateBSOCredentialsModal;