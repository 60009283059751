import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useUserData } from "../contexts/AuthContext";
import { Form, Col, Row, Button, Table, Alert } from 'react-bootstrap';
import { StyledSpan } from '../styled/Span';
import AddLawFirmModal from '../components/Modals/AddLawFirmModal';
import moment from "moment-timezone";
import Loader from "../components/Loader";
import { admin } from "../utils/EREDocs";
import { massageData, humanize, dehumanize, refreshAllStatusesCache } from "../utils/";
const defaultQueryParams = { limit: 25, showDebug: false };
let IntervalId = null;

const FirmManagement = () => {
  const navigate = useNavigate();
  const { userData } = useUserData();
  let { passport: { user: { org_id, user_id, email: user_email } = {} } = {} } = userData || {};
  (typeof org_id !== 'number') && (org_id = Number(org_id));

  let refresh_interval_seconds = 0
  let skipFields = ['bso_accounts', 'can_process_requests'];
  let dateFields = [];
  let renamedFields = {
    org_id: "ID",
    organization: "Law Firm",
    external_id: "External Firm ID",
    userCount: "User Count",
    bsoAccountCount: "BSO Acct. Count",
  };
  let tableFields = ['ID', 'Law Firm', 'prefix', 'External Firm ID', 'User Count', 'BSO Acct. Count', 'status', 'paused', 'locked'];

  const firmManagementRef = useRef();
  const [invokeUpdate, setInvokeUpdate] = useState(false);
  const [loadingFirms, setLoadingFirms] = useState(false);

  const [Pages, setPages] = useState([1]);
  const [activePage, setActivePage] = useState(1);
  const [tableHeader, setTableHeader] = useState([]);
  const [tableRows, setTableRows] = useState([]);

  const [showAlert, setShowAlert] = useState(false);
  const [formAlert, setFormAlert] = useState({ message: "", alert_type: "info" });
  const [firmStatuses, setFirmStatuses] = useState([]);
  const [filterFormState, setFilterFormState] = useState({
    firmId: '', // Represents the org_id of the firm
    firmName: '',
    externalFirmId: '',
    status: ''
  });

  const handleFormChange = (event) => {
    if (!event?.target) return;
    let { name, value, type, checked = false } = event.target;
    if (type === 'checkbox') {
      value = checked;
    }
    setFilterFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const searchFirms = async (event) => {
    if (event) event.preventDefault() && event.stopPropagation();
    return setInvokeUpdate(prev => !prev);
  };

  const clearFilters = () => {
    setFilterFormState({
      firmId: '',
      firmName: '',
      externalFirmId: '',
      status: ''
    });
    return setInvokeUpdate(prev => !prev);
  };

  // Extracts row items and save to rowObject and selectedItem
  const handleTableRowClick = (event) => {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation(); // Prevent other events from firing on click
    let clickedElement = event?.target || {};
    let { parentElement, dataset, tagName, type, innerText } = clickedElement;
    let action = false;

    // Go up one level in the DOM but preserve innerText
    if (tagName !== "TD" && parentElement?.tagName === "TD") {
      clickedElement = parentElement;
      parentElement = clickedElement.parentElement;
      dataset = { ...dataset, ...clickedElement?.dataset };
      tagName = clickedElement?.tagName;
      if (!innerText && clickedElement?.innerText) {
        innerText = clickedElement.innerText;
      }
    }

    let rowObject = {
      innerText,
      tagName,
      ...(type && { type }),
      ...dataset,
      ...parentElement?.dataset,
    };

    // Extract row items and save to rowObject and selectedItem
    let selectedItem = {};
    if (tagName === "TD") {
      let tdArray = Array.from(parentElement.querySelectorAll("td")).map(({ innerText }) => innerText).filter((i) => i) || [];
      let thArray = Array.from(parentElement.closest("table").querySelectorAll("thead tr th")).map(({ innerText }) => dehumanize(innerText)).filter((i) => i) || [];

      if ('col' in rowObject) { // Extract selectedItem
        let col = Number(rowObject.col);
        if (col && innerText === tdArray[col]) {
          selectedItem[thArray[col]] = tdArray[col];
        }
      }

      // Match thArray length with tdArray length
      if (tdArray.length > thArray.length) {
        // slice extra elements to match thArray length
        tdArray = tdArray.slice(0, thArray.length);
      } else if (tdArray.length < thArray.length) {
        // Fill in the blanks with null values to match thArray length
        let startIndex = tdArray.length;
        tdArray.length = thArray.length;
        tdArray.fill(null, startIndex);
      }

      if (tdArray.length === thArray.length) {
        for (let i = 0; i < thArray.length; i++) {
          rowObject[thArray[i]] = tdArray[i];
        }
      }
    }

    // Extract selectedItem if failed to do so above
    if ('field' in rowObject) {
      let field = dehumanize(rowObject.field); // We must dehumanize this field since that's what happened to all of values in thArray (see above)
      if (!selectedItem[field] && rowObject[field] === innerText) {
        selectedItem[field] = rowObject[field];
      }
    }

    // Object.keys(rowObject).length > 0 && console.log("handleTableRowClick", rowObject);
    // Object.keys(selectedItem).length > 0 && console.log("selectedItem", selectedItem);
    let { id, org_id } = rowObject || {};
    (!id && org_id) && (id = org_id);
    (id) && (action = "navigate");
    if (action) {
      if (["navigate"].includes(action)) {
        return navigate(`/firm-management-details/${id}`);
      } else {
        console.log(`Unknown action: ${action}`);
      }
    }
    return;
  };

  const getTableData = async ({
    startedAt = Date.now(), headerFields = tableFields, renamed_fields = renamedFields, date_fields = dateFields, functionFields = skipFields,
    refresh_in_seconds = refresh_interval_seconds,
  } = {}) => {
    let now = Date.now();
    let duration_seconds = Math.round((now - startedAt) / 1000);
    let ran_count = !refresh_in_seconds ? 1 : Math.round(duration_seconds / refresh_in_seconds) || 1; // Avoid dividing by zero
    let first_run = now === startedAt || ran_count === 1;

    // Detect if user navigated away from this page 
    let offsetWidth = firmManagementRef?.current?.offsetWidth || 0;
    let offsetHeight = firmManagementRef?.current?.offsetHeight || 0;
    let navigated_away = !firmManagementRef?.current || !(offsetWidth > 0 && offsetHeight > 0);

    if (!first_run && (navigated_away || !isAuthenticated)) {
      console.log("Clearing Interval because user navigated away.", { IntervalId, first_run, navigated_away, isAuthenticated, offsetWidth, offsetHeight });
      clearInterval(IntervalId);
      return;
    }

    let alertObj = { ...formAlert };
    let filterValues = Object.entries(filterFormState).filter(([id, value]) => value); // Filter out empty values
    let filteredData = (filterValues.length === 0) ? {} : Object.entries(filterFormState).reduce((acc, [id, value]) => ({ ...acc, ...(value && { [id]: value }) }), {});
    let queryParams = {
      ...defaultQueryParams,
      ...filteredData,
      page: activePage,
      order: "desc",
    };

    if (!queryParams.firmId && org_id) {
      queryParams.org_id = org_id; // Represents the logged in users org_id 
    } else if (user_id || user_email) { // Use the logged in user_id and email to help filter the results
      queryParams = { ...queryParams, ...(user_id && { user_id }), ...(user_email && { user_email }) };
    }

    let response;
    try {
      setLoadingFirms(true);
      if (!queryParams?.org_id && !queryParams?.firmId) {
        throw new Error("No org_id and no firmId found in queryParams");
      }
      response = await admin.getOrgAndChildrenRecords(queryParams);
      alertObj.message = ""; // Reset alert when successful 
    } catch (error) {
      console.log(error);
      response = error?.response || {};
      response.status = 500;
      response.statusText = `${error.message}`;
      if (!response?.data) {
        response.data = { err: 401, error: error.error, details: `${error.message}` };
      }
    } finally {
      setLoadingFirms(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    let { err, error, details, message, rows = [], count = 0, pageCount = 1, page, pages = [] } = data || {};
    if (status === 200) {
      let dataHeader = [...headerFields, ...functionFields];
      let dataRows = (!Array.isArray(rows) || rows.length === 0) ? [] : massageData(rows, dataHeader, renamed_fields, date_fields);
      if (rows.length === 0 || count === 0) {
        if (activePage > 1 && activePage === Pages[Pages.length - 1]) {
          setActivePage(Pages[Pages.length - 2]); // Make sure we don't get stuck loading nothing
        } else {
          alertObj.alert_type = "info";
          alertObj.message = "No firms found.";
        }
      }

      pages = pages.reduce((acc, item, index) => {
        if (index === 0 && item.number !== 1) {
          acc.push(1); // Always include the first page 
        }
        acc.push(item.number);
        if (index === (pages.length - 1) && !acc.includes(pageCount)) {
          acc.push(pageCount); // Always include the last page
        }
        return acc;
      }, []);

      setTableHeader(dataHeader);
      setTableRows(dataRows);
      setPages(pages);
    } else {
      alertObj.alert_type = "danger";
      if (typeof response?.data === 'string') {
        alertObj.message = response.data;
        alertObj.message += ` (${statusText}) `;
      } else {
        alertObj.message = `[${status ?? err}] ${statusText} `;    // API Error Code 
        alertObj.message += data?.status ? `${data.status} ` : ""; // API Status 
        alertObj.message += message ?? (details ? `${details} ` : "") + `Failed to read data.`;
      }
      alertObj.message += error ? `${error} ` : "";              // API Error 
    }

    setShowAlert(!!alertObj.message);
    if (!!alertObj.message) {
      alertObj.message = alertObj.message.trim();
      setFormAlert(prevState => ({ ...prevState, ...alertObj }));
    }
    if (first_run && refresh_in_seconds) {
      if (IntervalId !== null) {
        console.log("Clearing Interval because new interval is being started.", { IntervalId });
        clearInterval(IntervalId);
      }
      let interval_ms = refresh_in_seconds * 1000;
      IntervalId = setInterval(getTableData.bind(null, { startedAt, headerFields, renamed_fields, date_fields, functionFields, refresh_in_seconds }), interval_ms); // Next render every refresh_in_seconds seconds
      console.log("Set Interval!", { IntervalId, activePage, loadingLogs, startedAt });
    }

    // console.log("getTableData", { first_run, IntervalId, ran_count, duration_seconds, now, startedAt, navigated_away, isAuthenticated, offsetWidth, offsetHeight, status, statusText });
    return data;
  }; // END getTableData

  useEffect(() => {
    let mounted = true;
    const init = async () => {
      let allStatuses = refreshAllStatusesCache();
      let _firmStatuses = !Array.isArray(allStatuses) ? [] :
        allStatuses.filter(({ status }) => ['ACTIVE', 'INACTIVE'].includes(status)).map((x) => ({ ...x, status: x.status.toLowerCase() }));

      if (Array.isArray(_firmStatuses) && _firmStatuses.length > 0) {
        let stateDifference = _firmStatuses.filter(x => !firmStatuses.includes(x));
        if (stateDifference.length > 0) {
          setFirmStatuses(_firmStatuses);
        }
      }
      await getTableData();
    }
    if (mounted) init();
    return () => mounted = false;
  }, [invokeUpdate, activePage, org_id]);

  return (
    <div className="page-container" ref={firmManagementRef}>
      <Row>
        <Col>
          <h4 className="h-inline">Law Firm Management</h4>
        </Col>
        <Col>
          <AddLawFirmModal className="d-flex justify-content-end" org_id={0} setInvokeParentUpdate={setInvokeUpdate} />
        </Col>
      </Row>
      <hr />

      <div className="filter-section">
        <h5>Filters</h5>
        <Alert
          dismissible
          onClose={() => setShowAlert(false)}
          show={showAlert}
          variant={formAlert.alert_type}
        >{formAlert.message}</Alert>
        <Form onSubmit={searchFirms} onReset={clearFilters} autoComplete="off" className="row mb-3">
          <Col>
            <Form.Group controlId="id">
              <Form.Label>ID</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                name="firmId"
                value={filterFormState.firmId}
                onChange={handleFormChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="filterFirmName">
              <Form.Label>Firm Name</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                name="firmName"
                value={filterFormState.firmName}
                onChange={handleFormChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="filterExternalFirmId">
              <Form.Label>External Firm ID</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                name="externalFirmId"
                value={filterFormState.externalFirmId}
                onChange={handleFormChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="filterStatus">
              <Form.Label>Status</Form.Label>
              <Form.Select size="sm" name="status" defaultValue={''} onChange={handleFormChange} >
                <option value="">All</option>
                {Array.isArray(firmStatuses) && firmStatuses.length > 0 && firmStatuses.map(({ status_id, status_code, status, label, css_class } = {}, idx) => (
                  <option
                    key={`${status}-${idx}`}
                    value={status}
                    data-status-id={status_id}
                    data-status-code={status_code}
                    {...(css_class && { className: css_class })}
                    {...(filterFormState.status === status) && { selected: true }}
                  >
                    {label ?? status}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col className="d-flex align-items-end">
            <Button className="primary-button" type="submit" name="apply" {...(loadingFirms) && { disabled: true }} >Apply Filters</Button>
            <Button className="remove-button ms-3" type="reset" name="clear" {...(loadingFirms) && { disabled: true }} >Clear Filters</Button>
          </Col>
        </Form>
      </div>

      {loadingFirms ? (<Loader className="text-info" style={null} />) : (<Table hover>
        <thead>
          <tr>{tableHeader.filter((k) => !skipFields.includes(k)).map((header, index, arr) => (
            <th key={`${index}-${header}`}>{(header.includes("_") || header.charAt(header.length - 1) === header.charAt(header.length - 1).toLowerCase()) ? (humanize(header)) : header}</th>))}
          </tr>
        </thead>
        <tbody>{Array.isArray(tableRows) && tableRows.length ? tableRows.map((row = {}, index) => {
          const className = (row?.can_process_requests && row?.status === "active") ? "" : "table-secondary";
          const title = row?.bso_accounts;

          return (<tr
            key={`${index}-${row.ID}`}
            data-row={index}
            data-org_id={row?.ID}
            onClick={handleTableRowClick}
            {...(className) && { className }}
            {...(title) && { title }}
          >{Object.entries(row).filter(([k, v]) => !skipFields.includes(k)).map(([key, value], idx) => (
            <td key={`${index}-${key}`} data-col={idx} data-field={key}>
              {(dateFields.includes(key))
                ? (value || "").replace(moment(Date.now()).format("MM/D/YYYY "), "")
                : (key === "status"
                  ? (<StyledSpan data-value={value}>{humanize(value)}</StyledSpan>)
                  : key === "Law Firm" && row?.ID
                    ? <Link to={`/firm-management-details/${row?.ID}`} onClick={(e) => e.stopPropagation()}>{humanize(value)}</Link>
                    : ['paused', 'locked'].includes(key) && value
                      ? value.split(", ").map((vv, i, a) => {
                        const [attorney_id, attorney_name] = `${vv}`.trim().split(":");
                        return <Link to={`/bso-account-management-details/${attorney_id.trim()}`} onClick={(e) => e.stopPropagation()}>{humanize(attorney_name.trim())}{`${i < a.length - 1 ? ", " : ""}`}</Link>
                      }) : humanize(value, key === "prefix"))}
            </td>
          ))}
          </tr>)
        }) : (<tr><td colSpan={tableHeader.filter((k) => !skipFields.includes(k)).length}><strong>No firms found.</strong></td></tr>)}
        </tbody>
        {Pages.length > 1 && (<tfoot>
          <tr>
            <td className="text-right" colSpan={tableHeader.filter((k) => !skipFields.includes(k)).length + 1} >
              <nav>
                <ul className="pagination justify-content-end" data-active-page={activePage} >
                  {Pages && Pages.length > 0 && Pages.map((pageNumber, index) => (
                    <li key={`page-${pageNumber}-${index}`} className={`page-item ${pageNumber === activePage ? "disabled" : ""}`} >
                      <Link
                        to={`#page-${pageNumber}`}
                        className="page-link"
                        onClick={(e) => {
                          e.preventDefault();
                          setActivePage(pageNumber);
                        }}
                        {...(pageNumber === activePage) && { tabIndex: -1 }}
                      >{pageNumber}</Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </td>
          </tr>
        </tfoot>)}
      </Table>)}
    </div>
  );
}

export default FirmManagement;