import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import PublicHeader from '../components/PublicHeader';
import { useUserData } from "../contexts/AuthContext";

const EmailContact = (email = "support@eredocs.com") => {
  let emailLink = "mailto:" + email;
  return (
    <a href={emailLink}>{email}</a>
  )
}

const TelephoneContact = (phone = "(385) 232-0699") => {
  let phoneLink = "tel:" + phone.replace(/\D/g, '');
  return (
    <a href={phoneLink}>{phone}</a>
  )
}

const PrivacyPolicy = () => {
  const { userData } = useUserData();
  let { isAuthenticated = false } = userData || {};

  useEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <div className="page-container">
      {!isAuthenticated && <PublicHeader />}
      <div className="container">
        <title>Privacy Policy</title>
        <Row>
          <Col>
            <h1 className={"text-danger"}>Privacy Policy</h1>
            <h4>Your Solution to Making Life Simple</h4>
            <p>Effective: 12/15/2020</p>

            <p>
              This Privacy Policy describes how ERE Docs, LLC, (collectively, with all of their subsidiaries and affiliates, “ERE Docs,”, “we” or “our”), treats information about you that ERE Docs, collects and receives through your use of www.EREDocs.com (the “Site”).
            </p>

            <p>
              This Privacy Policy does not apply to any website owned and/or operated by or on behalf of any third party, even if we provide a link to such website on our Site.  When we refer to “you” or “your,” we mean the person accessing this Site. If the person accessing the Site acts on behalf of, or for the purposes of, another person, including a business or other organization, “you” or “your” also means that other person, including a business organization.
            </p>

            <p>
              <strong>1) Acceptance of Privacy Policy. </strong>
            </p>

            <p>
              Each time you access, use, register with or browse the Site, or provide information to ERE Docs, online or offline, you signify your acceptance of ERE Docs’s then-current Privacy Policy. If you do not accept this Privacy Policy, you are not authorized to access, use or browse the Site, or to provide information to ERE Docs, and must discontinue use of the Site immediately. If you provide your information to ERE Docs, offline, the then-current Privacy Policy applies to ERE Docs’s collection and use of your information.  This Privacy Policy is subject to be revised or updated from time to time. Accordingly, you should check the Privacy Policy regularly for updates. Should the Privacy Policy be revised, ERE Docs, will post the changes on the Site and the new Privacy Policy will be effective immediately upon posting. If ERE Docs, makes any material changes to the Privacy Policy, in addition to posting the revised Privacy Policy to the Site, ERE Docs, will notify you in the manner and to the extent required by law.
            </p>

            <p>
              <strong>2) Types of Information Collected. </strong>
            </p>

            <div>
              <p>We collect, retain in our database, and use information voluntarily provided by you. For example, we may collect and retain information under the following circumstances:</p>
              <p>If you request services, products or other information through the Site, we may collect personally identifiable information from you, including first and last name, physical address, occupation, business title, company name, e-mail address, home and employer address, office, home and mobile telephone numbers, fax number, account username, password, unique device identifier of your devices that connect to or access the Site and any other information that might facilitate contacting you (“Contact Information”) and credit or debit card number, bank account number and any other information that might facilitate collecting payment from you (“Financial Information”), which will be used to fulfill requests for such services or products. In addition, we may collect certain other information, including purchasing and transaction history, occupation, preferences, information regarding products or services you purchase from us or products or services that you contact customer service about, professional license and/or continue education numbers or identifiers, and other information that you provide to ERE Docs, or that is generated through your use of the Site or interactions with ERE Docs, that are not otherwise publicly available (“Other Information”).</p>
              <p>If you access, use or browse the Site, we may collect non-personally identifiable information about you, such as information regarding your use of the Site, your IP address, Site usage statistics, time stamps, browsing history, general geographic location, product settings, adjustments made to products, cookies, type of device you use to connect to the Site, type of computer operating system you use (e.g., Microsoft Windows, Google Chrome OS, Mac OS, Android or iOS), the type of browser you use (e.g., Firefox, Chrome, Internet Explorer, Safari), the domain name of your internet service provider, and information regarding your Internet location when you see ERE Docs,’s.</p>
            </div>

            <p>
              <strong>3) How We Collect Information. </strong>
            </p>

            <ul>
              <li>
                We may collect personally identifiable and/or non-personally identifiable information from you when you undertake any of the following activities (“Activities”):
              </li>
              <li>
                Access, use or browse the Site;
              </li>
              <li>
                Register for an account on the Site;
              </li>
              <li>
                Order or request products, literature, services or other information during your visit to the Site, via e-mail, fax or other online or offline means
              </li>
              <li>
                Contact, communicate with or otherwise interact with ERE Docs, or its personnel via e-mail, the Site, fax, telephone or by other means whether online or offline;
              </li>
              <li>
                Sign up to receive announcements, e-mails, newsletters or marketing materials; and
              </li>
            </ul>

            <p>
              By using the Site, or by submitting your personally identifiable information to ERE Docs, online or offline, you expressly consent to the storage and processing of your personally identifiable information in the United States in accordance with the laws of the United States, or in other jurisdictions.
            </p>

            <p>
              We may also collect personally identifiable information and/or non-personally identifiable information from your employer or authorized agent(s) when they seek to register you for a ERE Docs, product/service. If you are an employer or an authorized agent and wish to register employees or other third parties for a ERE Docs, product/service, by submitting to ERE Docs, the personally identifiable information and/or non-personally identifiable information of your employees or other third parties, you are representing and warranting to ERE Docs, that you are authorized in accordance with applicable laws and regulations to submit such information.
            </p>

            <p>
              <strong>4) How We Use Your Information. </strong>
            </p>

            <p> We may use the information we collect:</p>

            <ul>
              <li>
                To confirm orders and manage payment collection;
              </li>
              <li>
                To process and respond to your inquiries and other communications;
              </li>
              <li>
                For Site administration, support and improvement;
              </li>
              <li>
                To alert you to new features, terms, Activities, special events, products or services, and other useful information regarding any of the foregoing or ERE Docs, via e-mail, direct mail, telephone or other means;
              </li>
              <li>
                To promote and improve the products and services we offer;
              </li>
              <li>
                To monitor and analyze information regarding your browsing and viewing preferences and to diagnose problems with the Site;
              </li>
              <li>
                To enforce the Terms of Use;
              </li>
              <li>
                For fraud prevention, internal operations, legal compliance and other public purposes;
              </li>
              <li>
                For the purposes for which you provided the information;
              </li>
              <li>
                To log into and improve and administer Activities;
              </li>

              <ol>
                <li>
                  ERE Docs uses a limited use of Google Data to read message bodies of an account we will set up for joint use.
                </li>
                <li>
                  We will NOT use this email to send messages or for advertisements.
                </li>
                <li>
                  This will be used for authentication and or validation purposes.
                </li>
              </ol>
            </ul>

            <p>In addition to the uses discussed above, ERE Docs, may use non-personally identifiable information in any manner as long as ERE Docs, uses such information in its de-identified or aggregated form. These uses may include, without limitation, analysis of trends and how the Site is used; improving navigation of the Site; analysis of the performance of the Site and diagnosis of problems; analysis and developing advertisements and advertising campaigns; and providing you with special offers and promotions and determining the effectiveness of same.</p>

            <p>
              <strong>5) Sharing of Information. </strong>
            </p>

            <p>
              ERE Docs, may share information about you as follows or as otherwise described in this Privacy Policy:
            </p>

            <ul>
              <li>
                With governmental suppliers of information, payment processors, shippers, vendors, payment processors, consultants and affiliates, which may include our independent contractors and co-sponsors of Activities, for purposes of;
                <ol>
                  <li>
                    Outsourcing one or more of the Activities or other functions described in this Privacy Policy
                  </li>
                  <li>
                    Internal administration of ERE Docs, and/or the Site
                  </li>
                  <li>
                    To confirm or update information provided by you;
                  </li>
                </ol>
              </li>
              <li>
                In the event of any reorganization, merger, sale, joint venture, assignment, transfer or disposition of all or any portion of ERE Docs' business or operations (including without limitation in connection with bankruptcy or any similar proceedings);
              </li>
              <li>
                In response to a subpoena, legal order or official request, when we believe you have acted in violation of the Terms of Use.
                <ol>
                  <li>
                    To investigate, prevent or take action regarding suspected illegal activities, fraud or potential threats, or
                  </li>
                  <li>
                    To exercise legal rights or defend against claims, when we believe that doing so may protect your safety or the safety of ERE Docs, or others, or as otherwise permitted or required by law; or otherwise authorized by you.
                  </li>
                </ol>

              </li>
            </ul>

            <p>
              <strong>6) Communications with You. </strong>
            </p>

            <p>You authorize ERE Docs, to transmit e-mail or postal mail to you or to telephone you, to respond to your communications, promote our services and administer and provide information to you about ERE Docs, products and services, and other Activities. If we send any e-mails to promote our products and services, unsubscribe instructions will be included in each such promotional e-mail from ERE Docs. Please note that such requests may take up to ten (10) business days to become effective. You may not opt-out of receiving communications regarding your dealings with ERE Docs, the Site or technical information about the Site.</p>
            <p>For the purposes of any applicable law regarding notification of persons whose personal information was, or is reasonably believed to have been, acquired by an unauthorized person, any required notification may, where permitted by law, be made by the use of e-mail, telephone, fax or mail (including a notice printed in an available area of a bill or statement) or by posting a notice on the Site. The specific means used is up to us, and we will use our best judgment based on the circumstances. Where any notice is to be sent to a specific address or number (such as an e-mail address, physical address, telephone number, etc.), we will use the latest available address or number in our records. YOU AGREE TO THIS MEANS OF NOTIFICATION.</p>

            <p>
              <strong>7) Children. </strong>The Site is not directed toward children under 18 years of age nor does ERE Docs, knowingly collect information from children under 18 on the Site or offline. If you are under 18, you are not permitted to register for an account on the Site or to otherwise submit any personally identifiable information to ERE Docs. If you provide information to ERE Docs, through the Site or offline, you represent that you are 18 years of age or older.
            </p>
            <p>
              <strong>8) Protection of Personal Information. </strong>ERE Docs, uses reasonable administrative, physical and electronic security measures to protect against the loss, misuse and alteration of personally identifiable information. No transmission of data over the internet, wi-fi, bluetooth, or other communication protocol is guaranteed to be completely secure. It may be possible for third parties not under the control of ERE Docs, to intercept or access transmissions or private communications unlawfully. While we strive to protect personally identifiable information, neither ERE Docs, nor our service providers can ensure or warrant the security of any information you transmit to us over the internet. Any such transmission is at your own risk.
            </p>

            <p>
              <strong>9) Updating Your Information. </strong>You are responsible for keeping your information current. You may update your information by contacting us at {EmailContact()}. We will use reasonable efforts to process any change you make; provided, however, that requests to delete information are subject to ERE Docs internal reporting, processing and retention policies and all applicable legal obligations.
            </p>

            <p>
              <strong>10) Retention of Your Information. </strong>Subject to your right(s) as described in this Privacy Policy, we will retain your Personal Information only for as long as is necessary for the purposes set out in this Privacy Policy, unless a longer period is required under applicable law or is needed to resolve disputes or protect our legal rights.
            </p>

            <p>
              <strong>11) California Residents. </strong>
            </p>

            <p>
              If you are a resident of the state of California, the following rights are available to you under the California Consumer Privacy Act.
            </p>
            <ul>
              <li>
                Right to access. You have the right to request that we disclose the categories of personal information collected, the categories of sources from which personal information is collected, the business or commercial purpose for collection, the categories of third parties with which we share personal information, and the specific pieces of personal information that we have about you. You also have the right to request that we provide your personal information to you in a readily useable format.
              </li>
              <li>
                Right to request deletion. You have the right to request that we delete personal information that we collected from you. Note, however, that certain requests to delete personal information may be denied if we are required or authorized to retain the information as a matter of law, the information is necessary for detecting security incidents, exercising free speech, protecting or defending against legal claims, or for internal uses reasonably aligned with consumer expectations, including, but not limited to, to provide a customer requested product or service. Historical transactional records cannot be deleted.
              </li>
              <li>
                Right to non-discrimination. You have the right not to receive discriminatory treatment by ERE Docs, for exercising these privacy rights. We do not offer financial incentives related to the provision of data.
              </li>
              <li>
                Authorized agent. You have the right to designate an authorized agent to make these requests on your behalf. To exercise any of these rights, please e-mail us at {EmailContact()} by telephone at {TelephoneContact()}. To protect your privacy, you will be required to provide authenticating information prior to making changes or gaining access to your information. We will respond to inquiries, requests to review, and change requests to personal information within forty-five (45) days.
              </li>
            </ul>

            <p>
              <strong>12) Sell of Your Information. </strong>ERE Docs, does not sell your personal information.
            </p>

            <p>
              <strong>13) Links. </strong>You may have the opportunity to follow links on the Site to other websites that may be of interest to you. ERE Docs, endeavors to link only to reputable organizations. Because other websites may not be hosted or controlled by ERE Docs, we are not responsible for the privacy practices of those websites or the content provided thereon. Therefore, the privacy policies with respect to other websites may differ from those applicable to the Site. We encourage you to review the privacy policies of each other website. This Privacy Policy only applies to information collected and maintained by ERE Docs.
            </p>

            <p>
              <strong>14) Contact. </strong>If you have any questions or concerns regarding this Privacy Policy, please contact us at {EmailContact()} by telephone at {TelephoneContact()}. Please note that e-mail communications may not be secure. Accordingly, other than as directed in this Privacy Policy, you should not include personal or other sensitive information in your e-mail correspondence to the Site or ERE Docs.
            </p>

            <hr />

            <div className="page-container text-center">
              <p><strong><span>ERE<span className="blue-text">DOCS</span></span></strong></p>
              <p>
                {EmailContact()}
              </p>
              <p>
                {TelephoneContact("1 (385) 444-9672")}
              </p>
            </div>

          </Col>
        </Row>

      </div>
    </div>
  );
}

export default PrivacyPolicy;