import styled from "styled-components";
import { Button } from 'react-bootstrap';

export const StyledAgreementText = styled.div`
  margin-bottom: 1rem;
  padding: 15px; 
  text-align: left;
  overflow: auto; 
  height: 360px; 
  border: 1px solid var(--light-border); 
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06)!important;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: var(--scrollbar-bg);
  }
  
  &::-webkit-scrollbar {
    width: 12px;
    background-color: var(--scrollbar-bg);
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: var(--scrollbar-thumb);
  }

  & li {
    list-style: disc outside;
  }

  & .boldText {
    font-weight: bold;
  }
`;

export const StyledMutedText = styled.div`
  color: var(--muted-text);
  
  & > * {
    color: var(--muted-text);
  }
`;

// export const StyledLink = styled(Link)`
export const StyledBSOStatus = styled(Button)`
  & > span {
    font-weight: bold; 
  }
`;

export const StyledButton = styled(Button)`
  font-weight: bold; 
  & > span {
    font-weight: bold; 
  }
`;

export const StyledCircleButton = styled(Button)`
  border: 0;
  width: 16px;
  height: 16px;
  padding: 0 !important;
  border-radius: 100%;
`;

export const StyledDownloadButton = styled(Button).attrs((props) => ({ size: "sm", variant: "primary", className: "green-button", ...props }))`
  border-radius: 30px;
`;
