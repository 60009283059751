import React, { useState, useEffect } from 'react';
import { useUserData } from '../../contexts/AuthContext';
import { Form, Button, Alert, Row, Col } from 'react-bootstrap';
import { admin } from "../../utils/EREDocs";

const ForgotPasswordForm = ({ closeModal = () => { } } = {}) => {
  const { userData, loading, setLoading } = useUserData();
  let { passport: { user: { email: default_email = '' } = {} } = {} } = userData || {};
  if (!default_email && userData?.email) {
    default_email = userData.email;
  }

  const [formState, setFormState] = useState({ email: default_email });
  const [showSendButton, setShowSendButton] = useState(true);
  const [validated, setValidated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [modalAlert, setModalAlert] = useState({
    message: "Validation Error, please enter a valid email address.",
    alert_type: "info",
  });

  const formValidationEmail = (email) => {
    let valid = false;
    const email_regex_ver1 = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,40})+$/;
    const email_regex_ver2 = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email_regex_ver1.test(email) && email_regex_ver2.test(email)) {
      valid = true;
    }
    return valid;
  };

  const handleInputChange = (event) => {
    if (!event?.target?.name) {
      return;
    }
    let { name, value, type, checked = false } = event.target;
    if (type === 'checkbox') {
      value = checked;
    }
    setFormState((prevState) => ({ ...prevState, [name]: value }));

    // Validate Form on change
    let valid = false;
    let alert_message = "";
    let alert_type = "info";

    valid = formValidationEmail(value);
    setValidated(valid);
    setShowAlert(!valid);
    if (alert_message) {
      setModalAlert(prevState => ({ ...prevState, message: alert_message.trim(), alert_type }));
    }
  };

  const cancelForgotPassword = () => ((typeof closeModal === 'function') && closeModal());
  const forgotPassword = async (event) => {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);

    let results = await admin.forgotPassword(formState)
      .then((resp) => {
        setShowSendButton(false);
        setShowAlert(true);
        if (resp.data?.ok) {
          setModalAlert(prevState => ({ ...prevState, message: "A password reset link has been sent.  Please check your email in a few moments for the link to reset your password.  You may need to check your email spam folder if it isn't found in your inbox.", alert_type: "info" }));
        } else {
          setModalAlert(prevState => ({ ...prevState, message: "If a user is associated to that email, a password reset link will be sent momentarily.  Check your email and use the link to reset your password.", alert_type: "info" }));
        }
        return resp;
      })
      .catch((error) => {
        console.log("error in catch", error);
        setShowAlert(true);
        setModalAlert(prevState => ({ ...prevState, message: "There was an error requesting your password reset link.  Please contact support.", alert_type: "danger" }));
        return null;
      });

    setLoading(false);
    return;
  };

  useEffect(() => {
    let mounted = true;
    (mounted) && setLoading(false);
    return () => mounted = false
  }, []);

  return (
    <div>
      <Row className="text-center">
        <Alert
          dismissible
          onClose={() => setShowAlert(false)}
          show={showAlert}
          variant={modalAlert.alert_type}
        >{modalAlert.message}</Alert>

        <Form noValidate validated={validated} onSubmit={forgotPassword} autoComplete="off" >
          <p>Enter your email associated to your account below to get a password reset link.</p>
          <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="mt-3">Email:</Form.Label>
                <Form.Control
                  className="mb-3"
                  type="email"
                  placeholder=""
                  name="email"
                  onChange={handleInputChange}
                  value={formState.email}
                  autoComplete="off"
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                className={showSendButton ? "primary-button me-2" : "d-none"}
                disabled={!validated}
                type="submit"
                variant="success"
                {...(loading) && { disabled: true }}
              >{loading ? "Loading..." : "Send Link"}</Button>
              <Button className="simple-button" variant="light" onClick={cancelForgotPassword} {...(loading) && { disabled: true }}>{loading ? "Loading..." : "Cancel"}</Button>
            </Col>
          </Row>
        </Form>
      </Row>
    </div>
  );
}

export { ForgotPasswordForm };
export default ForgotPasswordForm;