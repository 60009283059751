import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { BSOAccountForm } from '../Forms';

const AddBSOAccountModal = ({ org_id, auth_id, setInvokeParentUpdate = () => { }, ...props } = {}) => {
  const [modalState, setModalState] = useState(false);
  const openModal = () => (setModalState(true));
  const closeModal = () => {
    setModalState(false);
    if (typeof setInvokeParentUpdate === 'function') {
      setInvokeParentUpdate((prevState) => !prevState);
    }
  }

  return (
    <div {...props} >
      <Button onClick={openModal}>+ Add BSO Account</Button>
      <Modal
        show={modalState}
        onHide={closeModal}
        animation={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title title={`(org_id: ${org_id} auth_id: ${auth_id})`} >Add BSO Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BSOAccountForm org_id={org_id} auth_id={0} hideBSOFields={true} closeModal={closeModal} setInvokeParentUpdate={setInvokeParentUpdate} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export { AddBSOAccountModal };
export default AddBSOAccountModal;