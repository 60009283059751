import axios from "axios";
const { getParams } = require('./index');
const AXIOS_TIMEOUT_MS = 1000 * Number(process.env.REACT_APP_AXIOS_TIMEOUT_SECONDS || 300); // In Milliseconds (default 5minutes)
let axiosOptions = { timeout: AXIOS_TIMEOUT_MS }; // (in ms) timeout of zero means there is no timeout limit 

// MATCHES with /manage/auth/
const auth = {
  insertOrUpdateAuthAccount: ({ auth_id = 0, payload = {} } = {}) => axios.post(`/manage/auth/${auth_id}`, payload, axiosOptions),
};

// MATCHES with /bsoaccount-management/
const bsoaccount_management = {
  getBSOAccountStrikeLogs: ({ auth_id = 0, ...params } = {}) => axios.get(`/bsoaccount-management/strikelogs/${auth_id}${getParams(params)}`, axiosOptions),
  getSecretsForSFTP: ({ org_id = 0, ...params } = {}) => axios.get(`/bsoaccount-management/secrets/sftp/${org_id}${getParams(params)}`, axiosOptions),
  updateSecretsForSFTP: ({ org_id = 0, ...payload } = {}) => axios.put(`/bsoaccount-management/secrets/sftp/${org_id}`, payload, axiosOptions),
  testSecretsForSFTP: ({ org_id = 0, ...payload } = {}) => axios.patch(`/bsoaccount-management/secrets/sftp/${org_id}`, payload, axiosOptions),
  updateSecrets: ({ auth_id = 0, ...payload } = {}) => axios.post(`/bsoaccount-management/secrets/update/${auth_id}`, payload, axiosOptions),
  checkSecrets: ({ auth_id = 0, ...params } = {}) => axios.get(`/bsoaccount-management/secrets/checksecrets/${auth_id}${getParams(params)}`, axiosOptions),
};

// MATCHES with /manage/org/
const org = {
  insertOrUpdateOrganization: ({ org_id = 0, payload = {} } = {}) => axios.post(`/manage/org/${org_id}`, payload, axiosOptions),
};

// MATCHES with /manage/secret/
const secret = {
  insertOrUpdateBsoCredsInSecrets: ({ org_id = 0, payload = {} } = {}) => axios.post(`/manage/secret/bso/${org_id}`, payload, axiosOptions),
  insertOrUpdateBsoLoginCredsInSecrets: ({ org_id = 0, payload = {} } = {}) => axios.post(`/manage/secret/bso/logins/${org_id}`, payload, axiosOptions),
  insertOrUpdateSftpCredsInSecrets: ({ org_id = 0, payload = {} } = {}) => axios.post(`/manage/secret/sftp/${org_id}`, payload, axiosOptions),
  getBsoSecrets: ({ org_id = 0, payload = {} } = {}) => axios.get(`/manage/secret/bso/${org_id}`, payload, axiosOptions),
  getBsoLoginsForOrg: ({ org_id = 0, payload = {} } = {}) => axios.get(`/manage/secret/bso/logins/${org_id}`, payload, axiosOptions),
  getSftpSecrets: ({ org_id = 0, payload = {} } = {}) => axios.get(`/manage/secret/sftp/${org_id}`, payload, axiosOptions),
};

// MATCHES with /manage/user/
const user = {
  insertOrUpdateUser: ({ user_id = 0, payload = {} } = {}) => axios.post(`/manage/user/${user_id}`, payload, axiosOptions),
};

export {
  auth,
  bsoaccount_management,
  org,
  secret,
  user,
}
export default {
  auth,
  bsoaccount_management,
  org,
  secret,
  user,
};

/***************************************************************************
  USE AS TEMPLATE
***************************************************************************
overkill_example3: ({org_id = 0, payload = {}} = {}) => axios.post(`/manage/org/${org_id}`, payload, axiosOptions),

overkill_example2: ({org_id = 0, queryParams = {}, payload} = {}) => axios.post(`/manage/org/${org_id}${isObject(queryParams) ? getParams(queryParams) : queryParams}`, payload, axiosOptions),

overkill_example1: (org_id, queryParams, postData) => {
  if(isObject(queryParams)) {
    queryParams = getParams(queryParams);
  }

  -- post example - /manage/org/:org_id?q=5
  return axios.post("/manage/org/" + org_id + queryParams, postData, axiosOptions),

  -- get example - /manage/org/:org_id?q=5
  return axios.get("/manage/org/" + org_id + queryParams, axiosOptions),
}

/***************************************************************************/
