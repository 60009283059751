import React, { useState, useEffect } from 'react';
import { useUserData } from '../../contexts/AuthContext';
import { Form, Alert, Row, Col } from 'react-bootstrap';
import SFTPSetupModal from '../Modals/SFTPSetupModal';
import TestSFTPDeliveryModal from '../Modals/TestSFTPDeliveryModal';
import { humanize } from '../../utils';
import { admin } from '../../utils/EREDocs';

const DeliverySettingsForm = ({ org_id } = {}) => {
  const { userData, setUserData } = useUserData();
  let { email: default_email,
    passport: {
      user: {
        email = '',
        organization: {
          org_id: default_org_id,
        } = {},
      } = {} } = {} } = userData || {};
  (typeof default_org_id !== "number") && (default_org_id = Number(default_org_id));
  (!org_id && default_org_id) && (org_id = default_org_id);
  (typeof org_id !== "number") && (org_id = Number(org_id));
  (!email && default_email) && (email = default_email);
  let deliveryFieldSettings = ['S3', 'SFTP'];
  let labelRemap = {
    S3: 'general_storage',
    SFTP: 'sftp_delivery'
  };
  let labelDescriptions = {
    general_storage: "We securely store a temporary copy until you download the file. After the file has been downloaded, it will be automatically deleted after 24 hours. If you haven't downloaded the document after 7 days, we permanently delete it.",
    sftp_delivery: "You can also setup an SFTP delivery by providing an account that has access to your firms SFTP server. These will get saved in a secure location that only the application will have access to. We suggest creating an account with limited write permissions to a particular directory.",
  };

  const [loadingForm, setLoadingForm] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [modalAlert, setModalAlert] = useState({ message: "", alert_type: "info" });
  const [formState, setFormState] = useState({
    S3: false,
    SFTP: false
  });

  const handleInputChange = (event) => {
    if (!event?.target) return;
    event.stopPropagation(); // Prevent other events from firing on click

    let { name, value, type, checked = false } = event.target;
    if (type === 'checkbox') {
      value = checked;
    } else if (type === 'radio') {
      (typeof value !== 'boolean') && (value = value === 'true');
    } else {
      event.preventDefault();
    }

    // Update the setting in form state 
    setFormState((prevState) => ({ ...prevState, [name]: value }));

    // Validate Form on change 
    let alertObject = { ...modalAlert };
    let requiredFields = [...deliveryFieldSettings];
    let valid = deliveryFieldSettings.includes(name)
    let testParams = Object.entries({ ...formState, [name]: value }).filter(([k, v]) => type === 'checkbox' || v).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
    setShowAlert(!valid);
    if (!valid) {
      alertObject.alert_type = "info";
      alertObject.message = "Please fill out all required fields. Missing " + requiredFields.filter((f) => !testParams[f]).join(', ');
      alertObject.message = alertObject.message.trim();
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }

    // Update the settings in the database
    if (valid) {
      updateDeliverySettings(name, value);
    }
  };

  const updateDeliverySettings = async (key, value) => {
    (typeof value !== 'boolean') && (value = value === "true");
    let alertObject = { ...modalAlert };
    let settings = [{ key, value }];
    let saveParams = { org_id, settings };
    let response;

    try {
      if (settings.length === 0) {
        throw new Error("Your form input is not valid. Please check your input and try again.");
      }

      setLoadingForm(true);
      response = await admin.saveOrgSettings(saveParams);
      alertObject.message = "Saved Successfully";
    } catch (ex) {
      console.log(ex);
      response = ex?.response || {};
      response.status = 500;
      response.statusText = `${ex.message}`;
      if (!response?.data) {
        response.data = { err: 401, details: `${ex.message}`, error: ex.error };
      }
    } finally {
      setLoadingForm(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    let { err, error, details, message = '', createdSettings = [] } = data;
    alertObject.alert_type = status !== 200 ? "danger" : "success";
    if (status !== 200) {
      alertObject.message = `[${status ?? err}] ${statusText} ${alertObject.message}`;
      alertObject.message += data?.status ? `${data.status} ` : ""; // ERROR 
      alertObject.message += error ? `${error} ` : ""; // ScrapeError Message + Controller Error 
      alertObject.message += (typeof response?.data === 'string') ? response.data : message ?? (details ? `${details} ` : "") + "Invalid submission.";
    }

    let newState = { ...formState };
    if (Array.isArray(createdSettings) && createdSettings.length > 0) {
      newState = {
        ...newState, ...createdSettings.reduce((acc, { key, value }) => ({
          ...acc,
          [key]: (typeof value !== 'boolean') ? value === true : value
        }), {})
      };
      setFormState(prevState => ({ ...prevState, ...newState }));
    }

    if (org_id === default_org_id) {
      setUserData((prev) => ({
        ...prev, ...newState,
        passport: {
          ...prev.passport,
          user: {
            ...prev.passport.user, ...newState,
            user_settings: { ...prev.passport.user.user_settings, ...newState }
          }
        }
      }));
    }

    // Show Alert success or danger
    setShowAlert(!!alertObject?.message);
    if (alertObject?.message) {
      alertObject.message = alertObject.message.trim();
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }
    return;
  };

  const getDeliverySettings = async (orgID = org_id) => {
    let inList = [...deliveryFieldSettings];
    let params = { ...(orgID) && { org_id: orgID } };
    let deliverySettings = await admin.getOrgSetting(params).then(({ status = 500, statusText = "Internal Server Error", data = {} }) => {
      let { settings: { settings: allSettings = {}, setting: individualSetting = {} } = {} } = data || {};
      (status !== 200) && console.log(`${status} ${statusText}`);
      if (individualSetting?.id) {
        allSettings[individualSetting?.id] = individualSetting;
      }
      return Object.entries(allSettings)
        .filter(([setting_id, { key } = {}]) => inList.includes(key))
        .reduce((acc, [setting_id, { id, key, value } = {}]) => {
          if (["true", "false"].includes(value)) {
            value = value === "true";
          }
          return { ...acc, [key]: value };
        }, { ...formState });
    });

    setFormState((prev) => ({ ...prev, ...deliverySettings }));
    if (orgID === default_org_id) {
      setUserData((prev) => ({
        ...prev, ...deliverySettings,
        passport: {
          ...prev.passport,
          user: {
            ...prev.passport.user, ...deliverySettings,
            user_settings: { ...prev.passport.user.user_settings, ...deliverySettings }
          }
        }
      }));
    }
    return;
  }

  useEffect(() => {
    let mounted = true;
    const init = async () => {
      setLoadingForm(true);
      await getDeliverySettings(org_id);
      setLoadingForm(false);
    }
    (mounted) && init();
    return () => mounted = false
  }, [org_id]);

  return (
    <div>
      <h4>File Delivery & Storage Settings</h4>
      <hr />
      <Alert
        dismissible
        onClose={() => setShowAlert(false)}
        show={showAlert}
        variant={modalAlert.alert_type}
        className="p-2 mb-1"
      >{modalAlert.message}</Alert>

      <Row>
        <Col>
          {Object.entries(formState).filter(([k, v]) => k === "S3" && deliveryFieldSettings.includes(k)).map(([key, value], index) => (<Col key={`${index}-${key}`}>
            <Form.Group controlId={key} className="mb-3">
              <Form.Label as={`${labelRemap[key] ?? key}`} column>
                <strong>{humanize(labelRemap[key] ?? key)}</strong>
              </Form.Label>
              <p>{labelDescriptions[labelRemap[key] ?? key] ?? ""}</p>
              <Form.Check
                inline
                type="radio"
                label="Enabled"
                name={key}
                disabled={loadingForm}
                onChange={handleInputChange}
                value={true}
                checked={typeof value === "boolean" ? value : value === "true"}
              />
              <Form.Check
                inline
                type="radio"
                label="Disabled"
                name={key}
                disabled={loadingForm}
                onChange={handleInputChange}
                value={false}
                checked={typeof value === "boolean" ? !value : value === "false"}
              />
            </Form.Group>
          </Col>))}
        </Col>
      </Row>

      <Row>
        <Col>
          {Object.entries(formState).filter(([k, v]) => k === "SFTP" && deliveryFieldSettings.includes(k)).map(([key, value], index) => (<Col key={`${index}-${key}`}>
            <Form.Group controlId={key} className="mb-3">
              <Form.Label as={`${labelRemap[key] ?? key}`} column>
                <strong>{humanize(labelRemap[key] ?? key)}</strong>
              </Form.Label>
              <p>{labelDescriptions[labelRemap[key] ?? key] ?? ""}</p>
              <Form.Check
                inline
                type="radio"
                label="Enabled"
                name={key}
                disabled={loadingForm}
                onChange={handleInputChange}
                value={true}
                checked={typeof value === "boolean" ? value : value === "true"}
              />
              <Form.Check
                inline
                type="radio"
                label="Disabled"
                name={key}
                disabled={loadingForm}
                onChange={handleInputChange}
                value={false}
                checked={typeof value === "boolean" ? !value : value === "false"}
              />
            </Form.Group>
          </Col>))}
        </Col>
      </Row>

      <Row>
        <Col>
          <SFTPSetupModal org_id={org_id} setParentState={setFormState} sftp_state={formState.SFTP} />
        </Col>
        <Col>
          <TestSFTPDeliveryModal org_id={org_id} />
        </Col>
      </Row>
    </div>
  );
}

export { DeliverySettingsForm };
export default DeliverySettingsForm;