import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserData } from "../contexts/AuthContext";
import { Row, Col } from 'react-bootstrap';
import ChangePasswordModal from '../components/Modals/ChangePasswordModal';

const UserAccount = () => {
  const navigate = useNavigate();
  const { userData, referrer = "/download-requests" } = useUserData();
  let { passport: { user: { user_id } = {} } = {}, isAuthenticated = false } = userData || {};

  if (!isAuthenticated) {
    navigate(referrer);
  }

  return (<div className="page-container">
    <div className="container-fluid">
      <Row>
        <Col>
          <p>Email: {userData?.email}</p>
          <ChangePasswordModal user_id={user_id} />
        </Col>
      </Row>
    </div>
  </div>);
}

export default UserAccount;