import React, { useState, useEffect } from 'react';
import { useUserData } from "../../contexts/AuthContext";
import { Row, Col, Alert } from 'react-bootstrap';
import { StyledSpan } from '../../styled/Span';
import { StyledButton } from "../../styled/Misc";
import UpdateBSOCredentialsModal from '../Modals/UpdateBSOCredentialsModal';
import UpdateGmailCredentialsModal from '../Modals/UpdateGmailCredentialsModal';
import { humanize, formatDate } from "../../utils/";
import { bsoaccount_management } from "../../utils/manage";
import { admin } from "../../utils/EREDocs";

const BSOCredentialSettings = ({ auth_id, org_id, prefix, bsoUser, defaultAuthUser, invokedByParent = false, ...props } = {}) => {
  const { userData } = useUserData();
  let { passport: {
    user: { auth_id: default_user_auth_id, user_id,
      selected_auth: { auth_id: default_auth_id, default_user_id, attorney, refresh_token_updatedAt } = {},
      organization: {
        org_id: default_org_id,
        prefix: default_prefix,
      } = {},
    } = {}
  } = {} } = userData || {};
  (!auth_id && (default_auth_id || default_user_auth_id)) && (auth_id = default_auth_id ? default_auth_id : default_user_auth_id);
  (!user_id && default_user_id) && (user_id = default_user_id);
  (!org_id && default_org_id) && (org_id = default_org_id);
  (!prefix && default_prefix) && (prefix = default_prefix);
  (org_id && typeof org_id !== "number") && (org_id = Number(org_id));
  (user_id && typeof user_id !== 'number') && (user_id = Number(user_id));
  (auth_id && typeof auth_id !== 'number') && (auth_id = Number(auth_id));
  (default_auth_id && typeof default_auth_id !== 'number') && (default_auth_id = Number(default_auth_id));
  const [loadingForm, setLoadingForm] = useState(false);
  const [showAlert, setShowAlert] = useState(true);
  const [modalAlert, setModalAlert] = useState({ message: "For security purposes, these credentials are stored in a secure password vault that can only be accessed by the application.", alert_type: "warning" });
  const [credentialsStatus, setCredentialsStatus] = useState({ storedBso: false, storedGmail: false });
  const [invokeCheckStoredCredentials, setInvokeCheckStoredCredentials] = useState(false);
  const [checkStoredCredCounter, setCheckStoredCredCounter] = useState(0);

  const checkStoredCredentials = async () => {
    setLoadingForm(true);
    let alertObject = { ...modalAlert };
    let payload = {
      auth_id,
      forceCheck: checkStoredCredCounter > 0,
      ...(prefix && { prefix }),
      ...(bsoUser && { bso_username: bsoUser }),
    };

    let response;
    try {
      response = await bsoaccount_management.checkSecrets(payload);
      alertObject.message = "For security purposes, these credentials are stored in a secure password vault that can only be accessed by the application. ";
    } catch (ex) {
      console.log(ex);
      response = ex?.response || {};
      response.status = 500;
      response.statusText = `${ex.message}`;
      if (!response?.data) {
        response.data = { ok: false, error: ex.error, message: `${ex.message}`, };
      }
    } finally {
      setLoadingForm(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    let { ok = false, error, message = '', storedBso: _storedBso = false, storedGmail: _storedGmail = false } = data || {};

    let newState = { storedBso: _storedBso, storedGmail: _storedGmail };
    alertObject.alert_type = (status !== 200 || !ok) ? "danger" : "success";
    if (status !== 200 || !ok) {
      alertObject.message = `[${status}] ${statusText} `;
      alertObject.message += Object.entries(newState).map(([key, value]) => `${key} = `.blue + `${value}`.brightWhite).join(', '.blue)
      newState.storedBso = newState.storedGmail = "?";
    }
    alertObject.message += message ? `${message} ` : "";
    alertObject.message += error ? `${error} ` : "";

    setCredentialsStatus((prev) => ({ ...prev, ...newState }));

    // Show Alert success or danger
    if (alertObject?.message) {
      alertObject.message = alertObject.message.trim();
      setShowAlert(true);
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }

    setCheckStoredCredCounter((prev) => prev + 1);
    return;
  }

  const getGmailToken = async (event) => {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation();

    let alertObject = { ...modalAlert };
    let payload = { auth_id, user_id: defaultAuthUser || user_id };
    let response;
    try {
      setLoadingForm(true);
      if (!payload.auth_id) {
        throw new Error("Invalid auth_id was provided");
      }
      if (!payload.user_id) {
        throw new Error("Invalid user_id was provided");
      }
      response = await admin.getGmailToken(payload);
      alertObject.alert_type = "warning";
      alertObject.message = "Retrieved Gmail Token URL ";
    } catch (ex) {
      console.log(ex);
      response = ex?.response || {};
      response.status = 500;
      response.statusText = `${ex.message}`;
      if (!response?.data) {
        response.data = { error: ex.error, message: `${ex.message}`, };
      }
    } finally {
      setLoadingForm(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    let { error, message = '', url } = data || {};
    if (status !== 200 || !url) {
      alertObject.alert_type = "danger";
      alertObject.message = `[${status}] ${statusText} `;
    }
    if (alertObject.message.charAt(alertObject.message.length - 1) !== ' ') {
      alertObject.message += ' '; // Add trailing space if there's not one already
    }
    alertObject.message += message ? `${message} ` : "";
    alertObject.message += error ? `${error} ` : "";

    if (url) {
      console.log("Retrieved Gmail Token URL: ", url);
      window.open(url, "_blank", "");
    }

    // Show Alert success or danger
    if (alertObject?.message) {
      alertObject.message = alertObject.message.trim();
      setShowAlert(true);
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }
    return;
  }

  const setGmailToken = async (event) => {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation();

    let code = prompt('Paste the code you received.');
    let alertObject = { ...modalAlert };
    let payload = { auth_id, code };
    let response;
    try {
      setLoadingForm(true);
      if (!payload.code) {
        throw new Error("Invalid code was provided");
      }
      if (!payload.auth_id) {
        throw new Error("Invalid auth_id was provided");
      }
      response = await admin.setGmailToken(payload);
      alertObject.alert_type = "success";
      alertObject.message = `Successfully set gmail token for auth_id: ${auth_id}. `;
    } catch (ex) {
      console.log(ex);
      response = ex?.response || {};
      response.status = 500;
      response.statusText = `${ex.message}`;
      if (!response?.data) {
        response.data = { error: ex.error, message: `${ex.message}`, };
      }
    } finally {
      setLoadingForm(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    let { ok = false, error, message = '', code: saved_code, auth_id: saved_auth_id, token = {} } = data || {};
    if (status !== 200 || !ok) {
      alertObject.alert_type = "danger";
      alertObject.message = `[${status}] ${statusText} Error may have been caused by not having a bso/url instance first. Use the [Get Update Token] button first.`;
    }
    if (!token) {
      alertObject.alert_type = "danger";
      alertObject.message += `Unknown response from server the token may not have been saved. Please try again! `;
    }
    (saved_code && typeof saved_code !== 'string') && (saved_code = `${saved_code}`);
    if (saved_code !== code) {
      alertObject.alert_type = "danger";
      alertObject.message += `Code did not match returned results. Please try setting the token again! ${code} !== ${saved_code} `;
    }
    (saved_auth_id && typeof saved_auth_id !== 'number') && (saved_auth_id = Number(saved_auth_id));
    if (saved_auth_id !== auth_id) {
      alertObject.alert_type = "danger";
      alertObject.message += `auth_id did not match returned results. Please try setting the token again! ${auth_id} !== ${saved_auth_id} `;
    }
    alertObject.message += error ? `${error} ` : "";
    alertObject.message += message ? `${message} ` : "";

    // Show Alert success or danger
    if (alertObject?.message) {
      alertObject.message = alertObject.message.trim();
      setShowAlert(true);
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }
    return;
  }

  useEffect(() => {
    let mounted = true;
    if (mounted && auth_id && (bsoUser || defaultAuthUser)) {
      checkStoredCredentials();
    }
    return () => mounted = false;
  }, [auth_id, bsoUser, defaultAuthUser, invokeCheckStoredCredentials, invokedByParent]);

  return (
    <div className="page-container" {...props}>
      <h4>Update BSO Credentials</h4>
      <Alert
        dismissible
        onClose={() => setShowAlert(false)}
        show={showAlert}
        variant={modalAlert.alert_type}
      >{modalAlert.message}</Alert>

      <Row>
        <Col>
          <p>Stored BSO Credentials: {loadingForm ? "loading..." : (
            <StyledSpan>{humanize(credentialsStatus.storedBso)}</StyledSpan>)}
          </p>
        </Col>
        {/* <Col><UpdateBSOCredentialsModal auth_id={auth_id} bsoUser={bsoUser} setInvokeParentUpdate={setInvokeCheckStoredCredentials} /></Col> */}
        <Col><UpdateBSOCredentialsModal auth_id={auth_id} setInvokeParentUpdate={setInvokeCheckStoredCredentials} /></Col>
      </Row>
      <Row>
        <Col>
          <p>Stored Gmail Credentials: {loadingForm ? "loading..." : (
            <StyledSpan>{humanize(credentialsStatus.storedGmail)}</StyledSpan>)}
          </p>
        </Col>
        <Col><UpdateGmailCredentialsModal auth_id={auth_id} setInvokeParentUpdate={setInvokeCheckStoredCredentials} /></Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <StyledButton
            className="btn-primary"
            title={`${(auth_id === default_auth_id && attorney) ? attorney : ""} auth_id=${auth_id} user_id=${defaultAuthUser || user_id || "?"} refresh_token_updatedAt=${refresh_token_updatedAt ? formatDate(refresh_token_updatedAt) : "?"}`}
            onClick={getGmailToken}
          >Get Gmail Token</StyledButton>
        </Col>
        <Col>
          <StyledButton
            className="green-button"
            title={`${(auth_id === default_auth_id && attorney) ? attorney : ""} auth_id=${auth_id} refresh_token_updatedAt=${refresh_token_updatedAt ? formatDate(refresh_token_updatedAt) : "?"}`}
            onClick={setGmailToken}
          >Set Gmail Token</StyledButton>
        </Col>
      </Row>
    </div>
  );
}

export default BSOCredentialSettings;