import React, { useState, useRef } from 'react';
import { Alert } from 'react-bootstrap'; // Bootstrap Components
import ClaimantsTable from '../components/Tables/ClaimantsTable';

const Claimants = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [pageAlert, setPageAlert] = useState({ message: "", alert_type: "info" });
  const claimantsRef = useRef();

  // Set up initial variables
  const showDebug = false;
  const refresh_interval_seconds = 0;
  const tableFields = ['claimant_name', 'SSN', 'organization', 'status_of_case', 'last_known_bso_level', 'last_captured'];
  const renamedFields = {
    last_four_social: "SSN",
    level: 'last_known_bso_level'
  };
  const dateFields = ['last_captured'];
  const skipFields = ['claimant_id', 'matter_id', 'org_id', 'auth_id', 'user_id', 'unexhibited_count', 'exhibited_count', 'media_count'];

  return (<div className="page-container" ref={claimantsRef}>
    <h4>Claimants</h4>
    <hr />
    <Alert
      dismissible
      onClose={() => setShowAlert(false)}
      show={showAlert}
      variant={pageAlert.alert_type}
    >{pageAlert.message}</Alert>
    <ClaimantsTable
      showFilter={true}
      showDebug={showDebug}
      showParentAlert={setShowAlert}
      setParentAlert={setPageAlert}
      parentRef={claimantsRef}
      refresh_interval_seconds={refresh_interval_seconds}
      tableFields={tableFields}
      renamedFields={renamedFields}
      dateFields={dateFields}
      skipFields={skipFields}
    />
  </div>);
}

export default Claimants;