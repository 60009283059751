import React, { useState, useRef } from 'react';
import { Alert } from 'react-bootstrap';
import ClaimantUploadRequests from "../components/Tables/ClaimantUploadRequests";

const UploadRequestLogs = ({ org_id, ...props } = {}) => {
  const uploadRequestLogsRef = useRef();
  const [showAlert, setShowAlert] = useState(false);
  const [pageAlert, setPageAlert] = useState({ message: "", alert_type: "info" });

  return (
    <div className="page-container" ref={uploadRequestLogsRef} {...props}>
      <h4>Upload Request Logs</h4>
      <hr />
      <Alert
        dismissible
        onClose={() => setShowAlert(false)}
        show={showAlert}
        variant={pageAlert.alert_type}
      >{pageAlert.message}</Alert>

      <ClaimantUploadRequests
        showFilter={true}
        showDebug={false}
        org_id={org_id}
        showClaimantLinks={true}
        showParentAlert={setShowAlert}
        setParentAlert={setPageAlert}
        parentRef={uploadRequestLogsRef}
        refresh_interval_seconds={0}
        skipFields={[
          'document_date', // defaultFormInputs
          'treatment_source', 'from_date', 'to_date', // extraFormInputs
          'claimant_id', 'document_type_id', 'total_file_size', 'status_details', 'firm', 'site_code', 'rqid', 'rf', 'dr', 'qid', 'doc_count', 'brq_ran_count', 'last_captured'
        ]}
        tableFields={['auth_id', 'attorney', 'id', 'status', 'brq_id', 'brq_status', 'tracking_number', 'last_status_check', 'next_run', 'Requested At', 'Delivered At', 'Duration', 'upload_method', 'claimant', 'document_type', 'status_of_case', 'requested_by']}
        renamedFields={{
          upload_request_id: 'id',
          bso_request_queue_id: 'brq_id',
          claimant_name: 'claimant',
          submitted_timestamp: 'Requested At',
          successful_sftp_delivery: 'Delivered At',
        }}
        dateFields={['last_status_check', 'next_run', 'Requested At', 'Delivered At']}
        formatStr={"MM/D/YYYY hh:mm:ss A"}
      />
    </div>
  );
}

export default UploadRequestLogs;