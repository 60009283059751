import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom"
import { useUserData } from "../../contexts/AuthContext";
import { Button, Table, ListGroup, Form, Row, Col } from 'react-bootstrap';
import { StyledListNoBorder } from "../../styled/List"

// Components
import CustomProgressBar from "../../components/CustomProgressBar";
import Loader from "../Loader";

// EREDocs Functions 
import { downloader } from '../../utils/EREDocs';
import { humanize, dehumanize, massageData, isObject, statusFieldsClass, getUrlPath, getParams } from "../../utils/";
import downloadRequestDetails from '../../fake-data/downloadRequestDetails.json' // When loadFakeData = true
import socialStatuses from "../../fake-data/socialStatus.json"; // When loadFakeData = true

// Globals 
const disableDownloadRowButton = true; // true will hide download button on each row 
const loadFakeData = process.env.REACT_APP_LOAD_FAKE_DATA === "true";
const SECONDS_REFRESH_DETAILS_LOGS = Number(process.env.REACT_APP_SECONDS_REFRESH_LOGS || '5'); // Refresh logs every 5 seconds. Zero means no refresh.
const IN_PROGRESS_STATUS = ['CREATED', 'PENDING', 'PROCESSING', 'INITIATING', 'INITIATED']; // Also referred to as SOCIAL_PENDING_STATUS
const SOCIAL_SUCCESS_STATUS = ['CANCELLED', 'READY', 'SUCCESS'];
// const SOCIAL_FAILED_STATUS = ['FAIL', 'FAILED', 'READY_WITH_ERRORS', 'BLOCKED', 'DELETED', 'NOT_IN_BSO'];
// const SOCIAL_FINISH_STATUS = [...SOCIAL_SUCCESS_STATUS, 'BLOCKED', 'DELETED', 'NOT_IN_BSO'];
const defaultQueryParams = { limit: 25, showDebug: false };
let IntervalId = null;
let SOCIAL_STATUSES = socialStatuses;

const ClaimantDownloadRequests = ({
  org_id, claimant_id = null, showFilter = true, showDebug = false, showClaimantLinks = true, showDownloadLinks = true, refresh_interval_seconds = SECONDS_REFRESH_DETAILS_LOGS,
  tableFields = [], renamedFields = {}, dateFields = [], skipFields = [], skipPdfFields = [],
  showParentAlert = () => { }, setParentAlert = () => { }, parentRef = null, ...props
} = {}) => {
  // If no claimant_id passed in force filter to be true
  if (!claimant_id && !showFilter) {
    showFilter = true;
  }
  if (claimant_id && typeof claimant_id !== 'number') {
    claimant_id = Number(claimant_id);
  }

  // Set default fields if not passed in
  if (!isObject(renamedFields)) {
    renamedFields = {};
  }
  if (!Array.isArray(dateFields)) {
    dateFields = [];
  }
  if (!Array.isArray(tableFields) || tableFields.length === 0) {
    tableFields = ['request_id', 'Requested', 'requested_by', 'ID', 'QID', 'claimant_name', 'SSN', 'social_status', 'Documents', 'Media'];
    renamedFields = {
      request_social_id: "ID",
      requested_timestamp: "Requested",
      social_status_details: "download_status_details",
      document_status: "Documents",
      media_status: "Media",
    };
    if (!dateFields.includes('Requested')) {
      dateFields.push('Requested');
    }
  }
  if (!Array.isArray(skipFields) || skipFields.length === 0) {
    const docProgressFields = ['document_percentage', 'docs_processed', 'docs_to_process', 'docs_requested', 'exhibited_status', 'unexhibited_status', 'custom_status'];
    const mediaProgressFields = ['media_percentage', 'media_processed', 'media_to_process', 'media_requested'];
    skipFields = ['claimant_id', 'matter_id', 'file_downloads', 'download_status_details', 'pdf_settings', ...docProgressFields, ...mediaProgressFields]; // These fields are used by functions and should not be displayed
  }
  if (!Array.isArray(skipPdfFields) || skipPdfFields.length === 0) {
    skipPdfFields = ['combine_pdf', 'settings_author', 'settings_creator', 'settings_producer', 'download_exhibited', 'download_unexhibited_if_exhibited_unavailable', 'download_unexhibited', 'download_audio', 'convert_to_pdf'];
  }

  const navigate = useNavigate();
  const { userData } = useUserData();
  let { isAuthenticated = false } = userData || {};

  const [invokeUpdate, setInvokeUpdate] = useState(false);
  const [loadingLogs, setLoadingLogs] = useState(false);

  const [tableHeader, setTableHeader] = useState([]);
  const [tableRows, setTableRows] = useState([]);

  const [Pages, setPages] = useState([1]);
  const [activePage, setActivePage] = useState(1);

  // Filter form state
  const [filterFormState, setFilterFormState] = useState({
    start_date: '',
    end_date: '',
    claimant: '',
    social_status: '',
    requested_by: ''
  });

  // Handle form change
  const handleFormChange = (event) => {
    if (!event?.target) return;
    const { name, value } = event.target;
    setFilterFormState({ ...filterFormState, [name]: value });
  };

  // Clear filters
  const clearFilters = (event) => {
    if (!event?.target) return;
    event.preventDefault();
    setFilterFormState({
      start_date: '',
      end_date: '',
      claimant: '',
      social_status: '',
      requested_by: ''
    });
    return setInvokeUpdate(prev => !prev);
  }

  // Search Social Requests 
  const searchSocialRequests = async (event) => {
    if (!event?.target) return;
    event.preventDefault();
    return setInvokeUpdate(prev => !prev);
  }

  /**
  * handleClick 
  * When action is download we'll prepare the href for downloading the file otherwise just navigate to the href 
  * @param {*} event 
  * @param {Object} params - {
  *  action - 'download' | 'navigate' | 'cancel' | 'cancel-all', 
  *  href - url string to download file or navigate to,
  * }
  * @returns 
  */
  const handleClick = async (event, { href, action = "navigate", request_id, request_social_id } = {}) => {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation();

    if (['cancel', 'cancel-all'].includes(action)) {
      if (!request_id && (event?.target?.dataset?.request_id)) {
        request_id = event?.target?.dataset?.request_id;
      }
      if (!request_social_id && (event?.target?.dataset?.request_social_id)) {
        request_social_id = event?.target?.dataset?.request_social_id;
      }
      if (window.confirm(`Are you sure you want to cancel ${(action === "cancel-all") ? "request_id: " + request_id : "request_social_id: " + request_social_id}?`)) {
        let response;
        try {
          if (action === "cancel-all") {
            if (!request_id) {
              throw new Error("request_id is required for cancel-all");
            }
            response = await downloader.cancelRequest(request_id);
          } else {
            if (!request_social_id) {
              throw new Error("request_social_id is required for cancel");
            }
            response = await downloader.cancelSocialRequest(request_social_id);
          }
        } catch (error) {
          console.log(error);
          response = error?.response || {};
          if (!response?.data) {
            response.data = { err: 401, error: error.error, details: `${error.message}` };
          }
        }

        let { status, statusText = '', data = {} } = response;
        let { err, error, details, message, success = false } = data || {};
        if (status !== 200 || !success) {
          let alert_type = "danger";
          let alert_message = `[${status ?? err}] ${statusText} `; // API Error Code 
          alert_message += data?.status ? `${data.status} ` : "";  // API Status 
          alert_message += error ? `${error} ` : "";               // API Error 
          alert_message += (typeof response?.data === 'string') ? response.data : message ?? (details ? `${details} ` : "") + `Failed to read data for request details.`;
          alert_message = alert_message.trim();
          showParentAlert(true);
          setParentAlert((prevState) => ({ ...prevState, message: alert_message, alert_type }));
        }
      }
      return setInvokeUpdate(prev => !prev); // Exit if action is cancel or cancel-all
    }

    if (!href && (event?.target?.href || event?.target?.dataset?.href)) {
      href = event?.target?.href || event?.target?.dataset?.href;
    }
    if (!href || href === "#" || href === "/eredocs") return;

    if (action === "navigate") {
      navigate(href); // Will not work with <Link> component or <a> tag. Just use Link and a tag naturally instead of navigate.
      return; // Exit if action is navigate
    }

    // Prepare href for downloading a file 
    href = `/${getUrlPath(href)}`;
    if (href === "#" || href === "/eredocs" || !showDownloadLinks) return;

    try {
      await downloader.getFile(href, ({ status, statusText, data }) => {
        let message = `${status} ${statusText}`;
        (typeof data === 'string') && (message += ` - ${data}`);
        if (![200, 201].includes(status)) {
          message = `Error ${message}`;
        } else {
          setInvokeUpdate(prev => !prev); // Exit if action is download
        }
        console.log(message);
      });
    } catch (error) {
      let { status, statusText, data } = error?.response || { 'status': 500, 'statusText': "Unknown server error.", data: { err: 500, 'details': error?.message ? error.message : error } };
      let error_message = `Error ${status} - `;
      if (typeof data === 'string') {
        error_message += (statusText === data) ? statusText : data;
      } else if (typeof data === 'object') {
        error_message += data?.message ? `${data?.details ? data.details + ". " : ""}${data.message}` : (data?.details ? data.details : "Unknown server error.");
      }
      console.log(error?.message ? error.message : (error?.stack ? error.stack : error));
      console.log(error_message);
    }
    return
  };

  /**
  * handleTableRowClick
  * Extracts row items and save to rowObject and selectedItem
  * @param {*} event 
  * @returns 
  */
  const handleTableRowClick = (event) => {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation(); // Prevent other events from firing on click

    let clickedElement = event?.target || {};
    let { parentElement, dataset, tagName, type, innerText, href, id } = clickedElement;
    let action = (href || ["BUTTON", "SPAN"].includes(tagName)) ? (
      `${innerText}`.toLowerCase().includes("download") ? "download" : (
        `${innerText}`.toLowerCase().includes("cancel") ? "cancel" : "navigate"
      )
    ) : false;

    // Go up one level in the DOM but preserve innerText
    if (tagName !== "TD" && parentElement?.tagName === "TD") {
      clickedElement = parentElement;
      parentElement = clickedElement.parentElement;
      dataset = { ...dataset, ...clickedElement?.dataset };
      tagName = clickedElement?.tagName;
      if (!innerText && clickedElement?.innerText) {
        innerText = clickedElement.innerText;
      }
    }

    let rowObject = {
      innerText,
      tagName,
      ...(type && { type }),
      ...dataset,
      ...parentElement?.dataset,
    };

    // Extract row items and save to rowObject and selectedItem
    let selectedItem = {};
    if (tagName === "TD") {
      let tdArray = Array.from(parentElement.querySelectorAll("td")).map(({ innerText }) => innerText).filter((i) => i) || [];
      let thArray = Array.from(parentElement.closest("table").querySelectorAll("thead tr th")).map(({ innerText }) => dehumanize(innerText)).filter((i) => i) || [];

      if ('col' in rowObject) { // Extract selectedItem
        let col = Number(rowObject.col);
        if (col && innerText === tdArray[col]) {
          selectedItem[thArray[col]] = tdArray[col];
        }
      }

      // Match thArray length with tdArray length
      if (tdArray.length > thArray.length) {
        // slice extra elements to match thArray length
        tdArray = tdArray.slice(0, thArray.length);
      } else if (tdArray.length < thArray.length) {
        // Fill in the blanks with null values to match thArray length
        let startIndex = tdArray.length;
        tdArray.length = thArray.length;
        tdArray.fill(null, startIndex);
      }

      if (tdArray.length === thArray.length) {
        for (let i = 0; i < thArray.length; i++) {
          rowObject[thArray[i]] = tdArray[i];
        }
      }
    }

    // Extract selectedItem if failed to do so above
    if ('field' in rowObject) {
      let field = dehumanize(rowObject.field); // We must dehumanize this field since that's what happened to all of values in thArray (see above)
      if (!selectedItem[field] && rowObject[field] === innerText) {
        selectedItem[field] = rowObject[field];
      }
    }

    // Object.keys(rowObject).length > 0 && console.log("handleSocialRowClick", rowObject);
    // Object.keys(selectedItem).length > 0 && console.log("selectedItem", selectedItem);

    if (action) {
      let { request_id, request_social_id } = rowObject;
      if (!request_id) {
        console.log("Missing request_id in rowObject:", rowObject);
        return;
      }
      if (["download", "navigate"].includes(action)) {
        if (!href) {
          let fileName = (request_social_id) ? `request_social_id_${request_social_id}.zip` : `request_id_${request_id}.zip`;
          href = `eredocs/downloader/file/${request_id}/0/${fileName}`;
        }
      } else if (action === "cancel") {
        if (id === `cancel-all-btn-${request_id}` || !request_social_id) {
          action = "cancel-all";
        }
      }
      return handleClick(event, { href, action, request_id, request_social_id });
    }
    return;
  };

  const getTableData = async ({ startedAt = Date.now(), headerFields = tableFields, renamed_fields = renamedFields, date_fields = dateFields, functionFields = skipFields, refresh_in_seconds = refresh_interval_seconds } = {}) => {
    let now = Date.now();
    let duration_seconds = Math.round((now - startedAt) / 1000);
    let ran_count = !refresh_in_seconds ? 1 : Math.round(duration_seconds / refresh_in_seconds) || 1; // Avoid dividing by zero
    let first_run = now === startedAt || ran_count === 1;

    // Detect if user navigated away from this page 
    let offsetWidth = parentRef?.current?.offsetWidth || 0;
    let offsetHeight = parentRef?.current?.offsetHeight || 0;
    let navigated_away = !parentRef?.current || !(offsetWidth > 0 && offsetHeight > 0);

    if (!first_run && (navigated_away || !isAuthenticated)) {
      console.log("Clearing Interval for details because user navigated away.", { IntervalId, first_run, navigated_away, isAuthenticated, offsetWidth, offsetHeight });
      clearInterval(IntervalId);
      return;
    }

    // Filter out empty values and format with { key: value } array of object 
    let filterValues = Object.entries(filterFormState).filter(([id, value]) => value); // Filter out empty values
    let filteredData = (filterValues.length === 0) ? {} : Object.entries(filterFormState).reduce((acc, [id, value]) => ({ ...acc, ...(value && { [id]: value }) }), {});
    let patchData = {
      ...defaultQueryParams,
      showDebug,
      ...(org_id && { org_id }),
      ...(claimant_id && { claimant_id }),
      ...filteredData,
      page: activePage
    };

    let alert_message = '';
    let alert_type = "info";
    let response;

    try {
      if (loadFakeData) {
        response = {
          status: 200,
          statusText: 'OK',
          data: downloadRequestDetails
        };
      } else {
        response = await downloader.getSocialDetails(0, patchData);
      }
      alert_message = '';
    } catch (error) {
      console.log(error);
      response = error?.response || {};
      if (!response?.data) {
        response.data = { err: 401, error: error.error, details: `${error.message}` };
      }
    }

    let { status, statusText = '', data = {} } = response;

    let { err, error, details, message, rows = [], count = 0, pageCount = 1, pages = [] } = data || {};

    if (status === 200) {
      let dataHeader = [...headerFields, ...functionFields];
      let dataRows = (!Array.isArray(rows) || rows.length === 0) ? [] : massageData(rows, dataHeader, renamed_fields, date_fields);
      if (rows.length === 0 || count === 0) {
        if (activePage > 1 && activePage === Pages[Pages.length - 1]) {
          setActivePage(Pages[Pages.length - 2]); // Make sure we don't get stuck loading nothing
        } else {
          alert_type = "info";
          alert_message = "No logs found";
        }
      }

      pages = pages.reduce((acc, item, index) => {
        if (index === 0 && item.number !== 1) {
          acc.push(1); // Always include the first page 
        }

        acc.push(item.number);

        if (index === (pages.length - 1) && !acc.includes(pageCount)) {
          acc.push(pageCount); // Always include the last page
        }
        return acc;
      }, []);

      setTableHeader(dataHeader);
      setTableRows(dataRows);
      setPages(pages);
    } else {
      alert_type = "danger";
      alert_message = `[${status ?? err}] ${statusText} `;    // API Error Code 
      alert_message += data?.status ? `${data.status} ` : ""; // API Status 
      alert_message += error ? `${error} ` : "";              // API Error 
      alert_message += (typeof response?.data === 'string') ? response.data : message ?? (details ? `${details} ` : "") + `Failed to read data for request details.`;
      alert_message = alert_message.trim();
    }

    showParentAlert(!!alert_message);
    if (alert_message) {
      setParentAlert(prevState => ({ ...prevState, message: alert_message, alert_type }));
    }

    if (first_run && refresh_in_seconds) {
      if (IntervalId !== null) {
        console.log("Clearing Interval for details because new interval is being started.", { IntervalId });
        clearInterval(IntervalId);
      }
      let interval_ms = refresh_in_seconds * 1000;
      IntervalId = setInterval(getTableData.bind(null, { startedAt, headerFields, renamed_fields, date_fields, functionFields, refresh_in_seconds }), interval_ms); // Next render every refresh_in_seconds seconds
      console.log("Set Interval for details!", { IntervalId, activePage, loadingLogs, startedAt });
    }

    // console.log("getTableData", { first_run, IntervalId, ran_count, duration_seconds, now, startedAt, navigated_away, isAuthenticated, offsetWidth, offsetHeight, status, statusText });
    return data;
  }; // END getTableData

  // ===============[ useEffect ]==================================
  useEffect(() => {
    const init = async () => {
      showParentAlert(false);
      setLoadingLogs(true);
      await getTableData();
      setLoadingLogs(false);
    }

    let mounted = true;
    if (mounted) {
      if (IntervalId !== null) {
        console.log("Clearing Interval for details because changed detected", { IntervalId });
        clearInterval(IntervalId);
      }
      init(); // Initialize Request Data for Table
    }

    // Returned function will be called on component unmount 
    return () => {
      if (IntervalId !== null) {
        console.log("Clearing Interval for details because this component unmounted.", { IntervalId });
        clearInterval(IntervalId);
        mounted = false;
      }
    }
  }, [invokeUpdate, activePage]);

  useEffect(() => {
    const loadStatuses = async () => {
      let response;
      try {
        if (loadFakeData) {
          response = {
            status: 200,
            statusText: 'OK',
            data: socialStatuses
          };
        } else {
          let queryParams = getParams({ enum_key: "enum_ere_download_request_socials_status" });
          response = await downloader.getStatus(queryParams);
        }
      } catch (error) {
        console.log(error);
        response = error?.response || {};
        if (!response?.data) {
          response.data = { err: 401, error: error.error, details: `${error.message}` };
        }
      }

      let { status, statusText = '', data = [] } = response;
      if (status === 200) {
        SOCIAL_STATUSES = (Array.isArray(data) && data.length > 0) ? data : socialStatuses;
      } else {
        SOCIAL_STATUSES = socialStatuses;
        let { err, error, details, message } = data || {};
        let alert_message = `[${status ?? err}] ${statusText} `;  // API Error Code 
        alert_message += data?.status ? `${data.status} ` : "";   // API Status 
        alert_message += error ? `${error} ` : "";                // API Error 
        alert_message += (typeof response?.data === 'string') ? response.data : message ?? (details ? `${details} ` : "") + `Failed to read status table.`;
        alert_message = alert_message.trim();
        console.log(alert_message);
      }
    };

    loadStatuses();
  }, []);

  return (
    <div {...props}>
      {!showFilter && (<h4>Download Requests</h4>)}
      {showFilter && (<div className="filter-section">
        <h5>Filter Logs</h5>
        <Form className="filter-form mb-3" onSubmit={searchSocialRequests} onReset={clearFilters} >
          <Row>
            <Col>
              <Form.Group controlId="filterStartDate">
                <Form.Label>Start Date:</Form.Label>
                <Form.Control
                  size="sm"
                  type="date"
                  name="start_date"
                  value={filterFormState.start_date}
                  onChange={handleFormChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="filterEndDate">
                <Form.Label>End Date:</Form.Label>
                <Form.Control
                  size="sm"
                  type="date"
                  name="end_date"
                  value={filterFormState.end_date}
                  onChange={handleFormChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="filterClaimant">
                <Form.Label>Claimant</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="claimant"
                  value={filterFormState.claimant}
                  onChange={handleFormChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="filterStatus">
                <Form.Label>Social Status</Form.Label>
                <Form.Select size="sm" name="status" defaultValue={''} onChange={handleFormChange} >
                  <option value="">All</option>
                  {SOCIAL_STATUSES && SOCIAL_STATUSES.length > 0 && SOCIAL_STATUSES.map(({ status_id, status_code, status, label, css_class } = {}, idx) => (
                    <option
                      key={`${status}-${idx}`}
                      value={status}
                      data-status-id={status_id}
                      data-status-code={status_code}
                      {...(css_class && { className: css_class })}
                      {...(filterFormState.social_status === status) && { selected: true }}
                    >
                      {label ?? status}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="filterRequestedBy">
                <Form.Label>Requested By</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="requested_by"
                  value={filterFormState.requested_by}
                  onChange={handleFormChange}
                />
              </Form.Group>
            </Col>
            <Col className="d-flex align-items-end">
              <Button className="primary-button" type="submit" name="apply" {...(loadingLogs) && { disabled: true }} >Apply Filters</Button>
              <Button className="remove-button ms-3" type="reset" name="clear" {...(loadingLogs) && { disabled: true }} >Clear Filters</Button>
            </Col>
          </Row>
        </Form>
      </div>)}

      {loadingLogs ? (<Loader className="text-info" style={null} />) : (<Table hover>
        <thead>
          <tr>{tableHeader.filter((k) => !skipFields.includes(k)).map((header, index, arr) => (<th
            key={`${index}-${header}`}
            {...(index === arr.length - 1) && { colSpan: 2 }}
          >{(header.includes("_") || header.charAt(header.length - 1) === header.charAt(header.length - 1).toLowerCase()) ? (
            header === "social_status" ? "Status" : humanize(header)
          ) : header}
          </th>))}</tr>
        </thead>
        <tbody>{Array.isArray(tableRows) && tableRows.length ? tableRows.map((row = {}, index) => {
          const showCancelButton = IN_PROGRESS_STATUS.includes(row?.social_status);
          const showDownloadButton = !showCancelButton && !disableDownloadRowButton
            && SOCIAL_SUCCESS_STATUS.includes(row?.social_status)
            && (row?.file_downloads || []).every(({ request_zip = false, expiresAt = Date.now(), status_code = 3 } = {}) => (
              Boolean(request_zip) && (new Date(expiresAt || Date.now()) - Date.now()) >= 0 && status_code !== 3
            ));

          return (<tr
            onClick={handleTableRowClick}
            key={`${index}-${row.ID}`}
            data-row={index}
            data-request_id={row?.request_id}
            data-request_social_id={row.ID}
            data-claimant_id={row.claimant_id}
            data-matter_id={row.matter_id}
            data-status={row?.social_status}
            data-exhibited_status={row.exhibited_status}
            data-unexhibited_status={row.unexhibited_status}
            data-custom_status={row.custom_status}
          >{Object.entries(row).filter(([k, v]) => !skipFields.includes(k)).map(([key, value], idx) => {
            let progressBarColumns = ["Documents", "Media"];
            let percentageFields = {
              Documents: "document_percentage",
              Media: "media_percentage",
            };

            return (<td key={`${index}-${key}`} data-col={idx} data-field={key} {...(["social_status", "Status", ...progressBarColumns].includes(key) && { className: statusFieldsClass(value) })}>
              {progressBarColumns.includes(key) ? (<CustomProgressBar
                showDownloadLinks={showDownloadLinks}
                percentProgress={row[percentageFields[key]] || 0}
                field={key}
                download_status={value} // document_status or media_status
                download_status_details={row.download_status_details}
                status={row?.social_status} // Overall status of the social
                exhibited_status={row.exhibited_status}
                unexhibited_status={row.unexhibited_status}
                custom_status={row.custom_status}
                docs_requested={row.docs_requested}
                media_requested={row.media_requested}
                file_downloads={row?.file_downloads}
              />) : (showClaimantLinks && key === "claimant_name" ? (
                <Link to={`/claimant-details/${row.claimant_id}`} title={`claimant_id=${row.claimant_id} matter_id=${row.matter_id}`} onClick={(e) => e.stopPropagation()}>{humanize(value)}</Link>
              ) : (dateFields.includes(key)) ? value : humanize(value))}
            </td>);
          })}<td>{isObject(row?.pdf_settings) && Object.entries(row.pdf_settings).filter(([k, v]) => !skipPdfFields.includes(k)).length > 0 && (<StyledListNoBorder
            key={`pdf_settings-${index}-${row.ID}`}>{Object.entries(row.pdf_settings).filter(([k, v]) => !skipPdfFields.includes(k)).map(([key, value], index, arr) => (<ListGroup.Item
              key={`${index}-${key}`}>{humanize(key)}: {typeof value !== 'string' ? humanize(value) : value}</ListGroup.Item>))}</StyledListNoBorder>)}{(
                showCancelButton || showDownloadButton) && (<Button
                  data-request_id={row?.request_id}
                  data-request_social_id={row.ID}
                  data-status={row?.social_status}
                  id={`${showCancelButton ? "cancel" : "download"}-request_social_id-${row.ID}`}
                  type={showCancelButton ? "reset" : "submit"}
                  size="sm"
                  variant="danger"
                >{showCancelButton ? "Cancel" : "Download"}</Button>)}</td>
          </tr>)
        }) : (<tr><td colSpan={tableHeader.filter((k) => !skipFields.includes(k)).length}>No socials included in the request.</td></tr>)}
        </tbody>
        {Pages.length > 1 && (<tfoot>
          <tr>
            <td className="text-right" colSpan={tableHeader.filter((k) => !skipFields.includes(k)).length + 1} >
              <nav>
                <ul className="pagination justify-content-end" data-active-page={activePage} >
                  {Pages && Pages.length > 0 && Pages.map((pageNumber, index) => (
                    <li key={`page-${pageNumber}-${index}`} className={`page-item ${pageNumber === activePage ? "disabled" : ""}`} >
                      <Link
                        to={`#page-${pageNumber}`}
                        className="page-link"
                        onClick={(e) => {
                          e.preventDefault();
                          setActivePage(pageNumber);
                        }}
                        {...(pageNumber === activePage) && { tabIndex: -1 }}
                      >{pageNumber}</Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </td>
          </tr>
        </tfoot>)}
      </Table>)}
    </div>
  );
}

export default ClaimantDownloadRequests;