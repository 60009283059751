import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserData } from "../contexts/AuthContext";
import StepProgressBar from 'react-step-progress';

import 'react-step-progress/dist/index.css';
import { Accordion, Button, Card, Row, Col, Alert, Form, InputGroup, ListGroup } from 'react-bootstrap';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import { StyledListNoBorder } from "../styled/List";
import { StyledCustomDownloadTable } from "../styled/Table";
import { StyledCardLight } from "../styled/Card";
import { StyledAccordionDownloader } from "../styled/Accordion";
import { StyledGreenCheckbox } from "../styled/Forms";

// EREDocs Functions 
import { bso, downloader } from "../utils/EREDocs"; // tryAutoLogin, checkCookies, processRequest
import { dehumanize, humanize, isObject, splitArrayChunks, sleep, massageData, validParams, renameKeys } from '../utils/';

let _isValid = true;
const CustomDownloadRequest = () => {
  const navigate = useNavigate();
  const { userData, setUserData } = useUserData();
  let { passport: { user: { org_id, user_settings = {} } = {} } = {}, isAuthenticated = false, bsoLoggedIn = false, server_autologin = false, client_autologin = false } = userData || {};

  const [isValid, setIsValid] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [pageAlert, setPageAlert] = useState({ message: "", alert_type: "info" });
  const [activeStep, setActiveStep] = useState(1);

  const [enterSocialForm, setEnterSocialForm] = useState({ ssn: '', isValidSSN: false, label: "Social Security Number", buttonText: "Search" }); // Step 1: Enter Social
  const [tabIndex, setTabIndex] = useState(0); // Step 3: Select Files

  // Step 4: Review & Submit 
  const [requestObject, setRequestObject] = useState({
    info: {
      request_type_id: bsoLoggedIn ? 1 : 2,
    },
    socials: []
  });

  // Step 2: Select Options 
  const [downloadOptions, setDownloadOptions] = useState([
    {
      name: "DOWNLOAD_INCREMENTAL_DOWNLOADER",
      label: "Download Incremental Downloader",
      checked: false,
      disabled: false,
    },
    {
      name: "PDF_OCR_PROCESSING",
      label: "OCR Processing",
      checked: false,
      disabled: false,
    },
    {
      name: "PDF_INCLUDE_BACKTOTOP_LINK",
      label: "Include Backtotop Link",
      checked: true,
      disabled: false,
    },
    {
      name: "PDF_INCLUDE_TITLES",
      label: "Include Titles",
      checked: true,
      disabled: false,
    },
    {
      name: "PDF_INCLUDE_TOC",
      label: "Include Toc",
      checked: true,
      disabled: false,
    },
    {
      name: "PDF_SETTINGS_AUTHOR",
      label: "Settings Author",
      value: "ERE",
      disabled: false,
    },
    {
      name: "PDF_SETTINGS_CREATOR",
      label: "Settings Creator",
      value: "ERE",
      disabled: false,
    },
    {
      name: "PDF_SETTINGS_PRODUCER",
      label: "Settings Producer",
      value: "ERE",
      disabled: false,
    },
    {
      name: "PDF_TITLE",
      label: "Pdf Title",
      value: "",
      disabled: false,
    }
  ]);

  // References for accordion steps
  const enterSocialStep = useRef();   // Step 1 
  const selectOptionsStep = useRef(); // Step 2 
  const selectFilesStep = useRef();   // Step 3
  const reviewAndSubmit = useRef();   // Step 4 

  // ===============[ toggleAccordionSlide ]==================================
  const toggleAccordionSlide = function (event) {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation();

    let { step = 1, innerText = '' } = this || {};
    if (!innerText) {
      innerText = event.target?.innerText
    }
    innerText = `${innerText}`.toLowerCase().trim();
    let targetElement = null;

    if (innerText && innerText.includes('select')) {
      step = innerText.includes('options') ? 2 : 3;
    } else if (innerText && innerText.includes('review')) {
      step = 4;
    } else if (innerText && innerText.includes('enter')) {
      step = 1;
    }

    // console.log("toggleAccordionSlide", { step, innerText });
    switch (step) {
      case 2:
        if (selectOptionsStep?.current.querySelector('button')) {
          targetElement = selectOptionsStep.current.querySelector('button');
        }
        break;

      case 3:
        if (selectFilesStep?.current.querySelector('button')) {
          targetElement = selectFilesStep.current.querySelector('button');
        }
        break;

      case 4:
        if (reviewAndSubmit?.current.querySelector('button')) {
          targetElement = reviewAndSubmit.current.querySelector('button');
        }
        break;

      default: // Step 1 (default)
        if (enterSocialStep?.current.querySelector('button')) {
          targetElement = enterSocialStep.current.querySelector('button');
        }
        break;
    }

    if (targetElement) {
      targetElement.click();
    }
  };

  // ===============[ updateRequestTable ]==================================
  const updateRequestTable = () => {
    if (!Array.isArray(requestObject.socials) || requestObject.socials.length === 0) {
      return;
    }

    requestObject.socials.forEach(({ all_resources, resources } = {}) => {
      let { case_docs, exhibits, media } = resources || {};

      // Update case_docs checkboxes
      if (Array.isArray(case_docs) && case_docs.length > 0) {
        case_docs.forEach(({ title, data = [] }, index, arr) => {
          let section_letter = title.split('.')[0]; // A, B, D, E, F
          let targetTable = document.getElementById(`table_case_docs_${section_letter}`);
          if (targetTable && targetTable.querySelector("tbody tr") && (data || []).length > 0) {
            // Update Single Resource Checkboxes
            targetTable.querySelectorAll("tbody tr").forEach((el) => {
              let { id } = el.dataset || {};
              data.forEach((row) => {
                if (row.id === id && el.querySelector("td input[type='checkbox']")) {
                  el.querySelectorAll("td input[type='checkbox']").forEach((checkboxElement) => {
                    checkboxElement.checked = true;
                  });
                }
              });
            }); // End Update Single Resource Checkboxes 

            // Update Select All Resources Checkboxes 
            if (index === arr.length - 1 && Array.isArray(all_resources?.case_docs) && all_resources.case_docs.length > 0) {
              all_resources.case_docs.forEach((tabObj = {}) => {
                let allSectionLetter = tabObj?.title?.split('.')[0]; // A, B, D, E, F
                if (allSectionLetter === section_letter && targetTable.querySelector(`thead tr th input[name='select_all_case_docs_${section_letter}']`)) {
                  let selectAllCheckbox = (tabObj?.data || []).length === (data || []).length;
                  targetTable.querySelector(`thead tr th input[name='select_all_case_docs_${section_letter}']`).checked = selectAllCheckbox;
                }
              });
            } // End Update Select All Resources Checkboxes
          } // End of if (targetTable)
        }); // End of case_docs.forEach
      } // End of if (Array.isArray(case_docs) && case_docs.length > 0)

      // Update exhibits checkboxes
      if (Array.isArray(exhibits) && exhibits.length > 0) {
        exhibits.forEach(({ title, data = [] }, index, arr) => {
          let section_letter = title.split('.')[0]; // A, B, D, E, F
          let targetTable = document.getElementById(`table_exhibits_${section_letter}`);
          if (targetTable && targetTable.querySelector("tbody tr") && (data || []).length > 0) {
            // Update Single Resource Checkboxes
            targetTable.querySelectorAll("tbody tr").forEach((el) => {
              let { id } = el.dataset || {};
              data.forEach((row) => {
                if (row.id === id && el.querySelector("td input[type='checkbox']")) {
                  el.querySelectorAll("td input[type='checkbox']").forEach((checkboxElement) => {
                    checkboxElement.checked = true;
                  });
                }
              });
            }); // End Update Single Resource Checkboxes 

            // Update Select All Resources Checkboxes 
            if (index === arr.length - 1 && Array.isArray(all_resources?.exhibits) && all_resources?.exhibits.length > 0) {
              all_resources?.exhibits.forEach((tabObj = {}) => {
                let allSectionLetter = tabObj?.title?.split('.')[0]; // A, B, D, E, F
                if (allSectionLetter === section_letter && targetTable.querySelector(`thead tr th input[name='select_all_exhibits_${section_letter}']`)) {
                  let selectAllCheckbox = (tabObj?.data || []).length === (data || []).length;
                  targetTable.querySelector(`thead tr th input[name='select_all_exhibits_${section_letter}']`).checked = selectAllCheckbox;
                }
              });
            } // End Update Select All Resources Checkboxes
          } // End of if (targetTable)
        }); // End of exhibits.forEach
      } // End of if (Array.isArray(exhibits) && exhibits.length > 0)

      // Update media checkboxes
      if (Array.isArray(media) && media.length > 0) {
        media.forEach(({ title, data = [] }, index, arr) => {
          let section_letter = "M"
          let targetTable = document.getElementById(`table_media_${section_letter}`);
          if (targetTable && targetTable.querySelector("tbody tr") && (data || []).length > 0) {
            // Update Single Resource Checkboxes
            targetTable.querySelectorAll("tbody tr").forEach((el) => {
              let { id } = el.dataset || {};
              data.forEach((row) => {
                if (row.id === id && el.querySelector("td input[type='checkbox']")) {
                  el.querySelectorAll("td input[type='checkbox']").forEach((checkboxElement) => {
                    checkboxElement.checked = true;
                  });
                }
              });
            }); // End Update Single Resource Checkboxes 

            // Update Select All Resources Checkboxes 
            if (index === arr.length - 1 && Array.isArray(all_resources?.media) && all_resources?.media.length > 0) {
              all_resources?.media.forEach((tabObj = {}) => {
                if (tabObj?.title === title && targetTable.querySelector(`thead tr th input[name='select_all_media_${section_letter}']`)) {
                  let selectAllCheckbox = (tabObj?.data || []).length === (data || []).length;
                  targetTable.querySelector(`thead tr th input[name='select_all_media_${section_letter}']`).checked = selectAllCheckbox;
                }
              });
            } // End Update Select All Resources Checkboxes
          } // End of if (targetTable)
        }); // End of media.forEach
      } // End of if (Array.isArray(media) && media.length > 0)
    }); // End requestObject.socials.forEach
  } // END updateRequestTable

  // ===============[ updateResources ]==================================
  const updateResources = ({ tab, section, id, select_all_name } = {}, checked = true) => { // Step 3 - Update Resources
    if (select_all_name) { // select_all_case_docs_A
      section = select_all_name.split("_").pop(); // A, B, D, E, F
      tab = select_all_name.split("_").filter((x) => !['select', 'all', section].includes(x)).join("_"); // case_docs, exhibits, media
    }
    if (!(id || select_all_name) || !section || !tab) {
      console.log(`Unable to ${checked ? "add" : "remove"} resource. Missing required parameters. tab: ${tab}, section: ${section}, id: ${id}`);
      return;
    }
    if (!['case_docs', 'exhibits', 'media'].includes(tab)) {
      console.log(`Unable to ${checked ? "add" : "remove"} resource. Invalid tab: ${tab}`);
      return;
    }
    const SECTION_NAMES = {
      'A': 'A. Payment Documents/Decisions',
      'B': 'B. Jurisdictional Documents/Notices',
      'D': 'D. Non-Disability Development',
      'E': 'E. Disability Related Development',
      'F': 'F. Medical Records',
      'M': 'Media Files'
    };
    if (!Object.keys(SECTION_NAMES).includes(section)) {
      console.log(`Unable to ${checked ? "add" : "remove"} resource. Invalid section: ${section}`);
      return;
    }
    let targetTitle = SECTION_NAMES[section];

    let keyMap = { // { oldKey: newKey }
      pg: 'pages',
      "#": 'exhibit',
    };

    // Search for the target resource in the requestObject all_resources and update resources
    return setRequestObject((prevState) => {
      let newState = { ...prevState };
      newState.socials.forEach((social = {}) => {
        let { ssn, all_resources = {} } = social || {};
        if (ssn === enterSocialForm.ssn) {
          all_resources[tab].forEach(({ title, data: raw_data = [] }) => {
            let data = [...raw_data].reduce((acc, item) => {
              acc.push(renameKeys(keyMap, item));
              return acc;
            }, []);

            if (title === targetTitle) {
              // Handle bulk selection
              if (!id && select_all_name) {
                if (checked) { // Bulk Add Resource when checked == true
                  if (!social?.resources) {
                    social.resources = {
                      [tab]: [{
                        title,
                        data: [...data],
                      }]
                    };
                  } else if (!social.resources[tab]) {
                    social.resources[tab] = [{
                      title,
                      data: [...data],
                    }];
                  } else if (!social.resources[tab].find(({ title } = {}) => title === targetTitle)) {
                    social.resources[tab].push({
                      title,
                      data: [...data],
                    });
                  } else {
                    social.resources[tab].forEach((tabObj = {}) => {
                      if (tabObj.title === targetTitle) {
                        tabObj.data = [...data];
                      }
                    });
                  }
                } else { // Bulk Remove Resource when checked == false
                  if (social?.resources && social.resources[tab]) {
                    if (social.resources[tab].find(({ title } = {}) => title === targetTitle)) {
                      social.resources[tab].forEach((tabObj = {}, tab_index) => {
                        if (tabObj.title === targetTitle) {
                          social.resources[tab].splice(tab_index, 1);
                          if (!social.resources[tab].length) {
                            delete social.resources[tab]; // Clean up empty tabs
                          }
                        }
                      });
                    }
                  }
                }
              } else {
                // Handle Single selection
                data.forEach((doc) => {
                  if (doc.id === id) {
                    if (checked) { // Add Resource when checked == true
                      if (!social?.resources) {
                        social.resources = {
                          [tab]: [{
                            title,
                            data: [{ ...doc }]
                          }]
                        };
                      } else if (!social.resources[tab]) {
                        social.resources[tab] = [{
                          title,
                          data: [{ ...doc }]
                        }];
                      } else if (!social.resources[tab].find(({ title } = {}) => title === targetTitle)) {
                        social.resources[tab].push({
                          title,
                          data: [{ ...doc }]
                        });
                      } else {
                        social.resources[tab].forEach((tabObj = {}) => {
                          let { title, data: resourceData } = tabObj || {};
                          if (title === targetTitle) {
                            if (!resourceData.find((doc = {}) => doc.id === id)) {
                              tabObj.data.push({ ...doc });
                            } else {
                              console.log(`Unable to add resource. Resource already exists. tab: ${tab} title: ${targetTitle}, id: ${id}`);
                            }
                          }
                        });
                      }
                    } else { // Remove Resource when checked == false
                      if (social?.resources && social.resources[tab]) {
                        if (social.resources[tab].find(({ title } = {}) => title === targetTitle)) {
                          social.resources[tab].forEach((tabObj = {}) => {
                            let { title, data: resourceData } = tabObj || {};
                            if (title === targetTitle) {
                              resourceData.forEach((doc, resource_index) => {
                                if (doc.id === id) {
                                  tabObj.data.splice(resource_index, 1);
                                }
                              });
                            }
                          });
                        }
                      }

                      // Clean up empty tabs
                      if (social?.resources && social.resources[tab]) {
                        if (social.resources[tab].find(({ title } = {}) => title === targetTitle)) {
                          social.resources[tab].forEach((tabObj = {}, tab_index) => {
                            let { title, data: resourceData } = tabObj || {};
                            if (title === targetTitle) {
                              if (!resourceData.length) {
                                social.resources[tab].splice(tab_index, 1);
                              }
                              if (!social.resources[tab].length) {
                                delete social.resources[tab];
                              }
                            }
                          });
                        }
                      }
                    } // End !checked 
                  } // End if (doc.id === id)
                }); // End data.forEach(doc) from all_resources
              } // End Handle Single selection
            } // End if (title === targetTitle)
          }); // End all_resources[tab].forEach(({ title, data = [] })
        } // End if (ssn === enterSocialForm.ssn)
      }); // End socials.forEach((social = {}) 

      // Check if request object is ready to be submitted
      let valid = false;
      if (newState.resources && Object.keys(newState.resources).length > 0) {
        let { case_docs = [], exhibits = [], media = [] } = newState.resources || {};
        const case_docs_count = Array.isArray(case_docs) ? case_docs.reduce((acc, { data = [] } = {}) => acc + data.length, 0) : 0;
        const exhibited_count = Array.isArray(exhibits) ? exhibits.reduce((acc, { data = [] } = {}) => acc + data.length, 0) : 0;
        const media_count = Array.isArray(media) ? media.reduce((acc, { data = [] } = {}) => acc + data.length, 0) : 0;
        valid = (case_docs_count + exhibited_count + media_count) > 0;
      }
      setIsValid(valid);
      return newState;
    }); // End setRequestObject
  } // End updateResources

  // ===============[ handleRequestChanges ]==================================
  const handleRequestChanges = async (event) => {
    if (!event?.target) return;
    event.stopPropagation(); // Prevent other events from firing on click

    let error_count = 0;
    let alertOptions = {
      message: '',
      alert_type: "info"
    };

    let { target = {}, which, keyCode, key } = event || {};
    let { name, value, checked, disabled = false, type, id, dataset, tagName, innerText, href, parentElement, className, title } = target;
    let action = false;

    if (type !== 'checkbox') {
      event.preventDefault(); // Allows checkboxes to work as normal
    }

    // Set action if needed 
    if (type === "checkbox") {
      let nameArr = name.split("_");
      if (['select', 'all'].every((n) => nameArr.includes(n))) {
        action = name; // select_all_case_docs_<section_index>, select_all_exhibits_0, select_all_media_1
      } else if (downloadOptions.reduce((acc, { name } = {}) => [...acc, name], []).includes(name) === false) {
        action = "toggle_selected";
      }
    } else if (tagName === 'BUTTON' && innerText) {
      action = `${innerText}`.replace("✘", "").replace("✔", "").toLowerCase().trim();
    } else if (tagName === "TD") {
      action = "toggle_selected";
    }
    // console.log("handleRequestChanges target", { ...dataset, name, value, checked, type, tagName, className, innerText, which, keyCode, key, href, title, action });

    // Go up one level in the DOM but preserve innerText
    if (!['TD', 'BUTTON'].includes(tagName)) {
      if (parentElement?.tagName === "TD") {
        target = parentElement;
      } else {
        target = parentElement?.closest("td");
      }
      if (target) {
        parentElement = target?.parentElement;
        dataset = { ...dataset, ...target?.dataset };
        tagName = target?.tagName;
        if (!innerText && target?.innerText) {
          innerText = target?.innerText;
        }
      }
    }

    let rowObject = {
      innerText,
      tagName,
      ...(type && { type }),
      ...dataset,
      ...parentElement?.dataset,
    };

    // Extract row items and save to rowObject and selectedItem
    let selectedItem = {};
    if (tagName === "TD") {
      // Step 3 - Select Resource 
      let requestTableElement = parentElement.closest("table") || document.querySelector("table");
      let tdArray = Array.from(parentElement.querySelectorAll("td")).map(({ innerText }) => innerText).filter((i) => i) || [];
      let thArray = Array.from(requestTableElement.querySelectorAll("thead tr th")).map(({ innerText }) => dehumanize(innerText)).filter((i) => i) || [];

      if ('col' in rowObject) { // Extract selectedItem
        let col = Number(rowObject.col);
        if (col && innerText === tdArray[col]) {
          selectedItem[thArray[col]] = tdArray[col];
        }
      }

      // Match thArray length with tdArray length
      if (tdArray.length > thArray.length) {
        // slice extra elements to match thArray length
        tdArray = tdArray.slice(0, thArray.length);
      } else if (tdArray.length < thArray.length) {
        // Fill in the blanks with null values to match thArray length
        let startIndex = tdArray.length;
        tdArray.length = thArray.length;
        tdArray.fill(null, startIndex);
      }

      if (tdArray.length === thArray.length) {
        for (let i = 0; i < thArray.length; i++) {
          rowObject[thArray[i]] = tdArray[i];
        }
      }
    } else if (tagName === "INPUT") {
      if (name === "ssn") { // Step 1 - Enter Social 
        // Validate SSN 
        let isValidSSN = false;
        let formattedSSN = `${value}`.trim().replace(/\D/g, '').replace(/^(\d{3})/, '$1-').replace(/-(\d{2})/, '-$1-').replace(/(\d)-(\d{4}).*/, '$1-$2');
        if (/[A-Za-z]+/g.test(value)) { // Note: This should never happen because we stripped out all non-numeric characters above 
          error_count++;
          alertOptions.message = `Social Security Number contains letters. Only numbers are allowed.`;
          alertOptions.alert_type = "danger";

        } else if ((/[-]+/g.test(value) && value.length !== 11) || (/[-]+/g.test(value) === false && value.length !== 9)) {
          error_count++;
          alertOptions.message = `Social Security Number is only ${value.replace(/\D/g, '').length} characters and must be 9.`;
          alertOptions.alert_type = "warning";

        } else if (!!requestObject.socials.find(({ ssn }) => ssn === formattedSSN)) {
          error_count++;
          alertOptions.message = `Social Security Number is a duplicate and will not be added.`;
          alertOptions.alert_type = "danger";

        } else {
          error_count = 0;
          isValidSSN = true;
        }

        let newState = {
          isValidSSN,
          [name]: isValidSSN ? formattedSSN : value,
          label: isValidSSN ? "Social Security Number" : alertOptions.message
        };
        setEnterSocialForm((prevState) => ({ ...prevState, ...newState }));
        setIsValid(isValidSSN && enterSocialForm.buttonText === "Edit");
      } else {
        // Update Step 2 - Select Download Options 

        if (name === "DOWNLOAD_INCREMENTAL_DOWNLOADER") { // Step 2 - Select Options 
          // Limit Bottom Queue 
          let exceededLimit = (requestObject?.socials.length > user_settings.BOTTOM_QUEUE_LIMIT ?? 5);
          if (exceededLimit) {
            if (!disabled) {
              event.target.disabled = true;
            }
            if (checked) {
              checked = false;
              event.target.checked = checked;
            }
            if (parentElement?.querySelector('span') && !parentElement.querySelector('span').className.includes("text-muted")) {
              event.target.parentElement.querySelector('span').classList.add("text-muted");
            }
          } else {
            if (disabled) {
              event.target.disabled = false;
            }
            if (parentElement?.querySelector('span')?.className.includes("text-muted")) {
              event.target.classList.remove("text-muted");
            }
          }
        }

        setDownloadOptions(prevState => {
          let newState = [...prevState];
          newState.forEach(item => {
            if (item.name === name) {
              if (type === 'checkbox') {
                item.checked = checked;
              } else {
                item.value = value;
              }
            }
          });

          setIsValid(!!newState.filter(({ checked = false, name = '' }) => checked && !`${name}`.toUpperCase().includes('PDF_')).length > 0);
          return newState;
        });
      }
    }
    // Object.keys(rowObject).length > 0 && console.log("handleRequestChanges rowObject", rowObject);
    // Object.keys(selectedItem).length > 0 && console.log("selectedItem", selectedItem);
    // console.log(`action: ${action}`);

    let { row, select_all_name, id: resource_id, link, tab, section } = rowObject;
    if (action) {
      if (action === 'search') { // Step 1 - Enter Social
        let newFormState = { ...enterSocialForm, buttonText: "Searching" };
        setEnterSocialForm((prevState) => ({ ...prevState, ...newFormState }));
        let mediaFields = ['id', 'link', 'section', 'description', 'judge', 'hearing', 'receipt_date'];
        let resourcesFields = [...mediaFields, 'exhibit', 'source', 'date_from', 'date_to', 'decision_date', 'document_date', 'received', 'marked', 'pages'];
        let response;
        try {
          let params = {
            ssn: newFormState.ssn,
            loadJS: false,
          };
          if (bsoLoggedIn) {
            params.fields = resourcesFields.join(",");
            response = await downloader.readClaimantInfo(params);
          } else {
            params = {
              ...params,
              org_id,
              showDebug: false,
              decryptSSN: true,
              includeResources: true,
              scrapeClaimant: true,
              sectionData: true, // Returns Array of objects with title and data, instead of just data 
              deliverClaimantResults: false,
            };
            response = await downloader.getClaimant(0, params);

            if (!response?.data || isObject(response.data) && Object.keys(response.data).length === 0) {
              response = {
                status: 204,
                statusText: 'OK',
                data: {
                  status: "No Content",
                  details: "LOGIN",
                  message: "User must log in to search for a claimant."
                }
              }
            }
          }
        } catch (error) {
          console.log(error?.stack, error?.message ?? error);
          response = error?.response || {};
          if (!response?.data) {
            response.data = { err: 401, details: `${ex.message}`, error: error.error };
          }
        } finally {
          let { status, statusText = '', data = {} } = response || {};
          let { err, error, details, message, resources = {}, claimant = {}, ...remainingData } = data;
          claimant = { ...claimant, ...remainingData };

          newFormState.buttonText = "Edit";
          newFormState.isValidSSN = status < 399;
          if (status !== 200) {
            newFormState.label = `[${status ?? err}] ${statusText} `;
            newFormState.label += data?.status ? `${data.status} ` : ""; // ERROR 
            newFormState.label += error ? `${error} ` : ""; // ScrapeError Message + Controller Error 
            newFormState.label += (typeof response?.data === 'string') ? response.data : message ?? (details ? `${details} ` : "") + "Social Security Number failed to read from BSO.";
          } else {
            newFormState.label = "Social Security Number";
          }

          let socialObj = {
            ssn: newFormState.ssn, // Original SSN entered 
            convert_to_pdf: true,
            Download_Type: "Custom",
            Status: (claimant?.ssn && resources) ? "Ready" : "Error",
            claimant: {},
            resources: {}, // Selected resources to be downloaded 
            all_resources: {},  // All available resources to download
          };

          // Update Claimant Info
          if (claimant?.ssn) {
            let renamedFields = {
              name: 'claimant_name',
            };
            let claimantFields = ['claimant_id', 'matter_id', 'auth_id', 'ssn', 'claimant_name', 'level', 'application', 'claim_type', 'last_insured', 'last_changed', 'alleged_onset', 'last_captured'];
            socialObj.claimant = (!isObject(claimant) ? [{}] : massageData(claimant, claimantFields, renamedFields))[0];
            if (claimant.ssn !== newFormState.ssn) { // Use Scraped SSN if it does not match entered SSN
              socialObj.ssn = newFormState.ssn = claimant.ssn;
            }
          }

          // Update Claimant Resources in socialObj
          if (resources && Object.keys(resources).length > 0) {
            let { case_docs = [], exhibits = [], media = [] } = resources;
            if (case_docs.length > 0) {
              socialObj.all_resources.case_docs = (case_docs || []).reduce((acc, { title = '', data = [] } = {}) => {
                if (title && data.length > 0) {
                  acc.push({ title, data: massageData(data, resourcesFields) });
                }
                return acc;
              }, []);
            }
            if (exhibits.length > 0) {
              socialObj.all_resources.exhibits = (exhibits || []).reduce((acc, { title = '', data = [] } = {}) => {
                if (title && data.length > 0) {
                  acc.push({ title, data: massageData(data, resourcesFields) });
                }
                return acc;
              }, []);
            }
            if (media.length > 0) {
              socialObj.all_resources.media = (media || []).reduce((acc, { title = '', data = [] } = {}) => {
                if (title && data.length > 0) {
                  acc.push({ title, data: massageData(data, mediaFields) });
                }
                return acc;
              }, []);
            }
          }

          // Update Request Object 
          let newReqObj = { ...requestObject };
          newReqObj.info.request_type_id = bsoLoggedIn ? 1 : 2;
          newReqObj.socials = [socialObj];
          setRequestObject(newReqObj);
          setEnterSocialForm((prevState) => ({ ...prevState, ...newFormState }));
          setIsValid(newFormState.isValidSSN && newFormState.buttonText === "Edit");
        }
      } else if (action === 'edit') { // Step 1 - Edit Social
        setEnterSocialForm((prevState) => ({ ...prevState, ...{ buttonText: "Search" } }));
        setIsValid(false); // Only valid when buttonText is "Edit" and isValidSSN is true
      } else if (['select', 'all'].every((n) => action.split("_").includes(n))) { // select_all_case_docs_<section_letter>, select_all_exhibits_A, select_all_media_M
        // Step 3 - Select All  
        updateResources({ select_all_name: select_all_name || action }, !!checked);
        let requestTableElement = parentElement.closest("table") || document.querySelector("table");
        if (requestTableElement) {
          requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']").forEach((el) => {
            el.checked = !!checked
          });
        }
      } else if (action === "toggle_selected") {
        // Step 3 - Toggle Selected 
        let updateResourceParams = { id: resource_id, tab, section };
        let updateResourceChecked = !!checked;
        let requestTableElement = parentElement.closest("table") || document.querySelector("table");
        row = Number(row);
        if (isNaN(row) && requestTableElement) {
          row = Array.from(requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']"))
            .reduce((acc, { dataset }, index) => {
              if (dataset?.id === resource_id || dataset?.select_all_name === select_all_name || dataset?.link === link) {
                acc = index
              }
              return acc;
            }, null);
        }

        if (!isNaN(row) && requestTableElement) {
          if (type !== "checkbox") {
            requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']").forEach((el, index) => {
              if (index === row) {
                el.checked = updateResourceChecked = !el.checked; // Toggle Checked State
              }
            });
          }

          // Click the "Select All" checkbox if all SSNs are selected
          if (requestTableElement.querySelector(`thead tr th input[name='${select_all_name}']`)) {
            let selectAllPreviousState = !!requestTableElement.querySelector(`thead tr th input[name='${select_all_name}']`)?.checked;
            let selectAllNewState = Array.from(requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']")).length === Array.from(requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']"))
              .map((el) => el?.checked).filter(Boolean).length;

            if (selectAllPreviousState !== selectAllNewState) {
              updateResourceParams.select_all_name = select_all_name || action;
              requestTableElement.querySelector(`thead tr th input[name='${select_all_name}']`).checked = updateResourceChecked = selectAllNewState;
            }
          }
        }

        updateResources(updateResourceParams, updateResourceChecked);
      } else {
        error_count++;
        alertOptions.message = `Unknown Action ${action}`;
        alertOptions.alert_type = "info";
      }
    } else if (isValid && error_count === 0 && select_all_name) {
      // Step 3 - Click the "Select All" checkbox if all resources are selected
      let requestTableElement = parentElement.closest("table") || document.querySelector("table");
      if (requestTableElement && requestTableElement.querySelector(`thead tr th input[name='${select_all_name}']`)) {
        let selectAllPreviousState = !!requestTableElement.querySelector(`thead tr th input[name='${select_all_name}']`)?.checked;
        let selectAllNewState = Array.from(requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']")).length === Array.from(requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']"))
          .map((el) => el?.checked).filter(Boolean).length;

        if (selectAllPreviousState !== selectAllNewState) {
          requestTableElement.querySelector(`thead tr th input[name='${select_all_name}']`).checked = selectAllNewState;
          updateResources({ select_all_name: select_all_name || action }, selectAllNewState);
        }
      }
    }

    setShowAlert(error_count > 0);
    if (error_count > 0) {
      alertOptions.message = `${error_count} error${error_count > 1 ? 's' : ''}: ${alertOptions?.message}`;
      setPageAlert(prevState => ({ ...prevState, ...alertOptions }));
    }
    return;
  }; // END handleRequestChanges

  const stepValidation = () => _isValid;
  const steps = [
    {
      label: 'Enter Socials',
      name: 'step 1',
      validator: stepValidation,
    },
    {
      label: 'Select Download Options',
      name: 'step 2',
      validator: stepValidation,
    },
    {
      label: 'Select Files',
      name: 'step 3',
      validator: stepValidation,
    },
    {
      label: 'Review and Submit',
      name: 'step 4',
      validator: stepValidation,
    }
  ];

  // ===============[ handleSubmitRequest ]==================================
  const handleSubmitRequest = async () => {
    // Updating newOptions to ensure it matches on all socials with step 1 - Select Download Options 
    let pdf_settings = downloadOptions.reduce((acc, { name, checked, value }) => ({
      ...acc,
      ...(`${name}`.toUpperCase().includes('PDF_') && { [`${name}`.toLowerCase().replace("pdf_", '')]: checked ?? value })
    }), {});

    if (pdf_settings.pdf_title) {
      pdf_settings.title = pdf_settings.pdf_title;
    }
    delete pdf_settings.pdf_title; // Remove pdf_title from pdf_settings

    let newOptions = downloadOptions.reduce((acc, { name, checked, value }) => ({
      ...acc,
      ...((`${name}`.toLowerCase().replace("pdf_", '') in pdf_settings === false) && { [name]: checked ?? value })
    }), { pdf_settings });

    // Prepare Request Object 
    let keyMap = {
      claimant_name: 'name',
    };
    let reqObj = { ...requestObject };
    reqObj.socials.forEach(ssn => {
      let { case_docs = [], exhibits = [], media = [] } = ssn?.resources || {};
      const case_docs_count = Array.isArray(case_docs) ? case_docs.reduce((acc, { data = [] } = {}) => acc + data.length, 0) : 0;
      const exhibited_count = Array.isArray(exhibits) ? exhibits.reduce((acc, { data = [] } = {}) => acc + data.length, 0) : 0;
      const media_count = Array.isArray(media) ? media.reduce((acc, { data = [] } = {}) => acc + data.length, 0) : 0;
      newOptions.DOWNLOAD_UNEXHIBITED = case_docs_count > 0;
      newOptions.DOWNLOAD_UNEXHIBITED_FALLBACK = false;
      newOptions.DOWNLOAD_EXHIBITED = exhibited_count > 0;
      newOptions.DOWNLOAD_AUDIO = media_count > 0;
      ssn.newOptions = { ...newOptions };
      delete ssn.all_resources; // Remove all_resources from request object
      ssn.claimant = renameKeys(keyMap, ssn.claimant);
    });

    reqObj.info = {
      request_type_id: bsoLoggedIn ? 1 : 2,
      defaults: newOptions,
      start_time: Date.now(),
    };

    // Update Bottom Queue Option 
    let { checked: USE_BOTTOM_QUEUE_OPTION = false } = downloadOptions.find(({ name }) => name === "DOWNLOAD_INCREMENTAL_DOWNLOADER") || {};
    reqObj.USE_BOTTOM_QUEUE_OPTION = USE_BOTTOM_QUEUE_OPTION;
    setRequestObject(reqObj); // Saving the request object to the state but we'll still use reqObj in case state is still being updated when we're ready to submit the request 

    // Validate Steps and BSO Login if needed
    let alertOptions = {
      message: "",
      alert_type: "info"
    };

    let requiredFields = ['DOWNLOAD_UNEXHIBITED', 'DOWNLOAD_EXHIBITED', 'DOWNLOAD_AUDIO']; // At least one of these must be true 
    let testParams = Object.entries({ ...reqObj.info.defaults }).filter(([k, v]) => v).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
    let valid = validParams(testParams, requiredFields, 'any');
    if (!valid) {
      alertOptions.message = `Must add at least 1 document from one of the tabs Case Documents, Exhibit List, or Media Files. (${requiredFields.filter((f) => !testParams[f]).join(', ')})`;
      alertOptions.alert_type = "warning";
      setPageAlert(prevState => ({ ...prevState, ...alertOptions }));
      setIsValid(valid);
      setShowAlert(!valid);
    } else if (!isValid) {
      alertOptions.message = "Please complete the form before submitting";
      alertOptions.alert_type = "warning";
      setPageAlert(prevState => ({ ...prevState, ...alertOptions }));
      setShowAlert(true);
      return;
    }
    setIsValid(false); // Reset Validation
    setShowAlert(false);

    // Validate cookies before trying to submit the request type 1 
    if (reqObj.info.request_type_id === 1) {
      if (server_autologin) {
        let current_user_session = { data: null };
        try {
          current_user_session = await bso.tryAutoLogin(); // Should return the same data ase API.getUserSession() if successful 
          isAuthenticated = !!current_user_session?.data?.logged_in;
        } catch (error) {
          isAuthenticated = false;
          if (error?.message) {
            console.log(error.message);
          } else {
            console.log(error?.stack, error);
          }
          alertOptions.message = `BSO Auto Login Failed! ${error.message ?? error}`;
          alertOptions.alert_type = "info";
          setPageAlert(prevState => ({ ...prevState, ...alertOptions }));
          setShowAlert(true);
        }

        if (userData.isAuthenticated !== isAuthenticated) {
          setUserData(prevState => ({
            ...prevState,
            ...current_user_session?.data,
            awaitingOTP: false,
            isAuthenticated
          }));
        }

        if (!isAuthenticated) {
          return; // Exit if not authenticated
        }
      }
      else if (client_autologin) {
        try {
          const { status, statusText, data } = await bso.checkCookies();
          console.log(`Status=${status} statusText=${statusText} ${(data && Array.isArray(data)) ? data.length + " entries read on pickup page." : ""}`);
          bsoLoggedIn = !!(data || false);
        } catch (error) {
          bsoLoggedIn = false;
          if (error?.message) {
            console.log(error.message);
          } else {
            console.log(error?.stack, error);
          }
          alertOptions.message = `Invalid Cookies! ${error.message ?? error}`;
          alertOptions.alert_type = "info";
          setPageAlert(prevState => ({ ...prevState, ...alertOptions }));
          setShowAlert(true);
        }

        if (userData.bsoLoggedIn !== bsoLoggedIn) {
          setUserData(prevState => ({ ...prevState, bsoLoggedIn }));
        }
        if (!bsoLoggedIn) {
          return; // Exit if not logged into BSO 
        }
      } else {
        alertOptions.message = 'Your account is not setup correctly to submit a request! Please update user settings to enable "Allow Server Auto Login" or "Client Auto Login".';
        alertOptions.alert_type = "warning";
        setPageAlert(prevState => ({ ...prevState, ...alertOptions }));
        setShowAlert(true);
        return;
      }
    } else if (!server_autologin) { // and assuming reqObj.info.request_type_id === 2 
      alertOptions.message = 'Your account is not setup correctly to submit a request! Please update user settings to enable "Allow Server Auto Login" or "Client Auto Login".';
      alertOptions.alert_type = "warning";
      setPageAlert(prevState => ({ ...prevState, ...alertOptions }));
      setShowAlert(true);
      return;
    }

    console.log(`handleSubmitRequest - Processing Request for ${requestObject.socials.length} socials`);
    let processRequestResponse = await downloader.processRequest(reqObj);
    if (processRequestResponse === undefined || processRequestResponse.status !== 200) {
      alertOptions.alert_type = "danger";
      alertOptions.message = processRequestResponse.data.message ? processRequestResponse.data.message : "Was not able to process your request!";
      alertOptions.message += ` Error: ${processRequestResponse.status} - ${processRequestResponse.statusText}`;
      console.log(processRequestResponse.data, alertOptions.message);
      setPageAlert(prevState => ({ ...prevState, ...alertOptions }));
      setShowAlert(true);
      return;
    }

    alertOptions.alert_type = "success";
    alertOptions.message = "Success! Processing Request";
    let { request_id, status, request_queue_batch, USE_BOTTOM_QUEUE = USE_BOTTOM_QUEUE_OPTION } = processRequestResponse?.data || {};
    let updatedUserData = {
      USE_BOTTOM_QUEUE_OPTION: USE_BOTTOM_QUEUE,
      ...(request_id && { request_id }),
      ...(status && { status }),
    };

    // Force user to login for new set of cookies when status is 'DOWNLOADING_FILES' or 'INITIATING'
    if (!status || ['DOWNLOADING_FILES', 'INITIATING'].includes(status)) {
      updatedUserData = { ...updatedUserData, bsoLoggedIn: false, AuthT: null, awaitingOTP: false, twofactor_iat: null, text_number: null };
    }

    // Update State 
    setUserData(prevState => ({ ...prevState, ...updatedUserData }));
    setRequestObject({ info: { request_type_id: 2 }, socials: [] });
    setPageAlert(prevState => ({ ...prevState, ...alertOptions }));
    setShowAlert(true);

    // Navigate Away from this page 
    if (request_id) {
      navigate(`/download-request-details/${request_id}`);
    } else {
      navigate(`/download-requests`);
    }
    return;
  }; // END handleSubmitRequest

  // ===============[ useEffect ]==================================
  useEffect(() => {
    // Set Download Options 
    let mounted = true;

    // Set Event Listeners 
    const setEventListeners = () => {
      const activeStepClasses = ['_35Ago', '_1CcaK']; // _35Ago = active, _1CcaK = error
      if (document.querySelector('li.step-progress')) {
        document.querySelectorAll('li.step-progress').forEach(stepBubble => {
          stepBubble.addEventListener('click', (event) => {
            if (!event?.target) return;
            let clickedElement = event?.target || {};
            let { className, tagName, type, innerText, parentElement } = clickedElement;
            // console.log({ className, tagName, type, innerText, parentElement });

            // Get the active step by activeStepClasses
            let step = 1;
            if (document.querySelector('li.step-progress')) {
              step = Array.from(document.querySelectorAll('li.step-progress')).reduce((acc, { className }, index) => {
                if (className.split(' ').filter((item) => activeStepClasses.includes(item)).length > 0) {
                  return index + 1;
                }
                return acc;
              }, step);
            }

            // Get the active step by clicked element
            if (['svg', 'path'].includes(tagName)) {
              innerText = event?.target.closest('li.step-progress') && event?.target.closest('li.step-progress').innerText;
            } else if ((tagName !== 'SPAN' || innerText?.includes('!')) && parentElement?.tagName === 'LI' && parentElement?.className.split(' ').includes('step-progress')) {
              innerText = parentElement.querySelector('span') && parentElement.querySelector('span').innerText;
              if (innerText && !isNaN(Number(innerText.trim()))) {
                tagName = 'SPAN';
              } else {
                innerText = parentElement.querySelector('div') && parentElement.querySelector('div').innerText;
                tagName = parentElement.querySelector('div') ? 'DIV' : tagName;
              }
            }
            if (tagName === 'SPAN' && innerText && !isNaN(Number(innerText.trim()))) {
              step = Number(innerText);
            }
            toggleAccordionSlide.call({ step, innerText }, event);
          });
        })
      }
    } // END setEventListeners

    if (mounted) {
      let inList = ['PDF', 'DOWNLOAD'];
      let notInList = ['PDF_COMBINE_PDF', 'DOWNLOAD_AUDIO', 'DOWNLOAD_UNEXHIBITED', 'DOWNLOAD_UNEXHIBITED_FALLBACK', 'DOWNLOAD_EXHIBITED']; // These are not needed for custom 
      let defaultOptions = [{
        name: "PDF_TITLE",
        label: "Pdf Title",
        value: "",
        disabled: false,
      }];
      let options = (isObject(user_settings) && Object.keys(user_settings).length > 0) ?
        Object.entries(user_settings).filter(([k, v]) => inList.some((item) => `${k}`.toUpperCase().includes(item)) && !notInList.includes(`${k}`.toUpperCase()))
          .reduce((acc, [name, value]) => {
            let item = {
              name,
              label: (humanize(name.replace(/pdf/gi, "").replace(/download/i, ""))).trim(),
              ...((['true', 'false'].includes(value) || typeof value === 'boolean') ? { checked: typeof value === 'boolean' ? value : value === 'true' } : { value }),
              ...(name === "DOWNLOAD_INCREMENTAL_DOWNLOADER" && {
                checked: (Number(user_settings.BOTTOM_QUEUE_LIMIT) === 0) || (!bsoLoggedIn && !server_autologin && !client_autologin) ? false : (typeof value === 'boolean' ? value : value === 'true'),
                disabled: (Number(user_settings.BOTTOM_QUEUE_LIMIT) === 0),
                title: `The incremental downloader option allows claimant records to be downloaded individually and is particularly useful when full processing from the BSO is slow. There can be no more than ${user_settings.BOTTOM_QUEUE_LIMIT ?? 5} request socials to enable this option.`
              })
            };

            if (item?.value) {
              acc.push(item);
            } else {
              acc.unshift(item);
            }
            return acc;
          }, defaultOptions) : defaultOptions;

      if (options.length > 0) {
        if (!options[0].name.includes('SETTINGS')) {
          options = options.reverse();
        }
        setDownloadOptions(options);
      }

      setEventListeners();
    }
    return () => mounted = false
  }, []);

  // activeStep useEffect
  useEffect(() => {
    let mounted = true;
    const bubbleStepUpdater = async (newStep) => {
      const previousButton = document.querySelector('._3CDiP.secondary-button');
      const nextButton = document.querySelector('._hsN1w.primary-button');
      const activeStepClasses = ['_35Ago', '_1CcaK']; // _35Ago = active, _1CcaK = error
      let currentStep = 1;
      let _isValid_ = true;

      if (document.querySelector('li.step-progress')) {
        currentStep = Array.from(document.querySelectorAll('li.step-progress')).reduce((acc, { className }, index) => {
          if (className.split(' ').filter((item) => activeStepClasses.includes(item)).length > 0) {
            return index + 1;
          }
          return acc;
        }, currentStep);
      }

      // Validate steps stopping at the current step 
      let clicks = Math.abs(newStep - currentStep);
      if (newStep > currentStep && nextButton) { // Going up 2 > 1 = true
        for (let i = 0; i < clicks; i++) {
          // Validate Step 1
          _isValid_ = enterSocialForm.isValidSSN && enterSocialForm.buttonText === 'Edit';

          if (_isValid_ && (i > 0 || newStep > 2 && clicks > 0)) {
            _isValid_ = true; // Step 2 is always valid on a custom request 
          }

          if (_isValid_ && (i > 1 || newStep > 3 && clicks > 0)) { // Validate Step 3
            if (Array.isArray(requestObject.socials) && requestObject.socials.length > 0 && Object.keys(requestObject.socials[0]?.resources).length > 0) {
              requestObject.socials.forEach(({ resources = {} } = {}) => {
                let { case_docs = [], exhibits = [], media = [] } = resources || {};
                const case_docs_count = Array.isArray(case_docs) ? case_docs.reduce((acc, { data = [] } = {}) => acc + data.length, 0) : 0;
                const exhibited_count = Array.isArray(exhibits) ? exhibits.reduce((acc, { data = [] } = {}) => acc + data.length, 0) : 0;
                const media_count = Array.isArray(media) ? media.reduce((acc, { data = [] } = {}) => acc + data.length, 0) : 0;
                _isValid_ = (case_docs_count + exhibited_count + media_count) > 0;
              });
            } else {
              _isValid_ = false;
            }
          }

          // Update the global state asap so we don't move on to the next step when the current step is invalid 
          _isValid = _isValid_;

          if (i > 0) {
            await sleep(0.3);
          }
          nextButton.click();
        }
      } else if (newStep < currentStep && previousButton) { // Going down 1 < 2 = true
        for (let i = 0; i < clicks; i++) {
          // Validate Step 1
          _isValid_ = enterSocialForm.isValidSSN && enterSocialForm.buttonText === 'Edit';

          if (_isValid_ && (i > 0 || newStep > 2 && clicks > 0)) {
            _isValid_ = true; // Step 2 is always valid on a custom request 
          }

          if (_isValid_ && (i > 1 || newStep > 3 && clicks > 0)) { // Validate Step 3
            if (Array.isArray(requestObject.socials) && requestObject.socials.length > 0 && Object.keys(requestObject.socials[0]?.resources).length > 0) {
              requestObject.socials.forEach(({ resources = {} } = {}) => {
                let { case_docs = [], exhibits = [], media = [] } = resources || {};
                const case_docs_count = Array.isArray(case_docs) ? case_docs.reduce((acc, { data = [] } = {}) => acc + data.length, 0) : 0;
                const exhibited_count = Array.isArray(exhibits) ? exhibits.reduce((acc, { data = [] } = {}) => acc + data.length, 0) : 0;
                const media_count = Array.isArray(media) ? media.reduce((acc, { data = [] } = {}) => acc + data.length, 0) : 0;
                _isValid_ = (case_docs_count + exhibited_count + media_count) > 0;
              });
            } else {
              _isValid_ = false;
            }
          }

          // Update the global state asap so we don't move on to the next step when the current step is invalid 
          _isValid = _isValid_;

          if (i > 0) {
            await sleep(0.3);
          }
          previousButton.click();
        }
      }
      return _isValid_;
    } // END bubbleStepUpdater

    bubbleStepUpdater(activeStep)
      .then((success) => {
        if (mounted) {
          setIsValid(success);
        }
      });

    return () => mounted = false
  }, [activeStep]);

  // useEffect tabIndex 
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      updateRequestTable();
    }
    return () => mounted = false
  }, [tabIndex]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      _isValid = isValid;
    }
    return () => mounted = false
  }, [isValid]);

  return (<div className="page-container">
    <h4>Custom Download Request</h4>
    <hr />
    <Alert
      dismissible
      onClose={() => setShowAlert(false)}
      show={showAlert}
      variant={pageAlert.alert_type}
    >{pageAlert.message}</Alert>

    <StepProgressBar
      startingStep={activeStep > 1 && activeStep - 1 || 0}
      onSubmit={handleSubmitRequest}
      buttonWrapperClass="d-none"
      stepClass="step-progress"
      primaryBtnClass="primary-button"
      secondaryBtnClass="secondary-button"
      steps={steps}
    />

    <StyledAccordionDownloader defaultActiveKey={[`${activeStep - 1}`]} alwaysopen="true" flush>
      <Accordion.Item eventKey='0'>
        <Accordion.Header ref={enterSocialStep} onClick={(e) => (setActiveStep(1))}>Step 1 - Enter Social</Accordion.Header>
        <Accordion.Body>
          <Form noValidate className="row">
            <Form.Group className="col-xl-4 col-lg-9 col-sm-12 pe-0 mb-3">
              <Form.Label className={`ps-0 ${enterSocialForm.isValidSSN ? "text-success" : "text-danger"}`}>{(enterSocialForm.isValidSSN ?
                "✔ " : "✘ ")}{enterSocialForm.label}</Form.Label>
              <InputGroup>
                <Form.Control id="enter_ssn" name="ssn" onChange={handleRequestChanges} value={enterSocialForm.ssn} autoComplete="off" required {...(["Edit", "Searching"].includes(enterSocialForm.buttonText)) && { disabled: true }} />
                <Button variant="primary" id="search-ssn" onClick={handleRequestChanges} {...(enterSocialForm.buttonText === "Searching") && { disabled: true }}>{(enterSocialForm.buttonText === "Edit") ?
                  (enterSocialForm.isValidSSN ? "✔ " : "✘ ") : ""}{enterSocialForm.buttonText}</Button>
              </InputGroup>
            </Form.Group>
            <Form.Group className="col-xl-8 col-lg-3 col-sm-12 text-end pe-0">
              <Button className="primary-button" onClick={toggleAccordionSlide}>Select Options &gt;</Button>
            </Form.Group>
          </Form>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey='1'>
        <Accordion.Header ref={selectOptionsStep} onClick={(e) => (setActiveStep(2))}> Step 2 - Select Options</Accordion.Header>
        <Accordion.Body>
          <Form noValidate className="row">
            <Form.Group className="col-12 text-end pe-0 mb-3">
              <Button className="primary-button" onClick={toggleAccordionSlide} >Select Files &gt;</Button>
            </Form.Group>
            <Alert variant="warning" className="col-12 p-2">Select the download options you would like to apply to your download request.</Alert>

            {(Array.isArray(downloadOptions) && downloadOptions.length > 0) && (<Form.Group className="row" controlId="downloadOptionsCheckbox" >
              {splitArrayChunks(downloadOptions, 3).map((chunk, idx, arr) => (<Col key={`chunk-${idx}`}>
                <StyledListNoBorder key={`list-${idx}`} >{chunk.map(({ name, label, checked = false, value, disabled = false, title }, index) => {
                  if (value || name === "PDF_TITLE") {
                    return (<div className="list-group-item d-flex" key={`${idx}-${index}`}>
                      <Form.Label className="me-2 mt-2" {...(title) && { title }} {...(disabled) && { className: "text-muted" }}>{label}</Form.Label>
                      <Form.Control key={`${name}-${index}`} className="w-auto" type="text" name={name} value={value} data-index={index} onChange={handleRequestChanges} {...(disabled) && { disabled }} />
                    </div>);
                  }
                  return (<StyledGreenCheckbox key={`${idx}-${index}`} className="list-group-item">
                    <Form.Control key={`${name}-${index}`} className="me-2" type="checkbox" name={name} checked={checked} data-index={index} onChange={handleRequestChanges} {...(disabled) && { disabled }} />
                    <span {...(title) && { title }} {...(disabled) ? { className: "text-muted ms-2" } : { className: "ms-2" }} >{label}</span>
                  </StyledGreenCheckbox>);
                })}</StyledListNoBorder>
              </Col>))}
            </Form.Group>)}
          </Form>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey='2'>
        <Accordion.Header ref={selectFilesStep} onClick={(e) => (setActiveStep(3))}> Step 3 - Select Files</Accordion.Header>
        <Accordion.Body>
          <Form noValidate className="row">
            <Form.Group className="col-12 pe-0 mb-3 d-flex justify-content-between">
              <h5 className="text-primary bold-text">ELECTRONIC FOLDER</h5>
              <Button className="primary-button" onClick={toggleAccordionSlide} >Review & Submit &gt;</Button>
            </Form.Group>

            {Array.isArray(requestObject.socials) && requestObject.socials.length > 0 && requestObject.socials.map((socialObj = {}, index) => {
              let { claimant: claimantDetails = {}, all_resources = {} } = socialObj || {};

              // All Resources 
              let { case_docs = [], exhibits = [], media = [] } = all_resources || {};
              const case_docs_count = Array.isArray(case_docs) ? case_docs.reduce((acc, { data = [] } = {}) => acc + data.length, 0) : 0;
              const exhibited_count = Array.isArray(exhibits) ? exhibits.reduce((acc, { data = [] } = {}) => acc + data.length, 0) : 0;
              const media_count = Array.isArray(media) ? media.reduce((acc, { data = [] } = {}) => acc + data.length, 0) : 0;

              const skipItem = ['claimant_id', 'matter_id', 'auth_id', 'last_captured'];
              claimantDetails = Object.keys(claimantDetails).reduce((acc, key) => {
                if (!skipItem.includes(key)) {
                  acc[key] = claimantDetails[key];
                }
                return acc;
              }, {});

              return (<Form.Group controlId="selectFiles" key={`select_files_${index}`}>
                <Row>{isObject(claimantDetails) && Object.keys(claimantDetails).length > 0 && splitArrayChunks(Object.entries(claimantDetails), 3).map((chunk, idx) => (<Col key={`chunk-${idx}`}>
                  <StyledListNoBorder>{chunk.map(([key, value], index) => (
                    <ListGroup.Item key={`${index}-${key}`}>
                      <strong>{key.charAt(key.length - 1) === key.charAt(key.length - 1).toLowerCase() ? humanize(key) : key}: </strong>{typeof value !== 'string' ? humanize(value) : value}
                    </ListGroup.Item>
                  ))}</StyledListNoBorder>
                </Col>))}</Row>

                <Tabs key="tabs" className="mt-3" selectedIndex={tabIndex} onSelect={(tabIndex) => setTabIndex(tabIndex)} >
                  <TabList>
                    <Tab key="tab-1" >
                      <h5 {...(tabIndex === 0 && { className: "text-primary bold-text" })}>Case Documents {`(${case_docs_count})`}</h5>
                    </Tab>
                    <Tab key="tab-2" >
                      <h5 {...(tabIndex === 1 && { className: "text-primary bold-text" })}>Exhibit List {`(${exhibited_count})`}</h5>
                    </Tab>
                    <Tab key="tab-3" >
                      <h5 {...(tabIndex === 2 && { className: "text-primary bold-text" })}>Multimedia Files {`(${media_count})`}</h5>
                    </Tab>
                  </TabList>

                  <TabPanel key="tab-1-case_docs">
                    {Array.isArray(case_docs) && case_docs.length > 0 && case_docs.map(({ title, data = [] } = {}, section_index) => {
                      let section_letter = title.split(".")[0];

                      // These fields will be skipped if there is no value 
                      let possiblySkipFieldsCounter = {
                        source: 0,
                        date_from: 0,
                        date_to: 0,
                        decision_date: 0,
                        document_date: 0,
                        received: 0,
                        marked: 0
                      };
                      let mediaFields = ["judge", "hearing", "receipt_date"];
                      let skipFields = ["id", "link", "section", ...mediaFields];
                      let tableFields = ["#", "description", ...(Object.keys(possiblySkipFieldsCounter)), "pg", ...skipFields];
                      let renamedFields = {
                        pages: "pg",
                        exhibit: "#",
                      };
                      let tableRows = data.reduce((acc, row = {}) => {
                        let newRow = tableFields.reduce((a, field) => {
                          if (field in row) {
                            a[field] = row[field];
                          } else if (Object.values(renamedFields).includes(field)) {
                            let renamedIndex = Object.values(renamedFields).indexOf(field);
                            let oldField = Object.keys(renamedFields)[renamedIndex];
                            if (row[oldField]) {
                              row[field] = row[oldField]; // rename the field
                              delete row[oldField]; // delete the old field
                              a[field] = row[field];
                            }
                          }

                          if (a[field] && field in possiblySkipFieldsCounter) {
                            possiblySkipFieldsCounter[field]++;
                          }

                          if (field === "section" && row[field] && section_letter !== row[field]) {
                            section_letter = row[field];
                          }

                          return a;
                        }, {});

                        if (Object.keys(newRow).length) {
                          acc.push(newRow);
                        }
                        return acc;
                      }, []);

                      // Update skipFields
                      Object.entries(possiblySkipFieldsCounter).forEach(([field, count]) => {
                        if (count === 0) {
                          skipFields.push(field);
                        }
                      });

                      return (<div key={`case_docs-${title}-${section_index}`} {...(section_index > 0 && { className: "mt-4" })}>
                        <h6>{title}</h6>
                        <StyledCustomDownloadTable id={`table_case_docs_${section_letter}`} hover>
                          <thead>
                            <tr>
                              <th key="0-select_all">
                                <StyledGreenCheckbox>
                                  <Form.Control
                                    key={`select_all_case_docs_${section_letter}`}
                                    name={`select_all_case_docs_${section_letter}`}
                                    className="me-2"
                                    type="checkbox"
                                    title={`Select All ${title}`}
                                    onChange={handleRequestChanges}
                                    defaultChecked={false}
                                  />
                                </StyledGreenCheckbox>
                              </th>
                              {tableFields.filter((k) => !skipFields.includes(k)).map((header, th_index) =>
                              (<th key={`${th_index + 1}-${header}`} >
                                {(header.includes("_") || header.charAt(header.length - 1) === header.charAt(header.length - 1).toLowerCase()) ? (humanize(header)) : header}
                              </th>))}
                            </tr>
                          </thead>
                          <tbody>{Array.isArray(tableRows) && tableRows.length > 0 && tableRows.map((row = {}, tr_index) =>
                          (<tr
                            onClick={handleRequestChanges}
                            key={`case_docs-${title}-${section_index}-${tr_index}`}
                            data-row={tr_index}
                            data-link={row?.link}
                            data-id={row?.id}
                            data-section={row?.section}
                            data-tab="case_docs"
                            data-select_all_name={`select_all_case_docs_${section_letter}`}
                          >
                            <td key="0-checkbox" width={45} data-col={0}>
                              <StyledGreenCheckbox>
                                <Form.Control
                                  className="me-2"
                                  type="checkbox"
                                  name={`row_${tr_index}`}
                                  title={`Select ${tr_index}`}
                                  defaultChecked={false}
                                />
                              </StyledGreenCheckbox>
                            </td>
                            {tableFields.filter((k) => !skipFields.includes(k)).map((field, td_index) => (<td key={`${td_index + 1}-${field}`} data-col={`${td_index + 1}`}>{row[field] || ""}</td>))}
                          </tr>))}
                          </tbody>
                        </StyledCustomDownloadTable>
                      </div>)
                    })}
                  </TabPanel>

                  <TabPanel key="tab-2-exhibits">
                    {Array.isArray(exhibits) && exhibits.length > 0 && exhibits.map(({ title, data = [] } = {}, section_index) => {
                      let section_letter = title.split(".")[0];

                      // These fields will be skipped if there is no value 
                      let possiblySkipFieldsCounter = {
                        source: 0,
                        date_from: 0,
                        date_to: 0,
                        decision_date: 0,
                        document_date: 0,
                        received: 0,
                        marked: 0
                      };
                      let mediaFields = ["judge", "hearing", "receipt_date"];
                      let skipFields = ["id", "link", "section", ...mediaFields];
                      let tableFields = ["#", "description", ...(Object.keys(possiblySkipFieldsCounter)), "pg", ...skipFields];
                      let renamedFields = {
                        pages: "pg",
                        exhibit: "#",
                      };
                      let tableRows = data.reduce((acc, row = {}) => {
                        let newRow = tableFields.reduce((a, field) => {
                          if (field in row) {
                            a[field] = row[field];
                          } else if (Object.values(renamedFields).includes(field)) {
                            let renamedIndex = Object.values(renamedFields).indexOf(field);
                            let oldField = Object.keys(renamedFields)[renamedIndex];
                            if (row[oldField]) {
                              row[field] = row[oldField]; // rename the field
                              delete row[oldField]; // delete the old field
                              a[field] = row[field];
                            }
                          }

                          if (a[field] && field in possiblySkipFieldsCounter) {
                            possiblySkipFieldsCounter[field]++;
                          }

                          if (field === "section" && row[field] && section_letter !== row[field]) {
                            section_letter = row[field];
                          }

                          return a;
                        }, {});

                        if (Object.keys(newRow).length) {
                          acc.push(newRow);
                        }
                        return acc;
                      }, []);

                      // Update skipFields
                      Object.entries(possiblySkipFieldsCounter).forEach(([field, count]) => {
                        if (count === 0) {
                          skipFields.push(field);
                        }
                      });

                      return (<div key={`exhibits-${title}-${section_index}`} {...(section_index > 0 && { className: "mt-4" })}>
                        <h6>{title}</h6>
                        <StyledCustomDownloadTable id={`table_exhibits_${section_letter}`} hover>
                          <thead>
                            <tr>
                              <th key="0-select_all">
                                <StyledGreenCheckbox>
                                  <Form.Control
                                    key={`select_all_exhibits_${section_letter}`}
                                    name={`select_all_exhibits_${section_letter}`}
                                    className="me-2"
                                    type="checkbox"
                                    title={`Select All ${title}`}
                                    onChange={handleRequestChanges}
                                    defaultChecked={false}
                                  />
                                </StyledGreenCheckbox>
                              </th>
                              {tableFields.filter((k) => !skipFields.includes(k)).map((header, th_index) =>
                              (<th key={`${th_index + 1}-${header}`}>
                                {(header.includes("_") || header.charAt(header.length - 1) === header.charAt(header.length - 1).toLowerCase()) ? (humanize(header)) : header}
                              </th>))}
                            </tr>
                          </thead>
                          <tbody>{Array.isArray(tableRows) && tableRows.length > 0 && tableRows.map((row = {}, tr_index) =>
                          (<tr
                            onClick={handleRequestChanges}
                            key={`exhibits-${title}-${section_index}-${tr_index}`}
                            data-row={tr_index}
                            data-link={row?.link}
                            data-id={row?.id}
                            data-section={row?.section}
                            data-tab="exhibits"
                            data-select_all_name={`select_all_exhibits_${section_letter}`}
                          >
                            <td key="0-checkbox" width={45} data-col={0}>
                              <StyledGreenCheckbox>
                                <Form.Control
                                  className="me-2"
                                  type="checkbox"
                                  name={`row_${tr_index}`}
                                  title={`Select ${tr_index}`}
                                  defaultChecked={false}
                                />
                              </StyledGreenCheckbox>
                            </td>
                            {tableFields.filter((k) => !skipFields.includes(k)).map((field, td_index) => (<td key={`${td_index + 1}-${field}`} data-col={`${td_index + 1}`}>{row[field] || ""}</td>))}
                          </tr>))}
                          </tbody>
                        </StyledCustomDownloadTable>
                      </div>)
                    })}
                  </TabPanel>

                  <TabPanel key="tab-3-media">
                    {Array.isArray(media) && media.length > 0 && media.map(({ title, data = [] } = {}, section_index) => {
                      let section_letter = "M";

                      // These fields will be skipped if there is no value 
                      let possiblySkipFieldsCounter = {
                        source: 0,
                        date_from: 0,
                        date_to: 0,
                        decision_date: 0,
                        document_date: 0,
                        received: 0,
                        marked: 0
                      };
                      let mediaFields = ["judge", "hearing", "receipt_date"];
                      let skipFields = ["id", "link", "section"];
                      let tableFields = ["#", "description", ...mediaFields, ...(Object.keys(possiblySkipFieldsCounter)), "pg", ...skipFields];
                      let renamedFields = {
                        pages: "pg",
                        exhibit: "#",
                      };
                      let tableRows = data.reduce((acc, row = {}) => {
                        let newRow = tableFields.reduce((a, field) => {
                          if (field in row) {
                            a[field] = row[field];
                          } else if (Object.values(renamedFields).includes(field)) {
                            let renamedIndex = Object.values(renamedFields).indexOf(field);
                            let oldField = Object.keys(renamedFields)[renamedIndex];
                            if (row[oldField]) {
                              row[field] = row[oldField]; // rename the field
                              delete row[oldField]; // delete the old field
                              a[field] = row[field];
                            }
                          }

                          if (a[field] && field in possiblySkipFieldsCounter) {
                            possiblySkipFieldsCounter[field]++;
                          }

                          return a;
                        }, {});

                        if (Object.keys(newRow).length) {
                          acc.push(newRow);
                        }
                        return acc;
                      }, []);

                      // Update skipFields
                      Object.entries(possiblySkipFieldsCounter).forEach(([field, count]) => {
                        if (count === 0) {
                          skipFields.push(field);
                        }
                      });

                      return (<div key={`media-${title}-${section_index}`} {...(section_index > 0 && { className: "mt-4" })}>
                        <h6>{title}</h6>
                        <StyledCustomDownloadTable id={`table_media_${section_letter}`} hover>
                          <thead>
                            <tr>
                              <th key="0-select_all">
                                <StyledGreenCheckbox>
                                  <Form.Control
                                    key={`select_all_media_${section_letter}`}
                                    name={`select_all_media_${section_letter}`}
                                    className="me-2"
                                    type="checkbox"
                                    title={`Select All ${title}`}
                                    onChange={handleRequestChanges}
                                    defaultChecked={false}
                                  />
                                </StyledGreenCheckbox>
                              </th>
                              {tableFields.filter((k) => !skipFields.includes(k)).map((header, th_index) =>
                              (<th key={`${th_index + 1}-${header}`}>
                                {(header.includes("_") || header.charAt(header.length - 1) === header.charAt(header.length - 1).toLowerCase()) ? (humanize(header)) : header}
                              </th>))}
                            </tr>
                          </thead>
                          <tbody>{Array.isArray(tableRows) && tableRows.length > 0 && tableRows.map((row = {}, tr_index) =>
                          (<tr
                            onClick={handleRequestChanges}
                            key={`media-${title}-${section_index}-${tr_index}`}
                            data-row={tr_index}
                            data-link={row?.link}
                            data-id={row?.id}
                            data-section={row?.section}
                            data-tab="media"
                            data-select_all_name={`select_all_media_${section_letter}`}
                          >
                            <td key="0-checkbox" width={45} data-col={0}>
                              <StyledGreenCheckbox>
                                <Form.Control
                                  className="me-2"
                                  type="checkbox"
                                  name={`row_${tr_index}`} title={`Select ${tr_index}`}
                                  defaultChecked={false}
                                />
                              </StyledGreenCheckbox>
                            </td>
                            {tableFields.filter((k) => !skipFields.includes(k)).map((field, td_index) => (<td key={`${td_index + 1}-${field}`} data-col={`${td_index + 1}`}>{row[field] || ""}</td>))}
                          </tr>))}
                          </tbody>
                        </StyledCustomDownloadTable>
                      </div>)
                    })}
                  </TabPanel>
                </Tabs>
              </Form.Group>)
            })}
          </Form>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey='3'>
        <Accordion.Header ref={reviewAndSubmit} onClick={(e) => (setActiveStep(4))}>Step 4 - Review and Submit</Accordion.Header>
        <Accordion.Body>
          <Row>
            <Col className="mb-3 d-flex justify-content-between">
              <Alert variant="warning" className="p-2">Please review your request before submitting.</Alert>
              <Button className="green-button align-self-start" onClick={(e) => (setActiveStep(5))} {...(!isValid || activeStep > 4) && { disabled: true }} >Submit Request</Button>
            </Col>
          </Row>

          <StyledCardLight className="card-hover mb-3">
            <Card.Header>
              <Card.Title>Included Socials
                <span className="text-primary ms-4 small" onClick={toggleAccordionSlide.bind({ step: 1 })} >Edit</span>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {requestObject?.socials.length > 0 && (<StyledListNoBorder>
                {requestObject.socials.map(({ ssn, claimant = {} }, index) => {
                  if (claimant?.claimant_name) {
                    return (<React.Fragment key={`social-${index}`}>
                      <li className="list-group-item"><strong>Claimant Name: </strong>{claimant.claimant_name}</li>
                      <li className="list-group-item"><strong>Claimant SSN: </strong>{ssn}</li>
                    </React.Fragment>)
                  }
                  return (<li key={`social-${index}`} className="list-group-item">{ssn}</li>)
                })}
              </StyledListNoBorder>) || (<span className="text-danger">No socials selected</span>)}
            </Card.Body>
          </StyledCardLight>

          <StyledCardLight className="card-hover mb-3">
            <Card.Header>
              <Card.Title>Selected Options
                <span className="text-primary ms-4 small" onClick={toggleAccordionSlide.bind({ step: 2 })} >Edit</span>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {(Array.isArray(downloadOptions) && downloadOptions.filter(({ checked = false }) => checked).length > 0) && (<StyledListNoBorder>
                {downloadOptions.filter(({ checked = false }) => checked).map(({ label }, index) => (<li key={index} className="list-group-item">{label}</li>))}
              </StyledListNoBorder>) || (<span className="text-danger">No download options selected</span>)}
            </Card.Body>
          </StyledCardLight>

          <StyledCardLight className="card-hover mb-3">
            <Card.Header>
              <Card.Title>Selected Resources
                <span className="text-primary ms-4 small" onClick={toggleAccordionSlide.bind({ step: 3 })} >Edit</span>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {requestObject?.socials.length > 0 && Object.keys(requestObject?.socials[0]?.resources).length > 0 && requestObject.socials.map(({ resources = {}, ssn } = {}, index) => {
                let { case_docs = [], exhibits = [], media = [] } = resources || {};

                return (<div key={`${ssn}-${index}`}>
                  {Array.isArray(case_docs) && case_docs.length > 0 && (<h5>Case Documents</h5>)}
                  {Array.isArray(case_docs) && case_docs.length > 0 && case_docs.map(({ title, data = [] } = {}, section_index) => {
                    let section_letter = title.split(".")[0]; // A, B, D, E, F
                    return (<div key={`case_docs-${section_letter}`}>
                      <strong>{title}</strong>
                      {Array.isArray(data) && data.length > 0 && (<StyledListNoBorder key={`case_docs-${section_letter}-${section_index}`} className="ms-4">
                        {data.map((item = {}, item_index) => (<li key={`${item.id || 0}-${item_index}`} className="list-group-item">{item.description}</li>))}
                      </StyledListNoBorder>)}
                    </div>)
                  })}

                  {Array.isArray(exhibits) && exhibits.length > 0 && (<h5 className="mt-2">Exhibit List</h5>)}
                  {Array.isArray(exhibits) && exhibits.length > 0 && exhibits.map(({ title, data = [] } = {}, section_index) => {
                    let section_letter = title.split(".")[0]; // A, B, D, E, F 
                    return (<div key={`exhibits-${section_letter}`}>
                      <strong>{title}</strong>
                      {Array.isArray(data) && data.length > 0 && (<StyledListNoBorder key={`exhibits-${section_letter}-${section_index}`} className="ms-4">
                        {data.map((item = {}, item_index) => (<li key={`${item.id || 0}-${item_index}`} className="list-group-item">{item.description}</li>))}
                      </StyledListNoBorder>)}
                    </div>)
                  })}

                  {Array.isArray(media) && media.length > 0 && (<h5>Multimedia Files</h5>)}
                  {Array.isArray(media) && media.length > 0 && media.map(({ title, data = [] } = {}, section_index) => {
                    let section_letter = "M"
                    return (<div key={`media-${section_letter}`}>
                      <strong>{title}</strong>
                      {Array.isArray(data) && data.length > 0 && (<StyledListNoBorder key={`media-${section_letter}-${section_index}`} className="ms-4">
                        {data.map((item = {}, item_index) => (<li key={`${item.id || 0}-${item_index}`} className="list-group-item">{item.description}</li>))}
                      </StyledListNoBorder>)}
                    </div>)
                  })}
                </div>)
              }) || (<span className="text-danger">No resources selected</span>)}
            </Card.Body>
          </StyledCardLight>

        </Accordion.Body>
      </Accordion.Item>
    </StyledAccordionDownloader>
  </div>);
}

export { CustomDownloadRequest };
export default CustomDownloadRequest;