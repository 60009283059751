import React, { useState } from 'react';
import { useParams } from "react-router-dom"
import { Row, Col } from 'react-bootstrap';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import DownloadRequestLogs from '../pages/DownloadRequestLogs';
import { LawFirmUserForm } from '../components/Forms';
import DefaultDownloadSettingsForm from '../components/Forms/DefaultDownloadSettingsForm';
import EREAccessSettingsForm from '../components/Forms/EREAccessSettingsForm';
import DefaultPDFSettingsForm from '../components/Forms/DefaultPDFSettingsForm';

const UserDetails = () => {
  let { user_id } = useParams();
  const [tabIndex, setTabIndex] = useState(0);
  const [orgId, setOrgId] = useState(0);

  return (<div className="page-container">
    <h2>User Details</h2>
    <hr />

    <Row>
      <Col xs={5}><LawFirmUserForm user_id={user_id} setSelectedOrgId={setOrgId} /></Col>

      <Col>
        <Tabs key="tabs" selectedIndex={tabIndex} onSelect={tabIndex => setTabIndex(tabIndex)} >
          <TabList>
            <Tab key="tab-1"><h5>Requests</h5></Tab>
            <Tab key="tab-2"><h5>Settings</h5></Tab>
          </TabList>

          <TabPanel key="tab-1-info" >
            <Row>
              <Col><DownloadRequestLogs org_id={orgId} user_id={user_id} /></Col>
            </Row>
          </TabPanel>

          <TabPanel key="tab-2-info" >
            <Row>
              <Col><DefaultDownloadSettingsForm user_id={user_id} /></Col>
              <Col><EREAccessSettingsForm user_id={user_id} /></Col>
            </Row>
            <Row>
              <Col><DefaultPDFSettingsForm user_id={user_id} /></Col>
            </Row>
          </TabPanel>
        </Tabs>
      </Col>
    </Row>
  </div>);
}

export default UserDetails;