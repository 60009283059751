import React, { Component } from 'react';
import SortableTree, { toggleExpandedForAll, getTreeFromFlatData, getFlatDataFromTree } from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import { Button } from 'react-bootstrap';
import { StyledCircleButton, StyledDownloadButton } from "../styled/Misc";
import { StyledClaimantFolder, StyledDirectoryDiv, StyledFileDiv } from "../styled/ClaimantFolder";
import { downloader } from "../utils/EREDocs";
class ClaimantEFolder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchString: '',
      searchFocusIndex: 0,
      searchFoundCount: null,
      loadingFile: false, // used to keep track of when files are being downloaded to prevent multiple downloads
      fromS3: props?.fromS3 || false,
      hideFullPath: props?.hideFullPath ?? true,
      claimant_id: props.claimant_id,
      user_id: props.user_id,
      org_id: props.org_id,
      auth_id: props.auth_id,
      isFlatData: props?.isFlatData ?? false,
      showDownloadButton: props?.showDownloadButton ?? false,
      showParentAlert: props?.showParentAlert,
      setParentAlert: props?.setParentAlert,
      rootPath: props?.rootPath || "/",
      treeData: props?.initialData
        ? props.initialData
        : (props?.initialFlatData || []).length > 0
          ? getTreeFromFlatData({
            flatData: props.initialFlatData.map(node => ({ ...node, title: node.name })),
            getKey: node => node.id, // resolve a node's key
            getParentKey: node => node.parent, // resolve a node's parent's key
            rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
          }) : []
    };

    this.updateTreeData = this.updateTreeData.bind(this);
    this.expandAll = this.expandAll.bind(this);
    this.collapseAll = this.collapseAll.bind(this);
    this.toggleS3Folder = this.toggleS3Folder.bind(this);
  }

  componentDidMount = () => {
    // If initial data is not passed in then we'll fetch it from the backend and update treeData. Otherwise just use what was passed in props.
    if (this.state.loadingFile) {
      this.setState({ loadingFile: false });
    }
    if (this.state.treeData.length === 0) {
      this.getClaimantFolderData();
    }
    if (typeof this.state.showParentAlert !== 'function') {
      this.setState({ showParentAlert: () => { } });
    }
    if (typeof this.state.setParentAlert !== 'function') {
      this.setState({ setParentAlert: () => { } });
    }
  };

  updateTreeData(treeData) {
    this.setState({ treeData });
  }

  expand(expanded) {
    this.setState({
      treeData: toggleExpandedForAll({
        treeData: this.state.treeData,
        expanded,
      }),
    });
  }

  expandAll() {
    this.expand(true);
  }

  collapseAll() {
    this.expand(false);
  }

  toggleS3Folder(e) {
    e.preventDefault();
    let fromS3 = !this.state.fromS3;
    this.setState({ fromS3 });
    this.getClaimantFolderData({ fromS3 });
  }

  getClaimantFolderData = async (params = {}) => {
    let { fromS3, hideFullPath = true, claimant_id, user_id, org_id, auth_id, rootPath, isFlatData = true } = { ...this.state, ...params };
    let formatAs = isFlatData ? "array" : "json";
    let queryParams = { fromS3, claimant_id, user_id, org_id, auth_id, rootPath, formatAs, hideFullPath, expanded: true, dragDisabled: true };
    let response;
    try {
      (typeof showParentAlert === 'function') && (showParentAlert(false));
      this.setState({ loadingFile: true });
      response = await downloader.getClaimantFolderTree(queryParams);
    } catch (error) {
      console.log("getClaimantFolderData Error", error);
      response = error?.response || {};
      if (!response?.data) {
        response.data = [];
      }
    } finally {
      this.setState({ loadingFile: false });
    }

    let { status, statusText = '', data = [] } = response;
    let alertObj = { alert_type: status !== 200 ? "danger" : "success", message: "" };
    if (status !== 200) {
      alertObj.message = `[${status}] ${statusText}`;
    }

    if (Array.isArray(data) && data.length > 0) {
      this.setState({
        treeData: isFlatData ? getTreeFromFlatData({
          flatData: data.map(node => ({ ...node, title: node.name })),
          getKey: node => node.id, // resolve a node's key
          getParentKey: node => node.parent, // resolve a node's parent's key
          rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
        }) : data
      });
    } else {
      this.setState({ treeData: [] });
    }

    (typeof showParentAlert === 'function') && (showParentAlert(!!alertObj.message));
    if (!!alertObj.message) {
      if (typeof setParentAlert === 'function') {
        setParentAlert((prevState) => ({ ...prevState, ...alertObj }));
      } else {
        console.log(alertObj.message);
      }
    }
  }

  render() {
    const {
      treeData,
      searchString,
      searchFocusIndex,
      searchFoundCount,
      rootPath,
      fromS3,
      showDownloadButton,
      claimant_id,
      user_id,
      org_id,
      auth_id,
      showParentAlert,
      setParentAlert,
      loadingFile,
    } = this.state;

    const alertNodeInfo = ({ node, path, treeIndex }) => {
      const objectString = Object.keys(node)
        .map(k => (k === 'children' ? 'children: Array' : `${k}: '${node[k]}'`))
        .join(',\n   ');

      let alert_message = 'Info passed to the icon and button generators:\n\n' +
        `node: {\n   ${objectString}\n},\n` +
        `path: [${path.join(', ')}],\n` +
        `treeIndex: ${treeIndex}`;

      if (typeof showParentAlert === 'function' && typeof setParentAlert === 'function') {
        showParentAlert(true);
        setParentAlert((prevState) => ({ ...prevState, alert_type: "info", message: alert_message }));
      } else {
        global.alert(alert_message);
      }
    };

    const handleFileDownload = async (event, { node, path, treeIndex } = {}) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      let flatTreeData = getFlatDataFromTree({ treeData, getNodeKey: ({ node }) => node.id });
      let pathArray = path.map((p) => (`${flatTreeData[p].node.title}`)); // ["path", "to", "file"]
      let params = { ...node, treeIndex, path, pathArray, fromS3 };
      let alertObj = { alert_type: "info", message: "" };
      let resp;
      try {
        (typeof showParentAlert === 'function') && (showParentAlert(false));
        this.setState({ loadingFile: true });
        resp = await downloader.downloadClaimantFile(claimant_id, params);
      } catch (error) {
        console.log("Error downloading file", error);
        resp = error?.response || { status: 500, statusText: error?.message || error || "Unknown Error" };
        resp.data = {
          ok: false,
          statusCode: resp.status,
          statusMessage: resp.statusText
        }
      } finally {
        this.setState({ loadingFile: false });
      }

      let { status, statusText, data = {} } = resp || {};
      let { ok = false, statusCode = 500, statusMessage, downloadDocs = [] } = data || {};
      alertObj.alert_type = (!ok || status !== 200) ? "danger" : "success";
      alertObj.message = `[${status || statusCode}] - `;
      alertObj.message += statusMessage || statusText || "Unknown Error";

      (typeof showParentAlert === 'function') && (showParentAlert(!!alertObj.message));
      if (!!alertObj.message) {
        if (typeof setParentAlert === 'function') {
          setParentAlert((prevState) => ({ ...prevState, ...alertObj }));
        } else {
          global.alert(alertObj.message);
        }
      }

      return;
    }; // END handleFileDownload

    const selectPrevMatch = () =>
      this.setState({
        searchFocusIndex:
          searchFocusIndex !== null
            ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
            : searchFoundCount - 1,
      });

    const selectNextMatch = () =>
      this.setState({
        searchFocusIndex:
          searchFocusIndex !== null
            ? (searchFocusIndex + 1) % searchFoundCount
            : 0,
      });

    return (<StyledClaimantFolder >
      <div>
        <h3>{rootPath || "File Explorer Theme"}</h3>
        <Button size="sm" variant="primary" onClick={this.toggleS3Folder}>Toggle {fromS3 ? "Local" : "S3"} Folder</Button>
        <Button size="sm" variant="primary" className="mx-3" onClick={this.expandAll}>Expand All</Button>
        <Button size="sm" variant="primary" onClick={this.collapseAll}>Collapse All</Button>

        <form className="ms-3" onSubmit={(e) => (e.preventDefault())} >
          <label htmlFor="find-box">
            Search:&nbsp;
            <input
              id="find-box"
              type="text"
              value={searchString}
              onChange={(event) => (
                this.setState({ searchString: event.target.value })
              )}
            />
          </label>

          <Button type="button" className="mx-3" disabled={!searchFoundCount} onClick={selectPrevMatch} >&lt;</Button>
          <Button type="submit" className="me-3" disabled={!searchFoundCount} onClick={selectNextMatch} >&gt;</Button>
          <span>&nbsp;
            {searchFoundCount > 0 ? searchFocusIndex + 1 : 0}
            &nbsp;/&nbsp;
            {searchFoundCount || 0}
          </span>
        </form>
      </div>

      <div>
        <SortableTree
          isVirtualized={false}
          theme={FileExplorerTheme}
          treeData={treeData}
          onChange={this.updateTreeData}
          searchQuery={searchString}
          searchFocusOffset={searchFocusIndex}
          searchFinishCallback={matches =>
            this.setState({
              searchFoundCount: matches.length,
              searchFocusIndex:
                matches.length > 0 ? searchFocusIndex % matches.length : 0,
            })
          }
          canDrag={({ node }) => !node.dragDisabled}
          canDrop={({ nextParent }) => !nextParent || nextParent.isDirectory}
          generateNodeProps={rowInfo => ({
            icons: rowInfo.node.isDirectory
              ? [<StyledDirectoryDiv expanded={rowInfo.node.expanded} />]
              : [<StyledFileDiv>F</StyledFileDiv>],
            buttons: [...(showDownloadButton && !rowInfo.node.isDirectory
              ? [<StyledDownloadButton onClick={(e) => handleFileDownload(e, rowInfo)} disabled={loadingFile} >✔ {loadingFile ? "Downloading..." : "Download"}</StyledDownloadButton>]
              : [<StyledCircleButton onClick={() => alertNodeInfo(rowInfo)} disabled={loadingFile}>i</StyledCircleButton>]),
            ],
          })}
        />
      </div>
    </StyledClaimantFolder>);
  }
}

export { ClaimantEFolder };
export default ClaimantEFolder;

// ===============[ NOTES ]=======================
// Example Usage 
/*
<ClaimantEFolder
  fromS3={true}
  showDownloadButton={true}
  claimant_id={claimant_id}
  user_id={user_id}
  org_id={org_id}
  auth_id={default_auth_id}
  formatAs="array"
  rootPath={rootPath}
  initialData={dummyData}
  initialFlatData={dummyFlatData}
/>
*/

// Example of props.initialData
const dummyData = [
  { title: '.gitignore' },
  { title: 'package.json' },
  {
    title: 'src',
    isDirectory: true,
    expanded: true,
    children: [
      { title: 'styles.css' },
      { title: 'index.js' },
      { title: 'reducers.js' },
      { title: 'actions.js' },
      { title: 'utils.js' },
    ],
  },
  {
    title: 'tmp',
    isDirectory: true,
    children: [
      { title: '12214124-log' },
      { title: 'drag-disabled-file', dragDisabled: true },
    ],
  },
  {
    title: 'build',
    isDirectory: true,
    children: [{ title: 'react-sortable-tree.js' }],
  },
  {
    title: 'public',
    isDirectory: true,
  },
  {
    title: 'node_modules',
    isDirectory: true,
  },
];

// Example of props.initialFlatData
const dummyFlatData = [
  { id: '1', name: 'N1', parent: null },
  { id: '2', name: 'N2', parent: null },
  { id: '3', name: 'N3', parent: 2 },
  { id: '4', name: 'N4', parent: 3 },
];
