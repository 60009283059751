import styled from "styled-components";

export const StyledClaimantFolder = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;

  & > div > form {
    display: inline-block;
  }

  & > div:nth-child(1) {
    flex: 0 0 auto;
    padding: 0;
    margin-bottom: 15px; 
  }

  & > div:nth-child(2) {
    flex; 1 0 50%;
    padding: 0 0 100px 15px;
    margin-bottom: 15px; 
  }
`;

export const StyledDirectoryDiv = styled.div`
  border-left: solid 8px gray;
  border-bottom: solid 10px gray;
  margin-right: 10px;
  box-sizing: border-box;
  width: 16px;
  height: 12px;
  filter: ${({ expanded = false }) => expanded ? 'drop-shadow(1px 0 0 gray) drop-shadow(0 1px 0 gray) drop-shadow(0 -1px 0 gray) drop-shadow(-1px 0 0 gray)' : 'none'};
  border-color: ${({ expanded = false }) => expanded ? 'white' : 'gray'};
`;

export const StyledFileDiv = styled.div`
  border: solid 1px black;
  font-size: 8px;
  text-align: center;
  margin-right: 10px;
  width: 12px;
  height: 16px;
`;