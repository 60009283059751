import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom"
import { useUserData } from "../contexts/AuthContext";
import { Form, Col, Row, Button, Table, Alert, Dropdown } from 'react-bootstrap';
import Swal from 'sweetalert2';
import moment from "moment-timezone";
import { StyledListNoBorder } from '../styled/List';
import { StyledSpan } from '../styled/Span';
import { StyledGreenCheckbox, StyledFormControl } from '../styled/Forms';
import Loader from "../components/Loader";
import { request_queue, admin } from "../utils/EREDocs";
import { massageData, humanize, dehumanize, renameKeys, isObject, formatDate, splitArrayChunksAlt } from "../utils";
import downloaderManagementStatus from '../fake-data/downloaderManagementStatus.json';
let seconds_between_server_checks = Number(process.env.REACT_APP_SECONDS_BETWEEN_SERVER_CHECKS || '60');
const defaultQueryParams = { limit: 50, showDebug: false };
let IntervalId = null;

const DownloadManagement = ({ showClaimantLinks = true } = {}) => {
  const { userData } = useUserData();
  let { passport: { user: { org_id, user_id, email: user_email, group_id = 6 } = {} } = {}, isAuthenticated = false } = userData || {};
  (typeof org_id !== 'number') && (org_id = Number(org_id));
  let superAdminAccess = (isAuthenticated && Number(group_id) === 1);
  let refresh_interval_seconds = Number(process.env.REACT_APP_SECONDS_REFRESH_AUDIT_LOGS || '30');
  let skipFields = ['requested_options', 'exhibited_count', 'unexhibited_count', 'media_count', 'auth_id', 'claimant_id', 'matter_id', 'request_id', 'title', 'firm_id', 'matter_id', 'ssn', 'zip_type', 'firm', 'qid', 'can_process_requests', 'last_captured'];
  let dateFields = ['Delivered At', 'Started At'];
  let renamedFields = {
    request_queue_id: "Queue ID",
    request_social_id: "Social ID",
  };
  let tableFields = [
    'Attorney',
    'Delivered At',
    'Queue ID',
    'Queue Status',
    'Request Status',
    'Social ID',
    'Social Status',
    'Job Type',
    'Core Status',
    'Zip Status',
    'PF Status',
    'Claimant',
    'Exhibited Status',
    'Unexhibited Status',
    'Media Status',
    'Duration',
    'Started At',
  ];

  const statusKeyMap = downloaderManagementStatus;
  const downloadManagementRef = useRef();
  const [invokeUpdate, setInvokeUpdate] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);

  const [dataCount, setDataCount] = useState(0);
  const [Pages, setPages] = useState([1]);
  const [activePage, setActivePage] = useState(1);
  const [tableHeader, setTableHeader] = useState([]);
  const [tableRows, setTableRows] = useState([]);

  const [showTableAlert, setShowTableAlert] = useState(false);
  const [tableAlert, setTableAlert] = useState({ message: "", alert_type: "info" });

  const [filterFormState, setFilterFormState] = useState({
    show_all: false,
    queue_id: '',
    social_id: '',
    auth_id: '',
    claimant_id: '',
    matter_id: '',
    request_id: '',
    firm_id: '',
    qid: '',
    firm: '',
    attorney: '',
    claimant: '',
    title: '',
    queue_status: '',
    request_status: '',
    social_status: '',
    job_type: '',
    core_status: '',
    zip_status: '',
    zip_type: '',
    pf_status: '',
    exhibited_status: '',
    unexhibited_status: '',
    media_status: '',
    started_at: '',
    delivered_at: '',
    end_date: '',
    // duration: '',
  });

  const [isValid, setIsValid] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [actionBitMask, setActionBitMask] = useState(0); // 0 - show no buttons 
  const [dropdownBitMask, setDropdownBitMask] = useState(31); // 31 - show all buttons 
  const [downloaderServerStatus, setDownloaderServerStatus] = useState({
    alias: process.env.REACT_APP_DOWNLOADER_SERVER_ALIAS || "DOWNLOADER_SERVER",
    status: "unknown",
    last_checked: Date.now(),
  });

  const handleFormChange = (event) => {
    if (!event?.target) return;
    let { name, value, type, checked = false } = event.target;
    if (type === 'checkbox') {
      value = checked;
    }

    setFilterFormState((prevState) => ({
      ...prevState,
      ...(name === 'show_all' ? {
        [name]: value,
        ...(value && {
          queue_id: '',
          social_id: '',
          auth_id: '',
          claimant_id: '',
          matter_id: '',
          request_id: '',
          firm_id: '',
          qid: '',
          firm: '',
          attorney: '',
          claimant: '',
          title: '',
          queue_status: '',
          request_status: '',
          social_status: '',
          job_type: '',
          core_status: '',
          zip_status: '',
          zip_type: '',
          pf_status: '',
          exhibited_status: '',
          unexhibited_status: '',
          media_status: '',
          started_at: '',
          delivered_at: '',
          end_date: ''
        })
      } : {
        [name]: value,
        show_all: false
      })
    }));

    if (name === 'show_all') {
      if (value && IntervalId !== null) {
        console.log("Clearing Interval because show all was checked.", { IntervalId });
        clearInterval(IntervalId);
      }
      searchRequests();
    }
  };

  const searchRequests = async (event) => {
    if (event) event.preventDefault() && event.stopPropagation();
    setActionBitMask(0);
    setDropdownBitMask(31);
    return setInvokeUpdate(prev => !prev);
  };

  const clearFilters = () => {
    setFilterFormState({
      show_all: false,
      queue_id: '',
      social_id: '',
      auth_id: '',
      claimant_id: '',
      matter_id: '',
      request_id: '',
      firm_id: '',
      qid: '',
      firm: '',
      attorney: '',
      claimant: '',
      title: '',
      queue_status: '',
      request_status: '',
      social_status: '',
      job_type: '',
      core_status: '',
      zip_status: '',
      zip_type: '',
      pf_status: '',
      exhibited_status: '',
      unexhibited_status: '',
      media_status: '',
      started_at: '',
      delivered_at: '',
      end_date: '',
      // duration: '',
    });
    setActionBitMask(0);
    setDropdownBitMask(31);
    return setInvokeUpdate(prev => !prev);
  };

  const handleBulkAction = async (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    let { target = {} } = event || {};
    let { innerText } = target || {};

    let action = "";
    if (`${innerText}`.toLowerCase().includes("mark")) {
      // Mark Good or Unavailable
      action = "mark";
      if (`${innerText}`.toLowerCase().includes("good")) {
        action += "_good";
      } else if (`${innerText}`.toLowerCase().includes("unavailable")) {
        action += "_unavailable";
      }
    } else if (`${innerText}`.toLowerCase().includes("retry")) {
      // Retry phase 1, 2, or 3 
      action = "retry";
      if (`${innerText}`.toLowerCase().includes("request")) {
        action += "_request"; // retry phase 1
      } else if (`${innerText}`.toLowerCase().includes("download")) {
        action += "_download"; // retry phase 2
      } else if (`${innerText}`.toLowerCase().includes("delivery") || `${innerText}`.toLowerCase().includes("core process")) {
        action += "_delivery"; // retry phase 3
      }
    }

    // console.log("handleBulkAction", { innerText, action }, selectedRows);
    // Flatten selectedRows and create params 
    let neededParams = ['request_queue_id', 'request_id', 'request_social_id', 'qid', 'auth_id'];
    let params = selectedRows.reduce((acc, row) => {
      Object.entries(row).forEach(([key, value]) => {
        if (neededParams.includes(key)) {
          acc[key] = key in acc ? `${acc[key]}, ${value}` : value;
        }
      });
      return acc;
    }, { action });

    let response;
    let alertObj = { ...tableAlert };
    try {
      if (!isValid) {
        throw new Error(`Invalid Selection! ${tableAlert.message}`);
      }

      setLoadingTable(true);
      response = await request_queue.downloadBulkAction(params);
      alertObj.message = ""; // Reset alert when successful 
      setActionBitMask(0);
      setDropdownBitMask(31);
      setInvokeUpdate(prev => !prev);
    } catch (error) {
      console.log(error);
      response = error?.response || {};
      response.status = 500;
      response.statusText = `${error.message}`;
      if (!response?.data) {
        response.data = { err: 500, error: error.error, details: `${error.message}` };
      }
    } finally {
      setLoadingTable(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    // console.log("handleBulkAction", { status, statusText, data });

    if (status > 399) {
      alertObj.alert_type = "danger";
      alertObj.message = `${statusText}`;
    }

    setShowTableAlert(!!alertObj.message);
    if (!!alertObj.message) {
      alertObj.message = alertObj.message.trim();
      setTableAlert(prevState => ({ ...prevState, ...alertObj }));
    }

    return;
  } // End handleBulkAction

  // Extracts row items 
  const handleTableRowClick = async (event) => {
    if (!event?.target) return;
    event.stopPropagation(); // Prevent other events from firing on click

    let error_count = 0;
    let alertOptions = {
      message: '',
      alert_type: "info"
    };

    let { target = {} } = event || {};
    let { name, value, checked, disabled = false, type, id, dataset, tagName, innerText, href, parentElement, className, title } = target;
    if (type !== 'checkbox') {
      event.preventDefault(); // Allows checkboxes to work as normal
    }

    // Go up one level in the DOM but preserve innerText
    if (!['TD'].includes(tagName)) {
      if (parentElement?.tagName === "TD") {
        target = parentElement;
      } else {
        target = parentElement?.closest("td");
      }
      if (target) {
        parentElement = target?.parentElement;
        dataset = { ...dataset, ...target?.dataset };
        tagName = target?.tagName;
        if (!innerText && target?.innerText) {
          innerText = target?.innerText;
        }
      }
    }

    // Set action if needed 
    let action = false;
    if (type === "checkbox") {
      let nameArr = name.split("_");
      if (['select', 'all'].every((n) => nameArr.includes(n))) {
        action = name; // select_all_rows
      } else {
        action = "toggle_selected";
      }
    } else if (tagName === "TD") {
      action = "toggle_selected";
    }

    let rowObject = {
      innerText,
      tagName,
      ...(type && { type }),
      ...dataset,
      ...parentElement?.dataset,
    };

    // Extract row items and save to rowObject and selectedItem
    let selectedItem = {};
    if (tagName === "TD") {
      // Select Resource 
      let requestTableElement = parentElement.closest("table") || document.querySelector("table");
      let thArray = Array.from(requestTableElement.querySelectorAll("thead tr th")).map(({ innerText }) => dehumanize(innerText)).slice(2); // remove 2 columns at beginning
      let tdArray = Array.from(parentElement.querySelectorAll("td")).map(({ innerText }) => innerText).slice(2); // remove 2 columns at beginning

      if ('col' in rowObject) { // Extract selectedItem
        let col = Number(rowObject.col);
        if (col && innerText === tdArray[col]) {
          selectedItem[thArray[col]] = tdArray[col];
        }
      }

      // Match thArray length with tdArray length
      if (tdArray.length > thArray.length) {
        // slice extra elements to match thArray length
        tdArray = tdArray.slice(0, thArray.length);
      } else if (tdArray.length < thArray.length) {
        // Fill in the blanks with null values to match thArray length
        let startIndex = tdArray.length;
        tdArray.length = thArray.length;
        tdArray.fill(null, startIndex);
      }

      if (tdArray.length === thArray.length) {
        for (let i = 0; i < thArray.length; i++) {
          rowObject[thArray[i]] = tdArray[i];
        }
      }
    }

    rowObject.action = action;
    // console.log(`action: ${action}`); 
    // Object.keys(rowObject).length > 0 && console.log("handleTableRowClick rowObject", rowObject);
    // Object.keys(selectedItem).length > 0 && console.log("selectedItem", selectedItem);

    let { row, col } = rowObject;
    let requestTableElement = parentElement.closest("table") || document.querySelector("table");
    if (Number(col) === 1 && requestTableElement) {
      requestTableElement.querySelectorAll("tbody tr td div.dropdown").forEach(div => div.classList.remove("show"));
      requestTableElement.querySelectorAll("tbody tr td div.dropdown div.dropdown-menu").forEach(div => div.classList.remove("show"));
    }

    if (action) {
      if (['select', 'all'].every((n) => action.split("_").includes(n))) { // select_all_rows
        let { err_count = 0, err_message = '' } = updateSelectedRows(rowObject, !!checked);
        error_count += err_count;
        alertOptions.message += err_message;
        if (requestTableElement) {
          requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']").forEach((el) => {
            el.checked = !!checked
          });
        }
      } else if (action === "toggle_selected") {
        // Toggle Selected 
        let updateResourceChecked = !!checked;
        row = Number(row);

        if (!isNaN(row) && requestTableElement) {
          if (type !== "checkbox") {
            requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']").forEach((el, index) => {
              if (index === row) {
                el.checked = updateResourceChecked = !el.checked; // Toggle Checked State
              }
            });
          }

          // Check if all is selected and update "select all" checkbox 
          if (requestTableElement.querySelector(`thead tr th input[name='select_all_rows']`)) {
            let selectAllPreviousState = !!requestTableElement.querySelector(`thead tr th input[name='select_all_rows']`)?.checked;
            let selectAllNewState = Array.from(requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']")).length === Array.from(requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']"))
              .map((el) => el?.checked).filter(Boolean).length;

            if (selectAllPreviousState !== selectAllNewState) {
              requestTableElement.querySelector(`thead tr th input[name='select_all_rows']`).checked = updateResourceChecked = selectAllNewState;
            }
          }
        }

        let { err_count = 0, err_message = '' } = updateSelectedRows(rowObject, updateResourceChecked);
        error_count += err_count;
        alertOptions.message += err_message;
      } else {
        console.log("Unknown Action");
        error_count++;
        alertOptions.message = `Unknown Action ${action}`;
        alertOptions.alert_type = "info";
      }
    }
    else if (error_count === 0) {
      // Check if all is selected and update "select all" checkbox 
      if (requestTableElement.querySelector(`thead tr th input[name='select_all_rows']`)) {
        let selectAllPreviousState = !!requestTableElement.querySelector(`thead tr th input[name='select_all_rows']`)?.checked;
        let selectAllNewState = Array.from(requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']")).length === Array.from(requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']"))
          .map((el) => el?.checked).filter(Boolean).length;

        if (selectAllPreviousState !== selectAllNewState) {
          requestTableElement.querySelector(`thead tr th input[name='select_all_rows']`).checked = updateResourceChecked = selectAllNewState;
        }
      }
    }

    let valid = error_count === 0;
    setIsValid(valid);
    setShowTableAlert(!valid);
    if (!valid) {
      alertOptions.message = `${error_count} error${error_count > 1 ? 's' : ''}: ${alertOptions?.message}`;
      setTableAlert(prevState => ({ ...prevState, ...alertOptions }));
    }
    return;
  }; // END handleTableRowClick

  const getTableData = async ({
    startedAt = Date.now(), headerFields = tableFields, renamed_fields = renamedFields, date_fields = dateFields, functionFields = skipFields,
    refresh_in_seconds = refresh_interval_seconds,
  } = {}) => {
    if (filterFormState.show_all) {
      refresh_in_seconds = 0; // Disable interval when showing all records
    }

    let now = Date.now();
    let duration_seconds = Math.round((now - startedAt) / 1000);
    let ran_count = !refresh_in_seconds ? 1 : Math.round(duration_seconds / refresh_in_seconds) || 1; // Avoid dividing by zero
    let first_run = now === startedAt || ran_count === 1;

    // Detect if user navigated away from this page 
    let offsetWidth = downloadManagementRef?.current?.offsetWidth || 0;
    let offsetHeight = downloadManagementRef?.current?.offsetHeight || 0;
    let navigated_away = !downloadManagementRef?.current || !(offsetWidth > 0 && offsetHeight > 0);

    if (!first_run && (navigated_away || !isAuthenticated)) {
      console.log("Clearing Interval because user navigated away.", { IntervalId, first_run, navigated_away, isAuthenticated, offsetWidth, offsetHeight });
      clearInterval(IntervalId);
      return;
    }

    let alertObj = { ...tableAlert };
    let filterValues = Object.entries(filterFormState).filter(([id, value]) => value); // Filter out empty values
    let filteredData = (filterValues.length === 0) ? {} : Object.entries(filterFormState).reduce((acc, [id, value]) => ({ ...acc, ...(value && { [id]: value }) }), {});
    let queryParams = {
      ...defaultQueryParams,
      ...filteredData,
      page: activePage
    };

    let response;
    try {
      setLoadingTable(true);
      response = await request_queue.getRequestQueue(0, queryParams);
      alertObj.message = ""; // Reset alert when successful 
    } catch (error) {
      console.log(error);
      response = error?.response || {};
      response.status = 500;
      response.statusText = `${error.message}`;
      if (!response?.data) {
        response.data = { err: 401, error: error.error, details: `${error.message}` };
      }
    } finally {
      setLoadingTable(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    let { err, error, details, message, rows = [], count = 0, pageCount = 1, page, pages = [] } = data || {};

    if (status === 200) {
      let dataHeader = [...headerFields, ...functionFields];
      let dataRows = (!Array.isArray(rows) || rows.length === 0) ? [] : massageData(rows, dataHeader, renamed_fields, date_fields, [], [], "MM/D/YYYY hh:mm:ss A");

      if (rows.length === 0 || count === 0) {
        if (activePage > 1 && activePage === Pages[Pages.length - 1]) {
          setActivePage(Pages[Pages.length - 2]); // Make sure we don't get stuck loading nothing
        } else {
          alertObj.alert_type = "info";
          alertObj.message = "No downloads found.";
        }
      }

      pages = pages.reduce((acc, item, index) => {
        if (index === 0 && item.number !== 1) {
          acc.push(1); // Always include the first page 
        }
        acc.push(item.number);
        if (index === (pages.length - 1) && !acc.includes(pageCount)) {
          acc.push(pageCount); // Always include the last page
        }
        return acc;
      }, []);

      setTableHeader(dataHeader);
      setTableRows(dataRows);
      setPages(pages);
      setDataCount(count);
    } else {
      alertObj.alert_type = "danger";

      if (typeof response?.data === 'string') {
        alertObj.message = response.data;
        alertObj.message += ` (${statusText}) `;
      } else {
        alertObj.message = `[${status ?? err}] ${statusText} `;    // API Error Code 
        alertObj.message += data?.status ? `${data.status} ` : ""; // API Status 
        alertObj.message += message ?? (details ? `${details} ` : "") + `Failed to read data.`;
      }

      alertObj.message += error ? `${error} ` : "";              // API Error 
    }

    setShowTableAlert(!!alertObj.message);
    if (!!alertObj.message) {
      alertObj.message = alertObj.message.trim();
      setTableAlert(prevState => ({ ...prevState, ...alertObj }));
    }
    if (first_run && refresh_in_seconds) {
      if (IntervalId !== null) {
        console.log("Clearing Interval because new interval is being started.", { IntervalId });
        clearInterval(IntervalId);
      }
      let interval_ms = refresh_in_seconds * 1000;
      IntervalId = setInterval(getTableData.bind(null, { startedAt, headerFields, renamed_fields, date_fields, functionFields, refresh_in_seconds }), interval_ms); // Next render every refresh_in_seconds seconds
      console.log("Set Interval!", { IntervalId, activePage, loadingTable, startedAt });
    }

    // console.log("getTableData", { first_run, IntervalId, ran_count, duration_seconds, now, startedAt, navigated_away, isAuthenticated, offsetWidth, offsetHeight, status, statusText });
    return data;
  }; // END getTableData

  // ===============[ updateSelectedRows ]==================================
  const updateSelectedRows = (params = {}, checked = true) => {
    let { action, col, row, queue_id, request_id, social_id, ssn, qid } = params;
    // console.log(`updateSelectedRows [${checked}]`, params);

    if (checked && IntervalId !== null) {
      console.log("Clearing Interval because row was selected.", { IntervalId });
      clearInterval(IntervalId);
    }

    if (filterFormState.show_all) {
      setFilterFormState((prevState) => ({ ...prevState, show_all: false }));
    }

    let err_count = 0;
    let err_message = '';
    let actionAccumulator = 0;
    const FIFTEEN_MIN_MS = 15 * 60 * 1000;

    const actionValidation = ({ delivered_at = null, zip_status = null, pf_status = null, requested_options = "", zip_type = null, qid = null, claimant = null } = {}) => {
      let bitMaskValue = 0;
      let count = 0;

      if (qid) { // phase 3 
        bitMaskValue = bitMaskValue | 1; // Show button [Retry Core Process (phase 3)]
      }

      if (delivered_at) { // phase 3 - Core Process
        bitMaskValue = bitMaskValue | 16; // Show button [Mark Good]
      } else if (zip_status || zip_type) { // Fail Request OR phase 2 - Download
        if (["Downloaded", "Ready for Download"].includes(pf_status)) {
          bitMaskValue = bitMaskValue | 2; // Show button [Retry Download (phase 2)]
        } else if (["Failed"].includes(pf_status)) {
          bitMaskValue = bitMaskValue | 10; // Show buttons [Mark Unavailable] and [Retry Download (phase 2)]
        }
      } else { // Fail request OR phase 1 - Initiate Download
        bitMaskValue = bitMaskValue | 8; // Show button [Mark Unavailable]

        // 4 - [Retry Request (phase 1)]
        let parsedOpt = requested_options.split(", ").reduce((acc, option) => {
          let tmp = option.split(" "); // exhibited_files [65]
          let aliasCountLabels = {
            exhibited_files: 'exhibited_count',
            unexhibited_files: 'unexhibited_count',
            unexhibited_fallback: 'unexhibited_count',
            unexibited_fallback: 'unexhibited_count',
            audio_files: 'media_count',
          };

          let key = tmp[0] in aliasCountLabels ? aliasCountLabels[tmp[0]] : tmp[0];
          let value = tmp[1]; // [65]

          const between_brackets_regex = /\[(.*?)\]/gm;
          let m;
          while ((m = between_brackets_regex.exec(value)) !== null) {
            if (m.index === between_brackets_regex.lastIndex) {
              between_brackets_regex.lastIndex++;
            }

            let desiredIndex = 1;
            m.forEach((match, groupIndex) => {
              if (groupIndex === desiredIndex) {
                value = Number(match);
                if (isNaN(value)) {
                  value = 0;
                }
              }
            });
          }

          return { ...acc, [key]: value };
        }, {});

        // Extract Count from Parsed Options
        count = Object.values(parsedOpt).reduce((acc, value) => acc + Number(value), 0);
        if (zip_type) {
          let aliasCountValues = {
            exhibits: 'exhibited_count',
            exibits: 'exhibited_count',
            unexhibited: 'unexhibited_count',
            case_docs: 'unexhibited_count',
            media: 'media_count',
          };
          count = `${zip_type}`.toLowerCase() in aliasCountValues ? parsedOpt[aliasCountValues[`${zip_type}`.toLowerCase()]] : 0;
        }
      }

      if (count > 0 || ["Unknown Claimant", null, ""].includes(claimant)) {
        bitMaskValue = bitMaskValue | 4; // Show button [Retry Request (phase 1)]
      }

      if (bitMaskValue === 0) { // Show All Buttons when bitMaskValue is 0
        bitMaskValue = 31;
      } else { // Always at leasts show Retry Request (phase 1)
        bitMaskValue = bitMaskValue | 4;
      }

      return bitMaskValue;
    }

    // Search for the target resource in the requestObject all_resources and update resources
    setSelectedRows((prevState) => {
      let newState = [...prevState];
      let allRows = tableRows || [];
      let originalFieldNames = Object.entries(renamedFields).reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {});

      if (action === "select_all_rows") {
        if (checked) {
          newState = allRows.map((r) => {
            // Use original field names found in our database 
            originalFieldNames = { ...Object.entries(r).reduce((acc, [key, value]) => ({ ...acc, [key]: dehumanize(key) }), {}), ...originalFieldNames };
            let _row = renameKeys(originalFieldNames, r);
            let { started_at = formatDate(), request_social_id, delivered_at = null, zip_status = null, zip_type = null, pf_status = null, qid = null, requested_options = "", claimant = "" } = _row || {};

            // Overall Validation
            if (!started_at || moment(Date.now() - FIFTEEN_MIN_MS).isAfter(new Date(started_at)) === false) { // Make sure row is older than 15 minutes 
              err_count++;
              err_message += `request_social_id: ${request_social_id} started_at ${started_at} which is not older than 15 minutes`;
            }

            // Action Validation
            actionAccumulator = actionAccumulator | actionValidation({ delivered_at, zip_status, zip_type, pf_status, qid, requested_options, claimant });
            return _row;
          });
        } else {
          newState = [];
        }
      } else {
        // Handle Single selection
        if (checked) {
          allRows.forEach((r = {}) => {
            if (r[renamedFields['request_queue_id']] === Number(queue_id) || r[renamedFields['request_social_id']] === Number(social_id) || qid && Number(r.qid) === Number(qid)) {
              originalFieldNames = { ...Object.entries(r).reduce((acc, [key, value]) => ({ ...acc, [key]: dehumanize(key) }), {}), ...originalFieldNames };
              let _row = renameKeys(originalFieldNames, r);
              let { started_at = formatDate(), request_social_id, delivered_at = null, zip_status = null, zip_type = null, pf_status = null, qid = null, requested_options = "", claimant = "" } = _row || {};

              // Only add if not already in the array
              if (queue_id && !newState.find(({ request_queue_id } = {}) => request_queue_id === Number(queue_id))) {
                // Overall Validation
                if (!started_at || moment(Date.now() - FIFTEEN_MIN_MS).isAfter(new Date(started_at)) === false) { // Make sure row is older than 15 minutes 
                  err_count++;
                  err_message += `request_social_id: ${request_social_id} started_at ${started_at} which is not older than 15 minutes`;
                }

                // Action Validation Button 
                actionAccumulator = actionAccumulator | actionValidation({ delivered_at, zip_status, zip_type, pf_status, qid, requested_options, claimant });
                newState.push(_row);
              } else if (qid && !newState.find(({ qid: row_qid } = {}) => row_qid === Number(qid))) {
                // Overall Validation
                const FIVE_MIN_MS = 5 * 60 * 1000;
                if (moment(Date.now() - FIVE_MIN_MS).isAfter(new Date(started_at)) === false) { // Make sure row is older than 5 minutes 
                  err_count++;
                  err_message += `qid: ${qid} started_at ${started_at} which is not older than 5 minutes`;
                }

                // Action Validation Button 
                actionAccumulator = actionAccumulator | 1;
                newState.push(_row);
              }
            }
          });
        } else {
          if (queue_id) {
            newState = newState.filter(({ request_queue_id } = {}) => request_queue_id !== Number(queue_id));
          } else if (qid) {
            newState = newState.filter(({ qid: row_qid } = {}) => row_qid !== Number(qid));
          }
        }
      }

      if (newState.length === 0) {
        setActionBitMask(0);
        setDropdownBitMask(31);
        setInvokeUpdate(prev => !prev);
      } else {
        setActionBitMask(prevState => prevState | actionAccumulator);

        if (Number(col) === 1) {
          setDropdownBitMask(actionAccumulator);
        }
      }

      return newState;
    }); // End setSelectedResources

    return { err_count, err_message };
  } // End updateSelectedRows

  const handleSingleAction = async (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    let { target = {} } = event || {};
    let { name, value, checked, disabled = false, type, id, dataset, tagName, innerText, href, parentElement, className, title } = target;
    if (type !== 'checkbox') {
      event.preventDefault(); // Allows checkboxes to work as normal
    }

    let action = "";
    if (`${innerText}`.toLowerCase().includes("mark")) {
      // Mark Good or Unavailable
      action = "mark";
      if (`${innerText}`.toLowerCase().includes("good")) {
        action += "_good";
      } else if (`${innerText}`.toLowerCase().includes("unavailable")) {
        action += "_unavailable";
      }
    } else if (`${innerText}`.toLowerCase().includes("retry")) {
      // Retry phase 1, 2, or 3 
      action = "retry";
      if (`${innerText}`.toLowerCase().includes("request")) {
        action += "_request"; // retry phase 1
      } else if (`${innerText}`.toLowerCase().includes("download")) {
        action += "_download"; // retry phase 2
      } else if (`${innerText}`.toLowerCase().includes("delivery") || `${innerText}`.toLowerCase().includes("core process")) {
        action += "_delivery"; // retry phase 3
      }
    }

    // Go up one level in the DOM but preserve innerText
    if (!['TD'].includes(tagName)) {
      if (parentElement?.tagName === "TD") {
        target = parentElement;
      } else {
        target = parentElement?.closest("td");
      }
      if (target) {
        parentElement = target?.parentElement;
        dataset = { ...dataset, ...target?.dataset };
        tagName = target?.tagName;
        if (!innerText && target?.innerText) {
          innerText = target?.innerText;
        }
      }
    }

    let rowObject = {
      innerText,
      tagName,
      ...(type && { type }),
      ...dataset,
      ...parentElement?.dataset,
    };

    // Extract row items and save to rowObject
    if (tagName === "TD") {
      // Select Resource 
      let requestTableElement = parentElement.closest("table") || document.querySelector("table");
      let thArray = Array.from(requestTableElement.querySelectorAll("thead tr th")).map(({ innerText }) => dehumanize(innerText)).slice(2); // remove 2 columns at beginning
      let tdArray = Array.from(parentElement.querySelectorAll("td")).map(({ innerText }) => innerText).slice(2); // remove 2 columns at beginning

      // Match thArray length with tdArray length
      if (tdArray.length > thArray.length) {
        // slice extra elements to match thArray length
        tdArray = tdArray.slice(0, thArray.length);
      } else if (tdArray.length < thArray.length) {
        // Fill in the blanks with null values to match thArray length
        let startIndex = tdArray.length;
        tdArray.length = thArray.length;
        tdArray.fill(null, startIndex);
      }

      if (tdArray.length === thArray.length) {
        for (let i = 0; i < thArray.length; i++) {
          rowObject[thArray[i]] = tdArray[i];
        }
      }
    }

    rowObject.action = action;
    // console.log(`action: ${action}`); 
    // Object.keys(rowObject).length > 0 && console.log("handleSingleAction rowObject", rowObject);

    let originalFieldNames = Object.entries(renamedFields).reduce((acc, [key, value]) => ({ ...acc, [dehumanize(value)]: key }), {});
    let neededParams = ['request_queue_id', 'request_id', 'request_social_id', 'qid', 'auth_id'];
    let params = Object.entries(rowObject).reduce((acc, [key, value]) => {
      if (key in originalFieldNames) {
        key = originalFieldNames[key];
      }
      if (neededParams.includes(key)) {
        acc[key] = key in acc ? `${acc[key]}, ${value}` : value;
      }
      return acc;
    }, { action });

    let response;
    let alertObj = { ...tableAlert };
    try {
      if (!isValid) {
        throw new Error(`Invalid Selection! ${tableAlert.message}`);
      }

      setLoadingTable(true);
      response = await request_queue.downloadBulkAction(params);
      alertObj.message = ""; // Reset alert when successful 
      setActionBitMask(0);
      setDropdownBitMask(31);
      setInvokeUpdate(prev => !prev);
    } catch (error) {
      console.log("Error on request_queue.downloadBulkAction with params", params);
      console.log(error);
      response = error?.response || {};
      response.status = 500;
      response.statusText = `${error.message}`;
      if (!response?.data) {
        response.data = { err: 500, error: error.error, details: `${error.message}` };
      }
    } finally {
      setLoadingTable(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    // console.log("handleSingleAction", { status, statusText, data });

    if (status > 399) {
      alertObj.alert_type = "danger";
      alertObj.message = `${statusText}`;
    }

    setShowTableAlert(!!alertObj.message);
    if (!!alertObj.message) {
      alertObj.message = alertObj.message.trim();
      setTableAlert(prevState => ({ ...prevState, ...alertObj }));
    }
    return;
  };

  const getServerStatus = async ({ checkLiveStatus = false } = {}) => {
    let seconds_from_last_checked = Math.round((Date.now() - downloaderServerStatus.last_checked) / 1000);

    if (checkLiveStatus) {
      if (seconds_from_last_checked < seconds_between_server_checks && !['running', 'stopped', 'blacklisted'].includes(downloaderServerStatus?.status)) {
        await Swal.fire({
          icon: 'info',
          title: "Too Soon!",
          text: `Please wait ${seconds_between_server_checks - seconds_from_last_checked} seconds before checking again.`,
          showCancelButton: true,
        });
        return;
      }
      let rebootSteps = ['running', 'stopping', 'stopped', 'pending', 'running'];
      let nextStep = (downloaderServerStatus?.status === 'blacklisted') ? 'stopping' : rebootSteps[rebootSteps.indexOf(downloaderServerStatus?.status) + 1] || 'running';
      let title = `Are you sure you want to set ${humanize((downloaderServerStatus.alias || '').toUpperCase().replace('_SERVER', ''))} from ${downloaderServerStatus?.status} to ${nextStep}?`;
      let confirmButtonText = `Yes, set  ${downloaderServerStatus.alias || 'server'} to ${nextStep}!`;
      let confirmed = await Swal.fire({
        title,
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText,
      }).then((result) => (result.isConfirmed));
      if (!confirmed) {
        return;
      }
    }

    let alertObj = { ...tableAlert };
    let serverStatus = "unknown";
    setLoadingTable(true);
    let { status, statusText, data } = await admin.getServerStatus({ server_alias: downloaderServerStatus.alias, dryRun: !Boolean(checkLiveStatus) }).catch((error) => {
      console.log("Error on admin.getServerStatus", error);
    }) || {};
    setLoadingTable(false);

    if (status > 399) {
      console.log("Error on admin.getServerStatus", { status, statusText, data });
      alertObj.alert_type = "danger";
      alertObj.message = `Error on admin.getServerStatus ${statusText}`;
    } else {
      alertObj.alert_type = "info";
      alertObj.message = "";
    }

    let { instance_state = [], status_details, status: _status } = data?.results || data || {};
    if (instance_state.length > 0) {
      serverStatus = instance_state[0]?.state || "unknown";
    }
    setDownloaderServerStatus(prevState => ({ ...prevState, status: serverStatus, ...(checkLiveStatus && { last_checked: Date.now() }) }));

    if (checkLiveStatus) {
      alertObj.message = status_details || _status || statusText || "";
    }
    if (!!alertObj.message) {
      setShowTableAlert(!!alertObj.message);
      setTableAlert(prevState => ({ ...prevState, ...alertObj }));
      await Swal.fire({
        icon: alertObj.alert_type === 'danger' ? 'error' : 'success',
        title: "Done!",
        text: alertObj.message,
        showConfirmButton: true,
        background: false,
        backdrop: false
      });
    }
  };

  const handleServerReboot = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    let seconds_from_last_checked = Math.round((Date.now() - downloaderServerStatus.last_checked) / 1000);
    if (seconds_from_last_checked < seconds_between_server_checks && !['running', 'stopped', 'blacklisted'].includes(downloaderServerStatus?.status)) {
      await Swal.fire({
        icon: 'info',
        title: "Too Soon!",
        text: `Please wait ${seconds_between_server_checks - seconds_from_last_checked} seconds before checking again.`,
        showCancelButton: true,
      });
      return;
    }

    let action = ['running', 'stopped', 'blacklisted'].includes(downloaderServerStatus.status || 'unknown') ? 'toggle_reboot' : 'check_status';
    if (action === "check_status") {
      return getServerStatus({ checkLiveStatus: true });
    }

    if (IntervalId) {
      clearInterval(IntervalId);
    }
    let rebootSteps = ['running', 'stopping', 'stopped', 'pending', 'running'];
    let nextStep = (downloaderServerStatus?.status === 'blacklisted') ? 'stopping' : rebootSteps[rebootSteps.indexOf(downloaderServerStatus?.status) + 1] || 'running';
    let title = `Are you sure you want to set ${humanize((downloaderServerStatus.alias || '').toUpperCase().replace('_SERVER', ''))} from ${downloaderServerStatus?.status} to ${nextStep}?`;
    let confirmButtonText = `Yes, set  ${downloaderServerStatus.alias || 'server'} to ${nextStep}!`;
    let confirmed = await Swal.fire({
      title,
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText,
    }).then((result) => (result.isConfirmed));
    if (!confirmed) {
      return;
    }

    let alertObj = { ...tableAlert };
    let serverStatus = "unknown";
    setLoadingTable(true);
    let { status, statusText, data } = await admin.toggleReboot({ server_alias: downloaderServerStatus.alias }).catch((error) => {
      console.log("Error on admin.handleServerReboot", error);
    }) || {};
    setLoadingTable(false);

    if (status > 399) {
      console.log("Error on admin.handleServerReboot", { status, statusText, data });
      alertObj.alert_type = "danger";
      alertObj.message = `Error on admin.getServerStatus ${statusText}`;
    } else {
      alertObj.alert_type = "info";
    }

    let { instance_state = [], status_details, status: _status } = data?.results || data || {};
    alertObj.message = status_details || _status || statusText || "";
    if (instance_state.length > 0) {
      serverStatus = instance_state[0]?.state || "unknown";
    }
    setDownloaderServerStatus(prevState => ({ ...prevState, status: serverStatus, last_checked: Date.now() }));
    if (!!alertObj.message) {
      setShowTableAlert(!!alertObj.message);
      setTableAlert(prevState => ({ ...prevState, ...alertObj }));
      await Swal.fire({
        icon: alertObj.alert_type === 'danger' ? 'error' : 'success',
        title: "Done!",
        text: alertObj.message,
        showConfirmButton: true,
        background: false,
        backdrop: false
      });
    }
  };

  const initFn = async () => {
    if (superAdminAccess) {
      await getServerStatus();
    }
  };

  useEffect(() => {
    let mounted = true;
    (mounted) && initFn();
    return () => mounted = false;
  }, []);

  useEffect(() => {
    let mounted = true;
    const init = async () => {
      setSelectedRows([]);
      await getTableData();
    }
    if (mounted) init();
    return () => mounted = false;
  }, [invokeUpdate, activePage, org_id]);

  return (
    <div className="page-container" ref={downloadManagementRef}>
      {superAdminAccess ? <div className="d-flex justify-content-between">
        <h4 className="h-inline">Download Management</h4>
        <Button
          type="submit"
          size="md"
          variant={'running' === downloaderServerStatus.status ? "success"
            : (['stopped', 'terminated', 'blacklisted'].includes(downloaderServerStatus.status) ? "danger"
              : ['pending', 'unknown'].includes(downloaderServerStatus.status) ? "info" : "warning")}
          onClick={handleServerReboot}
          {...(loadingTable) && { disabled: true }}
        >{`${humanize((downloaderServerStatus.alias || '').toUpperCase().replace('_SERVER', ''))} is ${downloaderServerStatus.status} | `}
          <strong>{['running', 'blacklisted'].includes(downloaderServerStatus.status) ? 'Stop' : 'stopped' === downloaderServerStatus.status ? 'Start' : 'Check Status'}</strong>
        </Button>
      </div> : <h4>Download Management</h4>}
      <hr />
      <Alert
        dismissible
        onClose={() => setShowTableAlert(false)}
        show={showTableAlert}
        variant={tableAlert.alert_type}
      >{tableAlert.message}</Alert>

      {(selectedRows.length > 1) ? (<div className="bulk-actions mb-3">
        {((actionBitMask & 16) === 16) && (<Button
          className="me-3"
          size="md"
          variant="primary"
          onClick={handleBulkAction}
          disabled={loadingTable}
        >{isValid ? "✔" : "✘"} Mark {selectedRows.length === tableRows.length ? "All" : selectedRows.length} as Good</Button>)}
        {((actionBitMask & 8) === 8) && (<Button
          className="me-3"
          size="md"
          variant="primary"
          onClick={handleBulkAction}
          disabled={loadingTable}
        >{isValid ? "✔" : "✘"} Mark {selectedRows.length === tableRows.length ? "All" : selectedRows.length} as Unavailable</Button>)}
        {((actionBitMask & 4) === 4) && (<Button
          className="me-3"
          size="md"
          variant="primary"
          onClick={handleBulkAction}
          disabled={loadingTable}
        >{isValid ? "✔" : "✘"} Retry {selectedRows.length === tableRows.length ? "All" : selectedRows.length} Request (phase 1)</Button>)}
        {((actionBitMask & 2) === 2) && (<Button
          className="me-3"
          size="md"
          variant="primary"
          onClick={handleBulkAction}
          disabled={loadingTable}
        >{isValid ? "✔" : "✘"} Retry {selectedRows.length === tableRows.length ? "All" : selectedRows.length} Download (phase 2)</Button>)}
        {((actionBitMask & 1) === 1) && (<Button
          className="me-3"
          size="md"
          variant="primary"
          onClick={handleBulkAction}
          disabled={loadingTable}
        >{isValid ? "✔" : "✘"} Retry {selectedRows.length === tableRows.length ? "All" : selectedRows.length} Core Process (phase 3)</Button>)}
      </div>) : (<div className="filter-section">
        <Form onSubmit={searchRequests} onReset={clearFilters} autoComplete="off" className="mb-3">
          {splitArrayChunksAlt(Object.entries(filterFormState).filter(([k, v]) => !['show_all'].includes(k)), 12, 14)
            .map((chunk, idx, arr) => (<Row key={`chunk-${idx}`}>
              <Col xs={12} md={12} lg={12} className="mb-3 d-flex justify-content-start ps-0">
                {chunk.map(([key, value], index) => (<Form.Group controlId={key} className="me-2 mt-2" key={`${idx}-${index}-${key}`}>
                  <Form.Label>{humanize(key)}</Form.Label>
                  {['job_type', 'zip_type'].includes(key) || `${key}`.toLowerCase().includes("status") ? (<Form.Select size="sm" name={key} value={value} onChange={handleFormChange} >
                    <option value="">--</option>
                    {key in statusKeyMap && Array.isArray(statusKeyMap[key]) && statusKeyMap[key].length > 0 && statusKeyMap[key].map(({ status, label, css_class = "text-primary" } = {}, idx) => (
                      <option
                        key={`${status}-${idx}`}
                        value={status}
                        {...(css_class && { className: css_class })}
                      >
                        {label ?? humanize(status)}
                      </option>
                    ))}
                  </Form.Select>) : (<StyledFormControl
                    key={`${key}-${index}`}
                    name={key}
                    value={value}
                    size="sm"
                    type={['started_at', 'delivered_at', 'end_date'].includes(key) ? "date" : "text"}
                    data-index={index}
                    className="w-auto"
                    onChange={handleFormChange}
                  />)}
                </Form.Group>))}
              </Col>
            </Row>))}

          <Row className="pt-3">
            <Col className="ps-2">
              <StyledListNoBorder>
                <StyledGreenCheckbox className="list-group-item">
                  <Form.Control
                    name="show_all"
                    type="checkbox"
                    title="Show All"
                    className="me-2"
                    checked={filterFormState.show_all}
                    onChange={handleFormChange}
                  />
                  <span className="ms-2">Show All</span>
                </StyledGreenCheckbox>
              </StyledListNoBorder>
            </Col>
            <Col className="d-flex justify-content-end">
              <Button className="primary-button" type="submit" name="apply" {...(loadingTable) && { disabled: true }} >Apply Filters</Button>
              <Button className="remove-button ms-3" type="reset" name="clear" {...(loadingTable) && { disabled: true }} >Clear Filters</Button>
            </Col>
          </Row>
        </Form>
      </div>)}

      {loadingTable ? (<Loader className="text-info" style={null} />) : (<Table hover>
        <thead>
          <tr>
            {Array.isArray(tableRows) && tableRows.length > 0 && (<th key="0-select_all">
              <StyledGreenCheckbox>
                <Form.Control
                  key="select_all_rows"
                  name="select_all_rows"
                  className="me-2"
                  type="checkbox"
                  title="Select All Rows"
                  defaultChecked={false}
                  onChange={handleTableRowClick}
                />
              </StyledGreenCheckbox>
            </th>)}
            <th {...((tableRows || []).length === 0) && { colSpan: "2" }}>Count {dataCount}</th>
            {tableHeader.filter((k) => !skipFields.includes(k)).map((header, index, arr) => (
              <th key={`${index}-${header}`}>{(header.includes("_") || header.charAt(header.length - 1) === header.charAt(header.length - 1).toLowerCase()) ? (humanize(header)) : header}</th>))
            }
          </tr>
        </thead>
        <tbody>{Array.isArray(tableRows) && tableRows.length > 0 ? tableRows.map((row = {}, tr_index) => {
          let parsedOpt = {};
          if (typeof row?.requested_options === "string") {
            try {
              if (row.requested_options.endsWith(',')) {
                row.requested_options = row.requested_options.slice(0, -1);
              }
              if (row.requested_options[row.requested_options.length - 2] === ",") {
                row.requested_options = row.requested_options.slice(0, -2);
              }
              if (!row.requested_options.endsWith('}')) {
                row.requested_options += '}';
              }
              parsedOpt = JSON.parse(`${row.requested_options}`);
            } catch (e) {
              parsedOpt = row.requested_options;
            }
          }

          if (isObject(parsedOpt)) {
            let aliasCountLabels = {
              exhibited_files: 'exhibited_count',
              unexhibited_files: 'unexhibited_count',
              unexhibited_fallback: 'unexhibited_count',
              unexibited_fallback: 'unexhibited_count',
              audio_files: 'media_count',
            };

            let pdfOptions = ['include_toc', 'include_titles', 'include_backtotop_link', 'ocr_processing', 'combine_pdf'];
            parsedOpt = Object.fromEntries(Object.entries(parsedOpt).filter(([key, value]) => Boolean(value) && !pdfOptions.includes(key)));
            row.requested_options = Object.entries(parsedOpt).reduce((acc, [key, value]) => {
              if (Boolean(value)) {
                if (key.endsWith('}')) {
                  label = key.slice(0, -1);
                }

                // Extract Counts 'exhibited_count', 'unexhibited_count', 'media_count'
                let label = key;
                if (key in aliasCountLabels && aliasCountLabels[key] in row) {
                  label = `${key} [${Number(row[aliasCountLabels[key]])}]`;
                }

                acc.push(label);
              }
              return acc;
            }, []).join(", ");
          }

          if (row.requested_options.endsWith('}')) {
            row.requested_options = row.requested_options.slice(0, -1);
          }

          let title = row?.requested_options ? `Options Requested: ${row.requested_options}.\n` : "";
          if (row?.title) {
            (!title.endsWith(" ") && !title.endsWith("\n")) && (title += " ");
            title += `PDF: ${row.title}\n`;
          }
          if (row?.firm_id) {
            (!title.endsWith(" ") && !title.endsWith("\n")) && (title += " ");
            title += `firm_id: ${row.firm_id}`;
          }
          if (row?.matter_id) {
            (!title.endsWith(" ") && !title.endsWith("\n")) && (title += " ");
            title += `matter_id: ${row.matter_id}`;
          }
          if (row?.request_queue_id) {
            (!title.endsWith(" ") && !title.endsWith("\n")) && (title += " ");
            title += `qid: ${row.request_queue_id}`;
          }
          if (row?.ssn) {
            (!title.endsWith(" ") && !title.endsWith("\n")) && (title += " ");
            title += `ssn: ${row.ssn.slice(-4)}`;
          }
          if (row?.zip_type) {
            (!title.endsWith(" ") && !title.endsWith("\n")) && (title += " ");
            title += `zip_type: ${row.zip_type}`;
          }
          if (row?.request_id) {
            (!title.endsWith(" ") && !title.endsWith("\n")) && (title += " ");
            title += `request_id: ${row.request_id}`;
          }

          const FIFTEEN_MIN_MS = 15 * 60 * 1000;
          let className = "Started At" in row && row["Started At"] && moment(Date.now() - FIFTEEN_MIN_MS).isAfter(new Date(row["Started At"])) ? (row?.can_process_requests ? "" : "table-secondary") : "light-blue";

          return (<tr
            key={`${tr_index}`}
            data-row={tr_index}
            data-claimant_id={row.claimant_id}
            data-matter_id={row.matter_id}
            data-request_queue_id={row.request_queue_id}
            data-request_id={row.request_id}
            data-request_social_id={row.request_social_id}
            data-qid={row.qid}
            data-auth_id={row.auth_id}
            onClick={handleTableRowClick}
            {...(className) && { className }}
            {...(title) && { title }}
          >
            <td key="0-checkbox" width={45} data-col={0}>
              <StyledGreenCheckbox>
                <Form.Control
                  className="me-2"
                  type="checkbox"
                  name={`row_${tr_index}`}
                  title={`Select ${tr_index}`}
                  defaultChecked={false}
                />
              </StyledGreenCheckbox>
            </td>
            <td data-col={1}>
              <Dropdown onClick={handleTableRowClick} >
                <Dropdown.Toggle className="simple-menu-button" variant="primary" id="dropdown-basic">&#x22EE;</Dropdown.Toggle>
                <Dropdown.Menu>
                  {((dropdownBitMask & 16) === 16) && (<Dropdown.Item onClick={handleSingleAction}>{isValid ? "✔" : "✘"} Mark as Good</Dropdown.Item>)}
                  {((dropdownBitMask & 8) === 8) && (<Dropdown.Item onClick={handleSingleAction}>{isValid ? "✔" : "✘"} Mark as Unavailable</Dropdown.Item>)}
                  {((dropdownBitMask & 4) === 4) && (<Dropdown.Item onClick={handleSingleAction}>{isValid ? "✔" : "✘"} Retry Request (phase 1)</Dropdown.Item>)}
                  {((dropdownBitMask & 2) === 2) && (<Dropdown.Item onClick={handleSingleAction}>{isValid ? "✔" : "✘"} Retry Download (phase 2)</Dropdown.Item>)}
                  {((dropdownBitMask & 1) === 1) && (<Dropdown.Item onClick={handleSingleAction}>{isValid ? "✔" : "✘"} Retry Core Process (phase 3)</Dropdown.Item>)}
                </Dropdown.Menu>
              </Dropdown>
            </td>
            {Object.entries(row).filter(([k, v]) => !skipFields.includes(k)).map(([key, value], td_index) => (<td
              key={`${td_index + 2}-${key}`}
              data-col={`${td_index + 2}`}
              {...(key === "Attorney" && row?.firm) && { title: `${row.firm} auth_id=${row?.auth_id}` }}
            >
              {showClaimantLinks && key === "Claimant"
                ? <Link to={`/claimant-details/${row.claimant_id}`} title={`claimant_id=${row.claimant_id} matter_id=${row.matter_id} ssn=${row?.ssn?.slice(-4)} last_captured=${row?.last_captured ? formatDate(row.last_captured) : "null"}`} onClick={(e) => e.stopPropagation()}>{humanize(value)}</Link>
                : dateFields.includes(key)
                  ? (value || "").replace(moment(Date.now()).format("MM/D/YYYY "), "")
                  : (["job type", "status"].some((f) => key.toLowerCase().includes(f))
                    ? (<StyledSpan data-value={value}>{humanize(value)}</StyledSpan>)
                    : key === "Attorney" && row?.auth_id
                      ? <Link to={`/bso-account-management-details/${row?.auth_id}`} onClick={(e) => e.stopPropagation()}>{humanize(value)}</Link>
                      : humanize(value, key === "prefix"))
              }
            </td>))}
          </tr>)
        }) : (<tr><td colSpan={tableHeader.filter((k) => !skipFields.includes(k)).length + 2}><strong>No downloads found.</strong></td></tr>)}
        </tbody>
        {Pages.length > 1 && (<tfoot>
          <tr>
            <td className="text-right" colSpan={tableHeader.filter((k) => !skipFields.includes(k)).length + 2} >
              <nav>
                <ul className="pagination justify-content-end" data-active-page={activePage} >
                  {Pages && Pages.length > 0 && Pages.map((pageNumber, index) => (
                    <li key={`page-${pageNumber}-${index}`} className={`page-item ${pageNumber === activePage ? "disabled" : ""}`} >
                      <Link
                        to={`#page-${pageNumber}`}
                        className="page-link"
                        onClick={(e) => {
                          e.preventDefault();
                          setActivePage(pageNumber);
                        }}
                        {...(pageNumber === activePage) && { tabIndex: -1 }}
                      >{pageNumber}</Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </td>
          </tr>
        </tfoot>)}
      </Table>)}
    </div>
  );
}

export default DownloadManagement;