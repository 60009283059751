import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';

function Footer() {
  return (
    <footer
      style={{
        backgroundColor: "#F8F8F8",
        borderTop: "1px solid #E7E7E7",
        textAlign: "center",
        padding: "10px",
        verticalAlign: "middle",
        position: "fixed",
        left: "0",
        bottom: "0",
        // height: "40px",
        width: "100%"
      }}
      className="text-center" id="main-footer">
      <div id="footer-bottom">
        <div id="footer-info">
          <Row>
            <Col className="d-flex justify-content-start">
              Copyright © {new Date().getFullYear()} ERE Docs, LLC
            </Col>
            <Col className="d-flex justify-content-end">
              <Link to={"/privacy-policy"}>Privacy Policy</Link>
            </Col>
          </Row>

        </div>
      </div>
    </footer >
  );
}

export default Footer;
