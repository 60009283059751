import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUserData } from '../contexts/AuthContext';

// Styles 
import { Row, Col, Alert } from 'react-bootstrap';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import 'react-tabs/style/react-tabs.css';

// Components 
import DownloadRequestLogs from '../pages/DownloadRequestLogs';
import UploadRequestLogs from '../pages/UploadRequestLogs';

const RequestsTabs = ({ org_id } = {}) => {
  const { userData } = useUserData();
  let { passport: {
    user: {
      organization: {
        org_id: default_org_id,
        prefix,
      } = {},
    } = {} } = {} } = userData || {};

  (typeof default_org_id !== "number") && (default_org_id = Number(default_org_id));
  (typeof org_id !== "number") && (org_id = Number(org_id));
  (!org_id && default_org_id) && (org_id = default_org_id);
  (org_id !== default_org_id) && (prefix = null);

  const [loadingPage, setLoadingPage] = useState(false);
  const [showPageAlert, setShowPageAlert] = useState(false);
  const [pageAlert, setPageAlert] = useState({ message: "", alert_type: "info" });
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <div className="page-container">
      <Alert
        dismissible
        onClose={() => setShowPageAlert(false)}
        show={showPageAlert}
        variant={pageAlert.alert_type}
        className="p-2 mb-1"
      >{pageAlert.message}</Alert>
      <Row>
        <Col>

          <Tabs key="tabs" selectedIndex={tabIndex} onSelect={tabIndex => setTabIndex(tabIndex)} >
            <TabList>
              <Tab key="tab-1" >
                <h5>Download Requests</h5>
              </Tab>
              <Tab key="tab-2" >
                <h5>Upload Requests</h5>
              </Tab>
            </TabList>
            <TabPanel key="tab-1-info" >
              <Row>
                <Col>
                  <DownloadRequestLogs org_id={org_id} />
                </Col>
              </Row>
            </TabPanel>
            <TabPanel key="tab-2-info" >
              <Row>
                <Col>
                  <UploadRequestLogs org_id={org_id} />
                </Col>
              </Row>
            </TabPanel>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
}

export default RequestsTabs;