import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom"
import { useUserData } from "../../contexts/AuthContext";
import { Button, Table, Form, Row, Col } from 'react-bootstrap';

// Components 
import Loader from "../Loader";

// EREDocs Functions 
import { downloader } from '../../utils/EREDocs';
import { humanize, dehumanize, massageData, isObject } from "../../utils/";
import claimantsFakeData from '../../fake-data/claimants.json' // When loadFakeData = true

// Globals 
const loadFakeData = process.env.REACT_APP_LOAD_FAKE_DATA === "true";
const SECONDS_REFRESH_DETAILS_LOGS = Number(process.env.REACT_APP_SECONDS_REFRESH_DETAILS_LOGS || '5'); // Refresh logs every 5 seconds. Zero means no refresh.
const defaultQueryParams = { limit: 25, showDebug: false };
let IntervalId = null;

const ClaimantsTable = ({
  claimant_id = null, showFilter = true, showDebug = false, showClaimantLinks = true, refresh_interval_seconds = SECONDS_REFRESH_DETAILS_LOGS,
  tableFields = [], renamedFields = {}, dateFields = [], skipFields = [],
  showParentAlert = () => { }, setParentAlert = () => { }, parentRef = null,
} = {}) => {
  // If no claimant_id passed in force filter to be true
  if (!claimant_id && !showFilter) {
    showFilter = true;
  }
  if (claimant_id && typeof claimant_id !== 'number') {
    claimant_id = Number(claimant_id);
  }

  // Set default fields if not passed in
  if (!isObject(renamedFields)) {
    renamedFields = {};
  }
  if (!Array.isArray(dateFields)) {
    dateFields = [];
  }
  if (!Array.isArray(tableFields) || tableFields.length === 0) {
    tableFields = ['claimant', 'SSN', 'organization', 'status_of_case', 'last_known_bso_level', 'last_captured'];
    renamedFields = {
      claimant_name: 'claimant',
      last_four_social: "SSN",
      level: 'last_known_bso_level'
    };
    if (!dateFields.includes('last_captured')) {
      dateFields.push('last_captured');
    }
  }
  if (!Array.isArray(skipFields) || skipFields.length === 0) {
    skipFields = ['claimant_id', 'matter_id', 'org_id', 'auth_id', 'user_id', 'unexhibited_count', 'exhibited_count', 'media_count']; // These fields are used by functions and should not be displayed
  }

  const { userData } = useUserData();
  let { isAuthenticated = false } = userData || {};

  const [invokeUpdate, setInvokeUpdate] = useState(false);
  const [loadingLogs, setLoadingLogs] = useState(false);

  const [tableHeader, setTableHeader] = useState([]);
  const [tableRows, setTableRows] = useState([]);

  const [Pages, setPages] = useState([1]);
  const [activePage, setActivePage] = useState(1);

  // Filter form state
  const [filterFormState, setFilterFormState] = useState({
    claimant_name: '',
    last_four_social: '',
    status_of_case: '',
  });

  // Handle form change
  const handleFormChange = (event) => {
    if (!event?.target) return;
    const { name, value } = event.target;
    setFilterFormState({ ...filterFormState, [name]: value });
  };

  // Clear filters
  const clearFilters = (event) => {
    if (!event?.target) return;
    event.preventDefault();
    setFilterFormState({
      claimant_name: '',
      last_four_social: '',
      status_of_case: '',
    });
    return setInvokeUpdate(prev => !prev);
  }

  // Search Claimants
  const searchClaimants = async (event) => {
    if (!event?.target) return;
    event.preventDefault();
    return setInvokeUpdate(prev => !prev);
  }

  /**
  * handleTableRowClick
  * Extracts row items and save to rowObject and selectedItem
  * @param {*} event 
  * @returns 
  */
  const handleTableRowClick = (event) => {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation(); // Prevent other events from firing on click

    let clickedElement = event?.target || {};
    let { parentElement, dataset, tagName, type, innerText, href, id } = clickedElement;

    let action = (href || ["BUTTON", "SPAN"].includes(tagName)) ? (
      `${innerText}`.toLowerCase().includes("download") ? "download" : (
        `${innerText}`.toLowerCase().includes("cancel") ? "cancel" : "navigate"
      )
    ) : false;

    // Go up one level in the DOM but preserve innerText
    if (tagName !== "TD" && parentElement?.tagName === "TD") {
      clickedElement = parentElement;
      parentElement = clickedElement.parentElement;
      dataset = { ...dataset, ...clickedElement?.dataset };
      tagName = clickedElement?.tagName;
      if (!innerText && clickedElement?.innerText) {
        innerText = clickedElement.innerText;
      }
    }

    let rowObject = {
      innerText,
      tagName,
      ...(type && { type }),
      ...dataset,
      ...parentElement?.dataset,
    };

    // Extract row items and save to rowObject and selectedItem
    let selectedItem = {};
    if (tagName === "TD") {
      let tdArray = Array.from(parentElement.querySelectorAll("td")).map(({ innerText }) => innerText).filter((i) => i) || [];
      let thArray = Array.from(parentElement.closest("table").querySelectorAll("thead tr th")).map(({ innerText }) => dehumanize(innerText)).filter((i) => i) || [];

      if ('col' in rowObject) { // Extract selectedItem
        let col = Number(rowObject.col);
        if (col && innerText === tdArray[col]) {
          selectedItem[thArray[col]] = tdArray[col];
        }
      }

      // Match thArray length with tdArray length
      if (tdArray.length > thArray.length) {
        // slice extra elements to match thArray length
        tdArray = tdArray.slice(0, thArray.length);
      } else if (tdArray.length < thArray.length) {
        // Fill in the blanks with null values to match thArray length
        let startIndex = tdArray.length;
        tdArray.length = thArray.length;
        tdArray.fill(null, startIndex);
      }

      if (tdArray.length === thArray.length) {
        for (let i = 0; i < thArray.length; i++) {
          rowObject[thArray[i]] = tdArray[i];
        }
      }
    }

    // Extract selectedItem if failed to do so above
    if ('field' in rowObject) {
      let field = dehumanize(rowObject.field); // We must dehumanize this field since that's what happened to all of values in thArray (see above)
      if (!selectedItem[field] && rowObject[field] === innerText) {
        selectedItem[field] = rowObject[field];
      }
    }

    // Object.keys(rowObject).length > 0 && console.log("handleSocialRowClick", rowObject);
    // Object.keys(selectedItem).length > 0 && console.log("selectedItem", selectedItem);
    // action && console.log("action", action);
    return;
  };

  const getTableData = async ({ startedAt = Date.now(), headerFields = tableFields, renamed_fields = renamedFields, date_fields = dateFields, functionFields = skipFields, refresh_in_seconds = refresh_interval_seconds } = {}) => {
    let now = Date.now();
    let duration_seconds = Math.round((now - startedAt) / 1000);
    let ran_count = !refresh_in_seconds ? 1 : Math.round(duration_seconds / refresh_in_seconds) || 1; // Avoid dividing by zero
    let first_run = now === startedAt || ran_count === 1;

    // Detect if user navigated away from this page 
    let offsetWidth = parentRef?.current?.offsetWidth || 0;
    let offsetHeight = parentRef?.current?.offsetHeight || 0;
    let navigated_away = !parentRef?.current || !(offsetWidth > 0 && offsetHeight > 0);

    if (!first_run && (navigated_away || !isAuthenticated)) {
      console.log("Clearing Interval for details because user navigated away.", { IntervalId, first_run, navigated_away, isAuthenticated, offsetWidth, offsetHeight });
      clearInterval(IntervalId);
      return;
    }

    // Filter out empty values and format with { key: value } array of object 
    let filterValues = Object.entries(filterFormState).filter(([id, value]) => value); // Filter out empty values
    let filteredData = (filterValues.length === 0) ? {} : Object.entries(filterFormState).reduce((acc, [id, value]) => ({ ...acc, ...(value && { [id]: value }) }), {});
    let postData = {
      ...defaultQueryParams,
      showDebug,
      ...(claimant_id && { claimant_id }),
      ...filteredData,
      page: activePage
    };

    let alert_message = '';
    let alert_type = "info";
    let response;

    try {
      if (loadFakeData) {
        response = {
          status: 200,
          statusText: 'OK',
          data: claimantsFakeData
        };
      } else {
        response = await downloader.findClaimants(postData);
      }
      alert_message = '';
    } catch (error) {
      console.log(error);
      response = error?.response || {};
      if (!response?.data) {
        response.data = { err: 401, error: error.error, details: `${error.message}` };
      }
    }

    let { status, statusText = '', data = {} } = response;
    let { err, error, details, message, rows = [], count = 0, pageCount = 1, pages = [] } = data || {};

    if (status === 200) {
      let dataHeader = [...headerFields, ...functionFields];
      let dataRows = (!Array.isArray(rows) || rows.length === 0) ? [] : massageData(rows, dataHeader, renamed_fields, date_fields);
      if (rows.length === 0 || count === 0) {
        if (activePage > 1 && activePage === Pages[Pages.length - 1]) {
          setActivePage(Pages[Pages.length - 2]); // Make sure we don't get stuck loading nothing
        } else {
          alert_type = "info";
          alert_message = "No logs found";
        }
      }

      pages = pages.reduce((acc, item, index) => {
        if (index === 0 && item.number !== 1) {
          acc.push(1); // Always include the first page 
        }

        acc.push(item.number);

        if (index === (pages.length - 1) && !acc.includes(pageCount)) {
          acc.push(pageCount); // Always include the last page
        }
        return acc;
      }, []);

      setTableHeader(dataHeader);
      setTableRows(dataRows);
      setPages(pages);
    } else {
      alert_type = "danger";
      alert_message = `[${status ?? err}] ${statusText} `;    // API Error Code 
      alert_message += data?.status ? `${data.status} ` : ""; // API Status 
      alert_message += error ? `${error} ` : "";              // API Error 
      alert_message += (typeof response?.data === 'string') ? response.data : message ?? (details ? `${details} ` : "") + `Failed to read data for request details.`;
      alert_message = alert_message.trim();
    }

    showParentAlert(!!alert_message);
    if (alert_message) {
      setParentAlert(prevState => ({ ...prevState, message: alert_message, alert_type }));
    }

    if (first_run && refresh_in_seconds) {
      if (IntervalId !== null) {
        console.log("Clearing Interval for details because new interval is being started.", { IntervalId });
        clearInterval(IntervalId);
      }
      let interval_ms = refresh_in_seconds * 1000;
      IntervalId = setInterval(getTableData.bind(null, { startedAt, headerFields, renamed_fields, date_fields, functionFields, refresh_in_seconds }), interval_ms); // Next render every refresh_in_seconds seconds
      console.log("Set Interval for details!", { IntervalId, activePage, loadingLogs, startedAt });
    }

    // console.log("getTableData", { first_run, IntervalId, ran_count, duration_seconds, now, startedAt, navigated_away, isAuthenticated, offsetWidth, offsetHeight, status, statusText });
    return data;
  }; // END getTableData

  // ===============[ useEffect ]==================================
  useEffect(() => {
    const init = async () => {
      showParentAlert(false);
      setLoadingLogs(true);
      await getTableData().then((cData) => {
        setLoadingLogs(false);
      });
    }

    let mounted = true;
    if (mounted) {
      if (IntervalId !== null) {
        console.log("Clearing Interval for claimants because changed detected", { IntervalId });
        clearInterval(IntervalId);
      }
      init(); // Initialize Request Data for Table
    }

    // Returned function will be called on component unmount 
    return () => {
      if (IntervalId !== null) {
        console.log("Clearing Interval for claimants because this component unmounted.", { IntervalId });
        clearInterval(IntervalId);
        mounted = false;
      }
    }
  }, [invokeUpdate, activePage]);

  return (
    <div>
      {showFilter && (<div className="filter-section">
        <h5>Search Claimants</h5>
        <Form className="filter-form mb-3" onSubmit={searchClaimants} onReset={clearFilters} >
          <Row>
            <Col>
              <Form.Group controlId="filterClaimantName">
                <Form.Label>Claimant Name</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="claimant_name"
                  value={filterFormState.claimant_name}
                  onChange={handleFormChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="filterLastFourSocial">
                <Form.Label>Last 4 SSN</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="last_four_social"
                  value={filterFormState.last_four_social}
                  onChange={handleFormChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="filterStatusOfCase">
                <Form.Label>Status of Case</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="status_of_case"
                  value={filterFormState.status_of_case}
                  onChange={handleFormChange}
                />
              </Form.Group>
            </Col>
            <Col className="d-flex align-items-end">
              <Button className="primary-button" type="submit" name="apply" {...(loadingLogs) && { disabled: true }} >Apply Filters</Button>
              <Button className="remove-button ms-3" type="reset" name="clear" {...(loadingLogs) && { disabled: true }} >Clear Filters</Button>
            </Col>
          </Row>
        </Form>
      </div>)}

      {loadingLogs ? (<Loader className="text-info" style={null} />) : (<Table hover>
        <thead>
          <tr>{tableHeader.filter((k) => !skipFields.includes(k)).map((header, index, arr) => (<th
            key={`${index}-${header}`}
          >{(header.includes("_")) ? (humanize(header)) : header}
          </th>))}</tr>
        </thead>
        <tbody>{Array.isArray(tableRows) && tableRows.length ? tableRows.map((row = {}, index) => {
          return (<tr
            onClick={handleTableRowClick}
            key={`${index}-${row.ID}`}
            data-row={index}
            data-claimant_id={row.claimant_id}
            data-matter_id={row.matter_id}
            data-org_id={row.org_id}
            data-user_id={row.user_id}
            data-unexhibited_count={row.unexhibited_count}
            data-exhibited_count={row.exhibited_count}
            data-media_count={row.media_count}
          >{Object.entries(row).filter(([k, v]) => !skipFields.includes(k)).map(([key, value], idx) => {
            return (<td key={`${index}-${key}`} data-col={idx} data-field={key}>
              {showClaimantLinks && key === "claimant_name" ? (
                <Link to={`/claimant-details/${row.claimant_id}`} title={`claimant_id=${row.claimant_id} matter_id=${row.matter_id}`} onClick={(e) => e.stopPropagation()}>{humanize(value)}</Link>
              ) : (dateFields.includes(key)) ? value : humanize(value)}
            </td>);
          })}
          </tr>)
        }) : (<tr><td colSpan={tableHeader.filter((k) => !skipFields.includes(k)).length}>No socials included in the request.</td></tr>)}
        </tbody>
        {Pages.length > 1 && (<tfoot>
          <tr>
            <td className="text-right" colSpan={tableHeader.filter((k) => !skipFields.includes(k)).length + 1} >
              <nav>
                <ul className="pagination justify-content-end" data-active-page={activePage} >
                  {Pages && Pages.length > 0 && Pages.map((pageNumber, index) => (
                    <li key={`page-${pageNumber}-${index}`} className={`page-item ${pageNumber === activePage ? "disabled" : ""}`} >
                      <Link
                        to={`#page-${pageNumber}`}
                        className="page-link"
                        onClick={(e) => {
                          e.preventDefault();
                          setActivePage(pageNumber);
                        }}
                        {...(pageNumber === activePage) && { tabIndex: -1 }}
                      >{pageNumber}</Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </td>
          </tr>
        </tfoot>)}
      </Table>)}
    </div>
  )
}

export default ClaimantsTable;