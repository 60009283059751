import styled from "styled-components";
import { ProgressBar } from 'react-bootstrap';

/**
 * getProgressClass
 * Examples
 *   variant: getProgressClass({ now })
 *   className: getProgressClass({ now, returnVariant: false })
 *   animation: ${({ now = 1 }) => getProgressClass({ now, returnSpeed: true }) + "s linear infinite progress-bar-stripes"};
 * 
 * @param {*} param0 
 * @returns 
 */
const getProgressClass = ({ now = 0, returnVariant = true, returnSpeed = false }) => {
  now = parseInt(now);
  if (now < 25) {
    return returnSpeed ? 1 : (returnVariant ? "danger" : "bg-danger");
  } else if (now < 50) {
    return returnSpeed ? 0.75 : (returnVariant ? "warning" : "bg-warning");
  } else if (now < 75) {
    return returnSpeed ? 0.5 : (returnVariant ? "info" : "bg-info");
  } else {
    return returnSpeed ? 0.25 : (returnVariant ? "success" : "bg-success");
  }
};

export const StyledProgressBar = styled(ProgressBar).attrs(({ now = 0 } = {}) => ({
  variant: getProgressClass({ now }),
  now: now,
  label: `${now}%`,
  striped: true,
  animated: true,
}))`

  height: 1.5rem;
  font-size: 1rem;  

  & > .progress-bar {
    border-radius: .25rem;
    border: 1px solid var(--light-grey);
    font-weight: bold;
  }

  & > .progress-bar-animated {
    animation-direction: reverse !important; 
    animation: ${({ now = 1 }) => (1.20 - (now / 100)).toFixed(2) + "s linear infinite progress-bar-stripes"};
  }
`;
