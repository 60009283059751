import React, { Suspense, lazy } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Navbar, Nav } from 'react-bootstrap';

// Components
import Loader from "../components/Loader";
import { UploadModal, DownloadRequestModal, BSOAccountModal } from './Modals/';
const ModalComponents = { UploadModal, DownloadRequestModal, BSOAccountModal };
const LazyComponent = lazy(() => import("../components/CapitalizedReferenceComponentExternals"));

// EREDocs functions
import { admin } from "../utils/EREDocs";
import { getUrlPath } from "../utils/"

const SubHeader = ({ pathname = "/", HeaderMenu = [], HeaderSubMenu = {}, hasEREAccess = false, AdminAccess = false, group_id = 6, OVERRIDE_LOGIN = false } = {}) => {
  (group_id && typeof group_id === 'string') && (group_id = Number(group_id));
  const navigate = useNavigate();
  let activeHeaderMenu = Object.entries(HeaderSubMenu).reduce((acc, [menu, subMenu]) => {
    let found = subMenu.find(({ page_link = "#", page_title = '', ereAccess = false, adminOnly = false, groupOnly = false, disabled = false }) => {
      if (page_link.includes(":") && pathname.includes(page_link.substring(0, page_link.indexOf(':')))) {
        let id_key = page_link.split(":").pop() || null;
        let id_value = Number(pathname.split("/").pop()) || null;
        if (id_key && id_value) {
          let subMenuIndex = subMenu.findIndex((m) => m?.page_link?.includes(":org_id"));
          if (subMenuIndex !== -1) {
            HeaderSubMenu[menu][subMenuIndex].params = { [id_key]: id_value };
          }
          acc[id_key] = id_value;
        }
      }

      return (page_title && !disabled &&
        ((ereAccess && hasEREAccess) || (adminOnly && AdminAccess) || (!ereAccess && !adminOnly)) &&
        (!groupOnly || groupOnly === group_id || OVERRIDE_LOGIN)) &&
        (pathname === page_link || page_link.includes(":") && pathname.includes(page_link.substring(0, page_link.indexOf(':'))))
    });

    if (found && !acc.page_title) {
      acc.page_title = menu;
      acc.page_link = pathname;
    }
    return acc;
  }, { page_title: '', page_link: pathname });

  if (!activeHeaderMenu || Object.values(activeHeaderMenu).filter(Boolean).length === 0) {
    activeHeaderMenu = HeaderMenu.find(({ page_link = "#", page_title = '', ereAccess = false, adminOnly = false, groupOnly = false, disabled = false }) => {
      return (page_title && !disabled &&
        ((ereAccess && hasEREAccess) || (adminOnly && AdminAccess)) &&
        (!groupOnly || groupOnly === group_id || OVERRIDE_LOGIN)) &&
        (Array.isArray(page_link) ? page_link.some((pg) => pathname === pg || pg.includes(":") && pathname.includes(pg.substring(0, pg.indexOf(':')))) :
          pathname === page_link)
    });
  }
  let activePageTitle = activeHeaderMenu?.page_title;
  let activePageLink = activeHeaderMenu?.page_link;

  const customNavigate = async (event, params) => {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation(); // Prevent other events from firing on click

    let { href = "/firm-management-details/:org_id" } = event?.target || {};
    href = `/${getUrlPath(href)}`;
    if (!href || href === "#") {
      href = "/firm-management-details/:org_id";
    }

    let results = { org_id: ":org_id" };
    try {
      let api_function;
      if (params.user_id) {
        api_function = "users";
      } else if (params.auth_id) {
        api_function = "authAccounts";
      }
      if (!api_function || !admin[api_function]) {
        throw new Error("api_function not found");
      }

      results = await admin[api_function](params).then(({ status = 500, statusText = "Internal Server Error", data: { data: loadedData = [] } = {} } = {}) => {
        (status !== 200) && console.log(`${status} ${statusText}`);
        if (Array.isArray(loadedData) && loadedData.length === 1) {
          return loadedData[0];
        }
        return { org_id: ":org_id" };
      });

    } catch (error) {
      console.log("customNavigate error", error);
      results = { org_id: ":org_id" };
    }

    let { org_id = ":org_id" } = results || {};
    let goTo = href.replace(":org_id", org_id);
    return navigate(goTo);
  }

  return (
    <div className="sub-header" >
      <Navbar className="sub-header-nav navbar-dark">
        <Nav className="me-auto">
          {activePageTitle in HeaderSubMenu && HeaderSubMenu[activePageTitle].filter(({ ereAccess = false, adminOnly = false, groupOnly = false, disabled = false, page_title = '', component_config }) => {
            return (page_title || component_config) && !disabled && ((ereAccess && hasEREAccess) || (adminOnly && AdminAccess) || (!ereAccess && !adminOnly)) && (!groupOnly || groupOnly === group_id || OVERRIDE_LOGIN);
          }).map(({ page_title, page_link, component_config, params }, i) => {
            if (component_config) {
              return (<Suspense key={i} fallback={<span className="header-nav-link"><Loader style={null} /></span>} >
                <LazyComponent components={ModalComponents} {...component_config} />
              </Suspense>)
            }
            return (<span key={`subheader-${page_title}-${i}`} className="header-nav-link">
              {(page_link.includes(":") ? (activePageTitle === page_title || activePageLink === page_link) : activePageLink === page_link)
                ? (
                  <span className="nav-link active">{page_title}</span>
                ) : (
                  <Link to={page_link} className="nav-link" {...(params && { onClick: (e) => (customNavigate.call(null, e, params)) })} >{page_title}</Link>
                )}
            </span>)
          })}
        </Nav>

        <Nav>
          <BSOAccountModal />
        </Nav>
      </Navbar>
    </div>
  );
}

export default SubHeader;