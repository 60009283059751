import React, { useState, useEffect } from 'react';
import { useUserData } from '../../contexts/AuthContext';
import { Form, Button, Alert, Col } from 'react-bootstrap';
import { StyledInputGroup } from "../../styled/Forms"
import { validParams, humanize } from "../../utils/";
import { bsoaccount_management } from "../../utils/manage";

const UpdateBSOCredentialsForm = ({ auth_id, bsoUser, closeModal = () => { }, setInvokeParentUpdate = () => { } } = {}) => {
  const { userData } = useUserData();
  let { email: default_email, auth_id: default_auth_id, bso_username: user_bso_username,
    passport: {
      user: { email = '',
        selected_auth: { auth_id: selected_auth_id, bso_username } = {},
      } = {}
    } = {}
  } = userData || {};
  (!email && default_email) && (email = default_email);
  (!bso_username && user_bso_username) && (bso_username = user_bso_username);
  (!auth_id && default_auth_id) && (auth_id = default_auth_id);
  (auth_id && typeof auth_id !== 'number') && (auth_id = Number(auth_id));
  (selected_auth_id && typeof selected_auth_id !== 'number') && (selected_auth_id = Number(selected_auth_id));
  let labelFields = {
    bsoUsername: "bso_username",
    bsoPassword: "bso_password",
  };

  const [loadingForm, setLoadingForm] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [modalAlert, setModalAlert] = useState({ message: "Please ensure you enter an 8 digit BSO Account Username & the correct case-sensitive password for the account.", alert_type: "info" });
  const [formState, setFormState] = useState({
    bsoUsername: bsoUser ? bsoUser : ((selected_auth_id === auth_id) ? bso_username : ""),
    bsoPassword: ""
  });

  const handleInputChange = (event) => {
    if (!event?.target) return;
    event.stopPropagation();
    let { name, value, type, checked = false } = event.target;
    (type !== 'checkbox') && event.preventDefault();
    value = (type === 'checkbox') ? checked :
      (name === 'bsoUsername' && value) ? `${value}`.toUpperCase() : value;
    setFormState((prevState) => ({ ...prevState, [name]: value }));

    // Validate Form on change
    let alertObject = { ...modalAlert };
    let requiredFields = ['bsoUsername', 'bsoPassword'];
    let testParams = Object.entries({ ...formState, [name]: value }).filter(([k, v]) => type === 'checkbox' || v).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
    let valid = validParams(testParams, requiredFields, 'required');

    if (valid) {
      const username_regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/gm; // Must Contain 8 Characters
      valid = username_regex.test(testParams.bsoUsername);
      if (valid) {
        const password_regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/gm; // Must Contain 8 Characters
        valid = password_regex.test(testParams.bsoPassword);
        if (!valid) {
          if (testParams.bsoPassword.length !== 8) {
            alertObject.message = "BSO Password must be 8 characters";
          } else {
            alertObject.message = "BSO Password must contain at least one number and one letter";
          }
        }
      } else {
        if (testParams.bsoUsername.length !== 8) {
          alertObject.message = "BSO Username must be 8 characters";
        } else {
          alertObject.message = "BSO Username must contain at least one number and one letter";
        }
      }
    } else {
      alertObject.message = "Please fill out all required fields. Missing " + requiredFields.filter((f) => !testParams[f]).map((k) => humanize(labelFields[k] ?? k)).join(', ');
    }

    setValidated(valid);
    setShowAlert(!valid);
    if (alertObject.message) {
      alertObject.message = alertObject.message.trim();
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }
    return;
  };

  // onReset 
  const cancelUpdateBSOCredentials = (event) => {
    if (event) {
      event.stopPropagation(); // Prevent other events from firing on click
      event.preventDefault();
    }
    setFormState((prev) => ({
      ...prev,
      bsoUsername: bsoUser ? bsoUser : ((selected_auth_id === auth_id) ? bso_username : ""),
      bsoPassword: ""
    }));
    if (closeModal && typeof closeModal === 'function') {
      closeModal();
    }
  }

  // onSubmit 
  const updateBSOCredentials = async (event) => {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation();
    let alertObject = { ...modalAlert };
    let payload = { auth_id, ...formState };
    let response;

    try {
      if (!validated) {
        throw new Error("Your form input is not valid. Please check your input and try again.");
      }

      setLoadingForm(true);
      response = await bsoaccount_management.updateSecrets(payload);
      alertObject.message = ""; // Clear Alert
    } catch (ex) {
      console.log(ex);
      response = ex?.response || {};
      response.status = 500;
      response.statusText = `${ex.message}`;
      if (!response?.data) {
        response.data = { ok: false, error: ex.error, statusMessage: `${ex.message}`, };
      }
    } finally {
      setLoadingForm(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    let { ok = false, statusCode = 500, statusMessage = "Internal Server Error", error, ARN, Name: SecretId, VersionId, data: SecretObject = null } = data || {};

    alertObject.alert_type = (status !== 200 || !ok) ? "danger" : "success";
    if (status !== 200 || !ok) {
      alertObject.message = (typeof response?.data === 'string') ? response.data : message ?? (details ? `${details} ` : "") + "Invalid submission.";
      alertObject.message += ` [${status}] ${statusText} `;
    } else {
      setFormState((prev) => ({
        ...prev,
        bsoUsername: bsoUser ? bsoUser : ((selected_auth_id === auth_id) ? bso_username : ""),
        bsoPassword: ""
      }));
    }
    alertObject.message += statusMessage ? `${statusMessage}` : "";
    alertObject.message += SecretId ? ` SecretId: ${SecretId} ` : "";
    alertObject.message += VersionId ? ` VersionId: ${VersionId} ` : "";
    alertObject.message += error ? ` ${error} ` : "";

    // Show Alert success or danger
    setShowAlert(!!alertObject?.message);
    if (alertObject?.message) {
      alertObject.message = alertObject.message.trim();
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }

    if (typeof setInvokeParentUpdate === 'function') {
      setInvokeParentUpdate((prevState) => !prevState);
    }
    return;
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setLoadingForm(false);
      setValidated(false);
      setShowAlert(true);
      setModalAlert((prevState) => ({ ...prevState, message: "Please ensure you enter an 8 digit BSO Account Username & the correct case-sensitive password for the account.", alert_type: "info" }));
      setFormState((prevState) => ({ ...prevState, bsoUsername: bsoUser ? bsoUser : ((selected_auth_id === auth_id) ? bso_username : "") }));
    }
    return () => mounted = false;
  }, [bsoUser]);

  return (
    <div>
      <p>For security purposes, BSO credentials are only passed through this form to avoid exposure to unauthorized entities. Credentials are stored in a secure vault that only the application has access to.</p>
      <Alert
        dismissible
        onClose={() => setShowAlert(false)}
        show={showAlert}
        variant={modalAlert.alert_type}
      >{modalAlert.message}</Alert>

      <Form validated={validated} onSubmit={updateBSOCredentials} onReset={cancelUpdateBSOCredentials} autoComplete="off">
        <StyledInputGroup className="row">
          {Object.entries(formState).map(([key, value], index) => (
            <Col key={`${index}-${key}`} xs={12} md={6} lg={6}>
              <Form.Label className="mt-3 me-2">{humanize(labelFields[key] ?? key)}</Form.Label>
              <Form.Control
                disabled={loadingForm}
                type="text"
                placeholder=""
                name={key}
                value={value}
                maxLength={8}
                length={8}
                minLength={8}
                onChange={handleInputChange}
                autoComplete="off"
                required
              />
            </Col>
          ))}
        </StyledInputGroup>

        <Col className="col-12 mt-3 d-flex justify-content-end">
          <Button className="btn btn-primary me-3" type="submit" disabled={loadingForm || !validated}>{loadingForm ? "Loading..." : "Save Changes"}</Button>
          <Button className="btn btn-secondary" type="reset" disabled={loadingForm}>{loadingForm ? "Loading..." : "Cancel"}</Button>
        </Col>
      </Form>
    </div>
  );
}

export { UpdateBSOCredentialsForm };
export default UpdateBSOCredentialsForm;