import styled from "styled-components";
import { Card } from 'react-bootstrap';

export const StyledCardNoBorder = styled(Card)`
  width: 100%;
  border: none !important;
  box-shadow: none !important;  

  & > .card-header {
    border-bottom: none; 
    padding: 0 0 14px 0; 
  }
  
  & > .card-body {
    padding: 0px;
  }
`;

export const StyledCardLight = styled(Card)`
  border: none !important;
  box-shadow: none !important;

  & > .card-header {
    background-color: var(--white) !important;
    color: #000 !important;
    padding-left: 0px;
    border-bottom: 1px solid var(--gray-line);
  }
`;

