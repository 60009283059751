import React, { useState, useEffect } from 'react';
import { useUserData } from '../../contexts/AuthContext';
import { Form, Button, Alert, Row, Col } from 'react-bootstrap';

// EREDocs Functions 
import { admin } from "../../utils/EREDocs";
import { formatPhoneNumber, validParams } from "../../utils/";

const LawFirmForm = ({ org_id, closeModal = () => { }, setInvokeParentUpdate = () => { } } = {}) => {
  const { userData } = useUserData();
  let { passport: { user: { org_id: session_org_id,
    organization: { org_id: default_firm_id, organization: default_firm_name, prefix: default_prefix, status: default_status,
      external_id: default_external_id, assigned_number: default_phone_number, address: default_address, city: default_city, state: default_state, zip: default_zip,
      billing_address: default_billing_address, billing_city: default_billing_city, billing_state: default_billing_state, billing_zip: default_billing_zip }
  } = {} } = {} } = userData || {};
  (org_id && typeof org_id !== 'number') && (org_id = Number(org_id));
  (session_org_id && typeof session_org_id !== 'number') && (session_org_id = Number(session_org_id));
  (default_firm_id && typeof default_firm_id !== 'number') && (default_firm_id = Number(default_firm_id));
  (!default_firm_id && session_org_id) && (default_firm_id = session_org_id);
  (org_id !== 0 && !org_id && default_firm_id) && (org_id = default_firm_id);

  const [loadingForm, setLoadingForm] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [modalAlert, setModalAlert] = useState({
    message: "Validation Error",
    alert_type: "info",
  });
  const [lawFirms, setLawFirms] = useState([]);
  const [editableForm, setEditableForm] = useState(!org_id);
  const [previousFormValues, setPreviousFormValues] = useState({});
  const [formState, setFormState] = useState({
    status: 'inactive',
    firmName: '',
    prefix: '',
    externalFirmId: '',
    phoneNumber: '',
    firmParents: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    billingAddress: '',
    billingCity: '',
    billingState: '',
    billingZip: '',
  });

  // ===============[ handleInputChange ]==================================
  const handleInputChange = (event) => {
    if (!event?.target?.name) {
      return;
    }
    let alertObject = {
      message: "",
      alert_type: "info",
    }
    let { name, value, type, checked = false } = event.target;
    if (type === 'checkbox') {
      value = checked;
    } else if (name === 'phoneNumber') {
      value = formatPhoneNumber(value);
    }
    setFormState((prevState) => ({ ...prevState, [name]: value }));

    // Validate Form on change 
    let requiredFields = ['firmName', 'prefix', 'firmParents'];
    let testParams = Object.entries({ ...formState, [name]: value }).filter(([k, v]) => v).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
    let valid = validParams(testParams, requiredFields, 'required');
    setValidated(valid);
    setShowAlert(!valid);
    if (!valid) {
      alertObject.message = "Please fill out all required fields. Missing " + requiredFields.filter((f) => !testParams[f]).join(', ');
    }
    if (alertObject.message) {
      alertObject.message = alertObject.message.trim();
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }
    return;
  };

  // ===============[ loadLawFirm ]==================================
  const loadLawFirm = async () => {
    let loadedValues = (org_id === default_firm_id) ? {
      status: default_status ?? 'inactive',
      firmName: default_firm_name,
      prefix: default_prefix,
      externalFirmId: default_external_id,
      phoneNumber: default_phone_number,
      firmParents: `${default_firm_id}`.includes('5') ? default_firm_id : `${default_firm_id}, 5`,
      address: default_address,
      city: default_city,
      state: default_state,
      zip: default_zip,
      billingAddress: default_billing_address,
      billingCity: default_billing_city,
      billingState: default_billing_state,
      billingZip: default_billing_zip,
    } : {
      status: 'inactive',
      firmName: '',
      prefix: '',
      externalFirmId: '',
      phoneNumber: '',
      firmParents: `${default_firm_id}`.includes('5') ? default_firm_id : `${default_firm_id}, 5`,
      address: '',
      city: '',
      state: '',
      zip: '',
      billingAddress: '',
      billingCity: '',
      billingState: '',
      billingZip: '',
    };

    if (org_id) {
      let { data: { data: loadedOrgs = [] } = {} } = await admin.organizations({ org_id });
      if (Array.isArray(loadedOrgs) && loadedOrgs.length === 1) {
        let { org_id: firm_id, status = "inactive", organization: firmName = '', prefix = '', external_id: externalFirmId = '', assigned_number: phoneNumber = '',
          org_parents: firmParents = default_firm_id, address = '', city = '', state = '', zip = '',
          billing_address: billingAddress = '', billing_city: billingCity = '', billing_state: billingState = '', billing_zip: billingZip = ''
        } = loadedOrgs[0] || {};
        (firm_id && typeof firm_id !== 'number') && (firm_id = Number(firm_id));
        (!firmParents) && (firmParents = `${firm_id}`.includes('5') ? firm_id : `${firm_id}, 5`);
        loadedValues = {
          status,
          firmName,
          prefix,
          externalFirmId,
          phoneNumber,
          firmParents,
          address,
          city,
          state,
          zip,
          billingAddress,
          billingCity,
          billingState,
          billingZip,
        }
      }
    }

    // Filter null values to be empty strings
    let phoneNumberFields = ['phoneNumber'];
    loadedValues = Object.entries(loadedValues).reduce((acc, [key, value]) => ({
      ...acc, ...{
        [key]: !value ? "" :
          phoneNumberFields.includes(key) ? formatPhoneNumber(value) : value
      }
    }), {});

    if (typeof loadedValues.firmParents !== 'string') {
      loadedValues.firmParents = `${loadedValues.firmParents}`;
    }

    if (loadedValues.firmParents && !loadedValues.firmParents.split(",").every((parent) => (lawFirms.map(({ org_id }) => Number(org_id)).includes(Number(parent))))) {
      let params = { org_ids: loadedValues.firmParents };
      await admin.organizations(params).then(({ status = 500, statusText = "Internal Server Error", data: { data: loadedLawFirms = [] } = {} } = {}) => {
        (status !== 200) && console.log(`${status} ${statusText}`);
        if (Array.isArray(loadedLawFirms) && loadedLawFirms.length > 0) {
          setLawFirms(loadedLawFirms);
        }
      });
    }

    // Save loadedValues
    setFormState(loadedValues);
    setPreviousFormValues(loadedValues);
    return loadedValues;
  }

  const editInfo = () => (setEditableForm(true));
  const cancelUpdateLawFirm = () => {
    setEditableForm(false);
    setFormState(previousFormValues);
    (typeof closeModal === 'function') && closeModal();
  }

  // ===============[ updateLawFirm ]==================================
  const updateLawFirm = async (event) => {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation();

    let alertObject = {
      message: "",
      alert_type: "info",
    };

    let response;
    try {
      if (!validated) {
        throw new Error("Your form input is not valid. Please check your input and try again.");
      }
      let saveParams = {
        ...(org_id && { org_id }),
        ...formState,
      };

      setLoadingForm(true);
      response = await admin.saveLawFirm(saveParams).then((resp) => {
        setLoadingForm(false);
        if (resp.data?.params?.org_id) {
          resp.data.params.org_id = Number(resp.data.params.org_id);
        }
        return resp;
      });
      alertObject.message = "Saved Successfully! ";
    } catch (ex) {
      setLoadingForm(false);
      console.log(ex);
      response = ex?.response || {};
      response.status = 500;
      response.statusText = `${ex.message}`;
      if (!response?.data) {
        response.data = { err: 401, details: `${ex.message}`, error: ex.error };
      }
    }

    let { status = 500, statusText = '', data = {} } = response;
    let { err, error, details, message = '', params = {} } = data;
    if (params?.org_id) {
      org_id = params.org_id;
    }

    alertObject.alert_type = status !== 200 ? "danger" : "success";
    alertObject.message += message;
    if (status !== 200) {
      alertObject.message = `[${status ?? err}] ${statusText} ${alertObject.message}`;
      alertObject.message += data?.status ? `${data.status} ` : ""; // ERROR 
      alertObject.message += error ? `${error} ` : ""; // ScrapeError Message + Controller Error 
      alertObject.message += (typeof response?.data === 'string') ? response.data : (details ? `${details} ` : "") + "Invalid submission.";
    }

    // Update previous with new values
    setPreviousFormValues(formState);
    setEditableForm(false);

    // Show Alert success or danger
    if (alertObject?.message) {
      alertObject.message = alertObject.message.trim();
      setShowAlert(true);
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }

    if (typeof setInvokeParentUpdate === 'function') {
      setInvokeParentUpdate((prevState) => !prevState);
    }
    return;
  };

  // ===============[ useEffect ]==================================
  useEffect(() => {
    let mounted = true;
    const init = async () => {
      setLoadingForm(true);
      await loadLawFirm();
      setLoadingForm(false);
    }
    if (mounted) {
      init();
    }
    return () => mounted = false
  }, [org_id]);

  return (
    <div>
      <Alert
        dismissible
        onClose={() => setShowAlert(false)}
        show={showAlert}
        variant={modalAlert.alert_type}
      >{modalAlert.message}</Alert>

      <Form noValidate autoComplete="off">
        {(org_id === 0 && <p>To add a new law firm, please complete the form below and ensure that all required fields are populated.</p>)}
        <Row>
          <Col>
            <Form.Label >Status</Form.Label>
            <Form.Select
              className={previousFormValues.status !== formState.status && 'changed-field'}
              disabled={!editableForm}
              aria-label="Status"
              name="status"
              required
              value={formState.status}
              onChange={handleInputChange}>
              <option value="">Select Status</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Form.Select>
          </Col>
          <Col>
            <Form.Label>External Firm ID</Form.Label>
            <Form.Control
              className={previousFormValues.externalFirmId !== formState.externalFirmId && 'changed-field'}
              disabled={!editableForm}
              type="text"
              placeholder=""
              name="externalFirmId"
              onChange={handleInputChange}
              value={formState.externalFirmId}
              autoComplete="off"
              required
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label className="mt-3">Firm Name</Form.Label>
            <Form.Control
              className={previousFormValues.firmName !== formState.firmName && 'changed-field'}
              disabled={!editableForm}
              type="text"
              placeholder=""
              name="firmName"
              onChange={handleInputChange}
              value={formState.firmName}
              autoComplete="off"
              required
            />
          </Col>
          <Col>
            <Form.Label className="mt-3">Prefix</Form.Label>
            <Form.Control
              className={previousFormValues.prefix !== formState.prefix && 'changed-field'}
              disabled={!editableForm}
              type="text"
              placeholder=""
              name="prefix"
              onChange={handleInputChange}
              value={formState.prefix}
              autoComplete="off"
              required
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label className="mt-3">Phone Number</Form.Label>
            <Form.Control
              className={previousFormValues.phoneNumber !== formState.phoneNumber && 'changed-field'}
              disabled={!editableForm}
              type="text"
              placeholder=""
              name="phoneNumber"
              onChange={handleInputChange}
              value={formState.phoneNumber}
              autoComplete="off"
              required
            />
          </Col>
          <Col>
            <Form.Label className="mt-3">Firm Parents</Form.Label>
            {lawFirms.length === 0 || !formState.firmParents || !formState.firmParents.split(",").every((parent) => (lawFirms.map(({ org_id }) => Number(org_id)).includes(Number(parent)))) ? (
              <Form.Control
                className={previousFormValues.firmParents !== formState.firmParents && 'changed-field'}
                disabled={!editableForm}
                type="text"
                placeholder=""
                name="firmParents"
                onChange={handleInputChange}
                value={formState.firmParents}
                autoComplete="off"
                required
              />
            ) : (<Form.Control
              multiple
              as="select"
              name="firmParents"
              value={formState.firmParents.split(",")}
              className={previousFormValues.firmParents !== formState.firmParents && 'changed-field'}
              disabled={formState.firmParents || !editableForm}
              onChange={handleInputChange}
              aria-label="Firm Parents">
              {lawFirms.length > 0 && lawFirms.map(({ org_id, prefix, organization = '' } = {}) => (
                <option key={`lawFirms-${org_id}`} value={org_id}>
                  {`${organization} (${org_id}-${prefix})`}
                </option>
              ))}
            </Form.Control>)}
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label className="mt-3">Address</Form.Label>
            <Form.Control
              className={previousFormValues.address !== formState.address && 'changed-field'}
              disabled={!editableForm}
              type="text"
              placeholder=""
              name="address"
              onChange={handleInputChange}
              value={formState.address}
              autoComplete="off"
              required
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label className="mt-3">City</Form.Label>
            <Form.Control
              className={previousFormValues.city !== formState.city && 'changed-field'}
              disabled={!editableForm}
              type="text"
              placeholder=""
              name="city"
              onChange={handleInputChange}
              value={formState.city}
              autoComplete="off"
              required
            />
          </Col>
          <Col>
            <Form.Label className="mt-3">State</Form.Label>
            <Form.Control
              className={previousFormValues.state !== formState.state && 'changed-field'}
              disabled={!editableForm}
              type="text"
              placeholder=""
              name="state"
              onChange={handleInputChange}
              value={formState.state}
              autoComplete="off"
              required
            />
          </Col>
          <Col>
            <Form.Label className="mt-3">Zip</Form.Label>
            <Form.Control
              className={previousFormValues.zip !== formState.zip && 'changed-field'}
              disabled={!editableForm}
              type="text"
              placeholder=""
              name="zip"
              onChange={handleInputChange}
              value={formState.zip}
              autoComplete="off"
              required
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label className="mt-3">Billing Address</Form.Label>
            <Form.Control
              className={previousFormValues.billingAddress !== formState.billingAddress && 'changed-field'}
              disabled={!editableForm}
              type="text"
              placeholder=""
              name="billingAddress"
              onChange={handleInputChange}
              value={formState.billingAddress}
              autoComplete="off"
              required
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label className="mt-3">Billing City</Form.Label>
            <Form.Control
              className={previousFormValues.billingCity !== formState.billingCity && 'changed-field'}
              disabled={!editableForm}
              type="text"
              placeholder=""
              name="billingCity"
              onChange={handleInputChange}
              value={formState.billingCity}
              autoComplete="off"
              required
            />
          </Col>
          <Col>
            <Form.Label className="mt-3">Billing State</Form.Label>
            <Form.Control
              className={previousFormValues.billingState !== formState.billingState && 'changed-field'}
              disabled={!editableForm}
              type="text"
              placeholder=""
              name="billingState"
              onChange={handleInputChange}
              value={formState.billingState}
              autoComplete="off"
              required
            />
          </Col>
          <Col>
            <Form.Label className="mt-3">Billing Zip</Form.Label>
            <Form.Control
              className={previousFormValues.billingZip !== formState.billingZip && 'changed-field'}
              disabled={!editableForm}
              type="text"
              placeholder=""
              name="billingZip"
              onChange={handleInputChange}
              value={formState.billingZip}
              autoComplete="off"
              required
            />
          </Col>
        </Row>
      </Form>

      <Row>
        {editableForm ? (<Col className="mt-3 d-flex justify-content-end">
          {JSON.stringify(previousFormValues) !== JSON.stringify(formState) && (<Button className="btn btn-primary me-3" onClick={updateLawFirm} {...(loadingForm || !validated) && { disabled: true }}>Save Changes</Button>)}
          <Button className="btn btn-secondary" onClick={cancelUpdateLawFirm} {...(loadingForm) && { disabled: true }}>Cancel Edit</Button>
        </Col>) : (<Col className="mt-3 d-flex justify-content-end"><Button onClick={editInfo}  {...(loadingForm) && { disabled: true }} >Edit</Button></Col>)}
      </Row>
    </div>
  );
}

export { LawFirmForm };
export default LawFirmForm;