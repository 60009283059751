import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Modal, Card } from 'react-bootstrap';

const DownloadRequestModal = () => {
  const navigate = useNavigate();
  const [modalState, setModalState] = useState(false);
  const openModal = () => (setModalState(true));
  const closeModal = () => (setModalState(false));

  // Navigate to appropriate page base on type
  const handleOptionClick = function (event) {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation();
    let { option = 'default' } = this || {};

    setModalState(false);
    if (option === "custom") {
      navigate(`/custom-download-request`);
      return;
    }
    navigate(`/default-download-request`);
    return;
  };

  return (
    <div>
      <Button className="green-button" onClick={openModal}>+ New Download Request</Button>
      <Modal show={modalState} onHide={closeModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Download Request Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="card-hover mb-3" onClick={handleOptionClick.bind({ option: 'default' })}>
            <Card.Body>
              <h5>Default Download</h5>
              <hr />
              <p>The default download option allows you to download exhibited, unexhibited, and media files for a batch of socials.</p>
            </Card.Body>
          </Card>
          <Card className="card-hover" onClick={handleOptionClick.bind({ option: 'custom' })}>
            <Card.Body>
              <h5>Custom Download</h5>
              <hr />
              <p>The custom download option is limited to one social per request. This option allows you to view the electronic folder contents before you select them for download.</p>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export { DownloadRequestModal };
export default DownloadRequestModal;