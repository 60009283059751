import React, { useState, useEffect } from 'react';
import { useUserData } from '../../contexts/AuthContext';
import { Form, Button, Alert, Row, Col } from 'react-bootstrap';
import { admin } from "../../utils/EREDocs";
import { validParams, formatPhoneNumber, formatDate, humanize } from "../../utils/";

const OVERRIDE_LOGIN = process.env.REACT_APP_OVERRIDE_LOGIN === "true";
const BSOAccountForm = ({ auth_id, org_id, hideBSOFields = true, closeModal = () => { }, invokedByParent = false, setInvokeParentUpdate = () => { }, setBsoUser = () => { }, setDefaultAuthUser = () => { }, setAuthOrgId = () => { }, ...props } = {}) => {
  const { userData } = useUserData();
  let { attorney, attorney_email, bso_username, isAuthenticated = false, passport: {
    user: { email = '', user_id: default_user_id, org_id: default_org_id, group_id = 6, auth_id: user_auth_id,
      organization: { organization: defaultFirm, prefix: defaultFirmPrefix, org_parents: firmParents, assigned_number: default_assigned_number, external_id: default_external_id } = {},
      selected_auth: {
        auth_id: default_auth_id, bso_username: default_bso_username, attorney: default_attorney, attorney_email: default_attorney_email, text_number: default_text_number,
      } = {}
    } = {} } = {} } = userData || {};
  let superAdminAccess = (isAuthenticated && Number(group_id) === 1) || OVERRIDE_LOGIN;
  (!email && userData?.email) && (email = userData.email);
  (!default_auth_id && user_auth_id) && (default_auth_id = user_auth_id);
  (!default_attorney && attorney) && (default_attorney = attorney);
  (!default_bso_username && bso_username) && (default_bso_username = bso_username);
  (!default_attorney_email && attorney_email) && (default_attorney_email = attorney_email);
  (auth_id && typeof auth_id !== 'number') && (auth_id = Number(auth_id));
  (default_auth_id && typeof default_auth_id !== 'number') && (default_auth_id = Number(default_auth_id));
  (org_id && typeof org_id !== 'number') && (org_id = Number(org_id));
  (default_org_id && typeof default_org_id !== 'number') && (default_org_id = Number(default_org_id));
  (org_id) && (default_org_id = org_id);

  const [loadingForm, setLoadingForm] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [modalAlert, setModalAlert] = useState({ message: "", alert_type: "info" });

  const [editableForm, setEditableForm] = useState(!auth_id);
  const [previousFormValues, setPreviousFormValues] = useState({});
  const [lawFirms, setLawFirms] = useState([{}]);
  const [users, setUsers] = useState([]);
  const [formState, setFormState] = useState({
    lawFirm: org_id,
    enabled: false,
    attorneyName: '',
    attorneyEmail: '',
    gmailPassword: '',
    text_number: '',
    text_forward: '',
    firm_id: '',
    bsoUsername: '',
    bsoPassword: '',
    defaultUser: '',
  });

  const handleInputChange = (event) => {
    if (!event?.target?.name) {
      return;
    }
    let alertObject = {
      message: "",
      alert_type: "info",
    }
    let { name, value, type, checked = false } = event.target;
    if (type === 'checkbox') {
      value = checked;
    }
    if (value) {
      if (name === 'bsoUsername') {
        value = `${value}`.toUpperCase();
      } else if (name === 'attorneyEmail') {
        value = `${value}`.toLowerCase();
      } else if (['text_number', 'text_forward'].includes(name)) {
        value = name === "text_forward"
          ? value.split(",").map((v) => `${v}`.includes("@") ? `${v}`.trim() : (
            /\d/.test(v) ? formatPhoneNumber(v) : `${v}`.trim()
          )).join(", ").replace(/\s\s+/g, ' ').trim()
          : formatPhoneNumber(value);
      } else if (name === "attorneyName") {
        value = humanize(value, false, true);
      }
    }
    setFormState((prevState) => ({ ...prevState, [name]: value }));

    // Validate Form on change 
    let requiredFields = ['lawFirm', 'attorneyName', 'defaultUser'];
    let testParams = Object.entries({ ...formState, [name]: value }).filter(([k, v]) => v).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
    let valid = validParams(testParams, requiredFields, 'required');
    if (valid) {
      const email_regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,40})+$/;
      valid = email_regex.test(testParams.attorneyEmail);
      if (testParams.bsoUsername) {
        if (valid) {
          const username_regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/gm; // Must Contain 8 Characters
          valid = username_regex.test(testParams.bsoUsername);
          if (!hideBSOFields && testParams.bsoPassword) {
            if (valid) {
              const password_regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/gm; // Must Contain 8 Characters
              valid = !testParams.bsoPassword ? true : password_regex.test(testParams.bsoPassword); // This field is not required! But if entered we'll validate it.
              if (!valid) {
                if (testParams.bsoPassword.length < 8) {
                  alertObject.message = "BSO Password must be at least 8 characters";
                } else {
                  alertObject.message = "BSO Password must contain at least one number and one letter";
                }
              }
            } else {
              if (testParams.bsoUsername.length < 8) {
                alertObject.message = "Username must be at least 8 characters";
              } else {
                alertObject.message = "Username must contain at least one number and one letter";
              }
            }
          }
        } else {
          alertObject.message = "Please enter a valid email address.";
        }
      }
    } else {
      alertObject.message = "Please fill out all required fields. Missing " + requiredFields.filter((f) => !testParams[f]).join(', ');
    }

    setValidated(valid);
    setShowAlert(!!alertObject.message);
    if (!!alertObject.message) {
      alertObject.message = alertObject.message.trim();
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }
  };

  const loadBsoAccount = async () => {
    let loadedValues = (auth_id === default_auth_id) ? {
      lawFirm: org_id,
      enabled: false,
      attorneyName: default_attorney,
      attorneyEmail: default_attorney_email,
      gmailPassword: '',
      text_number: default_text_number,
      text_forward: '',
      firm_id: default_external_id,
      bsoUsername: default_bso_username,
      bsoPassword: '',
      defaultUser: default_user_id,
    } : {
      lawFirm: org_id,
      enabled: false,
      attorneyName: '',
      attorneyEmail: '',
      gmailPassword: '',
      text_number: '',
      text_forward: '',
      firm_id: '',
      bsoUsername: '',
      bsoPassword: '',
      defaultUser: default_user_id,
    };

    if (auth_id || org_id) { // auth_id = 0 when adding a new law firm but we still want to pre populate the form by the auth_id found by org_id 
      let params = {
        ...(auth_id && { auth_id }),
        ...(org_id && { org_id }),
      }
      let { status = 500, statusText = "Internal Server Error", data: { data: loadedAuthAccounts = [] } = {} } = await admin.authAccounts(params);
      (status !== 200) && console.log(`${status} ${statusText}`);
      if (Array.isArray(loadedAuthAccounts) && loadedAuthAccounts.length === 1) {
        let {
          org_id: lawFirm = org_id,
          enabled = false,
          attorney: attorneyName = '',
          email: attorneyEmail = '',
          text_number = '',
          text_forward = '',
          firm_id = '',
          bso_username: bsoUsername = '',
          default_user_id: defaultUser = default_user_id,
          last_login_result,
          last_login_attempted,
        } = loadedAuthAccounts[0] || {};

        // If we don't have an auth_id we can assume we are adding a new auth_id so we don't want to populate everything we get back from the auth_account by org_id 
        if (!params?.auth_id) {
          loadedValues = {
            lawFirm,
            firm_id,
            enabled: true,
            defaultUser
          };
        } else {
          loadedValues = {
            lawFirm,
            firm_id,
            enabled,
            attorneyName,
            attorneyEmail,
            gmailPassword: '',
            text_number,
            text_forward,
            bsoUsername,
            bsoPassword: '',
            defaultUser
          };
        }

        if (params?.auth_id && (last_login_result || last_login_attempted)) {
          let alertObject = { ...modalAlert };
          alertObject.alert_type = last_login_result === "SUCCESSFUL" ? "success" : "warning";
          alertObject.message = `Last login attempted ${formatDate(last_login_attempted)}. Results ${humanize(last_login_result)}.`;
          setShowAlert(true);
          setModalAlert(prevState => ({ ...prevState, ...alertObject }));
        }
      }
    } // End of if (auth_id || org_id)

    // Filter null values to be empty strings
    let BooleanFields = ['enabled'];
    let phoneNumberFields = ['text_number', 'text_forward'];
    loadedValues = Object.entries(loadedValues).reduce((acc, [key, value]) => ({
      ...acc, ...{
        [key]: !value ? (BooleanFields.includes(key) ? Boolean(value) : "") :
          phoneNumberFields.includes(key) ? (
            key === "text_forward" ? value.split(",").map((v) =>
              `${v}`.includes("@") ? `${v}`.trim() : (
                /\d/.test(v) ? formatPhoneNumber(v) : `${v}`.trim()
              )).join(", ").replace(/,\s*$/, "").replace(/\s\s+/g, ' ').trim() : formatPhoneNumber(value)
          ) : value
      }
    }), {});

    if (!loadedValues.lawFirm || !lawFirms.map(({ org_id }) => Number(org_id)).includes(Number(loadedValues.lawFirm))) {
      let params = { org_id: Number(loadedValues.lawFirm) };
      await admin.organizations(params).then(({ status = 500, statusText = "Internal Server Error", data: { data: loadedLawFirms = [] } = {} } = {}) => {
        (status !== 200) && console.log(`${status} ${statusText}`);
        if (Array.isArray(loadedLawFirms) && loadedLawFirms.length > 0) {
          setLawFirms(loadedLawFirms);
        }
      });
    }

    // Save loadedValues
    setFormState(loadedValues);
    setPreviousFormValues(loadedValues);
    if (loadedValues.bsoUsername && typeof setBsoUser === 'function') {
      setBsoUser(loadedValues.bsoUsername);
    }
    if (loadedValues.defaultUser && typeof setDefaultAuthUser === 'function') {
      setDefaultAuthUser(Number(loadedValues.defaultUser));
    }
    if (loadedValues.lawFirm && typeof setAuthOrgId === 'function') {
      setAuthOrgId(Number(loadedValues.lawFirm));
    }
    return loadedValues;
  } // End of loadBsoAccount

  const editInfo = () => (setEditableForm(true));
  const cancelUpdateBSOAccount = () => {
    setEditableForm(false);
    setFormState(previousFormValues);
    (closeModal && typeof closeModal === 'function') && closeModal();
  }

  // ===============[ updateBSOAccount ]==================================
  const updateBSOAccount = async (event) => {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation();

    let alertObject = {
      message: "",
      alert_type: "info",
    };

    let response;
    try {
      if (!validated) {
        throw new Error("Your form input is not valid. Please check your input and try again.");
      }

      let saveParams = {
        ...(auth_id && { auth_id }),
        ...formState,
      };

      setLoadingForm(true);
      saveParams = Object.entries(saveParams).reduce((acc, [k, v]) => ({ ...acc, ...((['enabled', 'text_forward'].includes(k) || v) && { [k]: v }) }), {}); // Filter out empty values but allow enabled and text_forward to be falsy
      response = await admin.saveBSOAccount(saveParams).then((resp) => {
        setLoadingForm(false);
        if (resp.data?.params?.auth_id) {
          resp.data.params.auth_id = Number(resp.data.params.auth_id);
        }
        return resp;
      });

      alertObject.message = "Saved Successfully";
    } catch (ex) {
      setLoadingForm(false);
      console.log(ex);
      response = ex?.response || {};
      response.status = 500;
      response.statusText = `${ex.message}`;
      if (!response?.data) {
        response.data = { err: 401, details: `${ex.message}`, error: ex.error };
      }
    }

    let { status = 500, statusText = '', data = {} } = response;
    let { err, error, details, message = '', params = {}, info = {} } = data;
    if (params?.auth_id || info?.auth_id) {
      auth_id = params.auth_id || info.auth_id;
    }
    let { exit_code = 1, results, id = -1, secret_response = {} } = info || {};
    let { ok = false, statusCode = 500, statusMessage = "Internal Server Error", error: secret_error = "", ARN, Name: SecretId, VersionId, data: SecretObject = null } = secret_response || {};

    alertObject.alert_type = status !== 200 ? "danger" : "success";
    if (status !== 200) {
      alertObject.message = `[${status ?? err}] ${statusText} ${alertObject.message}`;
      alertObject.message += data?.status ? `${data.status} ` : ""; // ERROR 
      alertObject.message += error ? `${error} ` : ""; // ScrapeError Message + Controller Error 
      alertObject.message += (typeof response?.data === 'string') ? response.data : message ?? (details ? `${details} ` : "") + "Invalid submission.";
    }

    if (Object.keys(secret_response).length > 0) {
      if (alertObject.message.charAt(alertObject.message.length - 1) !== ' ') {
        alertObject.message += ' '; // Add trailing space if there's not one already
      }
      if (!ok || statusCode !== 200) {
        alertObject.message += `secret_response [${status}] ${statusText} `;
      }
      alertObject.message += statusMessage ? `${statusMessage}` : "";
      alertObject.message += SecretId ? ` SecretId: ${SecretId} ` : "";
      alertObject.message += VersionId ? ` VersionId: ${VersionId} ` : "";
      alertObject.message += secret_error ? ` secret_error: ${secret_error} ` : "";
    }

    // Update previous with new values
    setFormState((prevState) => ({ ...prevState, bsoPassword: '', gmailPassword: '' })); // Empty out the bsoPassword and gmailPassword fields
    setPreviousFormValues((prevState) => ({ ...prevState, ...formState, bsoPassword: '', gmailPassword: '' })); // Empty out the bsoPassword and gmailPassword fields
    setEditableForm(false);

    // Show Alert success or danger
    setShowAlert(!!alertObject?.message);
    if (!!alertObject?.message) {
      alertObject.message = alertObject.message.trim();
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }

    if (typeof setInvokeParentUpdate === 'function') {
      setInvokeParentUpdate((prevState) => !prevState);
    }
    return;
  };

  // ===============[ useEffect ]==================================
  useEffect(() => {
    let mounted = true;
    const init = async () => {
      setLoadingForm(true);
      await admin.users().then(({ status = 500, statusText = "Internal Server Error", data: { data: loadedUsers = [] } = {} } = {}) => {
        (status !== 200) && console.log(`${status} ${statusText}`);
        if (Array.isArray(loadedUsers) && loadedUsers.length > 0) {
          setUsers(loadedUsers);
        }
      });
      await loadBsoAccount();
      setLoadingForm(false);
    }
    (mounted) && init();
    return () => mounted = false;
  }, [auth_id, invokedByParent]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      const username_regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/gm; // Must Contain 8 Characters
      let userIsValid = formState.bsoUsername ? username_regex.test(formState.bsoUsername) : false;
      if (userIsValid && typeof setBsoUser === 'function') {
        setBsoUser(formState.bsoUsername);
      }
    }
    return () => mounted = false;
  }, [formState.bsoUsername]);

  useEffect(() => {
    let mounted = true;
    if (mounted && loadingForm && formState.defaultUser && typeof setDefaultAuthUser === 'function') {
      setDefaultAuthUser(Number(formState.defaultUser));
    }
    return () => mounted = false;
  }, [formState.defaultUser]);

  return (<div className="page-container" {...props}>
    <Alert
      dismissible
      onClose={() => setShowAlert(false)}
      show={showAlert}
      variant={modalAlert.alert_type}
    >{modalAlert.message}</Alert>
    <p>To add a new BSO Account, please complete the form below and ensure that all required fields are populated.</p>

    <Form noValidate autoComplete="off">
      <Row>
        <Col xs="auto" sm="auto" md="7" lg="7" >
          <Form.Label title="org_id">Law Firm</Form.Label>
          <Form.Select
            name="lawFirm"
            className={previousFormValues.lawFirm !== formState.lawFirm && 'changed-field'}
            disabled={!superAdminAccess || !editableForm}
            value={formState.lawFirm}
            title={`org_id=${formState.lawFirm}`}
            onChange={handleInputChange}
            aria-label="Law Firm">
            <option value="">Select Law Firm</option>
            {lawFirms.length > 0 && lawFirms.map(({ org_id, prefix, organization = '' } = {}) => (
              <option key={`lawFirms-${org_id}`} value={org_id}>
                {`${organization} (${org_id}-${prefix})`}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col xs="auto" sm="auto" md="3" lg="3">
          <Form.Label title="External Firm ID">Firm ID</Form.Label>
          <Form.Control
            className={previousFormValues.firm_id !== formState.firm_id && 'changed-field'}
            disabled={!editableForm}
            type="text"
            placeholder=""
            name="firm_id"
            onChange={handleInputChange}
            value={formState.firm_id}
            autoComplete="off"
            required
          />
        </Col>
        <Col xs="auto" sm="auto" md="2" lg="2">
          <Form.Label >Enabled</Form.Label>
          <Form.Select
            className={previousFormValues.enabled !== formState.enabled && 'changed-field'}
            disabled={!editableForm}
            onChange={handleInputChange}
            value={formState.enabled}
            aria-label="Enabled"
            name="enabled"
            required>
            <option value="">Select Status</option>
            <option value={true}>Enabled</option>
            <option value={false}>Disabled</option>
          </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label className="mt-3">Attorney Name</Form.Label>
          <Form.Control
            className={previousFormValues.attorneyName !== formState.attorneyName && 'changed-field'}
            disabled={!editableForm}
            type="text"
            placeholder=""
            name="attorneyName"
            onChange={handleInputChange}
            value={formState.attorneyName}
            autoComplete="off"
            required
          />
        </Col>
        <Col>
          <Form.Label className="mt-3">Text Forward</Form.Label>
          <Form.Control
            className={previousFormValues.text_forward !== formState.text_forward && 'changed-field'}
            disabled={!editableForm}
            type="text"
            placeholder=""
            name="text_forward"
            onChange={handleInputChange}
            value={formState.text_forward}
            autoComplete="off"
            required
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label className="mt-3">Attorney Email (GMAIL)</Form.Label>
          <Form.Control
            className={previousFormValues.attorneyEmail !== formState.attorneyEmail && 'changed-field'}
            disabled={!editableForm}
            type="text"
            placeholder=""
            name="attorneyEmail"
            title="Gmail used for OTP"
            onChange={handleInputChange}
            value={formState.attorneyEmail}
            autoComplete="off"
            required
          />
        </Col>
        {hideBSOFields === false && (<Col>
          <Form.Label className="mt-3">GMAIL Password</Form.Label>
          <Form.Control
            className={previousFormValues.gmailPassword !== formState.gmailPassword && 'changed-field'}
            disabled={!editableForm}
            type="text"
            placeholder=""
            name="gmailPassword"
            value={formState.gmailPassword}
            onChange={handleInputChange}
            autoComplete="off"
          />
        </Col>)}
      </Row>
      <Row>
        <Col>
          <Form.Label className="mt-3" title="Only used to pre-populate login form" >BSO Username</Form.Label>
          <Form.Control
            className={previousFormValues.bsoUsername !== formState.bsoUsername && 'changed-field'}
            disabled={!editableForm}
            type="text"
            placeholder=""
            name="bsoUsername"
            onChange={handleInputChange}
            value={formState.bsoUsername}
            autoComplete="off"
            required
          />
        </Col>
        {hideBSOFields === false && (<Col>
          <Form.Label className="mt-3">BSO Password</Form.Label>
          <Form.Control
            className={previousFormValues.bsoPassword !== formState.bsoPassword && 'changed-field'}
            disabled={!editableForm}
            type="text"
            placeholder=""
            name="bsoPassword"
            value={formState.bsoPassword}
            onChange={handleInputChange}
            autoComplete="off"
          />
        </Col>)}
        <Col>
          <Form.Label className="mt-3" title="Used for One Time Pass code">BSO Text Number (OTP)</Form.Label>
          <Form.Control
            className={previousFormValues.text_number !== formState.text_number && 'changed-field'}
            disabled={!editableForm}
            type="text"
            placeholder=""
            name="text_number"
            onChange={handleInputChange}
            value={formState.text_number}
            autoComplete="off"
            required
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label className="mt-3">Default User</Form.Label>
          <Form.Select
            className={previousFormValues.defaultUser !== formState.defaultUser && 'changed-field'}
            disabled={!editableForm}
            onChange={handleInputChange}
            name="defaultUser"
            value={formState.defaultUser}
            title={`user_id=${formState.defaultUser}`}
            aria-label="Default User">
            <option value="">Select Default User</option>
            {users.length > 0 && users.filter(({ org_id }) => Number(org_id) === Number(formState.lawFirm)).map(({ user_id, org_id, auth_id, email = '', first_name = '', last_name = '' } = {}) => (
              <option key={`user-${user_id}`} value={user_id} title={`user_id=${user_id} org_id=${org_id} auth_id=${auth_id}`}>
                {`${first_name || '[Missing First Name]'} ${last_name || '[Missing Last Name]'} - ${email}`}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
    </Form>

    <Row>
      {editableForm ? (<Col className="mt-3 d-flex justify-content-end">
        {JSON.stringify(previousFormValues) !== JSON.stringify(formState) && (<Button className="btn btn-primary me-3" onClick={updateBSOAccount} {...(loadingForm || !validated) && { disabled: true }}>Save Changes</Button>)}
        <Button className="btn btn-secondary" onClick={cancelUpdateBSOAccount} {...(loadingForm) && { disabled: true }}>Cancel Edit</Button>
      </Col>) : (<Col className="mt-3 d-flex justify-content-end"><Button className="btn btn-primary me-3" onClick={editInfo}  {...(loadingForm) && { disabled: true }} >Edit</Button></Col>)}
    </Row>
  </div>);
}

export { BSOAccountForm };
export default BSOAccountForm;