import styled from "styled-components";

const StyledLoader = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
`;

export default StyledLoader;