import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { ChangePasswordForm } from '../components/Forms';
import PublicHeader from '../components/PublicHeader';

const ChangePassword = () => {
  const navigate = useNavigate();
  let { reset_password_token } = useParams();
  const checkResetPasswordToken = async (token = reset_password_token) => {
    if (!token || token === "" || token === 0) {
      navigate(`/`);
    }
  }

  useEffect(() => {
    let mounted = true;
    (mounted) && checkResetPasswordToken(reset_password_token);
    return () => mounted = false;
  }, [reset_password_token]);

  return (
    <div className="page-container">
      <PublicHeader />
      <h4>Change Password</h4>
      <hr />
      <Row><Col><ChangePasswordForm reset_password_token={reset_password_token} /></Col></Row>
    </div>
  );
}

export default ChangePassword;