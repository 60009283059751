import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useUserData } from '../../contexts/AuthContext';
import { Col, Button, Table, Alert, Form } from 'react-bootstrap';
import { StyledSpan } from '../../styled/Span';
import { StyledFormControl } from '../../styled/Forms';
import Loader from "../Loader";
import { humanize, dehumanize, massageData, formatDate, getUrlPath } from "../../utils/";
import { bso, downloader } from "../../utils/EREDocs";
import pickupFilesTmp from '../../fake-data/pickupFiles.json'; // When loadFakeData = true
const OVERRIDE_LOGIN = process.env.REACT_APP_OVERRIDE_LOGIN === "true";
const loadFakeData = process.env.REACT_APP_LOAD_FAKE_DATA === "true";
const defaultQueryParams = { limit: 25, showDebug: false };
let IntervalId = null;

const PickupFiles = ({ auth_id, org_id, ...props } = {}) => {
  const { userData, setUserData } = useUserData();
  let { isAuthenticated = false, bsoLoggedIn = false, passport: {
    user: { org_id: default_org_id, auth_id: user_auth_id, group_id = 6,
      selected_auth: { auth_id: default_auth_id } = {}
    } = {}
  } = {} } = userData || {};
  (!auth_id && default_auth_id) && (auth_id = default_auth_id);
  (!auth_id && user_auth_id) && (auth_id = user_auth_id);
  (typeof auth_id !== 'number') && (auth_id = Number(auth_id));
  (typeof default_org_id !== 'number') && (default_org_id = Number(default_org_id));
  (typeof org_id !== 'number') && (org_id = Number(org_id));

  let superAdminAccess = (isAuthenticated && Number(group_id) === 1) || OVERRIDE_LOGIN;
  let refresh_interval_seconds = 0;
  let skipFields = ['key_attorney', 'key', 'link'];
  let dateFields = ['Date Requested', 'File Deletion Date'];
  let renamedFields = {
    lastName: 'Last Name',
    fileType: 'File Type',
    dateRequested: 'Date Requested',
    deleteDate: 'File Deletion Date',
  };
  let tableFields = ['ssn', 'Last Name', 'File Type', 'status', 'attorney', 'Date Requested', 'File Deletion Date'];

  const pickupFilesTableRef = useRef();
  const [invokeUpdate, setInvokeUpdate] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);

  const [Pages, setPages] = useState([1]);
  const [activePage, setActivePage] = useState(1);
  const [showTableAlert, setShowTableAlert] = useState(false);
  const [tableAlert, setTableAlert] = useState({ message: "", alert_type: "info" });

  const [lastUpdated, setLastUpdated] = useState(null);
  const [tableHeader, setTableHeader] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [formState, setFormState] = useState({
    last_name: "",
    last_four: "",
    file_type: "",
    status: "",
  });

  const handleInputChange = (event) => {
    if (!event?.target) return;
    event.stopPropagation(); // Prevent other events from firing on click

    let { name, value, type, checked = false } = event.target;
    if (type === 'checkbox') {
      value = checked;
    } else {
      event.preventDefault();
    }
    setFormState((prevState) => ({ ...prevState, [name]: value }));
    return;
  };

  const handleZipDownloadLink = async (event, { href, link, key } = {}) => {
    if (!event || !superAdminAccess) return; // Only super admins can download zip files from pickup page 
    event.preventDefault();
    event.stopPropagation();

    if (!href && link && key) {
      href = `/eredocs/downloader/zipfile?${link.split("?").pop()}&key=${key}`;
    }
    if (!href && (event?.target?.href || event?.target?.dataset?.href)) {
      href = event?.target?.href || event?.target?.dataset?.href;
    }
    if (!href || href === "#" || href === "/eredocs") return;

    // Prepare href for downloading a file 
    href = `/${getUrlPath(href)}`;
    let alertObj = { ...tableAlert };
    try {
      setLoadingTable(true);
      if (prompt("Are you sure that you want to download this file directly from the bso pickup files page?", "yes") !== "yes") {
        throw new Error("File download was cancelled.");
      }
      if (!bsoLoggedIn) {
        throw new Error("You must be logged in to the bso download a file.");
      }

      await downloader.getZipFile(href, ({ status, statusText, data } = {}) => {
        alertObj.message = `${status} ${statusText}`;
        (typeof data === 'string') && (alertObj.message += ` - ${data}`);
        if ([200, 201].includes(status)) {
          alertObj.alert_type = "success";
        } else {
          alertObj.alert_type = "danger";
        }
        setShowTableAlert(true);
        setTableAlert(prevState => ({ ...prevState, ...alertObj }));
      });
    } catch (error) {
      let { status, statusText, data } = error?.response || { 'status': 500, 'statusText': "Unknown server error.", data: { err: 500, 'details': error?.message ? error.message : error } };
      let error_message = `Error ${status} - `;
      if (typeof data === 'string') {
        error_message += (statusText === data) ? statusText : data;
      } else if (typeof data === 'object') {
        error_message += data?.message ? `${data?.details ? data.details + ". " : ""}${data.message}` : (data?.details ? data.details : "Unknown server error.");
      }

      console.log(error?.message ? error.message : (error?.stack ? error.stack : error));
      console.log(error_message);
      alertObj.message = error_message
      alertObj.alert_type = "danger";
      setShowTableAlert(true);
      setTableAlert(prevState => ({ ...prevState, ...alertObj }));
    } finally {
      setLoadingTable(false);
    }
    return;
  };

  // Extracts row items and save to rowObject and selectedItem
  const handleTableRowClick = (event) => {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation(); // Prevent other events from firing on click
    let clickedElement = event?.target || {};
    let { parentElement, dataset, tagName, type, innerText } = clickedElement;
    let action = false;

    // Go up one level in the DOM but preserve innerText
    if (tagName !== "TD" && parentElement?.tagName === "TD") {
      clickedElement = parentElement;
      parentElement = clickedElement.parentElement;
      dataset = { ...dataset, ...clickedElement?.dataset };
      tagName = clickedElement?.tagName;
      if (!innerText && clickedElement?.innerText) {
        innerText = clickedElement.innerText;
      }
    }

    let rowObject = {
      innerText,
      tagName,
      ...(type && { type }),
      ...dataset,
      ...parentElement?.dataset,
    };

    // Extract row items and save to rowObject and selectedItem
    let selectedItem = {};
    if (tagName === "TD") {
      let tdArray = Array.from(parentElement.querySelectorAll("td")).map(({ innerText }) => innerText).filter((i) => i) || [];
      let thArray = Array.from(parentElement.closest("table").querySelectorAll("thead tr th")).map(({ innerText }) => dehumanize(innerText)).filter((i) => i) || [];

      if ('col' in rowObject) { // Extract selectedItem
        let col = Number(rowObject.col);
        if (col && innerText === tdArray[col]) {
          selectedItem[thArray[col]] = tdArray[col];
        }
      }

      // Match thArray length with tdArray length
      if (tdArray.length > thArray.length) {
        // slice extra elements to match thArray length
        tdArray = tdArray.slice(0, thArray.length);
      } else if (tdArray.length < thArray.length) {
        // Fill in the blanks with null values to match thArray length
        let startIndex = tdArray.length;
        tdArray.length = thArray.length;
        tdArray.fill(null, startIndex);
      }

      if (tdArray.length === thArray.length) {
        for (let i = 0; i < thArray.length; i++) {
          rowObject[thArray[i]] = tdArray[i];
        }
      }
    }

    // Extract selectedItem if failed to do so above
    if ('field' in rowObject) {
      let field = dehumanize(rowObject.field); // We must dehumanize this field since that's what happened to all of values in thArray (see above)
      if (!selectedItem[field] && rowObject[field] === innerText) {
        selectedItem[field] = rowObject[field];
      }
    }

    // Object.keys(rowObject).length > 0 && console.log("handleTableRowClick", rowObject);
    // Object.keys(selectedItem).length > 0 && console.log("selectedItem", selectedItem); 
    let { key, link } = rowObject || {};
    (key && link) && (action = "download");
    if (action) {
      if (["download"].includes(action)) {
        let href = `/eredocs/downloader/zipfile?${link.split("?").pop()}&key=${key}`;
        return handleZipDownloadLink(event, { href, key, link });
      } else {
        console.log(`Unknown action: ${action}`);
      }
    }

    return;
  };

  const getTableData = async ({
    startedAt = Date.now(), headerFields = tableFields, renamed_fields = renamedFields, date_fields = dateFields, functionFields = skipFields,
    refresh_in_seconds = refresh_interval_seconds,
  } = {}) => {
    let now = Date.now();
    let duration_seconds = Math.round((now - startedAt) / 1000);
    let ran_count = !refresh_in_seconds ? 1 : Math.round(duration_seconds / refresh_in_seconds) || 1; // Avoid dividing by zero
    let first_run = now === startedAt || ran_count === 1;

    // Detect if user navigated away from this page 
    let offsetWidth = pickupFilesTableRef?.current?.offsetWidth || 0;
    let offsetHeight = pickupFilesTableRef?.current?.offsetHeight || 0;
    let navigated_away = !pickupFilesTableRef?.current || !(offsetWidth > 0 && offsetHeight > 0);

    if (!first_run && (navigated_away || !isAuthenticated)) {
      console.log("Clearing Interval because user navigated away.", { IntervalId, first_run, navigated_away, isAuthenticated, offsetWidth, offsetHeight });
      clearInterval(IntervalId);
      return;
    }

    let alertObj = { ...tableAlert };
    let filterValues = Object.entries(formState).filter(([id, value]) => value); // Filter out empty values
    let filteredData = (filterValues.length === 0) ? {} : Object.entries(formState).reduce((acc, [id, value]) => ({ ...acc, ...(value && { [id]: value }) }), {});
    let postData = {
      ...defaultQueryParams,
      ...filteredData,
      ...(auth_id && { auth_id }),
      ...(org_id && { org_id }),
      page: activePage
    };

    let response;
    try {
      setLoadingTable(true);
      if (!postData?.auth_id) {
        throw new Error("No auth_id found in postData");
      }

      if (loadFakeData) {
        response = {
          status: 200,
          statusText: 'OK',
          data: pickupFilesTmp,
        };
      } else if (Object.keys(filteredData).length > 0) {
        response = await bso.filteredPickupFiles(postData);
      } else {
        response = await bso.pickupFiles(postData);
      }

      alertObj.message = "";
    } catch (error) {
      console.log(error);
      response = error?.response || {};
      response.status = 500;
      response.statusText = `${error.message}`;
      if (!response?.data) {
        response.data = { err: 401, error: error.error, details: `${error.message}` };
      }
    } finally {
      setLoadingTable(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    let { err, error, details, message, rows = [], mtime, count = 0, pageCount = 1, page, pages = [] } = data || {};

    if (status === 200) {
      let formatStr = "MMMM D, YYYY hh:mm:ss A";
      let timeZone = "EST"; // NOTE: Must EST or Etc/GMT+5 because backend saves as EST like this Date.parse(_dateRequested + ' EST')
      let dataHeader = [...headerFields, ...functionFields];
      let dataRows = (!Array.isArray(rows) || rows.length === 0) ? [] : massageData(rows, dataHeader, renamed_fields, date_fields, [], [], formatStr, timeZone);
      if (rows.length === 0 || count === 0) {
        if (activePage > 1 && activePage === Pages[Pages.length - 1]) {
          setActivePage(Pages[Pages.length - 2]); // Make sure we don't get stuck loading nothing
        } else {
          alertObj.alert_type = "info";
          alertObj.message = "No Pickup Files found.";
        }
      }

      pages = pages.reduce((acc, item, index) => {
        if (index === 0 && item.number !== 1) {
          acc.push(1); // Always include the first page 
        }
        acc.push(item.number);
        if (index === (pages.length - 1) && !acc.includes(pageCount)) {
          acc.push(pageCount); // Always include the last page
        }
        return acc;
      }, []);

      setTableHeader(dataHeader);
      setTableRows(dataRows);
      setPages(pages);
      (mtime) && setLastUpdated(mtime);
    } else {
      alertObj.alert_type = "danger";
      alertObj.message = `[${status ?? err}] ${statusText} `;    // API Error Code 
      alertObj.message += data?.status ? `${data.status} ` : ""; // API Status 
      alertObj.message += error ? `${error} ` : "";              // API Error 
      alertObj.message += (typeof response?.data === 'string') ? response.data : message ?? (details ? `${details} ` : "") + `Failed to read data.`;
      alertObj.message = alertObj.message.trim();
    }

    setShowTableAlert(!!alertObj.message);
    if (alertObj.message) {
      setTableAlert(prevState => ({ ...prevState, ...alertObj }));
    }
    if (first_run && refresh_in_seconds) {
      if (IntervalId !== null) {
        console.log("Clearing Interval because new interval is being started.", { IntervalId });
        clearInterval(IntervalId);
      }
      let interval_ms = refresh_in_seconds * 1000;
      IntervalId = setInterval(getTableData.bind(null, { startedAt, headerFields, renamed_fields, date_fields, functionFields, refresh_in_seconds }), interval_ms); // Next render every refresh_in_seconds seconds
      console.log("Set Interval!", { IntervalId, activePage, loadingTable, startedAt });
    }

    // console.log("getTableData", { first_run, IntervalId, ran_count, duration_seconds, now, startedAt, navigated_away, isAuthenticated, offsetWidth, offsetHeight, status, statusText });
    return data;
  }; // END getTableData

  const readPickupFiles = async (event) => {
    if (!event?.target) return;
    event.preventDefault();

    let alertObj = { ...tableAlert };
    let queryParams = { ...defaultQueryParams, auth_id };
    let response;
    try {
      setLoadingTable(true);
      if (!queryParams?.auth_id) {
        throw new Error("No auth_id found in queryParams");
      }

      response = await bso.checkCookies(queryParams);
      alertObj.message = "Read BSO Pickup Page successfully.";
    } catch (error) {
      console.log(error);
      response = error?.response || {};
      response.status = 500;
      response.statusText = `${error.message}`;
    } finally {
      setLoadingTable(false);
    }

    let { status = 500, statusText = '', data = [] } = response;
    if (status !== 200) {
      alertObj.alert_type = "danger";
      alertObj.message = `[${status}] ${statusText} `;
      alertObj.message = alertObj.message.trim();
      if (auth_id === default_auth_id) {
        setUserData((prev) => ({ ...prev, bsoLoggedIn: false }));
      }
    }

    alertObj.alert_type = "success";
    if (Array.isArray(data) && data.length === 0) {
      alertObj.alert_type = "info";
      alertObj.message = "Read BSO Pickup Page successfully but didn't find anything";
    }

    setShowTableAlert(!!alertObj.message);
    if (alertObj.message) {
      setTableAlert(prevState => ({ ...prevState, ...alertObj }));
    }
    return setInvokeUpdate(prev => !prev);
  }

  const onSubmit = async (event) => {
    if (!event?.target) return;
    event.preventDefault();
    return setInvokeUpdate(prev => !prev);
  }

  const onReset = (event) => {
    if (!event?.target) return;
    event.stopPropagation(); // Prevent other events from firing on click
    event.preventDefault();
    setFormState({
      last_name: "",
      last_four: "",
      file_type: "",
      status: "",
    });
    return setInvokeUpdate(prev => !prev);
  };

  useEffect(() => {
    let mounted = true;
    const init = async () => {
      if (org_id === 0) return;
      await getTableData();
    }
    if (mounted) init();
    return () => mounted = false;
  }, [org_id, invokeUpdate, auth_id, activePage]);

  return (
    <div ref={pickupFilesTableRef} {...props}>
      <p>
        <Button className="btn btn-primary me-3" type="button" name="refresh" disabled={loadingTable} onClick={readPickupFiles}>Refresh</Button>
        {lastUpdated && (<span>Last updated {formatDate(lastUpdated)}.</span>)}
      </p>

      <Alert
        dismissible
        onClose={() => setShowTableAlert(false)}
        show={showTableAlert}
        variant={tableAlert.alert_type}
      >{tableAlert.message}</Alert>

      <p>This list is representing what is shown on the BSO Pick Up File page. Used for informational purposes only.</p>
      <Form noValidate onSubmit={onSubmit} onReset={onReset} id="pickup-search" autoComplete="off" className="row">
        {Object.entries(formState).map(([key, value], index) => (<Form.Group
          key={`${index}-${key}`} controlId={key} className={`col-lg-2 col-md-2 col-sm-auto col-auto ${index === 0 && "ps-0"}`} >
          <Form.Label>{humanize(key)}</Form.Label>
          <StyledFormControl size="sm" type="text" name={key} value={value} onChange={handleInputChange} />
        </Form.Group>))}
        <Col xs="auto" sm="auto" md="4" lg="4" className="d-flex align-items-end">
          <Button className="btn btn-primary me-3" type="submit" name="search" disabled={loadingTable}>Search</Button>
          <Button className="btn btn-secondary" type="reset" name="clear" disabled={loadingTable} >Clear</Button>
        </Col>
      </Form>

      {loadingTable ? (<Loader className="text-info" style={null} />) : (<Table className="mt-3" hover>
        <thead>
          <tr>{tableHeader.filter((k) => !skipFields.includes(k)).map((header, index) => (
            <th key={`${index}-${header}`}>{(header.includes("_") || header.charAt(header.length - 1) === header.charAt(header.length - 1).toLowerCase()) ? (humanize(header)) : header}</th>))}
          </tr>
        </thead>
        <tbody>{Array.isArray(tableRows) && tableRows.length ? tableRows.map((row = {}, index) => {
          return (<tr
            key={`${index}-${row.key}`}
            data-row={index}
            data-key_attorney={row?.key_attorney}
            data-key={row?.key}
            data-attorney={row?.attorney}
            data-link={row?.link}
            onClick={handleTableRowClick}
          >{Object.entries(row).filter(([k, v]) => !skipFields.includes(k)).map(([key, value], idx) => (
            <td key={`${index}-${key}`} data-col={idx} data-field={key}>
              {(dateFields.includes(key)) ? value :
                (key === "status" ? (<StyledSpan>{humanize(value)}</StyledSpan>) :
                  key === "email" ? `${value}`.toLowerCase() : humanize(value))}
            </td>
          ))}
          </tr>)
        }) : (<tr><td colSpan={tableHeader.filter((k) => !skipFields.includes(k)).length}><strong>No Pickup Files found.</strong></td></tr>)}
        </tbody>
        {Pages.length > 1 && (<tfoot>
          <tr>
            <td className="text-right" colSpan={tableHeader.filter((k) => !skipFields.includes(k)).length + 1} >
              <nav>
                <ul className="pagination justify-content-end" data-active-page={activePage} >
                  {Pages && Pages.length > 0 && Pages.map((pageNumber, index) => (
                    <li key={`page-${pageNumber}-${index}`} className={`page-item ${pageNumber === activePage ? "disabled" : ""}`} >
                      <Link
                        to={`#page-${pageNumber}`}
                        className="page-link"
                        onClick={(e) => {
                          e.preventDefault();
                          setActivePage(pageNumber);
                        }}
                        {...(pageNumber === activePage) && { tabIndex: -1 }}
                      >{pageNumber}</Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </td>
          </tr>
        </tfoot>)}
      </Table>)}
    </div>
  );
}

export { PickupFiles };
export default PickupFiles;