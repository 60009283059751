import React, { useState, useRef } from 'react';
import { Alert } from 'react-bootstrap'; // Bootstrap Components 
import ClaimantDownloadRequests from '../components/Tables/ClaimantDownloadRequests';
import { useUserData } from "../contexts/AuthContext";
const OVERRIDE_LOGIN = process.env.REACT_APP_OVERRIDE_LOGIN === "true";

const ClaimantsLogs = () => {
  const { userData } = useUserData();
  let { isAuthenticated = false, passport: { user: { org_id = 0, group_id = 6 } = {} } = {} } = userData || {};
  (group_id === 1) && (org_id = 0)
  const [showAlert, setShowAlert] = useState(false);
  const [pageAlert, setPageAlert] = useState({ message: "", alert_type: "info" });
  const claimantLogsRef = useRef();

  // Set up initial variables
  const showDebug = false;
  let adminAccess = (isAuthenticated && Number(group_id) === 2) || OVERRIDE_LOGIN;
  const showDownloadLinks = !adminAccess; // We don't want firm admins to see download links
  const refresh_interval_seconds = 0;
  const tableFields = ['request_id', 'Requested Date/Time', 'requested_by', 'ID', 'QID', 'claimant_name', 'SSN', 'social_status', 'Documents', 'Media'];
  const renamedFields = {
    request_social_id: "ID",
    requested_timestamp: "Requested Date/Time",
    social_status_details: "download_status_details",
    document_status: "Documents",
    media_status: "Media",
  };
  const dateFields = ['Requested Date/Time'];
  const docProgressFields = ['document_percentage', 'docs_processed', 'docs_to_process', 'docs_requested', 'exhibited_status', 'unexhibited_status', 'custom_status'];
  const mediaProgressFields = ['media_percentage', 'media_processed', 'media_to_process', 'media_requested'];
  const skipFields = ['claimant_id', 'matter_id', 'file_downloads', 'download_status_details', 'pdf_settings', ...docProgressFields, ...mediaProgressFields]; // These fields are used by functions and should not be displayed
  const skipPdfFields = ['combine_pdf', 'settings_author', 'settings_creator', 'settings_producer', 'download_exhibited', 'download_unexhibited_if_exhibited_unavailable', 'download_unexhibited', 'download_audio', 'convert_to_pdf'];

  return (<div className="page-container" ref={claimantLogsRef} >
    <h4>Claimant Logs</h4>
    <hr />
    <Alert
      dismissible
      onClose={() => setShowAlert(false)}
      show={showAlert}
      variant={pageAlert.alert_type}
    >{pageAlert.message}</Alert>
    <ClaimantDownloadRequests
      showDownloadLinks={showDownloadLinks}
      showFilter={true}
      showDebug={showDebug}
      showParentAlert={setShowAlert}
      setParentAlert={setPageAlert}
      parentRef={claimantLogsRef}
      refresh_interval_seconds={refresh_interval_seconds}
      tableFields={tableFields}
      renamedFields={renamedFields}
      dateFields={dateFields}
      skipFields={skipFields}
      skipPdfFields={skipPdfFields}
      org_id={org_id}
    />
  </div>);
}

export default ClaimantsLogs;