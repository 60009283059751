import React, { useState, useEffect, useRef } from 'react';
import { useUserData } from '../../contexts/AuthContext';
import { Form, Button, Alert, Col } from 'react-bootstrap';
import { StyledFormInput } from "../../styled/Forms";

// EREDocs Functions 
import { uploader } from "../../utils/EREDocs";
import { validParams, humanize, isObject, humanFileSize } from "../../utils/";

const UploadForm = ({ closeModal = () => { }, ...props } = {}) => {
  const formRef = useRef();
  const { userData } = useUserData();
  let { isAuthenticated = false, passport: {
    user: {
      group_id = 6,
      auth_id: user_auth_id,
      organization: { org_id } = {},
      selected_auth: {
        auth_id: default_auth_id, firm_id: default_firm_id,
      } = {}
    } = {} } = {},
  } = userData || {};

  (org_id && typeof org_id !== 'number') && (org_id = Number(org_id));
  (user_auth_id && typeof user_auth_id !== 'number') && (user_auth_id = Number(user_auth_id));
  (default_auth_id && typeof default_auth_id !== 'number') && (default_auth_id = Number(default_auth_id));
  (!default_auth_id && user_auth_id) && (default_auth_id = user_auth_id);

  let superAdminAccess = (isAuthenticated && Number(group_id) === 1);
  let skipFields = ['claimant_id']; // There's no way the end user will know it. 
  let labelRemap = {
    treatmentSrc: 'Treatment Source',
    treatmentStart: 'Treatment Start',
    treatmentEnd: 'Treatment End',
    documentDate: 'Document Date',
    indr: 'Individual Response',
    ef: 'Electronic Folder'
  };

  const [loadingForm, setLoadingForm] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [modalAlert, setModalAlert] = useState({ message: "", alert_type: "info" });
  const [selectedFile, setSelectedFile] = useState(null);
  const [formState, setFormState] = useState({
    documentTypeSelected: "", // Value of the selected documentType i.e F;Medical Evidence of Record (MER);0001 
    docTypeCodeSelected: "", // documentTypeCode of the selected documentType i.e. 0001
    formTypeSelected: "", // Using documentType to determine the form type: medical or default
    uploadMethod: "indr", // indr, or ef 
    documentType: {},
    defaultForm: [],
    medicalForm: []
  });

  // ======================[ handleInputChange ]===========================
  const handleInputChange = (event) => {
    if (!event?.target) return;
    event.stopPropagation();

    let valid = false;
    let error_count = 0;
    let alertObject = { ...modalAlert, message: "", alert_type: "info" };
    let { name, value, type, checked = false, selectedIndex, files } = event.target;

    if (type === 'checkbox') {
      value = checked;
      valid = true;
    } else if (type === 'radio') {
      (typeof value !== 'boolean') && (value = value === 'true');
      valid = true;
    } else if (type === 'file') {
      // check for 1 and only 1 file
      if (!files || !files[0] || files.length > 1) {
        error_count++;
        alertObject.message += "Please select a single file. ";
        alertObject.alert_type = "warning";
      } else {
        let fileType = "." + files[0].type?.split("/")?.pop()?.toLowerCase();
        let fileSize = files[0].size;
        let allowedFileExtensions = event?.target?.accept?.split(',').map((ext) => ext.trim()).filter(Boolean) || [".wpd", ".doc", ".docx", ".txt", ".rtf", ".xls", ".xlsx", ".pdf", ".tif", ".tiff"];

        // check file size
        if (fileSize >= 200000000) {
          error_count++;
          alertObject.message += `Your file size is ${humanFileSize(fileSize)} which exceeds allowed limit of 200 MB. `;
          alertObject.alert_type = "warning";
        } else {
          valid = true;
        }

        // check file type
        if (!allowedFileExtensions.includes(fileType)) {
          error_count++;
          alertObject.message += `File type of ${fileType} is not allowed. `;
          alertObject.alert_type = "warning";
        } else {
          valid = true;
        }

        if (valid) {
          setSelectedFile(files[0]);
        } else {
          setSelectedFile(null);
        }
      }
    } else {
      event.preventDefault();
      valid = true;
    }

    // format values for formState
    let specialCharacters = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (['site_code', 'dr', 'rf'].includes(name)) {
      value = `${value}`.toUpperCase();
      if (name === 'rf' && value && !['P', 'D', 'N'].includes(value)) {
        valid = false;
        error_count++;
        alertObject.message += `RF must be P, D, or N. `;
      } else if (name === 'dr' && value && !['F', 'S', 'N'].includes(value)) {
        valid = false;
        error_count++;
        alertObject.message += `DR must be F, S, or N. `;
      }
    } else if (name === "ssn") {
      value = `${value}`.replace(/\D/g, '').replace(/^(\d{3})/, '$1-').replace(/-(\d{2})/, '-$1-').replace(/(\d)-(\d{4}).*/, '$1-$2');
    } else if (['documentDate', 'treatmentStart', 'treatmentEnd'].includes(name)) {
      // formate date mm/dd/yyyy
      let cleaned = `${value}`.replace(/\D/g, '');
      let month = cleaned.substring(0, 2);
      let day = cleaned.substring(2, 4);
      let year = cleaned.substring(4, 8);
      if (month.length >= 2 && `${value ?? ""}`.length >= 3) {
        month += "/";
      }
      if (day.length >= 2 && `${value ?? ""}`.length >= 6) {
        day += "/";
      }

      let formatedValue = "";
      if (month) {
        formatedValue += `${month}`;
      }
      if (day) {
        formatedValue += `${day}`;
      }
      if (year) {
        formatedValue += `${year}`;
      }
      if (formatedValue) {
        value = formatedValue;
      }
    } else if (['treatmentSrc', 'notes'].includes(name) && value && specialCharacters.test(value)) {
      value = value.replace(specialCharacters, '').replace(/\s+/g, ' ');
      alertObject.message = `${name} cannot contain special characters !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`;
      alertObject.alert_type = "info";
    }

    // if the document type is selected, set the document type (default / medical)
    let testParams = { ...formState };
    if (name === "documentTypeSelected") {
      let selectedIndexObject = Object.entries(formState.documentType)[selectedIndex - 1];
      let [docTypeCodeSelected, obj = {}] = selectedIndexObject || [];
      let { formType: formTypeSelected = 'default' } = obj || {};
      testParams = { ...testParams, [name]: value, formTypeSelected, docTypeCodeSelected };
      setFormState((prevState) => ({ ...prevState, [name]: value, formTypeSelected, docTypeCodeSelected }));
    }
    else if (name && formState.formTypeSelected && formState[`${formState.formTypeSelected}Form`]) {
      setFormState((prevState) => {
        let newState = { ...prevState };
        newState[`${newState.formTypeSelected}Form`].forEach((inputField) => {
          if (inputField.name === name) {
            inputField.value = value;
          }
          if (name === "upload_method" && ['indr', 'ef'].includes(value)) {
            newState.uploadMethod = value;
          }
        });
        testParams = { ...testParams, ...newState };
        return newState;
      });
    }

    // Validate Form on change 
    let requiredFields = (testParams[`${testParams.formTypeSelected}Form`] || []).filter((inputField) => !['rf', 'dr'].includes(inputField.name) && inputField.required).map((inputField) => inputField.name);
    let formKeyValues = Object.entries((testParams[`${testParams.formTypeSelected}Form`] || [])
      .reduce((acc, { name: stateName, value: stateValue } = {}) => ({
        ...acc,
        ...(stateName === name ? { [name]: value } : { [stateName]: stateValue }),
      }), { docs: "", documentType: testParams.documentTypeSelected })).filter(([k, v]) => v).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
    formKeyValues.upload_method = formState.uploadMethod;

    // Validate Required Fields
    valid = validParams(formKeyValues, requiredFields, 'required');
    if (!valid) {
      error_count += Number(requiredFields.filter((f) => !formKeyValues[f]).length);
      alertObject.message += "Please fill out all required fields. Missing " + requiredFields.filter((f) => !formKeyValues[f]).map((f) => labelRemap[f] ?? humanize(f)).join(', ') + " ";
    }

    // Validate File Type
    if (type !== 'file') {
      let allowedFileExtensions = [".wpd", ".doc", ".docx", ".txt", ".rtf", ".xls", ".xlsx", ".pdf", ".tif", ".tiff"];
      let fileType = null;
      if (formKeyValues?.docs) {
        fileType = "." + formKeyValues.docs?.split(".")?.pop()?.toLowerCase();
      } else if (selectedFile) {
        fileType = "." + selectedFile.type?.split("/")?.pop()?.toLowerCase();
      }
      valid = allowedFileExtensions.includes(fileType);
      if (!valid) {
        error_count++;
        if (fileType) {
          alertObject.message += `File type of ${fileType} is not allowed. `;
        }
        alertObject.message += `Must be one of the following: ${allowedFileExtensions.join(", ")}. `;
        alertObject.alert_type = "warning";
      }

      // Validate File Size
      if (selectedFile) {
        let fileSize = selectedFile.size;
        valid = fileSize <= 200000000;
        if (!valid) {
          error_count++;
          alertObject.message += `Your file size is ${humanFileSize(fileSize)} which exceeds allowed limit of 200 MB. `;
          alertObject.alert_type = "warning";
        }
      }
    }

    // Validate rf
    if (formKeyValues?.rf && name !== 'rf') {
      valid = ['P', 'D', 'N'].includes(formKeyValues?.rf);
      if (!valid) {
        error_count++;
        alertObject.message += `RF must be P, D, or N. `;
        alertObject.alert_type = "warning";
      }
    }

    // Validate dr 
    if (formKeyValues?.dr && name !== 'dr') {
      valid = ['F', 'S', 'N'].includes(formKeyValues?.dr);
      if (!valid) {
        error_count++;
        alertObject.message += `DR must be F, S, or N. `;
        alertObject.alert_type = "warning";
      }
    }

    if (error_count > 0) {
      alertObject.message += ` [Error Count ${error_count}]`;
      valid = false;
    }
    setValidated(valid);
    setShowAlert(!!alertObject.message);
    if (!!alertObject.message) {
      alertObject.message = alertObject.message.trim();
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }
    return;
  };

  // ======================[ loadUploadForm ]===========================
  const loadUploadForm = async () => {
    let alertObj = { ...modalAlert };
    let params = {
      responseType: 'api',
      upload_method: formState.uploadMethod || "indr",
      ...(default_auth_id && { auth_id: default_auth_id }),
      ...(default_firm_id && { firm_id: default_firm_id }),
    };

    let response;
    try {
      setLoadingForm(true);
      response = await uploader.getUploadRequestForm(params);
      alertObj.message = "Upload form ready. ";
      alertObj.alert_type = "success";
    } catch (error) {
      console.error(error);
      response = error?.response || {};
      if (!response?.status) {
        response.status = 500;
      }
      if (!response?.statusText) {
        response.statusText = `${error?.message || "Unknown Error"}`;
      }
      if (!response?.data) {
        response.data = { err: response?.status || 500, message: response?.statusText || "Unknown Error" };
      }
    } finally {
      setLoadingForm(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    let { err, error, message = '', documentType = {}, defaultForm = [], medicalForm = [] } = data || {};
    if (status !== 200) {
      alertObj.alert_type = "danger";
      alertObj.message = `[${status ?? err}] ${statusText}`;
      alertObj.message += error ? `${error} ` : ""; // ScrapeError Message + Controller Error 
      alertObj.message += (typeof response?.data === 'string') ? response.data : message ?? "Unknown Error.";
    }
    alertObj.message += message ? `${message} ` : "";

    let newState = {
      ...(documentType && { documentType }),
    };

    // Save firm_id and auth_id as default values
    if (Array.isArray(defaultForm) && defaultForm.length > 0) {
      defaultForm.forEach((inputField) => {
        if (inputField.name === 'firm_id' && params.firm_id) {
          inputField.value = params.firm_id;
          inputField.disabled = !superAdminAccess;
        } else if (inputField.name === 'attorney_id' && params.auth_id) {
          inputField.value = params.auth_id;
          inputField.disabled = !superAdminAccess;
        }
      });
      newState.defaultForm = defaultForm;
    }

    if (Array.isArray(medicalForm) && medicalForm.length > 0) {
      medicalForm.forEach((inputField) => {
        if (inputField.name === 'firm_id' && params.firm_id) {
          inputField.value = params.firm_id;
          inputField.disabled = !superAdminAccess;
        } else if (inputField.name === 'attorney_id' && params.auth_id) {
          inputField.value = params.auth_id;
          inputField.disabled = !superAdminAccess;
        }
      });
      newState.medicalForm = medicalForm;
    }

    if (isObject(newState) && Object.keys(newState).length > 0) {
      // Must clear out the previous defaultForm and medicalForm arrays 
      setFormState((prevState) => ({ ...prevState, defaultForm: [], medicalForm: [], ...newState }));
    }

    setShowAlert(!!alertObj.message);
    if (!!alertObj.message) {
      setModalAlert(prevState => ({ ...prevState, ...alertObj }));
    }
    return;
  };

  // ======================[ onSubmit ]===========================
  const onSubmit = async (event) => {
    if (!event) return;
    event.stopPropagation(); // Prevent other events from firing on click
    event.preventDefault();

    let alertObject = { ...modalAlert, message: "", alert_type: "info" };
    let formKeyValues = (formState[`${formState.formTypeSelected}Form`] || [])
      .reduce((acc, { name, value } = {}) => ({
        ...acc,
        ...(value && { [name]: value })
      }), { docs: "", documentType: formState.documentTypeSelected });
    formKeyValues.upload_method = formState.uploadMethod; // Override whatever is there with uploadMethod

    // Prepare formData and Add selectedFile to formData 
    const formData = new FormData();
    formData.append("docs", selectedFile);

    // Add formKeyValues to the formData
    let filesField = ['docs'];
    if (Object.keys(formKeyValues).length > 0) {
      Object.entries((formKeyValues)).forEach(([key, value]) => {
        if (!filesField.includes(key) && value) {
          formData.append(key, value);
        }
      });
    }

    let response;
    try {
      if (!validated) {
        throw new Error("Your form input is not valid. Please check your input and try again.");
      }
      setLoadingForm(true);
      response = await uploader.createUploadRequest(formData);
      alertObject.message += "Uploaded Successfully";
    } catch (ex) {
      console.log(ex);
      response = ex?.response || {};
      if (!response?.status) {
        response.status = 500;
      }
      if (!response?.statusText) {
        response.statusText = `${ex?.message || "Unknown Error"}`;
      }
      if (!response?.data) {
        response.data = { err: response?.status || 500, message: response?.statusText || "Unknown Error" };
      }
    } finally {
      setLoadingForm(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    alertObject.alert_type = status !== 200 ? "danger" : "success";
    if (status !== 200) {
      alertObject.message += `[${status ?? err}] ${statusText}`;
      alertObject.message += data?.status ? `${data.status} ` : ""; // ERROR 
      alertObject.message += error ? `${error} ` : ""; // ScrapeError Message + Controller Error 
      alertObject.message += (typeof response?.data === 'string') ? response.data : "Unknown Error.";
    } else {
      // Reset Form State on success 
      onReset();
    }
    if (data?.message) {
      alertObject.message += `${data.message} `;
    }

    // Show Alert success or danger
    setShowAlert(!!alertObject?.message);
    if (!!alertObject?.message) {
      alertObject.message = alertObject.message.trim();
      setModalAlert(prevState => ({ ...prevState, ...alertObject }));
    }

    (closeModal && typeof closeModal === 'function') && closeModal();
    return;
  };

  const onReset = (event) => {
    if (!event) {
      event?.stopPropagation(); // Prevent other events from firing on click
      event?.preventDefault();
    }

    setSelectedFile(null);
    setFormState(prevState => {
      let newState = { ...prevState };
      newState.documentTypeSelected = "";
      newState.docTypeCodeSelected = "";
      newState.formTypeSelected = "";

      newState.defaultForm.forEach((inputField) => {
        if (inputField.name === 'firm_id' && default_firm_id) {
          inputField.value = default_firm_id;
          inputField.disabled = !superAdminAccess;
        } else if (inputField.name === "attorney_id" && default_auth_id) {
          inputField.value = default_auth_id;
          inputField.disabled = !superAdminAccess;
        } else {
          inputField.value = "";
          inputField.disabled = false;
        }
      });
      newState.medicalForm.forEach((inputField) => {
        if (inputField.name === 'firm_id' && default_firm_id) {
          inputField.value = default_firm_id;
          inputField.disabled = !superAdminAccess;
        } else if (inputField.name === "attorney_id" && default_auth_id) {
          inputField.value = default_auth_id;
          inputField.disabled = !superAdminAccess;
        } else {
          inputField.value = "";
          inputField.disabled = false;
        }

      });
      return newState;
    });
  };

  useEffect(() => {
    let mounted = true;
    const init = async () => (await loadUploadForm());
    (mounted) && init();
    return () => mounted = false
  }, [formState.uploadMethod]);

  return (
    <div {...props}>
      <Alert
        dismissible
        onClose={() => setShowAlert(false)}
        show={showAlert}
        variant={modalAlert.alert_type}
      >{modalAlert.message}</Alert>

      <Form ref={formRef} noValidate autoComplete="off" onSubmit={onSubmit} onReset={onReset} className="row">
        <Form.Group className="col-12 d-flex">
          <Form.Label className="mt-2 me-2 bold-text"><span className="text-danger">*</span>Document Type</Form.Label>
          {formState.documentType && Object.keys(formState.documentType).length > 0 && (<Form.Select
            className="flex-grow-1 w-auto"
            name="documentTypeSelected"
            value={formState.documentTypeSelected}
            onChange={handleInputChange}
            required>
            <option value="" key={0}>---Select Document Type---</option>
            {Object.entries(formState.documentType).map(([docTypeCode, obj = {}], idx) => {
              let { label = '', value = '' } = obj || {};
              if (docTypeCode) {
                return (<option key={`${idx}-${docTypeCode}`} value={value} >{label}</option>);
              }
            })}
          </Form.Select>)}
        </Form.Group>

        {formState.formTypeSelected && formState[`${formState.formTypeSelected}Form`] && formState[`${formState.formTypeSelected}Form`].filter((field) => !skipFields.includes(field.name))
          .map(({ name, label, placeholder = '', type = "text", required = false, disabled = false, value = '', options = '', size, maxlength,
            allowedFileExtensions = [".wpd", ".doc", ".docx", ".txt", ".rtf", ".xls", ".xlsx", ".pdf", ".tif", ".tiff"] }, index) => {
            let nOptions = ["No RF or No Barcode", "No DR or No Barcode"]; // Should really be "N"
            let dOptions = ['D or Blank']; // Should really be "D"
            if (options && Array.isArray(options) && type !== "select") {
              options = options.map((o) => nOptions.includes(o) ? "N" : (dOptions.includes(o) ? "D" : o));
            }
            return (<Form.Group key={`${index}-${name}`} controlId={name} className={`col-12 d-flex ${type === "file" ? "align-items-end" : ""}`}>
              <Form.Label className="mt-2 me-2 bold-text">
                {!['rf', 'dr'].includes(name) && required ? <span className="text-danger">*</span> : ""}{label ?? labelRemap[name] ?? humanize(name)}{(humanize(name) !== (label ?? labelRemap[name] ?? humanize(name))) ? ` (${name})` : ""}
              </Form.Label>

              {type === "select" ? (
                <Form.Select
                  className="flex-grow-1 w-auto"
                  key={name}
                  name={name}
                  onChange={handleInputChange}
                  value={formState.uploadMethod || value}
                  required>
                  {options.map((opt, idx) => (<option key={`${idx}-${opt}`} value={opt}>{labelRemap[opt] || opt}</option>))}
                </Form.Select>) : (<StyledFormInput
                  key={name}
                  name={name}
                  type={type}
                  className="flex-grow-1"
                  {...(size) && { size }}
                  {...(maxlength) && { maxlength }}
                  {...(type === "file") && { accept: allowedFileExtensions.join(", ") }}
                  {...(type !== "file") && { value }}
                  placeholder={options ? options.join(", ") : placeholder}
                  onChange={handleInputChange}
                  disabled={disabled}
                  required={!['rf', 'dr'].includes(name) && required}
                />)}
            </Form.Group>)
          })}

        <Col className="col-12"><small className="bold-text"><span className="text-danger">*</span> Required Fields</small></Col>
        <Col className="col-12 mt-3 d-flex justify-content-end">
          <Button className="btn btn-secondary me-3" type="reset" >Reset Form</Button>
          <Button className="btn btn-primary" type="submit" disabled={loadingForm || !validated}>
            Submit Upload Request
          </Button>
        </Col>
      </Form>
    </div>
  );
}

export { UploadForm };
export default UploadForm;