import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useUserData } from '../../contexts/AuthContext';
import { StyledSpan } from '../../styled/Span';
import { StyledListNoBorder } from '../../styled/List';
import { Row, Col, Table, Alert, ListGroup } from 'react-bootstrap';
import { bsoaccount_management } from "../../utils/manage";
import { admin } from "../../utils/EREDocs";
import { humanize, massageData, isObject } from "../../utils/";

const defaultQueryParams = { limit: 25, showDebug: false };
let IntervalId = null;
const BSOAccountStrikeLogs = ({ auth_id, showDebug = false, refresh_interval_seconds = 0,
  tableFields = [], renamedFields = {}, dateFields = [], skipFields = [], special_fields = [], special_field_columns = {},
} = {}) => {
  const { userData, setUserData, clearStrikesClicked } = useUserData();
  let { isAuthenticated = false, MAX_STRIKES_ALLOWED: MSA, passport: {
    user: { auth_id: user_auth_id, user_settings: { MAX_STRIKES_ALLOWED } = {},
      selected_auth: { auth_id: default_auth_id } = {}
    } = {}
  } = {} } = userData || {};

  (!auth_id && default_auth_id) && (auth_id = default_auth_id);
  (typeof auth_id !== 'number') && (auth_id = Number(auth_id));
  (typeof user_auth_id !== 'number') && (user_auth_id = Number(user_auth_id));
  (!MAX_STRIKES_ALLOWED && MSA) && (MAX_STRIKES_ALLOWED = MSA);
  (typeof MAX_STRIKES_ALLOWED !== 'number') && (MAX_STRIKES_ALLOWED = Number(MAX_STRIKES_ALLOWED));
  if (!isObject(renamedFields)) {
    renamedFields = {};
  }
  if (!Array.isArray(dateFields)) {
    dateFields = [];
  }
  if (!Array.isArray(tableFields) || tableFields.length === 0) {
    tableFields = ['ID', 'User', 'Strike Label', 'details', 'Strike Timestamp'];
    renamedFields = {
      strike_id: "ID",
      first_name: "User",
      strike_name: "Strike Label",
      createdAt: "Strike Timestamp",
    };
    special_fields = ['User'];
    special_field_columns = {
      "User": ['User', 'last_name'], // Represents first_name and last_name
    }
    if (!dateFields.includes('Strike Timestamp')) {
      dateFields.push('Strike Timestamp');
    }
  }

  const bsoStrikesLogsRef = useRef();
  const [invokeUpdate, setInvokeUpdate] = useState(false);
  const [tableAlert, setTableAlert] = useState({ alert_type: "warning", message: "In order to protect your account, ERE Docs logs any strikes that it can capture while users interact with the BSO. Currently, the BSO only allows up to 10 strikes before they lock out the BSO account." });
  const [Pages, setPages] = useState([1]);
  const [activePage, setActivePage] = useState(1);
  const [tableHeader, setTableHeader] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [BSOAccountStrikeLogsSummary, setBSOAccountStrikeLogsSummary] = useState({
    status: "",
    strikes_captured: 0,
    strikes_allowed: 0,
    remaining_strikes: 0
  });

  const onClearStrikesClicked = async (event) => {
    (event) && (event.preventDefault());
    await clearStrikesClicked(event, auth_id);
    setInvokeUpdate((prev) => !prev);
  };

  const getTableData = async ({
    startedAt = Date.now(), headerFields = tableFields, renamed_fields = renamedFields, date_fields = dateFields, functionFields = skipFields,
    refresh_in_seconds = refresh_interval_seconds, max_strikes_allowed = MAX_STRIKES_ALLOWED, specialFields = special_fields, specialFieldColumns = special_field_columns
  } = {}) => {
    let now = Date.now();
    let duration_seconds = Math.round((now - startedAt) / 1000);
    let ran_count = !refresh_in_seconds ? 1 : Math.round(duration_seconds / refresh_in_seconds) || 1; // Avoid dividing by zero
    let first_run = now === startedAt || ran_count === 1;

    // Detect if user navigated away from this page 
    let offsetWidth = bsoStrikesLogsRef?.current?.offsetWidth || 0;
    let offsetHeight = bsoStrikesLogsRef?.current?.offsetHeight || 0;
    let navigated_away = !bsoStrikesLogsRef?.current || !(offsetWidth > 0 && offsetHeight > 0);

    if (!first_run && (navigated_away || !isAuthenticated)) {
      console.log("Clearing Interval for details because user navigated away.", { IntervalId, first_run, navigated_away, isAuthenticated, offsetWidth, offsetHeight });
      clearInterval(IntervalId);
      return;
    }

    let queryParams = {
      ...defaultQueryParams,
      ...(auth_id && { auth_id }),
      showDebug,
      page: activePage
    };
    let alertObj = { message: "", alert_type: "info" };
    let response;
    try {
      response = await bsoaccount_management.getBSOAccountStrikeLogs(queryParams);
      alertObj = { alert_type: "warning", message: "In order to protect your account, ERE Docs logs any strikes that it can capture while users interact with the BSO. Currently, the BSO only allows up to 10 strikes before they lock out the BSO account." }
    } catch (error) {
      console.log(error);
      response = error?.response || {};
      if (!response?.data) {
        response.data = { err: 401, error: error.error, details: `${error.message}` };
      }
    }

    let { status, statusText = '', data = {} } = response;
    let { err, error, details, message, strikeLogs: rows = [], count = 0, pageCount = 1, page, pages = [] } = data || {};

    if (status === 200) {
      let dataHeader = [...headerFields, ...functionFields];
      let dataRows = (!Array.isArray(rows) || rows.length === 0) ? [] : massageData(rows, dataHeader, renamed_fields, date_fields, specialFields, specialFieldColumns);
      if (rows.length === 0 || count === 0) {
        if (activePage > 1 && activePage === Pages[Pages.length - 1]) {
          setActivePage(Pages[Pages.length - 2]); // Make sure we don't get stuck loading nothing
        } else {
          alertObj.alert_type = "warning";
          alertObj.message = "In order to protect your account, ERE Docs logs any strikes that it can capture while users interact with the BSO. Currently, the BSO only allows up to 10 strikes before they lock out the BSO account.";
        }
      }

      pages = pages.reduce((acc, item, index) => {
        if (index === 0 && item.number !== 1) {
          acc.push(1); // Always include the first page 
        }
        acc.push(item.number);
        if (index === (pages.length - 1) && !acc.includes(pageCount)) {
          acc.push(pageCount); // Always include the last page
        }
        return acc;
      }, []);

      setTableHeader(dataHeader);
      setTableRows(dataRows);
      setPages(pages);
    } else {
      console.log(`${status} - ${statusText} - ${message} - ${details}`);
      alertObj.alert_type = "danger";
      alertObj.message = `[${status ?? err}] ${statusText} `;    // API Error Code 
      alertObj.message += data?.status ? `${data.status} ` : ""; // API Status 
      alertObj.message += error ? `${error} ` : "";              // API Error 
      alertObj.message += (typeof response?.data === 'string') ? response.data : message ?? (details ? `${details} ` : "") + `Failed to read data for request details.`;
      alertObj.message = alertObj.message.trim();
    }

    setTableAlert(prevState => ({ ...prevState, ...alertObj }));
    if (first_run && refresh_in_seconds) {
      if (IntervalId !== null) {
        console.log("Clearing Interval for details because new interval is being started.", { IntervalId });
        clearInterval(IntervalId);
      }
      let interval_ms = refresh_in_seconds * 1000;
      IntervalId = setInterval(getTableData.bind(null, { startedAt, headerFields, renamed_fields, date_fields, functionFields, refresh_in_seconds }), interval_ms); // Next render every refresh_in_seconds seconds
      console.log("Set Interval for details!", { IntervalId, activePage, loadingLogs, startedAt });
    }

    setBSOAccountStrikeLogsSummary((prevState) => ({
      ...prevState,
      status: (max_strikes_allowed - rows.length) > 1 ? "Good" :
        (max_strikes_allowed - rows.length) >= 0 ? "Okay" : "Bad",
      strikes_captured: rows.length,
      strikes_allowed: max_strikes_allowed,
      remaining_strikes: max_strikes_allowed - rows.length,
    }));

    // console.log("getTableData", { first_run, IntervalId, ran_count, duration_seconds, now, startedAt, navigated_away, isAuthenticated, offsetWidth, offsetHeight, status, statusText });
    return data;
  }; // END getTableData

  useEffect(() => {
    let mounted = true;
    const init = async () => {
      if (auth_id) {
        if (!MAX_STRIKES_ALLOWED) {
          MAX_STRIKES_ALLOWED = await admin.getAuthSetting({ auth_id, key: 'MAX_STRIKES_ALLOWED' })
            .then(({ status = 500, statusText = "Internal Server Error", data: { auth_id: setting_auth_id, settings: { setting = {} } = {} } = {} }) => {
              (status !== 200) && console.log(`${status} ${statusText}`);
              let { value, key } = setting || {};
              (typeof value !== 'number') && (value = Number(value));
              (typeof setting_auth_id !== 'number') && (setting_auth_id = Number(setting_auth_id));
              if (setting_auth_id === user_auth_id) {
                setUserData((prev) => ({
                  ...prev, ...{ MAX_STRIKES_ALLOWED },
                  passport: {
                    ...prev.passport,
                    user: {
                      ...prev.passport.user,
                      user_settings: { ...prev.passport.user.user_settings, ...{ MAX_STRIKES_ALLOWED } }
                    }
                  }
                }));
              }
              return (key === 'MAX_STRIKES_ALLOWED' && setting_auth_id === auth_id) ? value : MAX_STRIKES_ALLOWED;
            });

        }
        await getTableData({ max_strikes_allowed: MAX_STRIKES_ALLOWED });
      }
    }
    (mounted) && init();
    return () => mounted = false
  }, [auth_id, activePage, invokeUpdate]);

  return (
    <div className="page-container" ref={bsoStrikesLogsRef} >
      <h4>BSO Strike Logs</h4>
      <Alert className="p-2" variant={tableAlert.alert_type}>{tableAlert.message}</Alert>
      <Row id="strike-summary" className="mb-4">
        <Col>
          <h4>Strike Summary</h4>
          <StyledListNoBorder>{Object.entries(BSOAccountStrikeLogsSummary).map(([key, value], idx) => (
            <ListGroup.Item key={`${idx}-${key}`} ><strong>{humanize(key)}</strong>: <StyledSpan data-value={value}>{value}</StyledSpan></ListGroup.Item>))}
          </StyledListNoBorder>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table hover>
            <thead>
              <tr>{tableHeader.filter((k) => !skipFields.includes(k)).map((header, index, arr) => (
                <th key={`${index}-${header}`}>{(header.includes("_") || header.charAt(header.length - 1) === header.charAt(header.length - 1).toLowerCase()) ? (humanize(header)) : header}</th>))}
                {(BSOAccountStrikeLogsSummary?.strikes_captured > 0 && (<th className="d-flex justify-content-end">
                  <button style={{ cursor: "pointer", marginLeft: "5px" }} title={"Click to clear strikes."} onClick={onClearStrikesClicked}>Clear Strikes</button>
                </th>))}
              </tr>
            </thead>
            <tbody>{Array.isArray(tableRows) && tableRows.length ? tableRows.map((row = {}, index) => {
              return (<tr
                key={`${index}-${row.ID}`}
                data-row={index}
                data-strike_id={row?.ID}
                data-strike_name={row?.strike_name}
              >{Object.entries(row).filter(([k, v]) => !skipFields.includes(k)).map(([key, value], idx, arr) => (
                <td key={`${index}-${key}`} data-col={idx} data-field={key} {...((idx === arr.length - 1) && { colSpan: 2 })}>{(dateFields.includes(key)) ? value : humanize(value)}</td>
              ))}
              </tr>)
            }) : (<tr><td colSpan={tableHeader.filter((k) => !skipFields.includes(k)).length}><strong>No strikes captured in the last 24 hours.</strong></td></tr>)}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={tableHeader.filter((k) => !skipFields.includes(k)).length + 1}>
                  <span>Showing all captured strikes in the last 24 hour period.</span>
                </td>
              </tr>
              {Pages.length > 1 && (<tr>
                <td className="text-right" colSpan={tableHeader.filter((k) => !skipFields.includes(k)).length + 1} >
                  <nav>
                    <ul className="pagination justify-content-end" data-active-page={activePage} >
                      {Pages && Pages.length > 0 && Pages.map((pageNumber, index) => (
                        <li key={`page-${pageNumber}-${index}`} className={`page-item ${pageNumber === activePage ? "disabled" : ""}`} >
                          <Link
                            to={`#page-${pageNumber}`}
                            className="page-link"
                            onClick={(e) => {
                              e.preventDefault();
                              setActivePage(pageNumber);
                            }}
                            {...(pageNumber === activePage) && { tabIndex: -1 }}
                          >{pageNumber}</Link>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </td>
              </tr>)}
            </tfoot>
          </Table>
        </Col>
      </Row>
    </div>
  );
}

export default BSOAccountStrikeLogs;