import React from "react";
import StyledLoader from "../styled/Loader";

const Loader = ({ className, children, style }) => {
  if (style === undefined) {
    return (
      <StyledLoader className={`${className ? className : ""}`}>
        <h1>Loading...</h1>
        {children}
      </StyledLoader>
    )
  }

  return (
    <div className={`${className ? className : ""}`} style={style} >
      <h4>Loading...</h4>
      {children}
    </div>
  )
}
export default Loader;
