import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useUserData } from '../../contexts/AuthContext';
import { Col, Button, Table, Alert, Form } from 'react-bootstrap';
import { StyledSpan } from '../../styled/Span';
import { StyledFormControl } from '../../styled/Forms';
import { humanize, massageData, handleTableRowClick, formatDate } from "../../utils/";
import { bso } from "../../utils/EREDocs";
const defaultQueryParams = { limit: 25, showDebug: false };
let IntervalId = null;

const AppealsStatusReport = ({ auth_id, ...props } = {}) => {
  const { userData } = useUserData();
  let { isAuthenticated = false,
    passport: {
      user: {
        auth_id: user_auth_id,
        selected_auth: {
          auth_id: default_auth_id
        } = {}
      } = {}
    } = {} } = userData || {};
  (!auth_id && default_auth_id) && (auth_id = default_auth_id);
  (!auth_id && user_auth_id) && (auth_id = user_auth_id);
  (typeof auth_id !== 'number') && (auth_id = Number(auth_id));

  let refresh_interval_seconds = 0;
  let skipFields = ['appeals_status_report_id'];
  let dateFields = ['status_date', 'request_date'];
  let dateString = "MM/DD/YYYY";
  let dateTimeZone = null; // null will ignore timezone and keep raw date value as is 
  let labelFields = {
    status_of_case: "status",
  };
  let renamedFields = {
    ...labelFields,
    last_four_social: 'ssn',
    first_name: 'name',
  };
  let special_fields = ['name'];
  let special_field_columns = {
    'name': ['name', 'middle_name', 'last_name'],
  };
  let tableFields = ['name', 'ssn', 'status', 'status_date', 'claim_type', 'electronic_folder', 'request_date'];

  const asrTableRef = useRef();
  const [mostRecentImport, setMostRecentImport] = useState(null);
  const [invokeUpdate, setInvokeUpdate] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [Pages, setPages] = useState([1]);
  const [activePage, setActivePage] = useState(1);
  const [showAlert, setShowAlert] = useState(false);
  const [modalAlert, setModalAlert] = useState({ message: "", alert_type: "info" });
  const [tableHeader, setTableHeader] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [formState, setFormState] = useState({
    first_name: "",
    last_name: "",
    status_of_case: "",
  });

  const handleInputChange = (event) => {
    if (!event?.target) return;
    event.stopPropagation(); // Prevent other events from firing on click

    let { name, value, type, checked = false } = event.target || {};
    if (type === 'checkbox') {
      value = checked;
    } else {
      event.preventDefault();
    }

    setFormState((prevState) => ({ ...prevState, [name]: value }));
    return;
  };

  const rowClick = (rowObject, selectedItem) => {
    // Object.keys(rowObject).length > 0 && console.log("rowClick", rowObject);
    // Object.keys(selectedItem).length > 0 && console.log("selectedItem", selectedItem);
    return;
  };

  /**
 * getLastImportedDateTime - Sets the most recent import date for display on frontend
 * 
 * @param {*} response  (passed in argument is the returned response from paged response of get ASR rows for auth_id)
 */
  const getLastImportedDateTime = async (response) => {
    let latestDateFromRecords = new Date(1970, 2, 15);
    try {
      if (response?.data?.rows?.length > 0) {
        let arrayRows = response.data.rows;
        arrayRows.forEach((record) => {
          let date = new Date(record['createdAt']);
          if (date > latestDateFromRecords) {
            latestDateFromRecords = date;
          }
        });
      }
    } catch (error) {
      console.error(error);
      setMostRecentImport(null);
    }
    if (latestDateFromRecords > new Date(1970, 2, 15)) {
      setMostRecentImport(formatDate(latestDateFromRecords, "MM/DD/YYYY hh:mm:ss A"));
    } else {
      setMostRecentImport(null);
    }
  }

  const getTableData = async ({
    startedAt = Date.now(), headerFields = tableFields, renamed_fields = renamedFields, date_fields = dateFields, functionFields = skipFields,
    specialFields = special_fields, specialFieldColumns = special_field_columns, date_string = dateString, date_time_zone = dateTimeZone,
    refresh_in_seconds = refresh_interval_seconds,
  } = {}) => {
    let now = Date.now();
    let duration_seconds = Math.round((now - startedAt) / 1000);
    let ran_count = !refresh_in_seconds ? 1 : Math.round(duration_seconds / refresh_in_seconds) || 1; // Avoid dividing by zero
    let first_run = now === startedAt || ran_count === 1;

    // Detect if user navigated away from this page 
    let offsetWidth = asrTableRef?.current?.offsetWidth || 0;
    let offsetHeight = asrTableRef?.current?.offsetHeight || 0;
    let navigated_away = !asrTableRef?.current || !(offsetWidth > 0 && offsetHeight > 0);

    if (!first_run && (navigated_away || !isAuthenticated)) {
      console.log("Clearing Interval because user navigated away.", { IntervalId, first_run, navigated_away, isAuthenticated, offsetWidth, offsetHeight });
      clearInterval(IntervalId);
      return;
    }

    let alertObj = { ...modalAlert };
    let filterValues = Object.entries(formState).filter(([id, value]) => value); // Filter out empty values
    let filteredData = (filterValues.length === 0) ? { auth_id } : Object.entries(formState).reduce((acc, [id, value]) => ({ ...acc, ...(value && { [id]: value }) }), { auth_id });
    let params = {
      ...defaultQueryParams,
      page: activePage,
      filteredData,
    };
    let response;
    try {
      setLoadingForm(true);
      if (!filteredData?.auth_id) {
        throw new Error("No auth_id found in filteredData");
      }
      response = await bso.findASR(params);
      getLastImportedDateTime(response); // derive the latest import / read createdAt dates of the HSR paged returned records and use that date for display of last import
      alertObj.message = "";
    } catch (error) {
      console.log(error);
      response = error?.response || {};
      response.status = 500;
      response.statusText = `${error.message}`;
      if (!response?.data) {
        response.data = { err: 401, error: error.error, details: `${error.message}` };
      }
    } finally {
      setLoadingForm(false);
    }

    let { status = 500, statusText = '', data = {} } = response;
    let { err, error, details, message, rows = [], count = 0, pageCount = 1, page, pages = [] } = data || {};

    if (status === 200) {
      let dataHeader = [...headerFields, ...functionFields];
      let dataRows = (!Array.isArray(rows) || rows.length === 0) ? [] : massageData(rows, dataHeader, renamed_fields, date_fields, specialFields, specialFieldColumns, date_string, date_time_zone);
      if (rows.length === 0 || count === 0) {
        if (activePage > 1 && activePage === Pages[Pages.length - 1]) {
          setActivePage(Pages[Pages.length - 2]); // Make sure we don't get stuck loading nothing
        } else {
          alertObj.alert_type = "info";
          alertObj.message = "No Results found.";
        }
      }

      pages = pages.reduce((acc, item, index) => {
        if (index === 0 && item.number !== 1) {
          acc.push(1); // Always include the first page 
        }
        acc.push(item.number);
        if (index === (pages.length - 1) && !acc.includes(pageCount)) {
          acc.push(pageCount); // Always include the last page
        }
        return acc;
      }, []);

      setTableHeader(dataHeader);
      setTableRows(dataRows);
      setPages(pages);
    } else {
      alertObj.alert_type = "danger";
      alertObj.message = `[${status ?? err}] ${statusText} `;    // API Error Code 
      alertObj.message += data?.status ? `${data.status} ` : ""; // API Status 
      alertObj.message += error ? `${error} ` : "";              // API Error 
      alertObj.message += (typeof response?.data === 'string') ? response.data : message ?? (details ? `${details} ` : "") + `Failed to read data.`;
      alertObj.message = alertObj.message.trim();
    }

    setShowAlert(!!alertObj.message);
    if (!!alertObj.message) {
      setModalAlert(prevState => ({ ...prevState, ...alertObj }));
    }
    if (first_run && refresh_in_seconds) {
      if (IntervalId !== null) {
        console.log("Clearing Interval because new interval is being started.", { IntervalId });
        clearInterval(IntervalId);
      }
      let interval_ms = refresh_in_seconds * 1000;
      IntervalId = setInterval(getTableData.bind(null, { startedAt, headerFields, renamed_fields, date_fields, functionFields, refresh_in_seconds }), interval_ms); // Next render every refresh_in_seconds seconds
      console.log("Set Interval!", { IntervalId, activePage, loadingForm, startedAt });
    }

    // console.log("getTableData", { first_run, IntervalId, ran_count, duration_seconds, now, startedAt, navigated_away, isAuthenticated, offsetWidth, offsetHeight, status, statusText });
    return data;
  }; // END getTableData

  const onSubmit = async (event) => {
    if (!event?.target) return;
    event.preventDefault();
    return setInvokeUpdate(prev => !prev);
  }

  const onReset = (event) => {
    if (!event?.target) return;
    event.stopPropagation(); // Prevent other events from firing on click
    event.preventDefault();
    setActivePage(1);
    setFormState({
      first_name: "",
      last_name: "",
      status_of_case: "",
    });
    return setInvokeUpdate(prev => !prev);
  };

  useEffect(() => {
    let mounted = true;
    const init = async () => {
      await getTableData();
    }
    if (mounted) init();
    return () => mounted = false;
  }, [invokeUpdate, auth_id, activePage]);

  return (
    <div ref={asrTableRef} {...props}>
      <Alert
        dismissible
        onClose={() => setShowAlert(false)}
        show={showAlert}
        variant={modalAlert.alert_type}
      >{modalAlert.message}</Alert>

      {mostRecentImport && <div className="alert alert-info">Last imported: {mostRecentImport}</div>}

      <Form noValidate autoComplete="off" id="asr-search" name="asr-search" onSubmit={onSubmit} onReset={onReset} className="row">
        {Object.entries(formState).map(([key, value], index) => (<Form.Group
          key={`${index}-${key}`} controlId={key} className={`col-lg-3 col-md-3 col-sm-auto col-auto ${index === 0 && "ps-0"}`} >
          <Form.Label>{humanize(labelFields[key] ?? key)}</Form.Label>
          <StyledFormControl size="sm" type="text" name={key} value={value} onChange={handleInputChange} />
        </Form.Group>))}
        <Col xs="auto" sm="auto" md="3" lg="3" className="d-flex align-items-end">
          <Button className="btn btn-primary me-3" type="submit" name="search" disabled={loadingForm}>Search</Button>
          <Button className="btn btn-secondary" type="reset" name="clear" disabled={loadingForm} >Clear</Button>
        </Col>
      </Form>

      <Table hover>
        <thead>
          <tr>{tableHeader.filter((k) => !skipFields.includes(k)).map((header, index) => (
            <th key={`${index}-${header}`}>{(header.includes("_") || header.charAt(header.length - 1) === header.charAt(header.length - 1).toLowerCase()) ? (humanize(header)) : header}</th>))}
          </tr>
        </thead>
        <tbody>{Array.isArray(tableRows) && tableRows.length ? tableRows.map((row = {}, index) => {
          return (<tr
            key={`${index}-${row.key}`}
            data-row={index}
            data-appeals_status_report_id={row?.appeals_status_report_id}
            onClick={(e) => (handleTableRowClick.call(null, e, rowClick))}
          >{Object.entries(row).filter(([k, v]) => !skipFields.includes(k)).map(([key, value], idx) => (
            <td key={`${index}-${key}`} data-col={idx} data-field={key}>
              {(dateFields.includes(key)) ? value :
                (['status', 'electronic_folder'].includes(key) ? (<StyledSpan>{humanize(value)}</StyledSpan>) :
                  key === "email" ? `${value}`.toLowerCase() : humanize(value))}
            </td>
          ))}
          </tr>)
        }) : (<tr><td colSpan={tableHeader.filter((k) => !skipFields.includes(k)).length}><strong>No Results found.</strong></td></tr>)}
        </tbody>
        {Pages.length > 1 && (<tfoot>
          <tr>
            <td className="text-right" colSpan={tableHeader.filter((k) => !skipFields.includes(k)).length + 1} >
              <nav>
                <ul className="pagination justify-content-end" data-active-page={activePage} >
                  {Pages && Pages.length > 0 && Pages.map((pageNumber, index) => (
                    <li key={`page-${pageNumber}-${index}`} className={`page-item ${pageNumber === activePage ? "disabled" : ""}`} >
                      <Link
                        to={`#page-${pageNumber}`}
                        className="page-link"
                        onClick={(e) => {
                          e.preventDefault();
                          setActivePage(pageNumber);
                        }}
                        {...(pageNumber === activePage) && { tabIndex: -1 }}
                      >{pageNumber}</Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </td>
          </tr>
        </tfoot>)}
      </Table>
    </div>
  );
}

export { AppealsStatusReport };
export default AppealsStatusReport;