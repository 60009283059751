import React from "react";
import { useUserData } from "../contexts/AuthContext";
const OVERRIDE_LOGIN = process.env.REACT_APP_OVERRIDE_LOGIN === "true";

const Debug = ({ debugData, debugLabel, children, ...props } = {}) => {
  const { userData } = useUserData();
  let { passport: { user: { group_id = 6 } = {} } = {}, isAuthenticated = false } = userData || {};
  let superAdminAccess = (isAuthenticated && Number(group_id) === 1) || OVERRIDE_LOGIN;
  let adminAccess = (isAuthenticated && Number(group_id) === 2) || OVERRIDE_LOGIN;
  let nonAdmin = (isAuthenticated && ![1, 2].includes(Number(group_id))) || OVERRIDE_LOGIN;
  if (superAdminAccess || adminAccess) {
    return (
      <div {...props}>
        {children}
        {debugData && Object.keys(debugData).length > 0 && (<div className="card" id="debug">
          <div className="card-header">
            <h2>{debugLabel}</h2>
          </div>
          {(debugData !== undefined && debugLabel !== undefined) && <div className="card-body">
            <pre>{JSON.stringify(debugData, null, 2)}</pre>
          </div>}
        </div>)}
      </div>
    )
  }

  return null;
}

export default Debug;
