import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUserData } from "../contexts/AuthContext";
import StepProgressBar from 'react-step-progress';

import 'react-step-progress/dist/index.css';
import { Accordion, Button, Card, Col, Alert, Form } from 'react-bootstrap';
import { StyledListNoBorder } from "../styled/List";
import { StyledRequestTable } from "../styled/Table";
import { StyledCardLight } from "../styled/Card";
import { StyledAccordionDownloader } from "../styled/Accordion";
import { StyledGreenCheckbox } from "../styled/Forms";

// EREDocs Functions 
import { bso, downloader } from "../utils/EREDocs"; // tryAutoLogin, checkCookies, processRequest
import { dehumanize, humanize, isObject, splitArrayChunks, sleep } from '../utils/';

let _isValid = true;
const DefaultDownloadRequest = () => {
  const navigate = useNavigate();
  const { userData, setUserData } = useUserData();
  let { isAuthenticated = false, server_autologin = false, client_autologin = false,
    passport: {
      user: {
        user_id, org_id, prefix, firm_id, auth_id: default_auth_id,
        user_settings = {},
        selected_auth: {
          auth_id, cookie_id, bsoLoggedIn = false, bso_username, firm_id: _firm_id, attorney, default_user_id, Cookies = []
        } = {},
      } = {}
    } = {},
  } = userData || {};
  let { cookie_id: _cookie_id, auth_id: _auth_id, tabid, cookies } = Cookies[0] || {};

  (!auth_id && default_auth_id) && (auth_id = default_auth_id);
  (auth_id && _auth_id && auth_id !== _auth_id) && (auth_id = _auth_id);
  (!user_id && default_user_id) && (user_id = default_user_id);
  (cookie_id && _cookie_id && cookie_id !== _cookie_id) && (cookie_id = _cookie_id);
  (!cookie_id && _cookie_id) && (cookie_id = _cookie_id);
  (!firm_id && _firm_id) && (firm_id = _firm_id);
  (typeof auth_id !== 'number') && (auth_id = Number(auth_id));
  (typeof user_id !== 'number') && (user_id = Number(user_id));
  (typeof org_id !== 'number') && (org_id = Number(org_id));
  (typeof cookie_id !== 'number') && (cookie_id = Number(cookie_id));

  const [isValid, setIsValid] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [pageAlert, setPageAlert] = useState({ message: "", alert_type: "info" });
  const [activeStep, setActiveStep] = useState(1);
  const [requestObject, setRequestObject] = useState({
    info: {
      request_type_id: bsoLoggedIn ? 1 : 2,
    },
    socials: []
  });
  const [downloadOptions, setDownloadOptions] = useState([
    {
      name: "DOWNLOAD_INCREMENTAL_DOWNLOADER",
      label: "Download Incremental Downloader",
      checked: false,
      disabled: false,
    },
    {
      name: "DOWNLOAD_AUDIO",
      label: "Download Audio",
      checked: false,
      disabled: false,
    },
    {
      name: "DOWNLOAD_UNEXHIBITED",
      label: "Download Unexhibited",
      checked: false,
      disabled: false,
    },
    {
      name: "DOWNLOAD_UNEXHIBITED_FALLBACK",
      label: "Download Unexhibited Fallback",
      checked: false,
      disabled: false,
    },
    {
      name: "DOWNLOAD_EXHIBITED",
      label: "Download Exhibited",
      checked: true,
      disabled: false,
    },
    {
      name: "PDF_OCR_PROCESSING",
      label: "OCR Processing",
      checked: false,
      disabled: false,
    },
    {
      name: "PDF_INCLUDE_BACKTOTOP_LINK",
      label: "Include Backtotop Link",
      checked: true,
      disabled: false,
    },
    {
      name: "PDF_INCLUDE_TITLES",
      label: "Include Titles",
      checked: true,
      disabled: false,
    },
    {
      name: "PDF_INCLUDE_TOC",
      label: "Include Toc",
      checked: true,
      disabled: false,
    },
    {
      name: "PDF_SETTINGS_AUTHOR",
      label: "Settings Author",
      value: "ERE",
      disabled: false,
    },
    {
      name: "PDF_SETTINGS_CREATOR",
      label: "Settings Creator",
      value: "ERE",
      disabled: false,
    },
    {
      name: "PDF_SETTINGS_PRODUCER",
      label: "Settings Producer",
      value: "ERE",
      disabled: false,
    }
  ]);

  // References for accordion steps
  const selectOptionsStep = useRef(); // Step 1 
  const enterSocialStep = useRef();   // Step 2
  const requestTableRef = useRef();   // Step 2 - Socials Table 
  const reviewAndSubmit = useRef();   // Step 3

  // ===============[ toggleAccordionSlide ]==================================
  const toggleAccordionSlide = function (event) {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation();

    let { step = 1, innerText = '' } = this || {};
    if (!innerText) {
      innerText = event.target?.innerText
    }
    innerText = `${innerText}`.toLowerCase().trim();
    let targetElement = null;

    if (innerText && innerText.includes('enter')) {
      step = 2;
    } else if (innerText && innerText.includes('review')) {
      step = 3;
    } else if (innerText && innerText.includes('select')) {
      step = 1;
    }

    switch (step) {
      case 2:
        if (enterSocialStep?.current.querySelector('button')) {
          targetElement = enterSocialStep.current.querySelector('button');
        }
        break;

      case 3:
        if (reviewAndSubmit?.current.querySelector('button')) {
          targetElement = reviewAndSubmit.current.querySelector('button');
        }
        break;

      default: // Step 1 (default)
        if (selectOptionsStep?.current.querySelector('button')) {
          targetElement = selectOptionsStep.current.querySelector('button');
        }
        break;
    }

    if (targetElement) {
      targetElement.click();
    }
  };

  // ===============[ handleRequestChanges ]==================================
  const handleRequestChanges = async (event) => {
    if (!event?.target) return;
    event.stopPropagation(); // Prevent other events from firing on click

    let error_count = 0;
    let alertOptions = {
      message: '',
      alert_type: "info"
    };

    let { target = {}, which, keyCode, key } = event || {};
    let { name, value, checked, disabled = false, type, dataset, tagName, innerText, href, parentElement, className, title } = target;
    let action = (href || ["BUTTON", "SPAN", "A"].includes(tagName) && innerText) ?
      (`${innerText}`.toLowerCase().includes("remove") ? "remove" : false) :
      (type === "checkbox" && "select_all_ssn" === name ? name :
        tagName === "TD" ? "toggle_selected" : false);
    if (type !== 'checkbox') {
      event.preventDefault();
    }

    // Go up one level in the DOM but preserve innerText
    if (tagName !== "TD" && parentElement?.tagName === "TD") {
      target = parentElement;
      parentElement = target.parentElement;
      dataset = { ...dataset, ...target?.dataset };
      tagName = target?.tagName;
      if (!innerText && target?.innerText) {
        innerText = target.innerText;
      }
    }

    let rowObject = {
      innerText,
      tagName,
      ...(type && { type }),
      ...dataset,
      ...parentElement?.dataset,
    };

    // Extract row items and save to rowObject and selectedItem
    let selectedItem = {};
    if (tagName === "TD") {
      // Update Step 2 - Remove Social Item from table 
      let requestTableElement = requestTableRef?.current || parentElement.closest("table") || document.querySelector("table");
      let tdArray = Array.from(parentElement.querySelectorAll("td")).map(({ innerText }) => innerText).filter((i) => i) || [];
      let thArray = Array.from(requestTableElement.querySelectorAll("thead tr th")).map(({ innerText }) => dehumanize(innerText)).filter((i) => i) || [];

      if ('col' in rowObject) { // Extract selectedItem
        let col = Number(rowObject.col);
        if (col && innerText === tdArray[col]) {
          selectedItem[thArray[col]] = tdArray[col];
        }
      }

      // Match thArray length with tdArray length
      if (tdArray.length > thArray.length) {
        // slice extra elements to match thArray length
        tdArray = tdArray.slice(0, thArray.length);
      } else if (tdArray.length < thArray.length) {
        // Fill in the blanks with null values to match thArray length
        let startIndex = tdArray.length;
        tdArray.length = thArray.length;
        tdArray.fill(null, startIndex);
      }

      if (tdArray.length === thArray.length) {
        for (let i = 0; i < thArray.length; i++) {
          rowObject[thArray[i]] = tdArray[i];
        }
      }

      setIsValid(!!requestObject?.socials.length > 0);
    } else if (tagName === "TEXTAREA" && value) {
      // Update Step 2 - Enter Socials 
      let newSocials = value.split('\n').reduce((acc, line) => {
        if (line.includes(",")) {
          line.split(",").forEach(item => {
            acc.push(`${item}`.trim().replace(/\D/g, '')); // Remove all non-numeric characters from comma delimited string
          });
        } else {
          acc.push(`${line}`.trim().replace(/\D/g, '')); // Remove all non-numeric characters from string
        }
        return acc;
      }, []).filter((raw_ssn = '') => {
        let isValidSSN = false; // Reset

        // Validate SSN 
        let formattedSSN = raw_ssn.replace(/^(\d{3})/, '$1-').replace(/-(\d{2})/, '-$1-').replace(/(\d)-(\d{4}).*/, '$1-$2');
        if (/[A-Za-z]+/g.test(raw_ssn)) { // Note: This should never happen because we stripped out all non-numeric characters above 
          error_count++;
          alertOptions.message = `${raw_ssn} contains letters. Only numbers are allowed.`;
          alertOptions.alert_type = "danger";

        } else if ((/[-]+/g.test(raw_ssn) && raw_ssn.length !== 11) || (/[-]+/g.test(raw_ssn) === false && raw_ssn.length !== 9)) {
          error_count++;
          alertOptions.message = `Social Security Number ${raw_ssn} must be 9 characters.`;
          alertOptions.alert_type = "warning";

        } else if (!!requestObject.socials.find(({ ssn }) => ssn === formattedSSN)) {
          error_count++;
          alertOptions.message = `${formattedSSN} is a duplicate and will not be added.`;
          alertOptions.alert_type = "danger";

        } else {
          error_count = 0;
          isValidSSN = true;
        }
        return isValidSSN;
      })
        .map(_ssn => {
          return {
            [name]: _ssn.replace(/\D/g, '').replace(/^(\d{3})/, '$1-').replace(/-(\d{2})/, '-$1-').replace(/(\d)-(\d{4}).*/, '$1-$2'),
            convert_to_pdf: true,
            Download_Type: "Default",
            Status: "Ready",
            // newOptions,
          }
        });

      if (newSocials.length > 0) {
        document.getElementById('enter_ssns').value = '';
        alertOptions.message = '';
        alertOptions.alert_type = "info";
        setShowAlert(false);
        setPageAlert(prevState => ({ ...prevState, ...alertOptions }));
        setRequestObject((prevState) => ({ ...prevState, ...{ ...{ info: { request_type_id: bsoLoggedIn ? 1 : 2 }, socials: [...prevState.socials, ...newSocials], } } }));

        // Limit Bottom Queue 
        let exceededLimit = ((requestObject?.socials.length + newSocials.length) > user_settings.BOTTOM_QUEUE_LIMIT ?? 5);
        let selectOptionsStepElement = selectOptionsStep?.current?.closest('.accordion-item').querySelector('.accordion-body') || document.querySelector(".accordion .accordion-body");
        if (selectOptionsStepElement) {
          Array.from(selectOptionsStepElement.querySelectorAll("input[type='checkbox']")).forEach((el) => {
            if (el.name === 'DOWNLOAD_INCREMENTAL_DOWNLOADER') {
              if (exceededLimit) {
                el.click();
                if (!el.disabled) {
                  el.disabled = true;
                }
                if (el?.parentElement?.querySelector('span') && !el.parentElement.querySelector('span').className.includes("text-muted")) {
                  el.parentElement.querySelector('span').classList.add("text-muted");
                }
              } else {
                if (el.disabled) {
                  el.disabled = false;
                }
                if (el?.parentElement?.querySelector('span')?.className.includes("text-muted")) {
                  el.parentElement.querySelector('span').classList.remove("text-muted");
                }
              }
            }
          });
        }

        if (!exceededLimit) {
          setIsValid(true);
        }
      }
    } else if (tagName === "INPUT" && !dataset.ssn) {
      // Limit Bottom Queue 
      if (name === "DOWNLOAD_INCREMENTAL_DOWNLOADER") {
        let exceededLimit = (requestObject?.socials.length > user_settings.BOTTOM_QUEUE_LIMIT ?? 5);
        if (exceededLimit) {
          if (!disabled) {
            event.target.disabled = true;
          }
          if (checked) {
            checked = false;
            event.target.checked = checked;
          }
          if (parentElement?.querySelector('span') && !parentElement.querySelector('span').className.includes("text-muted")) {
            event.target.parentElement.querySelector('span').classList.add("text-muted");
          }
        } else {
          if (disabled) {
            event.target.disabled = false;
          }
          if (parentElement?.querySelector('span')?.className.includes("text-muted")) {
            event.target.classList.remove("text-muted");
          }
        }
      }

      // Update Step 1 - Select Download Options 
      setDownloadOptions(prevState => {
        let newState = [...prevState];
        newState.forEach(item => {
          if (item.name === name) {
            if (type === 'checkbox') {
              item.checked = checked;
            } else {
              item.value = value;
            }
          }
        });

        setIsValid(!!newState.filter(({ checked = false, name = '' }) => checked && !`${name}`.toUpperCase().includes('PDF_')).length > 0);
        return newState;
      });
    }

    // Object.keys(rowObject).length > 0 && console.log("handleRequestChanges", rowObject);
    // Object.keys(selectedItem).length > 0 && console.log("selectedItem", selectedItem);
    if (action) {
      let { social_security_number, remove_all, row } = rowObject;
      if (action === 'remove') {
        let exceededLimit = ((requestObject?.socials.length - 1) > user_settings.BOTTOM_QUEUE_LIMIT ?? 5);
        if (social_security_number) { // Remove Social from Request Object
          setRequestObject((prevState) => ({
            ...prevState, ...{
              info: { request_type_id: bsoLoggedIn ? 1 : 2 },
              socials: prevState.socials.filter(({ ssn }) => ssn !== social_security_number)
            }
          }));
        } else { // Remove Selected Socials from Request Object
          const removeAll_regex = /\b(remove).*(all)\b/gi;
          let requestTableElement = requestTableRef?.current || parentElement.closest("table") || document.querySelector("table");
          let selectedItems = Array.from(requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']"))
            .reduce((acc, el) => ([...acc, ...(el?.checked && el?.dataset?.ssn ? [el.dataset.ssn] : [])]), []);
          exceededLimit = ((requestObject?.socials.length - selectedItems.length) > user_settings.BOTTOM_QUEUE_LIMIT ?? 5);

          if ((requestObject.socials.length === selectedItems.length && selectedItems.length > 0) || (remove_all || innerText && removeAll_regex.test(innerText))) {
            setRequestObject((prevState) => ({
              ...prevState, ...{
                info: { request_type_id: bsoLoggedIn ? 1 : 2 },
                socials: []
              }
            }));
          } else if (selectedItems.length > 0) {
            setRequestObject((prevState) => ({
              ...prevState, ...{
                info: { request_type_id: bsoLoggedIn ? 1 : 2 },
                socials: prevState.socials.filter(({ ssn }) => !selectedItems.includes(ssn))
              }
            }));
          }
        }

        // Limit Bottom Queue 
        let selectOptionsStepElement = selectOptionsStep?.current?.closest('.accordion-item').querySelector('.accordion-body') || document.querySelector(".accordion .accordion-body");
        if (selectOptionsStepElement) {
          Array.from(selectOptionsStepElement.querySelectorAll("input[type='checkbox']")).forEach((el) => {
            if (el.name === 'DOWNLOAD_INCREMENTAL_DOWNLOADER') {
              if (exceededLimit) {
                el.click();
                if (!el.disabled) {
                  el.disabled = true;
                }
                if (el?.parentElement?.querySelector('span') && !el.parentElement.querySelector('span').className.includes("text-muted")) {
                  el.parentElement.querySelector('span').classList.add("text-muted");
                }
              } else {
                if (el.disabled) {
                  el.disabled = false;
                }
                if (el?.parentElement?.querySelector('span')?.className.includes("text-muted")) {
                  el.parentElement.querySelector('span').classList.remove("text-muted");
                }
              }
            }
          });
        }

      } else if (action === 'select_all_ssn') {
        let requestTableElement = requestTableRef?.current || parentElement.closest("table") || document.querySelector("table");
        if (requestTableElement && requestTableElement.querySelector('thead tr th button[id="remove_selected"]')) {
          let removeBulkButtonState = requestTableElement.querySelector('thead tr th button[id="remove_selected"]').innerText;
          requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']").forEach((el) => {
            if (!!checked && removeBulkButtonState === 'Remove Selected') {
              requestTableElement.querySelector('thead tr th button[id="remove_selected"]').innerText = 'Remove All';
            } else if (!checked && removeBulkButtonState === 'Remove All') {
              requestTableElement.querySelector('thead tr th button[id="remove_selected"]').innerText = 'Remove Selected';
            }
            el.checked = !!checked
          });
        }
      } else if (action === "toggle_selected") {
        let requestTableElement = requestTableRef?.current || parentElement.closest("table") || document.querySelector("table");
        row = Number(row);
        if (isNaN(row) && requestTableElement) {
          row = Array.from(requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']"))
            .reduce((acc, { dataset }, index) => {
              if (dataset?.ssn === social_security_number) {
                acc = index
              }
              return acc;
            }, null)
        }

        if (!isNaN(row) && requestTableElement) {
          requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']").forEach((el, index) => {
            if (index === row) {
              el.checked = !el.checked; // Toggle Checked State
            }
          });

          // Click the "Select All" checkbox if all SSNs are selected
          if (requestTableElement.querySelector('thead tr th button[id="remove_selected"]') && requestTableElement.querySelector("thead tr th input[name='select_all_ssn']")) {
            let removeBulkButtonState = requestTableElement.querySelector('thead tr th button[id="remove_selected"]').innerText;
            let selectAllPreviousState = !!requestTableElement.querySelector("thead tr th input[name='select_all_ssn']")?.checked;
            let selectAllNewState = requestObject.socials.length === Array.from(requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']"))
              .map((el) => el?.checked).filter((item) => item).length;

            if (selectAllPreviousState !== selectAllNewState) {
              requestTableElement.querySelector("thead tr th input[name='select_all_ssn']").checked = selectAllNewState;
              if (selectAllNewState && removeBulkButtonState === 'Remove Selected') {
                requestTableElement.querySelector('thead tr th button[id="remove_selected"]').innerText = 'Remove All';
              } else if (!selectAllNewState && removeBulkButtonState === 'Remove All') {
                requestTableElement.querySelector('thead tr th button[id="remove_selected"]').innerText = 'Remove Selected';
              }
            }
          }
        }
      } else {
        error_count++;
        alertOptions.message = `Unknown Action ${action}`;
        alertOptions.alert_type = "info";
      }
    } else if (isValid && error_count === 0) {
      // Update Step 2 - Click the "Select All" checkbox if all SSNs are selected
      let requestTableElement = requestTableRef?.current || parentElement.closest("table") || document.querySelector("table");
      if (requestTableElement && requestTableElement.querySelector('thead tr th button[id="remove_selected"]') && requestTableElement.querySelector("thead tr th input[name='select_all_ssn']")) {
        let removeBulkButtonState = requestTableElement.querySelector('thead tr th button[id="remove_selected"]').innerText;
        let selectAllPreviousState = !!requestTableElement.querySelector("thead tr th input[name='select_all_ssn']")?.checked;
        let selectAllNewState = requestObject.socials.length === Array.from(requestTableElement.querySelectorAll("tbody tr td input[type='checkbox']"))
          .map((el) => el?.checked).filter((item) => item).length;

        if (selectAllPreviousState !== selectAllNewState) {
          requestTableElement.querySelector("thead tr th input[name='select_all_ssn']").checked = selectAllNewState;
          if (selectAllNewState && removeBulkButtonState === 'Remove Selected') {
            requestTableElement.querySelector('thead tr th button[id="remove_selected"]').innerText = 'Remove All';
          } else if (!selectAllNewState && removeBulkButtonState === 'Remove All') {
            requestTableElement.querySelector('thead tr th button[id="remove_selected"]').innerText = 'Remove Selected';
          }
        }
      }
    }

    if (error_count > 0) {
      alertOptions.message = `${error_count} error${error_count > 1 ? 's' : ''}: ${alertOptions?.message}`;
      setPageAlert(prevState => ({ ...prevState, ...alertOptions }));
    }
    setShowAlert(!!alertOptions?.message);
    return;
  }; // END handleRequestChanges

  const stepValidation = () => _isValid;
  const steps = [
    {
      label: 'Select Download Options',
      name: 'step 1',
      validator: stepValidation,
    },
    {
      label: 'Enter Socials',
      name: 'step 2',
      validator: stepValidation,
    },
    {
      label: 'Review and Submit',
      name: 'step 3',
      validator: stepValidation,
    }
  ];

  // ===============[ handleSubmitRequest ]==================================
  const handleSubmitRequest = async () => {
    // Updating newOptions to ensure it matches on all socials with step 1 - Select Download Options 
    let pdf_settings = downloadOptions.reduce((acc, { name, checked, value }) => ({
      ...acc,
      ...(`${name}`.toUpperCase().includes('PDF_') && { [`${name}`.toLowerCase().replace("pdf_", '')]: checked ?? value })
    }), {});

    let newOptions = downloadOptions.reduce((acc, { name, checked, value }) => ({
      ...acc,
      ...((`${name}`.toLowerCase().replace("pdf_", '') in pdf_settings === false) && { [name]: checked ?? value })
    }), { pdf_settings });

    // Prepare Request Object 
    let reqObj = { ...requestObject };
    reqObj.info = {
      request_type_id: bsoLoggedIn ? 1 : 2,
      defaults: newOptions,
      start_time: Date.now(),
      user_id, firm_id, org_id, prefix, auth_id, cookie_id, bso_username, attorney, tabid, cookies
    }
    reqObj.socials.forEach(ssn => {
      ssn.newOptions = newOptions;
    });

    // Update Bottom Queue Option 
    let { checked: USE_BOTTOM_QUEUE_OPTION = false } = downloadOptions.find(({ name }) => name === "DOWNLOAD_INCREMENTAL_DOWNLOADER") || {};
    reqObj.USE_BOTTOM_QUEUE_OPTION = USE_BOTTOM_QUEUE_OPTION;
    setRequestObject(reqObj); // Saving the request object to the state but we'll still use reqObj in case state is still being updated when we're ready to submit the request 

    // Validate Steps and BSO Login if needed
    let alertOptions = {
      message: "",
      alert_type: "info"
    };
    if (!isValid) {
      alertOptions.message = "Please complete the form before submitting";
      alertOptions.alert_type = "warning";
      setPageAlert(prevState => ({ ...prevState, ...alertOptions }));
      setShowAlert(true);
      return;
    }
    setIsValid(false); // Reset Validation
    setShowAlert(false);

    // Validate cookies before trying to submit the request type 1 
    if (reqObj.info.request_type_id === 1) {
      if (server_autologin) {
        let current_user_session = { data: null };
        try {
          current_user_session = await bso.tryAutoLogin(); // Should return the same data ase API.getUserSession() if successful 
          isAuthenticated = !!current_user_session?.data?.logged_in;
        } catch (error) {
          isAuthenticated = false;
          if (error?.message) {
            console.log(error.message);
          } else {
            console.log(error?.stack, error);
          }
          alertOptions.message = `BSO Auto Login Failed! ${error.message ?? error}`;
          alertOptions.alert_type = "info";
          setPageAlert(prevState => ({ ...prevState, ...alertOptions }));
          setShowAlert(true);
        }

        if (userData.isAuthenticated !== isAuthenticated) {
          setUserData(prevState => ({
            ...prevState,
            ...current_user_session?.data,
            awaitingOTP: false,
            isAuthenticated
          }));
        }

        if (!isAuthenticated) {
          return; // Exit if not authenticated
        }
      }
      else if (client_autologin) {
        try {
          const { status, statusText, data } = await bso.checkCookies();
          console.log(`Status=${status} statusText=${statusText} ${(data && Array.isArray(data)) ? data.length + " entries read on pickup page." : ""}`);
          bsoLoggedIn = !!(data || false);
        } catch (error) {
          bsoLoggedIn = false;
          if (error?.message) {
            console.log(error.message);
          } else {
            console.log(error?.stack, error);
          }
          alertOptions.message = `Invalid Cookies! ${error.message ?? error}`;
          alertOptions.alert_type = "info";
          setPageAlert(prevState => ({ ...prevState, ...alertOptions }));
          setShowAlert(true);
        }

        if (userData.bsoLoggedIn !== bsoLoggedIn) {
          setUserData(prevState => ({ ...prevState, bsoLoggedIn }));
        }
        if (!bsoLoggedIn) {
          return; // Exit if not logged into BSO 
        }
      } else {
        alertOptions.message = 'Your account is not setup correctly to submit a request! Please update user settings to enable "Allow Server Auto Login" or "Client Auto Login".';
        alertOptions.alert_type = "warning";
        setPageAlert(prevState => ({ ...prevState, ...alertOptions }));
        setShowAlert(true);
        return;
      }
    } else if (!server_autologin) { // and assuming reqObj.info.request_type_id === 2 
      alertOptions.message = 'Your account is not setup correctly to submit a request! Please update user settings to enable "Allow Server Auto Login" or "Client Auto Login".';
      alertOptions.alert_type = "warning";
      setPageAlert(prevState => ({ ...prevState, ...alertOptions }));
      setShowAlert(true);
      return;
    }

    console.log(`handleSubmitRequest - Processing Request for ${requestObject.socials.length} socials`);
    let processRequestResponse = await downloader.processRequest(reqObj);
    if (processRequestResponse === undefined || processRequestResponse.status !== 200) {
      alertOptions.alert_type = "danger";
      alertOptions.message = processRequestResponse.data.message ? processRequestResponse.data.message : "Was not able to process your request!";
      alertOptions.message += ` Error: ${processRequestResponse.status} - ${processRequestResponse.statusText}`;
      console.log(processRequestResponse.data, alertOptions.message);
      setPageAlert(prevState => ({ ...prevState, ...alertOptions }));
      setShowAlert(true);
      return;
    }

    alertOptions.alert_type = "success";
    alertOptions.message = "Success! Processing Request";
    let { request_id, status, request_queue_batch, USE_BOTTOM_QUEUE = USE_BOTTOM_QUEUE_OPTION } = processRequestResponse?.data || {};
    let updatedUserData = {
      USE_BOTTOM_QUEUE_OPTION: USE_BOTTOM_QUEUE,
      ...(request_id && { request_id }),
      ...(status && { status }),
    };

    // Force user to login for new set of cookies when status is 'DOWNLOADING_FILES' or 'INITIATING'
    if (!status || ['DOWNLOADING_FILES', 'INITIATING'].includes(status)) {
      updatedUserData = { ...updatedUserData, bsoLoggedIn: false, AuthT: null, awaitingOTP: false, twofactor_iat: null, text_number: null };
    }

    // Update State 
    setUserData(prevState => ({ ...prevState, ...updatedUserData }));
    setRequestObject({ info: { request_type_id: 2 }, socials: [] });
    setPageAlert(prevState => ({ ...prevState, ...alertOptions }));
    setShowAlert(true);

    // Navigate Away from this page 
    if (request_id) {
      navigate(`/download-request-details/${request_id}`);
    } else {
      navigate(`/download-requests`);
    }
    return;
  }; // END handleSubmitRequest

  // ===============[ useEffect ]==================================
  useEffect(() => {
    // Set Download Options 
    let mounted = true;

    // Set Event Listeners 
    const setEventListeners = () => {
      const activeStepClasses = ['_35Ago', '_1CcaK']; // _35Ago = active, _1CcaK = error
      if (document.querySelector('li.step-progress')) {
        document.querySelectorAll('li.step-progress').forEach(stepBubble => {
          stepBubble.addEventListener('click', (event) => {
            if (!event?.target) return;
            let clickedElement = event?.target || {};
            let { className, tagName, type, innerText, parentElement } = clickedElement;
            // console.log({ className, tagName, type, innerText, parentElement });

            // Get the active step by activeStepClasses
            let step = 1;
            if (document.querySelector('li.step-progress')) {
              step = Array.from(document.querySelectorAll('li.step-progress')).reduce((acc, { className }, index) => {
                if (className.split(' ').filter((item) => activeStepClasses.includes(item)).length > 0) {
                  return index + 1;
                }
                return acc;
              }, step);
            }

            // Get the active step by clicked element
            if (['svg', 'path'].includes(tagName)) {
              innerText = event?.target.closest('li.step-progress') && event?.target.closest('li.step-progress').innerText;
            } else if ((tagName !== 'SPAN' || innerText?.includes('!')) && parentElement?.tagName === 'LI' && parentElement?.className.split(' ').includes('step-progress')) {
              innerText = parentElement.querySelector('span') && parentElement.querySelector('span').innerText;
              if (innerText && !isNaN(Number(innerText.trim()))) {
                tagName = 'SPAN';
              } else {
                innerText = parentElement.querySelector('div') && parentElement.querySelector('div').innerText;
                tagName = parentElement.querySelector('div') ? 'DIV' : tagName;
              }
            }
            if (tagName === 'SPAN' && innerText && !isNaN(Number(innerText.trim()))) {
              step = Number(innerText);
            }
            toggleAccordionSlide.call({ step, innerText }, event);
          });
        })
      }
    } // END setEventListeners

    if (mounted) {
      let inList = ['PDF', 'DOWNLOAD'];
      let notInList = ['PDF_COMBINE_PDF'];
      let options = (isObject(user_settings) && Object.keys(user_settings).length > 0) ?
        Object.entries(user_settings).filter(([k, v]) => inList.some((item) => `${k}`.toUpperCase().includes(item)) && !notInList.includes(`${k}`.toUpperCase()))
          .reduce((acc, [name, value]) => {
            let item = {
              name,
              label: (humanize(name.replace(/pdf/gi, "").replace(/download/i, ""))).trim(),
              ...((['true', 'false'].includes(value) || typeof value === 'boolean') ? { checked: typeof value === 'boolean' ? value : value === 'true' } : { value }),
              ...(name === "DOWNLOAD_INCREMENTAL_DOWNLOADER" && {
                checked: (user_settings.BOTTOM_QUEUE_LIMIT === 0) || (!bsoLoggedIn && !server_autologin && !client_autologin) ? false : (typeof value === 'boolean' ? value : value === 'true'),
                disabled: (user_settings.BOTTOM_QUEUE_LIMIT === 0),
                title: `The incremental downloader option allows claimant records to be downloaded individually and is particularly useful when full processing from the BSO is slow. There can be no more than ${user_settings.BOTTOM_QUEUE_LIMIT ?? 5} request socials to enable this option.`
              })
            };

            if (item?.value) {
              acc.push(item);
            } else {
              acc.unshift(item);
            }
            return acc;
          }, []) : [];

      if (options.length > 0) {
        if (!options[0].name.includes('DOWNLOAD')) {
          options = options.reverse();
        }
        setDownloadOptions(options);
      }

      setEventListeners();
    }
    return () => mounted = false
  }, []);

  // activeStep useEffect
  useEffect(() => {
    let mounted = true;
    const bubbleStepUpdater = async (newStep) => {
      const previousButton = document.querySelector('._3CDiP.secondary-button');
      const nextButton = document.querySelector('._hsN1w.primary-button');
      const activeStepClasses = ['_35Ago', '_1CcaK']; // _35Ago = active, _1CcaK = error
      let currentStep = 1;
      let _isValid_ = true;

      if (document.querySelector('li.step-progress')) {
        currentStep = Array.from(document.querySelectorAll('li.step-progress')).reduce((acc, { className }, index) => {
          if (className.split(' ').filter((item) => activeStepClasses.includes(item)).length > 0) {
            return index + 1;
          }
          return acc;
        }, currentStep);
      }

      // Validate steps stopping at the current step
      let clicks = Math.abs(newStep - currentStep);
      if (newStep > currentStep && nextButton) {
        for (let i = 0; i < clicks; i++) {
          // Validate Step 1
          _isValid_ = !!downloadOptions.filter(({ checked = false, name = '' }) => checked && !`${name}`.toUpperCase().includes('PDF_')).length > 0;
          if (_isValid_ && (i > 0 || newStep > 2 && clicks > 0)) { // Validate Step 2+
            _isValid_ = !!requestObject?.socials.length > 0;
          }

          // Update the global state asap so we don't move on to the next step when the current step is invalid 
          _isValid = _isValid_;

          if (i > 0) {
            await sleep(0.3);
          }
          nextButton.click();
        }
      } else if (newStep < currentStep && previousButton) {
        for (let i = 0; i < clicks; i++) {
          // Validate Step 1
          _isValid_ = !!downloadOptions.filter(({ checked = false, name = '' }) => checked && !`${name}`.toUpperCase().includes('PDF_')).length > 0;
          if (_isValid_ && (i > 0 || newStep > 2 && clicks > 0)) { // Validate Step 2+
            _isValid_ = !!requestObject?.socials.length > 0;
          }

          // Update the global state asap so we don't move on to the next step when the current step is invalid 
          _isValid = _isValid_;

          if (i > 0) {
            await sleep(0.3);
          }
          previousButton.click();
        }
      }
      return _isValid_;
    } // END bubbleStepUpdater

    bubbleStepUpdater(activeStep)
      .then((success) => {
        if (mounted) {
          setIsValid(success);
        }
      });

    return () => mounted = false
  }, [activeStep]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      _isValid = isValid;
    }
    return () => mounted = false
  }, [isValid]);

  return (<div className="page-container">
    <h4>Default Download Request</h4>
    <hr />
    <Alert
      dismissible
      onClose={() => setShowAlert(false)}
      show={showAlert}
      variant={pageAlert.alert_type}
    >{pageAlert.message}</Alert>

    <StepProgressBar
      startingStep={activeStep > 1 && activeStep - 1 || 0}
      onSubmit={handleSubmitRequest}
      buttonWrapperClass="d-none"
      stepClass="step-progress"
      primaryBtnClass="primary-button"
      secondaryBtnClass="secondary-button"
      steps={steps}
    />

    <StyledAccordionDownloader defaultActiveKey={[`${activeStep - 1}`]} alwaysopen="true" flush>
      <Accordion.Item eventKey='0'>
        <Accordion.Header ref={selectOptionsStep} onClick={(e) => (setActiveStep(1))}> Step 1 - Select Download Options</Accordion.Header>
        <Accordion.Body>
          <Form noValidate className="row">
            <Form.Group className="col-12 text-end pe-0 mb-3">
              <Button className="primary-button" onClick={toggleAccordionSlide} >Enter Socials &gt;</Button>
            </Form.Group>
            <Alert variant="warning" className="col-12 p-2">Select the download options you would like to apply to your download request.</Alert>

            {(Array.isArray(downloadOptions) && downloadOptions.length > 0) && (<Form.Group className="row" controlId="downloadOptionsCheckbox" >
              {splitArrayChunks(downloadOptions, 3).map((chunk, idx, arr) => (<Col key={`chunk-${idx}`}>
                <StyledListNoBorder key={`list-${idx}`} {...(idx === arr.length - 1) && { className: "d-flex align-items-end" }} >{chunk.map(({ name, label, checked = false, value, disabled = false, title }, index) => {
                  if (value) {
                    return (<div className="list-group-item d-flex" key={`${idx}-${index}`}>
                      <Form.Label className="me-2 mt-2" {...(title) && { title }} {...(disabled) && { className: "text-muted" }}>{label}</Form.Label>
                      <Form.Control key={`${name}-${index}`} className="w-auto" type="text" name={name} value={value} data-index={index} onChange={handleRequestChanges} {...(disabled) && { disabled }} />
                    </div>);
                  }
                  return (<StyledGreenCheckbox key={`${idx}-${index}`} className="list-group-item">
                    <Form.Control key={`${name}-${index}`} className="me-2" type="checkbox" name={name} checked={checked} data-index={index} onChange={handleRequestChanges} {...(disabled) && { disabled }} />
                    <span {...(title) && { title }} {...(disabled) ? { className: "text-muted ms-2" } : { className: "ms-2" }} >{label}</span>
                  </StyledGreenCheckbox>);
                })}</StyledListNoBorder>
              </Col>))}
            </Form.Group>)}
          </Form>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey='1'>
        <Accordion.Header ref={enterSocialStep} onClick={(e) => (setActiveStep(2))}>Step 2 - Enter Socials</Accordion.Header>
        <Accordion.Body>
          <Form noValidate className="row">
            <Form.Group className="col-xl-4 col-lg-9 col-sm-12 pe-0 mb-3">
              {downloadOptions.find(({ name }) => name === "DOWNLOAD_INCREMENTAL_DOWNLOADER")?.checked && (<Form.Label className="ps-0">Add up to <strong>{user_settings.BOTTOM_QUEUE_LIMIT ?? 5}</strong> socials to allow <strong>Incremental Downloader</strong> option.</Form.Label>)}
              <textarea className="form-control" id="enter_ssns" name="ssn" rows="2" onKeyUp={handleRequestChanges} required />
            </Form.Group>
            <Form.Group className="col-xl-8 col-lg-3 col-sm-12 text-end pe-0">
              <Button className="primary-button" onClick={toggleAccordionSlide}>Review & Submit &gt;</Button>
            </Form.Group>

            {(Array.isArray(requestObject.socials) && requestObject.socials.length > 0) && (<Form.Group className="row">
              <StyledRequestTable hover ref={requestTableRef}>
                <thead>
                  <tr>
                    <th>
                      <StyledGreenCheckbox>
                        <Form.Control key={`select_all_ssn_${requestObject.socials.length}`} className="me-2" type="checkbox" name="select_all_ssn" title="Select All SSN" onClick={handleRequestChanges} defaultChecked={true} />
                      </StyledGreenCheckbox>
                    </th>
                    <th>Social Security Number</th>
                    <th>
                      <Button key={`remove_selected_${requestObject.socials.length}`} id="remove_selected" variant="danger" size="sm" className="remove-button" onClick={handleRequestChanges} >
                        Remove All
                      </Button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {requestObject.socials.map(({ ssn }, index) => (<tr key={`${index}-${ssn}`} onClick={handleRequestChanges} data-row={index} >
                    <td width={45} data-col={0} >
                      <StyledGreenCheckbox key={`0-${index}`} >
                        <Form.Control className="me-2" type="checkbox" name={`row_${index}`} title={`Select ${index}`} data-ssn={ssn} defaultChecked={true} />
                      </StyledGreenCheckbox>
                    </td>
                    <td data-col={1} key={`1-${index}`}>{ssn}</td>
                    <td data-col={2} key={`2-${index}`}><Link to="#remove-ssn" className="text-danger">Remove</Link></td>
                  </tr>))}
                </tbody>
              </StyledRequestTable>
            </Form.Group>)}
          </Form>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey='2'>
        <Accordion.Header ref={reviewAndSubmit} onClick={(e) => (setActiveStep(3))}>Step 3 - Review and Submit</Accordion.Header>
        <Accordion.Body>
          <div className="text-end pe-0 mb-2">
            <Button className="green-button" onClick={(e) => (setActiveStep(4))} {...(!isValid || activeStep > 3) && { disabled: true }} >Submit Request</Button>
          </div>
          <Alert variant="warning" className="col-12 p-2">Please review your request before submitting.</Alert>

          <StyledCardLight className="card-hover mb-3">
            <Card.Header>
              <Card.Title>Selected Download Options
                <span className="text-primary ms-4 small" onClick={toggleAccordionSlide.bind({ step: 1 })} >Edit</span>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {(Array.isArray(downloadOptions) && downloadOptions.filter(({ checked = false }) => checked).length > 0) && (<StyledListNoBorder>
                {downloadOptions.filter(({ checked = false }) => checked).map(({ label }, index) => (<li key={index} className="list-group-item">{label}</li>))}
              </StyledListNoBorder>) || (<span className="text-danger">No download options selected</span>)}
            </Card.Body>
          </StyledCardLight>
          <StyledCardLight className="card-hover mb-3">
            <Card.Header>
              <Card.Title>Included Socials
                <span className="text-primary ms-4 small" onClick={toggleAccordionSlide.bind({ step: 2 })} >Edit</span>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {requestObject?.socials.length > 0 && (<StyledListNoBorder>
                {requestObject.socials.map(({ ssn }, index) => (<li className="list-group-item" key={`social-${index}`}>{ssn}</li>))}
              </StyledListNoBorder>) || (<span className="text-danger">No socials selected</span>)}
            </Card.Body>
          </StyledCardLight>
        </Accordion.Body>
      </Accordion.Item>
    </StyledAccordionDownloader>
  </div>);
}

export { DefaultDownloadRequest };
export default DefaultDownloadRequest;