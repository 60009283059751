import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUserData } from "../contexts/AuthContext";
import { Form, Row, Col, Button, Table, Alert } from 'react-bootstrap'; // Bootstrap Components
import Loader from "../components/Loader";

// EREDocs Functions
import { downloader } from "../utils/EREDocs";
import { getParams, dehumanize, humanize, formatDate } from "../utils";
import downloadRequests from '../fake-data/downloadRequests.json'; // When loadFakeData = true
import requestStatuses from '../fake-data/requestStatus.json'; // When loadFakeData = true

// Globals 
const loadFakeData = process.env.REACT_APP_LOAD_FAKE_DATA === "true";
const SECONDS_REFRESH_LOGS = Number(process.env.REACT_APP_SECONDS_REFRESH_LOGS || '0'); // Refresh logs every 5 seconds. Zero means no refresh.
const defaultQueryParams = { limit: 25, showDebug: false, useLegacy: false };
let IntervalId = null;
let REQUEST_STATUSES = requestStatuses;

const DownloadRequestLogs = ({ org_id, user_id } = {}) => {
  const navigate = useNavigate();
  const { userData } = useUserData();
  let { passport: { user: { org_id: user_org_id } = {} } = {}, isAuthenticated = false } = userData || {};

  if (org_id !== 0 && !org_id) {
    org_id = user_org_id;
  }

  const [invokeUpdate, setInvokeUpdate] = useState(false);
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [requests, setRequests] = useState({});
  const [Pages, setPages] = useState([1]);
  const [activePage, setActivePage] = useState(1);
  const [showAlert, setShowAlert] = useState(false);
  const [formAlert, setFormAlert] = useState({ message: "", alert_type: "info" });

  // Filter form state
  const [filterFormState, setFilterFormState] = useState({
    start_date: '',
    end_date: '',
    status: '',
    requested_by: ''
  });

  const requestLogsRef = useRef();

  // Handle form change
  const handleFormChange = (event) => {
    if (!event?.target) return;
    const { name, value } = event.target;
    setFilterFormState({ ...filterFormState, [name]: value });
  };

  // Clear filters
  const clearFilters = (event) => {
    if (!event?.target) return;
    event.preventDefault();
    setFilterFormState({
      start_date: '',
      end_date: '',
      status: '',
      requested_by: ''
    });
    return setInvokeUpdate(prev => !prev);
  }

  // Search Requests 
  const searchRequests = async (event) => {
    if (!event?.target) return;
    event.preventDefault();
    return setInvokeUpdate(prev => !prev);
  }

  // Handle request row click
  const handleRequestRowClick = (event) => {
    if (!event?.target) return;
    event.preventDefault();

    let { parentElement, dataset, tagName, type, innerText } = event.target;
    let rowObject = {
      innerText,
      tagName,
      ...(type && { type }),
      ...dataset,
      ...parentElement?.dataset,
    };

    // extract row items and save to rowObject
    if (tagName === "TD") {
      let tdArray = Array.from(parentElement.querySelectorAll("td")).map(({ innerText }) => innerText) || [];
      let thArray = Array.from(parentElement.closest("table").querySelectorAll("thead tr th")).map(({ innerText }) => dehumanize(innerText)) || [];
      if (tdArray.length === thArray.length) {
        for (let i = 0; i < thArray.length; i++) {
          rowObject[thArray[i]] = tdArray[i];
        }
      }
    }

    // console.log("handleRequestRowClick", rowObject);
    let { request_id } = rowObject;
    if (request_id) {
      navigate(`/download-request-details/${request_id}`);
      return;
    }

    let alert_message = `Missing request_id in row: ${humanize(rowObject)}`;
    setShowAlert(true);
    setFormAlert(prevState => ({ ...prevState, message: alert_message, alert_type }));
    return;
  };

  const getRequestData = async ({ startedAt = Date.now() } = {}) => {
    let now = Date.now();
    let duration_seconds = Math.round((now - startedAt) / 1000);
    let ran_count = !SECONDS_REFRESH_LOGS ? 1 : Math.round(duration_seconds / SECONDS_REFRESH_LOGS) || 1; // Avoid dividing by zero
    let first_run = now === startedAt || ran_count === 1;

    // Detect if user navigated away from this page 
    let offsetWidth = requestLogsRef?.current?.offsetWidth || 0;
    let offsetHeight = requestLogsRef?.current?.offsetHeight || 0;
    let navigated_away = !requestLogsRef?.current || !(offsetWidth > 0 && offsetHeight > 0);

    if (!first_run && (navigated_away || !isAuthenticated)) {
      console.log("Clearing Interval for logs because user navigated away.", { IntervalId, first_run, navigated_away, isAuthenticated, offsetWidth, offsetHeight });
      clearInterval(IntervalId);
      return;
    }

    let filterValues = Object.entries({ ...filterFormState, ...(user_id && { user_id }) }).filter(([id, value]) => value); // Filter out empty values
    let filteredData = filterValues.length === 0 ? null : filterValues.map(([id, value]) => ({ id, value }));
    let queryParams = { ...defaultQueryParams, page: activePage };
    let postData = { queryParams, filteredData };
    let alertObj = { ...formAlert };
    let response;

    try {
      if (loadFakeData) {
        response = {
          status: 200,
          statusText: 'OK',
          data: downloadRequests
        };
      } else if (postData.filteredData !== null) {
        response = await downloader.filteredOrgDownloadRequests(org_id, postData);
      } else {
        response = await downloader.allOrgDownloadRequests(org_id, getParams(queryParams));
      }
      alertObj.message = "";
    } catch (error) {
      console.log(error);
      response = error?.response || {};
      if (!response?.data) {
        response.data = { err: 401, error: error.error, details: `${error.message}` };
      }
    }

    let { status, statusText = '', data = {} } = response;
    let { err, error, details, message, itemCount = 0, ere_download_requests = [], pageCount, pages = [] } = data;

    if (status === 200) {
      if (ere_download_requests.length === 0 || itemCount === 0) {
        if (activePage > 1 && activePage === Pages[Pages.length - 1]) {
          setActivePage(Pages[Pages.length - 2]); // Make sure we don't get stuck loading nothing
        } else {
          alertObj.alert_type = "info";
          alertObj.message = "No logs found";
        }
      }

      pages = pages.reduce((acc, item, index) => {
        if (index === 0 && item.number !== 1) {
          acc.push(1); // Always include the first page 
        }

        acc.push(item.number);

        if (index === (pages.length - 1) && !acc.includes(pageCount)) {
          acc.push(pageCount); // Always include the last page
        }
        return acc;
      }, []);

      setRequests(data);
      setPages(pages);
    } else {
      alertObj.alert_type = "danger";
      alertObj.message = `[${status ?? err}] ${statusText} `;    // API Error Code 
      alertObj.message += data?.status ? `${data.status} ` : ""; // API Status 
      alertObj.message += error ? `${error} ` : "";              // API Error 
      alertObj.message += (typeof response?.data === 'string') ? response.data : message ?? (details ? `${details} ` : "") + `Failed to read data for requests.`;
      alertObj.message = alertObj.message.trim();
    }

    setShowAlert(!!alertObj.message);
    if (!!alertObj.message) {
      setFormAlert(prevState => ({ ...prevState, ...alertObj }));
    }

    if (first_run && SECONDS_REFRESH_LOGS) {
      if (IntervalId !== null) {
        console.log("Clearing Interval for logs because new interval is being started.");
        clearInterval(IntervalId);
      }
      let interval_ms = SECONDS_REFRESH_LOGS * 1000;
      IntervalId = setInterval(getRequestData.bind(null, { startedAt }), interval_ms); // Next render every SECONDS_REFRESH_LOGS seconds
      console.log("Set Interval for logs!", { IntervalId, activePage, loadingLogs, startedAt });
    }

    // console.log("getRequestData", { first_run, IntervalId, ran_count, duration_seconds, now, startedAt, navigated_away, isAuthenticated, offsetWidth, offsetHeight, status, statusText });
    return data;
  } // END getRequestData

  // ===============[ useEffect ]==================================
  useEffect(() => {
    const init = async () => {
      if (org_id === 0) return;
      setShowAlert(false);
      setLoadingLogs(true);
      await getRequestData().then((reqData) => {
        setLoadingLogs(false);
      });
    }

    let mounted = true;
    if (mounted) {
      if (IntervalId !== null) {
        console.log("Clearing Interval for logs because changed detected", { IntervalId });
        clearInterval(IntervalId);
      }
      init(); // Initialize Request Data for Table
    }

    // Returned function will be called on component unmount 
    return () => {
      if (IntervalId !== null) {
        console.log("Clearing Interval for logs because this component unmounted.", { IntervalId });
        clearInterval(IntervalId);
        mounted = false;
      }
    }
  }, [org_id, invokeUpdate, activePage]);

  useEffect(() => {
    const loadStatuses = async () => {
      let response;

      try {
        if (loadFakeData) {
          response = {
            status: 200,
            statusText: 'OK',
            data: requestStatuses
          };
        } else {
          let queryParams = getParams({ enum_key: "enum_ere_download_request_status" });
          response = await downloader.getStatus(queryParams);
        }
      } catch (error) {
        console.log(error);
        response = error?.response || {};
        if (!response?.data) {
          response.data = { err: 401, error: error.error, details: `${error.message}` };
        }
      }

      let { status, statusText = '', data = [] } = response;
      if (status === 200) {
        REQUEST_STATUSES = (Array.isArray(data) && data.length > 0) ? data : requestStatuses;
      } else {
        REQUEST_STATUSES = requestStatuses;
        let { err, error, details, message } = data || {};
        let alert_message = `[${status ?? err}] ${statusText} `;    // API Error Code 
        alert_message += data?.status ? `${data.status} ` : ""; // API Status 
        alert_message += error ? `${error} ` : "";              // API Error 
        alert_message += (typeof response?.data === 'string') ? response.data : message ?? (details ? `${details} ` : "") + `Failed to read status table.`;
        alert_message = alert_message.trim();
        console.log(alert_message);
      }
    };

    loadStatuses();
  }, []);

  return (
    <div className="page-container" ref={requestLogsRef} >
      <h4>Download Request Logs</h4>
      <hr />

      <div className="filter-section">
        <h5>Filters</h5>
        <Alert
          dismissible
          onClose={() => setShowAlert(false)}
          show={showAlert}
          variant={formAlert.alert_type}
        >{formAlert.message}</Alert>
        <Form className="filter-form mb-3" onSubmit={searchRequests} onReset={clearFilters} >
          <Row>
            <Col>
              <Form.Group controlId="filterStartDate">
                <Form.Label>Start Date:</Form.Label>
                <Form.Control
                  size="sm"
                  type="date"
                  name="start_date"
                  value={filterFormState.start_date}
                  onChange={handleFormChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="filterEndDate">
                <Form.Label>End Date:</Form.Label>
                <Form.Control
                  size="sm"
                  type="date"
                  name="end_date"
                  value={filterFormState.end_date}
                  onChange={handleFormChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="filterStatus">
                <Form.Label>Status</Form.Label>
                <Form.Select size="sm" name="status" defaultValue={''} onChange={handleFormChange} >
                  <option value="">All</option>
                  {REQUEST_STATUSES && REQUEST_STATUSES.length > 0 && REQUEST_STATUSES.map(({ status_id, status_code, status, label, css_class } = {}, idx) => (
                    <option
                      key={`${status}-${idx}`}
                      value={status}
                      data-status-id={status_id}
                      data-status-code={status_code}
                      {...(css_class && { className: css_class })}
                      {...(filterFormState.status === status) && { selected: true }}
                    >
                      {label ?? status}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="filterRequestedBy">
                <Form.Label>Requested By</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="requested_by"
                  value={filterFormState.requested_by}
                  onChange={handleFormChange}
                />
              </Form.Group>
            </Col>
            <Col className="d-flex align-items-end">
              <Button className="primary-button" type="submit" name="apply" {...(loadingLogs) && { disabled: true }} >Apply Filters</Button>
              <Button className="remove-button ms-3" type="reset" name="clear" {...(loadingLogs) && { disabled: true }} >Clear Filters</Button>
            </Col>
          </Row>
        </Form>
      </div>

      {loadingLogs ? (<Loader className="text-info" style={null} />) : (<Table hover>
        <thead>
          <tr>
            <th>Request ID</th>
            <th>Status</th>
            <th>Socials Processed</th>
            <th>Requested By</th>
            <th>Requested Date</th>
          </tr>
        </thead>
        <tbody>
          {requests?.ere_download_requests && requests.ere_download_requests.map(({ request_id, status = '', social_count = 0, socials_to_process = 0, createdAt, UserRequests: { first_name = '', last_name = '' } = {} } = {}, index) => (
            <tr onClick={handleRequestRowClick} key={`row-${index}-${request_id}`} data-index={index} data-request_id={request_id}>
              <td>{request_id}</td>
              <td>{status}</td>
              <td>{`${social_count} of ${socials_to_process}`}</td>
              <td>{`${first_name} ${last_name}`}</td>
              <td>{formatDate(createdAt)}</td>
            </tr>
          ))}
        </tbody>
        {Pages.length > 1 && (<tfoot>
          <tr>
            <td className="text-right" colSpan={5} >
              <nav>
                <ul className="pagination justify-content-end" data-active-page={activePage} >
                  {Pages && Pages.length > 0 && Pages.map((pageNumber, index) => (
                    <li key={`page-${pageNumber}-${index}`} className={`page-item ${pageNumber === activePage ? "disabled" : ""}`} >
                      <Link
                        to={`#page-${pageNumber}`}
                        className="page-link"
                        onClick={(e) => {
                          e.preventDefault();
                          setActivePage(pageNumber);
                        }}
                        {...(pageNumber === activePage) && { tabIndex: -1 }}
                      >{pageNumber}</Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </td>
          </tr>
        </tfoot>)}
      </Table>)}
    </div>
  );
}

export default DownloadRequestLogs;